import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Button, Tooltip, Grid, FormHelperText, Radio, RadioGroup, 
         FormControl, FormControlLabel } from '@material-ui/core';
//import Switch from '@material-ui/core/Switch';
//import FormGroup from '@material-ui/core/FormGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import { KeyboardDatePicker } from '@material-ui/pickers';
import InfoIcon from '@material-ui/icons/Info';
//import Delete from '@material-ui/icons/Delete';
//import { postVendorForm, getVendorForm, aadharUpload, panUpload }  from '../../actions/asyncActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  table_root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  margin: {
    margin: theme.spacing(2),
  },
  errors: {
    color: 'red'
  },
  textfield:{
   padding: theme.spacing(1) 
  }
}));

export default function VendorForm(props) {
  //const user = JSON.parse(localStorage.getItem("m_users"));
  //const company_id= user.company_id;
  //const group_id = user.group_id;
  const classes = useStyles();
  //const [vendorForm, setVendorForm] = useState([]);
  //const [open, setOpen] = useState(false);
  
  // const { companyregno, esino, gstno, pfno, particularreg, capinvest, fields, values, 
  //   adharImage, panImage, comImage, esiImage, gstImage, pfImage, errors } = props;
  const { fields, values, errors, radioVal } = props;


  // useEffect( () => {
  //   getVendorForm(group_id)
  //   .then(resp => {
  //     setVendorForm(resp.data);
  //   })
  // }, []);

return (
 <React.Fragment>
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6}>
      <Typography gutterBottom>
       Company Reg No:
       <Tooltip title="Registration Number">
        <InfoIcon color="primary" fontSize="small"/>
       </Tooltip>
     </Typography>
     <TextField id="companyregno" name="companyregno" value={values.companyregno} 
       onChange={props.handleChange} fullWidth autoFocus />
       <FormHelperText className={classes.errors}>{errors.companyregno}</FormHelperText>
    </Grid>
    <Grid item xs className="m-t-15">
      <Typography gutterBottom>      
       <input id="filecom" type="file" variant="outlined" onChange={props.handleUploadCom} /> 
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Typography gutterBottom>
       ESI No:
     </Typography>
     <TextField id="esino" name="esino" value={values.esino} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs className="m-t-15">  
      <Typography gutterBottom>
       <input id="fileesi" type="file" variant="outlined" onChange={props.handleUploadEsi} />      
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Typography gutterBottom>
       GST/IGST No:
     </Typography>
     <TextField id="gstno" name="gstno" value={values.gstno} 
       onChange={props.handleChange} fullWidth />
       <FormHelperText className={classes.errors}>{errors.gstno}</FormHelperText>
    </Grid>
    <Grid item xs className="m-t-15">
      <Typography gutterBottom>
       <input id="filegst" type="file" variant="outlined" onChange={props.handleUploadGst} />   
      </Typography>  
    </Grid>
    <Grid item xs={12} sm={6}>
      <Typography gutterBottom>
       PF Number:
     </Typography>
     <TextField id="pfno" name="pfno" value={values.pfno} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs className="m-t-15">
      <Typography gutterBottom>
       <input id="filepf" type="file" variant="outlined" onChange={props.handleUploadPf} />   
      </Typography> 
    </Grid>
    <Grid item xs={12}>
      <FormControl component="fieldset" className={classes.formControl}>
        <Typography gutterBottom>
          Is your Business Revenue more than 500 Crores ?
        </Typography>
        <RadioGroup row aria-label="revenue" name="revenue" value={radioVal.revenue} onChange={props.handleRadioChange} fullWidth>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      { radioVal.revenue === 'yes' ?
       <Grid container spacing={3}>
        <Grid item xs>
          <TextField id="client_id" name="client_id"  label="Client ID" value={values.client_id} 
           onChange={props.handleChange} fullWidth />
        </Grid>
        <Grid item xs>
          <TextField id="client_secret" name="client_secret"  label="Client Secret" value={values.client_secret} 
           onChange={props.handleChange} fullWidth />
        </Grid>
       </Grid>
        : null
      }
    </Grid>
    <Grid item xs={12}>
      <Typography gutterBottom>
        Particulars of Registration / Statutory and Regulatory requirements if any:
     </Typography>
     <TextField id="particularreg" name="particularreg" label="Particulars" value={values.particularreg} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12}>
      <Typography gutterBottom>
        Capital Investment:
        <Tooltip title="Enter Capital Investment">
          <InfoIcon color="primary" fontSize="small"/>
        </Tooltip>
     </Typography>
     <TextField id="capinvest" name="capinvest"  value={values.capinvest} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12}>
      <Typography gutterBottom>
        Additional Places of Supply:
      </Typography>
      {fields.map((el, i)=> {
        return(
        <div key={i} className={classes.textfield}>
          <Grid container spacing={3}>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChange3(i, e)} value={el.placename || ''} id="placename" name="placename" label="Name"
             fullWidth autoComplete="placename" />
           </Grid>
           <Grid item xs={12} sm={3}>
            <TextField onChange={(e) => props.handleChange3(i, e)} value={el.placeaddress || ''} id="placeaddress" name="placeaddress" 
            label="Address" fullWidth autoComplete="placeaddress" />
           </Grid>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChange3(i, e)} value={el.placecity || ''} id="placecity" name="placecity" label="City"
             fullWidth autoComplete="placecity" />
           </Grid>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChange3(i, e)} value={el.placestate || ''} id="placestate" name="placestate" label="State"
             fullWidth autoComplete="placestate" />
           </Grid>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChange3(i, e)} value={el.placegst || ''} id="placegst" name="placegst" label="GST"
             fullWidth autoComplete="placegst" />
           </Grid>
           <Grid item xs={12} sm={1}>
             <Button size="large" color="secondary" variant="contained" onClick={() => props.handleRemove(i)}>
              X 
            </Button>
          </Grid>
          </Grid>
        </div>
        );
      })}
      <Button size="medium" color="primary" variant="contained" onClick={() => props.addClick()}>
        Add 
      </Button>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Typography gutterBottom>
       Aadhar Number: 
       </Typography>
       <TextField id="aadharno" name="aadharno" value={values.aadharno} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs className="m-t-15">
      <Typography gutterBottom>
        <input id="file" type="file" variant="outlined" onChange={props.handleUploadAadhar} />  
      </Typography>   
    </Grid>
    <Grid item xs={12} sm={6}>
      <Typography gutterBottom>
       PAN Number:
     </Typography>
     <TextField id="panno" name="panno" value={values.panno} 
       onChange={props.handleChange} fullWidth />
       <FormHelperText className={classes.errors}>{errors.panno}</FormHelperText>
    </Grid>
    <Grid item xs={12} sm={6} className="m-t-15">
      <Typography gutterBottom>
       <input id="files" type="file" variant="outlined" onChange={props.handleuploadPAN} /> 
      </Typography>
    </Grid>
  </Grid>
</React.Fragment>
);
}
