import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb'
import {Container,Row,Col,Card,CardBody,FormGroup,Progress,Nav,NavItem,NavLink,TabContent,TabPane} from 'reactstrap'
import { Target, Info, CheckCircle, PlusCircle } from 'react-feather';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
//import {Issues,Resolved,Comment,Done,All,Doing,CreateNewProject} from '../../../constant'
import { getComComponents, getCompensation, getBookLeave, getEmployee, postPayroll, getPayroll } from '../../../actions/asyncActions';

const PayrollProcessing = (props) => {
  
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    const [activeTab, setActiveTab] = useState("1")
    
    var curYear = new Date().getFullYear();
    const [assessmentYear, setAssessmentYear] = useState(curYear)
    var selectedYear = parseInt(assessmentYear)
    
    // var month = new Array();
    //   month[0] = "January";
    //   month[1] = "February";
    //   month[2] = "March";
    //   month[3] = "April";
    //   month[4] = "May";
    //   month[5] = "June";
    //   month[6] = "July";
    //   month[7] = "August";
    //   month[8] = "September";
    //   month[9] = "October";
    //   month[10] = "November";
    //   month[11] = "December";
      var  month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // const allProject = useSelector(content => content.Projectapp.all_Project);
    // const doingProject = useSelector(content => content.Projectapp.doing_Project);
    // const doneProject = useSelector(content => content.Projectapp.done_Project);
    
    var currentDate = new Date();
    var currentMonth = new Date();
    currentMonth.setDate(25) // Gets 25th of current Month
    var nxt = parseInt(currentDate.getMonth() + 1)
    var nextMonth = new Date();
    nextMonth.setMonth(nxt)
    nextMonth.setDate(28) // Gets 28th of next Month


    var c = new Date(nextMonth.getTime());   // clone the date to not muck the original value
    c.setDate(c.getDate()+1);        // add a day
    var inRange = currentDate >= currentMonth && currentDate < c; // use a half-open interval //Current Month to Next Range

    var prev = currentDate.getMonth() - 1;
    var prevMonth = new Date();
    prevMonth.setMonth(prev)
    prevMonth.setDate(25) // Get 25th of Previous month
    
    var presMonth = new Date();
    presMonth.setDate(28)

    var d = new Date(presMonth.getTime());
    d.setDate(d.getDate()+1);

    var prevToPresRange = currentDate >= prevMonth && currentDate < d; //Previous Month to Present Range
    

    // console.log("currentMonth", currentMonth)
    // console.log("nextMonth", nextMonth)
    console.log("inRange", inRange)
    const [bookLeaves, setBookLeaves] = useState([])
    const [employees, setEmployees] = useState([])
    const [payroll, setPayroll] = useState([])
    
    const zzz = employees.filter(x => x.job_status === 'ACTIVE'); //Find Active Employees
    const abcd = [...new Set(zzz.map(x => x.id))]; // Get their IDs
    const [compensation, setCompensation] = useState([]);
    const filterCompensation = compensation.filter(x=>x.group_id === group_id && abcd.includes(x.emp_id)).sort((a,b) => b.id - a.id)
    
    const lastCompensation = filterCompensation ? filterCompensation[0] : null
    const getLastPayMonth = lastCompensation ? lastCompensation && lastCompensation.last_pay_month : null;
    const getLastPayYear = lastCompensation ? lastCompensation && lastCompensation.last_pay_year : null;
    const findLastPayMonth = getLastPayMonth === null ? currentDate.getMonth() : getLastPayMonth;
    const findLastPayYear = getLastPayYear === null ? currentDate.getFullYear() : parseInt(getLastPayYear);
    console.log("findLastPayMonth", findLastPayMonth)
    // console.log("findLastPayYear", findLastPayYear)
    // console.log("MonthIndex", month.indexOf(findLastPayMonth))
    const findAllMonths = () => {
      var months = [];
      var lastMonth = month.indexOf(findLastPayMonth) 
      for(let i=0; i<=lastMonth; i++){
        months.push({
          val: month[i]
        })
      }
      return months
    }
    const getAllMonths = findAllMonths()
    const allMonthsArr = [...new Set(getAllMonths.map(x => x.val))];
    console.log("allMonthsArr", allMonthsArr)
    
    const presentMonth = new Date().getMonth(); //Current Month
    const lastPaymentMonth = getLastPayMonth === null ? 
                month[findLastPayMonth] : month.indexOf(findLastPayMonth)  //Last Payment Month
    //const previousMonth = presentMonth === 0 ? 11 : (presentMonth - 1); //Previous Month

    // console.log("presentMonth", presentMonth)
    console.log("lastPaymentMonth", lastPaymentMonth)
    //console.log("previousMonth", previousMonth)

    const payrollRange = presentMonth === lastPaymentMonth ? 
                        inRange 
                        : 
                        presentMonth === (lastPaymentMonth + 1) ?
                        prevToPresRange
                        : null;
    // console.log("payrollRange", payrollRange)
    
    useEffect(() => {
      getCompensation(group_id)
      .then(resp => setCompensation(resp.data))
    }, []);

    useEffect(() => {
      getBookLeave(group_id)
      .then(resp => setBookLeaves(resp.data))
    }, []);

    useEffect(() => {
      getEmployee(group_id)
      .then(resp => setEmployees(resp.data))
    }, []);

    useEffect(() => {
      getPayroll(group_id)
      .then(resp => setPayroll(resp.data))
    }, []);

    const RedirectToDetails = (id) => {
      props.history.push(`${process.env.PUBLIC_URL}/project/project-details`);
    }

    const numberOfDays = (year, month) => {
      var d = new Date(year, month, 0);
      return d.getDate();
    }

    const curMonth = new Date().getMonth()
    //const payrollMonth = inRange && findLastPayMonth === month[curMonth] ? month[curMonth + 1] : null; //Payroll Month
    const payrollMonth = payrollRange && presentMonth === lastPaymentMonth ? 
      month[curMonth-1] 
      : payrollRange && presentMonth === (lastPaymentMonth + 1) ?
      month[curMonth] 
      : null; //Payroll Month
    console.log("payrollMonth", payrollMonth)
    const completedMonths = [...new Set(filterCompensation.map(x => x.last_pay_month))]; //All Completed Months
    const completedYears = [...new Set(filterCompensation.map(x => x.last_pay_year))]; //All Completed Years
    
    // const bkleaves = bookLeaves.filter(x=> x.emp_id === 1 && x.type === 'Loss of Pay' && x.status === 'Approved')
    // const fgi = bkleaves ? bkleaves.filter(x=>new Date(x.end_date).getMonth() === month.indexOf('February')) : null
    // console.log("fgi", fgi)

    const runPayroll = async (n, i) => {
      //console.log("runPayroll", i)
      const passMonth = i === 0 ? month[0] : month[i]; //Get Passed Month
      const getPrevMonth = i === 0 ? month[11] : month[i-1]; //Get Previous Month
      const cyear = new Date().getFullYear();
      const cmonths = i;
      const actualDays = numberOfDays(cyear, cmonths) // Actual Days
      const lossPay = 1;

      const zzz = employees.filter(x => x.job_status === 'ACTIVE'); //Find Active Employees
      const abcd = [...new Set(zzz.map(x => x.id))]; // Get their IDs

      const getCompData = getLastPayMonth === null ? 
          compensation.filter(x=> x.last_pay_month === null && abcd.includes(x.emp_id)) 
          : 
          compensation.filter(x=> x.last_pay_month === getPrevMonth && abcd.includes(x.emp_id))
      
      let compData = [];
      await getCompData.forEach((item, j) => {
        const bkleaves = bookLeaves.filter(x=> x.emp_id === item.emp_id && x.type === 'Loss of Pay'
                       && x.status === 'Approved' && new Date(x.end_date).getMonth() === cmonths)
        const totalLOP = bkleaves.length > 0 ? bkleaves.reduce((a,b) => a + parseInt(b.total), 0) : 0;
        // console.log("totalLOP", totalLOP)
        const aaa = employees.find(x=>x.id === item.emp_id)
        const date_join = aaa && aaa.doj;
        const joinDays = new Date(date_join).getDate();
        const joinMonth = new Date(date_join).getMonth();
        const joinYear = new Date(date_join).getFullYear();
        const workDays = joinMonth === cmonths && joinYear === cyear ? actualDays - joinDays : actualDays
        
        compData.push({ 
          emp_id: item.emp_id,
          emp_name: item.emp_name, 
          category: item.category, 
          component: item.component, 
          monthly: parseFloat(item.monthly),
          month: n,
          year: cyear,
          lop: totalLOP,
          tot_days: workDays,
          net_salary: parseFloat(item.monthly) * (workDays - totalLOP) / actualDays
        })
      });
      console.log("compData", compData)
      const data = {
        items: compData,
        user_id: user.id,
        group_id: group_id
      }
      // postPayroll(data)
      // .then(resp => {
      //   console.log("response", resp.data)
      //   var responseData = resp.data.items
      //   toast.success("Data added successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
      //   props.history.push(`${process.env.PUBLIC_URL}/payrolladdition`, {responseData})
      // })

      props.history.push(`${process.env.PUBLIC_URL}/payrollsteps`, {compData, passMonth, assessmentYear})
    }

    const numCompletedMonths = month.filter(x=> completedMonths.includes(x) && completedYears.includes(selectedYear.toString()))
    
    return (
        <Fragment>
        <Breadcrumb parent="Payroll" title="Payroll Processing"/> 
        <Container fluid={true}>
            <Row>
              <Col md="12" className="project-list">
                <Card>
                  <Row>
                    <Col sm="6">
                      <Nav tabs className="border-tab">
                        <NavItem><NavLink className={activeTab === "1" ? "active" : ''} onClick={() => setActiveTab("1")}><Info/>{"Payroll"}</NavLink></NavItem>
                        <NavItem><NavLink className={activeTab === "2" ? "active" : ''} onClick={() => setActiveTab("2")}><CheckCircle/>{"Completed"}</NavLink></NavItem>
                        <NavItem><NavLink className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}><Target/>{"Disabled"}</NavLink></NavItem>
                      </Nav>
                    </Col>
                    <Col sm="6">
                      {/* <div className="text-right">
                        <FormGroup className="mb-0 mr-0"></FormGroup>
                        <Link className="btn btn-primary" to={`${process.env.PUBLIC_URL}/project/new-project`}> <PlusCircle/>{CreateNewProject}</Link>
                      </div> */}
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col sm="12">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12" lg={12}>
                            <div className="table-responsive">
                              <table className="table table-sm table-bordered table-hover">
                                <tbody className="text-center">
                                  <th>
                                    <h5>
                                    <label className="col-form-label">Select Year: </label>
                                    <select 
                                      defaultValue={assessmentYear} 
                                      onChange={(e) => setAssessmentYear(e.target.value)}
                                      className="ml-1 text-success"
                                      style={{width: '20%', height: '100%'}}
                                    >
                                      <option value={curYear}>{curYear}</option>
                                      <option value={curYear - 1}>{curYear - 1}</option>
                                      <option value={curYear - 2}>{curYear - 2}</option>
                                      <option value={curYear - 3}>{curYear - 3}</option>
                                    </select>
                                    </h5>
                                  </th>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                        {month.map((item,i) => {
                          const curMonth = new Date().getMonth()
                          const prevMonth = curMonth === 0 ? 11 : curMonth - 1;
                          //const checkTrue = month[curMonth] === item && getLastPayMonth === item;
                          //const checkMonth = month[curMonth] === item && inRange;
                          const checkYear = findLastPayYear === selectedYear;
                          const checkMonth = item === month[curMonth] && findLastPayMonth === month[curMonth - 1] && inRange;
                          // const checkCompleted = allMonthsArr.includes(item)
                          console.log("curMonth", curMonth)
                          console.log("prevMonth", prevMonth)
                          return(   
                            <Col sm="4" className="mt-4" key={i}>
                            { allMonthsArr.includes(item) && checkYear? 
                              <div className="card card-body">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <h6 className="f-w-600">{item}</h6>
                                  </div>
                                  <div className="col-xl-12 text-right ">
                                    <button className="btn btn-pill btn-warning text-right f-w-600 disabled">
                                      Completed
                                    </button>
                                  </div>
                                </div>
                              </div>
                           : (i === curMonth || i === prevMonth) && checkYear && !allMonthsArr.includes(item) ?
                              <div className="card card-body bg-secondary">
                               <div className="row">
                                 <div className="col-xl-12">
                                  <h6 className="f-w-600">{item}</h6>
                                 </div>
                                 <div className="col-xl-12 text-right ">
                                  <button className="btn btn-pill btn-warning text-right f-w-600" style={{cursor: "pointer"}} onClick={() => runPayroll(item, i)}>
                                    Start Payroll
                                  </button>
                                 </div>
                               </div>
                              </div>
                             :
                              <div className="card card-body bg-light text-dark">
                               <h6 className="f-w-600">{item}</h6>
                              </div>
                            }
                            </Col>
                          );
                         })
                        } 
                          {/* <Col sm="4" className="mt-4" key={i}>
                            <div className="project-box">
                              <span className={`badge ${item.badge === "Done" ? 'badge-success' : 'badge-primary'}`}>{item.badge}</span>
                              <h6 className="f-w-600" onClick={() => RedirectToDetails(item.id)}>{item.title}</h6>
                              <div className="media">
                                <img className="img-20 mr-1 rounded-circle" src={require(`../../../assets/images/${item.img}`)} alt=""/>
                                <div className="media-body">
                                  <p>{item.sites}</p>
                                </div>
                              </div>
                              <p>{item.desc}</p>
                              <Row className="details">
                                <Col xs="6"><span>{"Issues"} </span></Col>
                                <Col xs="6" className={item.badge === "Done" ? 'text-success' : 'text-primary'}>{item.issue}</Col>
                                <Col xs="6"> <span>{"Resolved"}</span></Col>
                                <Col xs="6" className={item.badge === "Done" ? 'text-success' : 'text-primary'}>{item.resolved}</Col>
                                <Col xs="6"> <span>{Comment}</span></Col>
                                <Col xs="6" className={item.badge === "Done" ? 'text-success' : 'text-primary'}>{item.comment}</Col>
                              </Row>
                              <div className="customers">
                                <ul>
                                  <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img1}`)} alt=""/></li>
                                  <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img2}`)} alt=""/></li>
                                  <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img3}`)} alt=""/></li>
                                  <li className="d-inline-block ml-2">
                                    <p className="f-12">{`+${item.like} More`}</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="project-status mt-4">
                                <div className="media mb-0">
                                  <p>{item.progress}% </p>
                                  <div className="media-body text-right"><span>{"Done"}</span></div>
                                </div>
                                {item.progress === "100" ? 
                                  <Progress className="sm-progress-bar"  color="success" value={item.progress} style={{height:"5px"}}  />
                                  :
                                  <Progress className="sm-progress-bar"  striped color="primary" value={item.progress} style={{height:"5px"}}  />
                                }
                              </div>
                            </div>
                          </Col> */}
                            
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                      { numCompletedMonths.length > 0 ?
                      <Row>
                        { allMonthsArr.map((item,i) => {    
                          return(
                            <Col sm="4" className="mt-4" key={i}>
                             <div className="card card-body">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <h6 className="f-w-600">{item}</h6>
                                  </div>
                                  <div className="col-xl-12 text-right ">
                                    <button className="btn btn-pill btn-warning text-right f-w-600 disabled">
                                      Completed
                                    </button>
                                  </div>
                                </div>
                             </div>
                            </Col>
                          );
                         })
                        }
                      </Row>
                      :
                      <Row>
                        <Col sm="12" className="mt-4">
                          <div>
                            <h4 className="text-center text-danger">Note: Payroll yet to run for the selected year</h4>
                          </div>
                        </Col>
                      </Row>
                      }
                      </TabPane>
                      <TabPane tabId="3">
                      <Row>
                        {/* {month.filter(x=>x != findLastPayMonth && x != payrollMonth).map((item,i) => { */}
                        {month.filter(x=> !allMonthsArr.includes(x)).map((item,i) => {
                          return(
                            <Col sm="4" className="mt-4" key={i}>
                             <div className="card card-body">
                              <h6 className="f-w-600">{item}</h6>
                             </div>
                            </Col>
                          );
                         })
                        }
                        {/* {doneProject.map((item,i) =>
                          <Col sm="4" className="mt-4"  key={i}>
                            <div className="project-box">
                              <span className="badge badge-success">{item.badge}</span>
                              <h6 className="f-w-600">{item.title}</h6>
                              <div className="media">
                                <img className="img-20 mr-1 rounded-circle" src={require(`../../../assets/images/${item.img}`)} alt=""/>
                                <div className="media-body">
                                  <p>{item.sites}</p>
                                </div>
                              </div>
                              <p>{item.desc}</p>
                              <Row className="details">
                                <Col xs="6"><span>{"Issues"} </span></Col>
                                <Col xs="6" className="text-success">{item.issue}</Col>
                                <Col xs="6"> <span>{"Resolved"}</span></Col>
                                <Col xs="6" className="text-success">{item.resolved}</Col>
                                <Col xs="6"> <span>{Comment}</span></Col>
                                <Col xs="6" className="text-success">{item.comment}</Col>
                              </Row>
                              <div className="customers">
                                <ul>
                                  <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img1}`)} alt=""/></li>
                                  <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img2}`)} alt=""/></li>
                                  <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img3}`)} alt=""/></li>
                                  <li className="d-inline-block ml-2">
                                    <p className="f-12">{item.like}</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="project-status mt-4">
                                <div className="media mb-0">
                                  <p>{item.progress}% </p>
                                  <div className="media-body text-right"><span>{"Done"}</span></div>
                                </div>
                                {item.progress === "100" ? 
                                  <Progress className="sm-progress-bar" color="success" value={item.progress} style={{height:"5px"}}  />
                                  :
                                  <Progress className="sm-progress-bar" striped color="success" value={item.progress} style={{height:"5px"}}  />
                                }
                              </div>
                            </div>
                          </Col>
                           )} */}
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>     
        </Fragment>
    );
}

export default PayrollProcessing;