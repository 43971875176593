import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import {Autocomplete} from '@material-ui/lab';
import { TextField, FormHelperText, Typography } from '@material-ui/core';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import PrintRTS from './PrintRTS';
import { getVendorForm, getPorders, getAllUser, sendRTSEmail, postMaterialRejects, 
         getMaterialRejects, postRTSWhatsApp } from '../../actions/asyncActions';

const MaterialReject = () => {
 
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const [vendorForm, setVendors] = useState([]);
 const approvedVendors = vendorForm.filter(x=>x.status === 'Approved');
 const [vendor_val, setVendorVal] = useState(null);
 const selectedVendor = vendor_val && vendor_val.id;
 const selectedVendorName = vendor_val && vendor_val.supname; //Vendor Name
 const selectedVendorEmail = vendor_val && vendor_val.email; //Vendor Email address
 const vendorMobile = vendor_val && vendor_val.mobileno;  //Vendor Mobile number
 const [porders, setPOrders] = useState([]);
 const [porder_val, setPorderVal] = useState(null);
 const selectedPO = porder_val && porder_val.id;              // PO Number
 const selectedPoUser = porder_val && porder_val.user_id;
 const filterPo = porders.filter(x=>x.vendor_id === selectedVendor);

 const [users, setUsers] = useState([]);
 const filterPoUser = users.find(x=>x.id === selectedPoUser);
 const generatedPoUser = filterPoUser && filterPoUser.firstname;  // Generated PO username
 const generatedPoUserEmail = filterPoUser && filterPoUser.email; // Generated PO User Email

 const rejectionReason = [{name:'Incorrect Item'}, {name:'Wrong Invoice/DO'}, {name:'Damage/Dirty'}];
 const [reasonVal, setReasonVal] = useState(null);
 const selectedReason = reasonVal && reasonVal.name;           // Reason
 const [description, setDescription] = useState(); 			   // Description
 const [mobile_number, setMobileNo] = useState();

 const [materialrejects, setMaterialRejects] = useState([]);
 const [currentmreject, setCurrentMreject] = useState([]);

 const findMaterialRejects = materialrejects.find(x=> x.poNumber === selectedPO);
 console.log("findMaterialRejects", findMaterialRejects);

 const [printmodalopen, setPrintModalOpen] = useState(false);

 useEffect( () => {
  getVendorForm(group_id)
   .then(resp => setVendors(resp.data))
 }, []);

 useEffect( () => {
  getPorders(group_id)
   .then(resp => setPOrders(resp.data))
 }, []);

 useEffect( () => {
  getAllUser(group_id)
   .then(resp => setUsers(resp.data))
 }, []);

 useEffect( () => {
  getMaterialRejects(group_id)
   .then(resp => setMaterialRejects(resp.data))
 }, []);

 const handleSendEmail = () => {

 	const data = {
 	  vendorName: selectedVendorName,
 	  vendorEmail: selectedVendorEmail,
 	  poNumber: selectedPO,
 	  generatedPoUser: generatedPoUser,
 	  generatedPoUserEmail: generatedPoUserEmail,
 	  reason: selectedReason,
 	  description: description,
 	  user_id: user.id,
 	  group_id: group_id
 	}

  //mobileno: vendorMobile
  const whatsappData = {
    vendorName: selectedVendorName,
    mobileno: mobile_number,
    poNumber: selectedPO,
    reason: selectedReason,
    description: description
  }

  if(!mobile_number){
    toast.error("Please enter the mobile number", {position: toast.POSITION.TOP_CENTER, autoClose:3000});
  }
  else {
    postRTSWhatsApp(whatsappData)
    postMaterialRejects(data)
    .then(resp => {
      setCurrentMreject(resp.data)
      getMaterialRejects(group_id)
      if(selectedVendorEmail == '' || generatedPoUserEmail == '') {
        } 
        else {
          sendRTSEmail(data)
          .then(resp => {
            setPrintModalOpen(!printmodalopen)
            toast.success("Email & WhatsApp sent successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
          })
        }	
    })
  }
 }

 const openRTSModal = () => {
 	setPrintModalOpen(!printmodalopen)
 	printRTS();
  setVendorVal(null)
  setPorderVal(null)
  setReasonVal(null)
  setDescription('')
  setMobileNo('')
 }

 const printRTS = () => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(`<html><head><title>${document.title}</title>
     <style>
     @page { margin: 0 }
      body { margin: 0 }
      .sheet {
      margin: 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      page-break-after: always;
      }

      /* Padding area */
      .sheet.padding-5mm { padding: 5mm }

      /* For screen preview */
      @media screen {
      body { background: #e0e0e0 }
        .sheet {
         background: white;
         box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
         margin: 2mm;
       }
       table {
          border-collapse: collapse;
      }
       tr.border_bottom td {
        border-bottom:1pt solid black;
      }
      table th, table td {
      border:1px solid #000;
        padding: 0.5em;
      }
      }
      @media print {
          html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
          font-size: 9pt !important;
               }
            }

     </style>
     `);
   mywindow.document.write('</head><body>');
   mywindow.document.write(document.getElementById('printreceipt').innerHTML);
   mywindow.document.write('</body></html>');

   mywindow.document.close(); // necessary for IE >= 10
   mywindow.focus(); // necessary for IE >= 10*/

   mywindow.print();
   mywindow.close();

   return true;
  }

	return (
	 <Fragment>
	  	<div style={{display:"none"}}>
          <PrintRTS
          	rtsData = {currentmreject}
          />
        </div>
	    <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-12">
                <div className="card">
                {/*
                  <div className="card-header bg-secondary">
                    <h5>Material Rejection Form</h5>
                  </div>
                */}
                  <div className="card-body">
                   <div className="row">
                     <div className="offset-xl-2 col-xl-4 col-lg-6 col-md-6">
                      <Autocomplete
                        freeSolo
                        options={approvedVendors}
                        getOptionLabel={option => option.supname}
                        value={vendor_val}                      
                        onChange={(event, newValue) => {
                          setVendorVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Vendor" margin="normal" variant="outlined" helperText="Please select Vendor" fullWidth />
                        )}
                      />
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6">
                      <Autocomplete 
                        freeSolo
                        options={filterPo}
                        getOptionLabel={option => ('PO-000').concat(option.id.toString())}
                        value={porder_val}                      
                        onChange={(event, newValue) => {
                          setPorderVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="PO" margin="normal" variant="outlined" helperText="Please select PO" fullWidth />
                        )}
                      />
                     </div>
                     { selectedVendor && selectedPO && findMaterialRejects ?
                       <div className="offset-xl-2 m-t-15">
                         <h4 className="text-danger">Note:RTS is already created for this PO</h4>
                       </div > 
                       : selectedVendor && selectedPO ?
                      <>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                         <TextField label="Vendor's Email id" variant="outlined" value={selectedVendorEmail} disabled fullWidth/>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                         <TextField label="PO Generated by" variant="outlined" value={generatedPoUser} disabled fullWidth/>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                         <TextField label="PO Generated User's Email" variant="outlined" value={generatedPoUserEmail} disabled fullWidth/>
                        </div>
                        <div className="offset-xl-3 col-xl-6 col-lg-6 col-md-6 m-t-20">
  	                     <TextField type="number" label="Mobile Number" variant="outlined" value={mobile_number} 
  	                      onChange={(e) => setMobileNo(e.target.value)} fullWidth/>
  	                    </div>
	                   	  <div className="offset-xl-3 col-xl-6 col-lg-6 col-md-6">
  	                     <Autocomplete 
  	                        freeSolo
  	                        options={rejectionReason}
  	                        getOptionLabel={option => option.name}
  	                        value={reasonVal}                      
  	                        onChange={(event, newValue) => {
  	                          setReasonVal(newValue);
  	                        }}            
  	                        renderInput={params => (
  	                          <TextField {...params} label="Reason" margin="normal" variant="outlined" 
  	                           helperText="Please mention Rejection Reason" fullWidth />
  	                        )}
  	                      />
	                      </div>
  	                    <div className="offset-xl-3 col-xl-6 col-lg-6 col-md-6">
  	                     <TextField multiline label="Description" variant="outlined" value={description} 
  	                      onChange={(e) => setDescription(e.target.value)} rows={4} fullWidth/>
  	                    </div>
  	                    <div className="offset-xl-4 col-xl-3">
  	                     <button onClick={handleSendEmail} className="btn btn-pill btn-secondary-gradien btn-lg m-t-15">
  	                      {/*Create RTS and send email */}
                          Send via WhatsApp
  	                     </button>
  	                    </div>
	                    </>
                      :
                      null
      			     }
                   </div>
                  </div>
    			</div>
    		 </div>
    		</div>          
        </div>

        {/* Block Vendor Email Model */}
  <Modal isOpen={printmodalopen} toggle={handleSendEmail} className="modal-body" centered={true}>
        <ModalBody>
            <Typography gutterBottom>
              Do you want to print the RTS details?
            </Typography>
        </ModalBody>
        <ModalFooter>
        <Button className="btn btn-pill btn-success active" onClick={ () => openRTSModal()}>Yes</Button>
        <Button className="btn btn-pill btn-secondary active" onClick={ () => setPrintModalOpen(!printmodalopen)}>No</Button>
    </ModalFooter>
  </Modal>
	 </Fragment>
	);
}

export default MaterialReject;