import React, { Fragment } from 'react';
import man from '../../../assets/images/dashboard/user1.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';

const UserPanel = () => {
    const url = '';
    const user = JSON.parse(localStorage.getItem("m_users"));
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#" />
                {/*
                    <div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div>
                */}
                </div>
                <h6 className="mt-3 f-14">{user.firstname}</h6>
                <p>{user.role}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;