import React, { Fragment, useState, useEffect }  from 'react';
import { TextField, Input, InputAdornment, Checkbox } from '@material-ui/core';
import Breadcrumb from '../../../components/common/breadcrumb';
import { UncontrolledTooltip } from 'reactstrap';
import { postCompensation, editEmployee, getEmployee, getCompensation, editCompensation } from '../../../actions/asyncActions';
import { toast } from 'react-toastify';

const ViewCompensation = (props) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const userRole = user.role;
    const group_id = user.group_id;
    const location = props.location;
    const employeeId = location.state ? location.state.id : null;
    const filterCompensation = location.state ? location.state.filterCompensation : null;
    console.log("filterCompensation", filterCompensation)
    const [employees, setEmployees] = useState([])
    const [values, setValues] = useState([]);
    console.log("values", values)
    const [pfChecked, setPfChecked] = useState(false)
    const [esiChecked, setEsiChecked] = useState(false)
    const [insuranceChecked, setInsuranceChecked] = useState(false)
    const [gratuityChecked, setGratuityChecked] = useState(false)

    useEffect( () => {
      getCompensation(group_id)
      .then(resp => {
        const ss = resp.data;
        const as = ss.filter(x => x.emp_id === employeeId);
        setValues(as)
      })
    }, []);

    const handleChange = (event, i) => {
        if(userRole === 'Employee'){
            
        }
        else {
        const tempQtys = [...values];
        tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
        setValues(tempQtys);
        }
        
    };

    
    const handleCheckPF = (event) => {
        setPfChecked(event.target.checked)
    }

    const handleCheckESI = (event) => {
        setEsiChecked(event.target.checked)
    }

    const handleCheckInsurance = (event) => {
        setInsuranceChecked(event.target.checked)
    }

    const handleCheckGratuity = (event) => {
        setGratuityChecked(event.target.checked)
    }

    // const basicMonth = (String(values.basic).indexOf('%') !== -1) ? 
    //             parseFloat(values.gross) * parseFloat(values.basic) / 100 
    //             :
    //             0;
    const basicMonth = parseFloat(values.basic);
    const basicAnnum = basicMonth ? basicMonth * 12 : 0; 

    const dearnessAnnum =  values.dearness ? parseFloat(values.dearness) * 12 : 0;

    const totalBasicMonth = (basicMonth || 0) + (parseFloat(values.dearness) || 0);
    const totalBasicAnnum = totalBasicMonth * 12;

    // const hraMonth = (String(values.hra).indexOf('%') !== -1) ? 
    //             parseFloat(values.gross) * parseFloat(values.hra) / 100 
    //             :
    //             0;
    const hraMonth = parseFloat(values.hra);
    const hraAnnum = hraMonth ? hraMonth * 12 : 0;
    const conveyanceAnnum = values.conveyance ? parseFloat(values.conveyance) * 12 : 0;
    const medicalAnnum = values.medical ? parseFloat(values.medical) * 12 : 0;
    const specialAnnum = values.special ? parseFloat(values.special) * 12 : 0;

    const employeePfMonth = parseFloat(values.employee_pf) || 0;
    const employeePfAnnum = employeePfMonth ? employeePfMonth * 12 : 0;

    const professionTaxMonth = parseFloat(values.p_tax) || 0;
    const professionTaxAnnum = professionTaxMonth ? professionTaxMonth * 12 : 0;

    const totalDeductionsMonth = (employeePfMonth || 0) + (professionTaxMonth || 0);
    const totalDeductionsAnnum = totalDeductionsMonth * 12;

    const salMonth = (basicMonth || 0) + (parseFloat(values.dearness) || 0) + (hraMonth || 0) + 
      (parseFloat(values.conveyance) || 0 ) + (parseFloat(values.medical) || 0) + 
      (parseFloat(values.special) || 0);

      const totalSalMonth = (basicMonth || 0) + (parseFloat(values.dearness) || 0) + (hraMonth || 0) + 
      (parseFloat(values.conveyance) || 0 ) + (parseFloat(values.medical) || 0) + 
      (parseFloat(values.special) || 0) - (totalDeductionsMonth || 0)
                          
    const totalSalAnnum = totalSalMonth * 12;

    //const employerPfMonth = pfChecked ? parseFloat((13.5 / 100) *  totalBasicMonth) : 0
    const employerPfMonth = parseFloat(values.employer_pf) || 0;
    const roundPfMonth = Math.round(employerPfMonth)
    const employerPfAnnum = employerPfMonth * 12;
    const roundPfAnnum = Math.round(employerPfAnnum)
    //const employerEsiMonth = esiChecked ? parseFloat((4 / 100) *  totalBasicMonth) : 0
    const employerEsiMonth = parseFloat(values.employer_esi) || 0;
    const employerEsiAnnum = employerEsiMonth * 12;
    //const employerGratMonth = gratuityChecked ? parseFloat((1 / 100) *  totalBasicMonth) : 0
    const employerGratMonth = parseFloat(values.employer_gratuity) || 0;
    const employerGratAnnum = employerGratMonth * 12;

    const insuranceAnnum = values.employer_insurance ? parseFloat(values.employer_insurance) * 12 : 0;

    const totalCTCMon = (parseFloat(values.gross) || 0) + (employerPfMonth || 0) + (employerEsiMonth || 0) + 
        (employerGratMonth || 0) + (parseFloat(values.employer_insurance) || 0);

    const totalCTCMonth = Math.round(totalCTCMon)
    const totalCTCAnnum = (totalCTCMonth * 12) + (parseFloat(values.variable_pay) || 0)

    const handleSubmit = () => {
        const pff = pfChecked ? employerPfMonth : 'NA'
        const esi = esiChecked ? employerEsiMonth : 'NA'
        const grat = gratuityChecked ? employerGratMonth : 'NA'
        const insur = insuranceChecked ? parseFloat(values.employer_insurance) : 'NA'

        const data = {
         emp_id: employeeId,
         gross: values.gross,
         basic: basicMonth,
         dearness: values.dearness,
         hra: hraMonth,
         conveyance: values.conveyance,
         medical: values.medical,
         special: values.special,
         variable_pay: values.variable_pay,
         bonus: 0,
         employee_pf: employeePfMonth,
         p_tax: professionTaxMonth,
         employer_pf: pff,
         employer_esi: esi,
         employer_gratuity: grat,
         employer_insurance: insur,
         ctc: totalCTCMonth,
         ctc_annum: totalCTCAnnum,
         user_id: user.id,
         group_id: group_id
        }
        const empData = {
          id: employeeId,
          compensation: 'yes'
        }
        if(parseFloat(values.gross) === (totalSalMonth + totalDeductionsMonth)) {
          postCompensation(data)
          .then(resp => {
            if(resp.data.emp_id === employeeId){
              editEmployee(empData)
              .then(resp => {
                getEmployee(group_id)
                .then(resp => {
                  setEmployees(resp.data)
                  toast.success("Compensation added successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
                  props.history.push(`${process.env.PUBLIC_URL}/employeemaster`)
                })
              })
            }
            else{
              toast.error("Something went wrong!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
            }            
          })
        }
        else {
          toast.error("Total Salary is not matching with the Gross, please check!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
        }
    }

    const handleUpdate = () => {
      let compensationData = [];
      values.forEach((item, i) => {
        compensationData.push({
          id: item.id,
          monthly: item.monthly,
          yearly: parseFloat(item.monthly) * 12
        })
      })
      const data = {
        items: compensationData,
        user_id: user.id,
        group_id: user.group_id
      }
      //Edit Compensation
      editCompensation(data)
      .then(resp => {
        toast.success("Data updated successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
        props.history.push(`${process.env.PUBLIC_URL}/employeemaster`)
      })
    }

    return(
      <Fragment>
        <Breadcrumb title="Compensation" parent="Employee" />
        <div className="container-fluid">
	      <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                 <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="table-primary">
                      <tr className="text-center">
                       <th className="text-center">
                        Components in salary
                       </th>
                       <th className="text-center">
                        Per month
                       </th>
                       <th className="text-center">
                        Per annum
                       </th>
                      </tr>
                    </thead>
                    <tbody>
                    {filterCompensation && filterCompensation.map((n,i) => {
                       const yearlyAmount = values[i] ? parseFloat(values[i].monthly) * 12 : null
                        return(
                          <tr key={i} className="text-center">
                            <td>{n.component}</td>
                            <td>
                              <TextField
                                key={i}
                                name="monthly"
                                type="number"
                                value = {values[i] ? values[i].monthly: null}
                                onChange={(e) => handleChange(e,i)}
                                variant="outlined"
                                margin="dense" 
                              />
                            </td>
                            <td>{yearlyAmount}</td>
                          </tr>
                        );
                      })
                    }
                    </tbody>
                  </table>
                 </div>
                </div>
                { userRole === 'Employee' ?
                null
                :
                <div className="card-footer text-center">
                  <button className="mb-0 btn btn-success" onClick={() => handleUpdate()}>
                    Update Compensation
                  </button>
                </div>
                }
              </div>
            </div>
          </div>
         </div>
        </Fragment>
    )
}

export default ViewCompensation;