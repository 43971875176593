import React, {Fragment, useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { TextField, Radio, RadioGroup,FormControl, FormControlLabel } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Autocomplete} from '@material-ui/lab';
import { toast } from 'react-toastify';
import { Document, Page, pdfjs } from 'react-pdf';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Logo from '../../../assets/images/nulogo.png';
import {getEmployee, getDepartment, getDesignation, editEmployee, 
    aadharUpload, panUpload, bankUpload, pfUpload, passportUpload, experienceUpload, 
    relieveUpload, tenthUpload, twelthUpload, degreeUpload, contractUpload,} from '../../../actions/asyncActions';
 
const ViewEmployee = (props) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const userRole = user.role;
    const location = props.location;
    const empId = location.state ? location.state.Id : null;
    const [empmodal, setEmpModal] = useState(false);

    
    const [currentEmp, setCurrentEmp] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [department, setDepartment] = useState([]);
    const [designation, setDesignation] = useState([]);

    const [department_val, setDepartmentVal] = useState(null);
    const selectedDepartment = department_val ? department_val && department_val.id : null;

    const filterDesignation = designation.filter(x=>x.dept_id === selectedDepartment)

    const [designation_val, setDesignationVal] = useState(null)
    const selectedDesignation = designation_val ? designation_val && designation_val.id : null

    const [radioVal, setRadio] = useState({ gender: '' });
    const [dob, setDob] = useState(new Date());
    const [finance_start, setFinanceStart ] = useState(new Date());
    const [finance_end, setFinanceEnd ] = useState(new Date());

    const [panImage, setPan] = useState();
    const [adharImage, setAadhar] = useState();
    const [passportImage, setPassPortImage] = useState();
    console.log("passportImage", passportImage)
    const [pfImage, setPf] = useState();
    const [expLetter, setExperience] = useState();
    const [relLetter, setRelieving] = useState();
    const [contract_file, setContract] = useState();
    const [bankImage, setBank] = useState();
    const [tenth_file, setTenth] = useState();
    const [twelth_file, setTwelth] = useState();
    const [degree_file, setDegree] = useState();

    const handleUploadPAN = async (event) => {
        setPan(document.getElementById('file_pan').files[0]);
    }
    
    const handleUploadAadhar = async (event) => {
        setAadhar(document.getElementById('file_aadhar').files[0]);
    }

    const handleUploadPassPort = async (event) => {
        setPassPortImage(document.getElementById('fileppt').files[0]);
    }

    const handleUploadPf = async (event) => {
        setPf(document.getElementById('filepf').files[0]);
    }

    const handleUploadExperience = async (event) => {
        setExperience(document.getElementById('filesexp').files[0]);
    }

    const handleUploadRelieving = async (event) => {
        setRelieving(document.getElementById('filesrel').files[0]);
    }

    const handleUploadContract = async (event) => {
        setContract(document.getElementById('files_contract').files[0]);
    }

    const handleUploadBank = async (event) => {
        setBank(document.getElementById('filess').files[0]);
    }

    const handleUploadTenth = async (event) => {
        setTenth(document.getElementById('file_tenth').files[0]);
    }

    const handleUploadTwelth = async (event) => {
        setTwelth(document.getElementById('file_twelth').files[0]);
    }

    const handleUploadDegree = async (event) => {
        setDegree(document.getElementById('file_degree').files[0]);
    }

    useEffect( () => {
        getEmployee(group_id)
        .then(resp => {
         const ss = resp.data;
         const as = ss.find(x => x.id === empId);
         setCurrentEmp(as);
         setRadio({gender: as.gender})
         setDob(new Date(as.dob))
         setFinanceStart(new Date(as.finance_start))
         setFinanceEnd(new Date(as.finance_end))
        })
    }, []);

    useEffect( () => {
        getEmployee(group_id)
        .then(resp => setEmployees(resp.data))
    }, []);

    useEffect( () => {
        getDepartment(group_id)
        .then(resp => setDepartment(resp.data))
    }, []);
  
    useEffect( () => {
        getDesignation(group_id)
        .then(resp => setDesignation(resp.data))
    }, []);

    var isEmployeeApproved = userRole === 'Employee' && currentEmp.status === 'Approved'

    const handleChange = event => {
        if(userRole === 'Employee' && currentEmp.status === 'Approved'){
            
        }
        else {
            setCurrentEmp({
                ...currentEmp,
                [event.target.name]: event.target.value
            });
        }
    };

    const handleRadioChange = event => {
        if(userRole === 'Employee' && currentEmp.status === 'Approved'){
            
        }
        else {
            setRadio({
            ...radioVal,
            [event.target.name]: event.target.value
            });
        }
    };

    const handleBack = () => {
        props.history.push(`${process.env.PUBLIC_URL}/employeemaster`)
    }

    const handleEmpBack = () => {
        props.history.push(`${process.env.PUBLIC_URL}/employeeform`)
    }

    const filterEmployee = employees.filter(x=>x.id !== empId);
	const [employee_val, setEmployeeVal] = useState(null)
    const selectedManager = employee_val ? employee_val && employee_val.id : null
    
    const [doj, setDoj] = useState("")

    const approveEmployee = () => {
        setEmpModal(!empmodal)
        setDepartmentVal(null)
        setDesignationVal(null)
        setEmployeeVal(null)
        setDoj("")
    }
    
    const handleUpdate = async (event) => {
        // Add Cashfree for Bank details Validation
        var statuss = userRole === 'Employee' ? 'Submitted' : 'Approved';
        var jobStatus = userRole === 'Employee' ? 'INACTIVE' : 'ACTIVE';

        const formData = new FormData()
        formData.append( 'file', adharImage )
        const aadhar = adharImage ? await aadharUpload(formData) : null;
        const aadharpath = aadhar ? "/" + aadhar.data.filename : currentEmp.aadhar;

        const formData2 = new FormData();
        formData2.append( 'file', panImage );
        const pan = panImage ? await panUpload(formData2) : null;
        const panpath = pan ? "/" + pan.data.filename : currentEmp.pan;

        const formData3 = new FormData();
        formData3.append( 'file', bankImage );
        const bank = bankImage ? await bankUpload(formData3) : null;
        const bankpath = bank ? "/" + bank.data.filename : currentEmp.bank;

        const formData4 = new FormData();
        formData4.append( 'file', pfImage );
        const pff = pfImage ? await pfUpload(formData4): null;
        const pfpath = pff ? "/" + pff.data.filename : currentEmp.pff;

        const formData5 = new FormData();
        formData4.append( 'file', passportImage );
        const pass = passportImage ? await passportUpload(formData4) : null;
        const pptpath = pass ? pass.data.filename : null;
        const passportpath = pptpath ? "/Data/Employee/" + pptpath : currentEmp.passport;
        
        const formData6 = new FormData();
        formData6.append( 'file', expLetter );
        const exp = expLetter ? await experienceUpload(formData6) : null
        const expath = exp ? exp.data.filename : null;
        const exppath = expath ? "/Data/Employee/" + expath : currentEmp.exp_letter;

        const formData7 = new FormData();
        formData7.append( 'file', relLetter );
        const rel = relLetter ? await relieveUpload(formData7) : null;
        const repath = rel ? rel.data.filename : null;
        const relpath = repath ? "/Data/Employee/" + repath : currentEmp.rel_letter;

        const formData8 = new FormData();
        formData8.append( 'file', tenth_file );
        const tenth = tenth_file ? await tenthUpload(formData8) : null
        const tenpath = tenth ? tenth.data.filename : null
        const tenthpath = tenpath ? "/Data/Employee/" + tenpath : currentEmp.tenth;

        const formData9 = new FormData();
        formData9.append( 'file', twelth_file );
        const twelth = twelth_file ? await twelthUpload(formData9) : null;
        const twelpath = twelth ? twelth.data.filename : null
        const twelthpath = twelpath ? "/Data/Employee/" + twelpath : currentEmp.twelth;

        const formData10 = new FormData();
        formData10.append( 'file', degree_file );
        const degree = degree_file ? await degreeUpload(formData10) : null;
        const degpath = degree ? degree.data.filename : null;
        const degreepath = degpath ? "/Data/Employee/" + degpath : currentEmp.degree;

        const formData11 = new FormData();
        formData11.append( 'file', contract_file );
        const contract = contract_file ? await contractUpload(formData11) : null;
        const contpath = contract ? contract.data.filename : null;
        const contractpath = contpath ? "/Data/Employee/" + contpath : currentEmp.contract;

        const userdata = {
            id: empId,  
            name: currentEmp.name,
            father: currentEmp.father,
            phone: currentEmp.phone,
            email: currentEmp.email,
            gender: radioVal.gender,
            dob: dob,
            emer_name: currentEmp.emer_name,
            emer_num: currentEmp.emer_num,
            address: currentEmp.address,
            tenth: tenthpath,
            twelth: twelthpath,
            degree: degreepath,
            //panno: currentEmp.panno,
            pan: panpath,
            //aadharno: currentEmp.aadharno,
            aadhar: aadharpath,
            //passportno: currentEmp.passport,
            passport: passportpath,  
            //pfno: currentEmp.pfno,
            pff: pfpath,
            company_name: currentEmp.company_name,
            period: currentEmp.period,
            com_designation: currentEmp.com_designation,
            exp_letter: exppath,
            rel_letter: relpath,
            contract: contractpath,
            bankname: currentEmp.bankname,
            accno: currentEmp.accno,
            accname:currentEmp.accname,
            ifsc:currentEmp.ifsc,
            // bank: bankpath,
            med_leave: currentEmp.med_leave,
            cas_leave: currentEmp.cas_leave,
            maternity_leave: currentEmp.maternity_leave,
            finance_start: finance_start,
            finance_end: finance_end,
            status: statuss,
            dept_id: selectedDepartment,
            desg_id: selectedDesignation,
            doj: doj,
            job_status: jobStatus,
			mgr_id: selectedManager,
            user_id: user.id,
            group_id: group_id
        }
        console.log("userdata", userdata)
        if((userRole === 'Employee' && currentEmp.status === 'Submitted') || (userRole !== 'Employee' && currentEmp.status === 'Approved')){
            editEmployee(userdata)
        	.then(resp => {
                toast.success("Details updated Successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
                if(userRole === 'Employee'){
                    props.history.push(`${process.env.PUBLIC_URL}/employeeform`)
                }
                else{
                    props.history.push(`${process.env.PUBLIC_URL}/employeemaster`)
                }
            })
            .catch(err => {
			    console.log(err)
			})
        }
        else if(userRole !== 'Employee' && !selectedDepartment || !selectedDesignation){
			toast.error("Department or Designation is not selected!!!",  {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
        }
        else if(userRole !== 'Employee' && !doj){
            toast.error("Date of Joining is not selected!!!",  {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
        }
		else{
			editEmployee(userdata)
			.then(resp => {
                toast.success("Employee Approved Successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
                props.history.push(`${process.env.PUBLIC_URL}/employeemaster`)
			})
			.catch(err => {
				console.log(err)
			})
		}
    }

    const [viewdocument, setViewDocument] = useState("");
    //const docpath = `http://172.105.41.149:9004/${viewdocument}`;
    var doctype = viewdocument ? (viewdocument || '').split('.')[1] : null;
    const docpath = `http://localhost:9004${viewdocument}`;
    console.log("docpath", docpath)
    const [docmodal, setDocModal] = useState(false);

    const [numPages, setNumPage] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPage(numPages);
    }

    const goToPrevPage = () => {
        setPageNumber(pageNumber - 1);
    }
    const goToNextPage = () => {
        setPageNumber(pageNumber + 1);
    }

    const openDocument = (data) => {
        setViewDocument(data);
        setDocModal(!docmodal);
    }

    const closeDocument = () => {
        setViewDocument("");
        setDocModal(!docmodal);
    }
   

    return(
      <Fragment>
        <div className="container-fluid">
          <div className="row">
           <div className="col-xl-12">
            <div className="card">
                <div className="card-header">
                 <div className="row">
                  <div className="col-xl-6">
                    <h5 className="text-left">
                        <img height="40px" src={Logo} alt="Company Logo" />
                    </h5>
                  </div>
                  <div className="col-xl-6">
                    <h5 className="text-right">Nu-Pie MCS Pvt Ltd</h5>
                  </div>
                 </div>
                </div>
                <div className="card-body">
                <h4 className="text-left">Employee Information</h4>
                <div className="table-responsive">
                    <table className="table">
                        <thead className="table-primary">
                            <tr>
                              <th className="text-center">Personal Information</th> 
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="container-fluid">
                  <div className="form-row m-t-15">
                    <div className="col-xl-6">
                        <label className="col-form-label">Name of the Employee:</label>
                        <TextField className="form-control" id="name" name="name" value={currentEmp.name} 
                        onChange={handleChange} fullWidth autoFocus />
                    </div>
                    <div className="col-xl-6">
                        <label className="col-form-label">Name of the Father:</label>
                        <TextField className="form-control" id="father" name="father" value={currentEmp.father} 
                        onChange={handleChange} fullWidth  />
                    </div>
                    <div className="col-xl-6">
                        <label className="col-form-label">Mobile Number:</label>
                        <TextField type="number" className="form-control" id="phone" name="phone" value={currentEmp.phone} 
                        onChange={handleChange} fullWidth />
                    </div>
                    <div className="col-xl-6">
                        <label className="col-form-label">Email ID:</label>
                        <TextField className="form-control" id="email" name="email" value={currentEmp.email} 
                        onChange={handleChange} fullWidth  />
                    </div>
                    <div className="col-xl-6">
                        <FormControl component="fieldset">
                            <label className="col-form-label">Gender:</label>
                            <RadioGroup row aria-label="gender" name="gender" value={radioVal.gender} onChange={handleRadioChange} fullWidth>
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="others" control={<Radio />} label="Transgender" />
                            </RadioGroup>
                        </FormControl>  
                    </div>
                    <div className="col-xl-6">
                        <label className="col-form-label">Date of Birth:</label>
                        <DatePicker className="form-control digits text-success"
                            selected={dob}
                            value={dob}
                            onChange={(date) =>
                                isEmployeeApproved ? 
                                dob :
                                setDob(date) 
                            }
                            dateFormat= "dd-MMM-yyyy"
                            placeholderText="Select your DOB"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>
                    <div className="col-xl-6">
                        <label className="col-form-label">Emergency Name:</label>
                        <TextField className="form-control" id="emer_name" name="emer_name" value={currentEmp.emer_name} 
                        onChange={handleChange} fullWidth />
                    </div>
                    <div className="col-xl-6">
                        <label className="col-form-label">Emergency Number:</label>
                        <TextField type="number" className="form-control" id="emer_num" name="emer_num" value={currentEmp.emer_num} 
                        onChange={handleChange} fullWidth />
                    </div>
                    <div className="col-xl-12">
                        <label className="col-form-label">Address:</label>
                        <TextField className="form-control" id="address" name="address" value={currentEmp.address} 
                        onChange={handleChange} fullWidth />
                    </div>
                 </div>
                </div>
                <div className="table-responsive m-t-15">
                    <table className="table">
                        <thead className="table-primary">
                            <tr>
                              <th className="text-center">Other Details</th> 
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="container-fluid">
                    <div className="form-row">
                        <div className="col-xl-6 col-md-12">
                            <div className="new-users activity m-t-15">
                                <div className="media">
                                    <div className="gradient-round m-r-30 m-t-20"></div>
                                    <div className="media-body">
                                    <h6 className="mb-1 f-w-700">{`PAN: ${currentEmp.panno}`}</h6>
                                    <p>{"PAN Number"}</p>
                                    </div>
                                    <span className="pull-right">
                                        <button className="btn btn-pill btn-outline-info m-r-30" onClick={() => openDocument(currentEmp.pan)}>
                                            View
                                        </button>
                                    </span>
                                    { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                                        null
                                    :
                                    <span className="pull-right">
                                        <input type="file" name="pan" id="file_pan" style={{display: "none"}} onChange={handleUploadPAN} />
                                        <label for="file_pan" className="btn btn-pill btn-outline-info m-r-30">
                                            Edit
                                        </label>
                                    </span>
                                    }
                                </div>
                                <div className="media">
                                    <div className="gradient-round m-r-30 m-t-20"><i className="fa fa-file-zip-o"></i></div>
                                    <div className="media-body">
                                    <h6 className="mb-1 f-w-700">{`AADHAR: ${currentEmp.aadharno}`}</h6>
                                    <p>{"AADHAR Number"}</p>
                                    </div>
                                    <span className="pull-right">
                                        <button className="btn btn-pill btn-outline-info m-r-30" onClick={() => openDocument(currentEmp.aadhar)}>
                                            View
                                        </button>
                                    </span>
                                    { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                                        null
                                    :
                                    <span className="pull-right">
                                        <input type="file" name="aadhar" id="file_aadhar" style={{display: "none"}} onChange={handleUploadAadhar} />
                                        <label for="file_aadhar" className="btn btn-pill btn-outline-info m-r-30">
                                            Edit
                                        </label>
                                    </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="new-users activity m-t-15">
                                <div className="media">
                                    <div className="gradient-round m-r-30 m-t-20"><i className="fa fa-file-zip-o"></i></div>
                                    <div className="media-body">
                                    <h6 className="mb-1 f-w-700">{`Passport: ${currentEmp.passportno}`}</h6>
                                    <p>{"Passport Number"}</p>
                                    </div>
                                    <span className="pull-right">
                                        <button className="btn btn-pill btn-outline-info m-r-30" onClick={() => openDocument(currentEmp.passport)}>
                                            View
                                        </button>
                                    </span>
                                    { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                                        null
                                    :
                                    <span className="pull-right">
                                        <input type="file" name="passport" id="fileppt" style={{display: "none"}} onChange={handleUploadPassPort} />
                                        <label for="fileppt" className="btn btn-pill btn-outline-info m-r-30">
                                            Edit
                                        </label>
                                    </span>
                                    }
                                </div>
                                <div className="media">
                                    <div className="gradient-round m-r-30 m-t-20"><i className="fa fa-file-zip-o"></i></div>
                                    <div className="media-body">
                                    <h6 className="mb-1 f-w-700">{`PF: ${currentEmp.pfno}`}</h6>
                                    <p>{"Provident Fund Number"}</p>
                                    </div>
                                    <span className="pull-right">
                                        <button className="btn btn-pill btn-outline-info m-r-30" onClick={() => openDocument(currentEmp.pff)}>
                                            View
                                        </button>
                                    </span>
                                    { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                                        null
                                    :
                                    <span className="pull-right">
                                        <input type="file" name="pff" id="filepf" style={{display: "none"}} onChange={handleUploadPf} />
                                        <label for="filepf" className="btn btn-pill btn-outline-info m-r-30">
                                            Edit
                                        </label>
                                    </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive m-t-15">
                    <table className="table">
                        <thead className="table-primary">
                            <tr>
                              <th className="text-center">Education Details</th> 
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="container-fluid">
                    <div className="form-row">
                        <div className="offset-xl-3 col-xl-6">
                            <div className="new-users activity m-t-15">
                                <div className="media">
                                    <div className="gradient-round m-r-30 m-t-20 text-center"><i className="fa fa-file-zip-o"></i></div>
                                    <div className="media-body">
                                    <h6 className="mb-1 f-w-700">{"X Certificate:"}</h6>
                                    <p>{"Class 10th Certificate"}</p>
                                    </div>
                                    <span className="pull-right">
                                        <button className="btn btn-pill btn-outline-info m-r-30" onClick={() => openDocument(currentEmp.tenth)}>
                                            View
                                        </button>
                                    </span>
                                    { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                                        null
                                    :
                                    <span className="pull-right">
                                        <input type="file" name="tenth" id="file_tenth" style={{display: "none"}} onChange={handleUploadTenth} />
                                        <label for="file_tenth" className="btn btn-pill btn-outline-info m-r-30">
                                            Edit
                                        </label>
                                    </span>
                                    }
                                </div>
                                <div className="media">
                                    <div className="gradient-round m-r-30 m-t-20 text-center"><i className="fa fa-file-zip-o"></i></div>
                                    <div className="media-body">
                                    <h6 className="mb-1 f-w-700">{"XII Certificate:"}</h6>
                                    <p>{"Class 12th Certificate"}</p>
                                    </div>
                                    <span className="pull-right">
                                        <button className="btn btn-pill btn-outline-info m-r-30" onClick={() => openDocument(currentEmp.twelth)}>
                                            View
                                        </button>
                                    </span>
                                    { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                                        null
                                    :
                                    <span className="pull-right">
                                        <input type="file" name="twelth" id="file_twelth" style={{display: "none"}} onChange={handleUploadTwelth} />
                                        <label for="file_twelth" className="btn btn-pill btn-outline-info m-r-30">
                                            Edit
                                        </label>
                                    </span>
                                    }
                                </div>
                                <div className="media">
                                    <div className="gradient-round m-r-30 m-t-20 text-center"><i className="fa fa-file-zip-o"></i></div>
                                    <div className="media-body">
                                    <h6 className="mb-1 f-w-700">{"Degree Certificate:"}</h6>
                                    <p>{"Degree Certificate"}</p>
                                    </div>
                                    <span className="pull-right">
                                        <button className="btn btn-pill btn-outline-info m-r-30" onClick={() => openDocument(currentEmp.degree)}>
                                            View
                                        </button>
                                    </span>
                                    { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                                        null
                                    :
                                    <span className="pull-right">
                                        <input type="file" name="degree" id="file_degree" style={{display: "none"}} onChange={handleUploadDegree} />
                                        <label for="file_degree" className="btn btn-pill btn-outline-info m-r-30">
                                            Edit
                                        </label>
                                    </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive m-t-15">
                    <table className="table">
                        <thead className="table-primary">
                            <tr>
                              <th className="text-center">Previous Job Experience</th> 
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="container-fluid">
                    <div className="form-row m-t-15">
                        <div className="col-xl-12">
                            <div className="form-row">
                                <div className="col-xl-6">
                                    <div className="col-xl-12">
                                        <label className="col-form-label">Name of the Company:</label>
                                        <TextField className="form-control" id="company_name" name="company_name" 
                                        value={currentEmp.company_name} onChange={handleChange} fullWidth />
                                    </div>
                                    <div className="col-xl-12 m-t-20">
                                        <label className="col-form-label">Period(in years):</label>
                                        <TextField className="form-control" id="period" name="period" value={currentEmp.period} 
                                        onChange={handleChange} fullWidth />
                                    </div>
                                    <div className="col-xl-12 m-t-20">
                                        <label className="col-form-label">Designation:</label>
                                        <TextField className="form-control" id="com_designation" name="com_designation" value={currentEmp.com_designation} 
                                        onChange={handleChange} fullWidth />
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="new-users activity">
                                        <div className="media">
                                            <div className="gradient-round m-r-30 m-t-20 text-center"><i className="fa fa-file-zip-o"></i></div>
                                            <div className="media-body">
                                            <h6 className="mb-1 f-w-700">{"Experience:"}</h6>
                                            <p>{"Experience Letter"}</p>
                                            </div>
                                            <span className="pull-right">
                                                <button className="btn btn-pill btn-outline-info m-r-30" onClick={() => openDocument(currentEmp.exp_letter)}>
                                                    View
                                                </button>
                                            </span>
                                            { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                                                null
                                            :
                                            <span className="pull-right">
                                                <input type="file" name="exp_letter" id="filesexp" style={{display: "none"}} onChange={handleUploadExperience} />
                                                <label for="filesexp" className="btn btn-pill btn-outline-info m-r-30">
                                                    Edit
                                                </label>
                                            </span>
                                            }
                                        </div>
                                        <div className="media">
                                            <div className="gradient-round m-r-30 m-t-20 text-center"><i className="fa fa-file-zip-o"></i></div>
                                            <div className="media-body">
                                            <h6 className="mb-1 f-w-700">{"Relieving:"}</h6>
                                            <p>{"Relieving Letter"}</p>
                                            </div>
                                            <span className="pull-right">
                                                <button className="btn btn-pill btn-outline-info m-r-30" onClick={() => openDocument(currentEmp.rel_letter)}>
                                                    View
                                                </button>
                                            </span>
                                            { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                                                null
                                            :
                                            <span className="pull-right">
                                                <input type="file" name="rel_letter" id="filesrel" style={{display: "none"}} onChange={handleUploadRelieving} />
                                                <label for="filesrel" className="btn btn-pill btn-outline-info m-r-30">
                                                    Edit
                                                </label>
                                            </span>
                                            }
                                        </div>
                                        <div className="media">
                                            <div className="gradient-round m-r-30 m-t-20 text-center"><i className="fa fa-file-zip-o"></i></div>
                                            <div className="media-body">
                                            <h6 className="mb-1 f-w-700">{"Contract:"}</h6>
                                            <p>{"Employee Contract"}</p>
                                            </div>
                                            <span className="pull-right">
                                                <button className="btn btn-pill btn-outline-info m-r-30" onClick={() => openDocument(currentEmp.contract)}>
                                                    View
                                                </button>
                                            </span>
                                            { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                                                null
                                            :
                                            <span className="pull-right">
                                                <input type="file" name="contract" id="files_contract" style={{display: "none"}} onChange={handleUploadContract} />
                                                <label for="files_contract" className="btn btn-pill btn-outline-info m-r-30">
                                                    Edit
                                                </label>
                                            </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { userRole !== 'Employee' ?
                <div className="table-responsive m-t-15">
                    <table className="table">
                        <thead className="table-primary">
                            <tr>
                              <th className="text-center">Leave Balance and Financial Year</th> 
                            </tr>
                        </thead>
                    </table>
                </div>
                : null
                }
                { userRole !== 'Employee' ?
                <div className="container-fluid">
                    <div className="form-row m-t-15">
                        <div className="col-xl-4">
                            <label className="col-form-label">Medical Leave:</label>
                            <TextField className="form-control" id="med_leave" name="med_leave" type="number"
                            value={currentEmp.med_leave} onChange={handleChange} fullWidth />
                        </div>
                        <div className="col-xl-4">
                            <label className="col-form-label">Casual Leave:</label>
                            <TextField className="form-control" id="cas_leave" type="number"
                                name="cas_leave" value={currentEmp.cas_leave} 
                                onChange={handleChange} fullWidth />
                        </div>
                        <div className="col-xl-4">
                            <label className="col-form-label">Maternity/Paternity:</label>
                            <TextField className="form-control" id="maternity_leave" type="number"
                                name="maternity_leave" value={currentEmp.maternity_leave} 
                                onChange={handleChange} fullWidth />
                        </div>
                        <div className="col-xl-6 m-t-20">
                            <label className="col-form-label">Financial Start:</label>
                            <DatePicker className="form-control digits text-success"
                                selected={finance_start}
                                value={finance_start}
                                onChange={date => setFinanceStart(date)}
                                dateFormat="MMM/yyyy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                showTwoColumnMonthYearPicker
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Select Start of the year"
                            />
                        </div>
                        <div className="col-xl-6 m-t-20">
                            <label className="col-form-label">Financial End:</label>
                            <DatePicker className="form-control digits text-success"
                                selected={finance_end}
                                value={finance_end}
                                onChange={date => setFinanceEnd(date)}
                                dateFormat="MMM/yyyy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                showTwoColumnMonthYearPicker
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Select End of the year"
                            />
                        </div>
                    </div>
                </div>
                : null
                }
                <div className="table-responsive m-t-15">
                    <table className="table">
                        <thead className="table-primary">
                            <tr>
                              <th className="text-center">Bank Details</th> 
                            </tr>
                        </thead>
                    </table>
                </div>
                { userRole === 'Employee' && currentEmp.status === 'Approved' ?
                <div className="container-fluid">
                    <div className="form-row m-t-15">
                        <div className="col-xl-12">
                            <label className="col-form-label">Name of the Bank:</label>
                            <TextField className="form-control" id="bankname" name="bankname" 
                            value={currentEmp.bankname} fullWidth />
                        </div>
                        <div className="col-xl-4">
                            <label className="col-form-label">Account No:</label>
                            <TextField className="form-control" id="accno" name="accno" type="number"
                            value={currentEmp.accno} fullWidth />
                        </div>
                        <div className="col-xl-4">
                            <label className="col-form-label">Account holder name::</label>
                            <TextField className="form-control" id="accname" name="accname" 
                            value={currentEmp.accname} fullWidth />
                        </div>
                        <div className="col-xl-4">
                            <label className="col-form-label">IFSC:</label>
                            <TextField className="form-control" id="ifsc" name="ifsc" 
                            value={currentEmp.ifsc} fullWidth />
                        </div>
                    </div>
                </div>    
                :
                <div className="container-fluid">
                    <div className="form-row m-t-15">
                        <div className="col-xl-12">
                            <label className="col-form-label">Name of the Bank:</label>
                            <TextField className="form-control" id="bankname" name="bankname" 
                             value={currentEmp.bankname} onChange={handleChange} fullWidth />
                        </div>
                        <div className="col-xl-4">
                            <label className="col-form-label">Account No:</label>
                            <TextField className="form-control" id="accno" name="accno" type="number"
                             value={currentEmp.accno} onChange={handleChange} fullWidth />
                        </div>
                        <div className="col-xl-4">
                            <label className="col-form-label">Account holder name::</label>
                            <TextField className="form-control" id="accname" name="accname" 
                             value={currentEmp.accname} onChange={handleChange} fullWidth />
                        </div>
                        <div className="col-xl-4">
                            <label className="col-form-label">IFSC:</label>
                            <TextField className="form-control" id="ifsc" name="ifsc" 
                             value={currentEmp.ifsc} onChange={handleChange} fullWidth />
                        </div>
                    </div>
                </div>
                }
             </div>
             {  userRole === 'Employee' && currentEmp.status === 'Submitted' ?
             <div className="card-footer text-center">
                <div className="row">
                  <div className="col-xl-6 col-md-6">
                    <button className="btn btn-pill btn-info btn-lg" onClick={handleEmpBack}>
                        Back
                    </button>
                  </div>
                  <div className="col-xl-6 col-md-6">
                    <button className="btn btn-pill btn-success btn-lg" onClick={() => handleUpdate()}>
                        Update
                    </button>
                  </div>
                </div>
             </div>
             :  userRole !== 'Employee' && currentEmp.status === 'Approved' ?
             <div className="card-footer text-center">
                <div className="row">
                  <div className="col-xl-6 col-md-6">
                    <button className="btn btn-pill btn-info btn-lg" onClick={handleBack}>
                        Back
                    </button>
                  </div>
                  <div className="col-xl-6 col-md-6">
                    <button className="btn btn-pill btn-success btn-lg" onClick={() => handleUpdate()}>
                        Update
                    </button>
                  </div>
                </div>
             </div>
             : userRole === 'Employee' && currentEmp.status === 'Approved' ?
             <div className="card-footer text-center">
                <div className="row">
                  <div className="col-xl-12">
                    <button className="btn btn-pill btn-info btn-lg" onClick={handleEmpBack}>
                        Back
                    </button>
                  </div>
                </div>
             </div>
             : 
             <div className="card-footer text-center">
                <div className="row">
                  <div className="col-xl-6 col-md-6">
                    <button className="btn btn-pill btn-info btn-lg" onClick={handleBack}>
                        Back
                    </button>
                  </div>
                  <div className="col-xl-6 col-md-6">
                    <button className="btn btn-pill btn-success btn-lg" onClick={() => approveEmployee()}>
                        Update and Approve
                    </button>
                  </div>
                </div>
             </div>
             }
            </div>
           </div>      
          </div>
        </div>

        {/* < !-- Using Form Modal for Approving Employee --> */}
			<Modal isOpen={empmodal} toggle={approveEmployee} className="modal-body" centered={true}>
              <ModalHeader className="text-center" toggle={approveEmployee}>
                Approve Employee
              </ModalHeader>   
              <ModalBody className="text-center">
                  <form>
                    <div className="form-row">
                      <div className="col-md-12">
						<Autocomplete
							freeSolo
							options={department}
							getOptionLabel={option => option.name}
							value={department_val}                      
							onChange={(event, newValue) => {
							setDepartmentVal(newValue);
							}}            
							renderInput={params => (
							<TextField {...params} label="Department" margin="dense" variant="outlined"
							 helperText="Please Select Department" fullWidth />
							)}
                      	/> 
                      </div>
                      <div className="col-md-12">
					    <Autocomplete
							freeSolo
							options={filterDesignation}
							getOptionLabel={option => option.name}
							value={designation_val}                      
							onChange={(event, newValue) => {
							setDesignationVal(newValue);
							}}            
							renderInput={params => (
							<TextField {...params} label="Designation" margin="dense" variant="outlined" 
							 helperText="Please Select Designation" fullWidth />
							)}
                      	/>
                      </div>
					  <div className="col-md-12">
					    <Autocomplete
							freeSolo
							options={filterEmployee}
							getOptionLabel={option => option.name}
							value={employee_val}                      
							onChange={(event, newValue) => {
							setEmployeeVal(newValue);
							}}            
							renderInput={params => (
							<TextField {...params} label="Manager" margin="dense" variant="outlined" 
							 helperText="Please Select Manager" fullWidth />
							)}
                      	/>
                      </div>
                      <div className="col-md-12">
					  	<DatePicker className="form-control digits text-success"
							selected={doj}
							value={doj}
							onChange={(date) => setDoj(date)}
							dateFormat= "dd-MMM-yyyy"
							placeholderText="Select Date of Joining"
							showMonthDropdown
							dropdownMode="select"
						/>
					  </div>
                    </div>
                  </form>
              </ModalBody>
              <ModalFooter>
				<button className="btn btn-danger" onClick={approveEmployee}>Cancel</button>
				<button className="btn btn-primary" onClick={handleUpdate}>Submit</button>
              </ModalFooter>
          </Modal>

          {/* View Employee Documents */}
          <Modal isOpen={docmodal} toggle={closeDocument} size="lg" style={{width:"1280", height:"840", textAlign: "center"}}>
            <ModalHeader toggle={closeDocument}>View Documents</ModalHeader>
            <ModalBody>
                {doctype === 'png' || doctype === 'jpg' || doctype === 'jpeg' ?
                    <PinchZoomPan>
                        <img alt='Test Image' src={`${docpath}`} />
                    </PinchZoomPan>
                : doctype === 'pdf' ?
                    <div>
                     { numPages < pageNumber ?
                        <nav>
                        <button className="btn btn-light" onClick={goToPrevPage}>Prev</button>&nbsp;&nbsp;
                        <button className="btn btn-secondary" onClick={goToNextPage}>Next</button>
                        </nav>
                        :
                        <>
                        </>
                    }   
                        <Document
                            file={`${docpath}`}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} />
                        </Document>
                        <p>Page {pageNumber} of {numPages}</p>
                    </div>
                :
                    <h3 className="text-center text-danger"> No Document Found</h3>
                }
            </ModalBody>
         </Modal>
      </Fragment>
    );
}

export default withRouter(ViewEmployee);