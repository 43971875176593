import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TextField, FormHelperText } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { getVendorForm, getPorders, getPOItems, getGrinItems, postGrin, postGrLogs, 
		 getIndInvoice, getIndInvoiceItems, updateInvItems } from '../../actions/asyncActions';

const ThreeWayMatch = () => {
 
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const [vendorForm, setVendors] = useState([]);
 const approvedVendors = vendorForm.filter(x=>x.status === 'Approved');
 const [vendor_val, setVendorVal] = useState(null);
 const selectedVendor = vendor_val && vendor_val.id;
 const aaa = vendor_val && vendor_val.supname;
 let bbb = aaa;
 var selectedVendorName = (bbb || '').toUpperCase();
 const [porders, setPOrders] = useState([]);
 const filterPo = porders.filter(x=>x.vendor_id === selectedVendor);
 const [porder_val, setPorderVal] = useState(null);
 const selectedPO = porder_val && porder_val.id;
 const selectedPOType = porder_val && porder_val.invType;
 

 const [grinItems, setGrinItems] = useState([]);
 const zzz = [...new Set(grinItems.map(x => x.po_no))];
 console.log("zzz", zzz)

 const [poitems, setPOItems] = useState([]);
 const filter_poitems = poitems.filter(x=>x.po_no === selectedPO && zzz.includes(x.po_no));
 console.log("filter_poitems", filter_poitems)
 const [errors, setError] = React.useState({});

 const [indInvoice, setIndInvoice] = useState([]);
 const [invoiceItems, setInvoiceItems] = useState([]);
 const filterInvoiceItems = invoiceItems.filter(x=>x.vendor_id === selectedVendorName && 
 							x.status === 'Approved' && x.invType === selectedPOType);

 useEffect( () => {
  getVendorForm(group_id)
   .then(resp => setVendors(resp.data))
 }, []);

 useEffect( () => {
  getPorders(group_id)
   .then(resp => setPOrders(resp.data))
 }, []);

 useEffect( () => {
  getPOItems(group_id)
   .then(resp => setPOItems(resp.data))
 }, []);

 useEffect( () => {
  getGrinItems(group_id)
   .then(resp => setGrinItems(resp.data))
 }, []);

 useEffect( () => {
  getIndInvoice(group_id)
   .then(resp => setIndInvoice(resp.data))
 }, []);

 useEffect( () => {
  getIndInvoiceItems(group_id)
   .then(resp => setInvoiceItems(resp.data))
 }, []);

  const [matchmodal, setMatchModal] = useState();
  const [approvedQty, setApprovedQty] = useState();

  const [matchId, setMatchId] = useState();
  const openMatchRow = (n, inQty, aprQty) => {
  	setMatchId(n.id);
  	setApprovedQty(aprQty);
  	setMatchModal(!matchmodal);
  	setError('');
  }

  const [poId, setPOId] = useState();
  const filterPoItems = poitems.find(x=>x.id === poId);
  const filterAmount = filterPoItems && filterPoItems.amount;
  const complete3Way = (n) => {
  	setPOId(n.id)
  }

  const [addedQty, setAddedQty] = useState(0);
  const [setId, setSelectedId] = useState();

  const [addItems, setAddItems] = useState([]);
  const addQty = addItems.reduce((a, b) => a + parseInt(b.qty), 0);
  console.log("addItems", addItems)
  console.log("addQty", addQty)

  const addRow = (n) => {
  	//setSelectedId(n.id);
  	setAddedQty(addedQty + parseInt(n.qty));
  	const data ={
  		id: n.id,
  		invQty: addedQty + parseInt(n.qty),
  		status: 'GRN Matched',
  		matchId: matchId
  	}
  	updateInvItems(data)
  	.then(resp => {
  	  getIndInvoiceItems(group_id)
  	  .then(resp => setInvoiceItems(resp.data))
  	  getGrinItems(group_id)
  	  .then(resp => setGrinItems(resp.data))
      setVendorVal(null);
      setPorderVal(null);
  	})
  }

  const validateForm = () => {
    const errors = [];
    if(addQty > approvedQty){
      errors.addItems = "Added Qty is more than Approved Qty";
     }
     return errors;
  }

  const handleSubmit = () => {

  	const errors = validateForm();
     if(Object.keys(errors).length) {
      setError(errors);
      return;
     }

  	let fieldsComp = addItems;
    const arr = [];
    fieldsComp.forEach(item => {
      arr.push({ 
        id:item.id, qty: item.qty, invQty:item.qty
      })
    });
    const data = {
    	items: arr,
    	matchId: matchId,
    	addedQty: addQty
    }
    if(addItems.length > 0){
      updateInvItems(data)
      .then(resp => {
        getIndInvoiceItems(group_id)
        .then(resp => setInvoiceItems(resp.data))
        getGrinItems(group_id)
        .then(resp => setGrinItems(resp.data))
        setVendorVal('');
        setAddItems([]);
        setMatchModal(!matchmodal)
      })
    }
    else{
      toast.warn("Please select one or more item for the match!!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
    }
    
  }

	return (
      <Fragment>
         <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-12">
                <div className="ribbon-wrapper card">
                  <div className="card-body">
                   <div className="ribbon ribbon-bookmark ribbon-primary">3 Way Matching</div>
                   <div className="row">
                     <div className="col-md-4 col-xl-4">
                      <Autocomplete
                        freeSolo
                        options={approvedVendors}
                        getOptionLabel={option => option.supname}
                        value={vendor_val}                      
                        onChange={(event, newValue) => {
                          setVendorVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Vendor" margin="normal" variant="outlined" helperText="Please select Vendor" fullWidth />
                        )}
                      />
                     </div>
                     <div className="col-md-4 col-xl-4">
                      <Autocomplete 
                        freeSolo
                        options={filterPo}
                        getOptionLabel={option => ('PO-000').concat(option.id.toString())}
                        value={porder_val}                      
                        onChange={(event, newValue) => {
                          setPorderVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="PO" margin="normal" variant="outlined" helperText="Please select PO" fullWidth />
                        )}
                      />
                     </div>
                     {selectedPOType ?
	                    <div className="col-md-4 col-xl-4">
	                      <TextField label="PO Type" margin="normal" variant="outlined" value={selectedPOType} fullWidth />
	                    </div>
	                    : 
	                    null
                	 }
                   {selectedVendor && selectedPO ?
                    (filter_poitems.length === 0 || filter_poitems.length === undefined ) ? 
                     <div className="row">
                      <div className="col-xl-12 m-l-15">
                       <h5 className="text-danger">Note:Items in the selected PO are yet to be received for the 3 way match </h5>
                      </div>
                     </div> 

                      :

                      <div className="row">
                      <div className="col-xl-12">
                       <div className="table-responsive">
                          <table className="table table-sm table-bordered table-hover">
                              <thead className="table-info">
                                  <tr>
                                    <th scope="col">PO.No</th>
                                    <th scope="col">ItemName</th>
                                    <th scope="col">PO Qty</th>
                                    <th scope="col">GRN Qty</th>
                                    <th scope="col">Inv Qty</th>
                                    <th scope="col">PO Amt</th>
                                    <th scope="col">Inv Amt</th>
                                    <th scope="col">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {filter_poitems.map((n, i)=> {
                                    {/*const filterGrnItems = grinItems.find(x=>x.po_itemno === n.id );
                                	 const aprQty = filterGrnItems && filterGrnItems.apr_qty || [];
		                             const inQty = filterGrnItems.reduce((a, b) => a + parseInt(b.invQty), 0);
		                             const inQty = filterGrnItems && filterGrnItems.invQty || [];
                                    */}
                                    const filterGrnItems = grinItems.filter(x=>x.po_itemno === n.id && x.status === 'Approved');
                                    const filterGrnItems2 = grinItems.find(x=>x.po_itemno === n.id && x.status === 'Approved');
                                    console.log("filterGrnItems", filterGrnItems)
                                    console.log("filterGrnItems2", filterGrnItems2)
                                    const aprQty = filterGrnItems.reduce((a, b) => a + b.apr_qty, 0);
                                    const aaa = filterGrnItems2 && filterGrnItems2.invQty;
                                    console.log("aaa", aaa)
                                    {/*
                                     var inQty = 0;
                                     var getInQty = filterGrnItems && filterGrnItems[0].invQty; 
                                    */}
                                    const inQty = aaa; 
                                    console.log("inQty", inQty);
                                    const bbb = invoiceItems.filter(x=>x.po_itemno === n.id); 
                                    const invAmt = bbb.reduce((a, b) => a + parseInt(b.amount), 0);
                                    return (
                                      <tr>
                                       <td>{('PO-000').concat(n.po_no.toString())}</td>
                                       <td>{n.part_name}</td>
                                       <td>{n.qty}</td>
                                       <td>{aprQty}</td>
                                       <td>{filterGrnItems2 ? aaa : 0}</td>
                                       <td>{n.total}</td>
                                       <td>{invAmt}</td>
                                       {aprQty === 0 ?
                                        null 
                                        : aprQty != inQty ?
	                                       <td>
	                                        <button className="btn btn-info btn-sm" type="button" 
	                                          onClick={() => openMatchRow(n, inQty, aprQty)}>
	                                          Match
	                                        </button>
	                                       </td>
	                                       : n.total >= invAmt ?
	                                       <td>
	                                        <button className="btn btn-success btn-sm" type="button"
	                                          onClick={()=> complete3Way(n)}>
	                                          Complete 3-Way Match
	                                        </button>
	                                       </td>
	                                       :
	                                       <td>
	                                        <button className="btn btn-success btn-sm disabled" type="button">
	                                          Complete 3-Way Match
	                                        </button>
	                                       </td>
                                   	   }
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                          </table>
                       </div>
                      </div>
                     </div>
                      : null
                    }
                   </div>
		              </div>
                </div>
              </div>
            </div>
         </div> 

     {/* 3 Way Match Modal */}
     		<Modal isOpen={matchmodal} toggle={openMatchRow} size="lg">
             <ModalHeader toggle={openMatchRow}>Match Invoice with GRN(Select Items to Match)</ModalHeader>
              <ModalBody>
                <div className="col-xl-12">
                  {filterInvoiceItems.length > 0 ?
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered table-hover">
                          <thead className="table-info">
                              <tr>
                                <th scope="col">Sl.No</th>
                                <th scope="col">Inv No</th>
                                <th scope="col">PartName</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              {filterInvoiceItems.map((n, i)=> {
                              	const aaa = indInvoice.find(x=>x.id === n.invoice_id);
                              	const invoiceNo = aaa && aaa.invoice_no;
                              	const bbb = setId === n.id;
                                return (
                                  <tr>
                                   <td>{i+1}</td>
                                   <td>{invoiceNo}</td>
                                   <td>{n.description}</td>
                                   <td>{n.qty}</td>
                                   <td>
                                    <input type="checkbox" checked={addItems.includes(n)} 
                                     onChange={(e) => {
                                      const checked = addItems.includes(n)
                                      setAddItems((prev) => 
                                       checked
                                     	? prev.filter((sc) => sc !== n)
                                     	: [...prev, n ]
                                      );
                                     }} 
                                    />
                                    <FormHelperText className="text-danger">{errors.addItems}</FormHelperText>
                                   </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                      </table>
                    </div>
                    :
                    <div>
                      <h3 className="text-danger">No matching items available...</h3>
                    </div>
                  }
                </div>
                {addItems.length > 0 ?
                <div className="col-xl-12 text-center m-t-15">
                  <button className="btn btn-success btn-md" type="button" onClick={handleSubmit}>
                   Match
                  </button>
                </div>
                :
                null
                }
              </ModalBody>
            </Modal>
      </Fragment>
    );
}
export default ThreeWayMatch;