import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { TablePagination, TextField } from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import { getAllUser, editUsers, postUsers, deleteUsers, sendUserEmail } from '../../actions/asyncActions';
import data from './data.json';

const MyUser = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const [person, setPerson] = useState([]);
    const filterPerson = person.filter(x => x.role !== 'superadmin')
    const initialFormState = { id: '', firstname:'', lastname:'', username:'', password:'', email:'', type:'', role:''}
    const [ cu_user, setCurrentUser ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();
    const [values, setValues] = useState({});

    // const department = [{name:'Admin'}, {name:'Vendor'}, {name:'Sales'}, {name:'Project'}, {name:'Procurement'}]
    // const [departmentval, setDepartmentVal] = useState(null);
    // const selectedDepartment = departmentval ? departmentval && departmentval.name : null;

    // const adminRoles = [{name:'Admin'}]
    // const vendorRoles = [{name: 'Vendor'}]
    // const salesRoles = [{name: 'Sales Manager'}, {name: 'Sales Supervisor'}]
    // const projectRoles = [{name: 'Project Manager'}, {name: 'Project Supervisor'}]
    // const procurementRoles = [{name: 'Procurement Manager'}, {name: 'Procurement Supervisor'}]

    const [dept, setDept] = useState(null)
    const [role, setRole] = useState(null)

    const [roleList, setRoleList] = useState([]);

    const filterManager = person.filter(x => x.role === (role ? role.manager : ''));
    const [manager, setManager] = useState([]);
    const selectedManager = manager ? manager && manager.id : null;

    const handleDeptChange = (obj) => {
      setDept(obj)
      setRoleList(obj.role)
      setRole(null)
    }

    const handleRoleChange = (obj) => {
      setRole(obj)
    }

    const elementStyle ={
      border:'solid',
      borderRadius:'10px',
      position:'relative',
      left:'1vh',
      height:'6vh',
      width:'23vh',
      marginTop:'1vh',
      marginBottom:'1vh'
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
     getAllUser(group_id)
     .then(resp => {
      setPerson(resp.data);
     })
    }, []);

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentUser({ ...cu_user, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
     setValues({firstname:'', lastname:'', username:'', password:'', email:'', type:'', role:''})
     setDept(null)
     setRole(null)
     setManager([])
    }

    const editRow = user => {
     setModal(!modal)
     setCurrentUser({ id: user.id, firstname: user.firstname, phone: user.phone, username: user.username, 
       password:user.password, email:user.email,type:user.type, role:user.role })
    }

    const deleteRow = n => {
      const data = {
        id: n.id
      }
      deleteUsers(data)
      .then(resp => {
        getAllUser(group_id)
        .then(resp => setPerson(resp.data))
        toast.error("User deleted successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
      })
    }

    const handleSubmit = () => {
        
      const depart = dept ? dept.department : 'NA';
      const roles = role ? role.name : 'NA';

      const userss = JSON.parse(localStorage.getItem("m_users"));
      const user = {
        firstname: values.firstname,
        phone: values.phone,
        email: values.email,
        username: values.username,
        password: values.password,
        type: values.type,
        department: depart,
        role: roles,
        mgr_id: selectedManager,
        group_id: userss.group_id,
      }
      const emailData = {
        firstname: values.firstname,
        email: values.email,
        username: values.username,
        password: values.password,
      }
      // const errors = validateForm(user);
      // if(Object.keys(errors).length) {
      //   setError(errors);
      //   return;
      // }
      postUsers(user)
      .then(resp => {
          sendUserEmail(emailData)
          .then(resp => {
            getAllUser(user.group_id)
            .then(resp => setPerson(resp.data))
              setAddModal(!addmodal)
              toast.success("User created successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
          })
          
        })
        .catch(err => {
          console.log("Error", err);
      })
   }

  // let userObj = {};
  // userObj = (person || []).sort((a,b) => {
  //   return b.id-a.id;
  // });
  // console.log("userObj", userObj[0]);

    const handleUpdate = (id, data) => {
        const user = JSON.parse(localStorage.getItem("m_users"));
        const store = user && user.group_id;
        const bbb = {
          id: id,
          firstname: data.firstname,
          lastname: data.lastname,
          username: data.username,
          password: data.password,
          email: data.email,
          type: data.type,
          role: data.role
        }
        // const errors = validateForm(data);
        // if(Object.keys(errors).length) {
        //   setError(errors);
        //   return;
        // }
        editUsers(bbb)
        .then(resp => {
              getAllUser(store)
              .then(resp => setPerson(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }

    const [search, setSearch] = useState(null);
    const updateSearch = (event) => {
      setSearch(event.target.value.substr(0,20));
    }

    const filterItems = (p) => {
      if(search) { 
       return p.firstname.toLowerCase().includes(search) || 
        p.username.toLowerCase().includes(search) ||
        p.role.toLowerCase().includes(search);
     }
     return true;
    }

        return (
            <Fragment>
            <Breadcrumb title="All Users" parent="Users" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                           <div className="col-md-2">
                            <input type="text" placeholder="Enter item to be searched" style={elementStyle} value = {search}
                              onChange={updateSearch} />
                           </div>
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Name</th>
                                                  <th scope="col">Email</th>
                                                  <th scope="col">Username</th>
                                                  <th scope="col">Role</th>
                                                  <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {filterPerson.filter(filterItems)
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.firstname}</td>
                                                     <td>{n.email}</td>
                                                     <td>{n.username}</td>
                                                     <td>{n.role}</td>
                                                     <td>
                                                      <button className="btn btn-info btn-xs" type="button" onClick={() => editRow(n)}>
                                                       <i className="icofont icofont-edit"></i>
                                                      </button>&nbsp;
                                                      <button className="btn btn-danger btn-xs" type="button" onClick={() => deleteRow(n)}>
                                                       <i className="fa fa-trash-o"></i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                       <button className="btn btn-info btn-sm" type="button" onClick={() => addRow()}>
                                         Add User
                                        </button>
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={filterPerson.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Add Users --> */}
            <Modal isOpen={addmodal} toggle={addRow} size="lg">
                <ModalHeader toggle={addRow}>Add User</ModalHeader>   
                <ModalBody>
                    <form>
                      <div className="form-row">
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label" htmlFor="message-text">Full Name:</label>
                          <input className="form-control" name="firstname" type="text" placeholder="Full name" 
                            value={values.firstname} onChange={handleChange}/>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label" htmlFor="message-text">Mobile No:</label>
                          <input className="form-control" name="phone" type="number" placeholder="Phone number" 
                            value={values.phone} onChange={handleChange}/>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="col-form-label" htmlFor="message-text">Email:</label>
                          <input className="form-control" name="email" type="email" placeholder="Email" 
                            value={values.email} onChange={handleChange}/>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label" htmlFor="message-text">Username:</label>
                          <input className="form-control" name="username" type="text" placeholder="Username" 
                            value={values.username} onChange={handleChange}/>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label" htmlFor="message-text">Password:</label>
                          <input className="form-control" name="password" type="password" placeholder="Password" 
                            value={values.password} onChange={handleChange}/>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label" htmlFor="message-text">Department:</label>
                          <Autocomplete
                            options={data}
                            getOptionLabel={option => option.department}
                            value={dept}                      
                            onChange={(event, newValue) => {
                              setDept(newValue);
                              setRoleList(newValue ? newValue.role : 0)
                              
                            }}            
                            renderInput={params => (
                              <TextField {...params} variant="outlined" margin="dense" 
                                helperText="Please choose the Department" fullWidth />
                            )}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label" htmlFor="message-text">Role:</label>
                          <Autocomplete
                            options={roleList}
                            getOptionLabel={option => option.name}
                            value={role}                      
                            onChange={(event, newValue) => {
                              setRole(newValue)
                            }}            
                            renderInput={params => (
                              <TextField {...params} variant="outlined" margin="dense" 
                                helperText="Please choose the role" fullWidth />
                            )}
                          />
                        </div>
                        <div className="offset-md-3 col-md-6 mb-3">
                          <label className="col-form-label" htmlFor="message-text">Manager:</label>
                          <Autocomplete
                            options={filterManager}
                            getOptionLabel={option => option.firstname}
                            value={manager}                      
                            onChange={(event, newValue) => {
                              setManager(newValue)
                            }}            
                            renderInput={params => (
                              <TextField {...params} variant="outlined" margin="dense" 
                                helperText="Please choose your Manager" fullWidth />
                            )}
                          />
                        </div>
                      </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={addRow}>Cancel</Button>
                  <Button color="primary" onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Users --> */}
            <Modal isOpen={modal} toggle={editRow} size="lg">
                <ModalHeader toggle={editRow}>Edit User</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Full Name:</label>
                              <input className="form-control" name="firstname" type="text" placeholder="Full name" 
                               value={cu_user.firstname} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Lastname:</label>
                              <input className="form-control" name="phone" type="number" placeholder="Phone" 
                               value={cu_user.phone} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Email:</label>
                              <input className="form-control" name="email" type="email" placeholder="Email" 
                               value={cu_user.email} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Username:</label>
                              <input className="form-control" name="username" type="text" placeholder="Username" 
                               value={cu_user.username} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Password:</label>
                              <input className="form-control" name="password" type="password" placeholder="Password" 
                               value={cu_user.password} onChange={handleInputChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={editRow}>Cancel</Button>
                  <Button color="primary" onClick={ () => handleUpdate(cu_user.id, cu_user)} >Save Changes</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default MyUser;