import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormHelperText, TablePagination, MenuItem } from '@material-ui/core';
import {
  getClients, msaUpload, othermsaUpload, postClients,
  editClients, approveClients, getAllUser, sendManagerEmail,
  getProjects, postTask, getTasks, editTask, deleteTask
} from '../../../actions/asyncActions';

const TaskDetails = () => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const mgr_id = user.mgr_id;
  const initialFormState = {
    id: '', name: '', phone: '', email: '', start_date: '', end_date: '', billing_address: '', gstin: '', pan: '',
    contact_person: ''
  }
  const [data, setCurrentData] = useState(initialFormState)
  const [modal, setModal] = useState();
  const [addmodal, setAddModal] = useState();
  const [values, setValues] = useState({ name: '', project_id: '' });
  console.log("values", values)
  const [errors, setError] = useState([]);
  const [msaImage, setMsa] = React.useState([]);
  const [othermsaImage, setOtherMsa] = React.useState([]);

  const [users, setUsers] = useState([])
  const [clients, setClient] = useState([])
  const [tasks, setTasks] = useState([])
  const submitted_data = tasks.filter(s => s.status === 'submitted');

  const [projects, setProjects] = useState([])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getClients(group_id)
      .then(resp => setClient(resp.data))
  }, []);

  useEffect(() => {
    getAllUser(group_id)
      .then(resp => setUsers(resp.data))
  }, []);

  useEffect(() => {
    getProjects(group_id)
      .then(resp => setProjects(resp.data))
  }, []);

  useEffect(() => {
    getTasks(group_id)
      .then(resp => setTasks(resp.data))
  }, []);

  const [StartDate, setStartDate] = React.useState(new Date());
  const handleStartDateChange = date => {
    setStartDate(date);
  };

  var nextDate = new Date(StartDate)
  nextDate.setDate(nextDate.getDate() + 1);

  const [EndDate, setEndDate] = React.useState(new Date(nextDate));
  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUploadMsa = async (event) => {
    setMsa(document.getElementById('filemsa').files[0]);
  }

  const handleUploadOtherMsa = async (event) => {
    setOtherMsa(document.getElementById('fileother').files[0]);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setCurrentData({ ...data, [name]: value })
  }

  const addRow = () => {
    setAddModal(!addmodal)
  }

  const editRow = n => {
    setModal(!modal)
    setCurrentData({
      id: n.id, name: n.name, project_id: n.project_id
    })
  }

  const validateForm = (data) => {
    const errors = [];
    if (!data.name) {
      errors.name = "Task Name is required";
    }
    return errors;
  }

  const handleSubmit = async (event) => {

    const getMgr = users.find(x => x.id === mgr_id)
    const mgrEmail = getMgr && getMgr.email;

    const data = {
      name: values.name,
      project_id: values.project_id,
      status: 'submitted',
      mgr_id: user.mgr_id,
      user_id: user.id,
      group_id: group_id,
    }
    const errors = validateForm(data);
    if (Object.keys(errors).length) {
      setError(errors);
      return;
    }
    postTask(data)
      .then(resp => {
        getTasks(group_id)
          .then(resp => setTasks(resp.data))
        setValues({
          name: '', project_id: ''
        });
        setAddModal(!addmodal)
      })
      .catch(err => {
        console.log("Error", err);
      })
  }

  const handleUpdate = async (id, data) => {

    const cat = {
      id: id,
      name: data.name,
      project_id: data.project_id,
      mgr_id: user.mgr_id,
      user_id: user.id,
      group_id: group_id
    }
    const errors = validateForm(data);
    if (Object.keys(errors).length) {
      setError(errors);
      return;
    }
    editTask(cat)
      .then(resp => {
        getTasks(group_id)
          .then(resp => setTasks(resp.data))
      })
      .catch(err => {
        console.log("Error", err);
      })
    setModal(!modal)
  }

  const approveClient = id => {
    const data = {
      id: id,
      status: 'approved'
    }
    approveClients(data)
      .then(resp => {
        getClients(group_id)
          .then(resp => setClient(resp.data))
      })
      .catch(err => {
        console.log("Error", err);
      })
  }

  const deleteRow = id => {
    const data = {
      id: id
    }
    deleteTask(data)
      .then(resp => {
        getTasks(group_id)
          .then(resp => setTasks(resp.data))
        toast.warn("Task deleted successfully !!!", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
      })
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-block row">
                <div className="col-sm-12 col-lg-12 col-xl-12">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="table-primary">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Project</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {submitted_data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i) => {
                          const findProject = projects.find(x => x.id === n.project_id)
                          const projectName = findProject && findProject.name;
                          return (
                            <tr>
                              <th scope="row">{n.id}</th>
                              <td>{n.name}</td>
                              <td>{projectName}</td>
                              <td>{n.status}</td>
                              <td>
                                <button className="btn btn-info btn-xs" type="button" onClick={() => editRow(n)}>
                                  <i className="icofont icofont-edit"></i>
                                </button> &nbsp;
                                {/* <button className="btn btn-success btn-xs" type="button" onClick={() => approveClient(n.id)}>
                                  <i className="fa fa-thumbs-up"></i>
                                </button> */}
                                <button className="btn btn-danger btn-xs" type="button" onClick={() => deleteRow(n.id)}>
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block row">
                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                        <button className="btn btn-info btn-sm" type="button" onClick={() => addRow()}>
                          Add Task
                        </button>
                      </div>
                      <div className="col-sm-6 col-lg-6 col-xl-6">
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={submitted_data.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* < !-- Using Form Modal for Add Tasks --> */}
      <Modal isOpen={addmodal} toggle={addRow} size="lg">
        <ModalHeader toggle={addRow}>Add Task</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-row">
              <div className="col-xl-6 mb-3">
                <TextField fullWidth required id="name" label="Name" margin="dense" name="name"
                  onChange={handleChange} value={values.name} variant="outlined"
                />
                <FormHelperText>{errors.name}</FormHelperText>
              </div>
              <div className="col-xl-6 mb-3">
                <TextField id="project_id" select label="Project Name" value={values.project_id}
                  onChange={handleChange} SelectProps={{ name: 'project_id' }} margin="dense" fullWidth variant="outlined" >
                  {
                    projects.map((n, index) => {
                      return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                    })
                  }
                </TextField>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={addRow}>Cancel</Button>
          <Button color="primary" onClick={handleSubmit} >Submit</Button>
        </ModalFooter>
      </Modal>

      {/* < !-- Using Form Modal for Edit Task --> */}
      <Modal isOpen={modal} toggle={editRow}>
        <ModalHeader toggle={editRow}>Edit Task</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-row">
              <div className="col-xl-6 mb-3">
                <TextField fullWidth required id="name" label="Name" margin="dense" name="name"
                  onChange={handleInputChange} value={data.name} variant="outlined"
                />
                <FormHelperText>{errors.name}</FormHelperText>
              </div>
              <div className="col-xl-6 mb-3">
                <TextField id="project_id" select label="Project Name" defaultValue={data.project_id}
                  onChange={handleInputChange} SelectProps={{ name: 'project_id' }} margin="dense" fullWidth variant="outlined" >
                  {
                    projects.map((n, index) => {
                      return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                    })
                  }
                </TextField>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={editRow}>Cancel</Button>
          <Button color="primary" onClick={() => handleUpdate(data.id, data)} >Save Changes</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

export default TaskDetails;