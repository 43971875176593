import React, { Fragment, useState, useEffect }  from 'react';
import { TextField, Input, InputAdornment, Checkbox } from '@material-ui/core';
import { postCompensation, editEmployee, getEmployee,
         getComCategory, getComComponents } from '../../../actions/asyncActions';
import { toast } from 'react-toastify';

const Compensation = (props) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const location = props.location;
    const employeeId = location.state ? location.state.id : null;
    const employeeName = location.state ? location.state.name : null;

    const [employees, setEmployees] = useState([])
    const [category, setCategory] = useState([])
    const [components, setComponents] = useState([])

    const [gross, setGross] = useState("");
    const filterCategory = category.find(x=>x.name === 'Employer Contribution')
    const categoryId = filterCategory ? filterCategory && filterCategory.id : null; //Employer Contribution ID
    const filterComp = components.filter(x=>x.category_id !== categoryId)
    const filterCompEC = components.filter(x=>x.category_id === categoryId) //Employer Contribution

    const filCatDeductions = category.find(x=>x.name === 'Deductions')
    const catDeductionId = filCatDeductions ? filCatDeductions && filCatDeductions.id : null; // Deduction ID

    const filPayoutDeductions = category.find(x=>x.name === 'Payouts')
    const payoutId = filPayoutDeductions ? filPayoutDeductions && filPayoutDeductions.id : null; // Payout ID

    console.log("filterComp", filterComp)
    console.log("filterCompEC", filterCompEC)

    // const [values, setValues] = useState({gross:"", basic:"", dearness:"", hra:"", conveyance:"",
    //         medical:"", special:"", insurance:"" });

    const [values, setValues] = useState([{}]);
    const [values2, setValues2] = useState([{}]);
    console.log("values", values)
    console.log("values2", values2)

    let abcd = values;

    // const handleChange = event => {
    //     setValues({
    //       ...values,
    //       [event.target.name]: event.target.value
    //     });
    // };

    const handleChange = (event, i, n) => {
      const filterCategory = category.find(x=>x.id === n.category_id)
      const categoryName = filterCategory && filterCategory.name;
      const tempQtys = [...values];
      tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
      tempQtys[i] = {...tempQtys[i], id : n.id, component: n.name, category_id : n.category_id, category: categoryName};
      setValues(tempQtys);
    };

    const filterDeductionVal = values ? (abcd || []).filter(x=> x !== undefined) : null; //Payouts & Deductions Data
    const filterPayoutsByCategory = filterDeductionVal ? filterDeductionVal.filter(x=> x.category_id === payoutId) : null;
    const filterDeductionByCategory = filterDeductionVal ? filterDeductionVal.filter(x=> x.category_id === catDeductionId) : null;
    
    const totalPayoutsMonth = filterPayoutsByCategory ? 
                             filterPayoutsByCategory.reduce((a,b) => a + parseFloat(b.payout), 0) 
                             : null
    const totalDeductionsMonth = filterDeductionByCategory ? 
                                filterDeductionByCategory.reduce((a,b) => a + parseFloat(b.payout), 0) 
                                : null
    const totalDeductionsAnnum = totalDeductionsMonth * 12;

    
    const totalSalMonth = totalPayoutsMonth - totalDeductionsMonth
    const totalSalAnnum = totalSalMonth * 12;
    

    const handleEmpContChange = (event, i, n) => {
      const filterCategory = category.find(x=>x.id === n.category_id)
      const categoryName = filterCategory && filterCategory.name;
      const tempQtys = [...values2];
      tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
      tempQtys[i] = {...tempQtys[i], id : n.id, component: n.name, category_id : n.category_id, category: categoryName};
      setValues2(tempQtys);
    }

    const [addItems, setAddItems] = useState([]);

    const filterECVal = values2 ? (values2 || []).filter(x=> x !== undefined) : null; //Employer Contribution Data
    
    const empContribution = filterECVal ? 
                         filterECVal.reduce((a,b) => a + parseFloat(b.emp_contribution), 0) 
                         : null
    const totalCTCMonth = gross ? parseFloat(gross) + (empContribution || 0) : 0
    const totalCTCAnnum = totalCTCMonth * 12;

    useEffect( () => {
        getComCategory(group_id)
        .then(resp => setCategory(resp.data))
    }, [])

    useEffect( () => {
        getComComponents(group_id)
        .then(resp => setComponents(resp.data))
    }, [])

    const [pfChecked, setPfChecked] = useState(false)
    const [esiChecked, setEsiChecked] = useState(false)
    const [insuranceChecked, setInsuranceChecked] = useState(false)
    const [gratuityChecked, setGratuityChecked] = useState(false)

    const handleCheckPF = (event) => {
        setPfChecked(event.target.checked)
    }

    const handleCheckESI = (event) => {
        setEsiChecked(event.target.checked)
    }

    const handleCheckInsurance = (event) => {
        setInsuranceChecked(event.target.checked)
    }

    const handleCheckGratuity = (event) => {
        setGratuityChecked(event.target.checked)
    }

    // const basicMonth = (String(values.basic).indexOf('%') !== -1) ? 
    //             parseFloat(values.gross) * parseFloat(values.basic) / 100 
    //             :
    //             0;
    //const [gross, setGross] = useState("");
    const basicMonth = parseFloat(values.basic);
    const basicAnnum = basicMonth ? basicMonth * 12 : 0; 

    const dearnessAnnum =  values.dearness ? parseFloat(values.dearness) * 12 : 0;

    const totalBasicMonth = (basicMonth || 0) + (parseFloat(values.dearness) || 0);
    const totalBasicAnnum = totalBasicMonth * 12;

    // const hraMonth = (String(values.hra).indexOf('%') !== -1) ? 
    //             parseFloat(values.gross) * parseFloat(values.hra) / 100 
    //             :
    //             0;
    const hraMonth = parseFloat(values.hra);
    const hraAnnum = hraMonth ? hraMonth * 12 : 0;
    const conveyanceAnnum = values.conveyance ? parseFloat(values.conveyance) * 12 : 0;
    const medicalAnnum = values.medical ? parseFloat(values.medical) * 12 : 0;
    const specialAnnum = values.special ? parseFloat(values.special) * 12 : 0;

    // const totalSalMonth = (basicMonth || 0) + (parseFloat(values.dearness) || 0) + (hraMonth || 0) + 
    //   (parseFloat(values.conveyance) || 0 ) + (parseFloat(values.medical) || 0) + 
    //   (parseFloat(values.special) || 0);
                          
    // const totalSalAnnum = totalSalMonth * 12;

    //const employerPfMonth = pfChecked ? parseFloat((13.5 / 100) *  totalBasicMonth) : 0
    const employerPfMonth = parseFloat(values.employerPf)
    const roundPfMonth = Math.round(employerPfMonth)
    const employerPfAnnum = employerPfMonth * 12;
    const roundPfAnnum = Math.round(employerPfAnnum)
    const employerEsiMonth = esiChecked ? parseFloat((4 / 100) *  totalBasicMonth) : 0
    //const employerEsiMonth = parseFloat(values.employerPf)
    const employerEsiAnnum = employerEsiMonth * 12;
    const employerGratMonth = gratuityChecked ? parseFloat((1 / 100) *  totalBasicMonth) : 0
    const employerGratAnnum = employerGratMonth * 12;

    const insuranceAnnum = values.insurance ? parseFloat(values.insurance) * 12 : 0;

    const totalCTCMon = (parseFloat(values.gross) || 0) + (employerPfMonth || 0) + (employerEsiMonth || 0) + 
        (employerGratMonth || 0) + (parseFloat(values.insurance) || 0);

    // const totalCTCMonth = Math.round(totalCTCMon)
    // const totalCTCAnnum = totalCTCMonth * 12;

    const handleSubmit = () => {
      // Line no: 58 & 86 for all the data
      
      let compensation_data = [];

      filterDeductionVal.forEach((item, i) => {
        compensation_data.push({ 
          emp_id: employeeId,
          emp_name: employeeName, 
          category: item.category, 
          component: item.component, 
          monthly: parseFloat(item.payout), 
          yearly: parseFloat(item.payout) * 12
        })
      });

      filterECVal.forEach((item, i) => {
        compensation_data.push({
          emp_id: employeeId,
          category: item.category,
          component: item.component,
          monthly: parseFloat(item.emp_contribution),
          yearly: parseFloat(item.emp_contribution) * 12
        })
      });
      console.log("compensationData", compensation_data)

      const data = {
        items: compensation_data,
        user_id: user.id,
        group_id: group_id
      }
      const empData = {
        id: employeeId,
        compensation: 'yes'
      }
      if(parseFloat(gross) === (totalSalMonth + totalDeductionsMonth)) {
        postCompensation(data)
        .then(resp => {
            editEmployee(empData)
            .then(resp => {
              getEmployee(group_id)
              .then(resp => {
                setEmployees(resp.data)
                toast.success("Compensation added successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
                props.history.push(`${process.env.PUBLIC_URL}/employeemaster`)
              })
            })           
        })
      }
      else {
        toast.error("Total Salary is not matching with the Gross, please check!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
      }

    }

    const handleSubmit2 = () => {

      // Line no: 58 & 86 for all the data
        const pff = pfChecked ? employerPfMonth : 'NA'
        const esi = esiChecked ? employerEsiMonth : 'NA'
        const grat = gratuityChecked ? employerGratMonth : 'NA'
        const insur = insuranceChecked ? parseFloat(values.insurance) : 'NA'

        const data = {
         emp_id: employeeId,
         gross: values.gross,
         basic: basicMonth,
         dearness: values.dearness,
         hra: hraMonth,
         conveyance: values.conveyance,
         medical: values.medical,
         special: values.special,
         variable_month: 0,
         variable_quarter: 0,
         bonus: 0,
         employer_pf: pff,
         employer_esi: esi,
         employer_gratuity: grat,
         employer_insurance: insur,
         ctc: totalCTCMonth,
         user_id: user.id,
         group_id: group_id
        }
        const empData = {
          id: employeeId,
          compensation: 'yes'
        }
        if(parseFloat(values.gross) === totalSalMonth) {
          postCompensation(data)
          .then(resp => {
            if(resp.data.emp_id === employeeId){
              editEmployee(empData)
              .then(resp => {
                getEmployee(group_id)
                .then(resp => {
                  setEmployees(resp.data)
                  toast.success("Compensation added successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
                  props.history.push(`${process.env.PUBLIC_URL}/employeemaster`)
                })
              })
            }
            else{
              toast.error("Something went wrong!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
            }            
          })
        }
        else {
          toast.error("Total Salary is not matching with the Gross, please check!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
        }
    }

    return(
        <Fragment>
         <div className="container-fluid">
	      <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header text-center">
                    <h5>Compensation Tab</h5>
                </div>
                <div className="card-body">
                 <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="text-center">
                       <th>
                        Gross salary per month
                       </th>
                       <th colSpan={2}>
                        <TextField 
                         type="number" 
                         fullWidth
                         InputProps={{
                            startAdornment: 
                            <InputAdornment position="start">
                             <i className="fa fa-inr"></i>
                            </InputAdornment>,
                         }}
                         name="gross"
                         value={gross}
                         onChange={(e) => setGross(e.target.value)}
                        />
                       </th>
                      </tr>
                      <tr className="text-center">
                       <th className="text-center">
                        Components in salary
                       </th>
                       <th className="text-center">
                        Per month
                       </th>
                       <th className="text-center">
                        Per annum
                       </th>
                      </tr>
                    </thead>
                    <tbody>
                      { filterComp.map((n,i) => {
                          return(
                            <tr key={i} className="text-center">
                              <th>{n.name}</th>
                              <th>
                                <TextField
                                  InputProps={{
                                  startAdornment: 
                                  <InputAdornment position="start">
                                  <i className="fa fa-inr"></i>
                                  </InputAdornment>,
                                  }}
                                  key={i}
                                  type="number"
                                  name="payout"
                                  value = {values[i] ? values[i].payout: null} 
                                  onChange={(e) => handleChange(e, i, n)}
                                />   
                              </th>
                              <th>
                              {12 * parseFloat(values[i] ? values[i].payout: 0)}
                              </th>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                    <tbody>
                      <tr className="text-center">
                        <th className="text-danger">
                          Total Deductions
                        </th>
                        <th className="text-danger">{totalDeductionsMonth}</th>
                        <th className="text-danger">{totalDeductionsAnnum}</th>
                       </tr>
                       <tr className="text-center">
                        <th className="text-secondary">
                          Net Salary(Gross - Deductions)
                        </th>
                        <th className="text-secondary">{totalSalMonth}</th>
                        <th className="text-secondary">{totalSalAnnum}</th>
                       </tr>
                    </tbody>
                    <tbody>
                      { filterCompEC.map((n,j) => {
                         return(
                          <tr key={j} className="text-center">
                            <th>
                              <input type="checkbox" checked={addItems.includes(n)} 
                                onChange={(e) => {
                                const checked = addItems.includes(n)
                                setAddItems((prev) => 
                                  checked
                                  ? prev.filter((sc) => sc !== n)
                                  : [...prev, n ]
                                  );
                                }} 
                              />
                              <label>{n.name}</label>
                            </th>
                            {addItems.includes(n) ? 
                            <>
                            <th>
                              <TextField
                                InputProps={{
                                startAdornment: 
                                <InputAdornment position="start">
                                <i className="fa fa-inr"></i>
                                </InputAdornment>,
                                }}
                                key={j}
                                type="number"
                                name="emp_contribution"
                                value = {values2[j] ? values2[j].emp_contribution: 0} 
                                onChange={(e) => handleEmpContChange(e, j, n)}
                              />   
                            </th>
                            <th>
                              {12 * parseFloat(values2[j] ? values2[j].emp_contribution: 0)}
                            </th>
                            </>
                            :
                            <>
                            <th>-</th>
                            <th>-</th>
                            </>
                            }
                          </tr>
                         );
                        })
                      }
                    </tbody>
                    <tbody>
                      <tr className="text-center">
                        <th className="text-success">
                            Total CTC( Gross + All Employer's Contribution)
                        </th>
                        <th className="text-success">{totalCTCMonth}</th>
                        <th className="text-success">{totalCTCAnnum}</th>
                      </tr>
                    </tbody>
                  </table>
                 </div>
                </div>
                <div className="card-footer text-center">
                    <button className="btn btn-info" onClick={handleSubmit}>Submit</button>
                </div>
              </div>
            </div>
          </div>
         </div>
        </Fragment>
    )
}

export default Compensation;