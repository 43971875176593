import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TextField, FormHelperText, MenuItem } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { getMasterInventory, getProjects, getTransInventory, getCartsInventory, postCartsInventory,
         deleteCartsInventory, getAllUser, postTransInventory2 } from '../../actions/asyncActions';

const ItemIssuance = () => {
 
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const [projects, setProject] = useState([]);
 const [project_val, setProjectVal] = React.useState(null);
 const selectedProject = project_val && project_val.id;

 const [masterInventory, setMasterInventory] = useState([]);
 const [transInventory, setTransInventory] = useState([]);
 const [cartsInventory, setCartsInventory] = useState([]);
 const [allUsers, setAllUsers] = useState([]);
 console.log("allUsers", allUsers)
 const filterUsers = allUsers.filter(x=>x.type === 'operations');
 const [user_val, setUserVal] = useState(null);
 const selectedUser = user_val && user_val.id;

 const [masterId, setMasterId] = useState();
 const [partNo, setPartNo] = useState();
 const [tempcartmodal, setTempCartModal] = useState();
 const [quantity, setQuantity] = useState();
 const [cartmodal, setCartModal] = useState();

 const [errors, setError] = useState({});

 useEffect( () => {
  getProjects(group_id)
   .then(resp => setProject(resp.data))
 }, []);

 useEffect( () => {
   getMasterInventory(group_id)
    .then(resp => setMasterInventory(resp.data))
 }, []);

 useEffect( () => {
   getTransInventory(group_id)
    .then(resp => setTransInventory(resp.data))
 }, []);

 useEffect( () => {
   getCartsInventory(group_id)
    .then(resp => setCartsInventory(resp.data))
 }, []);

 useEffect( () => {
   getAllUser(group_id)
    .then(resp => setAllUsers(resp.data))
 }, []);

 const tempCartRow = (n) => {
   setMasterId(n.id);
   setPartNo(n.part_no);
   setTempCartModal(!tempcartmodal)
   setError('')
 }

 const validateForm = (data) => {
   const errors = [];
    if(data.cartQty){
      errors.quantity = "This item is already added to the cart";
    }
    if(data.qty > data.totalQty){
     errors.quantity = "Entered Qty cannot be greater than total Qty";
    }
   return errors;
 }

 const handleTempCart = () => {
   const aaa = masterInventory.find(x=>x.id === masterId);
   const getWac = aaa && aaa.wac;
   const inventTrans = transInventory.filter(x=>x.inventory_id === masterId);
   const transQty = inventTrans.reduce((a,b) => a + parseFloat(b.qty), 0);

   const bbb = cartsInventory.find(x=>x.inventory_id === masterId);
   const cartQty = bbb && bbb.qty;

   const checkData = {
    qty: quantity,
    cartQty: cartQty,
    totalQty: transQty
   }
   const errors = validateForm(checkData);
    if(Object.keys(errors).length) {
     setError(errors);
    return;
   }

   const data = {
    inventory_id: masterId,
    qty: quantity,
    rate: getWac,
    total: parseFloat(quantity) * parseFloat(getWac),
    user_id: user.id,
    group_id: group_id
   }
   postCartsInventory(data)
   .then(resp => {
    getCartsInventory(group_id)
      .then(resp => setCartsInventory(resp.data))
    toast.success("Item is added to the cart !!!", {autoClose: 2000});
    setTempCartModal(!tempcartmodal)
    setQuantity('')
    setError('')
   })
   .catch(err => {
      console.log("Error", err);
   })
 }

 const handleCartPage = () => {
  setCartModal(!cartmodal)
 }

 const handleDelete= (n) => {
    const deleteId = {
      id : n.id
    }
    deleteCartsInventory(deleteId)
      .then(resp => {
        getCartsInventory(user.group_id)
        .then(resp => setCartsInventory(resp.data))
        toast.error("Item deleted from the cart !!!", {autoClose: 2000});
      })
      .catch(err => {
        console.log("Error", err);
    })
 }

 const validateForm2 = (data) => {
   const errors = [];
    if(!data.selectUser){
     errors.user_val = "User is not selected";
    }
    if(!data.selectProject){
      errors.project_val = "Project is not selected";
    }
   return errors;
 }

 const handleSubmit = () => {
    let cartData = cartsInventory;
    let inventoryTransData = [];
    cartData.forEach(item => {
      inventoryTransData.push({
        inventory_id: item.inventory_id,
        qty: -(item.qty),
        rate: item.rate,
        total: -(item.total),
        date: new Date(),
        type: 'Issuance',
        issued_user_id: selectedUser,
        project_id: selectedProject,
        user_id: user.id,
        group_id: group_id 
      })
    });
    let cartTransData = [];
    cartData.forEach(item => {
      cartTransData.push({
        id: item.id
      })
    })
    const checkData = {
      selectUser: selectedUser,
      selectProject: selectedProject
    }
    const errors = validateForm2(checkData);
      if(Object.keys(errors).length) {
       setError(errors);
      return;
    }
    postTransInventory2({inventoryTransData, cartTransData})
    .then(resp => {
        getTransInventory(group_id)
        .then(resp => setTransInventory(resp.data))
        getCartsInventory(group_id)
        .then(resp => setCartsInventory(resp.data))
        toast.success("Items issued successfully!!!", {autoClose: 2000});
        setCartModal(!cartmodal)
        selectedUser('')
      })
      .catch(err => {
        console.log("Error", err);
    })

 }

  return (
      <Fragment>
        <Breadcrumb title="Item Issuance" parent="Issuance" />
         <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-12">
                <div className="card card-absolute">
                  <div className="card-header bg-secondary">
                    <h5>Form for Issuance</h5>
                  </div>
                  <div className="card-body">
                     <div className="row">
                      {cartsInventory.length > 0?
                       <div className="offset-xl-9 col-xl-3">
                        <Button color="info" onClick={handleCartPage} className="btn btn-lg m-t-15">
                         Cart
                         <span className="badge badge-light m-l-15">{cartsInventory.length}</span>
                        </Button>
                       </div> : null
                      }
                     </div> 
                     <div className="row">
                      <div className="col-xl-12 m-t-15">
                       <div className="table-responsive">
                          <table className="table table-sm table-bordered table-hover">
                              <thead className="table-info">
                                  <tr>
                                    <th scope="col">Sl.No</th>
                                    <th scope="col">Part No</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Rate</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {masterInventory.map((n, i)=> {
                                    const inventTrans = transInventory.filter(x=>x.inventory_id === n.id );
                                    const transQty = inventTrans.reduce((a,b) => a + parseFloat(b.qty), 0);
                                    const totalVal = parseFloat(transQty * n.wac);

                                    return (
                                      <tr>
                                      {transQty === 0 ? null :
                                       <>
                                       <th scope="row">{i+1}</th>
                                       <td>{n.part_no}</td>
                                       <td>{n.description}</td>
                                       <td>{transQty}</td>
                                       <td>{n.wac}</td>
                                       <td>{totalVal}</td>
                                       <td>
                                        <button className="btn btn-info btn-sm" type="button" onClick={() => tempCartRow(n)}>
                                         Add
                                        </button>
                                       </td>
                                       </>
                                      }
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                          </table>
                       </div>
                      </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
         </div> 

        {/* < !-- Using Form Modal for Cart Addition --> */}
            <Modal isOpen={tempcartmodal} toggle={tempCartRow} className="modal-body" centered={true}>
                <ModalHeader toggle={tempCartRow}>Issue Qty</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                          <div className="col-sm-12 mb-3">
                            <label className="col-form-label text-center" htmlFor="recipient-name">Qty for <b>{partNo}</b>:</label>
                            <input className="form-control" type="text" onChange={e => setQuantity(e.target.value)} value={quantity} />
                            <FormHelperText className="text-danger">{errors.quantity}</FormHelperText>
                          </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={tempCartRow}>Cancel</Button>
                    <Button color="primary" onClick={handleTempCart} >Submit</Button>
                </ModalFooter>
            </Modal>

        {/* < !-- Using Modal for Viewing Cart Items --> */}
            <Modal isOpen={cartmodal} toggle={handleCartPage} className="modal-dialog modal-xl">
                <ModalHeader toggle={handleCartPage}>Issue Items</ModalHeader>   
                <ModalBody>
                   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Part No</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Rate</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cartsInventory.map((n, i) => {
                                                  const masterDetails = masterInventory.find(x=>x.id === n.inventory_id)
                                                  const partNo = masterDetails && masterDetails.part_no
                                                  const descr = masterDetails && masterDetails.description
                                                  return (
                                                    <tr>
                                                     <td>{partNo}</td>
                                                     <td>{descr}</td>
                                                     <td>{n.qty}</td>
                                                     <td>{n.rate}</td>
                                                     <td>{n.total}</td>
                                                     <td>
                                                      <button className="btn btn-danger btn-sm" type="button" onClick={() => handleDelete(n)}>
                                                       <i className="fa fa-trash-o"></i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="offset-sm-2 col-xl-4 col-lg-4 col-sm-12 m-t-15">
                                  <Autocomplete
                                    freeSolo
                                    options={filterUsers}
                                    getOptionLabel={option => option.firstname}
                                    value={user_val}                      
                                    onChange={(event, newValue) => {
                                      setUserVal(newValue);
                                    }}            
                                    renderInput={params => (
                                      <TextField {...params} label="Issue Person" margin="normal" variant="outlined" helperText="To whom is being issued?" fullWidth />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">{errors.user_val}</FormHelperText>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12 m-t-15">
                                  <Autocomplete
                                    freeSolo
                                    options={projects}
                                    getOptionLabel={option => option.name}
                                    value={project_val}                      
                                    onChange={(event, newValue) => {
                                      setProjectVal(newValue);
                                    }}            
                                    renderInput={params => (
                                      <TextField {...params} label="Project" margin="normal" variant="outlined" helperText="Please select Project" fullWidth />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">{errors.project_val}</FormHelperText>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleCartPage}>Close</Button>
                    <Button color="info" onClick={handleSubmit}>Issue Items</Button>
                </ModalFooter>
            </Modal>
      </Fragment>
    );
}
export default ItemIssuance;