import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button } from 'reactstrap';
import { TextField, FormHelperText, MenuItem, TablePagination, Snackbar, Slide, IconButton } from '@material-ui/core';
import {Close as CloseIcon } from "@material-ui/icons";
import {Autocomplete} from '@material-ui/lab';
import { Download } from 'react-feather';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMasterInventory, getTransInventory, getAllUser, getTransactionDownload } from '../../actions/asyncActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MaterialTransactionTable = () => {
 
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;

 const [masterInventory, setMasterInventory] = useState([]);
 const [transInventory, setTransInventory] = useState([]);
 
 const [allUsers, setAllUsers] = useState([]);
 const filterUsers = allUsers.filter(x=>x.type === 'operations');
 const [user_val, setUserVal] = useState(null);
 const selectedUser = user_val && user_val.id;
                          
 const [transaction_download, setTransactionDownload] = useState([]);

 const [startDate, setStartDate] = useState();
 const [endDate, setEndDate] = useState();
 console.log(startDate, endDate)

 const [page, setPage] = React.useState(0);
 const [rowsPerPage, setRowsPerPage] = React.useState(5);

 const handleChangePage = (event, newPage) => {
  setPage(newPage);
 };

 const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
 };

 useEffect( () => {
   getMasterInventory(group_id)
    .then(resp => setMasterInventory(resp.data))
 }, []);

 useEffect( () => {
   getTransInventory(group_id)
    .then(resp => setTransInventory(resp.data))
 }, []);

 useEffect( () => {
   getAllUser(group_id)
    .then(resp => setAllUsers(resp.data))
 }, []);

 const [open_download, setOpenDownload] = React.useState(false);
 const handleReviewClose = () => {
    setOpenDownload(false);
    setStartDate('')
    setEndDate('')
 };

 const handleDownload = () => {
    const data ={
      startDate: startDate,
      endDate: endDate,
      group_id: group_id
    }
    getTransactionDownload(data)
    .then(resp => setTransactionDownload(resp.data))
    .catch(err => {
      console.log("Error", err);
    })
    setOpenDownload(true)
 }

  return (
      <Fragment>
        <Breadcrumb title="Transaction Table" parent="Inventory Transaction" />
         <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-12">
                <div className="card card-absolute">
                  <div className="card-header bg-secondary">
                    <h5>Inventory Transaction Table</h5>
                  </div>
                  <div className="card-body">
                   <div className="row">
                     <div className="col-md-3">
                      <label htmlFor="exampleFormControlInput1">Select Start Date:</label>
                        <DatePicker className="form-control"
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          showMonthDropdown
                          showYearDropdown
                          isClearable
                          dropdownMode="select"
                          dateFormat="dd/MM/yyyy"
                          placeholderText = "Select FROM date"
                        />
                     </div>
                     <div className="col-md-3">
                      <label htmlFor="exampleFormControlInput1">Select End Date:</label>
                        <DatePicker className="form-control"
                          selected={endDate}
                          onChange={date => setEndDate(date)}
                          showMonthDropdown
                          showYearDropdown
                          isClearable
                          dropdownMode="select"
                          dateFormat="dd/MM/yyyy"
                          placeholderText = "Select TO date"
                        />
                     </div>
                   </div>
                     {startDate && endDate ?
                      <div className="row">
                      <div className="col-xl-12 m-t-15">
                       <div className="table-responsive">
                          <table className="table table-sm table-bordered table-hover">
                              <thead className="table-info">
                                  <tr>
                                    <th scope="col">Sl.No</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Part No</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Rate</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Type</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {transInventory.filter( item => {
                                    let date = new Date(item.date);
                                    return date >= startDate && date <= endDate;
                                  }).map((n, i)=> {
                                    const inventMaster = masterInventory.find(x=>x.id === n.inventory_id);
                                    const partNo = inventMaster && inventMaster.part_no;
                                    const Desc = inventMaster && inventMaster.description;

                                    return (
                                      <tr>
                                       <th scope="row">{i+1}</th>
                                       <td>
                                        {new Date(n.date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                       </td>
                                       <td>{partNo}</td>
                                       <td>{Desc}</td>
                                       <td>{n.qty}</td>
                                       <td>{n.rate}</td>
                                       <td>{n.total}</td>
                                       <td>{n.type}</td>
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                          </table>
                       </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="card-block row">
                            <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                              <button className="btn btn-info btn-sm" type="button" onClick={handleDownload}>
                                <Download size="14"/>Export
                              </button>
                            </div>
                            <div className="col-sm-6 col-lg-6 col-xl-6">
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={transInventory.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                               />
                            </div>
                          </div>
                        </div>
                      </div>
                     </div>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
         </div> 

        {/***** Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download}
          autoHideDuration={6000}
          onClose={handleReviewClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={transaction_download}
              filename="Transaction.csv"
              className="hidden"
              target="_blank">
              <Button onClick={getTransactionDownload} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleReviewClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Fragment>
    );
}
export default MaterialTransactionTable;