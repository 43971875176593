import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { toast } from 'react-toastify';
import { sendInviteVendor, postUsers } from '../../actions/asyncActions';

const SendInvite = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const userfname = user && user.firstname;
    const group_id = user.group_id;
    const [values, setValues] = useState({});
    console.log("values", values)
    
    const handleChange = event => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    };

    const handleSubmit = () => {
      const userdata = {
        firstname: values.firstname,
        username: values.email,
        password: values.phone,
        email: values.email,
        phone: values.phone,
        role: 'Vendor',
        group_id: group_id
      }
      const sendEmail = {
       email: values.email,
       fname: userfname,
      }
      if((!values.firstname) || (!values.phone) || (!values.email)) {
        toast.error("Please fill all the fields", {position: toast.POSITION.TOP_CENTER})
        } else {
        sendInviteVendor(sendEmail)
        postUsers(userdata)
        toast.success("Invitation has been sent to the Vendor !!!", {position: toast.POSITION.TOP_CENTER})
        setValues({firstname:'', phone:'', email:''})
        }
    }

      return (
        <Fragment>
          <Breadcrumb title="Invite Vendor" parent="SendInvite" />
          <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-6">
              <div className="ribbon-wrapper card">
                <div className="card-body text-center">
                  <div className="ribbon ribbon-bookmark ribbon-secondary">Send Invitation</div>
                {/*
                <div className="card card-absolute">
                  <div className="card-header bg-secondary">
                      <h5>Send Invitation</h5>
                  </div>
                  <div className="card-body text-center">
                */}
                    <div className="form-row">
                     <div className="col-xl-12">
                      <label className="col-form-label text-body" htmlFor="message-text">Name:</label>
                      <input className="form-control" name="firstname" type="email" 
                       value={values.firstname} onChange={handleChange}/>
                     </div>
                     <div className="col-xl-12">
                      <label className="col-form-label text-body" htmlFor="message-text">Mobile number:</label>
                      <input className="form-control" name="phone" type="phone" 
                       value={values.phone} onChange={handleChange}/>
                     </div>
                     <div className="col-xl-12">
                      <label className="col-form-label text-body" htmlFor="message-text">Enter an Email address:</label>
                      <input className="form-control" name="email" type="email" 
                       value={values.email} onChange={handleChange}/>
                     </div>
                    </div>
                  </div>
                  <div className="card-footer text-center">
                    <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Invite</button>
                  </div>
                </div>
             </div>
            </div>
          </div> 
        </Fragment>
      );
}

export default SendInvite;