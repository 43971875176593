import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { TextField, FormHelperText, TablePagination } from '@material-ui/core';
import { getVendorForm, getLogs, postLogs } from '../../actions/asyncActions';

const SecurityLogs = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const [values, setValues] = useState({});
    //const [errors, setError] = React.useState({});
    const [vendorForm, setVendors] = useState([]);
    const [vendor_val, setVendorVal] = useState(null);
    const vendorId = vendor_val && vendor_val.id;
    const vendorName = vendor_val && vendor_val.supname;
    const [securityLogs, setSecurityLogs] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getVendorForm(group_id)
      .then(resp => setVendors(resp.data))
    }, []);

    useEffect( () => {
      getLogs(group_id)
      .then(resp => setSecurityLogs(resp.data))
    }, []);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const validateForm = (data) => {
    const errors = [];
    if(data.emp_id === data.mgr_id){
      errors.mgr_id = "Cannot select Employee as Manager";
     }
     return errors;
  }

  const handleSubmit = () => {
    const users = JSON.parse(localStorage.getItem("m_users"));
    const group_id = users.group_id;
    const logs = {
      vehicle_no: values.vehicle,
      contact_person: values.contact,
      vendor_id: vendorId,
      vendor_name: vendorName,
      recd_time: new Date(),
      user_id: users.id,
      group_id: group_id,
      company_id: users.company_id
    }
    // const errors = validateForm(user);
    // if(Object.keys(errors).length) {
    //   setError(errors);
    //   return;
    // }
    postLogs(logs)
    .then(resp => {
        getLogs(user.group_id)
        .then(resp => setSecurityLogs(resp.data))
        setValues('');
        setVendorVal('');
        toast.success("Logs added successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 2000})
      })
      .catch(err => {
        console.log("Error", err);
    })
  }

        return (
            <Fragment>
            <Breadcrumb title="Gate Entry" parent="Logs" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                     <div className="card card-absolute">
                        <div className="card-header bg-secondary">
                            <h5>Log Entry</h5>
                        </div>
                        <div className="card-body">
                         <div className="col-md-12">
                          <label className="col-form-label text-body" htmlFor="message-text">Vehicle Number:</label> 
                          <input className="form-control" name="vehicle" type="text" 
                            value={values.vehicle} onChange={handleChange}/>
                         </div>
                         <div className="col-md-12"> 
                         <label className="col-form-label text-body" htmlFor="message-text">Contact Person:</label>
                          <input className="form-control" name="contact" type="text" 
                            value={values.contact} onChange={handleChange}/>
                         </div>
                         <div className="col-md-12">
                          <Autocomplete
                            freeSolo
                            options={vendorForm}
                            getOptionLabel={option => option.supname}
                            value={vendor_val}                      
                            onChange={(event, newValue) => {
                              setVendorVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params} label="Vendor" margin="normal" variant="outlined" helperText="Please select Vendor" fullWidth />
                            )}
                          />
                         </div>
                        </div>
                        <div className="card-footer text-center">
                          <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Submit</button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 m-t-20">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-info">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Vehicle Number</th>
                                                  <th scope="col">Contact Person</th>
                                                  <th scope="col">Vendor</th>
                                                  <th scope="col">Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {securityLogs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.vehicle_no}</td>
                                                     <td>{n.contact_person}</td>
                                                     <td>{n.vendor_name}</td>
                                                     <td>
                                                      {new Date(n.recd_time).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={securityLogs.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        );
}

export default SecurityLogs;