import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Download } from 'react-feather';
import { Paper, Table, TableHead, TableCell, TableRow, Grid, TextField, Typography, FormGroup, FormControlLabel,
Radio, RadioGroup, TablePagination, Snackbar, Slide, IconButton } from '@material-ui/core';
import TTLogo from './turbotechimg.jpg';
import NuLogo from './nupie.png';
import { filterVendorForms, getVendorForm, getVendorCustForm, getVendorPlaceForm, getVendorPropForm, getVendorMachForm,
getVendorInstForm, approveVendors, sendBlockVendorEmail, editVendorForm, downloadReject } from '../../actions/asyncActions';
import { CSVLink } from "react-csv";
import { Close as CloseIcon } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const VendorReject = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const [filterVendor, setFilterVendor] = useState([]);
    const submitted_data = filterVendor.filter(s => s.status === 'Rejected');
    const [modal, setModal] = useState();
    const [blockmodal, setBlockModal] = useState();
    const [blockyesmodal, setBlockYesModal] = useState();
    const initialFormState = { id: '', supname:'', factAddress:'', headAddress:'', telephone:'', office:'', res:'', faxno:'', mobileno:'', 
     email:'', companyregno:'', esino:'', gstno:'', pfno:'', particularreg:'', capinvest:'' }
    const [ data, setCurrentData ] = useState(initialFormState);

    const [vendorForm, setVendorForm] = useState([]);
    const [vendorCustForm, setVendorCustForm] = useState([]);
    const [vendorPlaceForm, setVendorPlaceForm] = useState([]);
    const [vendorPropForm, setVendorPropForm] = useState([]);
    const [vendorMachForm, setVendorMachForm] = useState([]);
    const [vendorInstForm, setVendorInstForm] = useState([]);

    const [selId, setSelectedID] = React.useState([]);
    console.log("selId", selId);
    const filterCustData = vendorCustForm.filter(x => x.vendor_id === selId);
    const filterPlaceData = vendorPlaceForm.filter(x => x.vendor_id === selId);
    const filterPropData = vendorPropForm.filter(x => x.vendor_id === selId);
    const filterMachData = vendorMachForm.filter(x => x.vendor_id === selId);
    const filterInstData = vendorInstForm.filter(x => x.vendor_id === selId);

    const [download_reject, setDownload] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
         filterVendorForms(group_id)
         .then(resp => {
           setFilterVendor(resp.data);
         })
    }, []);

    useEffect( () => {
        getVendorForm(group_id)
        .then(resp => {
          setVendorForm(resp.data);
        })
    }, []);

    useEffect( () => {
        getVendorCustForm(group_id)
        .then(resp => {
          setVendorCustForm(resp.data);
        })
    }, []);

    useEffect( () => {
        getVendorPlaceForm(group_id)
        .then(resp => {
          setVendorPlaceForm(resp.data);
        })
    }, []);

    useEffect( () => {
        getVendorPropForm(group_id)
        .then(resp => {
          setVendorPropForm(resp.data);
        })
    }, []);

    useEffect( () => {
        getVendorMachForm(group_id)
        .then(resp => {
          setVendorMachForm(resp.data);
        })
    }, []);

    useEffect( () => {
        getVendorInstForm(group_id)
        .then(resp => {
          setVendorInstForm(resp.data);
        })
    }, []);

    const viewRow = n => {
     setSelectedID(n.id)
     setModal(!modal)
     setCurrentData({ id: n.id, docno:n.docno, revno: n.revno, date: n.date, supname:n.supname, factAddress:n.factAddress, 
        headAddress:n.headAddress, telephone:n.telephone, office:n.office, res:n.res, faxno:n.faxno, mobileno:n.mobileno, email:n.email,
        companyregno:n.companyregno, esino:n.esino, gstno:n.gstno, pfno:n.pfno, particularreg:n.particularreg, capinvest:n.capinvest, 
        aadharno:n.aadharno, panno: n.panno, landarea:n.landarea, landown:n.landown, landpower:n.landpower, turnover:n.turnover, 
        skillmale:n.skillmale, skillfemale: n.skillfemale, semiskillmale: n.semiskillmale, semiskillfemale: n.semiskillfemale,
        propname:n.propname, propqualify:n.propqualify, propexperience:n.propexperience, propaddress:n.propaddress, customerlist:n.customerlist, 
        inspectbrotout:n.inspectbrotout, inspectbrotoutyes: n.inspectbrotoutyes, testbrotout:n.testbrotout, testbrotoutyes:n.testbrotoutyes, testbrotoutno:n.testbrotoutno, 
        inprocess:n.inprocess, finproinspect:n.finproinspect, qualcertify: n.qualcertify, isocertify:n.isocertify, iso:n.iso,
        referredby:n.referredby, referredbyyes:n.referredbyyes, risk:n.risk, scopesupply:n.scopesupply, aadhar:n.aadhar, pan:n.pan,
        bank:n.bank, comp:n.comp, esi:n.esi, gst:n.gst, pff:n.pff, customerlist: n.customerlist, instruments2:n.instruments2, capacity2:n.capacity2, 
        leasecount2:n.leasecount2, qty2:n.qty2, calibration2:n.calibration2, machines1:n.machines1, capacity1:n.capacity1, qty1:n.qty1,
        placename:n.placename, placeaddress:n.placeaddress, placecity:n.placecity, placestate:n.placestate, placegst:n.placegst})
    }

    const approveVendor = id => {
        const data = {
          id: id,
          status: 'Approved'
        }
        approveVendors(data)
        .then(resp => {
              filterVendorForms(group_id)
              .then(resp => setFilterVendor(resp.data))
              toast.success("Vendor Approved !")
            })
            .catch(err => {
              console.log("Error", err);
            })
            setModal(!modal)
    }

    const [open_download, setOpenDownload] = React.useState(false);

    const handleRejectClose = () => {
      setOpenDownload(false);
    };

    const handleDownloadSubmit = () => {
      const user = JSON.parse(localStorage.getItem("m_users"));
      const data = {
        group_id: user.group_id 
      }
      console.log("data", data)
        downloadReject(data)
        .then(resp => {
          setDownload(resp.data);
          console.log('Download File', resp.data);
        })
        .catch(err => {
          console.log("Error", err);
        })
        setOpenDownload(true);
    }


        return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Name</th>
                                                  <th scope="col">Email</th>
                                                  <th scope="col">Phone</th>
                                                  <th scope="col">Address</th>
                                                  <th scope="col">Status</th>
                                                  <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {submitted_data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.supname}</td>
                                                     <td>{n.email}</td>
                                                     <td>{n.mobileno}</td>
                                                     <td>{n.factAddress}</td>
                                                     <td>{n.status}</td>
                                                     <td>
                                                      <button className="btn btn-info btn-xs" type="button" onClick={() => viewRow(n)}>
                                                       <i className="fa fa-eye"></i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                      <div className="card-block row">
                                        <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                         <button className="btn btn-info btn-sm" type="button" onClick={handleDownloadSubmit}>
                                          <Download size="14"/>Export
                                         </button>
                                        </div>
                                        <div className="col-sm-6 col-lg-6 col-xl-6">
                                          <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={submitted_data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                           />
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={viewRow} size="lg">
                <ModalHeader toggle={viewRow}>Supplier Evaluation Form</ModalHeader>
                <ModalBody>
                    <Paper>
                       <Paper>
                        <Table aria-label="spanning table">
                         <TableHead>
                          <TableRow>
                            <TableCell> <img src={NuLogo} /> </TableCell>
                            <TableCell colSpan={8} align="center">
                              <b>VIGILENZ MEDICAL DEVICES SDN. BHD.,<br/>
                              SUPPLIER INITIAL EVALUATION CUM <br/>
                              REGISTRATION </b>
                            </TableCell>
                            <TableCell>
                            Doc No:  <b>{data.docno}</b><br/>
                            Rev No:  <b>{data.revno}</b><br/>
                            Date:  <b>{"01-10-2020"}</b><br/>
                            </TableCell>
                          </TableRow>
                         </TableHead>
                        </Table> 
                       </Paper> 
                    </Paper>
                      &nbsp;&nbsp;

                    <Paper>
       <Table  aria-label="spanning table">
       <TableHead>
         <TableRow>
          <TableCell colSpan={20}>
           Name Of The Supplier: <b>{data.supname}</b>
          </TableCell>
         </TableRow>
         <TableRow>
          <TableCell colSpan={10}>Address - Factory /Godown <br/>
           <b>{data.factAddress}</b>
          </TableCell>
          <TableCell colSpan={10}>Address - Head Office <br/>
           <b>{data.headAddress}</b>
          </TableCell>
         </TableRow>
        <TableRow>
         <TableCell colSpan={10}> 
          Telephone No: 
          <b>{data.telephone}</b>
         </TableCell>
         <TableCell> 
          Office: <b>{data.office}</b> 
         </TableCell>
         <TableCell> 
          Res: <b>{data.res}</b>
         </TableCell>
        </TableRow>
        <TableRow>
         <TableCell colSpan={10}> 
          Fax No:<b>{data.faxno}</b> 
         </TableCell>
         <TableCell colSpan={10}> 
          Mobile: <b>{data.mobileno}</b>
         </TableCell>
        </TableRow>
        <TableRow>
         <TableCell> 
          Email Id: <b>{data.email}</b>
         </TableCell>
        </TableRow>
       </TableHead>
      </Table>
     </Paper>

     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
         <TableCell colSpan={10}> 
         <Grid item xs>
          Company Reg No:<b>{data.companyregno}</b>
          </Grid><br/>
          {/* data.comp ?
           <img src={`${comfilepath}`} width="300" height="200" crossorigin="anonymous"/>
           : null
          */}
         </TableCell>
         <TableCell colSpan={10}> 
         <Grid item xs>
          ESI Number: <b>{data.esino}</b>
         </Grid><br/>
         {/* data.esi ?
          <img src={`${esifilepath}`} width="300" height="200"/>
          : null
         */}
         </TableCell>
        </TableRow>
       </TableHead>
      </Table>
     </Paper>
     &nbsp;&nbsp;

     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
         <TableCell colSpan={10}> 
         <Grid item xs>
          GST/IGST No: <b>{data.gstno}</b>
         </Grid><br/>
         {/*data.gst ?
         <img src={`${gstfilepath}`} width="300" height="200"/>
         : null
          */}
         </TableCell>
         <TableCell colSpan={10}> 
         <Grid item xs>
          PF Number: <b>{data.pfno}</b>
          </Grid><br/>
          {/* data.pff ?
            <img src={`${pfffilepath}`} width="300" height="200"/>
            : null
          */}
          </TableCell>
        </TableRow>
        </TableHead>
      </Table>
     </Paper>
     &nbsp;&nbsp;

     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
         <TableCell> 
          Particulars of Registration / Statutory and Regulatory requirements if any <br/>
          <b>{data.particularreg}</b>
         </TableCell>
        </TableRow>
        <TableRow>
         <TableCell> 
          Capital Investment: <b>{data.capinvest}</b>
         </TableCell>
        </TableRow>

       <Grid item xs={12} >
        <TableCell>
         Additional Places of Supply:<br/>
            <div >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}><Typography variant="body2">Name</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">Address</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">City</Typography></Grid>
              <Grid item xs={12} sm={2}><Typography variant="body2">State</Typography></Grid>
              <Grid item xs={12} sm={2}><Typography variant="body2">GST</Typography></Grid>
            </Grid>
            {filterPlaceData.map((el, i)=> {
             return(
              <Grid container spacing={3}>
               <Grid item xs={12} sm={2}>
                <TextField value={el.placename} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={el.placeaddress} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={el.placecity} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={2}>
                <TextField value={el.placestate} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={2}>
                <TextField value={el.placegst} fullWidth/>
               </Grid>
              </Grid>
              );
             })
            }
          </div>
        </TableCell>
      </Grid>
      </TableHead>
     </Table>
     </Paper>
     &nbsp;&nbsp;

    <Paper>
     <Table  aria-label="spanning table">
      <TableHead>
       <TableRow>
        <TableCell>
        <Grid item xs>
         Aadhar No: <b>{data.aadharno}</b>
        </Grid><br/>
        {/* data.aadhar ?
          <img src={`${aadharfilepath}`} width="300" height="200"/>
          : null
        */}
        </TableCell>
        <TableCell>
        <Grid item xs>
         PAN: <b>{data.panno}</b>
        </Grid><br/>
        {/* data.pan ?
         <img src={`${panfilepath}`} width="300" height="200"/>
         : null
        */}
        </TableCell>
       </TableRow>
      </TableHead>    
     </Table>
    </Paper>
    &nbsp;&nbsp;
     
     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
          <TableCell> Details Of Land And Building </TableCell>
        </TableRow>
        <TableRow>
          <TableCell> 
            A) Area: <b>{data.landarea}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell> 
            B) Own (or) Rented: <b>{data.landown}</b>
          </TableCell>
        </TableRow>
        <TableRow>  
          <TableCell> 
           C) Power: <b>{data.landpower}</b>
          </TableCell>
        </TableRow>
       </TableHead>    
      </Table>
     </Paper>

     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
          <TableCell> 
           Turn Over: <b>{data.turnover}</b>
          </TableCell>
        </TableRow>
       </TableHead>    
      </Table>
     </Paper>

     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <Grid item xs={12} >
          <TableCell>
           Proprietor / Directors Details: <br/>
            <div >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={3}><Typography variant="body2">Name</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">Qualification</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">Experience</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">Address</Typography></Grid>
            </Grid>
            {filterPropData.map((el, i)=> {
             return(
              <Grid container spacing={6}>
               <Grid item xs={12} sm={3}>
                <TextField value={el.propname}/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={el.propqualify}/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={el.propexperience}/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={el.propaddress}/>
               </Grid>
              </Grid>
             );
            })
          }
            </div>
          </TableCell>
        </Grid>
       </TableHead>    
      </Table>
     </Paper>

        <Paper>
         <Table  aria-label="spanning table">
          <TableHead>
           <TableRow>
            <TableCell> Employee Particulars </TableCell>
           </TableRow>
           <TableRow>
            <TableCell> 
             A) Skilled M/F: (Num)<br/>
             <div >
             <Grid container spacing={3}>
              <Grid item xs={12} sm={3}><Typography variant="body2">Male</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">Female</Typography></Grid>
            </Grid>
             <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
               <TextField value={data.skillmale}/>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField value={data.skillfemale}/>
              </Grid>
             </Grid>
             </div>
            </TableCell>
           </TableRow>
           <TableRow>
            <TableCell> 
             B) Semi Skilled M/F: (Num) <br/>
              <div >
               <Grid container spacing={3}>
                <Grid item xs={12} sm={3}><Typography variant="body2">Male</Typography></Grid>
                <Grid item xs={12} sm={3}><Typography variant="body2">Female</Typography></Grid>
               </Grid>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                 <TextField value={data.semiskillmale}/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={data.semiskillfemale}/>
               </Grid>
               </Grid>
               </div>
            </TableCell>
           </TableRow>
          </TableHead>    
         </Table>
        </Paper>

       <Paper>
        <Table  aria-label="spanning table">
         <TableHead>
          <TableRow>
            <TableCell> 
             List Of Customers: ( Give Details) <br/>
              <b>{filterCustData.map((n)=> {
                  return(
                  <TableRow>{n.customerlist}</TableRow>
                  ); 
                })}</b>
            </TableCell>
          </TableRow>
          </TableHead>    
        </Table>
       </Paper>

       <Paper>
        <Table  aria-label="spanning table">
         <TableHead>
         <TableRow>
          <TableCell> Do you Inspect Brought Out materials? 
           <Grid item xs>
            <FormGroup>
              <RadioGroup row aria-label="inspectbrotout" name="inspectbrotout" value={data.inspectbrotout} fullWidth>
              {data.inspectbrotout === 'yes'?
                <FormControlLabel value="yes" control={<Radio color="primary" checked="data.inspectbrotout"/>} label="Yes" />
                :
                <FormControlLabel value="no" control={<Radio color="primary" checked="data.inspectbrotout"/>} label="No" />
              }
              </RadioGroup>
           </FormGroup>
           {data.inspectbrotout === 'yes'?
            <Grid item xs={12}>
             <TextField multiline value={data.inspectbrotoutyes} fullWidth />
            </Grid>
            : null
           }
          </Grid>
          </TableCell>
          <TableCell> 
            Is In-Process Inspection Done During Manufacturing?
            <Grid item xs>
             <FormGroup>
              <RadioGroup row aria-label="inprocess" name="inprocess" value={data.inprocess} fullWidth>
              {data.inprocess === 'yes'?
                <FormControlLabel value="yes" control={<Radio color="primary" checked="data.inprocess"/>} label="Yes" />
                :
                <FormControlLabel value="no" control={<Radio color="primary" checked="data.inprocess"/>} label="No" />
              }
              </RadioGroup>
           </FormGroup>
            </Grid>  
          </TableCell>
        </TableRow>
       </TableHead>    
      </Table>
     </Paper>
     
     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
          <TableCell> 
            Do you have testing facilities for brought out material? If not, what is done for testing?  ( Give Details)  
            <Grid item xs>
             <FormGroup>
                <RadioGroup row aria-label="testbrotout" name="testbrotout" value={data.testbrotout} fullWidth>
                {data.testbrotout === 'yes'?
                  <FormControlLabel value="yes" control={<Radio color="primary" checked="data.testbrotout"/>} label="Yes" />
                  :
                  <FormControlLabel value="no" control={<Radio color="primary" checked="data.testbrotout"/>} label="No" />
                }
                </RadioGroup>
             </FormGroup>
           {data.testbrotout === 'yes'?
            <Grid item xs={12}>
              <TextField multiline value={data.testbrotoutyes} fullWidth />
            </Grid>
            :
            <Grid item xs={12}>
             <TextField multiline value={data.testbrotoutno} fullWidth />
            </Grid>
          }
          </Grid> 
          </TableCell>
        </TableRow>
       </TableHead>    
      </Table>
     </Paper>

     <Paper>
    <Table  aria-label="spanning table">
     <TableHead>
      <TableRow>
        <TableCell>
          Do You have finished product inspection set up?   
          <Grid item xs>
           <FormGroup>
              <RadioGroup row aria-label="finproinspect" name="finproinspect" value={data.finproinspect} fullWidth>
              {data.finproinspect === 'yes'?
                <FormControlLabel value="yes" control={<Radio color="primary" checked="data.finproinspect"/>} label="Yes" />
                :
                <FormControlLabel value="no" control={<Radio color="primary" checked="data.finproinspect"/>} label="No" />
              }
              </RadioGroup>
           </FormGroup>
          </Grid>
        </TableCell>
        <TableCell>
          Can you comply with the quality certificate when ever called for ? ( Self Certification )
          <Grid item xs>
            <FormGroup>
              <RadioGroup row aria-label="qualcertify" name="qualcertify" value={data.qualcertify} fullWidth>
                {data.qualcertify === 'yes'?
                  <FormControlLabel value="yes" control={<Radio color="primary" checked="data.qualcertify"/>} label="Yes" />
                  :
                  <FormControlLabel value="no" control={<Radio color="primary" checked="data.qualcertify"/>} label="No" />
                }
              </RadioGroup>
           </FormGroup>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          Is Supplier An ISO 9001 / 14001 Certified? (If Yes, attach the copy of relevant certificate)
          <Grid item xs>
            <FormGroup>
              <RadioGroup row aria-label="isocertify" name="isocertify" value={data.isocertify} fullWidth>
                {data.isocertify === 'yes'?
                  <FormControlLabel value="yes" control={<Radio color="primary" checked="data.isocertify"/>} label="Yes" />
                  :
                  <FormControlLabel value="no" control={<Radio color="primary" checked="data.isocertify"/>} label="No" />
                }
              </RadioGroup>
           </FormGroup>
           {/* data.iso === '1'?
              <img src={`${isofilepath}`} width="300" height="200"/>
            : null
           */}
          </Grid>
        </TableCell>
        <TableCell>
          Is Supplier Referred By The Customer ? ( If So Give Details )
          <Grid item xs>
            <FormGroup>
              <RadioGroup row aria-label="referredby" name="referredby" value={data.referredby} fullWidth>
                {data.referredby === 'yes'?
                  <FormControlLabel value="yes" control={<Radio color="primary" checked="data.referredby"/>} label="Yes" />
                  :
                  <FormControlLabel value="no" control={<Radio color="primary" checked="data.referredby"/>} label="No" />
                }
              </RadioGroup>
           </FormGroup>
           {data.referredby === 'yes' ?
            <Grid item xs={12}>
             <TextField multiline value={data.referredbyyes} fullWidth />
            </Grid>
            :
            null
          }
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell> 
         Risk & Opportunities Identified: <br/>
         <b>{data.risk}</b>
        </TableCell>
        <TableCell>
        </TableCell>
      </TableRow>
      </TableHead>    
    </Table>
   </Paper>

   <Paper>
    <Table  aria-label="spanning table">
     <TableHead>
      <TableRow>
        <TableCell> 
          Describe The Scope Of Supply ( Mention the Product / Process for which  you want to register )? <br/>
          <b>{data.scopesupply}</b>
        </TableCell>
      </TableRow>
     </TableHead>    
    </Table>
   </Paper>

   <Paper>
    <Table  aria-label="spanning table">
     <TableHead>
      <Grid item xs={12} >
        <Typography variant="h6" gutterBottom align="center">
          Facilities Available
        </Typography>
        <TableRow>
        <TableCell>
         A. Machineries/ Equipments<br/>
          <div >
          <Grid container spacing={6}>
            <Grid item xs={12} sm={4}><Typography variant="body2">Machines</Typography></Grid>
            <Grid item xs={12} sm={4}><Typography variant="body2">Capacity</Typography></Grid>
            <Grid item xs={12} sm={4}><Typography variant="body2">Qty</Typography></Grid>
          </Grid>
          {filterMachData.map((el, i)=> {
           return(
            <Grid container spacing={6}>
             <Grid item xs={12} sm={4}>
              <TextField value={el.machines1}/>
             </Grid>
             <Grid item xs={12} sm={4}>
              <TextField value={el.capacity1}/>
             </Grid>
             <Grid item xs={12} sm={4}>
              <TextField value={el.qty1}/>
             </Grid>
            </Grid>
            );
           })
          }
        </div>
      </TableCell>
      </TableRow>
      <TableRow>
      <TableCell>
         B. Instruments<br/>
          <div >
          <Grid container spacing={6}>
            <Grid item xs={12} sm={3}><Typography variant="body2">Instruments</Typography></Grid>
            <Grid item xs={12} sm={2}><Typography variant="body2">Capacity</Typography></Grid>
            <Grid item xs={12} sm={3}><Typography variant="body2">Lease Count</Typography></Grid>
            <Grid item xs={12} sm={2}><Typography variant="body2">Qty</Typography></Grid>
            <Grid item xs={12} sm={2}><Typography variant="body2">Calibration</Typography></Grid>
          </Grid>
          {filterInstData.map((el,i) => {
            return(
            <Grid container spacing={6}>
             <Grid item xs={12} sm={3}>
              <TextField value={el.instruments2}/>
             </Grid>
             <Grid item xs={12} sm={2}>
              <TextField value={el.capacity2}/>
             </Grid>
             <Grid item xs={12} sm={3}>
              <TextField value={el.leasecount2}/>
             </Grid>
             <Grid item xs={12} sm={2}>
              <TextField value={el.qty2}/>
             </Grid>
             <Grid item xs={12} sm={2}>
              <TextField value={el.calibration2}/>
             </Grid>
            </Grid>
            );
          })
          }  
        </div>
      </TableCell>
      </TableRow>
    </Grid>
   </TableHead>    
    </Table>
   </Paper>
    </ModalBody>
    <ModalFooter>
        <Button className="btn btn-pill btn-danger active" onClick={viewRow}>Cancel</Button>
        <Button className="btn btn-pill btn-success active" onClick={ () => approveVendor(data.id)}>Approve</Button>
    </ModalFooter>
</Modal>

{/***** Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download}
          autoHideDuration={6000}
          onClose={handleRejectClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_reject}
              filename="RejectedVendor.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadReject} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleRejectClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        </Fragment>
        );
}

export default VendorReject;