import React, { Fragment, useState, useEffect }  from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { TablePagination, TextField, MenuItem, Typography } from '@material-ui/core';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import {getPayrollAdd, payrollAddUpload, deletePayrollAdd, editPayrollAdd, postPayroll, deleteAllPayrollAdd,
        getEmployee } from '../../../actions/asyncActions';
import { toast } from 'react-toastify';

const PayrollDeductions = (props) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const {responseData, facilityfields2, bulk_upload, uploadfile, payrollAdd,
           modal, currentData } = props
    //const location = props.location;
    //const responseData = location.state ? location.state.compData : null;
    //const responseData = location.state ? location.state.responseData : null;
    //const addData = location.state ? location.state.additionData : null;
    //console.log("responseData", responseData)

    const [employees, setEmployees] = useState([]);
    const filterEmp = employees.filter(x=>x.job_status === 'ACTIVE')

    const [payrollAddition, setPayrollAdd] = useState([])
    const filterPayrollAdd = payrollAdd ? payrollAdd.filter(x=>x.category === 'Deductions') : null;

    // const [bulk_upload, setUpload] = useState();
    // const [uploadfile, setFile] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
        getPayrollAdd(group_id)
        .then(resp => setPayrollAdd(resp.data))
    }, [payrollAdd]);

    useEffect( () => {
        getEmployee(group_id)
        .then(resp => setEmployees(resp.data))
    }, []);

    // const bulkUpload = () => {
    //     setUpload(!bulk_upload)
    // }

    // const onFileDrop = (files) => {
    //     setFile(document.getElementById('file').files[0]);
    // }

    
    // const handleUploadSubmit = async (event) => {
    //     const formData = new FormData()
    //     formData.append( 'file', uploadfile )
    //     formData.append('user_id', user.id)
    //     formData.append('group_id', group_id)
    //     formData.append('category', 'Payouts')
    //     payrollAddUpload(formData)
    //     .then(resp => {
    //         getPayrollAdd(group_id)
    //         .then(resp => setPayrollAdd(resp.data))
    //         setUpload(!bulk_upload)
    //         toast.success("File uploaded successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
    //     })
    //     .catch(err => {
    //         console.log("Error", err);
    //     })
    // }

    const backScreen = () => {
        props.history.push(`${process.env.PUBLIC_URL}/payroll`, {responseData, payrollAdd})
    }

    
    // const [modal, setModal] = useState(false)
    // const initialFormState = { id: '', description: '', amount:''}
    // const [currentData, setCurrentData ] = useState(initialFormState)

    // const handleInputChange = event => {
    //     const { name, value } = event.target
    //     setCurrentData({ ...currentData, [name]: value })
    // }
    
    // const editRow = n => {
    //     setModal(!modal)
    //     setCurrentData({ id: n.id, description: n.description, amount: n.amount })
    // }

    // const deleteRow = n => {
    //     const data = {
    //         id: n.id
    //     }
    //     //Delete API
    //     deletePayrollAdd(data)
    //     .then(resp => {
    //         getPayrollAdd(group_id)
    //         .then(resp => setPayrollAdd(resp.data))
    //         toast.error("Item deleted successfully!!!")
    //     })
    // }

    // const handleUpdate = (id, data) => {

    //     const cat = {
    //       id: id,
    //       description: currentData.description,
    //       amount: currentData.amount,
    //       user_id: user.id
    //     }
    //     editPayrollAdd(cat)
    //     .then(resp => {
    //         getPayrollAdd(group_id)
    //         .then(resp => setPayrollAdd(resp.data))
    //         toast.info("Additions updated successfully!!!")
    //         setModal(!modal)
    //     })
    //     .catch(err => {
    //     console.log("Error", err);
    //     })
    // }
    

    const pushPayroll = () => {
        let compData = [];
        payrollAdd.forEach((item, i) => {
            
            const payrollData = responseData.sort((a,b) => b.id - a.id)
            const payrollMonth = payrollData ? payrollData && payrollData[0].month : null
            const payrollYear = payrollData ? payrollData && payrollData[0].year : null
            //const payrollLop = payrollData ? payrollData && payrollData[0].lop : null
            //const payrollTotalDays = payrollData ? payrollData && payrollData[0].tot_days : null

            const payLop  = payrollData ? payrollData.filter(x=>x.emp_id === item.emp_id) : null
            const payrollLop = payLop ? payLop && payLop[0].lop : null
            const payrollTotalDays = payLop ? payLop && payLop[0].tot_days : null
            
            compData.push({ 
                emp_id: item.emp_id,
                emp_name: item.emp_name, 
                category: item.category, 
                component: item.description, 
                monthly: parseFloat(item.amount),
                month: payrollMonth,
                year: payrollYear,
                lop: payrollLop,
                tot_days: payrollTotalDays,
                net_salary: parseFloat(item.amount) * (payrollTotalDays - payrollLop) / payrollTotalDays
            })
        });
        const data = {
            items: compData,
            user_id: user.id,
            group_id: group_id
        }
        postPayroll(data)
        .then(resp => {
            console.log("response", resp.data)
            var payrollAdd = resp.data.items
            var data = {group_id: group_id}
            deleteAllPayrollAdd(data)
            .then(resp => {
                getPayrollAdd(group_id)
                .then(resp => setPayrollAdd(resp.data))
                toast.success("Data added successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
                props.history.push(`${process.env.PUBLIC_URL}/payrolldeduction`, {responseData, payrollAdd})
            })
            
        })
    }

    return(
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                            { filterPayrollAdd && filterPayrollAdd.length > 0 ?
                              <div className="mb-3 m-t-10">   
                                <div className="mb-3 m-t-10">
                                    <Typography variant="h5" gutterBottom align="center">
                                        Deductions for the month
                                    </Typography>
                                    {facilityfields2.map((el, i)=> {
                                    return (
                                    <div className="form-row m-t-10">
                                        <div className="col-xl-3 col-md-3 ml-2">
                                            <TextField id="name" select value={el.name || ''} fullWidth label="Employee"
                                                onChange={(e) => props.handleChangeFacility2(i, e)} 
                                                SelectProps={{ name: 'name' }} margin="dense" variant="outlined">
                                                {
                                                    filterEmp.map((n, index) => {
                                                        return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </div>
                                        <div className="col-xl-4 col-md-4">
                                            <TextField id="description" value={el.description || ''} fullWidth 
                                                name="description" label="Description" margin="dense" variant="outlined"
                                                onChange={(e) => props.handleChangeFacility2(i, e)} />
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <TextField id="amount" value={el.amount || ''} fullWidth type="number"
                                                name="amount" label="Amount" margin="dense" variant="outlined"
                                                onChange={(e) => props.handleChangeFacility2(i, e)} />
                                        </div>
                                        <div className="col-xl-1 col-md-1 m-t-10">
                                            <Button size="large" color="danger" variant="contained" onClick={() => props.handleRemoveFacility2(i)}>
                                                X 
                                            </Button>
                                        </div>
                                    </div>
                                    );
                                     })
                                    } 
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Emp ID</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {filterPayrollAdd.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr key={i}>
                                                     <td>{n.emp_id}</td>
                                                     <td>{n.description}</td>
                                                     <td>{n.amount}</td>
                                                     <td>
                                                     <i className="fa fa-edit" onClick={() => props.editRow(n)} id="TooltipTop" data-placement="bottom"
                                                        style={{ width: 35, fontSize: 16, padding: 11, color: 'blue' }}>
                                                        <UncontrolledTooltip placement="bottom" target="TooltipTop">
                                                            Edit Item
                                                        </UncontrolledTooltip>
                                                      </i>
                                                      <i className="fa fa-trash-o" onClick={() => props.deleteRow(n)} id="TooltipTop1" data-placement="bottom"
                                                        style={{ width: 35, fontSize: 16, padding: 11, color: 'red' }}>
                                                        <UncontrolledTooltip placement="bottom" target="TooltipTop1">
                                                            Delete Item
                                                        </UncontrolledTooltip>
                                                      </i>  
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                    :
                                    <div className="mb-3 m-t-10">
                                    <Typography variant="h5" gutterBottom align="center">
                                        Deductions for the month
                                    </Typography>
                                    {facilityfields2.map((el, i)=> {
                                    return (
                                    <div className="form-row m-t-10" key={i}>
                                        <div className="col-xl-3 col-md-3 ml-2">
                                            <TextField id="name" select value={el.name || ''} fullWidth label="Employee"
                                                onChange={(e) => props.handleChangeFacility2(i, e)} 
                                                SelectProps={{ name: 'name' }} margin="dense" variant="outlined">
                                                {
                                                    filterEmp.map((n, index) => {
                                                        return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </div>
                                        <div className="col-xl-4 col-md-4">
                                            <TextField id="description" value={el.description || ''} fullWidth 
                                                name="description" label="Description" margin="dense" variant="outlined"
                                                onChange={(e) => props.handleChangeFacility2(i, e)} />
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <TextField id="amount" value={el.amount || ''} fullWidth type="number"
                                                name="amount" label="Amount" margin="dense" variant="outlined"
                                                onChange={(e) => props.handleChangeFacility2(i, e)} />
                                        </div>
                                        <div className="col-xl-1 col-md-1 m-t-10">
                                            <Button size="large" color="danger" variant="contained" onClick={() => props.handleRemoveFacility2(i)}>
                                                X 
                                            </Button>
                                        </div>
                                    </div>
                                    );
                                     })
                                    }
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <button className="btn btn-pill btn-primary btn-sm m-t-10 ml-2" onClick={() => props.addClickFacility2()}>
                                        Add 
                                    </button> 
                                    <button className="btn btn-pill btn-warning btn-sm m-t-10 ml-2" type="button" onClick={() => props.bulkUpload()} >
                                        Upload
                                    </button>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                { filterPayrollAdd.length > 0 ?
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterPayrollAdd.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                 :
                                   <>
                                   </>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={bulk_upload} toggle={props.bulkUpload} centered={true}>
                <ModalHeader toggle={props.bulkUpload}>Upload</ModalHeader>
                <ModalBody>
                  <div className="input-cropper col-md-9 mb-3">
                      <input id="file" type="file" onChange={props.onFileDrop} />
                  </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={props.bulkUpload}>Cancel</Button>
                    <Button color="primary" onClick={() => props.handleUploadSubmit2()}>Save</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Payroll Deductions --> */}
            <Modal isOpen={modal} toggle={props.editRow} centered={true}>
                <ModalHeader toggle={props.editRow}>Edit Payroll Additions</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-12 mb-3">
                              <TextField fullWidth required id="description" label="Description" margin="dense" name="description" 
                               onChange={props.handleInputChange} value={currentData.description} variant="outlined" 
                              />
                            </div>
                            <div className="col-xl-12 mb-3">
                              <TextField fullWidth required id="amount" label="Amount" margin="dense" name="amount" 
                               onChange={props.handleInputChange} value={currentData.amount} variant="outlined" type="number"
                              />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={props.editRow}>Cancel</Button>
                    <Button color="primary" onClick={ () => props.handleUpdate(currentData.id, currentData)} >Save Changes</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default PayrollDeductions;