import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Button, Grid, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  table_root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  margin: {
    margin: theme.spacing(2),
  },
  errors: {
    color: 'red'
  },
  textfield: {
    padding: theme.spacing(1),
  }
}));

export default function HouseProperty(props) {
  const classes = useStyles();
  
  const { interest_borrowed1, facilityfields, typeHp, allowed_deduction, incomeHp1, totalHPIncome } = props;

return (
 <React.Fragment>
  <div className="container-fluid">
    <div className="card">
      <div className="card-block row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table table-sm table-bordered table-hover">
              <thead className="table-primary text-center">
                <tr>
                  <th>Particulars</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>A. Income from Self-occupied property</th>
                  <th></th>
                </tr>
                <tr>
                  <th>Value of property</th>
                  <th>0</th>
                </tr>
                <tr>
                  <th>Interest on borrowed capital</th>
                  <th>
                  <TextField value={interest_borrowed1} onChange={(e) => props.setInterestBorrowed1(e.target.value)}
                    id="interest_borrowed1" name="interest_borrowed1" fullWidth variant="outlined" margin="dense"
                    type="number"/>
                  </th>
                </tr>
                <tr>
                  <th>Allowed Deduction</th>
                  <th>{allowed_deduction}</th>
                </tr>
                <tr>
                  <th>Income from Self-occupied house property</th>
                  <th>
                  <TextField value={incomeHp1} fullWidth variant="outlined" margin="dense" />
                  </th>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th>
                    B. Income from Let-out/Deemed LO(Click "ADD" for more properties, "X" for clearing)
                  </th>
                </tr>
              </tbody>
              <tbody>
                {facilityfields.map((el,i) => {
                  const annualValue = parseFloat(el.gross_rent - el.tax_paid)
                  const deduction = parseFloat(el.gross_rent - el.tax_paid) * 0.3;
                  const deduction30 = Math.round((deduction + Number.EPSILON) * 100) / 100;
                  const incomeHouse = annualValue - deduction30 - el.interest_borrowed2
                  const incomeHp = Math.round((incomeHouse + Number.EPSILON) * 100) / 100;
                  return(
                  <>
                    <tr key={i}>
                      <th>Gross rent from the property</th>
                      <th>
                      <TextField value={el.gross_rent} onChange={(e) => props.handleChangeFacility(i, e)}
                        id="gross_rent" name="gross_rent" fullWidth variant="outlined" margin="dense"
                        autoComplete="gross_rent" type="number"/>
                      </th>
                    </tr>
                    <tr>
                      <th>Tax Paid to local authorities</th>
                      <th>
                      <TextField value={el.tax_paid} onChange={(e) => props.handleChangeFacility(i, e)}
                        id="tax_paid" name="tax_paid" fullWidth variant="outlined" margin="dense" type="number"/>
                      </th>
                    </tr>
                    <tr>
                      <th>Annual Value</th>
                      <th>
                      <TextField className="m-t-5" value={annualValue} fullWidth variant="outlined" margin="dense"/>
                      </th>
                    </tr>
                    <tr>
                      <th>Deduction of 30%</th>
                      <th>
                      <TextField value={deduction30} fullWidth type="number" variant="outlined" margin="dense"/>
                      </th>
                    </tr>
                    <tr>
                      <th>Interest on Borrowed Capital</th>
                      <th>
                      <TextField value={el.interest_borrowed2} onChange={(e) => props.handleChangeFacility(i, e)}
                        id="interest_borrowed2" name="interest_borrowed2" fullWidth 
                        type="number" variant="outlined" margin="dense"/>
                      </th>
                    </tr>
                    <tr>
                      <th>Income from Let-out/Deemed Let-out House Property</th>
                      <th>
                      <TextField value={incomeHp} type="number" variant="outlined" margin="dense"/>
                      </th>
                    </tr>
                    <tr>
                      <th>Click 'X' to clear the fields of (B) property</th>
                      <th>
                        <button className="btn btn-lg btn-danger" onClick={() => props.handleRemoveFacility(i)}>
                          X 
                        </button>
                      </th>
                    </tr>
                  </>
                  )
                  })
                }
              </tbody>
              <tbody>
                <tr>
                  <th>Click to ADD (B) Property</th>
                  <th>
                  <button className="btn btn-info btn-md" onClick={() => props.addClickFacility()}>
                    Add 
                  </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-xl-12 m-t-10">
          <h4 className="text-success text-center">Total House Property Income/Deduction: {totalHPIncome || 0}</h4>
        </div>
      </div>
    </div>              
  </div>
  {/*
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom align="center">
        Income From House Property
      </Typography>
       {facilityfields.map((el, i)=> {
        const annualValue = parseFloat(el.gross_rent - el.tax_paid)
        const deduction30 = parseFloat(el.gross_rent - el.tax_paid) * 0.3;
        const incomeHp = el.type === 1 ?  
        el.interest_borrowed1 < 200000 ? -(el.interest_borrowed1) : -(allowed_deduction)
        : 
        annualValue - deduction30 - el.interest_borrowed2
        return(
        <div key={i} className={classes.textfield}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <TextField className="m-t-5" onChange={(e) => props.handleChangeFacility(i, e, el)} value={el.name || ''} 
                id="name" name="name" label="Name" fullWidth autoComplete="name" autoFocus/>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField id="type" select value={el.type || ''} fullWidth label="Type of HP"
                    onChange={(e) => props.handleChangeFacility(i, e, el)} SelectProps={{ name: 'type' }}
                    margin="dense">
                    {
                        typeHp.map((n, index) => {
                            return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                        })
                    }
                </TextField>
            </Grid>
            { el.type === 1 ?
            <>
                <Grid item xs={12} sm={12}>
                    <TextField className="m-t-5" value={el.value} 
                    id="value" name="value" label="Value of the property" fullWidth autoComplete="value" type="number"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField className="m-t-5" value={el.interest_borrowed1} onChange={(e) => props.handleChangeFacility(i, e, el)}
                    id="interest_borrowed1" name="interest_borrowed1" label="Interest on Borrowed Capital" fullWidth 
                    autoComplete="interest_borrowed1" type="number"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField className="m-t-5" label="Allowed Deduction" 
                    value={allowed_deduction} fullWidth type="number"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField className="m-t-5" label="Income from House Property" 
                    value={incomeHp} fullWidth type="number"/>
                </Grid>
            </>
            : el.type === 2 || el.type === 3 ?
            <>
                <Grid item xs={12} sm={12}>
                    <TextField className="m-t-5" value={el.gross_rent} onChange={(e) => props.handleChangeFacility(i, e, el)}
                    id="gross_rent" name="gross_rent" label="Gross rent from the property" fullWidth 
                    autoComplete="gross_rent" type="number"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField className="m-t-5" value={el.tax_paid} onChange={(e) => props.handleChangeFacility(i, e, el)}
                    id="tax_paid" name="tax_paid" label="Tax Paid to local authorities" fullWidth 
                    autoComplete="tax_paid" type="number"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField className="m-t-5" value={annualValue} 
                    label="Annual Value" fullWidth type="number"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField className="m-t-5" value={deduction30} 
                    label="Deduction of 30%" fullWidth type="number"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField className="m-t-5" value={el.interest_borrowed2} onChange={(e) => props.handleChangeFacility(i, e, el)}
                    id="interest_borrowed2" name="interest_borrowed2" label="Interest on Borrowed Capital" fullWidth 
                    autoComplete="interest_borrowed2" type="number"/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField className="m-t-5" value={incomeHp} 
                    label="Income from House Property" fullWidth type="number"/>
                </Grid>
            </>
            : null
            }
            <Grid item xs={12} sm={12}>
              <Button size="large" color="danger" variant="contained" onClick={() => props.handleRemoveFacility(i)}>
                X 
              </Button>
            </Grid>
          </Grid>
        </div>
        );
      })}
      <Button size="medium" color="primary" variant="contained" onClick={() => props.addClickFacility()}>
        Add 
      </Button>
      <div className="col-xl-12 m-t-10">
        <h4 className="text-success text-center">Total House Property Income/Deduction: {totalHPIncome || 0}</h4>
      </div> 
    </Grid>
  </Grid>
  */}
</React.Fragment>
);
}
