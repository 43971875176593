import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { TablePagination } from '@material-ui/core';
import { getMasterInventory, postMasterInventory, editMasterInventory, getTransInventory } from '../../actions/asyncActions';

const InventoryTable = () => {
	const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    const [masterInventory, setMasterInventory] = useState([]);
    const [transInventory, setTransInventory] = useState([]);
    const [addmodal, setAddModal] = useState();
    const [values, setValues] = useState({});

    const initialFormState = { id: '', mfr:'', category:'', part_no:'', description:'', unit:''}
    const [ currentData, setCurrentData ] = useState(initialFormState)
    const [modal, setModal] = useState();
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getMasterInventory(group_id)
      .then(resp => setMasterInventory(resp.data))
    }, []);

    useEffect( () => {
      getTransInventory(group_id)
      .then(resp => setTransInventory(resp.data))
    }, []);

    const addRow = () => {
      setAddModal(!addmodal)
    }

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleSubmit = () => {
    	const data = {
          mfr: values.mfr,
          category: values.category,
          part_no: values.part_no,
          description: values.description,
          unit: values.unit,
          user_id: user.id,
          group_id: group_id,
        }
        console.log("data", data)
        postMasterInventory(data)
        .then(resp => {
            getMasterInventory(group_id)
            .then(resp => setMasterInventory(resp.data))
            setAddModal(!addmodal)
          })
          .catch(err => {
            console.log("Error", err);
        })
    }

    const editRow = n => {
     setModal(!modal)
     setCurrentData({ id: n.id, mfr: n.mfr, category: n.category, part_no: n.part_no, 
       description:n.description, unit:n.unit })
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentData({ ...currentData, [name]: value })
    }

    const handleUpdate = (id, data) => {
        const aaa = {
          id: id,
          mfr: data.mfr,
          category: data.category,
          part_no: data.part_no,
          description: data.description,
          unit: data.unit,
          user_id: user.id,
        }
        editMasterInventory(aaa)
        .then(resp => {
              getMasterInventory(group_id)
              .then(resp => setMasterInventory(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }

	return(
		<Fragment>
		 <Breadcrumb title="Inventory" parent="Inventory" />
		 	<div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Manufacturer</th>
                                                  <th scope="col">Category</th>
                                                  <th scope="col">PartName</th>
                                                  <th scope="col">Description</th>
                                                  <th scope="col">Unit</th>
                                                  <th scope="col">Qty</th>
                                                  <th scope="col">Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {masterInventory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  const aaa = transInventory.filter(x=>x.inventory_id === n.id);
                                                  const totalQty = aaa.reduce((a,b) => a + parseFloat(b.qty), 0);
                                                  const totalValue = aaa.reduce((a,b) => a + parseFloat(b.total), 0);
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.mfr}</td>
                                                     <td>{n.category}</td>
                                                     <td>{n.part_no}</td>
                                                     <td>{n.description}</td>
                                                     <td>{n.unit}</td>
                                                     <td>{totalQty}</td>
                                                     <td>{totalValue}</td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                       
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={masterInventory.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

		</Fragment>
	);
}

export default InventoryTable;
