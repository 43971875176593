import React, { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Home, ThumbsUp, MoreHorizontal } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import ProjectDetails from './ProjectDetails';
import ProjectApproved from './ProjectApproved';
import RaisedInvoices from './billing/RaisedInvoices';
import ApprovedInvoices from './billing/ApprovedInvoices';
import Overdue from './billing/Overdue';

const ProjectTab = () => {
    return (
        <Fragment>
            <Breadcrumb title="Project List" parent="Project" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <Tabs className="col-sm-12">
                        <TabList className="tabs tab-title">
                            <Tab className="current">
                                <Home />Projects
                            </Tab>
                            <Tab>
                                <ThumbsUp />Approved
                            </Tab>
                            {/* <Tab>
                                    <MoreHorizontal />RaisedInvoices
                                </Tab>
                                <Tab>
                                    <ThumbsUp />Approved Invoices
                                </Tab>
                                <Tab>
                                    <MoreHorizontal />OverDue
                                </Tab> */}
                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel>
                                <ProjectDetails />
                            </TabPanel>
                            <TabPanel>
                                <ProjectApproved />
                            </TabPanel>
                            {/* <TabPanel>
                                    <RaisedInvoices />
                                </TabPanel>
                                <TabPanel>
                                    <ApprovedInvoices />
                                </TabPanel>
                                <TabPanel>
                                    <Overdue />
                                </TabPanel> */}
                        </div>
                    </Tabs>
                </div>
            </div>
        </Fragment>
    );
}

export default ProjectTab;