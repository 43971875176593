import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { TextField, FormHelperText } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';
import { getSettings, editSettings, updateEmpPassword } from '../../actions/asyncActions';

const Settings = (props) => {
	const user = JSON.parse(localStorage.getItem("m_users"));
	const group_id = user.group_id;
	const userId = user && user.id;

	const [settings, setSettings] = useState([]);

	const [values, setValues] = useState({})
	const currency = [{ name: 'INR' }, { name: 'USD' }];
	const [currency_val, setCurrencyVal] = useState(null);
	const selectedCurrency = currency_val && currency_val.name;

	const [password, setPassword] = useState('');
	const [confirm_password, setConfirmPassword] = useState('');
	const [submitting, setIsSubmitting] = useState(false);
	const [errors, setErrors] = useState({});
	console.log("errors", errors)

	const handleUpdate = () => {
		const getSetData = settings.find(x => x.group_id === group_id);
		const settingsId = getSetData ? getSetData && getSetData.id : null;
		const data = {
			id: settingsId,
			currency: selectedCurrency
		}
		//Update Settings
		editSettings(data)
			.then(resp => {
				getSettings(group_id)
					.then(resp => setSettings(resp.data))
				setCurrencyVal(null)
				toast.success("Settings updated successfully!!!", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
			})
			.catch(err => {
				console.log(err)
			})
	}

	// useState(() => {
	// 	getSettings(group_id)
	// 		.then(resp => setSettings(resp.data))
	// })

	useEffect(() => {
		if (submitting) {
			if (Object.keys(errors).length === 0) {
				updatePassword();
			}
			else {
				setIsSubmitting(false);
			}
		}
	}, [errors])

	const updatePassword = async (e) => {
		const checkPasswd = password === '' || password === null
		const form = {
			password: checkPasswd ? '' : password,
			user_id: userId
		}
		try {
			if (password === '' || password === null) {

			} else {
				await updateEmpPassword(form)
					.then(resp => {
						setPassword(null)
						setConfirmPassword(null)
						setIsSubmitting(false);
						toast.success("Password updated successfully")
						props.history.push(`${process.env.PUBLIC_URL}/settings`)
					})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const validatePassword = (e) => {
		e.preventDefault()
		let errs = validate();
		setErrors(errs);
		setIsSubmitting(true);
	}

	const validate = () => {
		let err = {};
		if (password !== confirm_password) {
			err.password = "Passwords do not match"
		}
		if (password === null || confirm_password === null) {
			err.password = "Cannot update empty passwords"
		}
		return err;
	}

	return (
		<Fragment>
			<Breadcrumb title="Settings" parent="Group" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12 col-xl-6">
						<div className="ribbon-wrapper card">
							<div className="card-body text-center">
								<div className="ribbon ribbon-bookmark ribbon-secondary">My Settings</div>
								<div className="form-row">
									{/* <div className="col-xl-12">
                      <label className="col-form-label text-body" htmlFor="message-text">Currency:</label>
                      <Autocomplete
                        freeSolo
                        options={currency}
                        getOptionLabel={option => option.name}
                        value={currency_val}                      
                        onChange={(event, newValue) => {
                          setCurrencyVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params}margin="normal" variant="outlined" helperText="Please select Currency" fullWidth />
                        )}
                      />
                     </div> */}
									<div className="col-xl-12">
										<TextField type="password" margin="dense" variant="outlined" label="Enter Password" fullWidth
											name="password" value={password} onChange={(e) => {
												setPassword(e.target.value)
												setErrors({})
											}} />
										<FormHelperText className="text-danger">{errors.password}</FormHelperText>
									</div>
									<div className="col-xl-12">
										<TextField type="password" margin="dense" variant="outlined" label="Confirm Password" fullWidth
											name="confirm_password" value={confirm_password} onChange={(e) => {
												setConfirmPassword(e.target.value)
												setErrors({})
											}} />
									</div>
									<div className="col-xl-12 m-t-10">
										<button className="btn btn-success" onClick={validatePassword}>Update</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);

}

// export default Settings;
export default withRouter(Settings);