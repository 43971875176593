import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import { TextField, MenuItem, FormHelperText, TablePagination } from '@material-ui/core';
import {getIndentItems, getIndent, editIndent,  editIndentItems, getProjects, getClients, getVendorForm, getItemLone, 
  getItemLtwo, getItemLthree, getItemLfour, getItemLfive, getAllUser, getBomItems, getCostSheet, getPOItems } from '../../actions/asyncActions';

const ApprovedIndent = () => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const [indents, setIndents] = useState([]);
  const [indentItems, setIndentItems] = useState([]);
  const [projects, setProject] = useState([]);
  const [ clients, setClient ] = useState([]);

  const [lone, setLone] = useState([]);
  const [ltwo, setLtwo] = useState([]);
  const [lthree, setLthree] = useState([]);
  const [lfour, setLfour] = useState([]);
  const [lfive, setLfive] = useState([]);
  const [person, setPerson] = useState([]);
  const [bom_items, setBOMItems] = useState([]);
  const [ vendorForm, setVendors ] = useState([]);
  const approvedVendors = vendorForm.filter(x=> x.status === 'Approved');
  const [costsheet, setCostsheet] = useState([]);
  const [poitems, setPOItems] = useState([]);

  const initialFormState = { id: '', name:'', lfour_id:'', lthree_id:'', ltwo_id:'', lone_id:'' }
  const [ data, setCurrentData ] = useState(initialFormState);
  const [modal, setModal] = useState();
  const [selectvendormodal, setSelectVendorModal] = useState();
  const [budgetmodal, setBudgetModal] = useState();

  const filter_indents = indents.filter(s => s.status === 'Approved');
  //const filter_indents = indents.filter(s => s.status === 'Created' && s.mgr_id === user.id && s.bom_ref !== null);
  const [errors, setError] = React.useState({});

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect( () => {
    getIndent(group_id)
    .then(resp => setIndents(resp.data))
  }, []);

  useEffect( () => {
    getIndentItems(group_id)
    .then(resp => {
      setIndentItems(resp.data);
    })
  }, []);

  useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
  }, []);

  useEffect( () => {
      getClients(group_id)
      .then(resp => setClient(resp.data))
    }, []);
  
  useEffect( () => {
    getItemLone(group_id)
    .then(resp => {
      setLone(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLtwo(group_id)
    .then(resp => {
      setLtwo(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLthree(group_id)
    .then(resp => {
      setLthree(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLfour(group_id)
    .then(resp => {
      setLfour(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLfive(group_id)
    .then(resp => {
      setLfive(resp.data);
    })
  }, []);

  useEffect( () => {
    getAllUser(group_id)
    .then(resp => {
      setPerson(resp.data);
    })
  }, []);

  useEffect( () => {
    getBomItems(group_id)
    .then(resp => setBOMItems(resp.data))
  }, []);

  useEffect( () => {
    getVendorForm(group_id)
    .then(resp => setVendors(resp.data))
  }, []);

  useEffect( () => {
    getCostSheet(group_id)
    .then(resp => setCostsheet(resp.data))
  }, []);

  useEffect( () => {
    getPOItems(group_id)
    .then(resp => setPOItems(resp.data))
  }, []);

  const [selId, setSelectedID] = React.useState([]);
  const filter_indentItems = indentItems.filter(x => x.indent_id === selId);

  const viewRow = n => {
  	setModal(!modal)
  	setSelectedID(n.id);
    const lev5 = (lfive || []).find(x=> x.id == n.l5);
    const leve5 = lev5 && lev5.name;
    const lev4 = (lfour || []).find(x=> x.id == n.l4);
    const leve4 = lev4 && lev4.name;
    const lev3 = (lthree || []).find(x=> x.id == n.l3);
    const leve3 = lev3 && lev3.name;
    const lev2 = (ltwo || []).find(x=> x.id == n.l2);
    const leve2 = lev2 && lev2.name;
    const lev1 = (lone || []).find(x=> x.id == n.l1);
    const leve1 = lev1 && lev1.name;
    
    setCurrentData({ id: n.id, project_id: n.project_id, client_id: n.client_id, qty:n.qty, date: n.date, 
    l1:n.l1, l2:n.l2, l3:n.l3, l4:n.l4, l5:n.l5, status:n.status })
  }

  const approveIndent = (id, n) => {
    const data = {
      id: id,
      user_id: user.id,
      status: 'Approved',
    }
    editIndent(data)
    .then(resp => {
          getIndent(group_id)
          .then(resp => setIndents(resp.data))
          toast.success("Indent Approved !!!")
        })
        .catch(err => {
          console.log("Error", err);
        })
  }

  const handleSelectVendor = (n) => {
    setSelectedID(n.id)
    setSelectVendorModal(!selectvendormodal)
    setVendorId('')
    setRemarks('')
    setPrice('')
  }

  const [price, setPrice] = React.useState([{}]);
  const [bprice, setBprice] = useState();
  
  const handlePriceChange = (event, i) => {
    setBprice(event.target.value);
    const tempQtys = [...price];
    tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
    setPrice(tempQtys);
    // const aaa = {...tempQtys[i]};
    const orders = [];
    // const orders2 = [];
    tempQtys.forEach(async(obj, idx) => {
      const { id, qty, cogs_ref } = indentItems[i];
      orders.push({
        cogsId: cogs_ref,
        pprice: tempQtys[i].price
      });
    })
    
    //  price.forEach( async (obj, idx) => {
    //   if(obj.price > 0){
    //   const { id, qty, cogs_ref } = indentItems[idx]; //26,26,27  1000,2000,30000
    //   console.log("cogs_ref", cogs_ref);
    //   const data = {
    //     cogs_refId: cogs_ref, //26 26
    //     ppprice: obj.price, //1000 2000
    //   }
    //   console.log("data", data);
    //   var tot = 0
    //   for (var i=0; i<orders.length; i++) {
    //     if(cogs_ref === orders[i].cogs_refId){
    //     tot = parseInt(obj.price) + parseInt(orders[i].ppprice);
    //     orders.push({data});
    //     // setDDD(tot)
    //     } else {
    //     tot = obj.price;
    //     //  setEEE(tot);
    //     orders2.push({data});
    //    } 
    //   }
    //  }
    //  console.log("orders", orders);
    //  console.log("orders2", orders2);
    // });
//       var bbb = 0;
//       bbb = bbb + parseInt(obj.price);
//       console.log("bbb", bbb);
     

// const aaa = [{2000},{3000}]
    // var total = 0;
    // for (var i=0; i<tempQtys.length; i++) {
    //     total = parseInt(10100) + parseInt(tempQtys[i].price);
    // }
    //setBprice(total);

  };

  const [discount, setDiscount] = React.useState([{}]);
  
  const handleDiscount = (event, i) => {
    const tempQtys = [...discount];
    tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
    setDiscount(tempQtys);
  };

  const [vendor_id, setVendorId] = React.useState([{}]);
  const handleVendorSelect = (event, i) => {
    const { name, value } = event.target
    const tempQtys = [...vendor_id];
    tempQtys[i] = {...tempQtys[i], [name]: value};
    setVendorId(tempQtys);
  };

  const [remarks, setRemarks] = React.useState([{}]);
  const handleRemarksChange = (event, i) => {
    const tempQtys = [...remarks];
    tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
    setRemarks(tempQtys);
  };

  const initialFormState2 = {cogs_ref: '', qty: '', bprice:''}
  const [budgetData, setBudgetData] = useState(initialFormState2);
  const [bgtStatus, setBgtStatus] = useState(false);
  const [cogsid, setCogsId ] = useState([]);
  const cogsValue = costsheet.find(x=>x.id === cogsid);
  const getCogsValue = cogsValue && cogsValue.cost;
  const poItemCogs = poitems.filter(x=>x.cogs_ref === cogsid);
  const getPOtotal = poItemCogs.reduce((previous, current) => previous + current.total, 0);
  const availBudget = parseFloat(getCogsValue) - parseFloat(getPOtotal);
  const totalPOItemVal = parseFloat(budgetData.qty) * parseFloat(budgetData.bprice);
  
  const checkBudgetRow = (n, i) => {
    setCogsId(n.cogs_ref);
    setBudgetData({cogs_ref: n.cogs_ref, qty: n.qty, bprice: bprice})
    setBudgetModal(!budgetmodal)
    setError({})
  }

  const validateForm = (data) => {
    const errors = [];
    if(parseFloat(data.totalPOItemVal) > parseFloat(data.availBudget)){
      errors.totalPOItemVal = "Current PO Value cannot exceed the Budget";
     }
     return errors;
  }

  const [count, setCount] = useState(0);

  const handlePriorSubmit = () => {
    const aaa = {
      totalPOItemVal: totalPOItemVal,
      availBudget: availBudget,
    }
    const errors = validateForm(aaa);
      if(Object.keys(errors).length) {
        setBgtStatus(false)
        setError(errors);
      return;
    }
    setBgtStatus(true)
    setCount(count+1)
    setBudgetModal(!budgetmodal)
  }

  const handleSubmitPO = (event, i) => {
   const user = JSON.parse(localStorage.getItem("m_users"));
   let orders=[];
   let iorders = [];
   let sprice = [];
   let sdiscount = [];
   let indentItems_data = [];
   let vendorID = [];
    price.forEach( async (obj, idx) => {
        if(obj.price > 0){
          const indeItems = filter_indentItems[idx];
          const indd = indents[idx];
          iorders.push(indd);
          orders.push(indeItems);
          const { id, indent_id, l1, l2, l3, l4, l5, qty, price, date,
                 project_id, client_id, mgr_id, cogs_ref, user_id, group_id } = filter_indentItems[idx];
          sprice = parseFloat(obj.price || 0);
          //sdiscount = parseFloat(discount[idx].discount || 0);
          //const sdate = selectedDate[idx].date  || new Date();
          const sremarks = remarks[idx] ? remarks[idx].remarks : null;
          const svendor = vendor_id[idx] ? vendor_id[idx].vendor_id : null;
          let total;
          total = (parseFloat(sprice) * parseFloat(qty));
          // if(parseFloat(sdiscount) === undefined || 0){
          //   total = (parseFloat(sprice) * parseFloat(qty));
          // }
          // else {
          // total = (parseFloat(sprice) * parseFloat(qty)) - (parseFloat(sprice) * parseFloat(qty))/ parseFloat(sdiscount);
          // }
          if(svendor !== null){
            vendorID.push({
              svendor: svendor
            });  
          }
          indentItems_data.push({
             id: id,
             indent_id: indent_id,
             price: sprice,
             //discount: sdiscount,
             total: total,
             vendor_id: svendor,
             remarks:sremarks,
             cogs_ref: cogs_ref,
             project_id: project_id,
             mgr_id: mgr_id,
             client_id: client_id,
             status: 'VendorSelected',
             user_id: user_id,
             group_id: group_id          
          });
        }

      })
    // if(indentItems_data.length != count){
    //   toast.error("One or more items has to be validated for the Budget", {position: toast.POSITION.TOP_CENTER})
    // }
    //if(vendorID.length === indentItems_data.length && indentItems_data.length === count){
    if(vendorID.length === indentItems_data.length){
      editIndentItems(indentItems_data)
      .then(resp => {
        getIndent(group_id)
        .then(resp => setIndents(resp.data))
      })
      setSelectVendorModal(!selectvendormodal);
      setVendorId('')
      setRemarks('')
      setPrice('')
      toast.success("The PO is Successfully Prepared. !!!", {position: toast.POSITION.TOP_CENTER})  
    }
    else {
      toast.error("Please select Vendor", {position: toast.POSITION.TOP_CENTER})
    }
    
  }

	return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Indent</th>
                                                  <th scope="col">Project</th>
                                                  <th scope="col">Client</th>
                                                  <th scope="col">BOM</th>
                                                  <th scope="col">Status</th>
                                                  <th scope="col">Created Date</th>
                                                  <th scope="col">Approved By</th>
                                                  <th scope="col">Item(Nos)</th>
                                                  <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {filter_indents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                const projectName = (projects || []).find(x=> x.id === n.project_id);
              								                  const clientName = (clients || []).find(x=> x.id === n.client_id);
              								                  const noOfItems = indentItems.filter(x=>x.indent_id === n.id);
              								                  const totalItems = noOfItems.length;
              								                  const userName = person.find(x=>x.id === n.user_id);
              								                  const approvedBy = userName && userName.firstname;

                                                return (
                                                  <tr>
                                                   <th scope="row">{i+1}</th>
                                                   <td>{("IN-000").concat(n.id)}</td>
                                                   <td>{projectName && projectName.name}</td>
                                                   <td>{clientName && clientName.name}</td>
                                                   <td>{n.bom_ref}</td>
                                                   <td>{n.status}</td>
                                                   <td>
                                                    {new Date(n.createdAt).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                   </td>
                                                   <td>{approvedBy}</td>
                                                   <td>{totalItems}</td>
                                                   <td>
                                                    <i className="fa fa-eye" onClick={() => viewRow(n)} id="TooltipTop" data-placement="top"
                                                     style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff ' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      View Items
                                                     </UncontrolledTooltip>
                                                    </i>
                                                    <i className="fa fa-weibo" onClick={() => handleSelectVendor(n)} id="TooltipTop1" data-placement="top"
                                                     style={{ width: 35, fontSize: 18, padding: 11, color: '#5023a4 ' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop1">
                                                      Select Vendor
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </td>
                                                  </tr>
                                                );
                                               })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                      {/*
                                       <button className="btn btn-info btn-sm" type="button">
                                         Export
                                        </button>
                                      */}
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={filter_indents.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          {/* < !-- Using Modal for Viewing Indent --> */}
            <Modal isOpen={modal} toggle={viewRow} className="modal-dialog modal-xl">
                <ModalHeader toggle={viewRow}>View Indent</ModalHeader>   
                <ModalBody>
                   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Project</th>
                                                    <th scope="col">Part No</th>
                                                    <th scope="col">Part Name</th>
                                                    <th scope="col">Material</th>
                                                    <th scope="col">Finish Size</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filter_indentItems.map((n)=> {
                								                  const proj = projects.find(x=>x.id === n.project_id);
                								                  const clint = clients.find(x=>x.id === n.client_id);
                								                  const projectName = proj && proj.name;
                								                  const clientName = clint && clint.name;

                                                  return (
                                                    <tr>
                                                     <td>{projectName}</td>
                                                     <td>{n.part_num}</td>
                                                     <td>{n.part_name}</td>
                                                     <td>{n.material}</td>
                                                     <td>{n.finish_size}</td>
                                                     <td>{n.qty}</td>
                                                     <td>
                                                      {new Date(n.date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{n.remarks}</td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={viewRow}>Close</Button>
                </ModalFooter>
            </Modal>

          {/* < !-- Using Modal for Select Vendor and Prepare PO --> */}
            <Modal isOpen={selectvendormodal} toggle={handleSelectVendor} className="modal-dialog modal-xl">
                <ModalHeader toggle={handleSelectVendor}>Prepare PO</ModalHeader>   
                <ModalBody>
                   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr style={{fontSize:12}}>
                                                    <th scope="col">PartNo</th>
                                                    <th scope="col">PartName</th>
                                                    <th scope="col">Material</th>
                                                    <th scope="col">FinishSize</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Vendor</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">Remarks</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Budget</th> 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filter_indentItems.map((n, i) => {
                                                  return (
                                                    <tr style={{fontSize:12}}>
                                                     <td>{n.part_num}</td>
                                                     <td>{n.part_name}</td>
                                                     <td>{n.material}</td>
                                                     <td>{n.finish_size}</td>
                                                     <td>{n.qty}</td>
                                                     <td>
                                                      <TextField id="vendor_id" select value={vendor_id[i]? vendor_id[i].vendor_id: null}
                                                       onChange={(e) => handleVendorSelect(e, i)} SelectProps={{ name: 'vendor_id' }}
                                                        margin="dense" variant="outlined" >
                                                        {
                                                          approvedVendors.map((n, index) => {
                                                            return <MenuItem key={index} value={n.id}>{n.supname}</MenuItem>
                                                          })
                                                        }
                                                      </TextField>
                                                     </td>
                                                     <td>
                                                      <TextField type="text" name="price" key={i} margin="dense"
                                                       value = {price[i] ? price[i].price: 0} onChange={(e) => handlePriceChange(e, i)}/>
                                                     </td>
                                                     <td>{parseFloat(n.qty) * parseFloat(price[i] ? price[i].price: 0)}</td>
                                                     {/*
                                                     <td>
                                                      <TextField type="number" name="discount" key={i} variant="outlined" margin="dense" size="small"
                                                       value = {discount[i] ? discount[i].discount: 0} onChange={(e) => handleDiscount(e, i)} />
                                                     </td>
                                                     */}
                                                     <td>
                                                      <TextField name="remarks" key={i} margin="dense" 
                                                        value = {remarks[i] ? remarks[i].remarks: null} 
                                                        onChange={(e) => handleRemarksChange(e, i)} />
                                                     </td>
                                                     <td>
                                                      {new Date(n.date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>
                                                      <button className="btn btn-pill btn-secondary-gradien" type="button" 
                                                       onClick={() => checkBudgetRow(n,i)}>
                                                       Check
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={handleSelectVendor}>Close</Button>
                  <Button className="btn btn-primary" color="info" onClick={handleSubmitPO}>Prepare PO</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Modal for Checking Budget --> */}
            <Modal isOpen={budgetmodal} toggle={checkBudgetRow} size="lg">
                <ModalHeader toggle={checkBudgetRow}><h2>Budget</h2></ModalHeader>
                <ModalBody>
                  {/*
                    <form>
                        <div className="form-group">
                          <label className="col-form-label" htmlFor="recipient-name">Total Budget as per COGS:<h4>{getCogsValue}</h4></label> 
                        </div>
                        <div className="form-group">
                          <label className="col-form-label" htmlFor="recipient-name">Used PO Budget:<h4>{getPOtotal}</h4></label>
                        </div>
                        <div className="form-group">
                          <label className="col-form-label" htmlFor="recipient-name">Available Budget:<h4>{availBudget}</h4></label>
                        </div>
                    </form>
                  */}
                  <div className="col-sm-12 col-xl-12">
                    <form className="mega-inline">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="media p-20">
                              <div className="media-body">
                                <h4>Total <span className="badge badge-primary pull-right">{getCogsValue} INR</span></h4>
                                <p>Budget as per COGS</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="media p-20">
                              <div className="media-body">
                                <h4>Spent <span className="badge badge-warning pull-right">{getPOtotal} INR</span></h4>
                                <p>Budget as per PO</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="media p-20">
                              <div className="media-body">
                                <h4>Available <span className="badge badge-info pull-right">{availBudget} INR</span></h4>
                                <p>Budget</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="media p-20">
                              <div className="media-body">
                                <h4>Current <span className="badge badge-danger pull-right">{totalPOItemVal} INR</span></h4>
                                <p>PO cost for the item</p>
                                <FormHelperText className="text-danger">{errors.totalPOItemVal}</FormHelperText>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={checkBudgetRow}>Cancel</Button>
                  <Button color="primary" onClick={handlePriorSubmit}>Proceed</Button>
                </ModalFooter>
            </Modal>

        </Fragment>
    );
}
export default ApprovedIndent;