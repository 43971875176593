import React, { Fragment, useState, useEffect }  from 'react';
import { TablePagination, TextField } from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getEmployee, postBookLeave, sendLeaveEmail } from '../../../actions/asyncActions';
import firefox from '../../../assets/images/dashboard/firefox.png'
import { toast } from 'react-toastify';

const LeaveBalance = () => {
    const user = JSON.parse(localStorage.getItem("m_users"))
    const group_id = user.group_id;
    const userRole = user.role;

    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

    const [employees, setEmployees] = useState([])
    const leaveBal = employees.find(x=>x.id === user.emp_id)
    const managerId = leaveBal ? leaveBal.mgr_id : null;
    const status = leaveBal ? leaveBal.status : null;
    const medLeave = leaveBal ? leaveBal.med_availed : 0
    const casLeave = leaveBal ? leaveBal.cas_availed : 0
    const maternityLeave = leaveBal ? leaveBal.maternity_availed : 0

    const medRemaining = leaveBal ? leaveBal.med_leave - medLeave : 0
    const casRemaining = leaveBal ? leaveBal.cas_leave - casLeave : 0
    const maternityRemaining = leaveBal ? leaveBal.maternity_leave - maternityLeave : 0

    const abcd = employees.filter(x=>x.id === user.emp_id)
    const filterEmployees = abcd.length === 1 ? abcd : employees;

    useEffect( () => {
        getEmployee(group_id)
        .then(resp => setEmployees(resp.data))
    }, [])

    const types = [{name: 'Medical Leave'}, {name: 'Casual Leave'}, {name: 'Maternity/Paternity Leave'}, {name: 'Loss of Pay'}];
    const [typeVal, setTypeVal] = useState(null)
    const selectedType = typeVal ? typeVal && typeVal.name : null;

    const startFullHalf = [{name: 'Full Day', value: 1}, {name: 'Half Day', value: 0.5}]
    const [startHalfVal, setStartHalfVal] = useState(null)
    const selectedStartHalf = startHalfVal ? startHalfVal && startHalfVal.value : 1;

    const endFullHalf = [{name: 'Full Day', value: 1}, {name: 'Half Day', value: 0.5}]
    const [endHalfVal, setEndHalfVal] = useState(null)
    const selectedEndHalf = endHalfVal ? endHalfVal && endHalfVal.value : 1;

    const [start_date, setStartDate] = useState(new Date())
    const [end_date, setEndDate] = useState(new Date())

    const [description, setDescription] = useState("")
    
    var difference_In_Time = end_date.getTime() - start_date.getTime() ;
    var difference_In_Days = Math.round(difference_In_Time / (1000 * 3600 * 24));
    console.log("difference_In_Days", difference_In_Days)

    const totalOfHalf = selectedStartHalf + selectedEndHalf;

    const totalDays = totalOfHalf === 1 ? difference_In_Days : 
                      totalOfHalf === 1.5 ? difference_In_Days + 0.5 :
                      totalOfHalf === 2 ? difference_In_Days + 1 : 0;
    
    console.log("totalDays", totalDays)                      

    const [leavemodal, setLeaveModal] = useState(false)
    const leaveRow = () => {
      setLeaveModal(!leavemodal)
      setDescription("")
      setStartDate(new Date())
      setEndDate(new Date())
      setTypeVal(null)
      setStartHalfVal("")
      setEndHalfVal("")
    }

    const handleSubmit = () => {
      const empName = employees.find(x=>x.id === user.emp_id);
      const employeeId = empName && empName.employee_id;
      const employeeName = empName && empName.name;

      const mgrName = employees.find(x=>x.id === managerId);
      const managerName = mgrName && mgrName.name;
      const managerEmail = mgrName && mgrName.email;

      const startDate = new Date(start_date).toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
      const endDate = new Date(end_date).toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })

      
      const data = {
        type: selectedType,
        start_date: start_date,
        end_date: end_date,
        total: totalDays,
        description: description,
        status: 'Pending',
        emp_id: user.emp_id,
        mgr_id: managerId,
        user_id: user.id,
        group_id: group_id
      }

      const emailData = {
        employeeId: employeeId,
        employeeName: employeeName,
        mgrId: managerId,
        managerName: managerName,
        managerEmail: managerEmail,
        type: selectedType,
        start_date: startDate,
        end_date: endDate,
        total: totalDays,
        description: description
      }

      if(selectedType === 'Medical Leave' && (totalDays > medRemaining)){
        toast.warn("Leave Applied is greater than the leave balance!!!")
      }
      else if(selectedType === 'Casual Leave' && (totalDays > casRemaining)){
        toast.warn("Leave Applied is greater than the leave balance!!!")
      }
      else if(selectedType === 'Maternity/Paternity Leave' && (totalDays > maternityRemaining)){
        toast.warn("Leave Applied is greater than the leave balance!!!")
      }
      else {
        //Post Leave API
        postBookLeave(data)
        .then(resp => {
          if(resp.data.id){
            //Send Leave Approval Email to Manager
            if(managerEmail === ''){

            }
            else {
              sendLeaveEmail(emailData)
              toast.success("Leave Applied Successfully!!!")
              setLeaveModal(!leavemodal)
              setDescription("")
              setStartDate(new Date())
              setEndDate(new Date())
              setTypeVal(null)
              setStartHalfVal("")
              setEndHalfVal("")
            }
          }
          else{
            toast.warn("Something went wrong....")
          }
        })
      }
    }
    
    return(
      <Fragment>
        { userRole === 'Employee' && status === 'Approved' ? 
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body row">
                  <div className="col-xl-6 text-center">
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={firefox} alt="#" />
                  </div>
                  <div className="col-xl-6">
                    <h4 className="text-info">Here are your leave balances</h4>
                  </div>
                  <div className="col-xl-12 m-t-15">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered table-hover">
                        <thead className="table-info">
                          <tr>
                            <th>Type</th>
                            <th>Availed</th>
                            <th>Remaining</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Medical</td>
                            <td>{medLeave || 0}</td>
                            <td>{medRemaining}</td>
                          </tr>
                          <tr>
                            <td>Casual</td>
                            <td>{casLeave || 0}</td>
                            <td>{casRemaining}</td>
                          </tr>
                          <tr>
                            <td>Maternity</td>
                            <td>{maternityLeave || 0}</td>
                            <td>{maternityRemaining}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-xl-12 m-t-15">
                    <button className="btn btn-info" onClick={leaveRow}>Apply Leave</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         : userRole === 'Employee' && status === 'Submitted' ? 
         <div className="container-fluid">
	        <div className="row">
            <h3 className="text-danger">Leave Balances are yet to be updated</h3>
          </div>  
         </div>
         :
        <div className="container-fluid">
	      <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                 <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="double border-double">
                      <tr className="text-center table-success">
                       <th></th>
                       <th></th>
                       <th colSpan={3}>Medical Leave</th>
                       <th colSpan={3}>Casual Leave</th>
                       <th colSpan={3}>Maternity/Paternity</th>
                      </tr>
                      <tr className="text-center table-info">
                        <th>Emp ID</th>
                        <th>Emp Name</th>
                        <th>Allowed</th>
                        <th>Availed</th>
                        <th>Balance</th>
                        <th>Allowed</th>
                        <th>Availed</th>
                        <th>Balance</th>
                        <th>Allowed</th>
                        <th>Availed</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody className="double border-double">
                        {filterEmployees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                            const medBalance = n.med_leave - n.med_availed
                            const casBalance = n.cas_leave - n.cas_availed
                            const maternityBalance = n.maternity_leave - n.maternity_availed
                            return (
                             <tr>
                                <th scope="row">{n.employee_id}</th>
                                <th>{n.name}</th>
                                <td>{n.med_leave || 0}</td>
                                <td>{n.med_availed || 0}</td>
                                <td>{medBalance || 0}</td>
                                <td>{n.cas_leave || 0}</td>
                                <td>{n.cas_availed || 0}</td>
                                <td>{casBalance || 0}</td>
                                <td>{n.maternity_leave || 0}</td>
                                <td>{n.maternity_availed || 0}</td>
                                <td>{maternityBalance || 0}</td>
                             </tr>
                            );
                        })
                        }
                    </tbody>
                  </table>
                 </div>
                </div>
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block row">
                        <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">

                        </div>
                        <div className="col-sm-6 col-lg-6 col-xl-6">
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={employees.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      } 

      {/* < !-- Using Form Modal for Approving Employee --> */}
			  <Modal isOpen={leavemodal} toggle={leaveRow} className="modal-body" centered={true}>
          <ModalHeader className="text-center" toggle={leaveRow}>
            Book Leave
          </ModalHeader>   
          <ModalBody className="text-center">
            <form>
              <div className="form-row">
                <div className="col-xl-12">
                  <Autocomplete
                    freeSolo
                    options={types}
                    getOptionLabel={option => option.name}
                    value={typeVal}                      
                    onChange={(event, newValue) => {
                    setTypeVal(newValue);
                    }}            
                    renderInput={params => (
                    <TextField {...params} label="Leave Type" margin="dense" variant="outlined"
                    helperText="Please Select Type of Leave" fullWidth />
                    )}
                  /> 
                </div>
                <div className="col-xl-6 m-t-10">
                  <DatePicker className="form-control digits text-success"
                    selected={start_date}
                    value={start_date}
                    onChange={(date) => setStartDate(date)}
                    dateFormat= "dd-MMM-yyyy"
                    placeholderText="Select Start Date"
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="col-xl-6">
                  <Autocomplete
                    freeSolo
                    options={startFullHalf}
                    getOptionLabel={option => option.name}
                    value={startHalfVal}                      
                    onChange={(event, newValue) => {
                    setStartHalfVal(newValue);
                    }}            
                    renderInput={params => (
                    <TextField {...params} label="Full/Half Day" margin="dense" variant="outlined"
                    helperText="Full/Half Day Leave" fullWidth />
                    )}
                  /> 
                </div>
                <div className="col-xl-6 m-t-10">
                  <DatePicker className="form-control digits text-success"
                    selected={end_date}
                    value={end_date}
                    onChange={(date) => setEndDate(date)}
                    minDate={new Date(start_date)}
                    dateFormat= "dd-MMM-yyyy"
                    placeholderText="Select End Date"
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
                {start_date.getTime() === end_date.getTime() ?
                null :
                <div className="col-xl-6">
                  <Autocomplete
                    freeSolo
                    options={endFullHalf}
                    getOptionLabel={option => option.name}
                    value={endHalfVal}                      
                    onChange={(event, newValue) => {
                    setEndHalfVal(newValue);
                    }}            
                    renderInput={params => (
                    <TextField {...params} label="Full/Half Day" margin="dense" variant="outlined"
                      helperText="Full/Half Day Leave" fullWidth />
                    )}
                  /> 
                </div>
                }

                <div className="col-xl-12">
                  <label className="col-form-label" htmlFor="message-text">
                    Total No of Days: <b>{totalDays}</b>
                  </label>
                </div>
                <div className="col-xl-12">
                  <TextField multiline label="Description" variant="outlined" value={description} 
  	                onChange={(e) => setDescription(e.target.value)} rows={4} fullWidth/>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
				    <button className="btn btn-danger" onClick={leaveRow}>Cancel</button>
				    <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
}

export default LeaveBalance;