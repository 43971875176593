import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, Archive, CloudOff, ThumbsUp } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import MyIndent from './MyIndent';
import IndentWithBOM from './IndentWithBOM';
import IndentWithoutBOM from './IndentWithoutBOM';
import IndentApproved from './IndentApproved';

const IndentTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="Project Indent List" parent="Indent" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Home style={{color:"blue"}}/>Indent
                                </Tab>
                                <Tab>
                                    <Archive style={{color:"pink"}}/>BOM Applicable
                                </Tab>
                                <Tab>
                                    <CloudOff style={{color:"orange"}}/>BOM Not Applicable
                                </Tab>
                                <Tab>
                                    <ThumbsUp style={{color:"green"}}/>Approved
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <MyIndent />
                                </TabPanel>
                                <TabPanel>
                                    <IndentWithBOM />
                                </TabPanel>
                                <TabPanel >
                                    <IndentWithoutBOM />
                                </TabPanel>
                                <TabPanel >
                                    <IndentApproved />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

export default IndentTab;