import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import { FormHelperText, TablePagination } from '@material-ui/core';
import {getGrinItems, getVendorForm, getPOItems, editGrinItems, postQcReject, postGrLogs, 
        postTransInventory } from '../../actions/asyncActions';

const QualityCheck = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    //const [values, setValues] = useState({});
    const [errors, setError] = React.useState({});
    const [vendorForm, setVendors] = useState([]);
    // const [vendor_val, setVendorVal] = useState(null);
    // const vendorId = vendor_val && vendor_val.id;
    // const vendorName = vendor_val && vendor_val.supname;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [grinItems, setGrinItems] = useState([]);
    const [poitems, setPOItems] = useState([]);

    const filterGrinItems = grinItems.filter(x=>x.status === 'GRN Raised')
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getVendorForm(group_id)
      .then(resp => setVendors(resp.data))
    }, []);

    useEffect( () => {
      getGrinItems(user.group_id)
      .then(resp => setGrinItems(resp.data))
    }, []);

    useEffect( () => {
      getPOItems(group_id)
       .then(resp => setPOItems(resp.data))
    }, []);

    
  // const handleChange = event => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };

  
  const [qualitymodal, setQualityModal] = useState()
  const [approveId, setApproveId] = useState([]);
  const [approveQty, setApproveQty] = useState();
  const [rejectedQty, setRejectedQty] = useState(0);
  const [receivedQty, setRecdQty] = useState();
  
  const validateForm = (approveData) => {
    console.log("approveData", approveData);
    const errors = [];
    if((parseInt(approveData.apr_qty) + parseInt(rejectedQty)) !== parseInt(receivedQty)){
      errors.approveQty = "Please fill in both the quantities to match the GRN";
     }
     return errors;
  }
  
  const approveRow = n => {
    console.log("approveRow", n);
    setApproveId(n.id);
    setApproveQty(n.rec_qty);
    setRecdQty(n.rec_qty);
    setQualityModal(!qualitymodal);
    setError('');
  }

  const approveItem = () => {
    const rejData = grinItems.find(x=>x.id === approveId);
    const aaa = poitems.find(x=>x.id === rejData.po_itemno);
    const poRate = aaa && aaa.price; 
    const data = {
      id: approveId,
      apr_qty: approveQty,
      user_id: user.id,
      status: 'Approved',
    }
    const approveData = {
      grin: rejData.grin,
      gr_item_id: rejData.id,
      rec_qty: rejData.rec_qty,
      apr_qty: approveQty,
      po_no: rejData.po_no,
      po_itemno: rejData.po_itemno,
      poqty: rejData.poqty,
      grqty: rejData.grqty,
      vendor_id: rejData.vendor_id,
      status: 'Approved',
      client_id: rejData.client_id,
      project_id: rejData.project_id,
      user_id: user.id,
      company_id: user.company_id,
      group_id: group_id
    }
    const rejectData = {
      grin: rejData.grin,
      gr_item_id: rejData.id,
      rej_qty: rejectedQty,
      po_no: rejData.po_no,
      po_itemno: rejData.po_itemno,
      poqty: rejData.poqty,
      grqty: rejData.grqty,
      vendor_id: rejData.vendor_id,
      status: 'Rejected',
      client_id: rejData.client_id,
      project_id: rejData.project_id,
      user_id: user.id,
      company_id: user.company_id,
      group_id: group_id
    }
    const inventoryData = {
      inventory_id: rejData.inventory_id,
      qty: approveQty,
      rate: parseFloat(poRate),
      total: approveQty * parseFloat(poRate),
      date: new Date(),
      type: 'Inward',
      vendor_id: rejData.vendor_id, 
      project_id: rejData.project_id,
      user_id: user.id,
      group_id: group_id
    }
    const errors = validateForm(approveData);
    if(Object.keys(errors).length) {
      setError(errors);
      return;
    }
    postTransInventory(inventoryData)
    postGrLogs(approveData)
    editGrinItems(data)
    .then(resp => {
      getGrinItems(group_id)
      .then(resp => setGrinItems(resp.data))
      if(parseInt(rejectedQty) === 0){

      }
      else{
        postQcReject(rejectData)
        postGrLogs(rejectData)
      }
      setQualityModal(!qualitymodal)
      toast.success("Quality Approved !!!")
    })
    .catch(err => {
      console.log("Error", err);
    })
  }

        return (
            <Fragment>
            <Breadcrumb title="Quality Check" parent="Quality" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-info">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">GRN</th>
                                                  <th scope="col">Vendor</th>
                                                  <th scope="col">Item Name</th>
                                                  <th scope="col">Material</th>
                                                  <th scope="col">Qty</th>
                                                  <th scope="col">Date</th>
                                                  <th scope="col">Type</th>
                                                  <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterGrinItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  const aaa = vendorForm.find(x=>x.id === n.vendor_id);
                                                  const vendorName = aaa && (aaa.supname || []);
                                                  const porderItems = poitems.find(x=>x.id === n.po_itemno);
                                                  const partName = porderItems && porderItems.part_name;
                                                  const material = porderItems && porderItems.material;
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{('GR-000').concat(n.grin)}</td>
                                                     <td>{vendorName}</td>
                                                     <td>{partName}</td>
                                                     <td>{material}</td>
                                                     <td>{n.rec_qty}</td>
                                                     <td>
                                                      {new Date(n.createdAt).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{n.type}</td>
                                                     <td>
                                                     <button>
                                                      <i className="fa fa-thumbs-up" onClick={() => approveRow(n)} id="TooltipTop" data-placement="top"
                                                       style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff ' }}>
                                                       <UncontrolledTooltip placement="top" target="TooltipTop">
                                                        Approve Item
                                                       </UncontrolledTooltip>
                                                      </i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterGrinItems.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Approveing Items --> */}
            <Modal isOpen={qualitymodal} toggle={approveRow} className="modal-body" centered={true}>
                <ModalHeader className="text-center" toggle={approveRow}>
                  Please enter the approved Quantity and rejected Quantity
                </ModalHeader>   
                <ModalBody className="text-center">
                    <form>
                        <div className="form-row">
                            <div className="col-md-6">
                              <label className="col-form-label">Approved Qty:</label> 
                              <input className="form-control" type="text" onChange={e => setApproveQty(e.target.value)} value={approveQty} />
                              <FormHelperText className="text-danger">{errors.approveQty}</FormHelperText>
                            </div>
                            <div className="col-md-6">
                              <label className="col-form-label">Rejected Qty:</label> 
                              <input className="form-control" type="text" onChange={e => setRejectedQty(e.target.value)} value={rejectedQty} />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={approveRow}>Cancel</Button>
                    <Button color="primary" onClick={approveItem}>Approve</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default QualityCheck;