import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import {Grid, Paper, Table} from '@material-ui/core';
import { getVendorForm, getProjects, getGroup,
         getPorders, getPOItems } from '../../actions/asyncActions';
import * as moment from 'moment';


export default function MaterialTableDemo(props) {
  
  //const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const [vendors, setVendors] = useState([]);
  const [projects, setProject] = useState([]);
  const [porders, setPOrders] = useState([]);
  const [poitems, setPOItems] = useState([]);
  
  var numberToText = require('number2text'); //Rupees in words
  
  useEffect( () => {
    getVendorForm(group_id)
    .then(resp => setVendors(resp.data))
  }, []); 

  useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
  }, []);

  useEffect( () => {
    getPorders(group_id)
    .then(resp => setPOrders(resp.data))
  }, []);

  useEffect( () => {
    getPOItems(group_id)
    .then(resp => setPOItems(resp.data))
  }, []);

  const filterPOItems = poitems.filter(x=>x.po_no === props.lastPO);
  const getPODate = porders.find(x=>x.id === props.lastPO)
  const poDate = getPODate && getPODate.createdAt;
  const getTotal = filterPOItems.reduce((a, b) => a + b.total, 0);
  const totalInWords = numberToText(getTotal,'', true);

  const proName = projects.find(x=>x.id === props.selectedProject);
  const projectName = proName && proName.name;

  const POItems = () => {
    return filterPOItems.map((n , i) => {
      return(
      <tr key={i}>
        <td>{i+1}</td>
        {(n.part_name && n.material) ?
          <td>{(n.part_name).concat(`(${n.material})`)}</td>
          :
          <td>{n.part_name}</td>
        }
        <td>{moment(n.date).format('ll')}</td>
        <td>{n.qty}</td>
        <td>{n.price}</td>
        <td>{n.total}</td>
      </tr>
    );
  });
  }

  const {selectedProject, selectedVendor, placeSupply, dispatch, lastPO } = props;
  // const datee = new Date(getDate);
  // console.log("datee", datee)
  const podate = moment(poDate).format('ll');
  console.log("podate", podate)
  const vendorAddress = vendors.find(x=>x.id === props.selectedVendor);
  const supplier = vendorAddress && vendorAddress.supname;
  const address = vendorAddress && vendorAddress.factAddress;
  const gstno = vendorAddress && vendorAddress.gstno;
  const porderItems = POItems();

  return (
    <div id="printreceipt">
     <section className="sheet padding-5mm">
      <table style={{width:'95%'}}>
        <tr> 
          <td style={{textAlign:"center"}}>
           <h2>Purchase Order</h2>
          </td>
        </tr>
      </table>
      <table style={{width:'95%'}}>
      <tr>
      <td>
      <table style={{textAlign:'left', width:'50%'}}>
       <tr>
        <td style={{textAlign:'left', border:'1px solid'}}>
         <pre> 
          <b>Invoice To: <br/>
          VIGILENZ MEDICAL DEVICES SDN. BHD.,</b> <br/>
          Survey No. 8/2, <br/>
          Honnasandra Village Kasaba Hobli, Nelmangala Taluk <br/>
          Bengaluru Rural District - 562123, Karnataka <br/>
          Contact: 080- 27738318 / 319 / 320 <br/>
          GSTIN: 29AAACT6674J1ZS <br/>
          State Name: Karnataka, Code: 29 <br/>
          CIN: U74200KA1000PT0015003 <br/>
          E-Mail: care@nu-pie.com
         </pre>
        </td>
       </tr>
      </table>
      </td>
      <td>
      <table style={{textAlign:'right', width:'50%', border:'1px solid'}}>
       <tr>
        <td style={{textAlign:'left'}}>
         <pre>
          Voucher No:<br/>
          <b>{("PO-000").concat(props.lastPO)}</b>
         </pre>
        </td>
        <td style={{textAlign:'left'}}>
          <pre>
           Dated:<br/>
           <b>{podate}</b>
          </pre>
        </td>
       </tr>
       <tr>
        <td style={{textAlign:'left'}}>
         <pre>
          Vendor:<br/>
          <b>{supplier}</b>
         </pre>
        </td>
        <td style={{textAlign:'left'}}>
          <pre>
           Mode/Terms of Payment<br/>
           <b>As per Annexure</b>
          </pre>
        </td>
       </tr>
       <tr>
        <td style={{textAlign:'left'}}>
         <pre>
          Suppliers Ref/Order No. <br/>
           <b>Old Reference</b>
         </pre>
        </td>
        <td style={{textAlign:'left'}}>
          <pre>
           Other Reference(s)<br/>
           <b>{projectName}</b>
          </pre>
        </td>
       </tr>
       <tr>
        <td style={{textAlign:'left'}}>
         <pre>
          Dispatch through<br/>
          <b>{props.dispatch}</b>
         </pre>
        </td>
        <td style={{textAlign:'left'}}>
          <pre>
           Destination<br/>
           <b>{props.placeSupply}</b>
          </pre>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     </table>
     <table style={{textAlign:'center', width:'95%', border:'1px solid'}}>
      <tr>
        <td style={{textAlign:'left'}}>
         <pre>
          Terms of Delivery<br/>
       <b>Items can be accepted based on the inspection from our QA<br/>
          if any defects found, item should be replaced free of cost immediately</b>
         </pre>
        </td>
      </tr>
     </table>
     <table style={{width:'95%', border: '1px solid'}}>
       <thead>
        <tr>              
         <th align="left">Sl.No</th>
         <th align="left">Description of Goods</th>
         <th align="left">Due on</th>
         <th align="left">Quantity</th>
         <th align="left">Rate</th>
         <th align="left">Amount</th>
        </tr>
       </thead>
       <tbody>
         {porderItems}
       </tbody>
     </table>
     <table style={{width:'95%', border: '1px solid'}}>
      <tr>
       <td colSpan="5" align="center"><i>Input GST@ 18%</i></td>
      </tr>
     </table>
     <table style={{width:'95%', border: '1px solid'}}>
      <tr>
       <td colSpan="5" align="right">Grand Total: <b>{getTotal} INR</b></td>
      </tr>
     </table>
     <table style={{width:'95%'}}>
      <tr>
       <td align="left">
       <p>Amount Chargeable (in words) <br/>
       <b>{totalInWords}</b>
       </p>
       </td>
      </tr>
     </table>

     </section>
    </div>
  );
}