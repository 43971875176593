import React, { useState, useEffect } from 'react';
import { TablePagination } from '@material-ui/core';
import { UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import { getMaterialRejects, deleteMaterialRejects } from '../../actions/asyncActions';

const MaterialRejectTable = () => {
	const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
	const [materialrejects, setMaterialRejects] = useState([]);

	const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
	
	useEffect( () => {
	  getMaterialRejects(group_id)
	   .then(resp => setMaterialRejects(resp.data))
  }, []);
  
  const deleteRow = n => {
    const data = {
      id: n.id
    }
    deleteMaterialRejects(data)
    .then(resp => {
      getMaterialRejects(group_id)
      .then(resp => setMaterialRejects(resp.data))
      toast.warn("RTS Data deleted successfully!!!")
    })
  }

	return (
		<div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover">
                                        <thead className="table-primary">
                                            <tr>
                                              <th scope="col">#</th>
                                              <th scope="col">RTS</th>
                                              <th scope="col">PO</th>
                                              <th scope="col">POUser</th>
                                              <th scope="col">POUserEmail</th>
                                              <th scope="col">Vendor</th>
                                              <th scope="col">VendorEmail</th>
                                              <th scope="col">Reason</th>
                                              <th scope="col">Description</th>
                                              <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {materialrejects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((n, i)=> {
                                              return (
                                                <tr>
                                                 <th scope="row">{i+1}</th>
                                                 <td>{('RTS-').concat(n.id)}</td>
                                                 <td>{('PO-000').concat(n.poNumber)}</td>
                                                 <td>{n.generatedPoUser}</td>
                                                 <td>{n.generatedPoUserEmail}</td>
                                                 <td>{n.vendorName}</td>
                                                 <td>{n.vendorEmail}</td>
                                                 <td>{n.reason}</td>
                                                 <td>{n.description}</td>
                                                 <td>
                                                  <i className="fa fa-trash-o" onClick={() => deleteRow(n)} id="TooltipTop" data-placement="top"
                                                    style={{ width: 35, fontSize: 16, padding: 11, color: '#ff3300' }}>
                                                    <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      Delete RTS
                                                    </UncontrolledTooltip>
                                                  </i>
                                                 </td>
                                                </tr>
                                              );
                                            })
                                          }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                             <TablePagination
                               rowsPerPageOptions={[5, 10, 25]}
                               component="div"
                               count={materialrejects.length}
                               rowsPerPage={rowsPerPage}
                               page={page}
                               onChangePage={handleChangePage}
                               onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)	
}

export default MaterialRejectTable;