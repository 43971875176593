import React, { Component, Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Modal, ModalBody, Input } from 'reactstrap';
import { TextField, MenuItem, Select } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getProjects, getTsAdd, postTsAdd, getTasks } from '../../../actions/asyncActions';
import Litepicker from "litepicker";
import "litepicker/dist/plugins/ranges";
import * as moment from 'moment';
import { toast } from 'react-toastify';
var _ = require('lodash');


// var startDate = new Date();
function getAllDaysOfWeek(startDate) { //Pass date as Param
    // const curr = this.state.range.date1 || new Date();
    // console.log("startDate", startDate)
    // const curr =  new Date();
    const curr = new Date(startDate) || new Date();
    var first = curr.getDate() - curr.getDay();
    const obj = { project: '', task: '' };

    for (var i = 1; i <= 7; i++) {
        var next = new Date(curr.getTime());
        next.setDate(first + i);

        // const dateStr = new Date(next.toString()).toLocaleString('en-GB', { month: 'short', day: '2-digit', weekday:'short' });
        // obj[dateStr.substring(0, 3) + '_str'] = dateStr;

        const dateStr = new Date(next.toString()).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' });
        obj[dateStr.substring(0, 3) + '_str'] = dateStr; // Mon_str, Tue_str
        // obj[Project_str] = '';
    }
    obj['total'] = '';
    return obj;
}

export default class LT extends Component {
    state = {
        range: '',
        start_date: new Date(),
        timesheet: [{ ...getAllDaysOfWeek(new Date()) }],
        tsProject: [],
        tsAdd: [],
        tsTask: [],
    }
    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("m_users"));
        const group_id = user.group_id;

        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 7; // last day is the first day + 6
        var firstday = new Date(curr.setDate(first + 1))
        var lastday = new Date(curr.setDate(last))

        var nextWeek = new Date();
        var bcd = (nextWeek.getDate() - nextWeek.getDay()) + 7;
        var efg = bcd + 7;
        var nextStart = new Date(nextWeek.setDate(bcd + 1))
        var nextEnd = new Date(nextWeek.setDate(efg))

        var lastWeek = new Date();
        var zzz = (lastWeek.getDate() - lastWeek.getDay()) - 7;
        var yyy = zzz + 7;
        var lastStart = new Date(lastWeek.setDate(zzz + 1))
        var lastEnd = new Date(lastWeek.setDate(yyy))

        //lastweekdate.setDate(lastweekdate.getDate() - 7);

        //var nextweekdate = new Date();
        //nextweekdate.setDate(nextweekdate.getDate() + 7);


        const picker = new Litepicker({
            element: document.getElementById("litepicker"),
            plugins: ["ranges"],
            setup: (picker) => {
                picker.on('selected', (date1, date2) => {
                    // some action
                    this.handleDateChange({ date1, date2 })
                    //this.state.range.date1
                });
            },
            ranges: {
                position: 'left',
                customRanges: {
                    'Next Week': [new Date(nextStart), new Date(nextEnd)], // first start date then end date.
                    'This Week': [new Date(firstday), new Date(lastday)], // first start date then end date.
                    'Last Week': [new Date(lastStart), new Date(lastEnd)] // first start date then end date.
                }
            }
        });

        getProjects(group_id)
            .then(resp => this.setState({ tsProject: resp.data }))
        getTasks(group_id)
            .then(resp => this.setState({ tsTask: resp.data }))
        getTsAdd(group_id)
            .then(resp => {
                const tsAddition = resp.data;
                var a = firstday.setHours(0, 0, 0, 0);
                var b = lastday.setHours(0, 0, 0, 0);
                // const filterTs = tsAddition.filter(x => x.user_id === user.emp_id && x.group_id === group_id && x.date >= a && x.date <= b);
                const filterTs = tsAddition.filter(x => x.user_id === user.emp_id && x.group_id === group_id);
                // this.setState({ tsAdd: resp.data })
                let timeSheet = this.state.timesheet;
                const projIds = filterTs && filterTs.reduce((a, b) => {
                    if (a && b && !a.includes(b.project_id)) {
                        a.push(b.project_id)
                    }
                    return a;
                }, []);
                const taskIds = filterTs && filterTs.reduce((a, b) => {
                    if (a && b && !a.includes(b.task_id)) {
                        a.push(b.task_id)
                    }
                    return a;
                }, []);
                if (projIds && projIds.length >= 1) {
                    const bbb = [{ ...getAllDaysOfWeek(this.state.start_date) }];
                    for (let i = 0; i < projIds.length; i++) {
                        if (i > 0) {
                            timeSheet.push(bbb)
                        }
                    }
                }
                const timeSh = projIds && projIds.map((pid, index) => {
                    return timeSheet[index].Project_val = pid;
                })
                const taskSh = taskIds && taskIds.map((tid, index) => {
                    return timeSheet[index].Task_val = tid;
                })

                this.setState({ tsAdd: filterTs, timeSheet: timeSh })
            })
    }

    handleDateChange = (data) => {

        const { project, total, ...adbc } = { ...getAllDaysOfWeek(data.date1.dateInstance) }
        let timesheet = _.cloneDeep(this.state.timesheet)
        timesheet = timesheet.map((obj, ind) => {
            return {
                ...obj,
                ...adbc
            }
        })
        this.setState({ timesheet: timesheet, range: data, start_date: data.date1.dateInstance })

    }


    addNewRow = () => {
        this.setState((prevState) => {
            prevState.timesheet.push({ ...getAllDaysOfWeek(this.state.start_date) })
            // console.log("prevState", prevState)
            // prevState.timesheet[rowNum]['Tue_val'] = 60;
            // prevState.timesheet[rowNum]['Wed_val'] = 45;
            // prevState.timesheet[rowNum].total = "105";
            return prevState;
        })
    }

    handleSubmit = (rowNum, col, val, data) => (event) => {
        event.preventDefault()
        const user = JSON.parse(localStorage.getItem("m_users"));
        const group_id = user.group_id;
        const inputVal = event.target ? event.target.value : 0;
        if (inputVal === '') {

        } else if (parseFloat(inputVal) > 12) {
            toast.warning("Max limit is 12 hours!!!", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
        }
        else {
            const projectId = this.state.timesheet[rowNum].Project_val;
            const taskId = this.state.timesheet[rowNum].Task_val;
            if (projectId && taskId) {
                const addData = {
                    project_id: projectId,
                    task_id: taskId,
                    date: new Date(data),
                    time: inputVal,
                    user_id: user.emp_id,
                    group_id: group_id
                }
                // console.log("addData", addData)
                //post API
                postTsAdd(addData)
                    .then(resp => {
                        if (resp.data.updating) {
                            getTsAdd(group_id)
                                .then(resp => {
                                    const tsAddition = resp.data;
                                    const filterTs = tsAddition.filter(x => x.user_id === user.emp_id && x.group_id === group_id);
                                    let timeSheet = this.state.timesheet;
                                    const projIds = filterTs && filterTs.reduce((a, b) => {
                                        if (a && b && !a.includes(b.project_id)) {
                                            a.push(b.project_id)
                                        }
                                        return a;
                                    }, []);
                                    const taskIds = filterTs && filterTs.reduce((a, b) => {
                                        if (a && b && !a.includes(b.task_id)) {
                                            a.push(b.task_id)
                                        }
                                        return a;
                                    }, []);
                                    if (projIds && projIds.length >= 1) {
                                        const bbb = [{ ...getAllDaysOfWeek(this.state.start_date) }];
                                        for (let i = 0; i < projIds.length; i++) {
                                            if (i > 0) {
                                                timeSheet.push(bbb)
                                            }
                                        }
                                    }
                                    const timeSh = projIds && projIds.map((pid, index) => {
                                        return timeSheet[index].Project_val = pid;
                                    })
                                    const taskSh = taskIds && taskIds.map((tid, index) => {
                                        return timeSheet[index].Task_val = tid;
                                    })
                                    this.setState({ tsAdd: filterTs, timeSheet: timeSh })
                                    toast.warn("Entry updated successfully!!!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000 })
                                    //toast.secondary("Entry updated successfully!!!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000 })
                                })
                        }
                        else {
                            getTsAdd(group_id)
                                .then(resp => {
                                    const tsAddition = resp.data;
                                    const filterTs = tsAddition.filter(x => x.user_id === user.emp_id && x.group_id === group_id);
                                    // this.setState({ tsAdd: resp.data })
                                    let timeSheet = this.state.timesheet;
                                    const projIds = filterTs && filterTs.reduce((a, b) => {
                                        if (a && b && !a.includes(b.project_id)) {
                                            a.push(b.project_id)
                                        }
                                        return a;
                                    }, []);
                                    const taskIds = filterTs && filterTs.reduce((a, b) => {
                                        if (a && b && !a.includes(b.task_id)) {
                                            a.push(b.task_id)
                                        }
                                        return a;
                                    }, []);
                                    if (projIds && projIds.length >= 1) {
                                        const bbb = [{ ...getAllDaysOfWeek(this.state.start_date) }];
                                        for (let i = 0; i < projIds.length; i++) {
                                            if (i > 0) {
                                                timeSheet.push(bbb)
                                            }
                                        }
                                    }
                                    const timeSh = projIds && projIds.map((pid, index) => {
                                        return timeSheet[index].Project_val = pid;
                                    })
                                    const taskSh = taskIds && taskIds.map((tid, index) => {
                                        return timeSheet[index].Task_val = tid;
                                    })
                                    this.setState({ tsAdd: filterTs, timeSheet: timeSh })
                                    toast.success("Entry added successfully!!!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000 })
                                })
                        }

                    })
            }
            else {
                toast.warning("Please select the project!!!", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
            }
            // console.log("onBlur activated", rowNum, col, val, data, inputVal)
            // console.log("ProjectVal", this.state.timesheet[rowNum].Project_val)

        }

    }

    handleInputChange = (rowNum, col, val, data) => (event) => {
        event.preventDefault()
        const inputVal = event.target ? event.target.value : 0;
        console.log("AllData", col)
        if (col === 'Project') {
            this.setState((prevState) => {
                prevState.timesheet[rowNum][col + '_val'] = inputVal; //obj[rowNum][Project_val]
                return prevState;
            })
        }
        if (col === 'Task') {
            this.setState((prevState) => {
                prevState.timesheet[rowNum][col + '_val'] = inputVal;
                return prevState;
            })
        }
        // else {
        //     var hours = (inputVal/60); 
        //     var remHours = Math.floor(hours);
        //     var minutes = (hours - remHours) * 60;
        //     var remMinutes = Math.round(minutes);
        //     var results = remHours + ':' + remMinutes;
        //     console.log("results", results)
        // }

        //Submit 
        //this.state.timesheet[rowNum].project
        // const data = {

        //     date: data.substring(5,2),
        //     value: inputVal
        // }
        // this.state.timesheet[rowNum].project
        this.setState((prevState) => {
            prevState.timesheet[rowNum][col + '_val'] = inputVal;
            const prevTotal = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].reduce((acc, day) => {
                const q = prevState.timesheet[rowNum][day + '_val'] || 0;
                acc += parseFloat(q)
                return acc;
            }, 0);
            // var phours = prevTotal / 60;
            // var premHours = Math.floor(phours);
            // var pminutes = (phours - premHours) * 60;
            // var premMin = Math.round(pminutes);
            // var premMinutes = premMin === 0 ? '00' : premMin;
            // var presults = premHours + ':' + premMinutes;

            // prevState.timesheet[rowNum]['total'] = presults;
            prevState.timesheet[rowNum]['total'] = prevTotal;
            return prevState
        })
    }



    getList() {
        const user = JSON.parse(localStorage.getItem("m_users"));
        const group_id = user.group_id;
        // const allDays = this.getAllDaysOfWeek();
        // const allDays = this.state.timesheet;
        // console.log("allDays", allDays)
        // return allDays.map((date,i) => {
        //     return(
        //         <td key={i} style={{width:'10%'}}>
        //             {new Date(date).toLocaleString('en-GB', { month: 'short', day: '2-digit', weekday:'short' })}
        //         </td>
        //     );
        // });
        const tableBodyArray = []
        let THead = null;
        const headerCols = ['Project', 'Task', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Total'];

        // return this.state.timesheet.map((obj,i) => {
        this.state.timesheet.forEach((obj, i) => {

            if (i === 0) {
                THead = (
                    <thead>
                        <tr key={"thead" + i}>
                            {/* {Object.keys(obj).map((day, idx) => { */}
                            {
                                headerCols.map((col, index2) => {
                                    return (

                                        <td key={index2} style={{ width: '10%' }}>
                                            {/* {new Date(obj[day]).toLocaleString('en-GB', { month: 'short', day: '2-digit', weekday:'short' })} */}
                                            {obj[col + '_str'] ? obj[col + '_str'] :
                                                col === 'Project' ? "Project" :
                                                    col === 'Task' ? "Task" : "Total"
                                            }
                                        </td>
                                    )
                                })
                                // && !day.includes('_val') 

                            }
                            {/* } */}
                        </tr>
                    </thead>
                )
                let foundProject = '';
                let foundTask = '';
                let foundTotal = 0;
                tableBodyArray.push(
                    <tr key={"tbody" + i}>
                        {
                            headerCols.map((col, idx) => {
                                // console.log("column", obj[col + '_str'])
                                const tsAddition = this.state.tsAdd;
                                //const filterAdditions = tsAddition && tsAddition.filter(x => x.user_id === user.emp_id && x.group_id === group_id);

                                // const findTime = tsAddition ? tsAddition.find(x =>
                                //     (obj[new Date(x?.date?.toString()).toLocaleString('en-GB',
                                //         { year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }).substring(0, 3) + '_str']) === obj[col + '_str']
                                // ) : null
                                const findTime = tsAddition ? tsAddition.find(x => new Date(x.date).setHours(0, 0, 0, 0) === new Date(obj[col + '_str']).setHours(0, 0, 0, 0)) : null;
                                // console.log("objectsss", obj[col + '_str'])
                                // console.log("findTime", findTime)
                                const foundTime = findTime && findTime.time;

                                // const foundProject = findTime && findTime.project_id;
                                // console.log("foundProject", foundProject)
                                // const findTsProjects = this.state.tsProject.find(x => x.id === parseInt(foundProject));
                                // const foundDescription = findTsProjects && findTsProjects.description;
                                let descArr;
                                // if (foundTime !== undefined) {
                                const abcd = tsAddition[0]?.project_id
                                // console.log("abcd", abcd)
                                if (abcd !== undefined) {
                                    descArr = abcd;
                                }
                                if (findTime && findTime.project_id && findTime.project_id >= 1) {
                                    foundProject = findTime.project_id;
                                    foundTask = findTime.task_id;
                                    foundTotal += parseFloat(foundTime);
                                    obj.Project_val = foundProject;
                                    obj.Task_val = foundTask;
                                    obj.Total_val = foundTotal;
                                }
                                // const findTsProjects = this.state.tsProject.find(x => tsAddition.map(n => n.project_id).includes(parseInt(foundProject)))
                                const findTsProjects = this.state.tsProject.find(x => x.id === parseInt(foundProject));
                                const foundDescription = findTsProjects && findTsProjects.description;
                                const foundTsId = findTsProjects && findTsProjects.id;
                                // descArr = abcd ? abcd : ''
                                // console.log("foundProject", foundProject)
                                // }

                                //console.log("foundDescription", foundDescription)    

                                let val = obj[col + '_val'] ? obj[col + '_val'] :
                                    col === 'Project' ? obj.project :
                                        col === 'Task' ? obj.task :
                                            col === 'Total' ? obj.total : "";

                                return (
                                    <td key={idx} style={{ width: '10%' }}>
                                        {/* {new Date(obj[day]).toLocaleString('en-GB', { month: 'short', day: '2-digit', weekday:'short' })} */}
                                        {/* {obj[day]} */}
                                        {col === 'Project' ?
                                            <>
                                                {/* <input type="text" name="projectName" value={val} 
                                     onChange={this.handleInputChange(i,col, val, obj[col+ '_str'])}
                                     list="projectName"/>
                                    <datalist id="projectName" form="carform">
                                      {this.state.tsProject.map((n,ind) => 
                                          <option key={ind}>{n.description}</option>
                                        )
                                      }
                                      const dateStr = new Date(next.toString()).toLocaleString('en-GB', { month: 'short', day: '2-digit', weekday:'short' });

                                     obj[dateStr.substring(0, 3) + '_str'] = dateStr;
                                    </datalist> */}
                                                {/* { abcd.includes(obj[col+ '_str']) ?
                                        

                                    } */}
                                                <TextField select value={val} defaultValue={val}
                                                    onChange={this.handleInputChange(i, col, val, obj[col + '_str'])}
                                                    margin="dense" variant="outlined" fullWidth>
                                                    {
                                                        this.state.tsProject.map((n, index) => {
                                                            return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                                                        })
                                                    }
                                                </TextField>
                                                {/* <Input type="select" name="ccmonth" id="ccmonth" 
                                     onChange={this.handleInputChange(i,col, val, obj[col+ '_str'])}>
                                        { this.state.tsProject.map((dropdown, pindex) => { 
                                            return <option key={pindex} dropdown={dropdown} value={dropdown.id}>
                                                {dropdown.description}</option>;
                                            })
                                        }
                                    </Input> */}
                                            </>
                                            : col === 'Task' ?

                                                <TextField select value={val} defaultValue={val}
                                                    onChange={this.handleInputChange(i, col, val, obj[col + '_str'])}
                                                    margin="dense" variant="outlined" fullWidth>
                                                    {
                                                        this.state.tsTask.filter(x => x.project_id === this.state.timesheet[i].Project_val).map((n, index) => {
                                                            return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                                                        })
                                                    }
                                                </TextField>
                                                :
                                                <input
                                                    type="text"
                                                    defaultValue={foundTime || val}
                                                    // defaultValue={foundTime ? foundTime : null}
                                                    onChange={this.handleInputChange(i, col, val, obj[col + '_str'])}
                                                    onBlur={this.handleSubmit(i, col, val, obj[col + '_str'])}
                                                    style={{ width: '100%' }} />
                                        }
                                    </td>
                                )

                            })
                        }
                    </tr>
                )

            }
            else {
                let foundProject = '';
                let foundTotal = 0;
                tableBodyArray.push(
                    <tr key={"tbody" + i}>
                        {
                            headerCols.map((col, idx) => {
                                const tsAddition = this.state.tsAdd;
                                // const filterAdditions = tsAddition && tsAddition.filter(x => x.user_id === user.id && x.group_id === group_id);
                                // const findTime = filterAdditions ? filterAdditions.find(x =>
                                //     (obj[new Date(x.date.toString()).toLocaleString('en-GB',
                                //         { year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }).substring(0, 3) + '_str']) === obj[col + '_str']
                                // ) : null
                                const findTime = tsAddition ? tsAddition.find(x => new Date(x.date).setHours(0, 0, 0, 0) === new Date(obj[col + '_str']).setHours(0, 0, 0, 0) && x.project_id === obj.Project_val) : null
                                const foundTime = findTime && findTime.time;
                                // const foundProject = findTime && findTime.project_id;
                                // const findTsProjects = this.state.tsProject.find(x => x.id === parseInt(foundProject));
                                // const foundDescription = findTsProjects && findTsProjects.description;

                                if (findTime && findTime.project_id && findTime.project_id >= 1) {
                                    foundProject = findTime.project_id;
                                    foundTotal += parseFloat(foundTime);
                                    obj.Project_val = foundProject;
                                    obj.Total_val = foundTotal;
                                }

                                let val = obj[col + '_val'] ? obj[col + '_val'] :
                                    col === 'Project' ? obj.project :
                                        col === 'Total' ? obj.total : "";

                                return (
                                    <td key={idx} style={{ width: '10%' }}>
                                        {col === 'Project' ?
                                            //  <Autocomplete
                                            //     freeSolo
                                            //     options={this.state.tsProject}
                                            //     getOptionLabel={option => option.description}
                                            //     defaultValue={val}
                                            //     onChange={this.handleInputChange(i,col, val, obj[col+ '_str'])}
                                            //     renderInput={params => (
                                            //         <TextField {...params} label="Select Project" margin="dense" 
                                            //           variant="outlined" />
                                            //     )}    
                                            //  />
                                            <>
                                                <TextField select value={val} defaultValue={val}
                                                    onChange={this.handleInputChange(i, col, val, obj[col + '_str'])}
                                                    margin="dense" variant="outlined" label="Project" fullWidth>
                                                    {
                                                        this.state.tsProject.filter(x => x.id !== this.state.timesheet[0].Project_val).map((n, index) => {
                                                            return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                                                        })
                                                    }
                                                </TextField>

                                                {/* <Input type="select" name="ccmonth" id="ccmonth" 
                                     onChange={this.handleInputChange(i,col, val, obj[col+ '_str'])} fullWidth>
                                        { this.state.tsProject.map(dropdown => { 
                                            return <option dropdown={dropdown} value={dropdown.id}>
                                                {dropdown.description}</option>;
                                            })
                                        }
                                    </Input> */}
                                            </>
                                            : col === 'Task' ?

                                                <TextField select value={val} defaultValue={val}
                                                    onChange={this.handleInputChange(i, col, val, obj[col + '_str'])}
                                                    margin="dense" variant="outlined" fullWidth>
                                                    {
                                                        this.state.tsTask.filter(x => x.project_id === this.state.timesheet[i].Project_val).map((n, index) => {
                                                            return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                                                        })
                                                    }
                                                </TextField>
                                                :
                                                <input
                                                    type="text"
                                                    defaultValue={foundTime || val}
                                                    onChange={this.handleInputChange(i, col, val, obj[col + '_str'])}
                                                    onBlur={this.handleSubmit(i, col, val, obj[col + '_str'])}
                                                    style={{ width: '100%' }} />
                                        }
                                    </td>
                                )

                            })
                        }
                    </tr>
                )
            }

        })
        return (
            <React.Fragment>
                {THead}
                <tbody>
                    {tableBodyArray.map(trow => trow)}
                </tbody>
            </React.Fragment>
        )
    }



    render() {
        console.log("timesheet", this.state.timesheet)
        //console.log("tsAdd", this.state.tsAdd)
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <h5 className="text-left">Timesheet</h5>
                                        </div>
                                        <div className="col-xl-6 text-right">
                                            <input
                                                onChange={() => this.handleDateChange()}
                                                id="litepicker"
                                                style={{ textAlign: 'center' }}
                                                placeholder="This Week"
                                                style={{ padding: '10px 25px' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        {/* <thead>
                                        <tr>
                                            <td style={{width:'15%', textAlign:'left'}}>Project</td>
                                            {this.getList()}
                                            <td>Total</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{width:'15%', textAlign:'left'}}>Project</td>
                                            {this.getList()}
                                            <td>Total</td>
                                        </tr>
                                    </tbody> */}

                                        {this.getList()}
                                    </table>
                                </div>
                                <div>
                                    <button className="btn btn-info btn-sm" type="button" onClick={this.addNewRow}>
                                        Add New
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}


//   const Timesheet = () => {
    // const user = JSON.parse(localStorage.getItem("m_users"));
    // const group_id = user.group_id;

//     const [openmodal, setOpenModal] = useState(false)
//     const [tsProject, setTsProject] = useState([])

//     const [type_val, setTypeVal] = useState(null)
//     const selectedType = type_val && type_val.description;

//     const [values, setValues] = useState([{types:'', modal:''}])
//     console.log("values", values)

//     const [startDate, setStartDate] = useState(new Date());
//     const [endDate, setEndDate] = useState(new Date());

//     useEffect(() => {
//         getTsProject(group_id)
//         .then(resp => setTsProject(resp.data))
//     }, []);

//     const [count, setCount] = useState([{id:''}])
//     console.log("count", count)
//     // const [modal, setModal] = useState(null)
//     // console.log("modal", modal)

//     const openRow = (i) => {
//         const tempValues = [...count];
//         tempValues[i] = {...tempValues[i], id: i};
//         setCount(tempValues)
//         setOpenModal(!openmodal)
//         //setModal(index)
//     }

//     const handleAddProject = () => {

//     }

//     const [facilityfields, setFacilityFields] = 
//     useState([{type_val:'' }]);

//     function addClickFacility(){
//         const values = [...facilityfields];
//         values.push({type_val:''  });
//         setFacilityFields(values);
//     }

//     function handleRemoveFacility(i){
//         const values = [...facilityfields];
//         values.splice(i,1);
//         setFacilityFields(values);
//     }

//     var curr = new Date; // get current date
//     var first = curr.getDate() - curr.getDay();
//     console.log("first", first)
//     //var firstday = (new Date(curr.setDate(first+1))).toString();
//     //console.log("firstday", firstday)
//     const getAllDaysOfWeek = () => {
//         let arr = [];
//         for(var i = 1; i <= 7; i++) {
//             var next = new Date(curr.getTime());
//             next.setDate(first+i);
//             arr.push(next.toString())
//             // alert(next.toString());

//         }
//         return arr; 
//     }
//     const getAllDays = getAllDaysOfWeek()
//     //console.log("getAllDays", getAllDays)

//     const getList = () => {
//         //var next = new Date(curr.getTime()).toLocaleString('en-GB', { month: 'short', day: '2-digit' })
//         return getAllDays.map((date,i) => {
//             return(
//                 <td key={i} style={{width:'10%'}}>
//                     {new Date(date).toLocaleString('en-GB', { month: 'short', day: '2-digit', weekday:'short' })}
//                 </td>
//             );
//         });

//     }
//     const handleTimeChange = (e,i) => {
//         console.log("timedata", i)
//     }

//     const getInputFields = () => {
//         return getAllDays.map((date,i) => {
//             return(
//                 <td key={i} style={{width:'10%'}}>
//                     <input type="text" disabled={selectedType ? false : true } onChange={(e) => selectedType ? handleTimeChange(e,i) : null} style={{width:'100%'}} />
//                 </td>
//             );
//         });
//     }

//     const getAllList = getList()
//     const getAllInputFields = getInputFields()

//     return(
//         <Fragment>
//             <div className="container-fluid">
//                 <div className="row">
//                     <div className="col-sm-12">
//                         <div className="card">
//                             <div className="card-header">
//                                 <div className="row">
//                                     <div className="col-xl-3">
//                                         <h5 className="text-left">Timesheet</h5>
//                                     </div>
//                                     <div className="col-xl-9">
//                                         <h5 className="text-right">Calendar</h5>
//                                         <div className="row">
//                                             <div className="col-xl-3">
//                                                 <DatePicker className="form-control digits"
//                                                     style={{float:'right'}}
//                                                     selected={startDate}
//                                                     selectsStart
//                                                     startDate={startDate}
//                                                     endDate={endDate}
//                                                     onChange={date => setStartDate(date)}
//                                                     dateFormat="dd/MM/yyyy"
//                                                 />
//                                             </div>
//                                             <div className="col-xl-3">
//                                                 <DatePicker className="form-control digits"
//                                                     style={{float:'right'}}
//                                                     selected={endDate}
//                                                     selectsEnd
//                                                     startDate={startDate}
//                                                     endDate={endDate}
//                                                     minDate={startDate}
//                                                     onChange={date => setEndDate(date)}
//                                                     dateFormat="dd/MM/yyyy"
//                                                 />  
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="table-responsive">
//                                 <table className="table">
//                                     <thead>
//                                         <tr>
//                                             <td style={{width:'15%', textAlign:'left'}}>Project</td>
//                                             {getAllList}
//                                             <td>Total</td>
//                                         </tr>
//                                     </thead>
//                                     {/* <tbody>
//                                         <tr>
//                                         {selectedType ?
//                                         <>
//                                             <td style={{width:'20%', textAlign:'left'}}>
//                                                {selectedType} 
//                                             </td>
//                                             {getAllInputFields}
//                                             <td>

//                                             </td>
//                                         </>
//                                         :
//                                             <td style={{width:'20%', textAlign:'left'}}>
//                                               <button className="btn btn-link" onClick={()=> openRow()}>
//                                                 <i className="fa fa-plus-circle"/>Task
//                                               </button>
//                                             </td>
//                                         } 
//                                         </tr>
//                                     </tbody> */}
//                                     <tbody>
//                                     {facilityfields.map((el,i) => {
//                                         return(
//                                             <tr key={i}>
//                                             {selectedType ?
//                                             <>
//                                                 <td style={{width:'15%', textAlign:'left'}}>
//                                                 {selectedType} 
//                                                 </td>
//                                                 {getAllInputFields}
//                                                 <td>
//                                                 <button className="btn btn-sn btn-danger" onClick={() => handleRemoveFacility(i)}>
//                                                     X 
//                                                 </button>
//                                                 </td>
//                                             </>
//                                             :
//                                             <>   
//                                                 <td style={{width:'15%', textAlign:'left'}}>
//                                                     <button className="btn btn-link" onClick={()=> openRow(i)}>
//                                                         <i className="fa fa-plus-circle"/>Task
//                                                     </button>
//                                                 </td>
//                                                 {getAllInputFields}
//                                                 <td>
//                                                     <button className="btn btn-sm btn-danger" onClick={() => handleRemoveFacility(i)}>
//                                                         X 
//                                                     </button>
//                                                 </td>
//                                             </>
//                                             } 
//                                             </tr>
//                                         )
//                                         })
//                                     }   
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-xl-12">
//                         <div className="card">
//                             <div className="card-block row">
//                                 <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
//                                     <button className="btn btn-info btn-sm" type="button" onClick={() => addClickFacility()}>
//                                         Add New
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* Select Project modal */}
//             <Modal isOpen={openmodal} toggle={openRow} centered={true}>   
//                 <form onSubmit={handleAddProject}>
//                     <ModalBody>
//                         <div className="row">
//                             <div className="col-xl-12">
//                               <Autocomplete
//                                 freeSolo
//                                 options={tsProject}
//                                 getOptionLabel={option => option.description}
//                                 value={type_val}
//                                 onChange={(e, newValue) => {
//                                     //setValues({ ...values, types: newValue, modal: modal })
//                                     setTypeVal(newValue);
//                                     //addClickFacility()
//                                     openRow()
//                                     } 
//                                 }                 
//                                 // onChange={(e, newValue) => {
//                                 //     const tempValues = [...values];
//                                 //     tempValues[i] = {...tempValues[i], type_val: e.target.value};
//                                 //     setTypeVal(newValue);
//                                 //     openRow();
//                                 //     addClickFacility()
//                                 // }}            
//                                 renderInput={params => (
//                                     <TextField {...params} label="Find Task" margin="dense" variant="outlined" fullWidth />
//                                 )}
//                               />
//                             </div>
//                         </div>
//                     </ModalBody>
//                 </form>
//             </Modal>
//         </Fragment>
//     );
// }

// export default Timesheet;