import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, Grid, Table, TableHead, TableCell, TableRow,
TextField, FormGroup, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Audiotrack } from '@material-ui/icons';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import Breadcrumb from '../../../components/common/breadcrumb';

import PayrollAdditions from './PayrollAdditions';
import PayrollDeductions from './PayrollDeductions';
import PayrollIncome from './PayrollIncome';

import {getCompensation, editCompensation2, getEmployee, getEmployeeTax, postEmployeeTax, postEmployeeTaxPaid, 
        payrollAddUpload, getPayrollAdd, editPayrollAdd, deletePayrollAdd,
        payrollDeductUpload, getEmployeeTaxPaid, editEmployeeTaxPaid, deleteAllPayrollAdd, postPayroll,
        getPayroll } from '../../../actions/asyncActions';
import { toast } from 'react-toastify';


const useStyles = makeStyles(theme => ({

  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
    },
    // backgroundColor: '#c9918d'
    backgroundColor: '#e4e8ff' 
  },
  stepper: {
    padding: theme.spacing(3, 0, 2),
    backgroundColor: '#e4e8ff'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    margin: theme.spacing(2),
  },
}));

const steps = ['Payroll Additions', 'Payroll Deductions', 'Taxes'];

const PayrollSteps = (props) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const userRole = user.role;  
  const userEmpId = user.emp_id;
  const getThisEmpId = user.emp_id; // Get Employee Id from the user table

  const location = props.location;
  const responseData = location.state ? location.state.compData : null;
  const passMonth = location.state ? location.state.passMonth : null;
  const passedMonth = passMonth.toUpperCase()
  const assessmentYear = location.state ? location.state.assessmentYear : null;

  console.log("responseData", responseData)
  console.log("passedMonth", passedMonth)

  const [payrollAdd, setPayrollAdd] = useState([])
  const [taxPaid, setTaxPaid] = useState([]);
  const [payrolls, setPayroll] = useState([]);

  {/* If IT Calculation done, show the below */}

  const [employeeTax, setEmployeeTax] = useState([]);
  const filterEmpTax = employeeTax ? employeeTax.find(x=>x.emp_id === userEmpId) : null;
  const netTaxSalary = filterEmpTax && filterEmpTax.net_taxable_income; //Net Taxable Salary
  const slabfive = filterEmpTax && filterEmpTax.slab_5;
  const slabten = filterEmpTax && filterEmpTax.slab_10;
  const slabfifteen = filterEmpTax && filterEmpTax.slab15;
  const slabTotalTax = filterEmpTax && filterEmpTax.total_tax;
  
  {/* End of the IT Calculation DONE part */}
  
  {/* Start of the IT Calculation Part */}  
  
  const [compensation, setCompensation] = useState([]);
  const filterCompensation = compensation.filter(x=>x.emp_id === userEmpId);

// 1. Salary Details computation
  const sumOfPayouts = compensation.filter(x=>x.emp_id === userEmpId && x.category === 'Payouts')
                      .reduce((a,b) => a + parseFloat(b.monthly), 0); // Sum of Payouts
  
  const [employees, setEmployees] = useState([]);
  const curEmployee = employees.find(x=>x.id === userEmpId);
  const dateJoin = curEmployee ? curEmployee && curEmployee.doj : null; //Date of Joining
  const financeStart = curEmployee ? curEmployee && curEmployee.finance_start : null; //Finance Start Date
  const financeEnd = curEmployee ? curEmployee && curEmployee.finance_end : null; // Finance End Date

  const financeYearStart = new Date(financeStart).getFullYear(); // FY Start
  const financeYearEnd = new Date(financeEnd).getFullYear(); // FY End

  const verifyDOJPrior = new Date(dateJoin).getTime() < new Date(financeStart).getTime(); //Check if DOJ prior to Finance Start
  
// To calculate the time difference of two dates 
  var Difference_In_Time = new Date(financeEnd).getTime() - new Date(dateJoin).getTime(); 
  
// To calculate the no. of days between two dates 
  var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24)); //No of Days for the FY

  const grossSal = verifyDOJPrior ? sumOfPayouts * 12 : sumOfPayouts * 12 * (Difference_In_Days / 365); //Gross Salary
  const grossSalary = Math.round((grossSal + Number.EPSILON) * 100) / 100 ; //Gross Salary with decimal places sorted

// 2. Salary Deduction computation  

  const ptax = filterCompensation.find(x=>x.component === 'Profession Tax')
  const profTax = ptax ? parseFloat(ptax && ptax.monthly) : 0;
  
  const standardDeduction = 50000; //Standard Deduction

  function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  var Difference_In_Months = monthDiff(new Date(dateJoin), new Date(financeEnd)) + 1;
  console.log("DifferenceMonths", Difference_In_Months)

  const professionTax = verifyDOJPrior ? profTax * 12 : profTax * Difference_In_Months;  //Profession Tax

  const [month_rent, setMonthRent] = useState(0)

  const hrAll = filterCompensation.find(x=>x.component === 'House Rent Allowances')
  const hrAllow = hrAll ? parseFloat(hrAll && hrAll.monthly) : 0;
  const hra1 = verifyDOJPrior ? hrAllow * 12 : hrAllow * Difference_In_Months; // HRA1

  const hrBasic = filterCompensation.find(x=>x.component === 'Basic Salary')
  const hrAllowBasic = hrBasic ? parseFloat(hrBasic && hrBasic.monthly) : 0;
  const hra2 = verifyDOJPrior ? (month_rent * 12) - (hrAllowBasic * 12 * 0.1) :
               (month_rent * Difference_In_Months) - (hrAllowBasic * Difference_In_Months * 0.1) // HRA2
  const hra3 = verifyDOJPrior ? (hrAllowBasic * 12 * 0.4) : (hrAllowBasic * Difference_In_Months * 0.4); //HRA3
  
  const leastHRA = hra2 <= 0 ? Math.min(hra1, hra3) : Math.min(hra1, hra2, hra3); //Least HRA
  console.log("leastHRA", leastHRA)

  const totalSalaryDeductions = standardDeduction + professionTax + leastHRA; //Total Salary Deduction

  // 3. House Property Income

  const typeHp = [{id: 1, name:'Self Occupied'}, {id: 2, name:'Let Out'}, {id: 3, name:'Deemed Let Out'}];
  
  const allowed_deduction = 200000;

  const [facilityfields, setFacilityFields] = 
   useState([{ 
    name:'', description:'', amount: ''
   }]);

  console.log("facility", facilityfields)

  function addClickFacility(){
    const values = [...facilityfields];
    values.push({ name:'', description:'', amount:'' });
    setFacilityFields(values);
  }

  function handleRemoveFacility(i){
    const values = [...facilityfields];
    values.splice(i,1);
    setFacilityFields(values);
  }

  function handleChangeFacility(i,e){
    const tempValues = [...facilityfields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFacilityFields(tempValues);
  }

  const [facilityfields2, setFacilityFields2] = 
   useState([{ 
    name:'', description:'', amount: ''
   }]);

  console.log("facility2", facilityfields2)

  function addClickFacility2(){
    const values = [...facilityfields2];
    values.push({ name:'', description:'', amount:'' });
    setFacilityFields2(values);
  }

  function handleRemoveFacility2(i){
    const values = [...facilityfields2];
    values.splice(i,1);
    setFacilityFields2(values);
  }

  function handleChangeFacility2(i,e){
    const tempValues = [...facilityfields2];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFacilityFields2(tempValues);
  }

  const [bulk_upload, setUpload] = useState();
  const [uploadfile, setFile] = useState([]);

  function bulkUpload() {
    setUpload(!bulk_upload)
  }

  function onFileDrop(files) {
    setFile(document.getElementById('file').files[0]);
  }

  const handleUploadSubmit = async (event) => {
    const formData = new FormData()
    formData.append( 'file', uploadfile )
    formData.append('user_id', user.id)
    formData.append('group_id', group_id)
    formData.append('category', 'Payouts')
    payrollAddUpload(formData)
    .then(resp => {
        getPayrollAdd(group_id)
        .then(resp => setPayrollAdd(resp.data))
        setUpload(!bulk_upload)
        toast.success("File uploaded successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
    })
    .catch(err => {
        console.log("Error", err);
    })
  }

  const handleUploadSubmit2 = async (event) => {
    const formData = new FormData()
    formData.append( 'file', uploadfile )
    formData.append('user_id', user.id)
    formData.append('group_id', group_id)
    formData.append('category', 'Deductions')
    payrollDeductUpload(formData)
    .then(resp => {
        getPayrollAdd(group_id)
        .then(resp => setPayrollAdd(resp.data))
        setUpload(!bulk_upload)
        toast.success("File uploaded successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
    })
    .catch(err => {
        console.log("Error", err);
    })
  }

    const [modal, setModal] = useState(false)
    const initialFormState = { id: '', description: '', amount:''}
    const [currentData, setCurrentData ] = useState(initialFormState)

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentData({ ...currentData, [name]: value })
    }
    
    const editRow = n => {
        setModal(!modal)
        setCurrentData({ id: n.id, description: n.description, amount: n.amount })
    }

    const deleteRow = n => {
        const data = {
            id: n.id
        }
        //Delete API
        deletePayrollAdd(data)
        .then(resp => {
            getPayrollAdd(group_id)
            .then(resp => setPayrollAdd(resp.data))
            toast.error("Item deleted successfully!!!")
        })
    }

    const handleUpdate = (id, data) => {

        const cat = {
          id: id,
          description: currentData.description,
          amount: currentData.amount,
          user_id: user.id
        }
        editPayrollAdd(cat)
        .then(resp => {
            getPayrollAdd(group_id)
            .then(resp => setPayrollAdd(resp.data))
            toast.info("Additions updated successfully!!!")
            setModal(!modal)
        })
        .catch(err => {
        console.log("Error", err);
        })
    }

  const totalHPIncome = facilityfields ? facilityfields.reduce((a,b) => a + parseFloat(b.incomeHp), 0) : 0; //Total HP Income/Deduction

  // 4. Category VI A

  const [values, setValues] = useState({});
  const totalVIADeduction = parseFloat(values.deductions_80_c || 0 + values.deductions_80_tta || 0 + values.deductions_80_d || 0 +
                        values.deductions_80_g || 0 + values.deductions_80_e || 0 + values.deductions_80_eea || 0 + 
                        values.deductions_80_ccd || 0) // Total Chapter VI A Deduction

  // 5. Summary
  
  const netTaxableIncome = grossSalary - (totalSalaryDeductions || 0) - (totalHPIncome || 0) - (totalVIADeduction || 0);
  console.log("netTaxIncome", netTaxableIncome)

  //const netTaxableIncome = 832000;

  //Slab 5% Calculation
  const getSlab5 = (amount) => {
    if(netTaxableIncome > 250000){
      if(netTaxableIncome > 500000){
        var taxPercent = (500000 - 250000) * 5/100;
        return parseFloat(taxPercent);
      }
      else {
        var taxPercent = (netTaxableIncome - 250000) * 5/100;
        return parseFloat(taxPercent);
      }
    }
    else{
      return 0;
    }
  }

  const getSlab10 = (amount) => {
    if(netTaxableIncome > 500000){
      if(netTaxableIncome > 750000){
        var taxPercent = (750000 - 500000) * 10/100;
        return taxPercent;
      }
      else{
        var taxPercent = (netTaxableIncome - 500000) * 10/100;
        return taxPercent;
      }
    }
    else{
      return 0;
    }
  }

  const getSlab15 = (netTaxableIncome) => {
    if(netTaxableIncome > 750000){
      if(netTaxableIncome > 1000000){
        var taxPercent = (1000000 - 750000) * 15/100;
        return taxPercent;
      }
      else{
        var taxPercent = (netTaxableIncome - 750000) * 15/100;
        return taxPercent;
      }
    }
    else{
      return 0;
    }
  }

  const slab5Tax = netTaxableIncome ? getSlab5(netTaxableIncome) : 0;
  const slab5 = Math.round((slab5Tax + Number.EPSILON) * 100) / 100;

  const slab10Tax = netTaxableIncome ? getSlab10(netTaxableIncome) : 0;
  const slab10 = Math.round((slab10Tax + Number.EPSILON) * 100) / 100;

  const slab15Tax = netTaxableIncome ? getSlab15(netTaxableIncome) : 0;
  const slab15 = Math.round((slab15Tax + Number.EPSILON) * 100) / 100;

  const totalTax = slab5 + slab10 + slab15; 

  const taxMonth = totalTax/Difference_In_Months;
  const taxPerMonth = Math.round((taxMonth + Number.EPSILON) * 100) / 100;
  
  useEffect( () => {
    getCompensation(group_id)
    .then(resp => setCompensation(resp.data))
  }, [])

  useEffect( () => {
    getEmployee(group_id)
    .then(resp => setEmployees(resp.data))
  }, [])

  useEffect( () => {
    getEmployeeTax(group_id)
    .then(resp => setEmployeeTax(resp.data))
  }, [])

  useEffect( () => {
    getPayrollAdd(group_id)
    .then(resp => setPayrollAdd(resp.data))
  }, [])

  useEffect( () => {
    getEmployeeTaxPaid(group_id)
    .then(resp => setTaxPaid(resp.data))
  }, []);

  useEffect( () => {
    getPayroll(group_id)
    .then(resp => setPayroll(resp.data))
  }, []);


  {/* End of the IT Calculation Part */}  

  const [employee, setEmployee] = useState([]);
  let empObj = {};
  empObj = (employee || []).sort((a,b) => {
    return b.id-a.id;
  });
  var empId = empObj ? (empObj && empObj[0] || []).employee_id : null;
  var foundLen = employee ? employee.length : 0;
  console.log("foundLen", foundLen)
  var str2 = "NU-";
  var str = foundLen === 0 ? "NU-0000" : empId;
  var res = str.split('-');
  var Num = res[1];
  var incrementValue = (+Num) + 1;
  incrementValue = ("0000" + incrementValue).slice(-4);
  var employeeId = str2 + incrementValue
  console.log("employeeId", employeeId)

  const [dob, setDob] = useState()
  const [finance_start, setFinanceStart] = useState()
  const [finance_end, setFinanceEnd] = useState()
  const [errors, setError] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStep2, setActiveStep2] = React.useState(0);
  

  const [radioVal, SetRadio] = React.useState({ gender: '' });
  
  const [subform, setSubForm] = React.useState(false);


  const handleSubFormClose = () => {
    setSubForm(false);
    if(userRole === 'Employee'){
      props.history.push(`${process.env.PUBLIC_URL}/itcalculation`)
      window.location.reload()
    }
    else{
      setActiveStep(activeStep - (steps.length-1));
      props.history.push(`${process.env.PUBLIC_URL}/itcalculation`)
    }
  };

  const handleNext = () => {
    // const errors = validateForm(values);
    //  if(Object.keys(errors).length) {
    //   setError(errors);
    //   return;
    //  }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  
  const validateForm = (data) => {
     const errors = [];
     if(activeStep === 0 || activeStep2 === 0){
     if(!data.name) {
       errors.name = "Employee name is required";
     }
     if(!data.father) {
        errors.father = "Father name is required";
      }
     if(!data.phone) {
       errors.phone = "Mobile number is required";
     }
     if(!data.email) {
       errors.email = "Email Id is required";
     }
    } else if(activeStep === 2 || activeStep2 === 2){
     if(!data.panno){
      errors.panno = "PAN number is required";
     }
   }
     return errors;
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleRadioChange = event => {
    SetRadio({
      ...radioVal,
      [event.target.name]: event.target.value
    });
  };

//   const getStepContent2 = (step) => {
//     switch (step) {
//         case 0: 
//           return <SalaryDetails filterCompensation={filterCompensation} grossSalary={grossSalary} />;
//         case 1:
//           return <SalaryDeductions standardDeduction={standardDeduction} professionTax={professionTax} 
//             month_rent={month_rent} setMonthRent={setMonthRent} leastHRA={leastHRA} 
//             totalSalaryDeductions={totalSalaryDeductions} />;
//         case 2: 
//           return <HouseProperty facilityfields={facilityfields} handleChangeFacility={handleChangeFacility} 
//             handleRemoveFacility={handleRemoveFacility} addClickFacility={addClickFacility} typeHp={typeHp}
//             allowed_deduction={allowed_deduction} totalHPIncome={totalHPIncome} />
//         case 3: 
//           return <ChapterVIA values={values} handleChange={handleChange} totalVIADeduction={totalVIADeduction}/>
//         case 4:
//           return <Summary grossSalary={grossSalary} totalSalaryDeductions={totalSalaryDeductions}
//             totalHPIncome={totalHPIncome} totalVIADeduction={totalVIADeduction} netTaxableIncome={netTaxableIncome}
//             slab5={slab5} slab10={slab10} slab15={slab15} totalTax={totalTax} />
//       default:
//         throw new Error('Unknown step');
//     }
//   }

  const getStepContent = (step) => {
    switch (step) {
        case 0: 
          return <PayrollAdditions responseData={responseData} facilityfields={facilityfields} 
            handleChangeFacility={handleChangeFacility} handleRemoveFacility={handleRemoveFacility} 
            addClickFacility={addClickFacility} bulk_upload={bulk_upload} bulkUpload={bulkUpload} 
            onFileDrop={onFileDrop} handleUploadSubmit={handleUploadSubmit} payrollAdd={payrollAdd}
            modal={modal} currentData={currentData} editRow={editRow} deleteRow={deleteRow} />;
        case 1:
          return <PayrollDeductions responseData={responseData} facilityfields2={facilityfields2} 
            handleChangeFacility2={handleChangeFacility2} handleRemoveFacility2={handleRemoveFacility2} 
            addClickFacility2={addClickFacility2} bulk_upload={bulk_upload} bulkUpload={bulkUpload} 
            onFileDrop={onFileDrop} handleUploadSubmit2={handleUploadSubmit2} payrollAdd={payrollAdd}
            modal={modal} currentData={currentData} editRow={editRow} deleteRow={deleteRow} />;
        case 2: 
          return <PayrollIncome taxPaid={taxPaid} />
      default:
        throw new Error('Unknown step');
    }
  }
  
  const handleSubmit = async(event) => {

    //1. Compensation Data = responseData
    const compData = {
      items: responseData,
      user_id: user.id,
      group_id: group_id
    }
    console.log("compData", compData)
    //2. facilityFields = manual entry for additions
    let manualAddData = [];
    if(facilityfields[0].name === ''){

    }
    else{
      facilityfields.forEach((item, i) => {

        const payrollData = responseData.sort((a,b) => b.id - a.id)
        const payrollMonth = payrollData ? payrollData && payrollData[0].month : null
        const payrollYear = payrollData ? payrollData && payrollData[0].year : null

        const payLop  = payrollData ? payrollData.filter(x=>x.emp_id === item.name) : null
        const payrollLop = payLop ? payLop && payLop[0].lop : null
        const payrollTotalDays = payLop ? payLop && payLop[0].tot_days : null
        const empName = payLop ? payLop && payLop[0].emp_name : null

        manualAddData.push({ 
          emp_id: item.name,
          emp_name: empName, 
          category: 'Payouts', 
          component: item.description, 
          monthly: parseFloat(item.amount),
          month: payrollMonth,
          year: payrollYear,
          lop: payrollLop,
          tot_days: payrollTotalDays,
          net_salary: parseFloat(item.amount) * (payrollTotalDays - payrollLop) / payrollTotalDays
        })
      });
    }
    console.log("manualAddData", manualAddData)
    //3. facilityFields2 = manual entry for deductions
    let manualDeductData = [];
    if(facilityfields2[0].name === ''){

    }
    else{
      facilityfields2.forEach((item, i) => {

        const payrollData = responseData.sort((a,b) => b.id - a.id)
        const payrollMonth = payrollData ? payrollData && payrollData[0].month : null
        const payrollYear = payrollData ? payrollData && payrollData[0].year : null

        const payLop  = payrollData ? payrollData.filter(x=>x.emp_id === item.name) : null
        const payrollLop = payLop ? payLop && payLop[0].lop : null
        const payrollTotalDays = payLop ? payLop && payLop[0].tot_days : null
        const empName = payLop ? payLop && payLop[0].emp_name : null

        manualDeductData.push({ 
          emp_id: item.name,
          emp_name: empName, 
          category: 'Deductions', 
          component: item.description, 
          monthly: parseFloat(item.amount),
          month: payrollMonth,
          year: payrollYear,
          lop: payrollLop,
          tot_days: payrollTotalDays,
          net_salary: parseFloat(item.amount) * (payrollTotalDays - payrollLop) / payrollTotalDays
        })
      });
    }
    console.log("manualDeductData", manualDeductData)
    //4. payrollAdd for Additions and Deductions
    let payrollAddData = [];
    if(payrollAdd.length > 0){
      payrollAdd.forEach((item, i) => {
          
        const payrollData = responseData.sort((a,b) => b.id - a.id)
        const payrollMonth = payrollData ? payrollData && payrollData[0].month : null
        const payrollYear = payrollData ? payrollData && payrollData[0].year : null

        const payLop  = payrollData ? payrollData.filter(x=>x.emp_id === item.emp_id) : null
        const payrollLop = payLop ? payLop && payLop[0].lop : null
        const payrollTotalDays = payLop ? payLop && payLop[0].tot_days : null
        
        payrollAddData.push({ 
          emp_id: item.emp_id,
          emp_name: item.emp_name, 
          category: item.category, 
          component: item.description, 
          monthly: parseFloat(item.amount),
          month: payrollMonth,
          year: payrollYear,
          lop: payrollLop,
          tot_days: payrollTotalDays,
          net_salary: parseFloat(item.amount) * (payrollTotalDays - payrollLop) / payrollTotalDays
        })
      });
    }
    else{

    }
    const data = {
      items: payrollAddData,
      user_id: user.id,
      group_id: group_id
    }
    console.log("payrollAddData", payrollAddData)
    //5. taxPaid, take the calculations from PayrollIncome
    const zzz = employeeTax.filter(x => x.status === 'Approved'); //Find Approved Tax Declarations
    const approvedTaxIds = [...new Set(zzz.map(x => x.emp_id))]; // Get their IDs

    const filterTaxPaids = taxPaid.filter(x => approvedTaxIds.includes(x.emp_id))
    let taxData = [];
    if(filterTaxPaids.length > 0) {
      filterTaxPaids.forEach((item, i) => {
              
        const payrollData = responseData.sort((a,b) => b.id - a.id)
        //const payrollEmployee = payrollData ? payrollData && payrollData[0].emp_name : null
        const payrollMonth = payrollData ? payrollData && payrollData[0].month : null
        const payrollYear = payrollData ? payrollData && payrollData[0].year : null
        //const payrollLop = payrollData ? payrollData && payrollData[0].lop : null
        //const payrollTotalDays = payrollData ? payrollData && payrollData[0].tot_days : null

        const payLop  = payrollData ? payrollData.filter(x=>x.emp_id === item.emp_id) : null
        const payrollLop = payLop ? payLop && payLop[0].lop : null
        const payrollTotalDays = payLop ? payLop && payLop[0].tot_days : null
        const payrollEmployee = payLop ? payLop && payLop[0].emp_name : null
        
        taxData.push({ 
            emp_id: item.emp_id,
            emp_name: payrollEmployee, 
            category: 'Taxes', 
            component: 'Tax Deductions', 
            monthly: parseFloat(item.tax_per_month),
            month: payrollMonth,
            year: payrollYear,
            lop: payrollLop,
            tot_days: payrollTotalDays,
            net_salary: parseFloat(item.tax_per_month)
        })
      });
    }
    else{

    }
    const itData = {
        items: taxData,
        user_id: user.id,
        group_id: group_id
    }
    console.log("taxData", taxData)
    //Get Tax Paid Details and 
    //  UPDATE 
    //  YTD= YTD + tax_per_month, 
    //  bal_amount = total_tax - new YTD, 
    //  bal_month = bal_month - 1,

    let taxDetails = filterTaxPaids;
    let editTaxData = []
    if(taxDetails.length > 0) {
      taxDetails.forEach((item, i) => {
        editTaxData.push({
              id: item.id,
              emp_id: item.emp_id,
              ytd: parseFloat(item.ytd + item.tax_per_month),
              bal_amount: parseFloat(item.total_tax) - parseFloat(item.ytd + item.tax_per_month), 
              bal_month: item.bal_month - 1,
          })
      });
    }
    else{

    }
    const taxDataa = {
      items: editTaxData,
      user_id: user.id,
      group_id: group_id
    }
    console.log("editTaxData", editTaxData)
    //6. Finally, put all together and add to Payroll table and
    let combinedData = []
    combinedData.push(...responseData, ...manualAddData, ...manualDeductData, ...payrollAddData, ...taxData)
    console.log("combinedData", combinedData)
    const allData = {
      items: combinedData,
      status: 'Submitted',
      user_id: user.id,
      group_id: group_id
    }

    // Compensation table update
    let compensationData = [];
    const filterCompensation = compensation.filter(x => approvedTaxIds.includes(x.emp_id))
    console.log("filterCompensation", filterCompensation)
    filterCompensation.forEach((item, i) => {
      compensationData.push({
        id: item.id,
        last_pay_month: passMonth,
        last_pay_year: assessmentYear
      })
    });
    console.log("compensationData", compensationData)
    const updateCompData = {
      items: compensationData,
      user_id: user.id,
      group_id: user.group_id
    }
    
    postPayroll(allData)
    .then(resp => {
      getPayroll(group_id)
      .then(resp => {
        setPayroll(resp.data)
        toast.success("Data added successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
        //7. delete all the additions from the payrollAdd table
        var data = {group_id: group_id}
        deleteAllPayrollAdd(data)
        .then(resp => {
          //8. Update Compensation table with last_pay_month && last_pay_year
          editCompensation2(updateCompData)
          .then(resp =>{
            //Update Tax Paid Table
            if(taxDetails.length > 0) {
              editEmployeeTaxPaid(taxDataa)
              .then(resp => {
                
              })
            }
            else{}
            props.history.push(`${process.env.PUBLIC_URL}/payrollfinal`)
          })    
        })
      })
    })
  }
  
  return (
    <React.Fragment>
      <Breadcrumb title="Payroll Processing" parent="Payroll" />
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center" >
            <b><i>PAYROLL PROCESS FOR THE MONTH - {passedMonth}</i></b>
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography align="center" variant="h5" gutterBottom>
                  <Audiotrack color="primary"/> Form is Successfully submitted <Audiotrack color="primary"/>
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} round variant="outlined" className={classes.button}>
                      Prev
                    </Button>
                  )}
                  {activeStep === steps.length - 1 ?
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.button}
                      round
                    >
                      Submit the form
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="primary"
                      round
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  }
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
      <React.Fragment>
      
      {/** Form Submit Model **/}
      <Dialog 
        open={subform} 
        onClose={handleSubFormClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick="true"
        disableEscapeKeyDown="true"
      >
      <DialogContent>
        <Typography align="center">
          <CheckCircleOutlineRoundedIcon style={{fontSize: '100px', color:'#4bb543'}}/>
        </Typography>
        <Grid xs={12} sm={12}>
        <Typography component="h2" variant="h6" align="center" gutterBottom>
          Thank you for submitting the form. You can check the status on your Dashboard...
        </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubFormClose} color="primary" size="sm" round>
          Close
        </Button>
      </DialogActions>
      </Dialog>
    </React.Fragment>  
    </React.Fragment>
  );
}

export default PayrollSteps;