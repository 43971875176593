import React from 'react';

export default function AddInventory(props) {
	const { values } = props;

	return( 
	  <React.Fragment>
	    <form>
        <div className="form-row">
          <div className="col-xl-6 mb-3">
            <label className="col-form-label" htmlFor="message-text">Manufacturer:</label>
            <input className="form-control" name="mfr" type="text" placeholder="Manufacturer" 
              value={values.mfr} onChange={props.handleChange}/>
          </div>
          <div className="col-xl-6 mb-3">
            <label className="col-form-label" htmlFor="message-text">Category:</label>
            <input className="form-control" name="category" type="text" placeholder="Category" 
              value={values.category} onChange={props.handleChange}/>
          </div>
          <div className="col-xl-6 mb-3">
            <label className="col-form-label" htmlFor="message-text">Part No:</label>
            <input className="form-control" name="part_no" type="text" placeholder="Part Num" 
              value={values.part_no} onChange={props.handleChange}/>
          </div>
          <div className="col-xl-6 mb-3">
            <label className="col-form-label" htmlFor="message-text">Unit:</label>
            <input className="form-control" name="unit" type="text" placeholder="Unit" 
              value={values.unit} onChange={props.handleChange}/>
          </div>
          <div className="col-md-12 mb-3">
            <label className="col-form-label" htmlFor="message-text">Description:</label>
            <input className="form-control" name="description" type="text" placeholder="Description" 
              value={values.description} onChange={props.handleChange}/>
          </div>
        </div>
      </form>
	  </React.Fragment>
	);
}