import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getVendorForm, getIndentItems, getProjects, getGroup } from '../../actions/asyncActions';
import * as moment from 'moment';

export default function MaterialTableDemo(props) {
  
  const { rtsData } = props;
  console.log("rtsData", rtsData)
  const datee = new Date();
  const newDate = moment(datee).format('ll');

  return (
    <div id="printreceipt">
     <section className="sheet padding-5mm">
      <table style={{width:'95%'}}>
        <tr> 
          <td style={{textAlign:"center"}}>
           <h1>RTS Form</h1>
          </td>
        </tr>
      </table>
      
      <table style={{width:'95%'}}>
        <tr>
        <td style={{textAlign:"center"}}>
         <pre>
          Dated: <b>{newDate}</b>
         </pre>
        </td>
       </tr>
      </table>
      <table style={{width:'95%', textAlign:"center"}}>
      <tr>
       <td> 
         RTS No: <b>{("RTS-").concat(rtsData.id)}</b>
       </td>
      </tr>
      <tr>
       <td> 
         PO Number: <b>{("PO-000").concat(rtsData.poNumber)}</b>
       </td>
      </tr>
      <tr>
       <td> 
         Reason for Rejection: <b>{rtsData.reason}</b>
       </td>
      </tr>
      <tr>
       <td> 
         Description: <b>{rtsData.description}</b>
       </td>
      </tr>
      <tr>
       <td>
        <pre>
         Please note that the PO Number: <b>{("PO-000").concat(rtsData.poNumber)}</b> was rejected due to 
         the reason stated above.
        </pre>
       </td>
      </tr>
      <tr>
       <td>
        <pre>
         For further resolution, please contact the assigned person <b>{rtsData.generatedPoUser}</b> 
         on this email address: <b>{rtsData.generatedPoUserEmail}</b>
        </pre>
       </td>
      </tr>
    </table>
  
     </section>
    </div>
  );
}