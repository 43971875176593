import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, Grid, Table, TableHead, TableCell, TableRow,
TextField, FormGroup, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Audiotrack } from '@material-ui/icons';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { postEmployee }  from '../../../actions/asyncActions';
import Breadcrumb from '../../../components/common/breadcrumb';

import SalaryDetails from './itStepper/SalaryDetails';
import SalaryDeductions from './itStepper/SalaryDeductions';
import HouseProperty from './itStepper/HouseProperty';
import ChapterVIA from './itStepper/ChapterVIA';
import Section80D from './itStepper/Section80D';
import Summary from './itStepper/Summary';

import {getCompensation, getEmployee, getEmployeeTax, postEmployeeTax, postEmployeeTaxPaid} from '../../../actions/asyncActions';
import { toast } from 'react-toastify';


const useStyles = makeStyles(theme => ({

  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
    },
    // backgroundColor: '#c9918d'
    backgroundColor: '#e4e8ff' 
  },
  stepper: {
    padding: theme.spacing(3, 0, 2),
    backgroundColor: '#e4e8ff'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    margin: theme.spacing(2),
  },
}));

const steps = ['Salary Details', 'Salary Deduction', 'House Property', 'Chapter VI A', 'Section 80D', 'Summary'];
const steps2 = ['Personal', 'Education', 'Other', 'Employee', 'Bank Account'];


export default function Checkout(props) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const userRole = user.role;  
  const userEmpId = user.emp_id;
  const getThisEmpId = user.emp_id; // Get Employee Id from the user table

  {/* If IT Calculation done, show the below */}

  const [employeeTax, setEmployeeTax] = useState([]);
  const filterEmpTax = employeeTax ? employeeTax.find(x=>x.emp_id === userEmpId) : null;
  const gross_salary = filterEmpTax && filterEmpTax.gross_salary;
  const standard_deduction = filterEmpTax && filterEmpTax.standard_deduction;
  const profession_tax = filterEmpTax && filterEmpTax.profession_tax;

  const least_hra = filterEmpTax && filterEmpTax.least_hra;
  const total_via_deduction = filterEmpTax && filterEmpTax.total_via_deduction;
  const total_hp_income = filterEmpTax && filterEmpTax.total_hp_income;
  const net_taxable_income = filterEmpTax && filterEmpTax.net_taxable_income;

  const slabfive = filterEmpTax && filterEmpTax.slab_5;
  const slabten = filterEmpTax && filterEmpTax.slab_10;
  const slabfifteen = filterEmpTax && filterEmpTax.slab15;
  const slabTotalTax = filterEmpTax && filterEmpTax.total_tax;
  
  {/* End of the IT Calculation DONE part */}
  
  {/* Start of the IT Calculation Part */}  
  
  const [compensation, setCompensation] = useState([]);
  const filterCompensation = compensation.filter(x=>x.emp_id === userEmpId);

// 1. Salary Details computation
  // function generateArrayOfYears() {
  //   var max = new Date().getFullYear() + 1
  //   var min = max - 3
  //   var years = []

  //   for (var i = max; i >= min; i--) {
  //     years.push({
  //       year: `${i-1}-${i}`
  //     })
  //   }
  //   return years
  // }

  // const assessmentYear = generateArrayOfYears()
  const [assessmentYear, setAssessmentYear] = useState("")
  console.log("assessmentYear", assessmentYear)

  const sumOfPayouts = compensation.filter(x=>x.emp_id === userEmpId && x.category === 'Payouts')
                      .reduce((a,b) => a + parseFloat(b.monthly), 0); // Sum of Payouts
  
  const [employees, setEmployees] = useState([]);
  const curEmployee = employees.find(x=>x.id === userEmpId);
  const dateJoin = curEmployee ? curEmployee && curEmployee.doj : null; //Date of Joining
  const financeStart = curEmployee ? curEmployee && curEmployee.finance_start : null; //Finance Start Date
  const financeEnd = curEmployee ? curEmployee && curEmployee.finance_end : null; // Finance End Date

  const financeYearStart = new Date(financeStart).getFullYear(); // FY Start
  const financeYearEnd = new Date(financeEnd).getFullYear(); // FY End

  const verifyDOJPrior = new Date(dateJoin).getTime() < new Date(financeStart).getTime(); //Check if DOJ prior to Finance Start
  
// To calculate the time difference of two dates 
  var Difference_In_Time = new Date(financeEnd).getTime() - new Date(dateJoin).getTime(); 
  
// To calculate the no. of days between two dates 
  var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24)); //No of Days for the FY

  const grossSal = verifyDOJPrior ? sumOfPayouts * 12 : sumOfPayouts * 12 * (Difference_In_Days / 365); //Gross Salary
  const grossSalary = Math.round((grossSal + Number.EPSILON) * 100) / 100 ; //Gross Salary with decimal places sorted

// 2. Salary Deduction computation  

  const ptax = filterCompensation.find(x=>x.component === 'Profession Tax')
  const profTax = ptax ? parseFloat(ptax && ptax.monthly) : 0;
  
  const standardDeduction = 50000; //Standard Deduction

  function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  var Difference_In_Months = monthDiff(new Date(dateJoin), new Date(financeEnd)) + 1;
  console.log("DifferenceMonths", Difference_In_Months)

  const professionTax = verifyDOJPrior ? profTax * 12 : profTax * Difference_In_Months;  //Profession Tax

  const [month_rent, setMonthRent] = useState(0)

  const hrAll = filterCompensation.find(x=>x.component === 'House Rent Allowances')
  const hrAllow = hrAll ? parseFloat(hrAll && hrAll.monthly) : 0;
  const hra1 = verifyDOJPrior ? hrAllow * 12 : hrAllow * Difference_In_Months; // HRA1

  const hrBasic = filterCompensation.find(x=>x.component === 'Basic Salary')
  const hrAllowBasic = hrBasic ? parseFloat(hrBasic && hrBasic.monthly) : 0;
  const hra2 = verifyDOJPrior ? (month_rent * 12) - (hrAllowBasic * 12 * 0.1) :
               (month_rent * Difference_In_Months) - (hrAllowBasic * Difference_In_Months * 0.1) // HRA2
  const hra3 = verifyDOJPrior ? (hrAllowBasic * 12 * 0.4) : (hrAllowBasic * Difference_In_Months * 0.4); //HRA3
  
  const leastHRA = hra2 <= 0 ? Math.min(hra1, hra3) : Math.min(hra1, hra2, hra3); //Least HRA
  console.log("hra1", hra1)
  console.log("hra2", hra2)
  console.log("hra3", hra3)
  console.log("leastHRA", leastHRA)

  const totalSalaryDeductions = standardDeduction + professionTax + leastHRA; //Total Salary Deduction

  // 3. House Property Income

  const typeHp = [{id: 1, name:'Self Occupied'}, {id: 2, name:'Let Out'}, {id: 3, name:'Deemed Let Out'}];
  
  const allowed_deduction = 200000;

  const [interest_borrowed1, setInterestBorrowed1] = useState(0)

  const [facilityfields, setFacilityFields] = 
   useState([{ 
    name:'', type:'', value: 0, interest_borrowed1: 0, gross_rent: 0, tax_paid:'', interest_borrowed2: '',
    allow_deduction:'', incomeHp:''
   }]);

  function addClickFacility(){
    const values = [...facilityfields];
    values.push({ name:'', type:'', amount:'' });
    setFacilityFields(values);
  }

  function handleRemoveFacility(i){
    const values = [...facilityfields];
    values.splice(i,1);
    setFacilityFields(values);
  }

  function handleChangeFacility(i,e){
    const tempValues = [...facilityfields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    tempValues[i] = {...tempValues[i], incomeHp: 
      (parseFloat(tempValues[i].gross_rent - tempValues[i].tax_paid)) - (parseFloat(tempValues[i].gross_rent - tempValues[i].tax_paid) * 0.3) - (tempValues[i].interest_borrowed2)};
    setFacilityFields(tempValues);
  }
  const incomeHp1 = parseFloat(interest_borrowed1) < 200000 ? -(parseFloat(interest_borrowed1)) : -(allowed_deduction)
  const totalHouseIncome = facilityfields[0].incomeHp === '' ? incomeHp1 : facilityfields.reduce((a,b) => a + parseFloat(b.incomeHp), 0) + incomeHp1; //Total HP Income/Deduction
  const totalHPIncome = Math.round((totalHouseIncome + Number.EPSILON) * 100) / 100;
  // 4. Category VI A

  const [values, setValues] = useState({});

  // 5. Section 80D
  const [isFamilySenior, setIsFamilySenior] = useState("Yes");
  const [isParentSenior, setIsParentSenior] = useState("Yes");

  const family_no = isFamilySenior === 'No'? 
    parseFloat(values.family_insurance || 0) + parseFloat(values.family_checkup || 0)  > 25000 ? 
    25000 : parseFloat(values.family_insurance || 0) + parseFloat(values.family_checkup || 0) 
    : null;
  console.log("family_no", family_no)
  const family_yes = isFamilySenior === 'Yes'? 
    parseFloat(values.family_insurance || 0) + parseFloat(values.family_checkup || 0) + parseFloat(values.med_exp_family || 0) > 50000 ? 
    50000 : parseFloat(values.family_insurance || 0) + parseFloat(values.family_checkup || 0) + parseFloat(values.med_exp_family || 0)
    : null;
  console.log("family_yes", family_yes)
  const parent_no = isParentSenior === 'No'? 
    parseFloat(values.parent_insurance || 0) + parseFloat(values.parent_checkup || 0) > 25000 ? 
    25000 : parseFloat(values.parent_insurance || 0) + parseFloat(values.parent_checkup || 0) 
    : null;
  console.log("parent_no", parent_no)
  const parent_yes = isParentSenior === 'Yes'? 
    parseFloat(values.parent_insurance || 0) + parseFloat(values.parent_checkup || 0) + parseFloat(values.med_exp_parent || 0) > 50000 ? 
    50000 : parseFloat(values.parent_insurance || 0) + parseFloat(values.parent_checkup || 0) + parseFloat(values.med_exp_parent || 0)
    : null;
  console.log("parent_yes", parent_yes)

  const totalFamily = isFamilySenior === 'Yes'? family_yes : family_no; // total_family
  const totalParent = isParentSenior === 'Yes'? parent_yes : parent_no; // total_parent

  const eligibleDeduction80 = totalFamily + totalParent; //deductions_80

  const totalVIA = parseFloat(values.deductions_80_c || 0) + parseFloat(values.deductions_80_tta || 0) + 
    parseFloat(values.deductions_80_g || 0) + parseFloat(values.deductions_80_e || 0) + 
    parseFloat(values.deductions_80_eea || 0) + parseFloat(values.deductions_80_ccd || 0) +
    eligibleDeduction80; // Total Chapter VI A Deduction

  const totalVIADeduction = totalVIA > 200000 ? 200000 : totalVIA;

  // 6. Summary
  
  const netIncome = grossSalary - (totalSalaryDeductions || 0) - (totalHPIncome || 0) - (totalVIADeduction || 0);
  const netTaxableIncome = Math.round(netIncome)
  console.log("netTaxIncome", netTaxableIncome)

  const [consent, setConsent] = useState(false)
  var consentence = "I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief and I undertake the responsibility to inform you of any changes therein, immediately."

  //const netTaxableIncome = 832000;

  //Slab 5% Calculation
  const getSlab5 = (amount) => {
    if(netTaxableIncome > 250000){
      if(netTaxableIncome > 500000){
        var taxPercent = (500000 - 250000) * 5/100;
        return parseFloat(taxPercent);
      }
      else {
        var taxPercent = (netTaxableIncome - 250000) * 5/100;
        return parseFloat(taxPercent);
      }
    }
    else{
      return 0;
    }
  }

  const getSlab10 = (amount) => {
    if(netTaxableIncome > 500000){
      if(netTaxableIncome > 750000){
        var taxPercent = (750000 - 500000) * 10/100;
        return taxPercent;
      }
      else{
        var taxPercent = (netTaxableIncome - 500000) * 10/100;
        return taxPercent;
      }
    }
    else{
      return 0;
    }
  }

  const getSlab15 = (netTaxableIncome) => {
    if(netTaxableIncome > 750000){
      if(netTaxableIncome > 1000000){
        var taxPercent = (1000000 - 750000) * 15/100;
        return taxPercent;
      }
      else{
        var taxPercent = (netTaxableIncome - 750000) * 15/100;
        return taxPercent;
      }
    }
    else{
      return 0;
    }
  }

  const slab5Tax = netTaxableIncome ? getSlab5(netTaxableIncome) : 0;
  const slab5 = Math.round((slab5Tax + Number.EPSILON) * 100) / 100;

  const slab10Tax = netTaxableIncome ? getSlab10(netTaxableIncome) : 0;
  const slab10 = Math.round((slab10Tax + Number.EPSILON) * 100) / 100;

  const slab15Tax = netTaxableIncome ? getSlab15(netTaxableIncome) : 0;
  const slab15 = Math.round((slab15Tax + Number.EPSILON) * 100) / 100;

  const totalTax = slab5 + slab10 + slab15; 

  const taxMonth = totalTax/Difference_In_Months;
  const taxPerMonth = Math.round((taxMonth + Number.EPSILON) * 100) / 100;
  
  useEffect( () => {
    getCompensation(group_id)
    .then(resp => setCompensation(resp.data))
  }, [])

  useEffect( () => {
    getEmployee(group_id)
    .then(resp => setEmployees(resp.data))
  }, [])

  useEffect( () => {
    getEmployeeTax(group_id)
    .then(resp => setEmployeeTax(resp.data))
  }, [])


  {/* End of the IT Calculation Part */}  

  const [employee, setEmployee] = useState([]);
  let empObj = {};
  empObj = (employee || []).sort((a,b) => {
    return b.id-a.id;
  });
  var empId = empObj ? (empObj && empObj[0] || []).employee_id : null;
  var foundLen = employee ? employee.length : 0;
  console.log("foundLen", foundLen)
  var str2 = "NU-";
  var str = foundLen === 0 ? "NU-0000" : empId;
  var res = str.split('-');
  var Num = res[1];
  var incrementValue = (+Num) + 1;
  incrementValue = ("0000" + incrementValue).slice(-4);
  var employeeId = str2 + incrementValue
  console.log("employeeId", employeeId)

  const [dob, setDob] = useState()
  const [finance_start, setFinanceStart] = useState()
  const [finance_end, setFinanceEnd] = useState()
  const [errors, setError] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStep2, setActiveStep2] = React.useState(0);
  

  const [radioVal, SetRadio] = React.useState({ gender: '' });
  
  const [subform, setSubForm] = React.useState(false);


  const handleSubFormClose = () => {
    setSubForm(false);
    if(userRole === 'Employee'){
      props.history.push(`${process.env.PUBLIC_URL}/itcalculation`)
      window.location.reload()
    }
    else{
      setActiveStep(activeStep - (steps.length-1));
      props.history.push(`${process.env.PUBLIC_URL}/itcalculation`)
    }
  };

  const handleNext = () => {
    // const errors = validateForm(values);
    //  if(Object.keys(errors).length) {
    //   setError(errors);
    //   return;
    //  }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  
  const validateForm = (data) => {
     const errors = [];
     if(activeStep === 0 || activeStep2 === 0){
     if(!data.name) {
       errors.name = "Employee name is required";
     }
     if(!data.father) {
        errors.father = "Father name is required";
      }
     if(!data.phone) {
       errors.phone = "Mobile number is required";
     }
     if(!data.email) {
       errors.email = "Email Id is required";
     }
    } else if(activeStep === 2 || activeStep2 === 2){
     if(!data.panno){
      errors.panno = "PAN number is required";
     }
   }
     return errors;
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleRadioChange = event => {
    SetRadio({
      ...radioVal,
      [event.target.name]: event.target.value
    });
  };

  const getStepContent = (step) => {
    switch (step) {
        case 0: 
          return <SalaryDetails assessmentYear setAssessmentYear={setAssessmentYear} 
            filterCompensation={filterCompensation} grossSalary={grossSalary} />;
        case 1:
          return <SalaryDeductions standardDeduction={standardDeduction} professionTax={professionTax} 
            month_rent={month_rent} setMonthRent={setMonthRent} leastHRA={leastHRA} 
            totalSalaryDeductions={totalSalaryDeductions} />;
        case 2: 
          return <HouseProperty interest_borrowed1={interest_borrowed1} setInterestBorrowed1={setInterestBorrowed1} 
            facilityfields={facilityfields} handleChangeFacility={handleChangeFacility} 
            handleRemoveFacility={handleRemoveFacility} addClickFacility={addClickFacility} typeHp={typeHp}
            allowed_deduction={allowed_deduction} incomeHp1={incomeHp1} totalHPIncome={totalHPIncome} />
        case 3: 
          return <ChapterVIA values={values} handleChange={handleChange} />
        case 4: 
          return <Section80D isFamilySenior={isFamilySenior} setIsFamilySenior={setIsFamilySenior}
            isParentSenior={isParentSenior} setIsParentSenior={setIsParentSenior}
            values={values} handleChange={handleChange} eligibleDeduction80={eligibleDeduction80}
            totalVIADeduction={totalVIADeduction} /> 
        case 5:
          return <Summary grossSalary={grossSalary} totalSalaryDeductions={totalSalaryDeductions}
            totalHPIncome={totalHPIncome} totalVIADeduction={totalVIADeduction} netTaxableIncome={netTaxableIncome}
            slab5={slab5} slab10={slab10} slab15={slab15} totalTax={totalTax} consentence={consentence}
            consent={consent} setConsent={setConsent} />
      default:
        throw new Error('Unknown step');
    }
  }
  
  const handleSubmit = async(event) => {
    
    const userdata = {
      emp_id: user.emp_id,
      assessment_year: assessmentYear,
      gross_salary: grossSalary,
      standard_deduction: standardDeduction,
      profession_tax: professionTax,
      month_rent: month_rent,
      least_hra: leastHRA,
      total_sal_deductions: totalSalaryDeductions,
      self_occupied_interest: 0,
      self_occupied_income: 0,
      let_out_rent: 0,
      let_out_tax_paid: 0,
      let_out_annual: 0,
      let_out_deduction: 0,
      let_out_interest: 0,
      let_out_income: 0,
      total_hp_income: totalHPIncome,
      deductions_80_c: values.deductions_80_c,
      deductions_80_tta: values.deductions_80_tta,
      deductions_80_g: values.deductions_80_g,
      deductions_80_e: values.deductions_80_e,
      deductions_80_eea: values.deductions_80_eea,
      deductions_80_ccd: values.deductions_80_ccd,
      total_family: totalFamily,
      total_parent: totalParent,
      deductions_80_d: eligibleDeduction80,
      total_via_deduction: totalVIADeduction,
      net_taxable_income: netTaxableIncome,
      slab_5: slab5,
      slab_10: slab10,
      slab15: slab15,
      total_tax: totalTax,
      status:'Pending',
      user_id: user.id,
      mgr_id: user.mgr_id,
      group_id: group_id
    }

    const taxData = {
      emp_id: user.emp_id,
      total_tax: totalTax,
      ytd: 0,
      bal_amount: totalTax,
      bal_month: Difference_In_Months,
      tax_per_month: taxPerMonth,
      user_id: user.id,
      group_id: group_id
    }
    console.log("taxData", taxData)
    postEmployeeTax(userdata)
    .then(resp => {
      if(resp.data.id){
        postEmployeeTaxPaid(taxData)
        .then(resp => {
          if(resp.data.id){
            setSubForm(true)
          }
          else{
            toast.warning("Something went wrong!!!")
          }
        })
      }
     //props.history.push(`${process.env.PUBLIC_URL}/employee`)
    })
    .catch(err => {
    console.log("Error", err);
    })
  }
  
  return (
    <React.Fragment>
      <Breadcrumb title="IT Declaration" parent="Payroll" />
      <CssBaseline />
      <main className={classes.layout}>
        { filterEmpTax ?
        <div className="container-fluid">
          <div className="row">
              <div className="col-sm-12">
                  <div className="card">
                      <div className="card-block row">
                          <div className="col-sm-12 col-lg-12 col-xl-12">
                              <div className="table-responsive">
                                  <table className="table table-sm table-bordered table-hover">
                                      <thead className="table-primary">
                                        <tr>
                                            <th scope="col">DESCRIPTION</th>
                                            <th scope="col">AMOUNT</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th>Gross Salary</th>
                                          <th>{gross_salary}</th>
                                        </tr>
                                        <tr>
                                          <th>Standard Deduction</th>
                                          <th>{standard_deduction}</th>
                                        </tr>
                                        <tr>
                                          <th>Professional Tax</th>
                                          <th>{profession_tax}</th>
                                        </tr>
                                        <tr>
                                          <th></th>
                                          <th className="text-warning">
                                            {parseFloat(standard_deduction) + parseFloat(profession_tax)}
                                          </th>
                                        </tr>
                                        <tr>
                                          <th>Net Salary</th>
                                          <th>{parseFloat(gross_salary) - (parseFloat(standard_deduction) + parseFloat(profession_tax))}</th>
                                        </tr>
                                        <tr>
                                          <th>Tax Related Deductions</th>
                                          <th></th>
                                        </tr>
                                        <tr>
                                          <th>HRA</th>
                                          <th>{least_hra}</th>
                                        </tr>
                                        <tr>
                                          <th>VI A Deductions</th>
                                          <th>{total_via_deduction}</th>
                                        </tr>
                                        <tr>
                                          <th>Home Loan Deductions</th>
                                          <th>{total_hp_income}</th>
                                        </tr>
                                        <tr>
                                          <th></th>
                                          <th className="text-danger">
                                            {parseFloat(least_hra || 0) + parseFloat(total_via_deduction || 0) +parseFloat(total_hp_income || 0)}
                                          </th>
                                        </tr>
                                        <tr>
                                          <th className="text-success"><h4>Net Taxable Salary</h4></th>
                                          <th className="text-success"><h4>{net_taxable_income}</h4></th>
                                        </tr>
                                        <tr>
                                          <th>Basic Exemption</th>
                                          <th>{'250000'}</th>
                                        </tr>
                                        <tr>
                                          <th>Tax Payable</th>
                                          <th></th>
                                        </tr>
                                        <tr>
                                          <th>5%</th>
                                          <th>{slabfive}</th>
                                        </tr>
                                        <tr>
                                          <th>10%</th>
                                          <th>{slabten}</th>
                                        </tr>
                                        <tr>
                                          <th>15%</th>
                                          <th>{slabfifteen}</th>
                                        </tr>
                                        <tr>
                                          <th className="text-info"><h5>Total Tax for the year</h5></th>
                                          <th className="text-info"><h5>{slabTotalTax}</h5></th>
                                        </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        :
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center" >
            <b><i>INCOME TAX DECLARATION</i></b>
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography align="center" variant="h5" gutterBottom>
                  <Audiotrack color="primary"/> Form is Successfully submitted <Audiotrack color="primary"/>
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} round variant="outlined" className={classes.button}>
                      Prev
                    </Button>
                  )}
                  {activeStep === steps.length - 1 ?
                    consent === true ?
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.button}
                      round
                    >
                      Submit the form
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      round
                      disabled
                    >
                      Submit the form
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="primary"
                      round
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  }
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      }
      </main>
      <React.Fragment>
      
      {/** Form Submit Model **/}
      <Dialog 
        open={subform} 
        onClose={handleSubFormClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick="true"
        disableEscapeKeyDown="true"
      >
      <DialogContent>
        <Typography align="center">
          <CheckCircleOutlineRoundedIcon style={{fontSize: '100px', color:'#4bb543'}}/>
        </Typography>
        <Grid xs={12} sm={12}>
        <Typography component="h2" variant="h6" align="center" gutterBottom>
          Thank you for submitting the form. You can check the status on your Dashboard...
        </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubFormClose} color="primary" size="sm" round>
          Close
        </Button>
      </DialogActions>
      </Dialog>
    </React.Fragment>  
    </React.Fragment>
  );
}