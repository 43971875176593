import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { TablePagination } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getWarehouse, postWarehouse, editWarehouse } from '../../actions/asyncActions';

const Warehouse = (props) => {
	const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    const [warehouse, setWarehouse] = useState([]);

    const [addmodal, setAddModal] = useState();
    const [values, setValues] = useState({});
    
    const initialFormState = { id: '', name:'', particulars:'' }
    const [ currentData, setCurrentData ] = useState(initialFormState)
    const [modal, setModal] = useState();
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
        getWarehouse(group_id)
        .then(resp => setWarehouse(resp.data))
    }, []);

    const addRow = () => {
      setAddModal(!addmodal)
    }

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleSubmit = () => {
    	const data = {
          name: values.name,
          particulars: values.particulars,
          group_id: group_id,
        }
        postWarehouse(data)
        .then(resp => {
            getWarehouse(group_id)
            .then(resp => setWarehouse(resp.data))
            setValues({ name:'', particulars:''})
            setAddModal(!addmodal)
            toast.success("Warehouse details added successfully", {position: toast.POSITION.TOP_CENTER})
          })
          .catch(err => {
            console.log("Error", err);
        })
    }

    const editRow = n => {
     setModal(!modal)
     setCurrentData({ id: n.id, name: n.name, particulars: n.particulars})
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentData({ ...currentData, [name]: value })
    }

    const handleUpdate = (id, data) => {
        const aaa = {
          id: id,
          name: data.name,
          particulars: data.particulars
        }
        editWarehouse(aaa)
        .then(resp => {
              getWarehouse(group_id)
              .then(resp => setWarehouse(resp.data))
              setModal(!modal)
              toast.success("Warehouse details updated successfully", {position: toast.POSITION.TOP_CENTER})
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }

	return(
		<Fragment>
		 <Breadcrumb title="Warehouse" parent="Inventory" />
		 	<div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Name</th>
                                                  <th scope="col">Particulars</th>
                                                  <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {warehouse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.name}</td>
                                                     <td>{n.particulars}</td>
                                                     <td>
                                                      <i className="icofont icofont-edit" onClick={() => editRow(n)} id="TooltipTop" data-placement="top"
                                                       style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff ' }}>
                                                       <UncontrolledTooltip placement="top" target="TooltipTop">
                                                        Edit row
                                                       </UncontrolledTooltip>
                                                      </i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                       <button className="btn btn-info btn-sm" type="button" onClick={() => addRow()}>
                                         Add Warehouse
                                        </button>
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={warehouse.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        {/* < !-- Using Form Modal for Add Warehouse--> */}
            <Modal isOpen={addmodal} toggle={addRow} className="modal-dialog">
                <ModalHeader toggle={addRow}>Add Warehouse</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Name:</label>
                              <input className="form-control" name="name" type="text"  
                               value={values.name} onChange={handleChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Particulars:</label>
                              <input className="form-control" name="particulars" type="text" 
                               value={values.particulars} onChange={handleChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={addRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>

        {/* < !-- Using Form Modal for Edit Warehouse --> */}
            <Modal isOpen={modal} toggle={editRow} className="modal-dialog">
                <ModalHeader toggle={editRow}>Edit Warehouse</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Name:</label>
                              <input className="form-control" name="name" type="text"  
                               value={currentData.name} onChange={handleInputChange}/>
                            </div>
                            <div className="col-xl-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Particulars:</label>
                              <input className="form-control" name="particulars" type="text" 
                               value={currentData.particulars} onChange={handleInputChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={editRow}>Cancel</Button>
                    <Button color="primary" onClick={() =>  handleUpdate(currentData.id, currentData)} >Save Changes</Button>
                </ModalFooter>
            </Modal>
		</Fragment>
	);
}

export default Warehouse;
