import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { Download } from 'react-feather';
import { TablePagination, TextField } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import {getIndentItems, getIndent, editIndent,  editIndentItems, getProjects, getClients,
getItemLone, getItemLtwo, getItemLthree, getItemLfour, getItemLfive, getAllUser, getBomItems,
BulkUploadIBOM, runIBOMScript } from '../../actions/asyncActions';

const IndentWithoutBOM = () => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const [indents, setIndents] = useState([]);
  const [indentItems, setIndentItems] = useState([]);
  const [projects, setProject] = useState([]);
  const [ clients, setClient ] = useState([]);

  const [lone, setLone] = useState([]);
  const [ltwo, setLtwo] = useState([]);
  const [lthree, setLthree] = useState([]);
  const [lfour, setLfour] = useState([]);
  const [lfive, setLfive] = useState([]);
  const [person, setPerson] = useState([]);
  const [bom_items, setBOMItems] = useState([]);

  const initialFormState = { id: '', name:'', lfour_id:'', lthree_id:'', ltwo_id:'', lone_id:'' }
  const [ data, setCurrentData ] = useState(initialFormState);
  const [modal, setModal] = useState();

  const filter_indents = indents.filter(s => s.status === 'Created' && s.mgr_id === user.id && s.bom_ref === null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect( () => {
    getIndent(group_id)
    .then(resp => {
      setIndents(resp.data);
    })
  }, []);

  useEffect( () => {
    getIndentItems(group_id)
    .then(resp => {
      setIndentItems(resp.data);
    })
  }, []);

  useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
  }, []);

  useEffect( () => {
      getClients(group_id)
      .then(resp => setClient(resp.data))
    }, []);
  
  useEffect( () => {
    getItemLone(group_id)
    .then(resp => {
      setLone(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLtwo(group_id)
    .then(resp => {
      setLtwo(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLthree(group_id)
    .then(resp => {
      setLthree(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLfour(group_id)
    .then(resp => {
      setLfour(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLfive(group_id)
    .then(resp => {
      setLfive(resp.data);
    })
  }, []);

  useEffect( () => {
    getAllUser(group_id)
    .then(resp => {
      setPerson(resp.data);
    })
  }, []);

  useEffect( () => {
    getBomItems(group_id)
    .then(resp => setBOMItems(resp.data))
  }, []);

  const [selId, setSelectedID] = React.useState([]);
  const filter_indentItems = indentItems.filter(x => x.indent_id === selId);

  const viewRow = n => {
  	setModal(!modal)
  	setSelectedID(n.id);
    const lev5 = (lfive || []).find(x=> x.id == n.l5);
    const leve5 = lev5 && lev5.name;
    const lev4 = (lfour || []).find(x=> x.id == n.l4);
    const leve4 = lev4 && lev4.name;
    const lev3 = (lthree || []).find(x=> x.id == n.l3);
    const leve3 = lev3 && lev3.name;
    const lev2 = (ltwo || []).find(x=> x.id == n.l2);
    const leve2 = lev2 && lev2.name;
    const lev1 = (lone || []).find(x=> x.id == n.l1);
    const leve1 = lev1 && lev1.name;
    
    setCurrentData({ id: n.id, project_id: n.project_id, client_id: n.client_id, qty:n.qty, date: n.date, 
    l1:n.l1, l2:n.l2, l3:n.l3, l4:n.l4, l5:n.l5, status:n.status })
  }

  const approveIndent = (id, n) => {
    const data = {
      id: id,
      user_id: user.id,
      status: 'Approved',
    }
    editIndent(data)
    .then(resp => {
          getIndent(group_id)
          .then(resp => setIndents(resp.data))
          toast.success("Indent Approved !!!")
        })
        .catch(err => {
          console.log("Error", err);
        })
  }

  const [bomuploadmodal, setBOMUploadModal] = useState()
  const [projectId, setProjectId] = useState()
  const [clientId, setClientId] = useState()

  const uploadBOM = n => {
    setProjectId(n.project_id)
    setClientId(n.client_id)
    setBOMUploadModal(!bomuploadmodal)
  }

  const bomtype = [{ name: 'Installation' }, {name: 'Others'}];
  const [typeval, setTypeVal] = useState(null);
  const [bomfile, setBomFile] = React.useState([]);
  var [isLoading, setIsLoading] = useState(false);

  const handleUploadBom = () => {
    setBomFile(document.getElementById('filebom').files[0]);
  }

  const handleBOMSubmit = () => {
    const formData = new FormData()
      formData.append( 'file', bomfile )

      const data = {
        project_id: projectId,        
        client_id: clientId,
        user_id: user.id,
        group_id: group_id,
        type_val: typeval,
      } 
      console.log("data", data);

      setIsLoading(true);
      if (data) {
        setTimeout(() => { 
          BulkUploadIBOM(formData)
          .then(resp => { 
            runIBOMScript(data)
            .then(resp => {
              setTypeVal(null)
              setBomFile([])
            })
          })
          setIsLoading(false)
          toast.success("BOM Uploaded successfully!!!")
        }, 5000);
      }
  }

	return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Indent</th>
                                                  <th scope="col">Project</th>
                                                  <th scope="col">Client</th>
                                                  <th scope="col">Status</th>
                                                  <th scope="col">Created Date</th>
                                                  <th scope="col">Created By</th>
                                                  <th scope="col">Item(Nos)</th>
                                                  <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filter_indents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                  const projectName = (projects || []).find(x=> x.id === n.project_id);
                								                  const clientName = (clients || []).find(x=> x.id === n.client_id);
                								                  const noOfItems = indentItems.filter(x=>x.indent_id === n.id);
                								                  const totalItems = noOfItems.length;
                								                  const userName = person.find(x=>x.id === n.user_id);
                								                  const createdBy = userName && userName.firstname;

                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{("IN-000").concat(n.id)}</td>
                                                     <td>{projectName && projectName.name}</td>
                                                     <td>{clientName && clientName.name}</td>
                                                     <td>{n.status}</td>
                                                     <td>
                                                      {new Date(n.createdAt).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{createdBy}</td>
                                                     <td>{totalItems}</td>
                                                     <td>
                                                      <i className="fa fa-eye" onClick={() => viewRow(n)} id="TooltipTop" data-placement="bottom"
                                                       style={{ width: 35, fontSize: 16, padding: 11, color: '#115f3f' }}>
                                                       <UncontrolledTooltip placement="bottom" target="TooltipTop">
                                                        View Items
                                                       </UncontrolledTooltip>
                                                      </i>
                                                      <i className="fa fa-plus" onClick={() => uploadBOM(n)} id="TooltipTop2" data-placement="bottom"
                                                       style={{ width: 35, fontSize: 16, padding: 11, color: '#21559e' }}>
                                                       <UncontrolledTooltip placement="bottom" target="TooltipTop2">
                                                        Upload BOM
                                                       </UncontrolledTooltip>
                                                      </i>
                                                      <i className="fa fa-thumbs-up" onClick={() => approveIndent(n.id, n)} id="TooltipTop1" data-placement="bottom"
                                                       style={{ width: 35, fontSize: 16, padding: 11, color: '#d81cb8' }}>
                                                       <UncontrolledTooltip placement="bottom" target="TooltipTop1">
                                                         Approve Indent
                                                       </UncontrolledTooltip>
                                                      </i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                        <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                        {/*
                                          <button className="btn btn-info btn-sm" type="button">
                                            <Download size="14"/>Export
                                          </button>
                                        */}
                                        </div>
                                        <div className="col-sm-6 col-lg-6 col-xl-6">
                                          <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={filter_indents.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                           />
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          {/* < !-- Using Modal for Viewing Indent --> */}
            <Modal isOpen={modal} toggle={viewRow} className="modal-dialog modal-xl">
              <ModalHeader toggle={viewRow}>View Indent</ModalHeader>   
                <ModalBody>
                   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Project</th>
                                                    <th scope="col">Part No</th>
                                                    <th scope="col">Part Name</th>
                                                    <th scope="col">Material</th>
                                                    <th scope="col">Finish Size</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filter_indentItems.map((n)=> {
								                  const proj = projects.find(x=>x.id === n.project_id);
								                  const clint = clients.find(x=>x.id === n.client_id);
								                  const projectName = proj && proj.name;
								                  const clientName = clint && clint.name;

                                                  return (
                                                    <tr>
                                                     <td>{projectName}</td>
                                                     <td>{n.part_num}</td>
                                                     <td>{n.l4}</td>
                                                     <td>{n.material}</td>
                                                     <td>{n.finish_size}</td>
                                                     <td>{n.qty}</td>
                                                     <td>
                                                      {new Date(n.date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{n.remarks}</td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={viewRow}>Close</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Modal for Uploading BOM --> */}
            <Modal isOpen={bomuploadmodal} toggle={uploadBOM}>
              <ModalHeader toggle={uploadBOM}>Upload BOM</ModalHeader>
                <ModalBody>
                  <div className="row text-center">
                   <div className="col-sm-12 col-xl-12">
                    <Autocomplete
                      freeSolo
                      options={bomtype}
                      getOptionLabel={option => option.name}
                      value={typeval}                      
                      onChange={(event, newValue) => {
                        setTypeVal(newValue);
                      }}            
                      renderInput={params => (
                        <TextField {...params} label="Type" margin="normal" variant="outlined" helperText="Please select BOM Type" fullWidth />
                      )}
                    />
                   </div>
                   <div className="col-sm-12 col-xl-12">
                    <label className="col-form-label text-body" htmlFor="message-text">Attach BOM File:</label>
                      <TextField id="filebom" type="file" margin="dense" variant="outlined" onChange={handleUploadBom} fullWidth/>
                   </div>
                   <div className="col-sm-12 col-xl-12 m-t-15">
                    <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleBOMSubmit}>Submit</button>
                   </div>
                  </div>                          
                </ModalBody>
            </Modal>
        </Fragment>
    );
}
export default IndentWithoutBOM;