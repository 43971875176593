import React, { Fragment } from 'react';
import { TextField } from '@material-ui/core';

const Section80D = (props) => {
    const {isFamilySenior, isParentSenior, values, eligibleDeduction80, totalVIADeduction } = props;

    return(
        <Fragment>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-block row">
                        <div className="col-xl-12">
                            <div className="table-responsive">
                                <table className="table table-sm table-bordered table-hover">
                                    <thead className="table-primary text-center">
                                      <tr>
                                          <th colSpan={12}>Section 80D</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                         <th>1</th>
                                         <th>
                                            Whether you or any of your family member(excluding parents) is a senior citizen?
                                         </th>
                                         <th colSpan={3}>
                                            <select 
                                                defaultValue={isFamilySenior} 
                                                onChange={(e) => props.setIsFamilySenior(e.target.value)}
                                                className="text-success"
                                                style={{width: '50%', height: '100%'}}
                                            >
                                            <option value={"Yes"}>{"Yes"}</option>
                                            <option value={"No"}>{"No"}</option>
                                            
                                            </select>
                                         </th>
                                        </tr>
                                    { isFamilySenior === 'No' ?
                                        <>
                                        <tr>
                                            <td>a</td>
                                            <td>Self and Family</td>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <td>(i)</td>
                                            <td>Health Insurance</td>
                                            <th>
                                            <input name="family_insurance" value={values.family_insurance} onChange={props.handleChange} 
                                             type="number" fullWidth/>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>(ii)</td>
                                            <td>Preventive Health Checkup</td>
                                            <th>
                                            <input name="family_checkup" value={values.family_checkup} onChange={props.handleChange} 
                                             type="number" fullWidth/>
                                            </th>
                                        </tr>
                                        </>
                                        :
                                        <>
                                        <tr>
                                            <td>b</td>
                                            <td>Self and Family(Senior Citizen)</td>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <td>(i)</td>
                                            <td>Health Insurance</td>
                                            <th>
                                            <input name="family_insurance" value={values.family_insurance} onChange={props.handleChange} 
                                             type="number" fullWidth/>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>(ii)</td>
                                            <td>Preventive Health Checkup</td>
                                            <th>
                                            <input name="family_checkup" value={values.family_checkup} onChange={props.handleChange} 
                                             type="number" fullWidth/>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>(iii)</td>
                                            <td>
                                             Medical Expenditure(This deduction to be claimed on which health insurance is not claimed)
                                            </td>
                                            <th>
                                            <input name="med_exp_family" value={values.med_exp_family} onChange={props.handleChange} 
                                             type="number" fullWidth/>
                                            </th>
                                        </tr>
                                        </>
                                    }

                                        <tr>
                                         <th>2</th>
                                         <th>
                                            Whether any one of your parents is a senior citizen?
                                         </th>
                                         <th colSpan={3}>
                                            <select 
                                                defaultValue={isParentSenior} 
                                                onChange={(e) => props.setIsParentSenior(e.target.value)}
                                                className="text-success"
                                                style={{width: '50%', height: '100%'}}
                                            >
                                            <option value={"Yes"}>{"Yes"}</option>
                                            <option value={"No"}>{"No"}</option>
                                            
                                            </select>
                                         </th>
                                        </tr>
                                    {isParentSenior === 'No' ?
                                        <>
                                        <tr>
                                            <td>a</td>
                                            <td>Parents</td>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <td>(i)</td>
                                            <td>Health Insurance</td>
                                            <th>
                                            <input name="parent_insurance" value={values.parent_insurance} onChange={props.handleChange} 
                                             type="number" fullWidth/>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>(ii)</td>
                                            <td>Preventive Health Checkup</td>
                                            <th>
                                            <input name="parent_checkup" value={values.parent_checkup} onChange={props.handleChange} 
                                             type="number" fullWidth/>
                                            </th>
                                        </tr>
                                        </>
                                        :
                                        <>
                                        <tr>
                                            <td>b</td>
                                            <td>Parents(Senior Citizen)</td>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <td>(i)</td>
                                            <td>Health Insurance</td>
                                            <th>
                                            <input name="parent_insurance" value={values.parent_insurance} onChange={props.handleChange} 
                                             type="number" fullWidth/>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>(ii)</td>
                                            <td>Preventive Health Checkup</td>
                                            <th>
                                            <input name="parent_checkup" value={values.parent_checkup} onChange={props.handleChange} 
                                             type="number" fullWidth/>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>(iii)</td>
                                            <td>
                                             Medical Expenditure
                                            </td>
                                            <th>
                                            <input name="med_exp_parent" value={values.med_exp_parent} onChange={props.handleChange} 
                                             type="number" fullWidth/>
                                            </th>
                                        </tr>
                                        </> 
                                    }   
                                        <tr>
                                            <th>3</th>
                                            <th>Eligible Amount of Deduction</th>
                                            <th>
                                            <input value={eligibleDeduction80} fullWidth/>
                                            </th>
                                        </tr>                                
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xl-12 text-center">
                            <div className="card">
                                <div className="card-block row">
                                    <div className="col-sm-12 col-lg-12 col-xl-12"></div>      
                                        <div className="col-xl-12 m-t-10">
                                            <h4 className="text-success">Total VI A Deductions for the FY: {totalVIADeduction}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Section80D;