import React, { Fragment, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import {TextField, Backdrop , CircularProgress, TablePagination} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { uploadCreditStmt, runCreditScript, getTempCredit, postTempCredit } from '../../actions/asyncActions';


const CreditStatement = () => {
 
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;

 const [page, setPage] = React.useState(0);
 const [rowsPerPage, setRowsPerPage] = React.useState(5);

 const handleChangePage = (event, newPage) => {
   setPage(newPage);
 };

 const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
 };

 const [creditFile, setCreditFile] = React.useState();
 
 const bankNames = [{ name: 'HDFC Bank', value: 'HDFC' }, {name: 'Kotak Bank', value: 'KOTAK'}, {name: 'CITI Bank', value: 'CITI'},
               {name: 'ICICI Bank', value: 'ICICI'}];
 const [bankval, setBankVal] = useState(null);
 const bankValName = (bankval || []).value;

 const [tempCredits, setTempCredit] = useState([]);
 let filterTempCredits = (tempCredits || []).filter(x=> x.status === 'Processing').sort((a,b) => {return b.id-a.id});
 let processedTempCredits = (tempCredits || []).filter(x=> x.status === 'File Processed').sort((a,b) => {return b.id-a.id});
 
 useEffect(() => { 
  const interval = setInterval(() => { 
    getTempCredit(group_id) 
    .then(resp => setTempCredit(resp.data))  
    }, 10000); 
  return () => clearInterval(interval);
 }, [tempCredits]);
 
 const handleUploadFile = () => {
   setCreditFile(document.getElementById('filecredit').files[0]);
 }

 const handleSubmit = async (event) => {
    const usersss = JSON.parse(localStorage.getItem("m_users"));
    const group_idd = usersss.group_id;
    const formData = new FormData()
    formData.append( 'file', creditFile )
    const inv = await uploadCreditStmt(formData);
    const aaa = inv.data.filename;
    const filepath = "/Data/Credit_Statements/" + aaa;

    const tempCredit = {
      filename: aaa,
      status: 'Processing',
      user_id: usersss.id,
      group_id: group_idd
    }

    const data = {
      name: bankValName,
      filename: filepath,
      user_id: user.id,
      group_id: group_id
    }

    postTempCredit({tempCredit, data})
    .then(resp => {
      getTempCredit(group_id) 
       .then(resp => setTempCredit(resp.data))
      setCreditFile('')
      setBankVal('')
      toast.success("Credit card statement is being uploaded !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 5000})
    })
 }

	return (
        <Fragment>
         <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-6">
               <div className="ribbon-wrapper card">
                <div className="card-body">
                  <div className="ribbon ribbon-bookmark ribbon-primary">Upload Credit Card Statement</div>
                    <div className="col-md-12">
                      <Autocomplete
                        freeSolo
                        options={bankNames}
                        getOptionLabel={option => option.name}
                        value={bankval}                      
                        onChange={(event, newValue) => {
                          setBankVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Bank" margin="normal" variant="outlined" helperText="Please select Bank" fullWidth />
                        )}
                      />
		                </div>
		                <div className="col-md-12">
		                  <label className="col-form-label text-body" htmlFor="message-text">Attach File:</label>
                      <TextField id="filecredit" type="file" margin="dense" variant="outlined" onChange={handleUploadFile} fullWidth/>
                    </div>
                </div>
                {creditFile ?
                <div className="card-footer text-center">
                  <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Submit</button>
                </div>
                : null
                }
              </div>
             </div>
             <div className="col-sm-12 col-xl-6">
              <div className="card">
                <div className="card-block row">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-hover">
                       <thead className="table-info">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Filename</th>
                          <th scope="col">Status</th>
                        </tr>
                       </thead>
                       <tbody>
                        {(filterTempCredits || []).map((n,i) => {
                          return(
                           <tr>
                            <td>{i+1}</td>
                            <td>{n.filename}</td>
                            <td style={{color: "blue", fontSize:16}}>
                             <b>{n.status}</b>
                            </td>
                           </tr>
                          )
                         })
                        }
                       </tbody>
                      </table>
                    </div>
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                     <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={filterTempCredits.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                     />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-block row">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-hover">
                       <thead className="table-info">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Filename</th>
                          <th scope="col">Status</th>
                        </tr>
                       </thead>
                       <tbody>
                        {(processedTempCredits || []).map((n,i) => {
                          return(
                           <tr>
                            <td>{i+1}</td>
                            <td>{n.filename}</td>
                            <td style={{color: "green", fontSize:16}}>
                             <b>{n.status}</b>
                            </td>
                           </tr>
                          )
                         })
                        }
                       </tbody>
                      </table>
                    </div>
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                     <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={processedTempCredits.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                     />
                    </div>
                  </div>
                </div>
              </div>
             </div> 
            </div>
          </div>
        </Fragment>
    );
}
export default CreditStatement;
