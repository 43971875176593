import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import { sendInviteVendor, postUsers } from '../../../actions/asyncActions';

const BasicForm = (props) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const userfname = user && user.firstname;
    const group_id = user.group_id;
    const [values, setValues] = useState({});
    var phoneLen = values ? values.phone : null;
    var phoneLength = phoneLen ? phoneLen.length : 0;
    var phoneno = /^\d{10}$/;
    
    const handleChange = event => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    };

    const handleSubmit = () => {
        const userdata = {
          firstname: values.firstname,
          username: values.email,
          password: values.phone,
          email: values.email,
          phone: values.phone,
          department: 'HR',
          role: 'Employee',
          group_id: group_id
        }
        const sendEmail = {
          fname: values.firstname,  
          email: values.email,
          phone: values.phone,
          type: 'Employee'
        }
        if((!values.firstname) || (!values.phone) || (!values.email)) {
          toast.error("Please fill all the fields", {position: toast.POSITION.TOP_CENTER})
        } 
        else if(!(values.phone.match(phoneno))) {
          toast.error("Mobile number is invalid", {position: toast.POSITION.TOP_CENTER})
        }
        else {
            sendInviteVendor(sendEmail)
            postUsers(userdata)
            toast.success("Details sent successfully !!!", {position: toast.POSITION.TOP_CENTER})
            setValues({firstname:'', phone:'', email:''})
            props.history.push(`${process.env.PUBLIC_URL}/employee`)
        }
    }

    const handleBack = () => {
      props.history.push(`${process.env.PUBLIC_URL}/employee`)
    }

    return (
        <Fragment>
          <Breadcrumb title="Basic Form" parent="Employee" />
          <div className="container-fluid">
            <div className="row">
             <div className="offset-xl-2 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="ribbon-wrapper card">
                <div className="card-body text-center">
                  <div className="ribbon ribbon-bookmark ribbon-secondary">Basic Info</div>
                    <div className="form-row">
                     <div className="col-xl-12">
                      <label className="col-form-label text-body" htmlFor="message-text">Name:</label>
                      <input className="form-control" name="firstname" type="email" 
                       value={values.firstname} onChange={handleChange}/>
                     </div>
                     <div className="col-xl-12">
                      <label className="col-form-label text-body" htmlFor="message-text">Mobile number:</label>
                      <input className="form-control" name="phone" type="phone" 
                       value={values.phone} onChange={handleChange}/>
                     </div>
                     <div className="col-xl-12">
                      <label className="col-form-label text-body" htmlFor="message-text">Enter an Email address:</label>
                      <input className="form-control" name="email" type="email" 
                       value={values.email} onChange={handleChange}/>
                     </div>
                     <div className="col-xl-6 m-t-15 text-left">
                      <i className="fa fa-arrow-left" onClick={() => handleBack()} id="TooltipTop" data-placement="top"
                        style={{ width: 35, fontSize: 22, padding: 11, color: 'green' }}>
                        <UncontrolledTooltip placement="top" target="TooltipTop">
                          Go Back
                        </UncontrolledTooltip>
                      </i>
                     </div>
                     <div className="col-xl-6 m-t-15 text-right">
                      <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Send Email</button>
                     </div>
                    </div>
                  </div>
                </div>
             </div>
            </div>
          </div> 
        </Fragment>
      );
}

export default withRouter(BasicForm);