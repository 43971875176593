import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Upload, Briefcase, ThumbsUp, ThumbsDown  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import InvoiceProcess from './InvoiceProcess';
import InvoiceReview from './InvoiceReview';

const VendorDashTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="Invoice" parent="Dashboard" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Upload />Submit Invoice
                                </Tab>
                                <Tab>
                                    <Briefcase />Summary
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <InvoiceProcess />
                                </TabPanel>
                                <TabPanel>
                                    <InvoiceReview />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

export default VendorDashTab;