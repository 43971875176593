import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import {Autocomplete} from '@material-ui/lab';
import { TextField, MenuItem, FormHelperText, TablePagination } from '@material-ui/core';
import { getLogs, postLogs, getGrinItems, getVendorForm, getPOItems, editGrinItems, getGrLogs } from '../../actions/asyncActions';

const Inventory = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const [values, setValues] = useState({});
    const [errors, setError] = React.useState({});
    const [vendorForm, setVendors] = useState([]);
    const [vendor_val, setVendorVal] = useState(null);
    const vendorId = vendor_val && vendor_val.id;
    const vendorName = vendor_val && vendor_val.supname;
    const [securityLogs, setSecurityLogs] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [grinItems, setGrinItems] = useState([]);
    const [poitems, setPOItems] = useState([]);

    const [grLogs, setGrLogs] = useState([]);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getVendorForm(group_id)
      .then(resp => setVendors(resp.data))
    }, []);

    useEffect( () => {
      getGrinItems(user.group_id)
      .then(resp => setGrinItems(resp.data))
    }, []);

    useEffect( () => {
      getPOItems(group_id)
       .then(resp => setPOItems(resp.data))
    }, []);

    useEffect( () => {
      getLogs(group_id)
      .then(resp => setSecurityLogs(resp.data))
    }, []);

    useEffect( () => {
      getGrLogs(group_id)
      .then(resp => setGrLogs(resp.data))
    }, []);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const validateForm = (data) => {
    const errors = [];
    if(data.emp_id === data.mgr_id){
      errors.mgr_id = "Cannot select Employee as Manager";
     }
     return errors;
  }

  const handleSubmit = () => {
    const users = JSON.parse(localStorage.getItem("m_users"));
    const group_id = users.group_id;
    const logs = {
      vehicle_no: values.vehicle,
      contact_person: values.contact,
      vendor_id: vendorId,
      vendor_name: vendorName,
      recd_time: new Date(),
      user_id: users.id,
      group_id: group_id,
      company_id: users.company_id
    }
    console.log("security", logs);
    // const errors = validateForm(user);
    // if(Object.keys(errors).length) {
    //   setError(errors);
    //   return;
    // }
    postLogs(logs)
    .then(resp => {
        getLogs(user.group_id)
        .then(resp => setSecurityLogs(resp.data))
        setValues([]);
        setVendorVal('');
      })
      .catch(err => {
        console.log("Error", err);
    })
  }

  const approveItem = (id, n) => {
    const data = {
      id: id,
      user_id: user.id,
      status: 'Approved',
    }
    editGrinItems(data)
    .then(resp => {
          getGrinItems(group_id)
          .then(resp => setGrinItems(resp.data))
          toast.success("Quality Approved !!!")
        })
        .catch(err => {
          console.log("Error", err);
        })
  }

        return (
            <Fragment>
            <Breadcrumb title="GR Logs" parent="GRIR" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-info">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">GRN</th>
                                                  <th scope="col">PartNum</th>
                                                  <th scope="col">Item Name</th>
                                                  <th scope="col">Material</th>
                                                  <th scope="col">POQty</th>
                                                  <th scope="col">RecdQty</th>
                                                  <th scope="col">ApprQty</th>
                                                  <th scope="col">Date</th>
                                                  <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {grLogs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  const aaa = vendorForm.find(x=>x.id === n.vendor_id);
                                                  const vendorName = aaa && aaa.supname || [];
                                                  const porderItems = poitems.find(x=>x.id === n.po_itemno);
                                                  const partNum = porderItems && porderItems.part_num;
                                                  const partName = porderItems && porderItems.part_name;
                                                  const material = porderItems && porderItems.material;
                                                  const qty = porderItems && porderItems.qty;
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{('GR-000').concat(n.grin)}</td>
                                                     <td>{partNum}</td>
                                                     <td>{partName}</td>
                                                     <td>{material}</td>
                                                     <td>{n.poqty}</td>
                                                     <td>{n.rec_qty}</td>
                                                     <td>{n.apr_qty}</td>
                                                     <td>
                                                      {new Date(n.createdAt).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{n.status}</td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={grLogs.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        );
}

export default Inventory;