import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getVendorForm, getIndentItems, getProjects, getGroup } from '../../actions/asyncActions';
import * as moment from 'moment';

export default function MaterialTableDemo(props) {
  
  const {grnNumber, poNumber, supplierCode, supplierName, MaterialName, Description, Warehouse, 
         Remarks, Quantity, uom } = props;
  const datee = new Date();
  const newDate = moment(datee).format('ll');

  return (
    <div id="printreceipt">
     <section className="sheet padding-5mm">
      <table style={{width:'95%'}}>
        <tr> 
          <td style={{textAlign:"center"}}>
           <h1>Stock Card</h1>
          </td>
        </tr>
      </table>
      <table style={{width:'95%'}}>
        <tr>
        <td style={{textAlign:"center"}}>
         <pre>
          Dated: <b>{newDate}</b>
         </pre>
        </td>
       </tr>
      </table>
      <table style={{width:'95%'}}>
      <tr>
       <td> 
        <pre>
         GRN: <b>{grnNumber}</b>
        </pre>
       </td>
       <td> 
        <pre>
         PO: <b>{poNumber}</b>
        </pre>
       </td>
      </tr>
      <tr>
       <td> 
        <pre>
         Supplier Code: <b>{supplierCode}</b>
        </pre>
       </td>
       <td> 
        <pre>
         Supplier Name: <b>{supplierName}</b>
        </pre>
       </td>
      </tr>
      <tr>
       <td> 
        <pre>
         Material Name: <b>{MaterialName}</b>
        </pre>
       </td>
       <td> 
        <pre>
         Description: <b>{Description}</b>
        </pre>
       </td>
      </tr>
      <tr>
        <td>
         <pre>
          UOM : <b>{uom}</b>
         </pre>
        </td>
        <td>
         <pre>
          Quantity: <b>{Quantity}</b>
         </pre>
        </td>
      </tr>
      <tr>
        <td>
         <pre>
          Warehouse : <b>{Warehouse}</b>
         </pre>
        </td>
        <td>
         <pre>
          Remarks: <b>{Remarks}</b>
         </pre>
        </td>
      </tr>
      </table>

     </section>
    </div>
  );
}