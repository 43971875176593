import React, { Fragment, useState , useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { indianNumberFormat, usNumberFormat } from '../../../../actions/numberFormat';
import { getRaisedInvoices, getRaisedInvItems, getSettings } from '../../../../actions/asyncActions';

const RaisedInvoices = (props) => {
	const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

   //  const elementStyle ={
	  // border:'solid',
	  // borderRadius:'10px',
	  // position:'relative',
	  // left:'1vh',
	  // height:'6vh',
	  // width:'23vh',
	  // marginTop:'1vh',
	  // marginBottom:'1vh',
	  // textAlign: 'center'
   //  }

    const [raisedinvoices, setRaisedInvoices] = useState([]);
    const filterInvoices = raisedinvoices.filter(x=>x.status_type === 'Preparation');

    const [raiseInvItems, setRaiseInvItems] = useState([]);
    const [settings, setSettings] = useState([]);
    const curSettings = settings.find(x=>x.group_id === group_id);
    const foundCurrency = curSettings ? curSettings && curSettings.currency : null;

    //const [search, setSearch] = useState('');
	
	//const [filteredInvoices, setFilteredInvoices] = useState([]);
  
    // useEffect(() => {
  	 //  setFilteredInvoices(
  	 //   filterInvoices.filter(n => {
  	 // 	 return n.client_name.toLowerCase().includes(search.toLowerCase());
  	 //   })
  	 //  )
    // }, [search, filterInvoices])

    useEffect( () => {
	  getRaisedInvoices(group_id)
	  .then(resp => setRaisedInvoices(resp.data))
	}, []);

	useEffect( () => {
	  getRaisedInvItems(group_id)
	  .then(resp => setRaiseInvItems(resp.data))
	}, []);

	useEffect( () => {
	  getSettings(group_id)
	  .then(resp => setSettings(resp.data))
	}, []);

	const viewForm = (n) => {
	 const invoiceId = n.id;
	 const invoiceNo = n.invoice_no;
	 const clientId = n.client_id;
	 const clientName = n.client_name;
	 const billingAddress = n.billing_address ;
	 const txnType = n.txn_type;
	 const status = n.status_type;
	 const dinvoice = n.doi;
	 const creditPeriod = n.credit_period;
	 const dueInvoice = n.due_date;
	 const placeSupply = n.place_supply;
	 const totalAmount = n.total_amount;
	 const currency = n.currency;
	 const exchangeRate = n.exchange_rate;
	 const customerNotes = n.cus_notes;
	 const termsConditions = n.terms;
	 const billType = n.bill_type;
	 const invItems = raiseInvItems.filter(x=>x.invoice_id === n.id);
	 props.history.push(`${process.env.PUBLIC_URL}/viewrinvoice`, {invoiceId, invoiceNo, clientId, clientName, billingAddress, txnType,
		status, dinvoice, creditPeriod, dueInvoice, placeSupply, totalAmount, currency, exchangeRate, customerNotes, termsConditions, 
		billType, invItems});
	} 
	return(
		<Fragment>
		 <div className="container-fluid">
              <div className="row">
              {/*
               <div className="col-xl-12">
                <input type="text" placeholder="Search Invoice" style={elementStyle} value={search}
	              onChange={e => setSearch(e.target.value)} />
               </div>
              */}
               <div className="col-xl-12">
                 <div className="row">
                  {filterInvoices.map((n, i) => {
                   // const aaa = (patientStatus || []).find(x=> x.form_id === n.id);
                   // const filterStatus = aaa && aaa.status;
                   // const filterAgent = aaa && aaa.assign_to;
                   // const aaaPickup = aaa && aaa.pickup;
                   //var amount = parseFloat(n.total_amount)
                   var totalAmount = foundCurrency ==='INR' ? indianNumberFormat(n.total_amount) : usNumberFormat(n.total_amount);
                   var totalAmountB = n.currency === 'USD' ? usNumberFormat(n.total_amount) : indianNumberFormat(n.total_amount)
                   const invoiceDate = new Date(n.doi).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric'});
                   const dueDate = new Date(n.due_date).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric'});
                   return (
                   	 <div className="col-sm-12 col-xl-4 col-md-6">
			            <div className="ribbon-wrapper card">
			              <div className="card-body">
							  {n.bill_type === 'Credit' ?
							  	<div className="ribbon ribbon-bookmark ribbon-danger">
								  <span>Credit Note #</span>{n.invoice_no}
							  	</div>
								:
								<div className="ribbon ribbon-bookmark ribbon-secondary">
								  <span>Invoice #</span>{n.invoice_no}
						    	</div>
							  }
			                <div className="row">
			                 <div className="col-sm-12 col col-md-12">
			                 <p>
							  Txn Type: <b>{n.txn_type}</b><br/>
			                  Client: <b>{n.client_name}</b><br/>
			                  Billing Address: <b>{n.billing_address}</b><br/>
			                  Date of Invoice: <b> {invoiceDate}</b><br/>
                              Due Date: <b> {dueDate}</b><br/>
			                  Total Amount: <b>{totalAmountB}</b><br/>
							  Status: <b>{n.status_type}</b><br/>
			                 </p>
				                <i className="fa fa-eye" onClick={() => viewForm(n)} id="TooltipTop" data-placement="top"
	                              style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
	                              <UncontrolledTooltip placement="top" target="TooltipTop">
	                                 View Invoice
	                              </UncontrolledTooltip>
	                            </i>
			                 </div>
			                </div>
			              </div>
			            </div>
			         </div>
	                 );
	                })
	               }
                 </div>
               </div>
              </div>
            </div>
		</Fragment>
	);
}

export default withRouter(RaisedInvoices);