import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button } from 'reactstrap';
import { TextField, FormHelperText, MenuItem, TablePagination, Snackbar, Slide, IconButton } from '@material-ui/core';
import {Close as CloseIcon } from "@material-ui/icons";
import {Autocomplete} from '@material-ui/lab';
import { Download } from 'react-feather';
import { CSVLink } from "react-csv";
import { getMasterInventory, getTransInventory, getAllUser, getIssuanceDownload } from '../../actions/asyncActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MaterialIssuanceTable = () => {
 
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;

 const [masterInventory, setMasterInventory] = useState([]);
 const [transInventory, setTransInventory] = useState([]);
 
 const [allUsers, setAllUsers] = useState([]);
 const filterUsers = allUsers.filter(x=>x.type === 'operations');
 const [user_val, setUserVal] = useState(null);
 const selectedUser = user_val && user_val.id;

 const filterTransInventory = transInventory.filter(x=>x.issued_user_id === selectedUser);

 const [issuance_download, setIssuanceDownload] = useState([]);

 const [page, setPage] = React.useState(0);
 const [rowsPerPage, setRowsPerPage] = React.useState(5);

 const handleChangePage = (event, newPage) => {
  setPage(newPage);
 };

 const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
 };

 useEffect( () => {
   getMasterInventory(group_id)
    .then(resp => setMasterInventory(resp.data))
 }, []);

 useEffect( () => {
   getTransInventory(group_id)
    .then(resp => setTransInventory(resp.data))
 }, []);

 useEffect( () => {
   getAllUser(group_id)
    .then(resp => setAllUsers(resp.data))
 }, []);

 const [open_download, setOpenDownload] = React.useState(false);
 const handleReviewClose = () => {
    setOpenDownload(false);
    setUserVal(null);
 };

 const handleDownload = () => {
    const data ={
      user_id: selectedUser
    }
    getIssuanceDownload(data)
    .then(resp => setIssuanceDownload(resp.data))
    .catch(err => {
      console.log("Error", err);
    })
    setOpenDownload(true)
 }

  return (
      <Fragment>
        <Breadcrumb title="Issuance Table" parent="Issuance" />
         <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-12">
                <div className="card card-absolute">
                  <div className="card-header bg-secondary">
                    <h5>Material Issuance Table</h5>
                  </div>
                  <div className="card-body">
                   <div className="row">
                     <div className="col-md-3">
                      <Autocomplete
                        freeSolo
                        options={filterUsers}
                        getOptionLabel={option => option.firstname}
                        value={user_val}                      
                        onChange={(event, newValue) => {
                          setUserVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Issued Person" margin="normal" variant="outlined" 
                           helperText="To whom items were issued?" fullWidth />
                        )}
                      />
                     </div>
                   </div>
                     {selectedUser ?
                      <div className="row">
                      <div className="col-xl-12">
                       <div className="table-responsive">
                          <table className="table table-sm table-bordered table-hover">
                              <thead className="table-info">
                                  <tr>
                                    <th scope="col">Sl.No</th>
                                    <th scope="col">Part No</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Rate</th>
                                    <th scope="col">Total</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {filterTransInventory.map((n, i)=> {
                                    const inventMaster = masterInventory.find(x=>x.id === n.inventory_id);
                                    const idd = inventMaster && inventMaster.id;
                                    const partNo = inventMaster && inventMaster.part_no;
                                    const Desc = inventMaster && inventMaster.description;
                                    const bbb = filterTransInventory.filter(x=>x.inventory_id === idd);
                                    const findQty = bbb.reduce((a,b) => a + parseFloat(b.qty), 0)

                                    return (
                                      <tr>
                                       <th scope="row">{i+1}</th>
                                       <td>{partNo}</td>
                                       <td>{Desc}</td>
                                       <td>{-(n.qty)}</td>
                                       <td>{n.rate}</td>
                                       <td>{-(n.total)}</td>
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                          </table>
                       </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="card-block row">
                            <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                              <button className="btn btn-info btn-sm" type="button" onClick={handleDownload}>
                                <Download size="14"/>Export
                              </button>
                            </div>
                            <div className="col-sm-6 col-lg-6 col-xl-6">
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filterTransInventory.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                               />
                            </div>
                          </div>
                        </div>
                      </div>
                     </div>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
         </div> 

        {/***** Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download}
          autoHideDuration={6000}
          onClose={handleReviewClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={issuance_download}
              filename="Issuance.csv"
              className="hidden"
              target="_blank">
              <Button onClick={getIssuanceDownload} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleReviewClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Fragment>
    );
}
export default MaterialIssuanceTable;