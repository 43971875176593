import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormHelperText, TablePagination } from '@material-ui/core';
import { getClients, msaUpload, othermsaUpload, postClients,
        editClients, approveClients, getAllUser, sendManagerEmail } from '../../../actions/asyncActions';

const ClientDetails = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const mgr_id = user.mgr_id;
    const initialFormState = { id: '', name:'', phone:'', email:'', start_date:'', end_date:'', billing_address:'', gstin:'', pan:'',
          contact_person:''}
    const [data, setCurrentData ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();
    const [values, setValues] = useState({});
    const [errors, setError] = useState([]);
    const [msaImage, setMsa] = React.useState([]);
    const [othermsaImage, setOtherMsa] = React.useState([]);

    const [users, setUsers] = useState([])
    const [ clients, setClient ] = useState([])
    const submitted_data = clients.filter(s => s.status === 'submitted' && s.mgr_id === user.id);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getClients(group_id)
      .then(resp => setClient(resp.data))
    }, []);

    useEffect( () => {
      getAllUser(group_id)
      .then(resp => setUsers(resp.data))
    }, []);

    const [StartDate, setStartDate] = React.useState(new Date());
    const handleStartDateChange = date => {
      setStartDate(date);
    };

    var nextDate = new Date(StartDate)
    nextDate.setDate(nextDate.getDate() + 1);

    const [EndDate, setEndDate] = React.useState(new Date(nextDate));
    const handleEndDateChange = date => {
      setEndDate(date);
    };

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleUploadMsa = async (event) => {
      setMsa(document.getElementById('filemsa').files[0]);
    }

    const handleUploadOtherMsa = async (event) => {
      setOtherMsa(document.getElementById('fileother').files[0]);
    }

    const handleInputChange = event => {
      const { name, value } = event.target
      setCurrentData({ ...data, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
    }

    const editRow = n => {
     setModal(!modal)
     setCurrentData({ id: n.id, name: n.name, phone: n.phone, email: n.email, start_date: n.start_date, end_date: n.end_date, 
      billing_address: n.billing_address, contact_person: n.contact_person, gstin: n.gstin, pan:n.pan, credit_period: n.credit_period,
      credit_amount: n.credit_amount })
    }
    
    const validateForm = (data) => {
     const errors = [];
     if(!data.name) {
       errors.name = "Client Name is required";
     }
     return errors;
    }

    const handleSubmit = async (event) => {
      const formData = new FormData();
      formData.append( 'file', msaImage );
      const msa = await msaUpload(formData);
      const msapath = msa.data.filename;

      const formData2 = new FormData();
      formData2.append( 'file', othermsaImage );
      const othermsa = await othermsaUpload(formData2);
      const othermsapath = othermsa.data.filename;
      const found = clients.find(s=> s.gstin === values.gstin);

      const getMgr = users.find(x=> x.id === mgr_id)
      const mgrEmail = getMgr && getMgr.email;

      const emailData = {
        email: mgrEmail,
        type: 'client'
      }

      const data = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        contact_person: values.contact_person,
        billing_address: values.billing_address,
        gstin: values.gstin,
        pan: values.pan,
        start_date: StartDate,
        end_date: EndDate,
        msa: msapath,
        othermsa: othermsapath,
        status: 'submitted',
        credit_period: values.credit_period,
        credit_amount: values.credit_amount,
        mgr_id: user.mgr_id,
        user_id: user.id,
        group_id: group_id,
      }
      const errors = validateForm(data);
      if(Object.keys(errors).length) {
        setError(errors);
        return;
      }
      if(!found){
      postClients(data)
      .then(resp => {
        //Send email to manager for approval
        if(mgrEmail == '') {
        } else {
        sendManagerEmail(emailData)
        }
          getClients(group_id)
          .then(resp => setClient(resp.data))
          setValues({id: '', name:'', phone:'', email:'', start_date:'', end_date:'', billing_address:'', gstin:'', pan:'',
                     contact_person:''});        
        })
        .catch(err => {
          console.log("Error", err);
        })
      } else{
         toast.success("Client Already Exists !", {position: toast.POSITION.TOP_CENTER})
        }
        setAddModal(!addmodal)
    }

    const handleUpdate = async(id, data) => {
      const formData = new FormData();
      formData.append( 'file', msaImage );
      const msa = await msaUpload(formData);
      const msapath = msa.data.filename;

      const formData2 = new FormData();
      formData2.append( 'file', othermsaImage );
      const othermsa = await othermsaUpload(formData2);
      const othermsapath = othermsa.data.filename;
      const found = clients.find(s=> s.gstin === values.gstin);

      const cat = {
        id: id,
        name: data.name,
        phone: data.phone,
        email: data.email,
        contact_person: data.contact_person,
        billing_address: data.billing_address,
        gstin: data.gstin,
        pan: data.pan,
        start_date: StartDate,
        end_date: EndDate,
        msa: msapath,
        othermsa: othermsapath,
        credit_period: data.credit_period,
        credit_amount: data.credit_amount,
        mgr_id: user.mgr_id,
        user_id: user.id,
        group_id: group_id
      }
      const errors = validateForm(data);
      if(Object.keys(errors).length) {
        setError(errors);
        return;
      }
      editClients(cat)
      .then(resp => {
        getClients(group_id)
          .then(resp => setClient(resp.data))
        })
        .catch(err => {
          console.log("Error", err);
        })
        setModal(!modal)
  }

  const approveClient = id => {
    const data = {
      id: id,
      status: 'approved'
    }
    approveClients(data)
    .then(resp => {
      getClients(group_id)
        .then(resp => setClient(resp.data))
      })
      .catch(err => {
        console.log("Error", err);
      })
  }

        return (
          <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">ID</th>
                                                  <th scope="col">Name</th>
                                                  <th scope="col">Start Date</th>
                                                  <th scope="col">End Date</th>
                                                  <th scope="col">Address</th>
                                                  <th scope="col">GSTIN</th>
                                                  <th scope="col">Status</th>
                                                  <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {submitted_data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <th scope="row">{n.id}</th>
                                                     <td>{n.name}</td>
                                                     <td>
                                                      {new Date(n.start_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>
                                                      {new Date(n.end_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{n.billing_address}</td>
                                                     <td>{n.gstin}</td>
                                                     <td>{n.status}</td>
                                                     <td>
                                                      <button className="btn btn-info btn-xs" type="button" onClick={() => editRow(n)}>
                                                       <i className="icofont icofont-edit"></i>
                                                      </button>
                                                      <button className="btn btn-success btn-xs" type="button" onClick={() => approveClient(n.id)}>
                                                       <i className="fa fa-thumbs-up"></i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                       <button className="btn btn-info btn-sm" type="button" onClick={() => addRow()}>
                                         Add Client
                                        </button>
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={submitted_data.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Add Clients --> */}
            <Modal isOpen={addmodal} toggle={addRow} size="lg">
              <ModalHeader toggle={addRow}>Add Client</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth required id="name" label="Name" margin="dense" name="name" 
                               onChange={handleChange} value={values.name} variant="outlined" 
                              />
                              <FormHelperText>{errors.name}</FormHelperText>
                            </div>
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth label="Phone" margin="dense" name="phone" type="number"
                                onChange={handleChange} value={values.phone} variant="outlined" id="phone"
                              />
                            </div>
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth label="Email" margin="dense" name="email" 
                                onChange={handleChange} value={values.email} variant="outlined" id="email"
                              />
                            </div>
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth label="Contact Person" margin="dense" name="contact_person" 
                                onChange={handleChange} value={values.contact_person} variant="outlined" id="contact_person"
                              />
                            </div>
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth label="GSTIN" margin="dense" name="gstin" 
                                onChange={handleChange} value={values.gstin} variant="outlined" id="gstin"
                              />
                            </div>
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth label="PAN" margin="dense" name="pan" 
                                onChange={handleChange} value={values.pan} variant="outlined" id="pan"
                              />
                            </div>
                            <div className="col-xl-12 mb-3">
                              <TextField fullWidth label="Address" margin="dense" name="billing_address" multiline 
                                onChange={handleChange} value={values.billing_address} variant="outlined" id="billing_address"
                              />
                            </div>
                            <div className="col-xl-6 mb-3">
                             <label className="col-form-label" htmlFor="message-text">Agreement Start Date:</label>
                              <DatePicker className="form-control digits"
                                selected={StartDate}
                                value={StartDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Agreement End Date:</label>
                              <DatePicker className="form-control digits"
                                selected={EndDate}
                                value={EndDate}
                                onChange={handleEndDateChange}
                                minDate={new Date(nextDate)}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Upload MSA:</label>
                              <TextField id="filemsa" type="file" margin="dense" variant="outlined" onChange={handleUploadMsa} fullWidth/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Upload Other Document:</label>
                              <TextField id="fileother" type="file" margin="dense" variant="outlined" onChange={handleUploadOtherMsa} fullWidth/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <TextField fullWidth label="Credit Period(Days)" margin="dense" name="credit_period"  
                                onChange={handleChange} value={values.credit_period} variant="outlined" id="credit_period"
                                type="number"/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <TextField fullWidth label="Credit Amount" margin="dense" name="credit_amount" 
                                onChange={handleChange} value={values.credit_amount} variant="outlined" id="credit_amount"
                                type="number"/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={addRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Client --> */}
            <Modal isOpen={modal} toggle={editRow}>
                <ModalHeader toggle={editRow}>Edit Client</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth required id="name" label="Name" margin="dense" name="name" 
                               onChange={handleInputChange} value={data.name} variant="outlined" 
                              />
                              <FormHelperText>{errors.name}</FormHelperText>
                            </div>
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth label="Phone" margin="dense" name="phone" 
                                onChange={handleInputChange} value={data.phone} variant="outlined" id="phone"
                              />
                            </div>
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth label="Email" margin="dense" name="email" 
                                onChange={handleInputChange} value={data.email} variant="outlined" id="email"
                              />
                            </div>
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth label="Contact Person" margin="dense" name="contact_person" 
                                onChange={handleInputChange} value={data.contact_person} variant="outlined" id="contact_person"
                              />
                            </div>
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth label="GSTIN" margin="dense" name="gstin" 
                                onChange={handleInputChange} value={data.gstin} variant="outlined" id="gstin"
                              />
                            </div>
                            <div className="col-xl-4 mb-3">
                              <TextField fullWidth label="PAN" margin="dense" name="pan" 
                                onChange={handleInputChange} value={data.pan} variant="outlined" id="pan"
                              />
                            </div>
                            <div className="col-xl-12 mb-3">
                              <TextField fullWidth label="Address" margin="dense" name="billing_address" multiline 
                                onChange={handleInputChange} value={data.billing_address} variant="outlined" id="billing_address"
                              />
                            </div>
                            <div className="col-xl-6 mb-3">
                             <label className="col-form-label" htmlFor="message-text">MSA Start Date:</label>
                              <DatePicker className="form-control digits"
                                selected={StartDate}
                                value={StartDate}
                                onChange={handleStartDateChange}
                                minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">MSA End Date:</label>
                              <DatePicker className="form-control digits"
                                selected={EndDate}
                                value={EndDate}
                                onChange={handleEndDateChange}
                                minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Upload MSA:</label>
                              <TextField id="filemsa" type="file" margin="dense" variant="outlined" onChange={handleUploadMsa} fullWidth/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Upload Other Document:</label>
                              <TextField id="fileother" type="file" margin="dense" variant="outlined" onChange={handleUploadOtherMsa} fullWidth/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <TextField fullWidth label="Credit Period(Days)" margin="dense" name="credit_period"  
                                onChange={handleInputChange} value={data.credit_period} variant="outlined" id="credit_period"
                              />
                            </div>
                            <div className="col-xl-6 mb-3">
                              <TextField fullWidth label="Credit Amount" margin="dense" name="credit_amount" 
                                onChange={handleInputChange} value={data.credit_amount} variant="outlined" id="credit_amount"
                              />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={editRow}>Cancel</Button>
                    <Button color="primary" onClick={ () => handleUpdate(data.id, data)} >Save Changes</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default ClientDetails;