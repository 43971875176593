import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import {TextField, FormLabel, FormControlLabel, RadioGroup, FormControl, Radio, MenuItem, FormHelperText} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import { getProjects, getClients, getIndent, postIndent, getIndentItems, getAllUser, sendManagerEmail,
  getItemLone, getItemLtwo, getItemLthree, getItemLfour, getItemLfive, getManagers, 
  getAddCart, postAddCart, deleteCart, getBoms, getBomItems, createIndent, indentUpload, drawingUpload, getCostSheet,
  getMasterInventory, postAddCart2 } from '../../actions/asyncActions';

const MyIndent = () => {
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;

 const elementStyle ={
  border:'solid',
  borderRadius:'10px',
  position:'relative',
  left:'1vh',
  height:'6vh',
  width:'23vh',
  marginTop:'1vh',
  marginBottom:'1vh'
 }

 const [projects, setProject] = useState([]);
 const filterProjects = projects.filter(x=>x.status === 'approved')
 const [project_val, setProjectVal] = React.useState(null);
 const selectedProject = project_val && project_val.id;
 const bomApplicable = project_val && project_val.bom_applicable; //Get BOM Applicable value(yes/no)
 console.log("bomApply", bomApplicable)
 const projectName = project_val && project_val.name;

 const [bom_value, setValue] = React.useState();
 const [bom_ref, setBOMValue] = React.useState('');

 const [boms, setBOM] = useState([]);
 const [bom_items, setBOMItems] = useState([]);
 const [costsheet, setCostsheet] = useState([]);
 const [masterInventory, setMasterInventory] = useState([]);
 const [cogs_ref, setCogsRef] =useState(null);
 const selectedCogs = cogs_ref && cogs_ref.id;

 const docNum = boms.filter(x=> x.project_num == projectName);
 console.log("docnum", docNum ? docNum.length : null)
 const filterBomItems = bom_items.filter(x=> x.document_no === bom_ref && x.added !== 1);
 console.log("filterBomItems", filterBomItems);
 const filterCostSheet = costsheet.filter(x=>x.project_id === selectedProject);

 const [datemodal, setDateModal] = useState();
 const [cartmodal, setCartModal] = useState();
 const [tempcartmodal, setTempCartModal] = useState();
 const [tempcartinvmodal, setTempCartInvModal] = useState();
 const [thankyoumodal, setThankYouModal] = useState();

 const ProjectClientId = project_val && project_val.client_id;
  const [ clients, setClient ] = useState([]);

  const selectedClients = clients.find(s => s.id === ProjectClientId);
  const clientName = selectedClients && selectedClients.name;
  const ClientId = selectedClients && selectedClients.id;

 const [lone, setLone] = useState([]);
  const [l1_val, setLoneVal] = useState(null);
  const selectedL1 = l1_val && l1_val.id;

  const [ltwo, setLtwo] = useState([]);
  const [l2_val, setLtwoVal] = useState(null);
  const selectedL2 = l2_val && l2_val.id;

  const [lthree, setLthree] = useState([]);
  const [l3_val, setLthreeVal] = useState(null);
  const selectedL3 = l3_val && l3_val.id;

  const [lfour, setLfour] = useState([]);
  const [l4_val, setLfourVal] = useState(null);
  const selectedL4 = l4_val && l4_val.id;

  const [lfive, setLfive] = useState([]);
  const [l5_val, setLfiveVal] = useState(null);
  const selectedL5 = l5_val && l5_val.id;
  
  const [indent, setIndent] = useState([]);
  const [indentItems, setIndentItems] = useState([]);
  const [ managers, setManagers] = useState([]);

  const [ users, setUsers] = useState([]);
  const [cart, setCart] = useState([]);
  console.log("cartinvid", cart ? (cart && cart[0] || []).inv_id : null)
  console.log("cartbomid", cart ? (cart && cart[0] || []).bom_item_id : null)
  const [errors, setError] = React.useState({});

  const [drawing, setDrawing] = useState([]);

  const handleUploadDrawing = async (event) => {
    setDrawing(document.getElementById('filedrawing').files[0]);
  }

  const [search, setSearch] = React.useState(null);
  const updateSearch = (event) => {
    setSearch(event.target.value.substr(0,20));
  }

  const [qtys, setQtys] = React.useState([{}]);
  
  const handleQtyChange = (event, i) => {
    const tempQtys = [...qtys];
    tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
    setQtys(tempQtys);
  };


 const handleChange = event => {
   setValue(event.target.value);
   if(event.target.value === 'no'){
   	setDateModal(!datemodal)
   }
 }

 const handleDateOpen = () => {
 	setDateModal(!datemodal)
 }

 const handleCartPage = () => {
 	setCartModal(!cartmodal)
 }

 const handleBOMChange = event => {
   setBOMValue(event.target.value);
 };

 const [ExpectedDate, setExpectedDate] = React.useState();
 const ExpDate = new Date(ExpectedDate).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
 
 const handleExpectedDateChange = date => {
   setExpectedDate(date);
 };

 useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
 }, []);

 useEffect( () => {
    getClients(group_id)
    .then(resp => setClient(resp.data))
 }, []);

 useEffect( () => {
    getBoms(group_id)
    .then(resp => setBOM(resp.data))
 }, []);

 useEffect( () => {
    getBomItems(group_id)
    .then(resp => setBOMItems(resp.data))
 }, []);

 useEffect( () => {
    getItemLone(group_id)
    .then(resp => {
      setLone(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLtwo(group_id)
    .then(resp => {
      setLtwo(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLthree(group_id)
    .then(resp => {
      setLthree(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLfour(group_id)
    .then(resp => {
      setLfour(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLfive(group_id)
    .then(resp => {
      setLfive(resp.data);
    })
  }, []);

  useEffect( () => {
    getManagers(group_id)
    .then(resp => {
      setManagers(resp.data);
    })
  }, []);

  useEffect( () => {
    getCostSheet(group_id)
    .then(resp => setCostsheet(resp.data))
  }, []);
  
  useEffect( () => {
    getMasterInventory(group_id)
    .then(resp => setMasterInventory(resp.data))
  }, []);

 const handleSubmit = () => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  //const filtMgrId = (managers || []).find(x=>x.emp_id === user.id);
  //const mgrId = filtMgrId && filtMgrId.mgr_id;
  const mgrId = user.mgr_id;
  const filtMgrEmail = users.find(x=>x.id === mgrId);
  const mgrEmail = filtMgrEmail && filtMgrEmail.email;

  let cartData = cart;
  let indentItems_data = [];
  cartData.forEach(item => {
	  indentItems_data.push({ 
      doc_no: item.doc_no, item_class: item.item_class, particulars: item.particulars, part_num: item.part_num, 
      part_name: item.part_name, function: item.function, item_type: item.item_type, material:item.material, 
      specs: item.specs, qty:item.qty, finish_size:item.finish_size, date:item.date, remarks:item.remarks,
      reason: item.reason, cogs_ref:item.cogs_ref, project_id:item.project_id, mgr_id:item.mgr_id, 
      inv_id: item.inv_id, inv_type: item.inv_type, client_id:item.client_id, status:item.status, 
      user_id:item.user_id, group_id:item.group_id 
	  })
  });
  const indent_data = {
	  bom_ref: cartData[0].bom_ref,
	  expDate: cartData[0].exp_date,
	  cogs_ref: cartData[0].cogs_ref,
	  project_id: cartData[0].project_id,
	  mgr_id: cartData[0].mgr_id,
	  client_id: cartData[0].client_id,
	  status: 'Created',
	  user_id: cartData[0].user_id,
	  group_id: cartData[0].group_id          
   }
   const sendEmail = {
    email: mgrEmail
   }

   createIndent({indent_data, indentItems_data})
    .then(resp => {
      getIndent(group_id)
      .then(resp => setIndent(resp.data))
      getAddCart(group_id)
      .then(resp => setCart(resp.data))
      if(mgrEmail == '') {
      } else {
      sendManagerEmail(sendEmail)
      }
      setValue('');
      setCartModal(!cartmodal);
      setProjectVal('');
      setBOMValue('');
      setTypeVal('');
      setThankYouModal(!thankyoumodal);
      toast.success("The Indent is successfully submitted !!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
    })
    .catch(err => {
      console.log("Error", err);
    })
 }
 
 const filterItems = (p, idx) => {
  if(l1_val !== null || l2_val !== null || l3_val !== null || l4_val !== null || search) {
    //1,2,3,4,5
    if(l1_val !== null && l2_val !== null && l3_val !== null && l4_val !== null && search){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1,2,3,4
    if(l1_val !== null && l2_val !== null && l3_val !== null && l4_val !== null){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4;
    }
    // 1,2,3,5
    if(l1_val !== null && l2_val !== null && l3_val !== null && search){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    // 1,2,4,5
    if(l1_val !== null && l2_val !== null && l4_val !== null && search){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    // 2,3,4,5
    if(l2_val !== null && l3_val !== null && l4_val !== null && search){
      return p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1,2,3
    if(l1_val !== null && l2_val !== null && l3_val !== null){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lthree_id === selectedL3;
    }
    //1,2,4
    if(l1_val !== null && l2_val !== null && l4_val !== null){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lfour_id === selectedL4;
    }
    //1,2,5
    if(l1_val !== null && l2_val !== null && search){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1,3,4
    if(l1_val !== null && l3_val !== null && l4_val !== null){
      return p.lone_id === selectedL1 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4;
    }
    //1,3,5
    if(l1_val !== null && l3_val !== null && search){
      return p.lone_id === selectedL1 && p.lthree_id === selectedL3 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1,4,5
    if(l1_val !== null && l4_val !== null && search){
      return p.lone_id === selectedL1 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //2,3,4
    if(l2_val !== null && l3_val !== null && l4_val !== null){
      return p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4;
    }
    //2,3,5
    if(l2_val !== null && l3_val !== null && search){
      return p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //2,4,5
    if(l2_val !== null && l4_val !== null && search){
      return p.ltwo_id === selectedL2 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //3,4,5
    if(l3_val !== null && l4_val !== null && search){
      return p.lthree_id === selectedL3 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1,2
    if(l1_val !== null && l2_val !== null){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2;
    }
    //1,3
    if(l1_val !== null && l3_val !== null){
      return p.lone_id === selectedL1 && p.lthree_id === selectedL3;
    }
    //1,4
    if(l1_val !== null && l4_val !== null){
      return p.lone_id === selectedL1 && p.lfour_id === selectedL4;
    }
    //1,5
    if(l1_val !== null && search){
      return p.lone_id === selectedL1 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //2,3
    if(l2_val !== null && l3_val !== null){
      return p.ltwo_id === selectedL2 && p.lthree_id === selectedL3;
    }
    //2,4
    if(l2_val !== null && l4_val !== null){
      return p.ltwo_id === selectedL2 && p.lfour_id === selectedL4;
    }
    //2,5
    if(l2_val !== null && search){
      return p.ltwo_id === selectedL2 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //3,4
    if(l3_val !== null && l4_val !== null){
      return p.lthree_id === selectedL3 && p.lfour_id === selectedL4;
    }
    //3,5
    if(l3_val !== null && search){
      return p.lthree_id === selectedL3 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //4,5 
    if(l4_val !== null && search){
      return p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1
    if(l1_val !== null){
      return p.lone_id === selectedL1;
    }
    //2
    if(l2_val !== null){
      return p.ltwo_id === selectedL2;
    }
    //3
    if(l3_val !== null){
      return p.lthree_id === selectedL3;
    }
    //4
    if(l4_val !== null){
      return p.lfour_id === selectedL4;
    }
    if(search) {
       return p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
     }
   // return true;
      // return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4;
  }
  return true;
  }


 const initialFormState = {id:'', part_num:'', l4:'', l5:'', function:'', material:'', finish_size:'', lfour_id:'', lfive_id:'', qty:'' }
 const [data, setCurrentData] = useState(initialFormState)
 
 const handleItemValues = (e) => {
 	setCurrentData({...data, [e.target.name]: e.target.value});
 }

 const [ptno, setPartNo] = useState()
 const [ptname, setPartName] = useState()
 const [ptfunction, setFunction] = useState()
 const [ptspecs, setSpecs] = useState()
 const [ptmaterial, setMaterial] = useState()
 const [ptfinsize, setFinSize] = useState()

 const [quantity, setQuantity] = useState();
 const [reason, setReason] = useState();
 const [cartDate, handleCartDate] = useState(new Date());
 const [cartRemarks, setCartRemarks] = useState();
 const [selectedId, setSelectedId] = useState();
 const [invItemId, setInvItemId] = useState();

 const tempCartRow = (n) => {
  const checkCart = cart.find(x=>x.bom_item_id === n.id);
  if(checkCart){
    toast.error("This item is already added to the cart!!!");
  }
  else {
  setSelectedId(n.id)
  setPartNo(n.part_num)
  setPartName(n.part_name)
  setFunction(n.function)
  setSpecs(n.specification)
  setMaterial(n.material)
  setFinSize(n.finish_size)
  setCurrentData({doc_no: n.document_no, item_class: n.item_class, part_num: n.part_num, part_name: n.part_name, function: n.function,
    item_type: n.item_type, material: n.material, specs: n.specification, qty: n.qty, finish_size:n.finish_size, 
    lfour_id: n.lfour_id, lfive_id: n.name})
  setTempCartModal(!tempcartmodal)   
  }
 }
 
 const [mfr, setMfr] = useState();
 const [category, setCategory] = useState();
 const [itemName, setItemName] = useState();
 const [particulars, setParticulars] = useState();
 const [description, setDescription] = useState();

 const tempCartRowInv = n => {
  const checkCart = cart.find(x=>x.inv_id === n.id);
  if(checkCart){
    toast.error("This item is already added to the cart!!!");
  }
  else {
   setInvItemId(n.id)
   setMfr(n.mfr)
   setCategory(n.category)
   setItemName(n.part_no)
   setParticulars(n.particulars)
   setDescription(n.description)
   setTempCartInvModal(!tempcartinvmodal)
  }
 }
 
 const validateForm = (data) => {
    const errors = [];
    if(!data.cogs_ref){
      errors.cogs_ref = "Please Select COGS to proceed";
     }
     return errors;
  }

 const handleTempCart = async(n) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  //const filtMgrId = (managers || []).find(x=>x.emp_id === user.id);
  //const mgrId = filtMgrId && filtMgrId.mgr_id;
  const mgrId = user.mgr_id;
  const lfourName = lfour.find(x=> x.id === data.lfour_id);
  const l4Name = lfourName && lfourName.name; 
  const lfourNm = lfour.find(x=> x.name == data.part_name);
  const l4Nm = lfourNm && lfourNm.id;

  const formData = new FormData();
  formData.append( 'file', drawing );
  const draw = await drawingUpload(formData);
  const drawpath = draw.data.filename;
  
  if(bom_value === 'no' || bomApplicable === 'no'){
    const aaa = {
    inv_type: materialType,
    l4: l4Name,
    l5: data.lfive_id,
    inv_id: invItemId,
    part_name: itemName,
    particulars: particulars,
    material: mfr,
    function: category,
    specs: description,
    qty: quantity,
    exp_date: ExpectedDate,
    drawing: drawpath,
    remarks: cartRemarks,
    cogs_ref: selectedCogs,
    mgr_id: mgrId,
    project_id: selectedProject,
    client_id: ClientId,
    status: 'Created',
    user_id: user.id,
    group_id: user.group_id
  }
  console.log("aaa", aaa);
    postAddCart2(aaa) 
    .then(resp => {
      getAddCart(group_id)
        .then(resp => setCart(resp.data))
      .then(resp => setQtys([{qtys:''}]))
      toast.success("Item is added to the cart !!!")
      setSearch('');
      setLoneVal(null);
      setQuantity('');
      setExpectedDate(new Date());
      setCartRemarks('');
      setCogsRef('');
      setTempCartInvModal(!tempcartinvmodal)
    })
  }
  else {
  // const aaa = {
  //   bom_ref: bom_ref,
  //   part_num: data.part_num,
  //   l4: data.l4,
  //   l5: data.l5,
  //   function: data.function,
  //   material: data.material,
  //   finish_size:data.finish_size,
  //   qty: quantity,
  //   date: cartDate,
  //   remarks: cartRemarks,
  //   cogs_ref: selectedCogs,
  //   project_id: selectedProject,
  //   mgr_id: mgrId,
  //   client_id: ClientId,
  //   status: 'Created',
  //   user_id: user.id,
  //   group_id: user.group_id
  // }
  const aaa = {
    bom_item_id: selectedId,
    bom_ref: bom_ref,
    doc_no: data.doc_no,
    item_class: data.item_class,
    part_num: ptno,
    part_name: ptname,
    function: ptfunction,
    item_type: data.item_type,
    material: ptmaterial,
    specs: ptspecs,
    qty: quantity,
    finish_size: ptfinsize,
    reason: reason,
    date: cartDate,
    remarks: cartRemarks,
    cogs_ref: selectedCogs,
    drawing: drawpath,
    project_id: selectedProject,
    mgr_id: mgrId,
    client_id: ClientId,
    status: 'Created',
    user_id: user.id,
    group_id: user.group_id
  }
  const bbb = {
    id: selectedId
  }
  const errors = validateForm(aaa);
    if(Object.keys(errors).length) {
     setError(errors);
    return;
  }
  console.log("aaa", aaa);
    postAddCart({aaa, bbb}) 
    .then(resp => {
      getAddCart(group_id)
        .then(resp => setCart(resp.data))
      .then(resp => setQtys([{qtys:''}]))
      toast.success("Item is added to the cart !!!", {position: toast.POSITION.BOTTOM_RIGHT, autoClose:2000})
      setSearch('');
      setLoneVal(null);
      setQuantity('');
      setReason('');
      handleCartDate(new Date());
      setCartRemarks('');
      setCogsRef('');
      setTempCartModal(!tempcartmodal)
    })
  }
}

// const [selectedDate, setSelectedDate] = useState([{date: new Date()}]);
  
// const handleDateChange = (date, i) => {
// 	const tempQtys = [...selectedDate];
// 	tempQtys[i] = {...tempQtys[i], date : date};
// 	setSelectedDate(tempQtys);
// };

// const [remarks, setRemarks] = React.useState([{}]);
  
// const handleRemarksChange = (event, i) => {
//   let tempQtys = null;
//   tempQtys = [...remarks];
//   tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
//   setRemarks(tempQtys);
// };

const handleDeleteSubmit= (n) => {
    const deleteId = {
      id : n.id,
      bom_item_id: n.bom_item_id
    }
    deleteCart(deleteId)
      .then(resp => {
        getAddCart(user.group_id)
        .then(resp => setCart(resp.data))
        toast.error("Item deleted from the cart !!!")
      })
      .catch(err => {
        console.log("Error", err);
    })
}
const [viewDrawing, setViewDrawing] = useState();
const drawingpath = `http://172.105.41.149:9003/${viewDrawing}`;
// const drawingpath = `http://localhost:9003/${viewDrawing}`;
const [drawingmodal, setDrawingModal] = useState(false);

const handleViewDrawing = (n) => {
  setViewDrawing(n.drawing);
  setDrawingModal(!drawingmodal);
}

const [searchDoc, setSearchDoc] = useState(null);
const updateSearchDoc = (event) => {
  setSearchDoc(event.target.value.substr(0,20));
}

const filterDocItems = (p) => {
  if(searchDoc) {
    return p.document_no.toLowerCase().includes(searchDoc.toLowerCase()) ||
    (p.part_num || '').toLowerCase().includes(searchDoc.toLowerCase()) ||
    (p.part_name || '').toLowerCase().includes(searchDoc.toLowerCase())
  }
  return true;
}

const [searchItem, setSearchItem] = useState(null);
const updateSearchItem = (event) => {
  setSearchItem(event.target.value.substr(0,20));
}

const filterInvItems = (p) => {
  if(searchItem) {
    return p.mfr.toLowerCase().includes(searchItem.toLowerCase()) ||
    (p.category || '').toLowerCase().includes(searchItem.toLowerCase()) ||
    (p.part_no || '').toLowerCase().includes(searchItem.toLowerCase()) ||
    (p.description || '').toLowerCase().includes(searchItem.toLowerCase())
  }
  return true;
}

const [searchItemService, setSearchItemService] = useState(null);
const updateSearchItemService = (event) => {
  setSearchItemService(event.target.value.substr(0,20));
}

const filterInvItemService = (p) => {
  if(searchItemService) {
    return p.particulars.toLowerCase().includes(searchItemService.toLowerCase()) ||
           p.description.toLowerCase().includes(searchItemService.toLowerCase())
  }
  return true;
}

const inventoryType = [{ name: 'Raw Material', value: 0 }, {name: 'Services', value: 2}];
const [typeval, setTypeVal] = useState(null);
const materialType = typeval ? typeval && typeval.value : null;
const materialTypeName = typeval ? typeval && typeval.name : null;
const filterInventory = masterInventory.filter(x=>x.raw_material === materialType)

	return (
        <Fragment>
         <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-xl-12">
                <div className="card card-absolute">
                    <div className="card-header bg-secondary">
                        <h5>Prepare Indent</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                       <div className="col-md-3">
                       	<Autocomplete
      			              freeSolo
      			              options={filterProjects}
      			              getOptionLabel={option => option.name}
      			              value={project_val}                      
      			              onChange={(event, newValue) => {
      			                setProjectVal(newValue);
      			              }}            
      			              renderInput={params => (
      			                <TextField {...params} label="Project" margin="normal" variant="outlined" helperText="Please select Project" fullWidth />
      			              )}
      			            />
                       </div>
                    {selectedProject && bomApplicable === 'no' ?
                      <div className="col-xl-3">
                        <Autocomplete
                          freeSolo
                          options={inventoryType}
                          getOptionLabel={option => option.name}
                          value={typeval}                      
                          onChange={(event, newValue) => {
                            setTypeVal(newValue);
                          }}            
                          renderInput={params => (
                            <TextField {...params} label="Item" margin="normal" variant="outlined" helperText="Please select Item Type" fullWidth />
                          )}
                        />
                      </div>
                      :
                      selectedProject && bomApplicable === 'yes' ?
                      <div className="col-md-2 m-t-15">
                        <FormControl component="fieldset">
                          <FormLabel component="legend">BOM Available</FormLabel>
                          <RadioGroup row aria-label="bom" name="bom" value={bom_value} onChange={handleChange} fullWidth>
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      :
                      null
                    }
                    {selectedProject && bom_value === 'yes' ?
                       <div className="col-md-3">
                       	<TextField id="bom_ref" select helperText="BOM Reference"  value={bom_ref}
		                      onChange={handleBOMChange} SelectProps={{ name: 'bom_ref' }} margin="normal" fullWidth variant="outlined" >
		                      {
		                        docNum.map((n, index) => {
		                          return <MenuItem key={index} value={n.document_no}>{n.document_no}</MenuItem>
		                        })
		                      }
		                    </TextField>
                       </div>
                      : 
                      selectedProject && bom_value === 'no' && ExpectedDate != '' ?
                      <div className="col-md-3">
                       <TextField id="ExpectedDate" value={ExpDate} margin="normal" helperText="ExpectedDate" 
                        variant="outlined" fullWidth />
                      </div>
                      :
                      null
                    }
                    {filterInventory.length > 0 || bom_ref ?
                      <div className="col-xl-3 m-t-15">
                        <Button color="info" onClick={handleCartPage} className="btn btn-lg m-t-15">
                         Cart
                         <span className="badge badge-light m-l-15">{cart.length}</span>
                        </Button>
                      </div>
                      :
                      null
                    }
                    { selectedProject && materialType && filterInventory.length === 0 ?
                        <div className="col-md-12">
                          <h3 className="text-danger text-center"> No items available for this type </h3>
                        </div>
                        : filterInventory.length > 0 && materialTypeName === 'Raw Material' ?
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="col-md-3">
                                <input type="text" placeholder="Enter item to be searched" style={elementStyle} value = {searchItem}
                                  onChange={updateSearchItem} />
                                </div>
                              <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                      <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                              <th scope="col">#</th>
                                              <th scope="col">Mfr</th>
                                              <th scope="col">Category</th>
                                              <th scope="col">PartName</th>
                                              <th scope="col">Description</th>
                                              <th scope="col">Unit</th>
                                              <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {filterInventory.filter(filterInvItems).map((n, i)=> {
                                            return (
                                            <tr style={{fontSize: '13px'}}>
                                              <th scope="row">{i+1}</th>
                                              <td>{n.mfr}</td>
                                              <td>{n.category}</td>
                                              <td>{n.part_no}</td>
                                              <td>{n.description}</td>
                                              <td>{n.unit}</td>
                                              <td>
                                              <button className="btn btn-info btn-sm" type="button" onClick={() => tempCartRowInv(n)}>
                                                Add
                                              </button>
                                              </td>
                                            </tr>
                                            );
                                          })
                                          } 
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                       </div>
                      </div>
                      : filterInventory.length > 0 && materialTypeName === 'Services' ?
                      <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="col-md-3">
                            <input type="text" placeholder="Enter item to be searched" style={elementStyle} value = {searchItemService}
                              onChange={updateSearchItemService} />
                            </div>
                          <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                  <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                          <th scope="col">#</th>
                                          <th scope="col">Particulars</th>
                                          <th scope="col">Description</th>
                                          <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                      {filterInventory.filter(filterInvItemService).map((n, i)=> {
                                        return (
                                        <tr style={{fontSize: '13px'}}>
                                          <th scope="row">{i+1}</th>
                                          <td>{n.particulars}</td>
                                          <td>{n.description}</td>
                                          <td>
                                          <button className="btn btn-info btn-sm" type="button" onClick={() => tempCartRowInv(n)}>
                                            Add
                                          </button>
                                          </td>
                                        </tr>
                                        );
                                      })
                                      } 
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
                   </div>
                  </div>
                  : 
                  null
                  }
                { (selectedProject && bom_value === "yes" && bom_ref && filterBomItems.length > 0) ? 
                	<div className="container-fluid">
                	<div className="row">
                	<div className="col-sm-12">
                        <div className="card">
                            <div className="col-md-2">
                             <input type="text" placeholder="Enter item to be searched" style={elementStyle} value = {searchDoc}
                               onChange={updateSearchDoc} />
                            </div>
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                  <th scope="col">#</th>
      				                                    <th scope="col">Doc No</th>
      				                                    <th scope="col">ItemClass</th>
      				                                    <th scope="col">PartNo</th>
      				                                    <th scope="col">Part Name</th>
      				                                    <th scope="col">Function</th>
      				                                    <th scope="col">Item Type</th>
      				                                    <th scope="col">Material</th>
      				                                    <th scope="col">Specs</th>
      				                                    <th scope="col">Qty</th>
      				                                    <th scope="col">Finish Size</th>
      				                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                             {filterBomItems.filter(filterDocItems).map((n, i)=> {
				                                       return (
        		                                    <tr style={{fontSize: '13px'}}>
        		                                     <th scope="row">{n.sl_no}</th>
        		                                     <td>{n.document_no}</td>
        		                                     <td>{n.item_class}</td>
        		                                     <td>{n.part_num}</td>
        		                                     <td>{n.part_name}</td>
        		                                     <td>{n.function}</td>
        		                                     <td>{n.item_type}</td>
        		                                     <td>{n.material}</td>
        		                                     <td>{n.specification}</td>
        		                                     <td>{n.qty}</td>
        		                                     <td>{n.finish_size}</td>
        		                                 	   <td>
        		                                      <button className="btn btn-info btn-sm" type="button" onClick={() => tempCartRow(n)}>
        		                                       Add
        		                                      </button>
        		                                     </td>
        		                                    </tr>
        		                                   );
        		                                  })
        		                                 } 
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    : (selectedProject && bom_value === "yes" && bom_ref && filterBomItems.length === 0) ?
                    <div className="col-xl-12">
                      <h3 className="text-danger text-center"> 
                       All items are already added for the preparation of the Indent 
                      </h3>
                    </div>
                    :
                    null
                   } 

                    {/* { bomApplicable === 'yes' ?
                    <div className="col-md-2 m-t-15">
                      <FormControl component="fieldset">
                        <FormLabel component="legend">BOM Available</FormLabel>
                        <RadioGroup row aria-label="bom" name="bom" value={bom_value} onChange={handleChange} fullWidth>
                          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    : bomApplicable === 'no' ?
                    <div className="col-xl-3">
                      <Autocomplete
                        freeSolo
                        options={inventoryType}
                        getOptionLabel={option => option.name}
                        value={typeval}                      
                        onChange={(event, newValue) => {
                          setTypeVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Item" margin="normal" variant="outlined" helperText="Please select Item Type" fullWidth />
                        )}
                      />
                    </div>
                    :
                    null
                    }
                    { selectedProject && materialType && filterInventory.length === 0 ?
                      <div className="col-md-12">
                        <h3 className="text-danger"> No items available for this type </h3>
                      </div>
                      : filterInventory.length > 0 ?
                      <div className="container-fluid">
                	      <div className="row">
                	        <div className="col-sm-12">
                            <div className="card">
                              <div className="col-md-3">
                              <input type="text" placeholder="Enter item to be searched" style={elementStyle} value = {searchItem}
                                onChange={updateSearchItem} />
                              </div>
                            <div className="card-block row">
                              <div className="col-sm-12 col-lg-12 col-xl-12">
                                  <div className="table-responsive">
                                    <table className="table table-bordered table-hover">
                                      <thead>
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Mfr</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">PartName</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Unit</th>
                                            <th scope="col">Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                        {filterInventory.filter(filterInvItems).map((n, i)=> {
                                          return (
                                          <tr style={{fontSize: '13px'}}>
                                            <th scope="row">{i+1}</th>
                                            <td>{n.mfr}</td>
                                            <td>{n.category}</td>
                                            <td>{n.part_no}</td>
                                            <td>{n.description}</td>
                                            <td>{n.unit}</td>
                                            <td>
                                            <button className="btn btn-info btn-sm" type="button" onClick={() => tempCartRow(n)}>
                                              Add
                                            </button>
                                            </td>
                                          </tr>
                                          );
                                        })
                                        } 
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    : 
                    null
                    }
                    { selectedProject && (!materialType) && docNum.length === 0 ?
                      <div className="col-md-12">
                        <h3 className="text-danger"> There is no document available for this project </h3>
                      </div>
                      :
                      bom_value === 'yes' ?
                       <div className="col-md-3">
                       	<TextField id="bom_ref" select helperText="BOM Reference"  value={bom_ref}
		                      onChange={handleBOMChange} SelectProps={{ name: 'bom_ref' }} margin="normal" fullWidth variant="outlined" >
		                      {
		                        docNum.map((n, index) => {
		                          return <MenuItem key={index} value={n.document_no}>{n.document_no}</MenuItem>
		                        })
		                      }
		                    </TextField>
                       </div> 
                    : bom_value === 'no' && ExpectedDate != '' ?
                      <div className="col-md-3">
                       	<TextField id="ExpectedDate" value={ExpDate} margin="normal" helperText="ExpectedDate" 
                		      variant="outlined" fullWidth />
                      </div> 
                    : 
                      null
                    }
                    {bom_ref || ExpectedDate?
                       <div className="col-md-3 m-t-15">
                       	<Button color="info" onClick={handleCartPage} className="btn btn-lg m-t-15">
                       	 Cart
                       	 <span className="badge badge-light m-l-15">{cart.length}</span>
                       	</Button>
                       </div> : null
                    }
                    
                	{ (selectedProject && bom_value === "yes" && bom_ref) ? 
                	<div className="container-fluid">
                	<div className="row">
                	<div className="col-sm-12">
                        <div className="card">
                            <div className="col-md-2">
                             <input type="text" placeholder="Enter item to be searched" style={elementStyle} value = {searchDoc}
                               onChange={updateSearchDoc} />
                            </div>
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                  <th scope="col">#</th>
      				                                    <th scope="col">Doc No</th>
      				                                    <th scope="col">ItemClass</th>
      				                                    <th scope="col">PartNo</th>
      				                                    <th scope="col">Part Name</th>
      				                                    <th scope="col">Function</th>
      				                                    <th scope="col">Item Type</th>
      				                                    <th scope="col">Material</th>
      				                                    <th scope="col">Specs</th>
      				                                    <th scope="col">Qty</th>
      				                                    <th scope="col">Finish Size</th>
      				                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                             {filterBomItems.filter(filterDocItems).map((n, i)=> {
				                                       return (
        		                                    <tr style={{fontSize: '13px'}}>
        		                                     <th scope="row">{n.sl_no}</th>
        		                                     <td>{n.document_no}</td>
        		                                     <td>{n.item_class}</td>
        		                                     <td>{n.part_num}</td>
        		                                     <td>{n.part_name}</td>
        		                                     <td>{n.function}</td>
        		                                     <td>{n.item_type}</td>
        		                                     <td>{n.material}</td>
        		                                     <td>{n.specification}</td>
        		                                     <td>{n.qty}</td>
        		                                     <td>{n.finish_size}</td>
        		                                 	   <td>
        		                                      <button className="btn btn-info btn-sm" type="button" onClick={() => tempCartRow(n)}>
        		                                       Add
        		                                      </button>
        		                                     </td>
        		                                    </tr>
        		                                   );
        		                                  })
        		                                 } 
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    : null
                   }

                   { selectedProject && bom_value === 'no' && ExpectedDate?
                   	 <div className="col-sm-12">
                       <div className="card">
                         <div className="card-block row">
		                       <div className="col-md-2">
		                        <Autocomplete
					                freeSolo
					                options={lone}
					                getOptionLabel={option => option.name}
					                value={l1_val}                      
					                onChange={(event, newValue) => {
					                  setLoneVal(newValue);
					                }}
					                renderInput={params => (
					                  <TextField {...params} label="L1" margin="normal" variant="outlined" fullWidth  />
					                )}
					            />
		                       </div>
		                       <div className="col-md-2">
		                        <Autocomplete
					                freeSolo
					                options={ltwo}
					                getOptionLabel={option => option.name}
					                value={l2_val}                      
					                onChange={(event, newValue) => {
					                  setLtwoVal(newValue);
					                }}
					                renderInput={params => (
					                  <TextField {...params} label="L2" margin="normal" variant="outlined" fullWidth  />
					                )}
					            />
		                       </div>
		                       <div className="col-md-2">
		                        <Autocomplete
					                freeSolo
					                options={lthree}
					                getOptionLabel={option => option.name}
					                value={l3_val}                      
					                onChange={(event, newValue) => {
					                  setLthreeVal(newValue);
					                }}
					                renderInput={params => (
					                  <TextField {...params} label="L3" margin="normal" variant="outlined" fullWidth  />
					                )}
					            />
		                       </div>
		                       <div className="col-md-2">
		                        <Autocomplete
					                freeSolo
					                options={lfour}
					                getOptionLabel={option => option.name}
					                value={l4_val}                      
					                onChange={(event, newValue) => {
					                  setLfourVal(newValue);
					                }}
					                renderInput={params => (
					                  <TextField {...params} label="L4" margin="normal" variant="outlined" fullWidth  />
					                )}
					            />
		                       </div>
		                       <div className="col-md-2">
		                        <TextField label="Search Items..." name="l5" type="text" variant="outlined" onChange={ updateSearch }
					                value = {search} margin="normal" fullWidth/>
		                       </div>
                            </div>
                         </div>
                      </div>
                     : null
                    }

                    {selectedProject && bom_value === 'no' && ExpectedDate?
                     <div className="col-sm-12">
                       <div className="card">
                         <div className="card-block row">
                          {
			                lfive.filter(filterItems).map((n, i) => {
			                const loneName = lone.find(x=> x.id === n.lone_id);
			                const l1Name = loneName && loneName.name;
			                const ltwoName = ltwo.find(x=> x.id === n.ltwo_id);
			                const l2Name = ltwoName && ltwoName.name;
			                const lthreeName = lthree.find(x=> x.id === n.lthree_id);
			                const l3Name = lthreeName && lthreeName.name;
			                const lfourName = lfour.find(x=> x.id === n.lfour_id);
			                const l4Name = lfourName && lfourName.name;

			                return (
			                   <div className="row">
				                <div className="col-md-2">
				                 <TextField value={ l1Name } label="L1" margin="dense" variant="outlined" fullWidth/>
				                </div>
				                <div className="col-md-2">
				                 <TextField value={ l2Name } label="L2" margin="dense" variant="outlined" fullWidth/>
				                </div>
				                <div className="col-md-2">
				                 <TextField value={ l3Name } label="L3" margin="dense" variant="outlined" fullWidth/>
				                </div>
				                <div className="col-md-2">
				                 <TextField value={ l4Name } label="L4" margin="dense" variant="outlined" fullWidth/>
				                </div>
				                <div className="col-md-2">
				                 <TextField value={ n.name } label="L5" margin="dense" variant="outlined" fullWidth/>
				                </div>
				                <div className="col-md-2 m-t-15">
				                 <button className="btn btn-info btn-sm" type="button" onClick={() => handleTempCart(n)}>
                           Add
                         </button>
				                </div>
				               </div>
			                ); })
			              }
                         </div>
                       </div>
                     </div>
                     : null
                    }
                  */}
                  </div>
                </div>
               </div>
            </div>
          </div>	
         </div> 

         {/* < !-- Using Form Modal for Expected Date--> */}
            <Modal isOpen={datemodal} toggle={handleDateOpen} className="modal-body" centered={true}>
                <ModalHeader className="text-center" toggle={handleDateOpen}>
                  Indent can be made without BOM However provide a Expected date of BOM
                </ModalHeader>   
                <ModalBody className="text-center">
                    <form>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                              <DatePicker className="form-control digits"
                                selected={ExpectedDate}
                                value={ExpectedDate}
                                onChange={handleExpectedDateChange}
                                minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/YYYY"
                              />
                            </div>
                            <div className="col-md-12 mb-3">
                              <Autocomplete
                                freeSolo
                                options={inventoryType}
                                getOptionLabel={option => option.name}
                                value={typeval}                      
                                onChange={(event, newValue) => {
                                  setTypeVal(newValue);
                                }}            
                                renderInput={params => (
                                  <TextField {...params} label="Item" margin="normal" variant="outlined" helperText="Please select Item Type" fullWidth />
                                )}
                              />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={handleDateOpen}>OK</Button>
                </ModalFooter>
            </Modal>

         {/* < !-- Using Modal for Viewing Cart Items --> */}
            <Modal isOpen={cartmodal} toggle={handleCartPage} className="modal-dialog modal-xl">
                <ModalHeader toggle={handleCartPage}>Prepare Indent</ModalHeader>   
                <ModalBody>
                   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                             {(cart[0] || []).inv_id ?
                                              (cart[0] || []).inv_type === 0 ?
                                              <tr>
                                                <th scope="col">Mfr</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">Part Name</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Expected Date</th>
                                                <th scope="col">COGS</th>
                                                <th scope="col">Remarks</th>
                                                <th scope="col">Action</th>
                                              </tr>
                                              :
                                              <tr>
                                                <th scope="col">Particulars</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Expected Date</th>
                                                <th scope="col">COGS</th>
                                                <th scope="col">Remarks</th>
                                                <th scope="col">Action</th>
                                              </tr>
                                            :
                                              <tr>
                                                <th scope="col">Part No</th>
                                                <th scope="col">Part Name</th>
                                                <th scope="col">Material</th>
                                                <th scope="col">Finish Size</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Expected Date</th>
                                                <th scope="col">COGS</th>
                                                <th scope="col">Remarks</th>
                                                <th scope="col">Action</th>
                                              </tr>
                                            } 
                                              
                                            </thead>
                                            <tbody>
                                                {cart.map((n, i) => {
                                                 const cogsRef = costsheet.find(x=>x.id === n.cogs_ref);
                                                  return (
                                                   (cart[0] || []).inv_id ?
                                                    (cart[0] || []).inv_type === 0 ?
                                                    <tr>
                                                      <td>{n.material}</td>
                                                      <td>{n.function}</td>
                                                      <td>{n.part_name}</td>
                                                      <td>{n.specs}</td>
                                                      <td>{n.qty}</td>
                                                      <td>
                                                        {new Date(n.exp_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                      </td>
                                                      <td>{cogsRef && cogsRef.description}</td>
                                                      <td>{n.remarks}</td>
                                                      <td>
                                                        <button className="btn btn-info btn-sm" type="button" onClick={() => handleViewDrawing(n)}>
                                                        <i className="fa fa-eye"></i>
                                                        </button>&nbsp;
                                                        <button className="btn btn-danger btn-sm" type="button" onClick={() => handleDeleteSubmit(n)}>
                                                        <i className="fa fa-trash-o"></i>
                                                        </button>
                                                      </td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                     <td>{n.particulars}</td>
                                                     <td>{n.specs}</td>
                                                     <td>{n.qty}</td>
                                                     <td>
                                                      {new Date(n.exp_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{cogsRef && cogsRef.description}</td>
                                                     <td>{n.remarks}</td>
                                                     <td>
                                                      <button className="btn btn-info btn-sm" type="button" onClick={() => handleViewDrawing(n)}>
                                                       <i className="fa fa-eye"></i>
                                                      </button>&nbsp;
                                                      <button className="btn btn-danger btn-sm" type="button" onClick={() => handleDeleteSubmit(n)}>
                                                       <i className="fa fa-trash-o"></i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                     <td>{n.part_num}</td>
                                                     <td>{n.part_name}</td>
                                                     <td>{n.material}</td>
                                                     <td>{n.finish_size}</td>
                                                     <td>{n.qty}</td>
                                                     <td>
                                                      {new Date(n.date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{cogsRef && cogsRef.description}</td>
                                                     <td>{n.remarks}</td>
                                                     <td>
                                                      <button className="btn btn-info btn-sm" type="button" onClick={() => handleViewDrawing(n)}>
                                                       <i className="fa fa-eye"></i>
                                                      </button>&nbsp;
                                                      <button className="btn btn-danger btn-sm" type="button" onClick={() => handleDeleteSubmit(n)}>
                                                       <i className="fa fa-trash-o"></i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={handleCartPage}>Close</Button>
                  <Button color="info" onClick={handleSubmit}>Prepare Indent</Button>
                </ModalFooter>
            </Modal>

        {/* < !-- Using Modal for Adding COGS reference into Cart Items --> */}
            <Modal isOpen={tempcartmodal} toggle={tempCartRow} size="lg">
                <ModalHeader toggle={tempCartRow}>Modify Item details</ModalHeader>
                <ModalBody>
                    <form>
                     <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-6">
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Part No:</label>
                            <input className="form-control" type="text" onChange={e => setPartNo(e.target.value)} value={ptno} />
                       </div>
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Part Name:</label>
                            <input className="form-control" type="text" onChange={e => setPartName(e.target.value)} value={ptname} />
                       </div>
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Function:</label>
                            <input className="form-control" type="text" onChange={e => setFunction(e.target.value)} value={ptfunction} />
                       </div>
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Material:</label>
                            <input className="form-control" type="text" onChange={e => setMaterial(e.target.value)} value={ptmaterial} />
                       </div>
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Specification:</label>
                            <input className="form-control" type="text" onChange={e => setSpecs(e.target.value)} value={ptspecs} />
                       </div>
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Finish Size:</label>
                            <input className="form-control" type="text" onChange={e => setFinSize(e.target.value)} value={ptfinsize} />
                       </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Quantity:</label>
                            <input className="form-control" name="quantity" type="number" onChange={e => setQuantity(e.target.value)} value={quantity} />
                        </div>
                       {parseInt(quantity) > data.qty ?
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Reason for adding more Qty:</label>
                            <input className="form-control" type="text" onChange={e => setReason(e.target.value)} value={reason} />
                        </div> : null
                       }
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="message-text">Expected Date:</label>
                            <DatePicker className="form-control digits"
	                            selected={cartDate}
	                            value={cartDate}
	                            onChange={handleCartDate}
	                            minDate={new Date()}
	                            dateFormat="dd/MM/yyyy"
	                          />
                            
                        </div>
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Remarks:</label>
                            <input className="form-control" type="text" onChange={e => setCartRemarks(e.target.value)} value={cartRemarks} />
                        </div>
                        <div className="form-group">
                          <Autocomplete
    				                freeSolo
    				                options={filterCostSheet}
    				                getOptionLabel={option => option.description}
    				                value={cogs_ref}                      
    				                onChange={(event, newValue) => {
    				                  setCogsRef(newValue);
    				                }}
    				                renderInput={params => (
    				                  <TextField {...params} label="COGS Reference" margin="normal" variant="outlined" fullWidth  />
    				                )}
      				            />
				                  <FormHelperText className="text-danger">{errors.cogs_ref}</FormHelperText>
                        </div>
                        <div className="form-group">
                          <label className="col-form-label" htmlFor="recipient-name">Attach File:</label>
                          <input className="form-control" id="filedrawing" type="file" onChange={handleUploadDrawing} />
                        </div>
                       </div>
                      </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={tempCartRow}>Cancel</Button>
                  <Button color="primary" onClick={handleTempCart}>Add</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Modal for Adding COGS reference into Cart Items for Raw material/services --> */}
            <Modal isOpen={tempcartinvmodal} toggle={tempCartRowInv} size="lg">
                <ModalHeader toggle={tempCartRowInv}>Modify Item details</ModalHeader>
                <ModalBody>
                    <form>
                     <div class="row">
                     {materialTypeName === 'Raw Material' ?
                      <div class="col-sm-6 col-md-6 col-lg-6">
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Manufacturer/Material:</label>
                            <input className="form-control" type="text" onChange={e => setMfr(e.target.value)} value={mfr} />
                       </div>
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Category/Function:</label>
                            <input className="form-control" type="text" onChange={e => setCategory(e.target.value)} value={category} />
                       </div>
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Part Name:</label>
                            <input className="form-control" type="text" onChange={e => setItemName(e.target.value)} value={itemName} />
                       </div>
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Specification/Description:</label>
                            <input className="form-control" type="text" onChange={e => setDescription(e.target.value)} value={description} />
                       </div>
                      </div>
                    :
                      <div class="col-sm-6 col-md-6 col-lg-6">
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Particulars:</label>
                            <input className="form-control" type="text" onChange={e => setParticulars(e.target.value)} value={particulars} />
                       </div>
                       <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Specification/Description:</label>
                            <input className="form-control" type="text" onChange={e => setDescription(e.target.value)} value={description} />
                       </div>
                      </div>
                    } 
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Quantity:</label>
                            <input className="form-control" name="quantity" type="number" onChange={e => setQuantity(e.target.value)} value={quantity} />
                        </div>
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="message-text">Expected Date:</label>
                            <DatePicker className="form-control digits"
	                            selected={ExpectedDate}
                              value={ExpectedDate}
                              onChange={handleExpectedDateChange}
	                            minDate={new Date()}
	                            dateFormat="dd/MM/yyyy"
	                          />    
                        </div>
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="recipient-name">Remarks:</label>
                            <input className="form-control" type="text" onChange={e => setCartRemarks(e.target.value)} value={cartRemarks} />
                        </div>
                        <div className="form-group">
                          <Autocomplete
    				                freeSolo
    				                options={filterCostSheet}
    				                getOptionLabel={option => option.description}
    				                value={cogs_ref}                      
    				                onChange={(event, newValue) => {
    				                  setCogsRef(newValue);
    				                }}
    				                renderInput={params => (
    				                  <TextField {...params} label="COGS Reference" margin="normal" variant="outlined" fullWidth  />
    				                )}
      				            />
				                  <FormHelperText className="text-danger">{errors.cogs_ref}</FormHelperText>
                        </div>
                        <div className="form-group">
                          <label className="col-form-label" htmlFor="recipient-name">Attach File:</label>
                          <input className="form-control" id="filedrawing" type="file" onChange={handleUploadDrawing} />
                        </div>
                       </div>
                      </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={tempCartRowInv}>Cancel</Button>
                  <Button color="primary" onClick={handleTempCart}>Add</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={drawingmodal} toggle={handleViewDrawing} size="lg">
             <ModalHeader toggle={handleViewDrawing}>View Drawing</ModalHeader>
              <ModalBody>
                <img src={`${drawingpath}`} width="720" height="640" crossOrigin="anonymous"/>
              </ModalBody>
            </Modal>

        </Fragment>
    );
}
export default MyIndent;