import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Button, Collapse } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { getProjects, getCostSheet, getIndentItems, getPOItems, getIndInvoiceItems, getGrinItems } from '../../../actions/asyncActions';

const ViewProject = (props) => {
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const location = props.location;
 const projectId = location.state ? location.state.projectId : 1;

 const [projects, setProject] = useState([]);
 const findPOVal = projects.find(x=>x.id === projectId);
 const poValue = findPOVal && findPOVal.po_value;

 const [costSheet, setCostSheet] = useState([]);
 const filterCostSheet = costSheet.filter(x=>x.project_id === projectId);
 const calculateTotalCost = filterCostSheet.reduce((a,b) => a + parseFloat(b.cost), 0);

 const [activeIndex, setActiveIndex] = useState(null);
 const [activeIndex2, setActiveIndex2] = useState(null);
 const [activeIndex3, setActiveIndex3] = useState(null);

 const [costSheetId, setCostSheetId] = useState();
 const [indentItemId, setIndentItemId] = useState();
 const [poItemsId, setPOItemsId] = useState();

 const [indentItems, setIndentItems] = useState([]);
 const filterIndentItems = indentItems.filter(x=>x.cogs_ref === costSheetId);
 const totalIndentCost = indentItems.reduce((a,b) => a + parseFloat(b.total), 0);

 const [poItems, setPOItems] = useState([]);
 const filterPOItems = poItems.filter(x=>x.indentItem_id === indentItemId);

 const [invoiceItems, setInvoiceItems] = useState([]);
 const filterInvoiceItems = invoiceItems.filter(x=>x.po_itemno === poItemsId);

 const [grnItems, setGrnItems] = useState([]);

 useEffect( () => {
  getProjects(group_id)
  .then(resp => setProject(resp.data))
 }, []);

 useEffect( () => {
  getCostSheet(group_id)
  .then(resp => setCostSheet(resp.data))
 }, []);

 useEffect( () => {
  getIndentItems(group_id)
  .then(resp => setIndentItems(resp.data))
 }, []); 

 useEffect( () => {
  getPOItems(group_id)
  .then(resp => setPOItems(resp.data))
 }, []);
 
 useEffect( () => {
  getIndInvoiceItems(group_id)
  .then(resp => setInvoiceItems(resp.data))
 }, []);

 useEffect( () => {
  getGrinItems(group_id)
  .then(resp => setGrnItems(resp.data))
 }, []);

	return(
	 <Fragment>
	  <Breadcrumb title="Project Cost" parent="Project Management" />
	  <div className="container-fluid">
	   <div className="row">
		<div className="col-xl-12">
		    <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
		    <div className="table-responsive">
		     <table className="table table-bordered">
		      <thead className="table-primary">
		        <tr>
	             <th scope="col"><h3>Module </h3></th>
	             <th scope="col"><h3>Cost </h3></th>
	             <th scope="col"><h3>Balance </h3></th>
	            </tr>
	          </thead>
	          <tbody>
	           <th> <h4>Total Costsheet Value</h4></th>
	           <th> <h4>₹ {calculateTotalCost}</h4></th>
	           <th> <h4>₹ {calculateTotalCost - totalIndentCost}</h4></th>
	          </tbody>
	          <tbody>
	           <th> <h4>PO Value</h4></th>
	           <th> <h4>₹ {poValue}</h4></th>
	          </tbody>
	          <thead className="table-primary">
		        <tr>
	             <th scope="col"><h4>Module </h4></th>
	             <th scope="col"><h4>Cost </h4></th>
	             <th scope="col"><h4>Balance </h4></th>
	            </tr>
	          </thead>
	          <tbody>
			    { filterCostSheet.map((n,index) => {
			    	const indItems = indentItems.filter(x=>x.cogs_ref === n.id);
			    	const totalCost = indItems.reduce((a,b) => a + parseFloat(b.total), 0);
			    	const balance = parseFloat(n.cost) - parseFloat(totalCost);

			     	return(
			          <tr>
			           <td>
			            <h3 className="mb-0">
	                     <button className="btn btn-link pl-0" data-toggle="collapse"
	                       onClick={event => {setActiveIndex(
	                           activeIndex === index ? null : index
	                        );
	                        setCostSheetId(n.id)
	                       }}
	                      data-target="#collapseicon" 
	                      aria-expanded="false"
	                      aria-controls="collapseicon"
	                     >
	                      {n.description}
	                     </button>
		                </h3>
		    {/* Start: This Collapse is for showing Indent Items */}
		             { filterIndentItems.length > 0 ?
			           <Collapse isOpen={activeIndex === index} >
			            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion" >
			             <div className="table-responsive">
			              <table className="table table-bordered">
			                <thead className="table-success">
			                 <tr> 
			                  <td>Indent No</td>
			                  <td>Indent Item Name</td>
			                  <td> Cost</td>
			                 </tr>
			                </thead>
		      				<tbody>
		      				 { filterIndentItems.map((s, index2) => {

		      				 	return(
		      				 	 <tr>
		      				 	  <td>{('IN-000').concat(s.id)}</td>
		      				 	  <td>
		      				 	    <h3 className="mb-0">
				                     <button className="btn btn-link pl-0" data-toggle="collapse"
				                       onClick={event => {setActiveIndex2(
				                           activeIndex2 === index2 ? null : index2
				                        );
				                        setIndentItemId(s.id)
				                       }}
				                      data-target="#collapseicon2" 
				                      aria-expanded="false"
				                      aria-controls="collapseicon2"
				                     >
					                  {(s.part_name).concat(`(${s.material})`)}
					                 </button>
					                </h3>
					{/* Start: This Collapse is for showing PO Items */}
								   { filterPOItems.length > 0 ?
					                <Collapse isOpen={activeIndex2 === index2} >
					                 <div className="collapse show" id="collapseicon2" aria-labelledby="collapseicon2" 
					                  data-parent="#accordion">
					                   <div className="table-responsive">
					                   <table className="table table-bordered">
					                    <thead className="table-danger">
						                 <tr>
						                  <td>PO No</td> 
						                  <td>PO Item Name</td>
						                  <td>PO Qty</td>
						                  <td>GRN Qty</td>
						                  <td>Remainder</td>
						                  <td> Cost</td>
						                 </tr>
						                </thead>
					                    <tbody>
					                     { filterPOItems.map((t, index3) => {
					                     	const filterGRNItems = grnItems.filter(x=>x.po_itemno === t.id && x.status === 'Approved');
					                     	const getGRNQty = filterGRNItems.reduce((a,b) => a + parseInt(b.apr_qty), 0);
					                     	const remainingQty = t.qty - getGRNQty;
					                     	return(
					                     	 <tr>
					                     	  <td>{('PO-000').concat(t.po_no)}</td>
					                     	  <td>
					                     	    <h3 className="mb-0">
							                     <button className="btn btn-link pl-0" data-toggle="collapse"
							                       onClick={event => {setActiveIndex3(
							                           activeIndex3 === index3 ? null : index3
							                        );
							                        setPOItemsId(t.id)
							                       }}
							                      data-target="#collapseicon3" 
							                      aria-expanded="false"
							                      aria-controls="collapseicon3"
							                     >
								                  {(t.part_name).concat(`(${t.material})`)}
								                 </button>
								                </h3>
					{/* Start: This Collapse is for showing Invoice Items 
										  { filterInvoiceItems.length > 0 ?
											<Collapse isOpen={activeIndex3 === index3} >
							                 <div className="collapse show" id="collapseicon3" aria-labelledby="collapseicon3" 
							                  data-parent="#accordion">
							                  <div className="table-responsive">
							                   <table className="table table-bordered">
							                    <thead className="table-danger">
								                 <tr> 
								                  <td>Invoice Item Name</td>
								                  <td> Cost</td>
								                 </tr>
								                </thead>
								                <tbody>
								                 { filterInvoiceItems.map((z, index4) => {
								                 	return(
								                 	 <tr>
								                 	  <td>{z.description}</td>
								                 	  <td>₹ {z.amount}</td>
								                 	 </tr>
								                 	);
								                  })

								                 }
								                </tbody>
								               </table>
					                     	  </div>
					                     	 </div>
					                     	</Collapse>
					                     	:
							                <Collapse isOpen={activeIndex3 === index3}>
								             <h3 className="text-danger"> There are no items </h3>
								            </Collapse>
								          }
				 End: for the Invoice Items  */}
					                     	  </td>
					                     	  <td>{t.qty}</td>
					                     	  <td>{getGRNQty}</td>
					                     	  <td>{remainingQty}</td>
					                     	  <td>₹ {t.total} </td>
					                     	 </tr>
					                     	);
					                       })

					                     }
					                    </tbody>
					                   </table>
					                   </div>
					                 </div>
					                </Collapse>
					                :
					                <Collapse isOpen={activeIndex2 === index2}>
						             <h3 className="text-danger"> There are no items </h3>
						            </Collapse>
						           }
				{/* End: for the PO Items  */}	                
		      				 	  </td>
		      				 	  <td><h6>₹ {s.total}</h6></td>
		      				 	 </tr>
		      				 	);
		      				   })
		      				 }
		      				</tbody>
		      			  </table>
			             </div>
			            </div>
			           </Collapse>
			           :
			           <Collapse isOpen={activeIndex === index}>
			            <h3 className="text-danger"> There are no items </h3>
			           </Collapse>
			        }
			           </td>
			           <td>
			            <h6>₹ {n.cost} </h6>
			           </td>
			           <td>
			            <h6>₹ {balance} </h6>
			           </td>
			          </tr>
			     	);
			      }) 
			    }
			  </tbody>
			 </table>
			</div>
		    </div>
	    </div>
	   </div>
	  </div>
	 </Fragment>
	);
}

export default withRouter(ViewProject);