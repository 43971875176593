import React, { Fragment, useState, useEffect }  from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { TablePagination, TextField, Typography } from '@material-ui/core';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import {getPayrollAdd, payrollDeductUpload, deletePayrollAdd, editPayrollAdd, postPayroll, 
        getEmployeeTaxPaid, editEmployeeTaxPaid, getEmployeeTax} from '../../../actions/asyncActions';
import { toast } from 'react-toastify';

const PayrollIncome = (props) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const {responseData, taxPaid} = props;
    // const location = props.location;
    // const responseData = location.state ? location.state.responseData : null;
    // const additionData = location.state ? location.state.payrollAdd : null;
    console.log("responseData", responseData)
    // console.log("additionData", additionData)

    const [payrollAdd, setPayrollAdd] = useState([])
    const filterPayrollDeduct = payrollAdd ? payrollAdd.filter(x=>x.category === 'Deductions') : null;
    const [bulk_upload, setUpload] = useState();
    const [uploadfile, setFile] = useState([]);

    const [taxesPaid, setTaxPaid] = useState([]);
    const [empTax, setEmpTax] = useState([]);
    const zzz = empTax.filter(x => x.status === 'Approved'); //Find Approved Tax Declarations
    const approvedTaxIds = [...new Set(zzz.map(x => x.emp_id))]; // Get their IDs

    const filterTaxPaids = taxPaid.filter(x => approvedTaxIds.includes(x.emp_id))

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
        getPayrollAdd(group_id)
        .then(resp => setPayrollAdd(resp.data))
    }, []);

    useEffect( () => {
        getEmployeeTaxPaid(group_id)
        .then(resp => setTaxPaid(resp.data))
    }, [taxPaid]);

    useEffect( () => {
        getEmployeeTax(group_id)
        .then(resp => setEmpTax(resp.data))
    }, []);

    const bulkUpload = () => {
        setUpload(!bulk_upload)
    }

    const onFileDrop = (files) => {
        setFile(document.getElementById('file').files[0]);
    }

    const [deductionData, setDeductionData] = useState([])

    const handleUploadSubmit = async (event) => {
        const formData = new FormData()
        formData.append( 'file', uploadfile )
        formData.append('user_id', user.id)
        formData.append('group_id', group_id)
        formData.append('category', 'Deductions')
        payrollDeductUpload(formData)
        .then(resp => {
            setDeductionData(resp.data)
            getPayrollAdd(group_id)
            .then(resp => setPayrollAdd(resp.data))
            setUpload(!bulk_upload)
            toast.success("File uploaded successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
        })
        .catch(err => {
            console.log("Error", err);
        })
    }

    const backScreen = () => {
        //props.history.push(`${process.env.PUBLIC_URL}/payrolladdition`, {responseData, additionData})
    }

    const [modal, setModal] = useState(false)
    const initialFormState = { id: '', description: '', amount:''}
    const [currentData, setCurrentData ] = useState(initialFormState)

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentData({ ...currentData, [name]: value })
    }
    
    const editRow = n => {
        setModal(!modal)
        setCurrentData({ id: n.id, description: n.description, amount: n.amount })
    }

    const deleteRow = n => {
        const data = {
            id: n.id
        }
        //Delete API
        deletePayrollAdd(data)
        .then(resp => {
            getPayrollAdd(group_id)
            .then(resp => setPayrollAdd(resp.data))
            toast.error("Item deleted successfully!!!")
        })
    }

    const handleUpdate = (id, data) => {

        const cat = {
          id: id,
          description: currentData.description,
          amount: currentData.amount,
          user_id: user.id
        }
        editPayrollAdd(cat)
        .then(resp => {
            getPayrollAdd(group_id)
            .then(resp => setPayrollAdd(resp.data))
            toast.info("Additions updated successfully!!!")
            setModal(!modal)
        })
        .catch(err => {
        console.log("Error", err);
        })
    }

    const pushPayroll = () => {
        let compData = [];
        let taxData = [];
        taxPaid.forEach((item, i) => {
            
            const payrollData = responseData.sort((a,b) => b.id - a.id)
            //const payrollEmployee = payrollData ? payrollData && payrollData[0].emp_name : null
            const payrollMonth = payrollData ? payrollData && payrollData[0].month : null
            const payrollYear = payrollData ? payrollData && payrollData[0].year : null
            //const payrollLop = payrollData ? payrollData && payrollData[0].lop : null
            //const payrollTotalDays = payrollData ? payrollData && payrollData[0].tot_days : null

            const payLop  = payrollData ? payrollData.filter(x=>x.emp_id === item.emp_id) : null
            const payrollLop = payLop ? payLop && payLop[0].lop : null
            const payrollTotalDays = payLop ? payLop && payLop[0].tot_days : null
            const payrollEmployee = payLop ? payLop && payLop[0].emp_name : null
            
            compData.push({ 
                emp_id: item.emp_id,
                emp_name: payrollEmployee, 
                category: 'Taxes', 
                component: 'Tax Deductions', 
                monthly: parseFloat(item.tax_per_month),
                month: payrollMonth,
                year: payrollYear,
                lop: payrollLop,
                tot_days: payrollTotalDays,
                net_salary: parseFloat(item.tax_per_month)
            })
        });
        const data = {
            items: compData,
            user_id: user.id,
            group_id: group_id
        }
        //Get Tax Paid Details and 
        //  UPDATE 
        //  YTD= YTD + tax_per_month, 
        //  bal_amount = total_tax - new YTD, 
        //  bal_month = bal_month - 1,

        let taxDetails = taxPaid;
        taxDetails.forEach((item, i) => {
            taxData.push({
                id: item.id,
                emp_id: item.emp_id,
                ytd: parseFloat(item.ytd + item.tax_per_month),
                bal_amount: parseFloat(item.total_tax) - parseFloat(item.ytd + item.tax_per_month), 
                bal_month: item.bal_month - 1,
            })
        });
        console.log("taxData", taxData)

        const taxDataa = {
            items: taxData,
            user_id: user.id,
            group_id: group_id
        }
         
        postPayroll(data)
        .then(resp => {
            editEmployeeTaxPaid(taxDataa)
            .then(resp => {
                toast.success("Data added successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
                props.history.push(`${process.env.PUBLIC_URL}/payrollfinal`, )
            })
        })
    }

    return(
        <Fragment>
          {/* <Breadcrumb title="Income Tax" parent="Payroll" /> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12 mb-3 m-t-10">
                                    <Typography variant="h5" gutterBottom align="center">
                                        Taxes for the month
                                    </Typography>
                                    {filterTaxPaids.length > 0 ?
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Emp ID</th>
                                                    <th scope="col">Total Tax</th>
                                                    <th scope="col">YTD</th>
                                                    <th scope="col">Balance</th>
                                                    <th scope="col">Balance Months</th>
                                                    <th scope="col">Tax Per Month</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {filterTaxPaids.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr key={i}>
                                                     <td>{n.emp_id}</td>
                                                     <td>{n.total_tax}</td>
                                                     <td>{n.ytd}</td>
                                                     <td>{n.bal_amount}</td>
                                                     <td>{n.bal_month}</td>
                                                     <td>{n.tax_per_month}</td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div>
                                        <h4 className="ml-1 text-danger text-center">There are no Approved Tax Declarations</h4>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                  {/* {filterTaxPaids.length > 0 ?  
                                    <button className="btn btn-pill btn-success btn-sm m-t-10 ml-2" type="button" onClick={() => pushPayroll()}>
                                        Confirm
                                    </button>
                                   :
                                   null
                                  } */}
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                {filterTaxPaids.length > 0 ? 
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterTaxPaids.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                   :
                                   null
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={bulk_upload} toggle={bulkUpload} centered={true}>
                <ModalHeader toggle={bulkUpload}>Upload</ModalHeader>
                <ModalBody>
                  <div className="input-cropper col-md-9 mb-3">
                      <input id="file" type="file" onChange={onFileDrop} />
                  </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={bulkUpload}>Cancel</Button>
                    <Button color="primary" onClick={() => handleUploadSubmit()}>Save</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Payroll Deductions --> */}
            <Modal isOpen={modal} toggle={editRow} centered={true}>
                <ModalHeader toggle={editRow}>Edit Payroll Additions</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-12 mb-3">
                              <TextField fullWidth required id="description" label="Description" margin="dense" name="description" 
                               onChange={handleInputChange} value={currentData.description} variant="outlined" 
                              />
                            </div>
                            <div className="col-xl-12 mb-3">
                              <TextField fullWidth required id="amount" label="Amount" margin="dense" name="amount" 
                               onChange={handleInputChange} value={currentData.amount} variant="outlined" type="number"
                              />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={editRow}>Cancel</Button>
                    <Button color="primary" onClick={ () => handleUpdate(currentData.id, currentData)} >Save Changes</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default PayrollIncome;