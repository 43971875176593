import React, { Fragment, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import {TextField, Backdrop , CircularProgress, TablePagination} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import DatePicker from "react-datepicker";
import { uploadBankStmt, runBankScript, getTempBank, postTempBank } from '../../actions/asyncActions';


const BankStatement = () => {
 
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;

 const [page, setPage] = React.useState(0);
 const [rowsPerPage, setRowsPerPage] = React.useState(5);

 const handleChangePage = (event, newPage) => {
   setPage(newPage);
 };

 const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
 };

 const [bankFile, setBankFile] = React.useState();
 
 const names = [{ name: 'South Indian Bank', value: 'SIB' }, { name: 'HDFC Bank', value: 'HDFC' }, {name: 'Kotak Bank', value: 'KOTAK'}, {name: 'CITI Bank', value: 'CITI'},
               {name: 'ICICI Bank', value: 'ICICI'}];
 const [bankval, setBankVal] = useState(null);
 const bankName = (bankval || []).value;

 const [tempBanks, setTempBank] = useState([]);
 let filterTempBanks = (tempBanks || []).filter(x=> x.status === 'Processing').sort((a,b) => {return b.id-a.id});
 let processedTempBanks = (tempBanks || []).filter(x=> x.status === 'File Processed').sort((a,b) => {return b.id-a.id});
 
 useEffect(() => { 
  const interval = setInterval(() => { 
    getTempBank(group_id) 
    .then(resp => setTempBank(resp.data))  
    }, 10000); 
  return () => clearInterval(interval);
 }, []);

 const handleUploadFile = () => {
   setBankFile(document.getElementById('filebank').files[0]);
 }

 const handleSubmit = async (event) => {
    const usersss = JSON.parse(localStorage.getItem("m_users"));
    const group_idd = usersss.group_id;
    const formData = new FormData()
    formData.append( 'file', bankFile )
    const bankStmt = await uploadBankStmt(formData);
    const aaa = bankStmt.data.filename;
    const filepath = "/Data/Bank_Statements/" + aaa;

    const tempBank = {
      filename: aaa,
      status: 'Processing',
      user_id: user.id,
      group_id: group_id
    }

    const data = {
      name: bankName,
      filename: filepath,
      user_id: usersss.id,
      group_id: group_idd,
    }
    postTempBank({tempBank, data})
    .then(resp => {
      getTempBank(group_id) 
       .then(resp => setTempBank(resp.data))
      setBankFile('')
      setBankVal(null)
      toast.success("Bank statement is being processed !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 5000})
    })     
 }

	return (
        <Fragment>
         <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-6">
               <div className="ribbon-wrapper card">
                <div className="card-body">
                  <div className="ribbon ribbon-bookmark ribbon-primary">Upload Bank Statement</div>
                    <div className="col-md-12">
                      <Autocomplete
                        freeSolo
                        options={names}
                        getOptionLabel={option => option.name}
                        value={bankval}                      
                        onChange={(event, newValue) => {
                          setBankVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Bank" margin="normal" variant="outlined" helperText="Please select Bank" fullWidth />
                        )}
                      />
		                </div>
		                <div className="col-md-12">
		                  <label className="col-form-label text-body" htmlFor="message-text">Attach File:</label>
                      <TextField id="filebank" type="file" margin="dense" variant="outlined" onChange={handleUploadFile} fullWidth/>
                    </div>
                </div>
                {bankFile ?
                <div className="card-footer text-center">
                  <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Submit</button>
                </div>
                : null
                }
              </div>
             </div>
             <div className="col-sm-12 col-xl-6">
              <div className="card">
                <div className="card-block row">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-hover">
                       <thead className="table-info">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Filename</th>
                          <th scope="col">Status</th>
                        </tr>
                       </thead>
                       <tbody>
                        {(filterTempBanks || []).map((n,i) => {
                          return(
                           <tr>
                            <td>{i+1}</td>
                            <td>{n.filename}</td>
                            <td style={{color: "blue", fontSize:16}}>
                             <b>{n.status}</b>
                            </td>
                           </tr>
                          )
                         })
                        }
                       </tbody>
                      </table>
                    </div>
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                     <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={filterTempBanks.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                     />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-block row">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-hover">
                       <thead className="table-info">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Filename</th>
                          <th scope="col">Status</th>
                        </tr>
                       </thead>
                       <tbody>
                        {(processedTempBanks || []).map((n,i) => {
                          return(
                           <tr>
                            <td>{i+1}</td>
                            <td>{n.filename}</td>
                            <td style={{color: "green", fontSize:16}}>
                             <b>{n.status}</b>
                            </td>
                           </tr>
                          )
                         })
                        }
                       </tbody>
                      </table>
                    </div>
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                     <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={processedTempBanks.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                     />
                    </div>
                  </div>
                </div>
              </div>
             </div>
            </div>
          </div>
        </Fragment>
    );
}
export default BankStatement;
