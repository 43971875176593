import React, { Fragment } from 'react';
import { TextField, Typography, Grid, FormHelperText,Radio, RadioGroup, 
         FormControl, FormControlLabel } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BasicDetails = (props) => {

    const { values, errors, dob, radioVal } = props
    
    return(
     <Fragment>
       <Grid container spacing={3} >
        <Grid item xl={6} sm={6}>
            <Typography gutterBottom>
                Name of the Employee:
            </Typography>
            <TextField required id="name" name="name" value={values.name} onChange={props.handleChange} fullWidth autoFocus />
            <FormHelperText className="text-danger">{errors.name}</FormHelperText>
        </Grid>
        <Grid item xl={6} sm={6}>
            <Typography gutterBottom>
                Name of the Father:
            </Typography>
            <TextField required id="father" name="father" value={values.father} onChange={props.handleChange} fullWidth />
            <FormHelperText className="text-danger">{errors.father}</FormHelperText>
        </Grid>
        <Grid item xl={6} sm={6}>
            <Typography gutterBottom>
                Mobile Number:
            </Typography>
            <TextField type="number" id="phone" name="phone" value={values.phone} onChange={props.handleChange} fullWidth  />
            <FormHelperText className="text-danger">{errors.phone}</FormHelperText>
        </Grid>
        <Grid item xl={6} sm={6}>
            <Typography gutterBottom>
                Email ID:
            </Typography>
            <TextField id="email" name="email" value={values.email} onChange={props.handleChange} fullWidth  />
            <FormHelperText className="text-danger">{errors.email}</FormHelperText>
        </Grid>
        <Grid item xl={6} sm={6}>
        <FormControl component="fieldset">
            <Typography gutterBottom>
             Gender:
            </Typography>
            <RadioGroup row aria-label="gender" name="gender" value={radioVal.gender} onChange={props.handleRadioChange} fullWidth>
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="others" control={<Radio />} label="Transgender" />
            </RadioGroup>
        </FormControl>
        </Grid>
        <Grid item xl={6} sm={6}>
            <Typography gutterBottom>
                Date of Birth:
            </Typography>
            <DatePicker className="form-control digits text-success"
             selected={dob}
             value={dob}
             onChange={date => props.setDob(date)}
             dateFormat= "dd-MMM-yyyy"
             placeholderText="Select your DOB"
             showMonthDropdown
             showYearDropdown
             dropdownMode="select"
            />
            <FormHelperText className="text-danger">{errors.dob}</FormHelperText>
        </Grid>
        <Grid item xl={6} sm={6}>
         <Typography gutterBottom>
            Emergency Contact Name:
         </Typography>
         <TextField id="emer_name" name="emer_name"  value={values.emer_name} 
            onChange={props.handleChange} fullWidth />
        </Grid>
        <Grid item xl={6} sm={6}>
         <Typography gutterBottom>
            Emergency Contact Number:
         </Typography>
         <TextField type="number" id="emer_num" name="emer_num"  value={values.emer_num} 
            onChange={props.handleChange} fullWidth />
        </Grid>
        <Grid item xl={12} sm={12}>
         <Typography gutterBottom>
            Address:
         </Typography>
         <TextField multiline id="address" name="address"  value={values.address} 
            onChange={props.handleChange} fullWidth />
        </Grid>
      </Grid>
     </Fragment>
    );
}

export default BasicDetails;