import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import Breadcrumb from '../../components/common/breadcrumb';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import {TextField, FormLabel, FormControlLabel, RadioGroup, FormControl, Radio, MenuItem, FormHelperText} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import { getProjects, getClients, getIndent, postIndent, getIndentItems, getAllUser, sendManagerEmail,
  getItemLone, getItemLtwo, getItemLthree, getItemLfour, getItemLfive, getManagers, 
  getAddCart, postAddCart, deleteCart, getBoms, getBomItems, createIndent, indentUpload, getCostSheet } from '../../actions/asyncActions';

const NonIndent = () => {
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const [projects, setProject] = useState([]);
 const [project_val, setProjectVal] = React.useState(null);
 const selectedProject = project_val && project_val.id;
 const projectName = project_val && project_val.name;

 const [bom_value, setValue] = React.useState();
 const [bom_ref, setBOMValue] = React.useState();

 const [boms, setBOM] = useState([]);
 const [bom_items, setBOMItems] = useState([]);
 const [costsheet, setCostsheet] = useState([]);
 const [cogs_ref, setCogsRef] =useState(null);
 const selectedCogs = cogs_ref && cogs_ref.id;

 const docNum = boms.filter(x=> x.project_num == projectName);
 const filterBomItems = bom_items.filter(x=> x.document_no === bom_ref);
 const filterCostSheet = costsheet.filter(x=>x.project_id === selectedProject);

 const [datemodal, setDateModal] = useState();
 const [cartmodal, setCartModal] = useState();
 const [tempcartmodal, setTempCartModal] = useState();
 const [thankyoumodal, setThankYouModal] = useState();

 const ProjectClientId = project_val && project_val.client_id;
  const [ clients, setClient ] = useState([]);

  const selectedClients = clients.find(s => s.id === ProjectClientId);
  const clientName = selectedClients && selectedClients.name;
  const ClientId = selectedClients && selectedClients.id;

 const [lone, setLone] = useState([]);
  const [l1_val, setLoneVal] = useState(null);
  const selectedL1 = l1_val && l1_val.id;

  const [ltwo, setLtwo] = useState([]);
  const [l2_val, setLtwoVal] = useState(null);
  const selectedL2 = l2_val && l2_val.id;

  const [lthree, setLthree] = useState([]);
  const [l3_val, setLthreeVal] = useState(null);
  const selectedL3 = l3_val && l3_val.id;

  const [lfour, setLfour] = useState([]);
  const [l4_val, setLfourVal] = useState(null);
  const selectedL4 = l4_val && l4_val.id;

  const [lfive, setLfive] = useState([]);
  const [l5_val, setLfiveVal] = useState(null);
  const selectedL5 = l5_val && l5_val.id;
  
  const [indent, setIndent] = useState([]);
  const [indentItems, setIndentItems] = useState([]);
  const [ managers, setManagers] = useState([]);

  const [ users, setUsers] = useState([]);
  const [cart, setCart] = useState([]);
  const [errors, setError] = React.useState({});

  const [search, setSearch] = React.useState(null);
  const updateSearch = (event) => {
    setSearch(event.target.value.substr(0,20));
  }

  const [qtys, setQtys] = React.useState([{}]);
  
  const handleQtyChange = (event, i) => {
    const tempQtys = [...qtys];
    tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
    setQtys(tempQtys);
  };


 const handleChange = event => {
   setValue(event.target.value);
   if(event.target.value === 'no'){
   	setDateModal(!datemodal)
   }
 }

 const handleDateOpen = () => {
 	setDateModal(!datemodal)
 }

 const handleCartPage = () => {
 	setCartModal(!cartmodal)
 }

 const handleBOMChange = event => {
   setBOMValue(event.target.value);
 };

 const [ExpectedDate, setExpectedDate] = React.useState();
 const ExpDate = new Date(ExpectedDate).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
 
 const handleExpectedDateChange = date => {
   setExpectedDate(date);
 };

 useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
 }, []);

 useEffect( () => {
    getClients(group_id)
    .then(resp => setClient(resp.data))
 }, []);

 useEffect( () => {
    getBoms(group_id)
    .then(resp => setBOM(resp.data))
 }, []);

 useEffect( () => {
    getBomItems(group_id)
    .then(resp => setBOMItems(resp.data))
 }, []);

 useEffect( () => {
    getItemLone(group_id)
    .then(resp => {
      setLone(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLtwo(group_id)
    .then(resp => {
      setLtwo(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLthree(group_id)
    .then(resp => {
      setLthree(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLfour(group_id)
    .then(resp => {
      setLfour(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLfive(group_id)
    .then(resp => {
      setLfive(resp.data);
    })
  }, []);

  useEffect( () => {
    getManagers(group_id)
    .then(resp => {
      setManagers(resp.data);
    })
  }, []);

  useEffect( () => {
    getCostSheet(group_id)
    .then(resp => setCostsheet(resp.data))
  }, []); 

 const handleSubmit2 = () => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const filtMgrId = (managers || []).find(x=>x.emp_id === user.id);
  const mgrId = filtMgrId && filtMgrId.mgr_id;
  const filtMgrEmail = users.find(x=>x.id === mgrId);
  const mgrEmail = filtMgrEmail && filtMgrEmail.email;

  let cartData = cart;
  let indentItems_data = [];
  cartData.forEach(item => {
	  indentItems_data.push({ 
	    part_num: item.part_num, l4: item.l4, l5:item.l5, material:item.material, finish_size:item.finish_size, qty:item.qty, 
	    date:item.date, remarks:item.remarks, cogs_ref:item.cogs_ref, project_id:item.project_id, mgr_id:item.mgr_id,
	    client_id:item.client_id, status:item.status, user_id:item.user_id, group_id:item.group_id 
	  })
  });
  const indent_data = {
	  bom_ref: cartData[0].bom_ref,
	  expDate: cartData[0].exp_date,
	  cogs_ref: cartData[0].cogs_ref,
	  project_id: cartData[0].project_id,
	  mgr_id: cartData[0].mgr_id,
	  client_id: cartData[0].client_id,
	  status: 'Created',
	  user_id: cartData[0].user_id,
	  group_id: cartData[0].group_id          
   }
   const sendEmail = {
      email: mgrEmail
    }
    createIndent({indent_data, indentItems_data})
    .then(resp => {
        getIndent(group_id)
        .then(resp => setIndent(resp.data))
        getAddCart(group_id)
        .then(resp => setCart(resp.data))
        if(mgrEmail == '') {
        } else {
        sendManagerEmail(sendEmail)
        }
        setValue('');
        setCartModal(!cartmodal);
        setProjectVal('');
        setValue('');
        setThankYouModal(!thankyoumodal);
        toast.success("The Indent is successfully submitted !!!", {position: toast.POSITION.TOP_CENTER})
      })
      .catch(err => {
        console.log("Error", err);
      })
  
 }
 
 const handleSubmit = (event, i) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const filtMgrId = (managers || []).find(x=>x.emp_id === user.id);
  const mgrId = filtMgrId && filtMgrId.mgr_id;
  const filtMgrEmail = users.find(x=>x.id === mgrId);
  const mgrEmail = filtMgrEmail && filtMgrEmail.email;
  let orders=[];
  let sqtys = [];
  let indentItems_data = [];
    qtys.forEach( async (obj, idx) => {
        if(obj.qtys > 0 ){
          const cartItems = cart[idx];
          orders.push(cartItems);
          const { bom_ref, part_num, material, finish_size, exp_date, l4, l5, project_id, client_id, mgr_id, user_id, group_id } = cart[idx];
          sqtys = parseInt(obj.qtys || 0);
          const sdate = selectedDate[idx].date  || new Date();
          const sremarks = remarks[idx].remarks;
          // const fileIndent = uploadfile[idx].uploadfile;
          // console.log("fileIndent", fileIndent);
          // const formData = new FormData();
          // formData.append( 'file', fileIndent );
          // const indfile = await indentUpload(formData);
          // console.log("indfile", indfile);
          // const fileindentpath = indfile.data.filename;
           indentItems_data.push({
            part_num: part_num,
            l4: l4,
            l5: l5,
            material: material,
            finish_size: finish_size,
            qty: sqtys,
            date: sdate,
            remarks: sremarks,
            project_id: project_id,
            mgr_id: mgr_id,
            client_id: client_id,
            status: 'Created',
            user_id: user_id,
            group_id: group_id          
          });
        }
      })
    const indent_data = {
      bom_ref: orders[0].bom_ref,
      expDate: orders[0].exp_date,
      project_id: orders[0].project_id,
      mgr_id: orders[0].mgr_id,
      client_id: orders[0].client_id,
      status: 'Created',
      user_id: orders[0].user_id,
      group_id: orders[0].group_id          
    }
    const sendEmail = {
      email: mgrEmail
    }
    createIndent({indent_data, indentItems_data})
    .then(resp => {
        getIndent(group_id)
        .then(resp => setIndent(resp.data))
        getAddCart(group_id)
        .then(resp => setCart(resp.data))
        if(mgrEmail == '') {
        } else {
        sendManagerEmail(sendEmail)
        }
        setValue('');
        setCartModal(!cartmodal);
        setProjectVal('');
        setValue('');
        setThankYouModal(!thankyoumodal);
        toast.success("The Indent is successfully submitted !!!", {position: toast.POSITION.TOP_CENTER})
      })
      .catch(err => {
        console.log("Error", err);
      })
  }

 const filterItems = (p, idx) => {
  if(l1_val !== null || l2_val !== null || l3_val !== null || l4_val !== null || search) {
    //1,2,3,4,5
    if(l1_val !== null && l2_val !== null && l3_val !== null && l4_val !== null && search){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1,2,3,4
    if(l1_val !== null && l2_val !== null && l3_val !== null && l4_val !== null){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4;
    }
    // 1,2,3,5
    if(l1_val !== null && l2_val !== null && l3_val !== null && search){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    // 1,2,4,5
    if(l1_val !== null && l2_val !== null && l4_val !== null && search){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    // 2,3,4,5
    if(l2_val !== null && l3_val !== null && l4_val !== null && search){
      return p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1,2,3
    if(l1_val !== null && l2_val !== null && l3_val !== null){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lthree_id === selectedL3;
    }
    //1,2,4
    if(l1_val !== null && l2_val !== null && l4_val !== null){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lfour_id === selectedL4;
    }
    //1,2,5
    if(l1_val !== null && l2_val !== null && search){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1,3,4
    if(l1_val !== null && l3_val !== null && l4_val !== null){
      return p.lone_id === selectedL1 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4;
    }
    //1,3,5
    if(l1_val !== null && l3_val !== null && search){
      return p.lone_id === selectedL1 && p.lthree_id === selectedL3 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1,4,5
    if(l1_val !== null && l4_val !== null && search){
      return p.lone_id === selectedL1 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //2,3,4
    if(l2_val !== null && l3_val !== null && l4_val !== null){
      return p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4;
    }
    //2,3,5
    if(l2_val !== null && l3_val !== null && search){
      return p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //2,4,5
    if(l2_val !== null && l4_val !== null && search){
      return p.ltwo_id === selectedL2 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //3,4,5
    if(l3_val !== null && l4_val !== null && search){
      return p.lthree_id === selectedL3 && p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1,2
    if(l1_val !== null && l2_val !== null){
      return p.lone_id === selectedL1 && p.ltwo_id === selectedL2;
    }
    //1,3
    if(l1_val !== null && l3_val !== null){
      return p.lone_id === selectedL1 && p.lthree_id === selectedL3;
    }
    //1,4
    if(l1_val !== null && l4_val !== null){
      return p.lone_id === selectedL1 && p.lfour_id === selectedL4;
    }
    //1,5
    if(l1_val !== null && search){
      return p.lone_id === selectedL1 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //2,3
    if(l2_val !== null && l3_val !== null){
      return p.ltwo_id === selectedL2 && p.lthree_id === selectedL3;
    }
    //2,4
    if(l2_val !== null && l4_val !== null){
      return p.ltwo_id === selectedL2 && p.lfour_id === selectedL4;
    }
    //2,5
    if(l2_val !== null && search){
      return p.ltwo_id === selectedL2 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //3,4
    if(l3_val !== null && l4_val !== null){
      return p.lthree_id === selectedL3 && p.lfour_id === selectedL4;
    }
    //3,5
    if(l3_val !== null && search){
      return p.lthree_id === selectedL3 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //4,5 
    if(l4_val !== null && search){
      return p.lfour_id === selectedL4 && p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    //1
    if(l1_val !== null){
      return p.lone_id === selectedL1;
    }
    //2
    if(l2_val !== null){
      return p.ltwo_id === selectedL2;
    }
    //3
    if(l3_val !== null){
      return p.lthree_id === selectedL3;
    }
    //4
    if(l4_val !== null){
      return p.lfour_id === selectedL4;
    }
    if(search) {
       return p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
     }
   // return true;
      // return p.lone_id === selectedL1 && p.ltwo_id === selectedL2 && p.lthree_id === selectedL3 && p.lfour_id === selectedL4;
  }
  return true;
  }

 const initialFormState = {id:'', part_num:'', l4:'', l5:'', material:'', finish_size:'', lfour_id:'', lfive_id:'' }
 const [data, setCurrentData] = useState(initialFormState)
 const tempCartRow = (n) => {
 	setCurrentData({part_num: n.part_num,l4: n.part_name, l5: n.specification, material: n.material,
    finish_size:n.finish_size, lfour_id: n.lfour_id, lfive_id: n.name})
 	setTempCartModal(!tempcartmodal) 	
 }

 const validateForm = (data) => {
    const errors = [];
    if(!data.cogs_ref){
      errors.cogs_ref = "Please Select COGS to proceed";
     }
     return errors;
  }

 const handleTempCart = (n) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const filtMgrId = (managers || []).find(x=>x.emp_id === user.id);
  const mgrId = filtMgrId && filtMgrId.mgr_id;
  const lfourName = lfour.find(x=> x.id === data.lfour_id);
  const l4Name = lfourName && lfourName.name; 
  const lfourNm = lfour.find(x=> x.name == data.part_name);
  const l4Nm = lfourNm && lfourNm.id;
  
  if(bom_value === 'no'){
    const aaa = {
    l4: l4Name,
    l5: data.lfive_id,
    exp_date: ExpectedDate,
    project_id: selectedProject,
    mgr_id: mgrId,
    project_id: selectedProject,
    client_id: ClientId,
    status: 'Created',
    user_id: user.id,
    group_id: user.group_id
  }
  console.log("aaa", aaa);
    postAddCart(aaa) 
    .then(resp => {
      getAddCart(group_id)
        .then(resp => setCart(resp.data))
      .then(resp => setQtys([{qtys:''}]))
      toast.success("Item is added to the cart !!!")
      setSearch('');
      setLoneVal(null);
      setTempCartModal(!tempcartmodal)
    })
  }
  else {
  const aaa = {
    bom_ref: bom_ref,
    part_num: data.part_num,
    l4: data.l4,
    l5: data.l5,
    material: data.material,
    finish_size:data.finish_size,
    qty: quantity,
    date: cartDate,
    remarks: cartRemarks,
    cogs_ref: selectedCogs,
    project_id: selectedProject,
    mgr_id: mgrId,
    client_id: ClientId,
    status: 'Created',
    user_id: user.id,
    group_id: user.group_id
  }
  const errors = validateForm(aaa);
    if(Object.keys(errors).length) {
     setError(errors);
    return;
  }
  console.log("aaa", aaa);
    postAddCart(aaa) 
    .then(resp => {
      getAddCart(group_id)
        .then(resp => setCart(resp.data))
      .then(resp => setQtys([{qtys:''}]))
      toast.success("Item is added to the cart !!!")
      setSearch('');
      setLoneVal(null);
      setTempCartModal(!tempcartmodal)
    })
  }
}

const [selectedDate, setSelectedDate] = useState([{date: new Date()}]);
  
const handleDateChange = (date, i) => {
	const tempQtys = [...selectedDate];
	tempQtys[i] = {...tempQtys[i], date : date};
	setSelectedDate(tempQtys);
};

const [remarks, setRemarks] = React.useState([{}]);
  
const handleRemarksChange = (event, i) => {
  let tempQtys = null;
  tempQtys = [...remarks];
  tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
  setRemarks(tempQtys);
};

const [quantity, setQuantity] = useState();
const handleQty = event => {
	setQuantity(event.target.value);
}

const [cartDate, handleCartDate] = useState(new Date());

const [cartRemarks, setCartRemarks] = useState();
const handleCartRemarks = event => {
	setCartRemarks(event.target.value);
}

const handleDeleteSubmit= (n) => {
    const deleteId = {
      id : n.id
    }
    deleteCart(deleteId)
      .then(resp => {
        getAddCart(user.group_id)
        .then(resp => setCart(resp.data))
      })
      .catch(err => {
        console.log("Error", err);
    })
}

	return (
        <Fragment>
        <Breadcrumb title="Non Project Indent List" parent="Indent" />
         <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-xl-12">
                <div className="card card-absolute">
                    <div className="card-header bg-secondary">
                        <h5>Prepare Indent</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">ItemClass</th>
                                <th scope="col">PartNo</th>
                                <th scope="col">Part Name</th>
                                <th scope="col">Function</th>
                                <th scope="col">Item Type</th>
                                <th scope="col">Material</th>
                                <th scope="col">Specs</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Finish Size</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
				                      <tr>
                               <th scope="row">{1}</th>
                               <td>{"AAA"}</td>
                               <td>{"TB16M13H00F00S90T135L"}</td>
                               <td>{"Turbine disk"}</td>
                               <td>{"Rotor"}</td>
                               <td>{"MFG"}</td>
                               <td>{"13-8 PH @ H 1025 Condition"}</td>
                               <td>{"1 x 2000L"}</td>
                               <td>{"2"}</td>
                               <td>{"OD 310.0 x 51.0 Thk."}</td>
                               <td>
                                <button className="btn btn-info btn-sm" type="button">
                                 Add
                                </button>
                               </td>
                              </tr>
                              <tr>
                               <th scope="row">{2}</th>
                               <td>{"AAA"}</td>
                               <td>{"TB16M13H00F00S90T135L"}</td>
                               <td>{"Turbine disk"}</td>
                               <td>{"Rotor"}</td>
                               <td>{"MFG"}</td>
                               <td>{"13-8 PH @ H 1025 Condition"}</td>
                               <td>{"1 x 2000L"}</td>
                               <td>{"2"}</td>
                               <td>{"OD 310.0 x 51.0 Thk."}</td>
                               <td>
                                <button className="btn btn-info btn-sm" type="button">
                                 Add
                                </button>
                               </td>
                              </tr>
                              <tr>
                               <th scope="row">{3}</th>
                               <td>{"AAA"}</td>
                               <td>{"TB16M13H00F00S90T135L"}</td>
                               <td>{"Turbine disk"}</td>
                               <td>{"Rotor"}</td>
                               <td>{"MFG"}</td>
                               <td>{"13-8 PH @ H 1025 Condition"}</td>
                               <td>{"1 x 2000L"}</td>
                               <td>{"2"}</td>
                               <td>{"OD 310.0 x 51.0 Thk."}</td>
                               <td>
                                <button className="btn btn-info btn-sm" type="button">
                                 Add
                                </button>
                               </td>
                              </tr>
                              <tr>
                               <th scope="row">{4}</th>
                               <td>{"AAA"}</td>
                               <td>{"TB16M13H00F00S90T135L"}</td>
                               <td>{"Turbine disk"}</td>
                               <td>{"Rotor"}</td>
                               <td>{"MFG"}</td>
                               <td>{"13-8 PH @ H 1025 Condition"}</td>
                               <td>{"1 x 2000L"}</td>
                               <td>{"2"}</td>
                               <td>{"OD 310.0 x 51.0 Thk."}</td>
                               <td>
                                <button className="btn btn-info btn-sm" type="button">
                                 Add
                                </button>
                               </td>
                              </tr>
                              <tr>
                               <th scope="row">{5}</th>
                               <td>{"AAA"}</td>
                               <td>{"TB16M13H00F00S90T135L"}</td>
                               <td>{"Turbine disk"}</td>
                               <td>{"Rotor"}</td>
                               <td>{"MFG"}</td>
                               <td>{"13-8 PH @ H 1025 Condition"}</td>
                               <td>{"1 x 2000L"}</td>
                               <td>{"2"}</td>
                               <td>{"OD 310.0 x 51.0 Thk."}</td>
                               <td>
                                <button className="btn btn-info btn-sm" type="button">
                                 Add
                                </button>
                               </td>
                              </tr>
				                    </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </Fragment>
    );
}
export default NonIndent;