import React, { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Home, Settings, ThumbsUp  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import Holiday from './Holiday'
import LeaveBalance from './LeaveBalance';
import LeaveApproval from './LeaveApproval';

const HolidayTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="Leave Management" parent="HR" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-xl-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Home />Holiday
                                </Tab>
                                <Tab className="current">
                                    <Settings />Leave Balance
                                </Tab>
                                <Tab className="current">
                                    <ThumbsUp />Leave Approval
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <Holiday />
                                </TabPanel>
                                <TabPanel>
                                    <LeaveBalance />
                                </TabPanel>
                                <TabPanel>
                                    <LeaveApproval />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

export default HolidayTab;