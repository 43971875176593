import React, { Fragment, useState, useEffect } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { Download } from 'react-feather';
import { TablePagination } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import {getIndentItems, getIndent, editIndent,  editIndentItems, getProjects, getClients,
getItemLone, getItemLtwo, getItemLthree, getItemLfour, getItemLfive, getAllUser, getBomItems } from '../../actions/asyncActions';

const IndentApproved = () => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const [indents, setIndents] = useState([]);
  const [indentItems, setIndentItems] = useState([]);
  const [projects, setProject] = useState([]);
  const [ clients, setClient ] = useState([]);

  const [lone, setLone] = useState([]);
  const [ltwo, setLtwo] = useState([]);
  const [lthree, setLthree] = useState([]);
  const [lfour, setLfour] = useState([]);
  const [lfive, setLfive] = useState([]);
  const [person, setPerson] = useState([]);
  const [bom_items, setBOMItems] = useState([]);

  const initialFormState = { id: '', name:'', lfour_id:'', lthree_id:'', ltwo_id:'', lone_id:'' }
  const [ data, setCurrentData ] = useState(initialFormState);
  const [modal, setModal] = useState();

  const filter_indents = indents.filter(s => s.status === 'Approved');
  //const filter_indents = indents.filter(s => s.status === 'Created' && s.mgr_id === user.id && s.bom_ref !== null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  useEffect( () => {
    getIndent(group_id)
    .then(resp => {
      setIndents(resp.data);
    })
  }, []);

  useEffect( () => {
    getIndentItems(group_id)
    .then(resp => {
      setIndentItems(resp.data);
    })
  }, []);

  useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
  }, []);

  useEffect( () => {
      getClients(group_id)
      .then(resp => setClient(resp.data))
    }, []);
  
  useEffect( () => {
    getItemLone(group_id)
    .then(resp => {
      setLone(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLtwo(group_id)
    .then(resp => {
      setLtwo(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLthree(group_id)
    .then(resp => {
      setLthree(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLfour(group_id)
    .then(resp => {
      setLfour(resp.data);
    })
  }, []);

  useEffect( () => {
    getItemLfive(group_id)
    .then(resp => {
      setLfive(resp.data);
    })
  }, []);

  useEffect( () => {
    getAllUser(group_id)
    .then(resp => {
      setPerson(resp.data);
    })
  }, []);

  useEffect( () => {
    getBomItems(group_id)
    .then(resp => setBOMItems(resp.data))
  }, []);

  const [selId, setSelectedID] = React.useState([]);
  const filter_indentItems = indentItems.filter(x => x.indent_id === selId);

  const viewRow = n => {
  	setModal(!modal)
  	setSelectedID(n.id);
    const lev5 = (lfive || []).find(x=> x.id == n.l5);
    const leve5 = lev5 && lev5.name;
    const lev4 = (lfour || []).find(x=> x.id == n.l4);
    const leve4 = lev4 && lev4.name;
    const lev3 = (lthree || []).find(x=> x.id == n.l3);
    const leve3 = lev3 && lev3.name;
    const lev2 = (ltwo || []).find(x=> x.id == n.l2);
    const leve2 = lev2 && lev2.name;
    const lev1 = (lone || []).find(x=> x.id == n.l1);
    const leve1 = lev1 && lev1.name;
    
    setCurrentData({ id: n.id, project_id: n.project_id, client_id: n.client_id, qty:n.qty, date: n.date, 
    l1:n.l1, l2:n.l2, l3:n.l3, l4:n.l4, l5:n.l5, status:n.status })
  }

  const approveIndent = (id, n) => {
    const data = {
      id: id,
      user_id: user.id,
      status: 'Approved',
    }
    editIndent(data)
    .then(resp => {
          getIndent(group_id)
          .then(resp => setIndents(resp.data))
          toast.success("Indent Approved !!!")
        })
        .catch(err => {
          console.log("Error", err);
        })
  }

	return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Indent</th>
                                                  <th scope="col">Project</th>
                                                  <th scope="col">Client</th>
                                                  <th scope="col">BOM</th>
                                                  <th scope="col">Status</th>
                                                  <th scope="col">Created Date</th>
                                                  <th scope="col">Approved By</th>
                                                  <th scope="col">Item(Nos)</th>
                                                  <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {filter_indents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                const projectName = (projects || []).find(x=> x.id === n.project_id);
              								                  const clientName = (clients || []).find(x=> x.id === n.client_id);
              								                  const noOfItems = indentItems.filter(x=>x.indent_id === n.id);
              								                  const totalItems = noOfItems.length;
              								                  const userName = person.find(x=>x.id === n.user_id);
              								                  const approvedBy = userName && userName.firstname;

                                                return (
                                                  <tr>
                                                   <th scope="row">{i+1}</th>
                                                   <td>{("IN-000").concat(n.id)}</td>
                                                   <td>{projectName && projectName.name}</td>
                                                   <td>{clientName && clientName.name}</td>
                                                   <td>{n.bom_ref}</td>
                                                   <td>{n.status}</td>
                                                   <td>
                                                    {new Date(n.createdAt).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                   </td>
                                                   <td>{approvedBy}</td>
                                                   <td>{totalItems}</td>
                                                   <td>
                                                    <i className="fa fa-eye" onClick={() => viewRow(n)} id="TooltipTop" data-placement="top"
                                                     style={{ width: 35, fontSize: 16, padding: 11, color: '#007bff ' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      View Items
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </td>
                                                  </tr>
                                                );
                                               })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                      {/*
                                        <button className="btn btn-info btn-sm" type="button">
                                          <Download size="14"/>Export
                                        </button>
                                      */}
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={filter_indents.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          {/* < !-- Using Modal for Viewing Indent --> */}
            <Modal isOpen={modal} toggle={viewRow} className="modal-dialog modal-xl">
                <ModalHeader toggle={viewRow}>View Indent</ModalHeader>   
                <ModalBody>
                   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Project</th>
                                                    <th scope="col">Part No</th>
                                                    <th scope="col">Part Name</th>
                                                    <th scope="col">Material</th>
                                                    <th scope="col">Finish Size</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filter_indentItems.map((n)=> {
                								                  const proj = projects.find(x=>x.id === n.project_id);
                								                  const clint = clients.find(x=>x.id === n.client_id);
                								                  const projectName = proj && proj.name;
                								                  const clientName = clint && clint.name;

                                                  return (
                                                    <tr>
                                                     <td>{projectName}</td>
                                                     <td>{n.part_num}</td>
                                                     <td>{n.part_name}</td>
                                                     <td>{n.material}</td>
                                                     <td>{n.finish_size}</td>
                                                     <td>{n.qty}</td>
                                                     <td>
                                                      {new Date(n.date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{n.remarks}</td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={viewRow}>Close</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}
export default IndentApproved;