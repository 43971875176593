import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { FormHelperText, TablePagination, TextField, Typography } from '@material-ui/core';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import {getGrinItems, getVendorForm, getPOItems, editGrinItems, postQcReject, postGrLogs, 
        postTransInventory, getMasterInventory, getWarehouse } from '../../actions/asyncActions';
import PrintStockCard from './print.js';

const QualityCheck = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    //const [values, setValues] = useState({});
    const [errors, setError] = React.useState({});
    const [vendorForm, setVendors] = useState([]);
    // const [vendor_val, setVendorVal] = useState(null);
    // const vendorId = vendor_val && vendor_val.id;
    // const vendorName = vendor_val && vendor_val.supname;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [grinItems, setGrinItems] = useState([]);
    const [poitems, setPOItems] = useState([]);

    const filterGrinItems = grinItems.filter(x=>x.status === 'GRN Raised')

    const [masterInventory, setMasterInventory] = useState([]);
    const [warehouse, setWarehouse] = useState([]);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getVendorForm(group_id)
      .then(resp => setVendors(resp.data))
    }, []);

    useEffect( () => {
      getGrinItems(user.group_id)
      .then(resp => setGrinItems(resp.data))
    }, []);

    useEffect( () => {
      getPOItems(group_id)
       .then(resp => setPOItems(resp.data))
    }, []);

    useEffect( () => {
      getMasterInventory(group_id)
       .then(resp => setMasterInventory(resp.data))
    }, []);

    useEffect( () => {
      getWarehouse(group_id)
       .then(resp => setWarehouse(resp.data))
    }, []);

    
  // const handleChange = event => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };

  
  const [qualitymodal, setQualityModal] = useState()
  const [approveId, setApproveId] = useState();
  const [poItemId, setPOItemId] = useState();
  const [approveQty, setApproveQty] = useState();
  const [rejectedQty, setRejectedQty] = useState(0);
  const [receivedQty, setRecdQty] = useState();

  const grnData = grinItems.find(x=>x.id === approveId);
  const grnNo = grnData && grnData.id; //GRN No
  const dOrderNo = grnData && grnData.do_no; //do_no
  const poNumber = grnData && grnData.po_no; //PO Number
  const invMasterId = grnData && grnData.inventory_id; 
  const supplierCode = grnData && grnData.vendor_id;
  const supName = vendorForm.find(x => x.id === supplierCode);
  const supplierName = supName && supName.supname;

  const invMaster = masterInventory.find(x =>x.id === invMasterId);
  const partNo = invMaster && invMaster.part_no;
  const description = invMaster && invMaster.description;
  const uom = invMaster && invMaster.unit;

  const [remarks, setRemarks] = useState();
  //const warehouse = [{ name: 'WH1' }, { name: 'WH2' }, { name: 'WH3' }, { name: 'WH4' }, { name: 'WH5' }];
  const [warehouseVal, setWarehouseVal] = useState(null);
  const selectedWarehouse = warehouseVal && warehouseVal.name;

  const [printmodal, setPrintModal] = useState()
  
  const validateForm = (approveData) => {
    const errors = [];
    if((parseInt(approveData.apr_qty) + parseInt(rejectedQty)) !== parseInt(receivedQty)){
      errors.approveQty = "Please fill in both the quantities to match the GRN";
     }
     return errors;
  }
  
  const approveRow = n => {
    setApproveId(n.id);
    setPOItemId(n.po_itemno);
    setApproveQty(n.rec_qty);
    setRecdQty(n.rec_qty);
    setQualityModal(!qualitymodal);
    setError('');
  }

  const approveItem = () => {
    const rejData = grinItems.find(x=>x.id === approveId);
    const aaa = poitems.find(x=>x.id === rejData.po_itemno);
    const poRate = aaa && aaa.price; 
    const data = {
      id: approveId,
      po_itemno: poItemId,
      apr_qty: approveQty,
      user_id: user.id,
      warehouse: selectedWarehouse,
      remarks: remarks,
      status: 'Approved',
    }
    const approveData = {
      grin: rejData.grin,
      gr_item_id: rejData.id,
      rec_qty: rejData.rec_qty,
      apr_qty: approveQty,
      po_no: rejData.po_no,
      po_itemno: rejData.po_itemno,
      poqty: rejData.poqty,
      grqty: rejData.grqty,
      vendor_id: rejData.vendor_id,
      status: 'Approved',
      warehouse: selectedWarehouse,
      remarks: remarks,
      client_id: rejData.client_id,
      project_id: rejData.project_id,
      user_id: user.id,
      company_id: user.company_id,
      group_id: group_id
    }
    const rejectData = {
      grin: rejData.grin,
      gr_item_id: rejData.id,
      rej_qty: rejectedQty,
      po_no: rejData.po_no,
      po_itemno: rejData.po_itemno,
      poqty: rejData.poqty,
      grqty: rejData.grqty,
      vendor_id: rejData.vendor_id,
      status: 'Rejected',
      inventory_id: rejData.inventory_id,
      do_no: rejData.do_no,
      do_file: rejData.do_file,
      warehouse: selectedWarehouse,
      remarks: remarks,
      client_id: rejData.client_id,
      project_id: rejData.project_id,
      user_id: user.id,
      company_id: user.company_id,
      group_id: group_id
    }
    const inventoryData = {
      inventory_id: rejData.inventory_id,
      qty: approveQty,
      rate: parseFloat(poRate),
      total: approveQty * parseFloat(poRate),
      date: new Date(),
      type: 'Inward',
      vendor_id: rejData.vendor_id, 
      project_id: rejData.project_id,
      user_id: user.id,
      group_id: group_id
    }
    const errors = validateForm(approveData);
    if(Object.keys(errors).length) {
      setError(errors);
      return;
    }
    postTransInventory(inventoryData)
    postGrLogs(approveData)
    editGrinItems(data)
    .then(resp => {
      getGrinItems(group_id)
      .then(resp => setGrinItems(resp.data))
      if(parseInt(rejectedQty) === 0){

      }
      else{
        postQcReject(rejectData)
        postGrLogs(rejectData)
      }
      setQualityModal(!qualitymodal)
      toast.success("Quality Approved & Created GRN successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
      printRow()
    })
    .catch(err => {
      console.log("Error", err);
    })
  }

  const printRow = () => {
    setPrintModal(!printmodal)
  }

  const handleStockCardOpen = () => {
    handlePrintStockCard()
    setPrintModal(!printmodal)
  }

  const handlePrintStockCard = () => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(`<html><head><title>${document.title}</title>
     <style>
     @page { margin: 0 }
      body { margin: 0 }
      .sheet {
      margin: 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      page-break-after: always;
      }

      /* Padding area */
      .sheet.padding-5mm { padding: 5mm }

      /* For screen preview */
      @media screen {
      body { background: #e0e0e0 }
        .sheet {
         background: white;
         box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
         margin: 2mm;
       }
       table {
          border-collapse: collapse;
      }
       tr.border_bottom td {
        border-bottom:1pt solid black;
      }
      table th, table td {
      border:1px solid #000;
        padding: 0.5em;
      }
      }
      @media print {
          html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
          font-size: 9pt !important;
               }
            }

     </style>
     `);
   mywindow.document.write('</head><body>');
   mywindow.document.write(document.getElementById('printreceipt').innerHTML);
   mywindow.document.write('</body></html>');

   mywindow.document.close(); // necessary for IE >= 10
   mywindow.focus(); // necessary for IE >= 10*/

   mywindow.print();
   mywindow.close();

   return true;
  }

        return (
            <Fragment>
            <Breadcrumb title="Quality Check" parent="Material Management" />
            <div style={{display:"none"}}>
              <PrintStockCard
                grnNumber = {('GR-000').concat(grnNo)}
                poNumber = {('PO-000').concat(poNumber)}
                supplierCode = {supplierCode}
                supplierName = {supplierName}
                MaterialName = {partNo}
                Description = {description}
                Warehouse ={selectedWarehouse}
                Remarks = {remarks}
                Quantity = {approveQty}
                uom = {uom}
              />
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-info">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">DO No</th>
                                                  <th scope="col">Vendor</th>
                                                  <th scope="col">Item Name</th>
                                                  <th scope="col">Material</th>
                                                  <th scope="col">Qty</th>
                                                  <th scope="col">Date</th>
                                                  <th scope="col">Type</th>
                                                  <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterGrinItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  const aaa = vendorForm.find(x=>x.id === n.vendor_id);
                                                  const vendorName = aaa && (aaa.supname || []);
                                                  const porderItems = poitems.find(x=>x.id === n.po_itemno);
                                                  const partName = porderItems && porderItems.part_name;
                                                  const material = porderItems && porderItems.material;
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.do_no}</td>
                                                     <td>{vendorName}</td>
                                                     <td>{partName}</td>
                                                     <td>{material}</td>
                                                     <td>{n.rec_qty}</td>
                                                     <td>
                                                      {new Date(n.createdAt).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{n.type}</td>
                                                     <td>
                                                     <button>
                                                      <i className="fa fa-thumbs-up" onClick={() => approveRow(n)} id="TooltipTop" data-placement="top"
                                                       style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff ' }}>
                                                       <UncontrolledTooltip placement="top" target="TooltipTop">
                                                        Approve Item
                                                       </UncontrolledTooltip>
                                                      </i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterGrinItems.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Approveing Items --> */}
            <Modal isOpen={qualitymodal} toggle={approveRow} className="modal-body" centered={true}>
                <ModalHeader className="text-center" toggle={approveRow}>
                  Please fill the blank fields
                </ModalHeader>   
                <ModalBody className="text-center">
                    <form>
                      <div className="form-row">
                        <div className="col-md-12">
                          <label className="col-form-label">DO No:<h5>{dOrderNo}</h5></label> 
                        </div>
                        <div className="col-md-6">
                          <label className="col-form-label">Item name:<h5>{partNo}</h5></label> 
                        </div>
                        <div className="col-md-6">
                          <label className="col-form-label">Description:<h5>{description}</h5></label> 
                        </div>
                        <div className="col-md-6">
                          <label className="col-form-label">Approved Qty:</label> 
                          <input className="form-control" type="text" onChange={e => setApproveQty(e.target.value)} value={approveQty} />
                          <FormHelperText className="text-danger">{errors.approveQty}</FormHelperText>
                        </div>
                        <div className="col-md-6">
                          <label className="col-form-label">Rejected Qty:</label> 
                          <input className="form-control" type="text" onChange={e => setRejectedQty(e.target.value)} value={rejectedQty} />
                        </div>
                        <div className="col-md-6">
                          <label className="col-form-label">Warehouse No:</label>
                          <Autocomplete 
                            freeSolo
                            options={warehouse}
                            getOptionLabel={option => option.name}
                            value={warehouseVal}                      
                            onChange={(event, newValue) => {
                              setWarehouseVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params} margin="dense" variant="outlined" fullWidth />
                            )}
                          /> 
                        </div>
                        <div className="col-md-6">
                          <label className="col-form-label">Remarks:</label> 
                          <input className="form-control m-t-10" type="text" onChange={e => setRemarks(e.target.value)} value={remarks} />
                        </div>
                      </div><hr/>
                      <div className="offset-sm-3 col-sm-3">
                       <Button color="success" onClick={approveItem}>Create Stock Card</Button>
                      </div>
                    </form>
                </ModalBody>
            </Modal>

           {/*Print Stock Card Modal */}
           <Modal isOpen={printmodal} toggle={printRow} className="modal-body" centered={true}>
            <ModalBody className="text-center">
              <form>
               <div className="form-row">
                <div className="col-xl-12">
                  <Typography align="center">
                    <CheckCircleOutlineRoundedIcon style={{fontSize: '100px', color:'#4bb543'}}/>
                  </Typography>
                  <Typography component="h2" variant="h6" align="center" gutterBottom>
                    The GRN is: <b> {('GR-000').concat(grnNo)} </b>
                  </Typography>&nbsp;
                  <Typography component="h2" align="center">
                    <Button color="success" className="btn btn-secondary btn-md" onClick={handleStockCardOpen}>Print Stock Card</Button>
                  </Typography>
                </div>
               </div>
              </form>
            </ModalBody>
           </Modal>
        </Fragment>
        );
}

export default QualityCheck;