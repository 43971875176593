import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TextField, FormHelperText, MenuItem } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { getVendorForm, getPorders, getPOItems, getGrinItems, postGrin, postGrLogs, getMasterInventory, 
         doUpload } from '../../actions/asyncActions';

const GoodsReceived = () => {
 
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const [vendorForm, setVendors] = useState([]);
 const approvedVendors = vendorForm.filter(x=>x.status === 'Approved');
 const [vendor_val, setVendorVal] = useState(null);
 const selectedVendor = vendor_val && vendor_val.id;
 const [porders, setPOrders] = useState([]);
 const [porder_val, setPorderVal] = useState(null);
 const selectedPO = porder_val && porder_val.id;
 const filterPo = porders.filter(x=>x.vendor_id === selectedVendor);

 const [poitems, setPOItems] = useState([]);
 const filter_poitems = poitems.filter(x=>x.po_no === selectedPO && x.status !== "Received");
 const [errors, setError] = React.useState({});

 //const [grinList, setGrin] = useState([]);
 const [grinItems, setGrinItems] = useState([]);
 const [masterInventory, setMasterInventory] = useState([]);
 const filterMasterInventory = masterInventory.filter(x=>x.raw_material != 1)

 useEffect( () => {
  getVendorForm(group_id)
   .then(resp => setVendors(resp.data))
 }, []);

 useEffect( () => {
  getPorders(group_id)
   .then(resp => setPOrders(resp.data))
 }, []);

 useEffect( () => {
  getPOItems(group_id)
   .then(resp => setPOItems(resp.data))
 }, []);

 useEffect( () => {
  getGrinItems(user.group_id)
   .then(resp => setGrinItems(resp.data))
 }, []);

 useEffect( () => {
   getMasterInventory(group_id)
    .then(resp => setMasterInventory(resp.data))
 }, []);

 const [grqty, setSelectedQty] = React.useState([{}]);
 console.log("grqty", grqty)
 const handleQtyChange = (event, i) => {
  const tempQtys = [...grqty];
  tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
  setSelectedQty(tempQtys);
 }


 const [inventory_id, setInventoryId] = React.useState([{}]);
 const handleInventorySelect = (event, i) => {
  const { name, value } = event.target
  const tempQtys = [...inventory_id];
  tempQtys[i] = {...tempQtys[i], [name]: value};
  setInventoryId(tempQtys);
 };

 const [deliverymodal, setDeliveryModal] = useState();
 const [do_no, setDoNumber] = useState();
 const [do_file, setDoFile] = useState([]);

 const handleUploadDo = async (event) => {
   setDoFile(document.getElementById('filedo').files[0]);
 }

 const dOrderOpen = (event, i) => {
  let recdqty = [];
  let orders = [];
  let grqtys = [];
  let po_dataItems = [];
  let errors = [];
  let errorsQty = [];
  let inventoryID = [];
  grqty.forEach( async (obj, idx) => {
        if(obj.grqty){
          const poItems = filter_poitems[idx];
          orders.push(poItems);
          const { id, project_id, qty, client_id, mgr_id, user_id, group_id } = filter_poitems[idx];
          recdqty =  grinItems.find(x=>x.po_itemno === id);
          const aaa = recdqty && recdqty.rec_qty;
          const sinventory = inventory_id[idx] ? inventory_id[idx].inventory_id : null;

          if(sinventory !== null){
            inventoryID.push({
              sinventory: sinventory
            });  
          }
          
          grqtys = parseInt(obj.grqty) || 0;
          if(aaa){
            po_dataItems.push({
              rec_qty: grqtys,
              grqty: aaa,
              po_no: selectedPO,
              po_itemno: id,
              poqty: qty,
              vendor_id: selectedVendor,
              inventory_id: sinventory,
              project_id: project_id,
              client_id: client_id,
              status: 'GRN Raised',
              type: 'Regular',
              user_id: user_id,
              group_id: group_id          
            });
            errors = validateForm(po_dataItems, idx);
            
            if(Object.keys(errors).length) {
              errorsQty.push({
                aaa: errors
              });
              setError(errors);
              return;
            }
          }
          else {
            po_dataItems.push({
              rec_qty: grqtys,
              po_no: selectedPO,
              po_itemno: id,
              poqty: qty,
              vendor_id: selectedVendor,
              inventory_id: sinventory,
              project_id: project_id,
              client_id: client_id,
              status: 'GRN Raised',
              type: 'Regular',
              user_id: user_id,
              group_id: group_id          
            });
          }
          errors = validateForm(po_dataItems, idx);
         
          if(Object.keys(errors).length) {
            errorsQty.push({
              aaa: errors
            });
            setError(errors);
            return;
          }
        }
        })
        if(Object.keys(errorsQty).length > 0){
          return;
        }
        else if(po_dataItems.length > 0) {
          if(inventoryID.length === po_dataItems.length){
            setDeliveryModal(!deliverymodal)
            setDoNumber('')
          }
        }  
        else {
          toast.error("Please select the item from the Inventory", {position: toast.POSITION.TOP_CENTER})
        }
 }

 const validateForm = (data, i) => {
   const errors = [];
    if((data[i].rec_qty + data[i].grqty) > (data[i].poqty)){
     errors.grqty = "Qty cannot be greater than PO Qty";
    }
    if((data[i].rec_qty) < 0) {
     errors.grqty = "Qty cannot be negative";
    }
    if(data[i].rec_qty > data[i].poqty){
      errors.grqty = "Qty cannot be greater than PO Qty";
    }
    if(data[i].inventory_id === undefined){
      errors.inventory_id = "Please select relevant Inventory Item";
    }
    if(!(data[i].rec_qty)){
      errors.grqty = "Please enter the received Qty";
    }
   return errors;
 }

 const handleSubmit = async (event, i) => {
  const user = JSON.parse(localStorage.getItem("m_users"));

  const formData = new FormData();
  formData.append( 'file', do_file );
  const dOrder = await doUpload(formData);
  const dOrderPath = dOrder.data.filename;

  let recdqty = [];
  let orders = [];
  let grqtys = [];
  let po_dataItems = [];
  let errors = [];
  let errorsQty = [];
    grqty.forEach( async (obj, idx) => {
        if(obj.grqty){
          const poItems = filter_poitems[idx];
          orders.push(poItems);
          const { id, project_id, qty, client_id, mgr_id, user_id, group_id } = filter_poitems[idx];
          recdqty =  grinItems.find(x=>x.po_itemno === id);
          const aaa = recdqty && recdqty.rec_qty;
          //const sinventory = inventory_id[idx].inventory_id || null;
          
          grqtys = parseInt(obj.grqty) || 0;
          if(aaa){
            po_dataItems.push({
              rec_qty: grqtys,
              grqty: aaa,
              po_no: selectedPO,
              po_itemno: id,
              poqty: qty,
              vendor_id: selectedVendor,
              inventory_id: inventory_id[idx].inventory_id,
              project_id: project_id,
              client_id: client_id,
              status: 'GRN Raised',
              type: 'Regular',
              do_no: do_no,
              do_file: dOrderPath,
              user_id: user_id,
              group_id: group_id          
            });
            errors = validateForm(po_dataItems, idx);
            
            if(Object.keys(errors).length) {
              errorsQty.push({
                aaa: errors
              });
              setError(errors);
              return;
            }
          }
          else {
            po_dataItems.push({
              rec_qty: grqtys,
              po_no: selectedPO,
              po_itemno: id,
              poqty: qty,
              vendor_id: selectedVendor,
              inventory_id: inventory_id[idx].inventory_id,
              project_id: project_id,
              client_id: client_id,
              status: 'GRN Raised',
              type: 'Regular',
              do_no: do_no,
              do_file: dOrderPath,
              user_id: user_id,
              group_id: group_id          
            });
          }
          errors = validateForm(po_dataItems, idx);
         
          if(Object.keys(errors).length) {
            errorsQty.push({
              aaa: errors
            });
            setError(errors);
            return;
          }
        }
        })  
        const po_data = {
          po_no: selectedPO,
          vendor_id: selectedVendor,
          project_id: orders[0].project_id,
          client_id: orders[0].client_id,
          status: 'GRN Raised',
          user_id: user.id,
          group_id: user.group_id
        }

        if(Object.keys(errorsQty).length > 0){
          return;
        }
        else{
          postGrin({po_data, po_dataItems})
          .then(resp => {
            getGrinItems(user.group_id)
            .then(resp => setGrinItems(resp.data))
            toast.success("Material receipt is created successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
            setVendorVal(null)
            setPorderVal(null)
            setDeliveryModal(!deliverymodal)
            setDoNumber('')
          })
          .catch(err => {
            console.log("Error", err);
          })
        }
  }

	return (
      <Fragment>
         <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-12">
                <div className="card">
                {/*
                  <div className="card-header bg-secondary">
                    <h5>Create Material Receipt</h5>
                  </div>
                */}
                  <div className="card-body">
                   <div className="row">
                     <div className="col-xl-4 col-lg-6 col-md-6">
                      <Autocomplete
                        freeSolo
                        options={approvedVendors}
                        getOptionLabel={option => option.supname}
                        value={vendor_val}                      
                        onChange={(event, newValue) => {
                          setVendorVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Vendor" margin="normal" variant="outlined" helperText="Please select Vendor" fullWidth />
                        )}
                      />
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6">
                      <Autocomplete 
                        freeSolo
                        options={filterPo}
                        getOptionLabel={option => ('PO-000').concat(option.id.toString())}
                        value={porder_val}                      
                        onChange={(event, newValue) => {
                          setPorderVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="PO" margin="normal" variant="outlined" helperText="Please select PO" fullWidth />
                        )}
                      />
                     </div>
                     {selectedVendor && selectedPO ?
                      filter_poitems.length > 0 ?
                      <div className="row">
                      <div className="col-xl-12">
                       <div className="table-responsive">
                          <table className="table table-sm table-bordered table-hover">
                              <thead className="table-info">
                                  <tr>
                                    <th scope="col">Sl.No</th>
                                    <th scope="col">PartNo</th>
                                    <th scope="col">PartName</th>
                                    <th scope="col">Inventory</th>
                                    <th scope="col">PO Qty</th>
                                    <th scope="col">Recd</th>
                                    <th scope="col">Bal</th>
                                    <th scope="col">Received Qty</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {filter_poitems.map((n, i)=> {
                                    //const filterGrnItems = grinItems.find(x=>x.po_itemno === n.id );
                                    const filterGrnItems = grinItems.filter(x=>x.po_itemno === n.id && x.type === 'Regular');
                                    const grirItems = filterGrnItems.reduce((a, b) => a + b.rec_qty, 0);
                                    //const grirItems = filterGrnItems && filterGrnItems.rec_qty || [];
                                    const balance = n.qty - grirItems;
                                    return (
                                      <tr>
                                       <th scope="row">{i+1}</th>
                                       <td>{n.part_num}</td>
                                       <td>{n.part_name}</td>
                                       {(n.qty === grirItems)?
                                       <td></td>
                                        :
                                       <td>
                                        <TextField id="inventory_id" select value={inventory_id[i]? inventory_id[i].inventory_id: null}
                                         onChange={(e) => handleInventorySelect(e, i)} SelectProps={{ name: 'inventory_id' }}
                                          margin="dense" variant="outlined" >
                                          {
                                            filterMasterInventory.map((n, index) => {
                                              return <MenuItem key={index} value={n.id}>{n.part_no}</MenuItem>
                                            })
                                          }
                                        </TextField>
                                        <FormHelperText className="text-danger">{errors.inventory_id}</FormHelperText>
                                       </td>
                                       }
                                       <td>{n.qty}</td>
                                       <td>{grirItems}</td>
                                       <td>{balance}</td>
                                       {(n.qty === grirItems)?
                                         <td></td>
                                         :
                                         <td>
                                          <TextField type="number" name="grqty" key={i} variant="outlined" margin="dense" size="small"
                                            value={grqty[i] ? grqty[i].grqty: ''} onChange={(e) => handleQtyChange(e, i)}/>
                                          <FormHelperText className="text-danger">{errors.grqty}</FormHelperText>
                                         </td>
                                       }
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                          </table>
                       </div>
                      </div>
                      <div className="col-xl-12 text-center m-t-15">
                       <button className="btn btn-secondary btn-md" type="button" onClick={dOrderOpen}>
                        Create Material Receipt
                       </button>
                      </div>
                     </div>
                     :
                     <div className="row">
                       <div className="col-xl-12">
                        <h3 className="text-success">All the items are received for this PO</h3>
                       </div>
                     </div>
                      : null
                    }
                   </div>
		              </div>
                </div>
              </div>
            </div>
         </div> 

       {/* < !-- Using Form Modal for Upload Delivery Order(DO) --> */}
          <Modal isOpen={deliverymodal} toggle={dOrderOpen} className="modal-body" centered={true}>
              <ModalHeader className="text-center" toggle={dOrderOpen}>
                Please enter the Delivery Order No and upload the document
              </ModalHeader>   
              <ModalBody className="text-center">
                  <form>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label className="col-form-label">DO Number:</label> 
                        <input className="form-control" type="text" onChange={e => setDoNumber(e.target.value)} value={do_no} />
                      </div>
                      <div className="col-md-12">
                        <label className="col-form-label" htmlFor="recipient-name">Attach File:</label>
                        <input className="form-control" id="filedo" type="file" onChange={handleUploadDo} />
                      </div>
                    </div>
                  </form>
              </ModalBody>
              <ModalFooter>
                  <Button color="danger" onClick={dOrderOpen}>Cancel</Button>
                  <Button color="primary" onClick={handleSubmit}>Submit</Button>
              </ModalFooter>
          </Modal>
      </Fragment>
    );
}
export default GoodsReceived;