import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//import clsx from 'clsx';
import { Card, CardContent, Divider, TextField, Button, MenuItem,FormHelperText,  Grid } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1),
  },
  barchartcard: {
    margin: theme.spacing(1),
  },
  errors: {
    color: 'red'
  }
  }));

export default function Consumption(props) {
  const classes = useStyles();
    const theme = useTheme();
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    const downloadCostsheetFile = () => {
      //fetch('http://localhost:9004/Data/COGS/costsheet.xlsx')
      //fetch('http://172.105.41.149:9004/Data/COGS/costsheet.xlsx')
      //fetch('https://testerp.nu-pie.com/Data/COGS/costsheet.xlsx')
      fetch('172.105.41.149:9004/Data/COGS/costsheet.xlsx')
          .then(response => {
              response.blob().then(blob => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = 'costsheet.xlsx';
                  a.click();
              });
              //window.location.href = response.url;
      });
    }
  
    const { importfile, ProjectNum, errors } = props;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-4">
            <TextField fullWidth required id="name" label="Project Num" margin="normal" name="name" 
              value={ProjectNum} variant="outlined" />
          </div>
          <div className="col-xl-4">
            <TextField id="file" type="file" name="file" margin="normal" variant="outlined" onChange={props.handleUploadCostSheet} fullWidth />
            <FormHelperText className={classes.errors}>{errors.file}</FormHelperText>
          </div>
          <div className="col-xl-4 m-t-20">
            <button className="btn btn-info btn-lg" onClick={downloadCostsheetFile}>Download Costsheet Template</button>
          </div>
        </div>
      </div>
    );
}