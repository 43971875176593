import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { signinUser2 } from './asyncActions';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    // case "CHECKOUT_SUCCESS":
    //   return { ...state, cart_items: action.data };
    // case "PLACE_ORDER":
    //   return { ...state, cart_items: [] };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

// const onKeyDown = (e) => {

//   }

//   const pressEnter = (e) => {
//     e.preventDefault();
//   }

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser(event, login, password, history) {
  event.preventDefault();
  //pressEnter(e);
  // setError(false);
  // setIsLoading(true);
  const data = await signinUser2(login, password)
  if (data) {
    setTimeout(() => {
      localStorage.setItem("id_token", "1");
       toast.success("Login Successful!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 1000})
        if(data.user.role === 'superadmin') {
          history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
          window.location.reload();
        }
        else if(data.user.role === 'Admin') {
          history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
          window.location.reload();
        }
        else if(data.user.role === 'Vendor') {
          history.push(`${process.env.PUBLIC_URL}/vendorform`);
          window.location.reload();
        }
        else if(data.user.role === 'Employee') {
          history.push(`${process.env.PUBLIC_URL}/employeeform`);
          window.location.reload();
        }
    }, 1000);
  } else {
   // dispatch({ type: "LOGIN_FAILURE" });
    alert("Enter valid credentials");
    // setError(true);
    // setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

// function CartItems(dispatch, user_id, store_id, history, setIsLoading, setError) {
//   const data = await getCheckout(user_id, store_id)
//   dispatch({ type: "CHECKOUT_SUCCESS", data });
  
  
// }
