import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TablePagination, TextField } from '@material-ui/core';
import { getGroup, editGroup, postGroup } from '../../actions/asyncActions';

const MyGroup = () => {
    const [group, setGroup] = useState([]);
    const initialFormState = { id: '', name:'', email:'', phone:'', gst:'', address:'', country:'', city:'', 
          state:'', owner_details:'', no_stores:'', finance:'', hr:'' }
    const [ data, setCurrentData ] = useState(initialFormState);
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();
    const [values, setValues] = useState({});

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getGroup()
      .then(resp => {
        setGroup(resp.data);
      })
    }, []);

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentData({ ...data, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
    }

    const editRow = n => {
     setModal(!modal)
     setCurrentData({ id: n.id, name: n.name, email: n.email, phone: n.phone, address: n.address, country:n.country, city: n.city, 
      state:n.state, gst: n.gst})
    }

    const handleSubmit = () => {
        const user = {
          name: values.name,
          email: values.email,
          phone: values.phone,
          address: values.address,
          city: values.city,
          state: values.state,
          country: values.country,
          gst: values.gst
        }
        console.log("user", user);
        // const errors = validateForm(user);
        // if(Object.keys(errors).length) {
        //   setError(errors);
        //   return;
        // }
        postGroup(user)
        .then(resp => {
            getGroup()
            .then(resp => setGroup(resp.data))
            setValues({ id: '', name:'', email:'', phone:'', address:'', gst:'', country:'', city:'', state:''});
            setAddModal(!addmodal)
          })
          .catch(err => {
            console.log("Error", err);
        })
   }

    const handleUpdate = (id, data) => {
        
        const bbb = {
          id: id,
          name: data.name,
          email: data.email,
          phone: data.phone,
          address: data.address,
          country: data.country,
          city: data.city,
          state: data.state,
          gst: data.gst
        }
        // const errors = validateForm(data);
        // if(Object.keys(errors).length) {
        //   setError(errors);
        //   return;
        // }
        editGroup(bbb)
        .then(resp => {
              getGroup()
              .then(resp => setGroup(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }

    const [search, setSearch] = useState(null);
    const updateSearch = (event) => {
      setSearch(event.target.value.substr(0,20));
    }

    const filterItems = (p) => {
      if(search) {
       return p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
     }
     return true;
    }

        return (
            <Fragment>
            <Breadcrumb title="All Groups" parent="Group" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                           <div className="col-md-2">
                            <TextField label="Filter by Name..." name="name" type="text" variant="outlined" onChange={ updateSearch }
                              value = {search} margin="dense" fullWidth/>
                           </div>
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">
                                                     <input className="checkbox_animated" type="checkbox" />
                                                    </th>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Phone</th>
                                                    <th scope="col">Address</th>
                                                    <th scope="col">City</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {group.filter(filterItems)
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <td> <input className="checkbox_animated" type="checkbox" /> </td>
                                                     <th scope="row">{n.id}</th>
                                                     <td>{n.name}</td>
                                                     <td>{n.email}</td>
                                                     <td>{n.phone}</td>
                                                     <td>{n.address}</td>
                                                     <td>{n.city}</td>
                                                     <td>
                                                      <button className="btn btn-info btn-xs" type="button" onClick={() => editRow(n)}>
                                                       <i className="icofont icofont-edit"></i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                 <button className="btn btn-info btn-md" type="button" onClick={() => addRow()}>
                                   Add Group
                                  </button>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={group.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Add Users --> */}
            <Modal isOpen={addmodal} toggle={addRow} size="lg">
                <ModalHeader toggle={addRow}>Add Group</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-4 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Name:</label>
                              <input className="form-control" name="name" type="text" placeholder="Name" 
                               value={values.name} onChange={handleChange}/>
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Email:</label>
                              <input className="form-control" name="email" type="email" placeholder="Email" 
                               value={values.email} onChange={handleChange}/>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Phone:</label>
                              <input className="form-control" name="phone" type="text" placeholder="Phone" 
                               value={values.phone} onChange={handleChange}/>
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Address:</label>
                              <input className="form-control" name="username" type="text" placeholder="Address" 
                               value={values.address} onChange={handleChange}/>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="col-form-label" htmlFor="message-text">City:</label>
                              <input className="form-control" name="city" type="text" placeholder="City" 
                               value={values.city} onChange={handleChange}/>
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="col-form-label" htmlFor="message-text">State:</label>
                              <input className="form-control" name="state" type="text" placeholder="State" 
                               value={values.state} onChange={handleChange}/>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Country:</label>
                              <input className="form-control" name="country" type="text" placeholder="Country" 
                               value={values.country} onChange={handleChange}/>
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="col-form-label" htmlFor="message-text">GST:</label>
                              <input className="form-control" name="gst" type="text" placeholder="GST" 
                               value={values.gst} onChange={handleChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                    <Button color="secondary" onClick={addRow}>Cancel</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Users --> */}
            <Modal isOpen={modal} toggle={editRow} size="lg">
                <ModalHeader toggle={editRow}>Edit Group</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-4 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Name:</label>
                              <input className="form-control" name="name" type="text" placeholder="Name" 
                               value={data.name} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Email:</label>
                              <input className="form-control" name="email" type="email" placeholder="Email" 
                               value={data.email} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Phone:</label>
                              <input className="form-control" name="phone" type="text" placeholder="Phone" 
                               value={data.phone} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Address:</label>
                              <input className="form-control" name="username" type="text" placeholder="Address" 
                               value={data.address} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="col-form-label" htmlFor="message-text">City:</label>
                              <input className="form-control" name="city" type="text" placeholder="City" 
                               value={data.city} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="col-form-label" htmlFor="message-text">State:</label>
                              <input className="form-control" name="state" type="text" placeholder="State" 
                               value={data.state} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Country:</label>
                              <input className="form-control" name="country" type="text" placeholder="Country" 
                               value={data.country} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="col-form-label" htmlFor="message-text">GST:</label>
                              <input className="form-control" name="gst" type="text" placeholder="GST" 
                               value={data.gst} onChange={handleInputChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={ () => handleUpdate(data.id, data)} >Save Changes</Button>
                    <Button color="secondary" onClick={editRow}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default MyGroup;