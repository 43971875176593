import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, Briefcase, ThumbsUp, ThumbsDown  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import ThreeWayMatch from './ThreeWayMatch';
import GRNMatch from './GRNMatch';
import PendingGRNThreeWay from './PendingGRNThreeWay';
import PendingInvThreeWay from './PendingInvThreeWay';

const ThreeWayTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="3 Way Matching"/>
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Home />3 Way Match
                                </Tab>
                                <Tab>
                                    <Briefcase />GRN Matched
                                </Tab>
                                <Tab>
                                    <Briefcase />Pending GRN 3-Way
                                </Tab>
                                <Tab>
                                    <Briefcase />Pending Invoice Items 3-Way
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <ThreeWayMatch />
                                </TabPanel>
                                <TabPanel>
                                    <GRNMatch />
                                </TabPanel>
                                <TabPanel>
                                    <PendingGRNThreeWay />
                                </TabPanel>
                                <TabPanel>
                                    <PendingInvThreeWay />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

export default ThreeWayTab;