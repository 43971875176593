import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Autocomplete} from '@material-ui/lab';
import { TextField, MenuItem, FormHelperText, TablePagination } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { getInvoice, editInvoice, deleteInvoice, postInvoice, getInvoiceItems, uploadInvoice } from '../../actions/asyncActions';

const InvoiceReview = (props) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [invoice, setInvoice] = useState([]);
  const filterInvoice = invoice.filter(x=>x.status === 'Processed');
  const [invoiceItems, setInvoiceItems] = useState([]);

  const [values, setValues] = useState({});
  const [invoiceDate, handleInvoiceDate] = useState(new Date());
  const [invoicefile, setInvoiceFile] = useState([]);
  const [fields, setFields] = useState([{ description: '', hsn:'', gst:'', length:'', width:'', nos:'', qty:'', rate:'', amount:'' }]);
  
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  function addClick(){
    const values = [...fields];
    values.push({ description: '', hsn:'', gst:'', length:'', width:'', nos:'', qty:'', rate:'', amount:'' });
    setFields(values);
    }

  function handleRemove(i){
    const values = [...fields];
    values.splice(i,1);
    setFields(values);
  }

  function handleChange3(i,e){
    const tempValues = [...fields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFields(tempValues);
  }

  const handleUploadInvoice = async (event) => {
    setInvoiceFile(document.getElementById('invoicefile').files[0]);
  }
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect( () => {
    getInvoice(group_id)
     .then(resp => setInvoice(resp.data))
  }, []);

  useEffect( () => {
    getInvoiceItems(group_id)
     .then(resp => setInvoiceItems(resp.data))
  }, []);
  
  const viewRow = (n) => {
   const id = n.id;
   const invoiceNo = n.invoice_no;
   const invoiceDate = n.invoice_date;
   const custName = n.customer_name;
   const email = n.email_address;
   const website = n.customer_website;
   const amount = n.invoice_amount;
   const invFile = n.inv_file;
   const invExt = n.inv_ext;
   const remarks = n.remarks;
   const vendor_name = n.vendor_id;
   const temp_id = n.temp_id;
   const filterInvItems = invoiceItems.filter(x=>x.invoice_id === n.id);
   props.history.push(`${process.env.PUBLIC_URL}/mviewinvoice`, 
    {id,invoiceNo,invoiceDate,custName,email,website,amount,invFile,invExt,filterInvItems,remarks,vendor_name,temp_id});
  }

  const deleteRow = (n) => {
    const deleteId = {
      id : n.id,
      temp_id: n.temp_id
    }
    deleteInvoice(deleteId)
      .then(resp => {
        getInvoice(group_id)
        .then(resp => setInvoice(resp.data))
        toast.error("Invoice deleted!!!")
      })
      .catch(err => {
        console.log("Error", err);
    })
  }

  const [invoicemodal, setInvoiceModal] = useState()
  const addInvoice = () => {
    setInvoiceModal(!invoicemodal);
  }

  const handleSubmit = async(event) => {
    const formData = new FormData()
    formData.append( 'file', invoicefile )
    const inv = await uploadInvoice(formData);
    const invpath = "/Data/INVOICE/RawData/PDFData/" + inv.data.filename;
    const invExt = inv.data.mimetype;

    let fieldsComp = fields;
    const arr = [];
    fieldsComp.forEach(item => {
      arr.push({ 
        description:item.description, hsn:item.hsn, gst:item.gst, length:item.length, width:item.width, nos:item.nos, 
        qty:item.qty, rate:item.rate, amount:item.amount
      })
    });

    const data = {
      invoice_no: values.invoice_no,
      invoice_date: invoiceDate,
      customer_name: values.customer_name,
      email_address: values.email_address,
      customer_website: values.customer_website,
      invoice_amount: values.invoice_amount,
      inv_file: invpath,
      inv_ext: invExt,
      status: "Processed",
      user_id: user.id,
      group_id: group_id,
    }
    const dataItems = {
      items: arr,
      user_id: user.id,
      group_id: group_id,
    }
    postInvoice({data, dataItems})
    .then(resp => {
      getInvoice(group_id)
      .then(resp => setInvoice(resp.data))
      setValues({invoice_no:'', invoice_date:'', customer_name:'', customer_website:'', email_address:'', invoice_amount:''})
      setInvoiceModal(!invoicemodal)
      toast.success("Invoice added successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
    })
    .catch(err => {
      console.log("Error", err);
    }) 
  }

    return (
      <Fragment>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-info">
                                            <tr>
                                              <th scope="col">#</th>
                                              <th scope="col">Invoice no</th>
                                              <th scope="col">Invoice Date</th>
                                              <th scope="col">Customer</th>
                                              <th scope="col">Invoice Amount</th>
                                              <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterInvoice.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((n, i)=> {
                                              return (
                                                <tr>
                                                 <th scope="row">{i+1}</th>
                                                 <td>{n.invoice_no}</td>
                                                 <td>
                                                  {new Date(n.invoice_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                 </td>
                                                 <td>{n.customer_name}</td>
                                                 <td>{n.invoice_amount}</td>
                                                 <td>
                                                   <button>
                                                    <i className="fa fa-eye" onClick={() => viewRow(n)} id="TooltipTop" data-placement="top"
                                                     style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      View Invoice
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </button>
                                                   <button>
                                                   <i className="fa fa-trash-o" onClick={() => deleteRow(n)} id="TooltipTop1" data-placement="top"
                                                   style={{ width: 35, fontSize: 18, padding: 11, color: '#e6152a' }}>
                                                   <UncontrolledTooltip placement="top" target="TooltipTop1">
                                                    Delete Invoice
                                                   </UncontrolledTooltip>
                                                  </i>
                                                  </button>
                                                 </td>
                                                </tr>
                                              );
                                            })
                                          }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-block row">
                                  <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                   <button className="btn btn-info btn-sm" type="button" onClick={addInvoice}>
                                     Add Invoice
                                    </button>
                                  </div>
                                  <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <TablePagination
                                      rowsPerPageOptions={[5, 10, 25]}
                                      component="div"
                                      count={filterInvoice.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onChangePage={handleChangePage}
                                      onChangeRowsPerPage={handleChangeRowsPerPage}
                                     />
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      {/* < !-- Using Modal for Adding Invoice Details Manually --> */}
            <Modal isOpen={invoicemodal} toggle={addInvoice} size="xl">
                <div className="media m-t-15">
                 <div className="media-body">
                  <h4 className="f-w-600 text-center">
                      <u>Add Invoice Details</u>
                  </h4>
                 </div>
                </div>
                <ModalBody>
                    <form>
                     <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <label className="col-form-label" htmlFor="recipient-name">Invoice No:</label>
                        <input className="form-control" type="text" name="invoice_no" onChange={handleChange} value={values.invoice_no}/>
                       </div>
                       <div className="col-sm-6 col-md-6 col-lg-6">
                        <label className="col-form-label" htmlFor="message-text">Invoice Date:</label>
                         <DatePicker className="form-control digits"
                          selected={invoiceDate}
                          value={invoiceDate}
                          onChange={handleInvoiceDate}
                          dateFormat="dd/MM/yyyy"
                         />
                        </div>
                       <div className="col-sm-6 col-md-6 col-lg-6">
                        <label className="col-form-label" htmlFor="recipient-name">Customer Name:</label>
                        <input className="form-control" type="text" name="customer_name" onChange={handleChange} value={values.customer_name}/>
                       </div>
                       <div className="col-sm-6 col-md-6 col-lg-6">
                        <label className="col-form-label" htmlFor="recipient-name">Email:</label>
                        <input className="form-control" type="text" name="email_address" onChange={handleChange} value={values.email_address}/>
                       </div>
                       <div className="col-sm-6 col-md-6 col-lg-6">
                        <label className="col-form-label" htmlFor="recipient-name">Website:</label>
                        <input className="form-control" type="text" name="customer_website" onChange={handleChange} value={values.customer_website}/>
                       </div>
                       <div className="col-sm-6 col-md-6 col-lg-6">
                        <label className="col-form-label" htmlFor="recipient-name">Total Amount:</label>
                        <input className="form-control" type="text" name="invoice_amount" onChange={handleChange} value={values.invoice_amount}/>
                       </div>
                       <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="col-form-label" htmlFor="recipient-name">Attach File:</label>
                        <input className="form-control" id="invoicefile" type="file" onChange={handleUploadInvoice} />
                       </div>
                       <div className="col-sm-12 col-md-12 col-lg-12 m-t-15">
                        <div className="media">
                         <div className="media-body">
                          <h4 className="f-w-600 text-center">
                              <u>Item Details</u>
                          </h4>
                         </div>
                        </div>
                        {fields.map((el, i)=> {
                          return(
                           <div key={i} className="row p-1">
                            <div className="col-sm-2">
                             <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.description || ''} 
                              id="description" name="description" label="Description"/>
                            </div>
                            <div className="col-sm-1">
                             <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.hsn || ''} 
                              id="hsn" name="hsn" label="HSN"/>
                            </div>
                            <div className="col-sm-1">
                             <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.gst || ''} 
                              id="gst" name="gst" label="GST"/>
                            </div>
                            <div className="col-sm-1">
                             <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.length || ''} 
                              id="length" name="length" label="length"/>
                            </div>
                            <div className="col-sm-1">
                             <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.width || ''} 
                              id="width" name="width" label="Width"/>
                            </div>
                            <div className="col-sm-1">
                             <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.nos || ''} 
                              id="nos" name="nos" label="Nos"/>
                            </div>
                            <div className="col-sm-2">
                             <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.qty || ''} 
                              id="qty" name="qty" label="Qty"/>
                            </div>
                            <div className="col-sm-1">
                             <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.rate || ''} 
                              id="rate" name="rate" label="Rate"/>
                            </div>
                            <div className="col-sm-1">
                             <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.amount || ''} 
                              id="amount" name="amount" label="Amount"/>
                            </div>
                            <div className="col-sm-1 m-t-15">
                              <Button size="large" color="danger" variant="contained" onClick={() => handleRemove(i)}>
                                X 
                              </Button>
                            </div>
                           </div>
                          );
                         })
                        }
                        <Button size="medium" color="primary" variant="contained" onClick={() => addClick()}>
                          Add 
                        </Button>
                       </div>
                     </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={addInvoice}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit}>Submit</Button>
                </ModalFooter>
            </Modal>
      </Fragment>
    );
}

export default withRouter(InvoiceReview);