import React, { Fragment, useState, useEffect } from 'react';
import { TablePagination, TextField } from '@material-ui/core';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import { toast } from 'react-toastify';
import {getBookLeave, editBookLeave, editEmployee, getEmployee } from '../../../actions/asyncActions';

const LeaveApproval = () => {
	const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const userRole = user.role;

  const [bookLeave, setBookLeave] = useState([]);
  const [employees, setEmployees] = useState([]);

  const filterLeave = bookLeave.filter(x=>x.emp_id === user.emp_id)
  const filterBookLeave = userRole === 'Employee' ? filterLeave : bookLeave;

  const reverseBookLeave = filterBookLeave.sort((a,b) => b.id - a.id)
  console.log("reverseBookLeave", reverseBookLeave)

	const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect( () => {
      getBookLeave(group_id)
        .then(resp => setBookLeave(resp.data))
  }, []);

  useEffect( () => {
      getEmployee(group_id)
        .then(resp => setEmployees(resp.data))
  }, []);

  const approveLeave = n => {
      const empFilter = employees.find(x=>x.id === n.emp_id)
      var medAvailed = empFilter && empFilter.med_availed || 0;
      var casAvailed = empFilter && empFilter.cas_availed || 0;
      var maternityAvailed = empFilter && empFilter.maternity_availed || 0;

      var addMedical = n.type === "Medical Leave" ? medAvailed + n.total : medAvailed;
      var addCasual = n.type === "Casual Leave" ? casAvailed + n.total : casAvailed;
      var addMaternity = n.type === "Maternity/Paternity Leave" ? maternityAvailed + n.total : maternityAvailed;
      const data = {
        id: n.id,
        status: 'Approved',
        user_id: user.id
      }
      const empData = {
          id: n.emp_id,
          med_availed: addMedical,
          cas_availed: addCasual,
          maternity_availed: addMaternity,
          user_id: user.id
      }
      console.log("empData", empData)
      editBookLeave(data)
      .then(resp => {
          editEmployee(empData)
          .then(resp => {
              getBookLeave(group_id)
              .then(resp => setBookLeave(resp.data))
              toast.success("Leave Approved Successfully!!!")
          })
      })
  }

  const [rejectmodal, setRejectModal] = useState(false)
  const [rejectId, setRejectId] = useState([])
  const [reason, setReason] = useState("")

  const rejectRow = n => {
    setRejectId(n.id)
    setRejectModal(!rejectmodal)
  }

  const rejectLeave = n => {
    const data = {
      id: rejectId,
      status: 'Rejected',
      reason: reason,
      user_id: user.id
    }
    editBookLeave(data)
    .then(resp => {
      getBookLeave(group_id)
      .then(resp => setBookLeave(resp.data))
      toast.info("Leave Rejected !!!")
      setRejectModal(!rejectmodal)
    })
  }
	
	return (
      <Fragment>
		    <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover">
                                        <thead className="table-primary">
                                            <tr>
                                              <th scope="col">Emp ID</th>
                                              <th scope="col">Type</th>
                                              <th scope="col">From</th>
                                              <th scope="col">To</th>
                                              <th scope="col">Total Days</th>
                                              <th scope="col">Description</th>
                                              <th scope="col">Status</th>
                                              <th scope="col">Reason</th>
                                              {userRole === 'Employee' ?
                                                null
                                                :
                                                <th scope="col">Action</th>
                                              }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterBookLeave.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((n, i)=> {
                                              const aaa = employees.find(x=>x.id === n.emp_id);
                                              const empId = aaa && aaa.employee_id;
                                              const empName = aaa && aaa.name;
                                              return (
                                                <tr>
                                                 <th scope="row">
                                                  {empId}<br />
                                                  <small>{empName}</small>
                                                 </th>
                                                 <td>{n.type}</td>
                                                 <td>
                                                  {new Date(n.start_date).toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                                 </td>
                                                 <td>
                                                  {new Date(n.end_date).toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                                 </td>
                                                 <td>{n.total}</td>
                                                 <td>{n.description}</td>
                                                 <td>{n.status}</td>
                                                 <td>{n.reason}</td>
                                                { n.status === 'Pending' && userRole !== 'Employee' ?
                                                 <td>
                                                  <i className="fa fa-thumbs-up" onClick={() => approveLeave(n)} id="TooltipTop" data-placement="bottom"
                                                    style={{ width: 35, fontSize: 16, padding: 11, color: 'green' }}>
                                                    <UncontrolledTooltip placement="bottom" target="TooltipTop">
                                                      Approve Leave
                                                    </UncontrolledTooltip>
                                                  </i>
                                                  <i className="fa fa-thumbs-down" onClick={() => rejectRow(n)} id="TooltipTop1" data-placement="bottom"
                                                    style={{ width: 35, fontSize: 16, padding: 11, color: 'red' }}>
                                                    <UncontrolledTooltip placement="bottom" target="TooltipTop1">
                                                      Reject Leave
                                                    </UncontrolledTooltip>
                                                  </i>
                                                 </td>
                                                 : n.status === 'Rejected' && userRole !== 'Employee' ?
                                                 <td>
                                                  <i className="fa fa-thumbs-up" onClick={() => approveLeave(n)} id="TooltipTop" data-placement="bottom"
                                                    style={{ width: 35, fontSize: 16, padding: 11, color: 'green' }}>
                                                    <UncontrolledTooltip placement="bottom" target="TooltipTop">
                                                      Approve Leave
                                                    </UncontrolledTooltip>
                                                  </i>
                                                 </td>
                                                 : n.status === 'Approved' ?
                                                 null
                                                 : userRole === 'Employee' ?
                                                 null :
                                                 null
                                                }
                                                </tr>
                                              );
                                            })
                                          }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                             <TablePagination
                               rowsPerPageOptions={[5, 10, 25]}
                               component="div"
                               count={filterBookLeave.length}
                               rowsPerPage={rowsPerPage}
                               page={page}
                               onChangePage={handleChangePage}
                               onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        {/* Reject Leave Modal */}
			<Modal isOpen={rejectmodal} toggle={rejectRow} centered={true}>
			 <ModalHeader toggle={rejectRow}>Reason for the Rejection</ModalHeader>   
			  <ModalBody>
				<div className="form-row text-center">
					<div className="col-xl-12">
					<label className="col-form-label" htmlFor="message-text">
						<b>Please mention the reason for rejecting the leave:</b>
					</label>
					<TextField multiline label="Reason" variant="outlined" value={reason} 
						onChange={(e) => setReason(e.target.value)} rows={6} fullWidth/>
					</div>
					<div className="col-xl-12 m-t-20">
						<button className="btn btn-success" onClick={() => rejectLeave()}>
							Confirm
						</button>
					</div>
				</div>
			  </ModalBody>
			</Modal>

      </Fragment>
	)	
}

export default LeaveApproval;