import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getVendorForm, getIndentItems, getProjects, getGroup } from '../../actions/asyncActions';
import * as moment from 'moment';

export default function MaterialTableDemo(props) {
  
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const [vendors, setVendors] = useState([]);
  const [indentItems, setIndentItems] = useState([]);
  const [projects, setProject] = useState([]);
  var numberToText = require('number2text');
    
  useEffect( () => {
    getVendorForm(group_id)
    .then(resp => setVendors(resp.data))
  }, []); 

  useEffect( () => {
    getIndentItems(group_id)
    .then(resp => setIndentItems(resp.data))
  }, []);

  useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
  }, []);

  const filterIndentItems = indentItems.filter
  (x=> x.project_id === props.selectedProject && x.vendor_id === props.selectedVendor && x.status === "VendorSelected");
  const getTotal = filterIndentItems.reduce((a, b) => a + b.total, 0);
  const totalInWords = numberToText(getTotal,'', true);

  const proName = projects.find(x=>x.id === props.selectedProject);
  const projectName = proName && proName.name;

  const POItems = () => {
    return filterIndentItems.map((n , i) => {
      return(
      <tr key={i}>
        <td>{i+1}</td>
        {(n.part_name && n.material) ?
          <td>{(n.part_name).concat(`(${n.material})`)}</td>
          :
          <td>{n.part_name}</td>
        }
        <td>{moment(n.date).format('ll')}</td>
        <td>{n.qty}</td>
        <td>{n.price}</td>
        <td>{''}</td>
        <td>{n.discount}</td>
        <td>{n.total}</td>
      </tr>
    );
  });
  }

  const {selectedProject, selectedVendor, placeSupply, dispatch, lastPO } = props;
  const datee = new Date();
  const date = moment(datee).format('ll');
  const vendorAddress = vendors.find(x=>x.id === props.selectedVendor);
  const supplier = vendorAddress && vendorAddress.supname;
  const address = vendorAddress && vendorAddress.factAddress;
  const gstno = vendorAddress && vendorAddress.gstno;
  const poitems = POItems();

  return (
    <div id="printreceipt">
     <section className="sheet padding-5mm">
      <table style={{width:'95%'}}>
        <tr> 
          <td style={{textAlign:"center"}}>
           <h2>Purchase Order</h2>
          </td>
        </tr>
      </table>
      <table style={{width:'95%'}}>
      <tr>
      <td>
      <table style={{textAlign:'left', width:'50%'}}>
       <tr>
        <td style={{textAlign:'left', border:'1px solid'}}>
         <pre> 
          <b>Invoice To: <br/>
          VIGILENZ MEDICAL DEVICES SDN. BHD.,</b> <br/>
          Survey No. 8/2, <br/>
          Honnasandra Village Kasaba Hobli, Nelmangala Taluk <br/>
          Bengaluru Rural District - 562123, Karnataka <br/>
          Contact: 080- 27738318 / 319 / 320 <br/>
          GSTIN: 29AAACT6674J1ZS <br/>
          State Name: Karnataka, Code: 29 <br/>
          CIN: U74200KA1000PT0015003 <br/>
          E-Mail: care@nu-pie.com
         </pre>
        </td>
       </tr>
      </table>
      </td>
      <td>
      <table style={{textAlign:'right', width:'50%', border:'1px solid'}}>
       <tr>
        <td style={{textAlign:'left'}}>
         <pre>
          Voucher No:<br/>
          <b>{("PO-000").concat(props.lastPO)}</b>
         </pre>
        </td>
        <td style={{textAlign:'left'}}>
          <pre>
           Dated:<br/>
           <b>{date}</b>
          </pre>
        </td>
       </tr>
       <tr>
        <td style={{textAlign:'left'}}>
         <pre>
          Vendor:<br/>
          <b>{supplier}</b>
         </pre>
        </td>
        <td style={{textAlign:'left'}}>
          <pre>
           Mode/Terms of Payment<br/>
           <b>As per Annexure</b>
          </pre>
        </td>
       </tr>
       <tr>
        <td style={{textAlign:'left'}}>
         <pre>
          Suppliers Ref/Order No. <br/>
           <b>Old Reference</b>
         </pre>
        </td>
        <td style={{textAlign:'left'}}>
          <pre>
           Other Reference(s)<br/>
           <b>{projectName}</b>
          </pre>
        </td>
       </tr>
       <tr>
        <td style={{textAlign:'left'}}>
         <pre>
          Dispatch through<br/>
          <b>{props.dispatch}</b>
         </pre>
        </td>
        <td style={{textAlign:'left'}}>
          <pre>
           Destination<br/>
           <b>{props.placeSupply}</b>
          </pre>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     </table>
     <table style={{textAlign:'center', width:'95%', border:'1px solid'}}>
      <tr>
        <td style={{textAlign:'left'}}>
         <pre>
          Terms of Delivery<br/>
       <b>Items can be accepted based on the inspection from our QA<br/>
          if any defects found, item should be replaced free of cost immediately</b>
         </pre>
        </td>
      </tr>
     </table>
     <table style={{width:'95%', border: '1px solid'}}>
       <thead>
        <tr>              
         <th align="left">Sl.No</th>
         <th align="left">Description of Goods</th>
         <th align="left">Due on</th>
         <th align="left">Quantity</th>
         <th align="left">Rate</th>
         <th align="left">per</th>
         <th align="left">Disc%</th>
         <th align="left">Amount</th>
        </tr>
       </thead>
       <tbody>
         {poitems}
       </tbody>
     </table>
     <table style={{width:'95%', border: '1px solid'}}>
      <tr>
       <td colSpan="5" align="center"><i>Input GST@ 18%</i></td>
      </tr>
     </table>
     <table style={{width:'95%', border: '1px solid'}}>
      <tr>
       <td colSpan="5" align="right">Grand Total: <b>{getTotal} INR</b></td>
      </tr>
     </table>
     <table style={{width:'95%'}}>
      <tr>
       <td align="left">
       <p>Amount Chargeable (in words) <br/>
       <b>{totalInWords}</b>
       </p>
       </td>
      </tr>
     </table>

     </section>
    </div>
  );
}