import React, { Fragment, useState, useEffect, useRef } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Send } from 'react-feather';
//import Chats from '../../pages/chat/chats';
import ScrollToBottom from 'react-scroll-to-bottom';
import io from "socket.io-client";
import Messages from '../../pages/chat/Messages';
import '../../pages/chat/chats.css';
import { getAllUser } from '../../actions/asyncActions.js';

let socket;

const ThemeCustomizer = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const user_id = user && user.id;
    const group_id = user && user.group_id;
    const userfname = user && user.firstname;
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const filterUsers = allUsers.filter(x => x.id !== user_id);
    const [clickedUser, setClickedUser] = useState('');
    const aaa = allUsers.find(x => x.id == clickedUser);
    const filterClickedUser = aaa && aaa.firstname;
    //const ENDPOINT = 'http://127.0.0.1:4000';
    //const ENDPOINT = 'http://172.105.41.149:4000';
    //const ENDPOINT = 'https://testerp.nu-pie.com';
    //const ENDPOINT = '172.105.41.149:4000'
    const filterMessages = messages.filter(x => (x.sender_id == user_id || x.receiver_id == user_id)
        && (x.sender_id == clickedUser || x.receiver_id == clickedUser)
        && x.group_id == group_id);
    //const filterMessages = messages.filter(x=>x.sender_id == user_id && x.receiver_id == clickedUser && x.group_id === group_id);
    // console.log("filterMessages", filterMessages)
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [filterMessages]);

    useEffect(() => {
        getAllUser(group_id)
            .then(resp => setAllUsers(resp.data))
    }, []);

    // useEffect(() => {
    //   //connect to socket.io//
    //   socket = io(ENDPOINT);

    //   //check for connection
    //   if(socket !== undefined){
    //    console.log('connected to socket...');
    //   }
    // }, [ENDPOINT]);

    // useEffect(() => {
    //    socket.on('output', msg => {
    //     setMessages(...messages, msg);
    //    });
    // }, []);

    // const sendMessage = (event) => {
    //    event.preventDefault();
    //    if(!message) {
    //     toast.warn("Please enter message", {position: toast.POSITION.TOP_CENTER, autoClose:2000})
    //    }
    //    else {
    //     socket.emit('input', {
    //       sender_id: user_id,
    //       receiver_id: clickedUser,
    //       name: userfname, 
    //       message: message,
    //       group_id: group_id
    //     });
    //     setMessage('');
    //    }
    // }

    const sendMessage = (event) => {

    }

    const openChatModel = (n) => {
        setClickedUser(n.id);
        setActiveTab1('2');
    }

    const primary_color = localStorage.getItem('primary_color');
    const secondary_color = localStorage.getItem('secondary_color');
    const layout_version = localStorage.getItem('layout_version');
    const sidebar_type = localStorage.getItem('wrapper')
    const body_sidebar_type = localStorage.getItem('bodyWrapper');
    const [modal, setModal] = useState();
    const [rightSidebar, setRightSidebar] = useState(true);
    const [activeTab, setActiveTab] = useState('1');
    const [activeTab1, setActiveTab1] = useState('1');

    const configDB = useSelector(content => content.Customizer.customizer);
    const color = localStorage.getItem('color')

    const mix_layout = configDB.color.mix_layout;
    const [layout_type, setLayout_type] = useState(configDB.settings.layout_type);
    const dispatch = useDispatch();
    const config_primary = configDB.color.primary_color;
    const config_secondary = configDB.color.secondary_color;
    const config_color = configDB.color.color;
    const config_layout_version = configDB.color.layout_version;
    configDB.settings.sidebar.wrapper = sidebar_type;
    configDB.settings.sidebar.bodyWrapper = body_sidebar_type;

    useEffect(() => {
        dispatch({ type: 'ADD_COSTOMIZER' });

        dispatch({
            type: 'ADD_COLOR',
            payload: {
                color,
                primary_color,
                secondary_color,
                layout_version,
            }
        })

        //set layout_type
        document.body.setAttribute('main-theme-layout', layout_type);
        document.documentElement.dir = layout_type;

        //set sidebar wrapper
        if (sidebar_type === null && body_sidebar_type === null) {
            document.querySelector(".page-wrapper").className = 'page-wrapper ' + configDB.settings.sidebar.wrapper;
            document.querySelector(".page-body-wrapper").className = 'page-body-wrapper ' + configDB.settings.sidebar.bodyWrapper;
        } else {
            document.querySelector(".page-wrapper").className = 'page-wrapper ' + sidebar_type;
            document.querySelector(".page-body-wrapper").className = 'page-body-wrapper ' + body_sidebar_type;
        }

        //set sidebar setting
        document.querySelector(".page-sidebar").setAttribute('sidebar-layout', configDB.settings.sidebar_setting);

        //set colors
        document.body.className = mix_layout;
        document.documentElement.className = color;

        if (localStorage.getItem('primary_color') == null || localStorage.getItem('secondary_color') == null || localStorage.getItem('color') == null || localStorage.getItem('layout_version') == null) {
            document.documentElement.className = config_color;
            localStorage.setItem('primary_color', config_primary)
            localStorage.setItem('secondary_color', config_secondary);
            localStorage.setItem('color', config_color);
            localStorage.setItem('layout_version', config_layout_version)
            dispatch({
                type: 'ADD_COLOR',
                payload: {
                    color: config_color,
                    primary_color: config_primary,
                    secondary_color: config_secondary,
                    layout_version: config_layout_version
                }
            })
        }

        if (sidebar_type === 'compact-wrapper' || configDB.settings.sidebar.wrapper === 'compact-wrapper') {
            document.querySelector(".compactLogo").className = 'compactLogo show';
        } else {
            document.querySelector(".compactLogo").className = 'compactLogo hide';
        }
        // eslint-disable-next-line
    }, []);

    const toggle = () => {
        setModal(!modal)
    }

    const openCustomizer = () => {
        if (rightSidebar) {
            setRightSidebar(!rightSidebar)
            document.querySelector(".customizer-contain").classList.add('open');
            document.querySelector(".customizer-links").classList.add('open');
        }
    }

    const closeCustomizer = () => {
        setRightSidebar(!rightSidebar)
        document.querySelector(".customizer-contain").classList.remove('open');
        document.querySelector(".customizer-links").classList.remove('open');
    }

    const handleSidebarColor = (e) => {
        document.querySelectorAll(".sidebar-bg-settings li").forEach((item) => {
            item.classList.remove('active');
        });
        document.querySelector(".page-sidebar").className = 'page-sidebar ' + e.target.classList.value;
        e.target.classList.add('active');
    }

    const handleLayout = (layout) => {
        setLayout_type(layout)
        document.querySelectorAll(".main-layout li").forEach((item) => {
            item.classList.remove('active');
        });
        document.body.setAttribute('main-theme-layout', layout);
        document.documentElement.dir = layout;
        dispatch({ type: 'ADD_LAYOUT', payload: layout });
    }

    const handleSidebarSetting = (e) => {
        e.preventDefault();
        document.querySelectorAll(".sidebar-setting li").forEach((item) => {
            item.classList.remove('active');
        });
        document.querySelector(".page-sidebar").setAttribute('sidebar-layout', e.currentTarget.getAttribute('data-attr'));
        e.currentTarget.classList.add('active');
        dispatch({ type: 'ADD_SIDEBAR_SETTINGS', payload: e.currentTarget.getAttribute('data-attr') })
    }

    const handleCustomizerMix = (e) => {
        e.preventDefault();
        document.querySelectorAll(".customizer-mix li").forEach((item) => {
            item.classList.remove('active');
        });
        document.body.className = e.currentTarget.getAttribute('data-attr');
        e.currentTarget.classList.add('active');
        dispatch({ type: 'ADD_MIXlAYOUT', payload: e.currentTarget.getAttribute('data-attr') });
    }

    const handleSidebarType = (e, wrapper, bodyWrapper) => {
        e.preventDefault();
        document.querySelectorAll(".sidebar-type li").forEach((item) => {
            item.classList.remove('active');
        });
        document.querySelector(".page-wrapper").className = 'page-wrapper ' + wrapper;
        document.querySelector(".page-body-wrapper").className = 'page-body-wrapper ' + bodyWrapper;
        e.currentTarget.classList.add('active');
        dispatch({ type: 'ADD_SIDEBAR_TYPES', payload: { wrapper, bodyWrapper } })

        localStorage.setItem('wrapper', wrapper);
        localStorage.setItem('bodyWrapper', bodyWrapper);
        window.location.reload();
    }

    const colorChangeTheme = (value) => {

        if (value === 'light-1') {
            localStorage.setItem('color', 'color-1');
            localStorage.setItem('layout_version', 'light');
            localStorage.setItem('primary_color', '#4466f2');
            localStorage.setItem('secondary_color', '#1ea6ec');
        } if (value === 'light-2') {
            localStorage.setItem('color', 'color-2');
            localStorage.setItem('layout_version', 'light');
            localStorage.setItem('primary_color', '#0288d1');
            localStorage.setItem('secondary_color', '#26c6da');
        } if (value === 'light-3') {
            localStorage.setItem('color', 'color-3');
            localStorage.setItem('layout_version', 'light');
            localStorage.setItem('primary_color', '#8e24aa');
            localStorage.setItem('secondary_color', '#ff6e40');
        } if (value === 'light-4') {
            localStorage.setItem('color', 'color-4');
            localStorage.setItem('layout_version', 'light');
            localStorage.setItem('primary_color', '#4c2fbf');
            localStorage.setItem('secondary_color', '#2e9de4');
        } if (value === 'light-5') {
            localStorage.setItem('color', 'color-5');
            localStorage.setItem('layout_version', 'light');
            localStorage.setItem('primary_color', '#7c4dff');
            localStorage.setItem('secondary_color', '#7b1fa2');
        } if (value === 'light-6') {
            localStorage.setItem('color', 'color-6');
            localStorage.setItem('layout_version', 'light');
            localStorage.setItem('primary_color', '#3949ab');
            localStorage.setItem('secondary_color', '#4fc3f7');
        } if (value === 'dark-1') {
            localStorage.setItem('color', 'color-1');
            localStorage.setItem('layout_version', 'dark-only');
            localStorage.setItem('primary_color', '#4466f2');
            localStorage.setItem('secondary_color', '#1ea6ec');
        } if (value === 'dark-2') {
            localStorage.setItem('layout_version', 'dark-only');
            localStorage.setItem('primary_color', '#0288d1');
            localStorage.setItem('secondary_color', '#26c6da');
            localStorage.setItem('color', 'color-2');
        } if (value === 'dark-3') {
            localStorage.setItem('layout_version', 'dark-only');
            localStorage.setItem('primary_color', '#8e24aa');
            localStorage.setItem('secondary_color', '#ff6e40');
            localStorage.setItem('color', 'color-3');
        } if (value === 'dark-4') {
            localStorage.setItem('layout_version', 'dark-only');
            localStorage.setItem('primary_color', '#4c2fbf');
            localStorage.setItem('secondary_color', '#2e9de4');
            localStorage.setItem('color', 'color-4');
        } if (value === 'dark-5') {
            localStorage.setItem('layout_version', 'dark-only');
            localStorage.setItem('primary_color', '#7c4dff');
            localStorage.setItem('secondary_color', '#7b1fa2');
            localStorage.setItem('color', 'color-5');
        } if (value === 'dark-6') {
            localStorage.setItem('layout_version', 'dark-only');
            localStorage.setItem('primary_color', '#3949ab');
            localStorage.setItem('secondary_color', '#4fc3f7');
            localStorage.setItem('color', 'color-6');
        }
        window.location.reload();
    }

    return (
        <Fragment>
            <div className="customizer-links">
                <div className="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
                    <Nav tabs className="tab-list-bottom border-tab-primary">

                        <NavItem className="nav nav-tabs" id="myTab" role="tablist">

                            <NavLink className={activeTab1 == '1' ? 'active' : ''} onClick={() => setActiveTab1('1')}>
                                <div className="settings">
                                    <i className="icofont icofont-chat" onClick={openCustomizer}></i>
                                </div>
                            </NavLink>
                        </NavItem>
                        {/*
                        <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                            
                            <NavLink className={activeTab1 == '2' ? 'active' : ''} onClick={() => setActiveTab1('2')}>
                                <div className="settings color-settings">
                                    <i className="icofont icofont-color-bucket" onClick={openCustomizer}></i>
                                </div>
                            </NavLink>
                        </NavItem>
                    */}
                    </Nav>
                </div>
            </div>
            <div className="customizer-contain">
                <div className="tab-content" id="c-pills-tabContent">
                    <div className="customizer-header">
                        <i className="icon-close" onClick={closeCustomizer}></i>
                        <h5 style={{ textAlign: 'center' }}>Nu-Pie Chat</h5>
                    </div>
                    <div className="customizer-body outerContainer">
                        <TabContent activeTab={activeTab1}>
                            <TabPane tabId="1">
                                {/* List All Users */}
                                <div className="users">
                                    {filterUsers.map((n, i) => {
                                        return (
                                            <ol key={i}>
                                                <button type="button" className="btn btn-primary btn-block" style={{ height: '50px' }}
                                                    onClick={() => openChatModel(n)}>
                                                    {n.firstname}
                                                </button>
                                            </ol>
                                        );
                                    })
                                    }
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                {/*<Messages messages={messages} />*/}
                                {/* Message box */}
                                <div>
                                    <div className="card">
                                        <h5 className="card-header bg-success" onClick={() => setActiveTab1('1')}>
                                            {filterClickedUser}
                                        </h5>
                                        <div className="card-body messages innerContainer">
                                            <p className="mb-0">
                                                {filterMessages.map((n, i) => {
                                                    let isCurrentUser = false;
                                                    if (n.name == userfname) {
                                                        isCurrentUser = true;
                                                    }
                                                    return (
                                                        isCurrentUser
                                                            ? (
                                                                <div key={i}>
                                                                    <div className="messageContainer justifyEnd">
                                                                        <div className="messageBox backgroundBlue">
                                                                            <p className="messageText colorWhite">{n.message}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                            : (
                                                                <div key={i}>
                                                                    <div className="messageContainer justifyStart">
                                                                        <div className="messageBox backgroundLight">
                                                                            <p className="messageText colorDark">{n.message}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )

                                                    );
                                                })
                                                }
                                            </p>
                                            <div ref={messagesEndRef} />
                                        </div>
                                        <form className="form">
                                            <input
                                                className="input"
                                                type="text"
                                                placeholder="Type a message ..."
                                                value={message}
                                                onChange={(event) => setMessage(event.target.value)}
                                                onKeyPress={event => event.key == 'Enter' ? sendMessage(event) : null}
                                            />
                                            <button className="sendButton" onClick={(event) => sendMessage(event)}><Send /></button>
                                        </form>
                                    </div>
                                </div>
                                {/*
                          <p style={{textAlign:'center', background: '#32a873', height: '50px', fontSize:22}}>
                           <b>{filterClickedUser}</b>
                          </p>
                          <div className="messages">
                           {filterMessages.map((n,i) => {
                             let isCurrentUser = false;
                             if(n.name == userfname){
                                isCurrentUser = true;
                             }
                             return (
                              isCurrentUser
                              ? (
                                 <div key={i}>
                                   <div className="messageContainer justifyEnd">
                                    <div className="messageBox backgroundBlue">
                                     <p className="messageText colorWhite">{n.message}</p>
                                    </div>
                                   </div>
                                 </div>
                              )
                              : (
                                 <div key={i}>
                                   <div className="messageContainer justifyStart">
                                    <div className="messageBox backgroundLight">
                                     <p className="messageText colorDark">{n.message}</p>
                                    </div>
                                    <p className="sentText pl-10">{n.name}</p>
                                   </div>
                                 </div>
                              )
                              
                             );
                            })
                           }
                          </div>
                      */}
                                {/* Input box 
                          <form className="form">
                               <input 
                                className="input"
                                type="text"
                                placeholder="Type a message ..."
                                value={message} 
                                onChange={(event) => setMessage(event.target.value)}
                                onKeyPress={event => event.key == 'Enter' ? sendMessage(event) : null}
                               />
                               <button className="sendButton" onClick={(event) => sendMessage(event)}><Send /></button>
                          </form>
                          */}
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ThemeCustomizer