import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Button, Grid, Radio, RadioGroup, FormControl } from '@material-ui/core';
//import Switch from '@material-ui/core/Switch';
//import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import { KeyboardDatePicker } from '@material-ui/pickers';
//import InfoIcon from '@material-ui/icons/Info';
//import Delete from '@material-ui/icons/Delete';
//import { postVendorForm, getVendorForm, aadharUpload, panUpload }  from '../../actions/asyncActions';

 
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  table_root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  margin: {
    margin: theme.spacing(2),
  },
  errors: {
    color: 'red'
  },
  textfield: {
    padding: theme.spacing(1),
  }
}));

export default function VendorForm(props) {
  //const user = JSON.parse(localStorage.getItem("m_users"));
  //const company_id= user.company_id;
  //const group_id = user.group_id;
  const classes = useStyles();
  
  //const datee = new Date();
  //const [errors, setError] = useState({});
  //const [vendorForm, setVendorForm] = useState([]);
  //const [open, setOpen] = useState(false);
  

  // useEffect( () => {
  //   getVendorForm(group_id)
  //   .then(resp => {
  //     setVendorForm(resp.data);
  //   })
  // }, []);

  // const { inspectbrotout, testbrotout, inprocess, finproinspect, qualcertify, isoImage,
  //         isocertify, referredby, risk, scopesupply, custfields, values, radioVal } = props;
  const { custfields, values, radioVal } = props;


return (
 <React.Fragment>
    <Grid container spacing={3}>
     <Grid item xs={12}>
      <Typography gutterBottom>
        List of Customers( Give Details )
      </Typography>
      {custfields.map((el, i)=> {
        return(
        <div key={i} className={classes.textfield}>
          <Grid container spacing={6}>
           <Grid item xs={12} sm={4}>
            <TextField onChange={(e) => props.handleChangeCust(i, e)} value={el.customerlist || ''} id="customerlist" name="customerlist" label="Name"
             fullWidth autoComplete="customerlist" autoFocus/>
           </Grid>
           <Grid item xs={12} sm={2}>
            <Button size="large" color="secondary" variant="contained" onClick={() => props.handleRemoveCust(i)}>
              X 
            </Button>
           </Grid>
          </Grid>
        </div>
        );
      })}
      <Button size="medium" color="primary" variant="contained" onClick={() => props.addClickCust()}>
        Add 
      </Button>
    </Grid>
    <Grid item xs>
      <FormControl component="fieldset" className={classes.formControl}>
        <Typography gutterBottom>
          Do You Inspect Brought-out Materials?
        </Typography>
        <RadioGroup row aria-label="inspectbrotout" name="inspectbrotout" value={radioVal.inspectbrotout} onChange={props.handleRadioChange} fullWidth>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      { radioVal.inspectbrotout === 'yes' ?
        <Grid item xs={12}>
          <TextField multiline id="inspectbrotoutyes" name="inspectbrotoutyes"  label="Give Details" value={values.inspectbrotoutyes} 
           onChange={props.handleChange} fullWidth />
        </Grid>
        : null
      }
    </Grid>
    <Grid item xs>
      <FormControl component="fieldset" className={classes.formControl}>
        <Typography gutterBottom>
          Is In-Process Inspection Done During Manufacturing?  
        </Typography>
        <RadioGroup row aria-label="inprocess" name="inprocess" value={radioVal.inprocess} onChange={props.handleRadioChange} fullWidth>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </Grid>

    <Grid item xs={12}>
      <FormControl component="fieldset" className={classes.formControl}>
        <Typography gutterBottom>
          Do You Have Testing Facilities For Brought-out Material? If Not, What is Done for Testing? ( Give Details )
        </Typography>
        <RadioGroup row aria-label="testbrotout" name="testbrotout" value={radioVal.testbrotout} onChange={props.handleRadioChange} fullWidth>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    {radioVal.testbrotout === 'no' ?
      <Grid item xs={12}>
      <TextField multiline id="testbrotoutno" name="testbrotoutno"  label="Give Details" value={values.testbrotoutno} 
       onChange={props.handleChange} fullWidth />
      </Grid>
      :
      <Grid item xs={12}>
      <TextField multiline id="testbrotoutyes" name="testbrotoutyes" label="Give Details" value={values.testbrotoutyes} 
       onChange={props.handleChange} fullWidth />
      </Grid>
    }
    </Grid>
    <Grid item xs={12}>
     <FormControl component="fieldset" className={classes.formControl}>
        <Typography gutterBottom>
          Do You Have Finished Product Inspection Setup?
        </Typography>
        <RadioGroup row aria-label="finproinspect" name="finproinspect" value={radioVal.finproinspect} onChange={props.handleRadioChange} fullWidth>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl component="fieldset" className={classes.formControl}>
        <Typography gutterBottom>
          Can You Comply With The Quality Certificate Whenever Called For ? ( Self Certification )
        </Typography>
        <RadioGroup row aria-label="qualcertify" name="qualcertify" value={radioVal.qualcertify} onChange={props.handleRadioChange} fullWidth>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl component="fieldset" className={classes.formControl}>
        <Typography gutterBottom>
          Is Supplier An ISO 9001 / 14001 Certified? (If Yes, Attach The Copy Of Relevant Certificate)
        </Typography>
        <RadioGroup row aria-label="isocertify" name="isocertify" value={radioVal.isocertify} onChange={props.handleRadioChange} fullWidth>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
          { radioVal.isocertify === 'yes' ?
            <Typography gutterBottom>
             <input id="isofile" type="file" variant="outlined" onChange={props.handleUploadISO} /> 
            </Typography>
            
            : null
          }
    </Grid>
    <Grid item xs={12}>
      <FormControl component="fieldset" className={classes.formControl}>
        <Typography gutterBottom>
          Is Supplier Referred By The Customer ? ( If So Give Details )
        </Typography>
        <RadioGroup row aria-label="referredby" name="referredby" value={radioVal.referredby} onChange={props.handleRadioChange} fullWidth>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      {radioVal.referredby === 'yes' ?
        <TextField multiline id="referredbyyes" name="referredbyyes" label="Give Details" value={values.referredbyyes} 
       onChange={props.handleChange} fullWidth />
        : null
      }
    </Grid>
    <Grid item xs={12}>
      <Typography gutterBottom>
        Risk & Opportunities Identified:
      </Typography>
      <TextField id="risk" name="risk" label="Give Details" value={values.risk} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12}>
      <Typography gutterBottom>
        Describe The Scope Of Supply ( Mention The Product / Process For Which  You Want To Register )
      </Typography>
      <TextField multiline id="scopesupply" name="scopesupply" value={values.scopesupply} 
       onChange={props.handleChange} fullWidth />
    </Grid>
  </Grid>
</React.Fragment>
);
}
