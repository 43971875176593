
import React, { useState, useEffect } from 'react';
import { withRouter} from "react-router";
import {  sendresettoken, updatepaswordviaemail } from '../../actions/asyncActions';

const ResetPassword = (props) => {
  
 
  const [response, setResponse] = useState([]);

  const [resetvalues , setResetValues] = useState({
    username: '',
    password: '', 
  });
  const path = window.location.pathname;
  const token = path.substring(15);
  const email = response.email;

  const handleChange = event => {
    setResetValues({
      ...resetvalues,
      [event.target.name]: event.target.value
    });
};
  
  
  useEffect(() => {
    const data = {
      token: token
    }
    sendresettoken(data)
    .then(resp => setResponse(resp.data))
  }, [])


  const updatePassword = () => {
      const data  = {
        email: email,
        password: resetvalues.password,
        resetPasswordToken: token
      }
      updatepaswordviaemail(data)
      .then(resp => {
        
      })
      .catch(err => {
        console.log("error", err);
      })
      props.history.push(`${process.env.PUBLIC_URL}/pages/login`);
  }

  

   return (
    <div>
    <div className="page-wrapper">
        <div className="container-fluid p-0">
           
            <div className="authentication-main">
                <div className="row">
                    <div className="col-md-12">
                        <div className="auth-innerright">
                            <div className="authentication-box">
                                
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h4>Update Your Password</h4>
                                           
                                        </div>
                                        <form className="theme-form">
                                             
                                            <div className="form-group">
                                                
                                                <input className="form-control" type="text" placeholder="Enter your Password" name="password" value={resetvalues.password} onChange={handleChange} />
                                            </div>
                                            
                                            <div className="card-footer text-right">
                                                <button className="btn btn-primary" type="submit" onClick={updatePassword}>Update Password</button>
                                            </div>
                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>
   )
 

};







export default withRouter(ResetPassword);



