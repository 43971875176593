import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export default function AddTax(props) {
	const { values, taxRateList, taxrateval} = props;

	return( 
	  <React.Fragment>
	    <form>
        <div className="form-row">
          <div className="offset-xl-3 col-xl-6">
           <label className="col-form-label" htmlFor="message-text">Rate</label>
           <div className="input-group">
            <div className="input-group-prepend"><span className="input-group-text" id="inputGroupPrepend">₹</span></div>
            <input className="form-control" name="rate" type="text" placeholder="rate" aria-describedby="inputGroupPrepend"
              value={values.rate} onChange={props.handleChange} />
            <div className="input-group-postpend"><span className="input-group-text" id="inputGroupPrepend">per unit</span></div>
           </div>
           </div>
          <div className="col-xl-6 mb-3">
           <label className="col-form-label" htmlFor="message-text">Tax Rate</label>
           <Autocomplete
              options={taxRateList}
              getOptionLabel={option => option.name}
              value={taxrateval}                      
              onChange={(event, newValue) => {
                props.handleTaxValue(newValue);
              }}            
              renderInput={params => (
                <TextField {...params} margin="dense" variant="outlined" helperText="Select Tax Rate" fullWidth />
              )}
            />
          </div>
          <div className="col-xl-6 mb-3">
            <label className="col-form-label" htmlFor="message-text">HSN/SAC Code</label>
            <input className="form-control m-t-10" name="hsn" type="text" placeholder="HSN/SAC Code" 
             value={values.hsn} onChange={props.handleChange}/>
          </div>
        </div>
      </form>
	  </React.Fragment>
	);
}