import React, { useState, useEffect } from 'react';
import Logo from '../../../assets/images/nulogo.png';
import { getCompensation, getDesignation, getEmployee, getPayroll } from '../../../actions/asyncActions';
import {getGroup} from '../../../actions/asyncActions';
import * as moment from 'moment';

export default function PrintMusterWage(props) {
  //style={{transform:'rotate(-90deg)'}} //Show column vertically
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;

  var  month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const {selectedMonths, selectedYears } = props;
  //console.log("selectedMonths", selectedMonths, selectedYears)
  var monthIndex = month.indexOf(selectedMonths)
  //console.log("monthIndex", monthIndex)

  const [groups, setGroups] = useState([]);
  const filterGroup = groups.find(x=>x.id === group_id)
  const companyName = filterGroup && filterGroup.name; //Name of establishment
  var comIndex = companyName && companyName.indexOf(' ')
  var employerName = companyName && companyName.substr(0, comIndex); // Name of Employer
  
  const [payroll, setPayroll] = useState([]);
  const [compensation, setCompensation] = useState([])
  const componentsPay = compensation.filter(x=>x.last_pay_month === selectedMonths && x.last_pay_year === selectedYears && x.category === 'Payouts')
  const filterComponents = [...new Set(componentsPay.map(x => x.component))];

  const componentsDeduct = compensation.filter(x=>x.last_pay_month === selectedMonths && x.last_pay_year === selectedYears && x.category === 'Deductions')
  const filterDeductions = [...new Set(componentsDeduct.map(x => x.component))];
  //console.log("filterDeductions", filterDeductions)

  const [employees, setEmployees] = useState([]);
  const filterEmployees = employees.filter(x=>x.job_status === 'ACTIVE');

  const [designation, setDesignation] = useState([]);

  useEffect( () => {
    getGroup(group_id)
    .then(resp => setGroups(resp.data))
  }, [])

  useEffect( () => {
    getCompensation(group_id)
    .then(resp => setCompensation(resp.data))
  }, [])

  useEffect( () => {
    getEmployee(group_id)
    .then(resp => setEmployees(resp.data))
  }, [])

  useEffect( () => {
    getDesignation(group_id)
    .then(resp => setDesignation(resp.data))
  }, [])

  useEffect( () => {
    getPayroll(group_id)
    .then(resp => setPayroll(resp.data))
  }, [])

  // var numberToText = require('number2text');
    
  // //const getTotal = filterIndentItems.reduce((a, b) => a + b.total, 0);
  // const totalInWords = numberToText(55555,'', true);

  // const {companyName, selectedMonth, selectedYear, employeeId } = props;

  // const payslipData = selectedMonth && selectedYear ? 
	// 	payroll.filter(x => x.emp_id === employeeId && x.month === selectedMonth && x.year === selectedYear) 
	//   : null;

  // const paidDays = payslipData ? payslipData && payslipData[0].tot_days : 0;
  // const lopDays = payslipData ? payslipData && payslipData[0].lop : 0;
  // const filterPayouts = payslipData ? payslipData.filter(x=>x.category === 'Payouts') : null;
  // const filterDeductions = payslipData ? payslipData.filter(x=>x.category === 'Deductions' || x.category === 'Taxes') : null

  // const payoutsTot = payslipData ? filterPayouts.reduce((a,b) => a + parseFloat(b.net_salary), 0) : null;
  // const payoutsTotal =  payslipData ? Math.round((payoutsTot + Number.EPSILON) * 100) / 100 : null;
  // const deductionsTot = payslipData ? filterDeductions.reduce((a,b) => a + parseFloat(b.net_salary), 0) : null;
  // const deductionsTotal =  payslipData ? Math.round((deductionsTot + Number.EPSILON) * 100) / 100 : null;

  // const netAmt = payslipData ? payoutsTot - deductionsTot : null;
  // const netAmount = payslipData ? Math.round(netAmt) : null;
  // const netAmountWords = payslipData ? numberToText(netAmount,'', true) : null;

  // const handlePayrollPayouts = () => {
  //   if(getPayoutData === null){

  //   }
  //   else{
  //     return getPayoutData && getPayoutData.map((n,i) => {
        
  //       return(
  //         <tr key={i}>
  //           <th colSpan={2} style={{textAlign:'left'}}>{n.payoutComponent}</th>
  //           <td colSpan={2} style={{borderRight: '1px', textAlign:'right'}}>{n.payoutAmount}</td>
  //         </tr>
  //       );
  //     })
  //   }
  // }

  // const handlePayrollDeductions = () => {
  //   if(getDeductionData === null){

  //   }
  //   else{
  //     return getDeductionData && getDeductionData.map((n,i) => {
        
  //       return(
  //         <tr key={i}>
  //           <th colSpan={2} style={{textAlign:'left'}}>{n.deductionComponent}</th>
  //           <td colSpan={2} style={{borderRight: '1px', textAlign:'right'}}>{n.deductionAmount}</td>
  //         </tr>
  //       );
  //     })
  //   }
  // }
  
  // const empDetails = employees.find(x=>x.id === employeeId)
  // const empCode = empDetails && empDetails.employee_id; //Emp Id
  // const name = empDetails && empDetails.name; //Name
  // const designationId = empDetails && empDetails.desg_id; //Designation Id
  // const desnDetails = designation.find(x=>x.id === designationId)
  // const designationName = desnDetails && desnDetails.name; //Designation Name
  // const uan = empDetails && empDetails.pfno; //UAN
  // const pan = empDetails && empDetails.panno; //PAN
  // const doj = empDetails && empDetails.doj; 
  // const dateJoin = moment(doj).format('ll');//DOJ
  // const bankName = empDetails && empDetails.bankname; //Bank
  // const bankAccount = empDetails && empDetails.accno; //Account

  // const handlePayoutData = () => {
  //   let allPayrollData = [];
  //   if(filterPayouts === null){

  //   }
  //   else{
  //     filterPayouts.forEach((item, i) => {
  //       allPayrollData.push({
  //         payoutComponent: item.component,
  //         payoutAmount: item.net_salary
  //       })
  //     })
  //     return allPayrollData;
  //   }
  // }

  // const handleDeductionData = () => {
  //   let allPayrollData = [];
  //   if(filterDeductions === null){

  //   }
  //   else{
  //     filterDeductions.forEach((item, i) => {
  //       allPayrollData.push({
  //         deductionComponent: item.component,
  //         deductionAmount: item.net_salary
  //       })
  //     })
  //     return allPayrollData;
  //   }
  // }

  // const getPayoutData = handlePayoutData();
  // const getDeductionData = handleDeductionData();
  // console.log("getPayoutData", getPayoutData)
  // console.log("getDeductionData", getDeductionData)

  // const payrollPayout = handlePayrollPayouts()
  // const payrollDeduction = handlePayrollDeductions()

  const getDaysInMonths = (month, year) => {
    var date = new Date(year, month, 1);
    //console.log("curDate", date)
    // var days = [];
    var all_days = [];
    while (date.getMonth() == month) {
        //var d = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        //var d = date.toLocaleString('en-GB', { month: 'short', day: '2-digit', weekday:'short' });
        var d = moment(date).format('ll');
        all_days.push(d);
        date.setDate(date.getDate() + 1);
    }
    return all_days && all_days.map((days,index) => {
      return(
        <th key={index}>{days}</th>
      )
    })
  }

  const payoutComponents = () => {
    return filterComponents && filterComponents.map((comp, ind) => {
      return(
        <th key={ind}>{comp}</th>
      )
    })
  }

  const deductionComponents = () => {
    return filterDeductions && filterDeductions.map((comp, ind) => {
      return(
        <th key={ind}>{comp}</th>
      )
    })
  }

  const employeeDetails = () => {
    return filterEmployees && filterEmployees.map((n, index) => {
      const filterDesg = designation.find(x=>x.id === n.desg_id);
      const foundDesignation = filterDesg ? filterDesg && filterDesg.name : null
      const nofDaysPay = new Date(parseInt(selectedYears), monthIndex, 0).getDate();
      //console.log("nofDaysPay", nofDaysPay)
      const dateJoin = new Date(n.doj).getDate();
      // console.log("dateJoin", dateJoin)
      const dateJoinMonth = new Date(n.doj).getMonth();
      const dateJoinYear = new Date(n.doj).getFullYear();

      const checkIfSameMonth = (dateJoinMonth === monthIndex) && (dateJoinYear === parseInt(selectedYears))
      const workingDays = checkIfSameMonth ? (nofDaysPay - dateJoin) : nofDaysPay
      //console.log("workingDays", workingDays)

      const allPayrollData = payroll.filter(x=>x.emp_id === n.id && x.month === selectedMonths && 
        x.year === selectedYears && x.status === 'Approved')
      const basic = allPayrollData ? allPayrollData.find(x=> x.component === 'Basic Salary') : 0;
      const basicSalary = basic && basic.net_salary

      const da = allPayrollData ? allPayrollData.find(x=> x.component === 'Dearness Allowances') : 0;
      const dearness = da && da.net_salary 

      const hra = allPayrollData ? allPayrollData.find(x=> x.component === 'House Rent Allowances') : 0;
      const houseRent = hra && hra.net_salary 

      const conveyance = allPayrollData ? allPayrollData.find(x=> x.component === 'Conveyance Allowances') : 0;
      const conveyanceAllow = conveyance && conveyance.net_salary
      
      const medical = allPayrollData ? allPayrollData.find(x=> x.component === 'Medical') : 0;
      const medicalAllow = medical && medical.net_salary

      const special = allPayrollData ? allPayrollData.find(x=> x.component === 'Special Allowances') : 0;
      const specialAllow = special && special.net_salary

      const employeepf = allPayrollData ? allPayrollData.find(x=> x.component === 'Employees PF Contribution') : 0;
      const employeePFContribution = employeepf && employeepf.net_salary

      const profession = allPayrollData ? allPayrollData.find(x=> x.component === 'Profession Tax') : 0;
      const professionTax = profession && profession.net_salary

      const additions = allPayrollData ? 
      allPayrollData.filter(x=>x.category === 'Payouts').reduce((a,b) => a + parseFloat(b.net_salary), 0)
      : 
      null;
      const payouts = Math.round((additions + Number.EPSILON) * 100) / 100;
      const subtractions = allPayrollData ? 
      allPayrollData.filter(x=>x.category === 'Deductions').reduce((a,b) => a + parseFloat(b.net_salary), 0)
      : 
      null;
      const deductions = Math.round((subtractions + Number.EPSILON) * 100) / 100;
      const tax_subtractions = allPayrollData ? 
      allPayrollData.filter(x=>x.category === 'Taxes').reduce((a,b) => a + parseFloat(b.net_salary), 0)
      : 
      null;
      const tax_deductions = Math.round((tax_subtractions + Number.EPSILON) * 100) / 100;
      const netSal = payouts - deductions - tax_deductions;
      const netSalary = Math.round(netSal)

      
      return(
        <tr>
          <td>{index+1}</td>
          <td>{n.name}</td>
          <td>{n.gender}</td>
          <td>{foundDesignation}</td>
          <td>{moment(n.doj).format('ll')}</td>
          <td>{n.pfno}</td>
          <td>{workingDays}</td>
          <td>0</td>
          <td>{basicSalary}</td>
          <td>{dearness}</td>
          <td>{houseRent}</td>
          <td>{conveyanceAllow}</td>
          <td>{medicalAllow}</td>
          <td>{specialAllow}</td>
          <td>{payouts}</td>
          <td>{employeePFContribution}</td>
          <td>{professionTax}</td>
          <td>{deductions}</td>
          <td>{netSalary}</td>
          <td>{'Bank Transfer'}</td>
        </tr>
      )
    })
  }

  const daysInMonth = getDaysInMonths(monthIndex, parseInt(selectedYears))
  //console.log("daysInMonth", daysInMonth)
  //const getAllDaysInMonths = getAllDays();
  const getPayoutsComponents = payoutComponents();
  const getDeductionsComponents = deductionComponents();
  const getEarnedWages = 0;
  const getDeductions = 0;

  const getEmployeeDetails = employeeDetails();
  const attendanceInMonth = 0;
  

  return (
    <div id="printreceipt">
       <section className="sheet padding-5mm">
          <table>
           <tr>
             <td>Name of the Establishment: <b>{companyName}</b></td>
           </tr>
           <tr>
            <td>Name of the Employer: <b>{employerName}</b></td>
           </tr>
           <tr>
             <td>Month: <b>{selectedMonths}</b></td>
             <td align="center">Year: <b>{selectedYears}</b></td>
           </tr>
          </table>
          <table style={{width:'95%', border: '1px solid'}}>
            <thead>
              <tr>
                <th colSpan="18" align="right">Hours worked on</th>
                <th colSpan="19" align="right">Earned Wages</th>
                <th align="center">Deductions</th>
              </tr>
            </thead>
          </table>
          <table style={{width:'95%', border: '1px solid black'}}>
            <thead>
              <tr>              
                <th>Sl.No</th>
                <th>Full Name</th>
                <th>Gender</th>
                <th>Designation</th>
                <th>DOJ</th>
                <th>PF No</th>
                {daysInMonth}
                <th>No of Days Payable</th>
                <th>Total Overtime hours worked</th>
                {getPayoutsComponents}
                <th>Total</th>
                {getDeductionsComponents}
                <th>Total</th>
                <th>Net Payable</th>
                <th>Mode of Payment</th>
              </tr>
            </thead>
            <tbody>
                {getEmployeeDetails}
            </tbody>
          </table>
       </section>
       {/* <div style={{margin: '15px'}}>
          <table style={{width:'100%', textAlign:'left', border: '2px solid black', borderCollapse:'collapse', tableLayout:'fixed'}}>
            <tr style={{height:'100px', backgroundColor:'#c2d69b'}}>
              <td colSpan={4} style={{paddingLeft:'6px'}}>
                <img height="40px" src={Logo} /></td>
              <td colSpan={4} style={{textAlign:'right', fontSize:'25px', fontWeight: 'bold'}}>{companyName}</td>
            </tr>
            <tr style={{border: '1px solid'}}>
              <td colSpan={8} style={{textAlign:"center"}}>
                <h2>Payslip for the month of <b>{`${selectedMonth}-${selectedYear}`}</b></h2>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>Emp ID</th>
              <td colSpan={2}>:{empCode}</td>
              <th colSpan={2}>UAN</th>
              <td colSpan={2}>:{uan}</td>   
            </tr>
            <tr>
              <th colSpan={2}>Name</th>
              <td colSpan={2}>:{name}</td>
              <th colSpan={2}>PAN</th>
              <td colSpan={2}>:{pan}</td>
            </tr>
            <tr>
              <th colSpan={2}>Designation</th>
              <td colSpan={2}>:{designationName}</td>
              <th colSpan={2}>DOJ</th>
              <td colSpan={2}>:{dateJoin}</td>
            </tr>
            <tr>
              <th colSpan={2}>Paid Days</th>
              <td colSpan={2}>:{paidDays}</td>
              <th colSpan={2}>LOP</th>
              <td colSpan={2}>:{lopDays}</td>
            </tr>
            <tr>
              <th colSpan={2}>Bank</th>
              <td colSpan={2}>:{bankName}</td>
              <th colSpan={2}>Account No</th>
              <td colSpan={2}>:{bankAccount}</td>
            </tr>
            <tr>
              <table style={{border: '2px solid black', cellpadding:'0px', cellspacing:'0px', width:'50%', float:'left',
                            borderCollapse:'collapse', tableLayout:'fixed', minHeight:'250px'}}>
                <thead style={{border:'1px solid'}}>
                  <tr style={{border:'1px solid black', height:'40px', textAlign:'left'}}>
                    <th colSpan={2}>Earnings</th>
                    <th colSpan={2} style={{borderRight:'1px', textAlign:'right'}}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {payrollPayout}
                </tbody>
                <tfoot>
                  <tr style={{border:'1px solid black', height:'40px', textAlign:'left'}}>
                    <th colSpan={2}>Gross Earnings:</th>
                    <th colSpan={2} style={{textAlign:'right'}}>{payoutsTotal}</th>
                  </tr>
                </tfoot>
              </table>
              <table style={{border: '2px solid black', cellpadding:'0px', cellspacing:'0px', width:'50%', float:'left',
                            borderCollapse:'collapse', tableLayout:'fixed', minHeight:'250px'}}>
                <thead style={{border:'1px solid'}}>
                  <tr style={{border:'1px solid black', height:'40px', textAlign:'left'}}>
                    <th colSpan={2}>Deductions</th>
                    <th colSpan={2} style={{borderRight:'1px', textAlign:'right'}}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {payrollDeduction}
                </tbody>
                <tfoot>
                  <tr style={{border:'1px solid black', height:'40px', textAlign:'left'}}>
                    <th colSpan={2}>Gross Deductions</th>
                    <th colSpan={2} style={{textAlign:'right'}}>{deductionsTotal}</th>
                  </tr>
                </tfoot>
              </table>
            </tr>
            <tr>
              <table style={{border: '1px solid black', cellpadding:'0px', cellspacing:'0px', width:'100%', float:'left',
                  borderCollapse:'collapse', tableLayout:'fixed'}}>
                <tr>
                  <td colSpan={8}>
                    <h2>Net Amount: {netAmount}</h2>
                  </td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    <h2>Net Amount in words: {netAmountWords}</h2>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
          <footer style={{position: "fixed", left: "0", bottom: "0", width:"100%"}}>
            <p align="center">***This is a Computer generated payslip. Signature not required***</p>
          </footer>
        </div>
      </section> */}
    </div>
  );
}