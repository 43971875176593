import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormHelperText, TablePagination } from '@material-ui/core';
import { getHoliday, postHoliday, editHoliday, deleteHoliday } from '../../../actions/asyncActions';

const Holiday = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const userRole = user.role;

    const [holidays, setHolidays] = useState([]);
    const [addmodal, setAddModal] = useState(false);
    const [description, setDescription] = useState("");
    const [holidayDate, setHolidayDate] = useState(new Date());

    const foundDay = holidayDate.getDay();
    const daysName = [{name: "Sunday", value: 0}, {name: "Monday", value: 1}, {name: "Tuesday", value: 2},
        {name: "Wednesday", value: 3}, {name: "Thursday", value: 4}, {name: "Friday", value: 5},
        {name: "Saturday", value: 6}];
    
    var month = new Array();
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    const gotDay = daysName.find(x=> x.value === foundDay); //Week Day
    const gotMonth = month[holidayDate.getMonth()]; //Month
    const gotYear = holidayDate.getFullYear(); //Year

    const initialFormState = { id: '', description: ''}
    const [data, setCurrentData ] = useState(initialFormState)
    const [editedDate, setEditedDate] = useState(new Date())
    const [modal, setModal] = useState();
    
    const [errors, setError] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getHoliday(group_id)
      .then(resp => setHolidays(resp.data))
    }, []);

    const handleInputChange = event => {
      const { name, value } = event.target
      setCurrentData({ ...data, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
    }

    const editRow = n => {
     setModal(!modal)
     setCurrentData({ id: n.id, description: n.description })
     setEditedDate(new Date(n.date))
    }

    const deleteRow = n => {
      const data = {
        id: n.id
      }
      //Delete API
      deleteHoliday(data)
      .then(resp => {
        getHoliday(group_id)
        .then(resp => setHolidays(resp.data))
        toast.error("Holiday deleted successfully!!!")
      })
    }

    const foundEditDay = editedDate.getDay();
    const gotEditDay = daysName.find(x=> x.value === foundEditDay); //Week Day
    const gotEditMonth = month[editedDate.getMonth()]; //Month
    const gotEditYear = editedDate.getFullYear(); //Year
    
    const validateForm = (data) => {
     const errors = [];
     if(!data.description) {
       errors.description = "Please enter the description";
     }
     return errors;
    }

    const handleSubmit = () => {
      const data = {
        date: holidayDate,
        day: gotDay.name,
        month: gotMonth,
        year: gotYear,
        description: description,
        user_id: user.id,
        group_id: group_id,
      }
      const errors = validateForm(data);
        if(Object.keys(errors).length) {
        setError(errors);
        return;
        }
      postHoliday(data)
      .then(resp => {
          getHoliday(group_id)
          .then(resp => setHolidays(resp.data))
          toast.success("Holiday added successfully!!!")
          setAddModal(!addmodal)
          setHolidayDate(new Date())
          setDescription("")
      })
    }

    const handleUpdate = (id, data) => {

      const cat = {
        id: id,
        date: editedDate,
        day: gotEditDay.name,
        month: gotEditMonth,
        year: gotEditYear,
        description: data.description,
        user_id: user.id
      }
      const errors = validateForm(cat);
        if(Object.keys(errors).length) {
        setError(errors);
        return;
        }
      editHoliday(cat)
      .then(resp => {
        getHoliday(group_id)
          .then(resp => setHolidays(resp.data))
          toast.info("Holiday updated successfully!!!")
          setModal(!modal)
        })
        .catch(err => {
          console.log("Error", err);
        })
  }

        return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Description</th>
                                                  <th scope="col">Date</th>
                                                  <th scope="col">Day</th>
                                                  <th scope="col">Month</th>
                                                  <th scope="col">Year</th>
                                                  { userRole === 'Employee' ? 
                                                    null :
                                                  <th scope="col">Action</th>
                                                  }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {holidays.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.description}</td>
                                                     <td>
                                                      {new Date(n.date).toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                                     </td>
                                                     <td>{n.day}</td>
                                                     <td>{n.month}</td>
                                                     <td>{n.year}</td>
                                                     { userRole === 'Employee' ?
                                                     null
                                                     :
                                                     <td>
                                                        <i className="fa fa-edit" onClick={() => editRow(n)} id="TooltipTop" data-placement="bottom"
                                                            style={{ width: 35, fontSize: 16, padding: 11, color: 'blue' }}>
                                                            <UncontrolledTooltip placement="bottom" target="TooltipTop">
                                                                Edit Holiday
                                                            </UncontrolledTooltip>
                                                        </i>
                                                        <i className="fa fa-trash-o" onClick={() => deleteRow(n)} id="TooltipTop1" data-placement="bottom"
                                                            style={{ width: 35, fontSize: 16, padding: 11, color: 'red' }}>
                                                            <UncontrolledTooltip placement="bottom" target="TooltipTop1">
                                                                Delete Holiday
                                                            </UncontrolledTooltip>
                                                        </i>
                                                     </td>
                                                     }
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                      { userRole === 'Employee' ?
                                        null
                                        :
                                        <button className="btn btn-info btn-sm" type="button" onClick={() => addRow()}>
                                         Add Holiday
                                        </button>
                                      }
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={holidays.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Add Holiday --> */}
            <Modal isOpen={addmodal} toggle={addRow} centered={true}>
                <ModalHeader toggle={addRow}>Add Holiday</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-12 mb-3">
                              <TextField fullWidth required id="description" label="Description" margin="dense" name="description" 
                               onChange={(e) =>setDescription(e.target.value)} value={description} variant="outlined" 
                              />
                              <FormHelperText className="text-danger">{errors.description}</FormHelperText>
                            </div>
                            <div className="col-xl-12 mb-3">
                             <label className="col-form-label" htmlFor="message-text">Holiday Date:</label>
                              <DatePicker className="form-control digits"
                                selected={holidayDate}
                                value={holidayDate}
                                onChange={(date) => setHolidayDate(date)}
                                dateFormat="dd/MMM/yyyy"
                                placeholderText="Please select a date"
                              />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={addRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Holidays --> */}
            <Modal isOpen={modal} toggle={editRow}>
                <ModalHeader toggle={editRow}>Edit Holiday</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-12 mb-3">
                              <TextField fullWidth required id="description" label="Description" margin="dense" name="description" 
                               onChange={handleInputChange} value={data.description} variant="outlined" 
                              />
                              <FormHelperText className="text-danger">{errors.description}</FormHelperText>
                            </div>
                            <div className="col-xl-12 mb-3">
                             <label className="col-form-label" htmlFor="message-text">Holiday Date:</label>
                              <DatePicker className="form-control digits"
                                selected={new Date(editedDate)}
                                value={new Date(editedDate)}
                                onChange={(date) => setEditedDate(date)}
                                dateFormat="dd/MMM/yyyy"
                                placeholderText="Please select a date"
                              />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={editRow}>Cancel</Button>
                    <Button color="primary" onClick={ () => handleUpdate(data.id, data)} >Save Changes</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default Holiday;