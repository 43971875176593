import React, { useState, useEffect } from 'react';
import Logo from '../../../assets/images/nulogo.png';
import { getDesignation, getEmployee, getPayroll } from '../../../actions/asyncActions';
import * as moment from 'moment';

export default function PayslipDownload(props) {
  
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;

  const [employees, setEmployees] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [payroll, setPayroll] = useState([]);

  useEffect( () => {
    getEmployee(group_id)
    .then(resp => setEmployees(resp.data))
  }, [])

  useEffect( () => {
    getDesignation(group_id)
    .then(resp => setDesignation(resp.data))
  }, [])

  useEffect( () => {
    getPayroll(group_id)
    .then(resp => setPayroll(resp.data))
  }, [])

  var numberToText = require('number2text');
    
  //const getTotal = filterIndentItems.reduce((a, b) => a + b.total, 0);
  const totalInWords = numberToText(55555,'', true);

  const {companyName, selectedMonth, selectedYear, employeeId } = props;

  const payslipData = selectedMonth && selectedYear ? 
		payroll.filter(x => x.emp_id === employeeId && x.month === selectedMonth && x.year === selectedYear) 
	  : null;

  const paidDays = payslipData ? payslipData && payslipData[0].tot_days : 0;
  const lopDays = payslipData ? payslipData && payslipData[0].lop : 0;
  const filterPayouts = payslipData ? payslipData.filter(x=>x.category === 'Payouts') : null;
  const filterDeductions = payslipData ? payslipData.filter(x=>x.category === 'Deductions' || x.category === 'Taxes') : null

  const payoutsTot = payslipData ? filterPayouts.reduce((a,b) => a + parseFloat(b.net_salary), 0) : null;
  const payoutsTotal =  payslipData ? Math.round((payoutsTot + Number.EPSILON) * 100) / 100 : null;
  const deductionsTot = payslipData ? filterDeductions.reduce((a,b) => a + parseFloat(b.net_salary), 0) : null;
  const deductionsTotal =  payslipData ? Math.round((deductionsTot + Number.EPSILON) * 100) / 100 : null;

  const netAmt = payslipData ? payoutsTot - deductionsTot : null;
  const netAmount = payslipData ? Math.round(netAmt) : null;
  const netAmountWords = payslipData ? numberToText(netAmount,'', true) : null;

  const handlePayrollPayouts = () => {
    if(getPayoutData === null){

    }
    else{
      return getPayoutData && getPayoutData.map((n,i) => {
        
        return(
          <tr key={i}>
            <th colSpan={2} style={{textAlign:'left'}}>{n.payoutComponent}</th>
            <td colSpan={2} style={{borderRight: '1px', textAlign:'right'}}>{n.payoutAmount}</td>
          </tr>
        );
      })
    }
  }

  const handlePayrollDeductions = () => {
    if(getDeductionData === null){

    }
    else{
      return getDeductionData && getDeductionData.map((n,i) => {
        
        return(
          <tr key={i}>
            <th colSpan={2} style={{textAlign:'left'}}>{n.deductionComponent}</th>
            <td colSpan={2} style={{borderRight: '1px', textAlign:'right'}}>{n.deductionAmount}</td>
          </tr>
        );
      })
    }
  }
  
  const empDetails = employees.find(x=>x.id === employeeId)
  const empCode = empDetails && empDetails.employee_id; //Emp Id
  const name = empDetails && empDetails.name; //Name
  const designationId = empDetails && empDetails.desg_id; //Designation Id
  const desnDetails = designation.find(x=>x.id === designationId)
  const designationName = desnDetails && desnDetails.name; //Designation Name
  const uan = empDetails && empDetails.pfno; //UAN
  const pan = empDetails && empDetails.panno; //PAN
  const doj = empDetails && empDetails.doj; 
  const dateJoin = moment(doj).format('ll');//DOJ
  const bankName = empDetails && empDetails.bankname; //Bank
  const bankAccount = empDetails && empDetails.accno; //Account

  const handlePayoutData = () => {
    let allPayrollData = [];
    if(filterPayouts === null){

    }
    else{
      filterPayouts.forEach((item, i) => {
        allPayrollData.push({
          payoutComponent: item.component,
          payoutAmount: item.net_salary
        })
      })
      return allPayrollData;
    }
  }

  const handleDeductionData = () => {
    let allPayrollData = [];
    if(filterDeductions === null){

    }
    else{
      filterDeductions.forEach((item, i) => {
        allPayrollData.push({
          deductionComponent: item.component,
          deductionAmount: item.net_salary
        })
      })
      return allPayrollData;
    }
  }

  const getPayoutData = handlePayoutData();
  const getDeductionData = handleDeductionData();
  console.log("getPayoutData", getPayoutData)
  console.log("getDeductionData", getDeductionData)

  const payrollPayout = handlePayrollPayouts()
  const payrollDeduction = handlePayrollDeductions()

  return (
    <div id="printreceipt">
      <section className="sheet padding-5mm">
        <div style={{margin: '15px'}}>
          <table style={{width:'100%', textAlign:'left', border: '2px solid black', borderCollapse:'collapse', tableLayout:'fixed'}}>
            <tr style={{height:'100px', backgroundColor:'#c2d69b'}}>
              <td colSpan={4} style={{paddingLeft:'6px'}}>
                <img height="40px" src={Logo} /></td>
              <td colSpan={4} style={{textAlign:'right', fontSize:'25px', fontWeight: 'bold'}}>{companyName}</td>
            </tr>
            <tr style={{border: '1px solid'}}>
              <td colSpan={8} style={{textAlign:"center"}}>
                <h2>Payslip for the month of <b>{`${selectedMonth}-${selectedYear}`}</b></h2>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>Emp ID</th>
              <td colSpan={2}>:{empCode}</td>
              <th colSpan={2}>UAN</th>
              <td colSpan={2}>:{uan}</td>   
            </tr>
            <tr>
              <th colSpan={2}>Name</th>
              <td colSpan={2}>:{name}</td>
              <th colSpan={2}>PAN</th>
              <td colSpan={2}>:{pan}</td>
            </tr>
            <tr>
              <th colSpan={2}>Designation</th>
              <td colSpan={2}>:{designationName}</td>
              <th colSpan={2}>DOJ</th>
              <td colSpan={2}>:{dateJoin}</td>
            </tr>
            <tr>
              <th colSpan={2}>Paid Days</th>
              <td colSpan={2}>:{paidDays}</td>
              <th colSpan={2}>LOP</th>
              <td colSpan={2}>:{lopDays}</td>
            </tr>
            <tr>
              <th colSpan={2}>Bank</th>
              <td colSpan={2}>:{bankName}</td>
              <th colSpan={2}>Account No</th>
              <td colSpan={2}>:{bankAccount}</td>
            </tr>
            <tr>
              <table style={{border: '2px solid black', cellpadding:'0px', cellspacing:'0px', width:'50%', float:'left',
                            borderCollapse:'collapse', tableLayout:'fixed', minHeight:'250px'}}>
                <thead style={{border:'1px solid'}}>
                  <tr style={{border:'1px solid black', height:'40px', textAlign:'left'}}>
                    <th colSpan={2}>Earnings</th>
                    <th colSpan={2} style={{borderRight:'1px', textAlign:'right'}}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {payrollPayout}
                </tbody>
                <tfoot>
                  <tr style={{border:'1px solid black', height:'40px', textAlign:'left'}}>
                    <th colSpan={2}>Gross Earnings:</th>
                    <th colSpan={2} style={{textAlign:'right'}}>{payoutsTotal}</th>
                  </tr>
                </tfoot>
              </table>
              <table style={{border: '2px solid black', cellpadding:'0px', cellspacing:'0px', width:'50%', float:'left',
                            borderCollapse:'collapse', tableLayout:'fixed', minHeight:'250px'}}>
                <thead style={{border:'1px solid'}}>
                  <tr style={{border:'1px solid black', height:'40px', textAlign:'left'}}>
                    <th colSpan={2}>Deductions</th>
                    <th colSpan={2} style={{borderRight:'1px', textAlign:'right'}}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {payrollDeduction}
                </tbody>
                <tfoot>
                  <tr style={{border:'1px solid black', height:'40px', textAlign:'left'}}>
                    <th colSpan={2}>Gross Deductions</th>
                    <th colSpan={2} style={{textAlign:'right'}}>{deductionsTotal}</th>
                  </tr>
                </tfoot>
              </table>
            </tr>
            <tr>
              <table style={{border: '1px solid black', cellpadding:'0px', cellspacing:'0px', width:'100%', float:'left',
                  borderCollapse:'collapse', tableLayout:'fixed'}}>
                <tr>
                  <td colSpan={8}>
                    <h2>Net Amount: {netAmount}</h2>
                  </td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    <h2>Net Amount in words: {netAmountWords}</h2>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
          <footer style={{position: "fixed", left: "0", bottom: "0", width:"100%"}}>
            <p align="center">***This is a Computer generated payslip. Signature not required***</p>
          </footer>
        </div>
      </section>
    </div>
  );
}