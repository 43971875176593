import React, { Fragment, useState, useEffect } from 'react';
import { TablePagination } from '@material-ui/core';
import { getClients } from '../../../actions/asyncActions';

const ClientApproved = () => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;

  const [clients, setClients] = useState([]);
  const approved_data = clients.filter(s => s.status === 'approved');

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect( () => {
    getClients(group_id)
    .then(resp => setClients(resp.data))
  }, []);

  return (
         <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">ID</th>
                                                  <th scope="col">Name</th>
                                                  <th scope="col">Start Date</th>
                                                  <th scope="col">End Date</th>
                                                  <th scope="col">Address</th>
                                                  <th scope="col">GSTIN</th>
                                                  <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {approved_data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <th scope="row">{n.id}</th>
                                                     <td>{n.name}</td>
                                                     <td>
                                                      {new Date(n.start_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>
                                                      {new Date(n.end_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{n.billing_address}</td>
                                                     <td>{n.gstin}</td>
                                                     <td>{n.status}</td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                       <button className="btn btn-info btn-sm" type="button">
                                         Export
                                        </button>
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={approved_data.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>              
                </div>
            </div>            
        </Fragment>
    );
}
export default ClientApproved;