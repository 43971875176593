import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import ScrollToBottom from 'react-scroll-to-bottom';
import io from "socket.io-client";
import './chats.css';

let socket;

const SendChats = () => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const userfname = user && user.firstname;
  const userlname = user && user.lastname;
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  console.log("messages", messages);
  const ENDPOINT = 'http://127.0.0.1:4000';

    useEffect(() => {
      //connect to socket.io
      socket = io(ENDPOINT);

      //check for connection
      if(socket !== undefined){
       console.log('connected to socket...');
      }
    }, [ENDPOINT]);

    useEffect(() => {
       socket.on('output', msg => {
        console.log("output message", msg);
        setMessages(...messages, msg);
       });
    }, []);

    const sendMessage = (event) => {
       event.preventDefault();
       if(message) {
        socket.emit('input', {
          name: userfname, 
          message: message
        });
        setMessage('');
       }
    }
    
  return (
    <Fragment>
      <Breadcrumb title="Chat" parent="Chat" />
      <div className="outerContainer">
      	<div className="container">
          {/* Infobar */}
          <div className="infoBar">
		   <div className="leftInnerContainer">
		    <h3>Nu-pie Chat</h3>
		   </div>
		  </div>
		  
		  {/* Message box */}
			  <ScrollToBottom className="messages">
			   {messages.map((n,i) => {
			   	 let isCurrentUser = false;
			   	 if(n.name == userfname){
			   	 	isCurrentUser = true;
			   	 }
			   	 return (
			   	  isCurrentUser
			   	  ? (
			   	     <div key={i}>
				   	   <div className="messageContainer justifyEnd">
				  	 	<p className="sentText pr-10">{n.name}</p>
				  	 	<div className="messageBox backgroundBlue">
				  	 	 <p className="messageText colorWhite">{n.message}</p>
				  	 	</div>
				  	   </div>
			   	     </div>
			   	  )
			   	  : (
			   	  	 <div key={i}>
				   	   <div className="messageContainer justifyStart">
				  	 	<div className="messageBox backgroundLight">
				  	 	 <p className="messageText colorDark">{n.message}</p>
				  	 	</div>
				  	 	<p className="sentText pl-10">{n.name}</p>
				  	   </div>
			   	     </div>
			   	  )
			   	  
			   	 );
			   	})
			   }
			  </ScrollToBottom>
		  

		  {/* Input box */}
		  <form className="form">
			   <input 
			   	className="input"
			   	type="text"
			   	placeholder="Type a message ..."
			   	value={message} 
			   	onChange={(event) => setMessage(event.target.value)}
			   	onKeyPress={event => event.key == 'Enter' ? sendMessage(event) : null}
			   />
		       <button className="sendButton" onClick={(event) => sendMessage(event)}>Send</button>
		  </form>
      	</div>
      </div>
      {/*
      <div className="container-fluid">
        <div className="row">
         <div className="col-sm-12 col-xl-6">
            <div className="card card-absolute">
              <div className="card-header bg-secondary">
                  <h5>Nu-pie Chat</h5>
              </div>
              <div className="card-body text-center" style={{height: 300}}>
                 
              </div>
              <form className="form">
			   <input 
			   	className="input"
			   	type="text"
			   	placeholder="Type a message ..."
			   	value={message} 
			   	onChange={(event) => setMessage(event.target.value)}
			   	onKeyPress={event => event.key === 'Enter' ? sendMessage(event) : null}
			   />
			   <button className="sendButton" onClick={(event) => sendMessage(event)}>Send</button>
			  </form>
            </div>
         </div>
        </div>
      </div> 
     */}
    </Fragment>
  );
}

export default SendChats;