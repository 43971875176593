import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Autocomplete} from '@material-ui/lab';
import { TextField, MenuItem, FormHelperText, TablePagination, Snackbar, Slide, IconButton } from '@material-ui/core';
import { CSVLink } from "react-csv";
import { Download } from 'react-feather';
import {Close as CloseIcon } from "@material-ui/icons";
import { withRouter } from 'react-router-dom';
import { getCreditStmt, downloadCreditStatement } from '../../actions/asyncActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CreditReview = (props) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;

  const elementStyle ={
	  border:'solid',
	  borderRadius:'10px',
	  position:'relative',
	  left:'1vh',
	  height:'6vh',
	  width:'23vh',
	  marginTop:'1vh',
	  marginBottom:'1vh',
	  textAlign:'center'
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [creditStmt, setCreditStmt] = useState([]);
  const [download_credit, setDownload] = useState([]);
  const [download_filter_credit, setFilterDownload] = useState([]);

  useEffect( () => {
    getCreditStmt(group_id)
     .then(resp => setCreditStmt(resp.data))
  }, []);

  useEffect( () => {
    downloadCreditStatement(group_id)
    .then(resp => setDownload(resp.data))
  }, []);

  const [search, setSearch] = useState('');
  const [filteredCreditStmt, setFilteredCreditStmt] = useState([]);
  useEffect(() => {
  	setFilteredCreditStmt(
  	 creditStmt.filter(n => {
  	  return n.transaction_details.toLowerCase().includes(search.toLowerCase());
  	 })
  	)
  }, [search, creditStmt])

  const addTagRow = (n) => {

  }

  const [tagmodal, setTagModal] = useState()
  const addTag = () => {
    setTagModal(!tagmodal);
  }

  const handleSubmit = () => {
  	
  }

  const [open_download, setOpenDownload] = React.useState(false);

  const handleReviewClose = () => {
    setOpenDownload(false);
  };

  const handleDownloadSubmit = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const data = {
      group_id: user.group_id 
    }
    console.log("data", data)
      downloadCreditStatement(data)
      .then(resp => {
        setDownload(resp.data);
        console.log('Download File', resp.data);
      })
      .catch(err => {
        console.log("Error", err);
      })
      setOpenDownload(true);
  }

  const [open_filter_download, setOpenFilterDownload] = React.useState(false);
  const handleReviewClose2 = () => {
    setOpenFilterDownload(false);
  };

  const handleFilterDownloadSubmit = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    
    let downloadData = filteredCreditStmt;
    let creditItems_data = [];
    downloadData.forEach(item => {
	  creditItems_data.push({ 
	    date: item.date, transaction_details: item.transaction_details, spends_area: item.spends_area, 
	    amount: item.amount, filename: item.filename
	  })
    });
      setFilterDownload(creditItems_data);
      setOpenFilterDownload(true);
  }

    return (
      <Fragment>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                             <div className="row">
                               <div className="col">
	                           	<input type="text" placeholder="Search Items" style={elementStyle} value={search}
	                              onChange={e => setSearch(e.target.value)} />&nbsp;&nbsp;
	                        	 {search?
		                        	<button className="btn btn-info btn-sm" type="button" onClick={handleFilterDownloadSubmit}>
		                             	<Download size="14"/>Export
		                            </button>
		                            : null
	                        	 }
	                           </div>
                             </div>
                            </div>
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-info">
                                          <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">File</th>
                                            <th scope="col">Particulars</th>
                                            <th scope="col">Spends Area</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            {filteredCreditStmt.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((n, i)=> {
                                              return (
                                                <tr>
                                                 <td>
                                                  {new Date(n.date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                 </td>
                                                 <td>{n.filename}</td>
                                                 <td>{n.transaction_details}</td>
                                                 <td>{n.spends_area}</td>
                                                 <td>{n.amount}</td>
                                                 <td>
                                                   <button>
                                                    <i className="fa fa-plus" onClick={() => addTagRow(n)} id="TooltipTop" data-placement="top"
                                                     style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      Add Tags
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </button>
                                                 </td>
                                                </tr>
                                              );
                                            })
                                          }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-block row">
                                  <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                    <button className="btn btn-info btn-sm" type="button" onClick={addTag}>
                                     Create Tags
                                    </button>&nbsp;&nbsp;
                                    <button className="btn btn-info btn-sm" type="button" onClick={handleDownloadSubmit}>
                                     <Download size="14"/>Export
                                    </button>
                                  </div>
                                  <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <TablePagination
                                      rowsPerPageOptions={[5, 10, 25]}
                                      component="div"
                                      count={filteredCreditStmt.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onChangePage={handleChangePage}
                                      onChangeRowsPerPage={handleChangeRowsPerPage}
                                     />
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      {/* < !-- Using Modal for Creating Tags --> */}
            <Modal isOpen={tagmodal} toggle={addTag} size="xl">
                <div className="media m-t-15">
                 <div className="media-body">
                  <h4 className="f-w-600 text-center">
                      <u>Create Tags</u>
                  </h4>
                 </div>
                </div>
                <ModalBody>
                    
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={addTag}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit}>Submit</Button>
                </ModalFooter>
            </Modal>

      {/***** Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download}
          autoHideDuration={6000}
          onClose={handleReviewClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_credit}
              filename="CreditStatement.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadCreditStatement} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleReviewClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        {/***** Download Filtered Items CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_filter_download}
          autoHideDuration={6000}
          onClose={handleReviewClose2}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_filter_credit}
              filename="CreditStatement.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadCreditStatement} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleReviewClose2}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

      </Fragment>
    );
}

export default withRouter(CreditReview);