export const indianNumberFormat = (value) =>
new Intl.NumberFormat('en-IN', {
style: 'currency',
currency: 'INR'
}).format(value);

export const usNumberFormat = (value) =>
new Intl.NumberFormat('en-US', {
style: 'currency',
currency: 'USD'
}).format(value);