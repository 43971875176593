import React, { Fragment } from 'react';
import Breadcrumb from '../../../../components/common/breadcrumb';
import { Home, Settings, MoreHorizontal  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import RaisedInvoices from './RaisedInvoices';
import ApprovedInvoices from './ApprovedInvoices';

const BillingTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="Billing" parent="Billing" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Home />Raised Invoices
                                </Tab>
                                <Tab>
                                    <MoreHorizontal />Approved Invoices
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <RaisedInvoices />
                                </TabPanel>
                                <TabPanel>
                                    <ApprovedInvoices />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

export default BillingTab;