import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { TablePagination, Snackbar, Slide, IconButton,
         Stepper, Step, StepLabel, TextField, MenuItem } from '@material-ui/core';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Collapse } from 'reactstrap';
import { Download, ThumbsUp } from 'react-feather';
import { CSVLink } from "react-csv";
import {Close as CloseIcon } from "@material-ui/icons";
import Payouts from './editStepper/Payouts';
import Deductions from './editStepper/Deductions';
import TaxDeductions from './editStepper/TaxDeductions';
import { toast } from 'react-toastify';
import {getEmployee, getPayroll, downloadPayroll, editPayroll, getCompensation, editPayroll2} from '../../../actions/asyncActions';
import PrintMuster from './PrintMusterWage';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const PayrollFinal2 = (props) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    const elementStyle = {
      position:'relative',
      left:'1vh',
      height:'6vh',
      width:'32vh',
      marginTop:'1vh',
      marginBottom:'1vh',
      textAlign: 'center'
    }

    const [employees, setEmployees] = useState([])
    const filterEmployees = employees.filter(x=>x.job_status === 'ACTIVE');
    
    const selectedMonth = "January" 
    const selectedYear = "2021"

    const [payroll, setPayroll] = useState([])

    const abcdMonths = [...new Set(payroll.map(x => x.month))]; //Unique Months in Payroll Table
    const abcdYears = [...new Set(payroll.map(x => x.year))]; //Unique Years in Payroll Table
    
    let payrollMonths = [];
    let payrollYears = [];
    //Get Payroll Months for selection
    abcdMonths.forEach((item, i) => {
      payrollMonths.push({
        id: i+1,
        label:item,
        value: item
      })
    })

    //Get Payroll Years for selection
    abcdYears.forEach((item, i) => {
      payrollYears.push({
        id: i+1,
        label:item,
        value: item
      })
    })

    const [compensation, setCompensation] = useState("");
    
    const [selectedMonths, setSelectedMonths] = useState("")
    const [selectedYears, setSelectedYears] = useState("")
    // console.log("selectedMonths", selectedMonths, selectedYears)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect(() => {
      getPayroll(group_id)
      .then(resp => setPayroll(resp.data))
    }, []);

    useEffect(() => {
      getEmployee(group_id)
      .then(resp => setEmployees(resp.data))
    }, [payroll]);

    useEffect(() => {
      getCompensation(group_id)
      .then(resp => {
        const aaa = employees.filter(x => x.job_status === 'ACTIVE');
        const bbb = [...new Set(aaa.map(x => x.id))];
        const ccc = resp.data;
        const ddd = ccc.filter(x=>x.group_id === group_id && bbb.includes(x.emp_id))
        setCompensation(ddd[0])
      })
    }, [employees])

    useEffect(() => {
      if(compensation){
        const lastPayMonth = compensation ? compensation.last_pay_month : null
        const lastPayYear = compensation ? compensation.last_pay_year : null
        setSelectedMonths(lastPayMonth)
        setSelectedYears(lastPayYear)
      }
      else{
        console.log("Error set")
      }
    }, [compensation]);
    
    {/* Export Full Data */}
    const [download_payroll, setDownload] = useState([]);
    const [open_download, setOpenDownload] = React.useState(false);

    const handleReviewClose = () => {
        setOpenDownload(false);
    };

    const handleDownloadSubmit = () => {
        const users = JSON.parse(localStorage.getItem("m_users"));
        const abcd = payroll.filter(x=> x.month === selectedMonths && x.year === selectedYears && x.status === 'Approved' )
        
        const data = {
            group_id: users.group_id,
            month: selectedMonths,
            year: selectedYears
        }
        if(abcd.length > 0){
            downloadPayroll(data)
            .then(resp => {
            setDownload(resp.data);
            })
            .catch(err => {
            console.log("Error", err);
            })
            setOpenDownload(true);
        }
        else{
            toast.warning("Selected Dataset is not available for the download!!!", 
            {position: toast.POSITION.TOP_CENTER, autoClose:3000})
        }
        
    }

    {/* Export Profession Tax Data */}
    const [download_pt, setDownloadPt] = useState([]);
    const [open_download_pt, setOpenDownloadPt] = React.useState(false);

    const handlePtClose = () => {
        setDownloadPt([])
        setOpenDownloadPt(false);
    };

    const handleDownloadPT = () => {
        const users = JSON.parse(localStorage.getItem("m_users"));
        const abcd = payroll.filter(x=> x.month === selectedMonths && x.year === selectedYears && x.status === 'Approved' )

        const data = {
            group_id: users.group_id,
            month: selectedMonths,
            year: selectedYears,
            type: 'PT'
        }
        if(abcd.length > 0){
            downloadPayroll(data)
            .then(resp => {
            setDownloadPt(resp.data);
            })
            .catch(err => {
            console.log("Error", err);
            })
            setOpenDownloadPt(true);
        }
        else{
            toast.warning("Selected Dataset is not available for the download!!!", 
            {position: toast.POSITION.TOP_CENTER, autoClose:3000})
        }
    }

    {/* Export PF Data */}
    const [download_pf, setDownloadPf] = useState([]);
    const [open_download_pf, setOpenDownloadPf] = React.useState(false);

    const handlePfClose = () => {
        setDownloadPf([])
        setOpenDownloadPf(false);
    };

    const handleDownloadPF = () => {
        const users = JSON.parse(localStorage.getItem("m_users"));
        const abcd = payroll.filter(x=> x.month === selectedMonths && x.year === selectedYears && x.status === 'Approved' )

        const data = {
            group_id: users.group_id,
            month: selectedMonths,
            year: selectedYears,
            type: 'PF'
        }
        if(abcd.length > 0) {
            downloadPayroll(data)
            .then(resp => {
            setDownloadPf(resp.data);
            })
            .catch(err => {
            console.log("Error", err);
            })
            setOpenDownloadPf(true);
        }
        else{
            toast.warning("Selected Dataset is not available for the download!!!", 
            {position: toast.POSITION.TOP_CENTER, autoClose:3000})
        }
    }

    {/* Export Income Tax Data */}
    const [download_it, setDownloadIt] = useState([]);
    const [open_download_it, setOpenDownloadIt] = React.useState(false);

    const handleItClose = () => {
        setDownloadIt([]);
        setOpenDownloadIt(false);
    };

    const handleDownloadIT = () => {
        const users = JSON.parse(localStorage.getItem("m_users"));
        const abcd = payroll.filter(x=> x.month === selectedMonths && x.year === selectedYears && x.status === 'Approved' )

        const data = {
            group_id: users.group_id,
            month: selectedMonths,
            year: selectedYears,
            type: 'IT'
        }
        if(abcd.length > 0) {
            downloadPayroll(data)
            .then(resp => {
            setDownloadIt(resp.data);
            })
            .catch(err => {
            console.log("Error", err);
            })
            setOpenDownloadIt(true);
        }
        else{
            toast.warning("Selected Dataset is not available for the download!!!", 
            {position: toast.POSITION.TOP_CENTER, autoClose:3000})
        }
    }

    {/* Export Bank Data */}
    const [download_bank, setDownloadBank] = useState([]);
    const [open_download_bank, setOpenDownloadBank] = React.useState(false);

    const handleBankClose = () => {
        setDownloadBank([]);
        setOpenDownloadBank(false);
    };

    const handleDownloadBank = () => {
        const users = JSON.parse(localStorage.getItem("m_users"));
        const abcd = payroll.filter(x=> x.month === selectedMonths && x.year === selectedYears && x.status === 'Approved' )

        const data = {
            group_id: users.group_id,
            month: selectedMonths,
            year: selectedYears,
            type: 'Bank'
        }
        if(abcd.length > 0) {
            downloadPayroll(data)
            .then(resp => {
                setDownloadBank(resp.data);
            })
            .catch(err => {
            console.log("Error", err);
            })
            setOpenDownloadBank(true);
        }
        else{
            toast.warning("Selected Dataset is not available for the download!!!", 
            {position: toast.POSITION.TOP_CENTER, autoClose:3000})
        }
    }


    // State variable to keep track of all the expanded rows
    // By default, nothing expanded. Hence initialized with empty array.
    const [expandedRows, setExpandedRows] = useState([]);

    // State variable to keep track which row is currently expanded.
    const [expandState, setExpandState] = useState({});

    /**
     * This function gets called when show/hide link is clicked.
     */
    const handleExpandRow = (event, userId) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(userId);

        let obj = {};
        isRowExpanded ? (obj[userId] = false) :  (obj[userId] = true);
        setExpandState(obj);

        // If the row is expanded, we are here to hide it. Hence remove
        // it from the state variable. Otherwise add to it.
        const newExpandedRows = isRowExpanded ?
          currentExpandedRows.filter(id => id !== userId) :
          currentExpandedRows.concat(userId);
        
        setExpandedRows(newExpandedRows);
    }

    const approvePayroll = () => {
        const data = {
          group_id: group_id,
          month: selectedMonths,
          year: selectedYears
        }
        editPayroll2(data)
        .then(resp => {
          if(resp.data.error){
            toast.warning(`${resp.data.error}`, {position: toast.POSITION.TOP_CENTER, autoClose:3000})
          }
          else{
            getPayroll(group_id)
            .then(resp => setPayroll(resp.data))
            toast.success("Payroll Approved Successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
          }
          
        })
    }

    const handlePrintMusterWage = () => {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');
      mywindow.document.write(`<html><head><title>${document.title}</title>
       <style>
       @page { margin: 0 }
        body { margin: 0 }
        .sheet {
        margin: 0;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        page-break-after: always;
        }
    
        /* Padding area */
        .sheet.padding-5mm { padding: 5mm }
    
        /* For screen preview */
        @media screen {
        body { background: #e0e0e0 }
        .sheet {
         background: white;
         box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
         margin: 2mm;
         }
         table {
          border-collapse: collapse;
        }
         tr.border_bottom td {
        border-bottom:1pt solid black;
        }
        table th, table td {
        border:1px solid #000;
        padding: 0.5em;
        }
        }
        @media print {
          html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
          font-size: 9pt !important;
             }
          }
    
       </style>
       `);
       mywindow.document.write('</head><body>');
       mywindow.document.write(document.getElementById('printreceipt').innerHTML);
       mywindow.document.write('</body></html>');
    
       mywindow.document.close(); // necessary for IE >= 10
       mywindow.focus(); // necessary for IE >= 10*/
    
       mywindow.print();
       mywindow.close();
    
       return true;
    }

    
    return(
        <Fragment>
            <Breadcrumb title="Finalized Payroll" parent="Payroll" />
            <div className="container-fluid">
            <div style={{display:'none'}}>
              <PrintMuster 
                selectedMonths={selectedMonths}
                selectedYears={selectedYears}
              />
            </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                  <div className="row">
                                    <div className="col">
                                      <TextField select value={selectedMonths} onChange={(e) => setSelectedMonths(e.target.value)} 
                                        margin="dense" variant="outlined" style={elementStyle} label="Select Month">
                                        {
                                          payrollMonths.map((n, index) => {
                                            return <MenuItem key={index} value={n.value}>{n.label}</MenuItem>
                                          })
                                        }
                                      </TextField>
                                       &nbsp; &nbsp;
                                      <TextField select value={selectedYears} onChange={(e) => setSelectedYears(e.target.value)} 
                                        margin="dense" variant="outlined" style={elementStyle} label="Select Year">
                                        {
                                          payrollYears.map((n, index) => {
                                            return <MenuItem key={index} value={n.value}>{n.label}</MenuItem>
                                          })
                                        }
                                      </TextField>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Employee</th>
                                                    <th scope="col">Month</th>
                                                    <th scope="col">Payouts</th>
                                                    <th scope="col">Deductions</th>
                                                    <th scope="col">Tax Paid</th>
                                                    <th scope="col">Net Salary</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {filterEmployees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i) => {
                                                const filterPayroll = payroll.filter(x=>x.emp_id === n.id && x.month === selectedMonths && 
                                                  x.year === selectedYears && x.status === 'Approved' )
                                                // const filterPayroll = payroll.filter(x=>x.emp_id === n.id)
                                                
                                                const findPayroll = selectedMonths && selectedYears ? payroll.find(x=>x.emp_id === n.id && x.month === selectedMonths && x.year === selectedYears)
                                                : payroll.find(x=>x.emp_id === n.id && x.month === selectedMonth && x.year === selectedYear)
                                                
                                                const filterMonth = findPayroll && findPayroll.month 
                                                const filterYear = findPayroll && findPayroll.year 
                                                const currentStatus = findPayroll && findPayroll.status

                                                const additions = filterPayroll ? 
                                                filterPayroll.filter(x=>x.category === 'Payouts').reduce((a,b) => a + parseFloat(b.net_salary), 0)
                                                : 
                                                null;
                                                const payouts = Math.round((additions + Number.EPSILON) * 100) / 100;
                                                const subtractions = filterPayroll ? 
                                                filterPayroll.filter(x=>x.category === 'Deductions').reduce((a,b) => a + parseFloat(b.net_salary), 0)
                                                : 
                                                null;
                                                const deductions = Math.round((subtractions + Number.EPSILON) * 100) / 100;
                                                const tax_subtractions = filterPayroll ? 
                                                filterPayroll.filter(x=>x.category === 'Taxes').reduce((a,b) => a + parseFloat(b.net_salary), 0)
                                                : 
                                                null;
                                                const tax_deductions = Math.round((tax_subtractions + Number.EPSILON) * 100) / 100;
                                                const netSal = payouts - deductions - tax_deductions;
                                                const netSalary = Math.round((netSal + Number.EPSILON) * 100) / 100;
                                                return(
                                                <>
                                                    <tr key={n.id}>
                                                      <td className="text-success">
                                                        {n.employee_id}<br/>
                                                        <h6>{n.name}</h6>
                                                      </td>
                                                      <td>{findPayroll ? filterMonth.concat('-', filterYear) : 'NA'}</td>
                                                      <td>{payouts}</td>
                                                      <td>{deductions}</td>
                                                      <td>{tax_deductions}</td>
                                                      <td>{netSalary}</td>
                                                      <td>{findPayroll ? currentStatus : 'NA'}</td>
                                                      <td>
                                                        <button className="btn btn-link text-center" 
                                                        onClick={event => handleExpandRow(event, n.id)}>
                                                            {
                                                            expandState[n.id] ?
                                                            <i className="fa fa-eye-slash" style={{ width: 35, fontSize: 20, padding: 11, color: 'green' }}/> 
                                                            : 
                                                            <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'green' }}/>
                                                            } 
                                                        </button>
                                                      </td>
                                                    </tr>
                                                    <>
                                                    { expandedRows.includes(n.id) ?
                                                     <tr className="text-center">
                                                         <td colSpan={12}>
                                                            <table>
                                                            <thead className="table-warning">
                                                                <th>Category</th>
                                                                <th>Component</th>
                                                                <th>Amount</th>
                                                                <th>LOP</th>
                                                                <th>WorkingDays</th>
                                                                <th>NetSalary</th>
                                                            </thead>
                                                            <tbody>
                                                            {payroll.filter(x=>x.emp_id === n.id && x.month === selectedMonths && x.year === selectedYears)
                                                            .map((n,i) => {
                                                                return(
                                                                    <tr key={i}>
                                                                        <td>{n.category}</td>
                                                                        <td>{n.component}</td>
                                                                        <td>{n.monthly}</td>
                                                                        <td>{n.lop}</td>
                                                                        <td>{n.tot_days}</td>
                                                                        <td>{n.net_salary}</td>
                                                                    </tr>
                                                                );    
                                                              })
                                                             }
                                                            </tbody>
                                                            </table>
                                                         </td>
                                                     </tr>
                                                     : null
                                                    }
                                                    </>
                                                  </>
                                                )}
                                                )
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-6 col-lg-6 col-xl-6 m-t-10">
                                    <i className="fa fa-download ml-3" onClick={() => handleDownloadSubmit()} id="TooltipTop" data-placement="bottom"
                                        style={{ width: 35, fontSize: 22, padding: 15, color: '#178d91' }}>
                                        <UncontrolledTooltip placement="bottom" target="TooltipTop">
                                         Export Data
                                        </UncontrolledTooltip>
                                    </i>
                                    <i className="fa fa-cloud-download ml-3" onClick={() => handleDownloadPT()} id="TooltipTop1" data-placement="bottom"
                                        style={{ width: 35, fontSize: 22, padding: 15, color: 'green' }}>
                                        <UncontrolledTooltip placement="bottom" target="TooltipTop1">
                                         Export PT Deductions
                                        </UncontrolledTooltip>
                                    </i>
                                    <i className="fa fa-download ml-3" onClick={() => handleDownloadPF()} id="TooltipTop2" data-placement="bottom"
                                        style={{ width: 35, fontSize: 22, padding: 15, color: 'orange' }}>
                                        <UncontrolledTooltip placement="bottom" target="TooltipTop2">
                                         Export PF Deductions
                                        </UncontrolledTooltip>
                                    </i>
                                    <i className="fa fa-cloud-download ml-3" onClick={() => handleDownloadIT()} id="TooltipTop3" data-placement="bottom"
                                        style={{ width: 35, fontSize: 22, padding: 15, color: 'blue' }}>
                                        <UncontrolledTooltip placement="bottom" target="TooltipTop3">
                                         Export IT Deductions
                                        </UncontrolledTooltip>
                                    </i> 
                                    <i className="fa fa-credit-card ml-3" onClick={() => handleDownloadBank()} id="TooltipTop4" data-placement="bottom"
                                        style={{ width: 35, fontSize: 22, padding: 15, color: '#3acfb8' }}>
                                        <UncontrolledTooltip placement="bottom" target="TooltipTop4">
                                         Export Bank Data
                                        </UncontrolledTooltip>
                                    </i>
                                    <i className="fa fa-print ml-3" onClick={() => handlePrintMusterWage()} id="TooltipTop5" data-placement="bottom"
                                        style={{ width: 35, fontSize: 22, padding: 15, color: 'cyan' }}>
                                        <UncontrolledTooltip placement="bottom" target="TooltipTop5">
                                         Print Muster Wage Roll
                                        </UncontrolledTooltip>
                                    </i>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterEmployees.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        {/***** Full Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download}
          autoHideDuration={6000}
          onClose={handleReviewClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_payroll}
              filename="PayrollData.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadPayroll} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleReviewClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        {/***** PT Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download_pt}
          autoHideDuration={6000}
          onClose={handlePtClose} 
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_pt}
              filename="ProfessionTax.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadPayroll} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handlePtClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        {/***** PF Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download_pf}
          autoHideDuration={6000}
          onClose={handlePfClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_pf}
              filename="ProvidentFund.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadPayroll} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handlePfClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        {/***** Income Tax Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download_it}
          autoHideDuration={6000}
          onClose={handleItClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_it}
              filename="IncomeTax.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadPayroll} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleItClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        {/***** Bank Data Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download_bank}
          autoHideDuration={5000}
          onClose={handleBankClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_bank}
              filename="BankDetails.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadPayroll} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleBankClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
    </Fragment>
    );
}

export default PayrollFinal2;