import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Autocomplete} from '@material-ui/lab';
import { TextField, MenuItem, FormHelperText, TablePagination } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { getIndInvoice, deleteIndInvoice, postIndInvoice, getIndInvoiceItems, updateIndInvoice, 
         getVendorForm, updateIndInvPayment, uploadIndInvoice, getPorders, getPayments, postPayments } from '../../actions/asyncActions';
import PrintCheque from './PrintCheque.js';

const PaymentProcessing = (props) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [invoice, setInvoice] = useState([]);
  const filterInvoice = invoice.filter(x=>x.status === 'GRN Matched');
  const filterInvoiceApproved = invoice.filter(x=>x.status === 'Approved');
  const [invoicemodal, setInvoiceModal] = useState()
  const [invId, setInvId ] = useState()
  const [invitemsmodal, setInvItemsModal] = useState()
  const [invoiceItems, setInvoiceItems] = useState([]);
  const filterInvItems = invoiceItems.filter(x=>x.invoice_id === invId);
  const findStatus = filterInvItems.find(x=>x.status === 'Approved')
  const [vendorform, setVendors] = useState([]);
  const approvedVendors = vendorform.filter(x=>x.status === 'Approved');
  const [vendor_val, setVendorVal] = useState(null);
  const selectedVendorId = vendor_val && vendor_val.id;
  const selectedVendor = vendor_val && vendor_val.supname;
  const vendorName = (selectedVendor || '').toUpperCase();

  var firstWord = vendorName.replace(/ .*/, ''); //GetFirstWord of Supplier Vendor Name
  // const joinVendor = vendorName.split(" ").join(""); // removed space from the Vendor Name

  function getRandom(length) { //Random Number Generator
    return Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1));
  }

  var getRandomNo = getRandom(12);
  var getTransferId = "transfer".concat(selectedVendorId, getRandomNo); //Transfer ID 

  const filInvVendor = invoice.filter(x=>x.vendor_id == vendorName && x.status === 'GRN Matched');
  const [ppmodal, setPPModal] = useState();

  const [porders, setPorders] = useState([]);
  const [payments, setPayments] = useState([]);

  const checkInvoice = () => {
    setInvoiceModal(!invoicemodal);
  }

  const openInvItems = n => {
    setInvId(n.id)
    setInvItemsModal(!invitemsmodal)

  }

  const handleUpdate = () => {
    if((findStatus || '').status === 'Approved'){
      toast.error("One or more items are yet to be matched with GRN")
      setInvItemsModal(!invitemsmodal)
    } else {

    const aaa = invoice.find(x=>x.id === invId);
    const bbb = vendorform.find(x=>x.supname.toUpperCase() === aaa.vendor_id);
    const getCreditPeriod = bbb.credit;
    var d = new Date(aaa.apr_date);
    d.setDate(d.getDate() + parseInt(getCreditPeriod));
    const data = {
      id: invId,
      status: 'GRN Matched',
      due_date: d
    }
    updateIndInvoice(data)
      .then(resp => {
        getIndInvoice(group_id)
        .then(resp => setInvoice(resp.data))
        toast.success("Status Updated!!!")
        setInvoiceModal(!invoicemodal)
        setInvItemsModal(!invitemsmodal)
      })
      .catch(err => {
        console.log("Error", err);
    })
   }
  }

  const openPaymentProcess = () => {
    setPPModal(!ppmodal)
    setError('')
    setAddItems([])
  }

  const modeofpay = [{name: 'Cashfree'}, {name: 'Card'}, {name: 'Cash'}, {name: 'UPI'}, {name: 'Bank Transfer'}, {name: 'Cheque'} ]
  const [modeval, setModeVal] = useState(null);

  const [errors, setError] = useState({});
  const validateForm = (data, i) => {
   const errors = [];
    if(!data[i].mode){
      errors.mode = "Please choose mode of payment";
    }
    if(data[i].amount > data[i].invAmount){
      errors.price = "Entered amount is greater than invoice Amount";
    }
    if(data[i].amount + data[i].paidAmt > data[i].invAmount){
      errors.price = "Entered amount is greater than invoice Amount"; 
    }
   return errors;
 }

  const handleSubmit = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    let fieldsComp = addItems;
    const arr = [];
    fieldsComp.forEach(item => {
      arr.push({ 
        id:item.id
      })
    });
    const data = {
      items: arr,
      group_id: group_id
    }

    let paymentdata = [];
    let errors = [];
    let errorsQty = [];
    let invoiceAmt;
    price.forEach(async (obj, idx) => {
      if(obj.price > 0){
        const {id, po_no, vendor_id, invoice_amount, invType } = fieldsComp[idx];
        const filterInvItems = invoiceItems.filter(x=>x.invoice_id === id);
        const itemAmt = filterInvItems.reduce((a,b) => a + parseFloat(b.amount), 0);
        const itemGst = filterInvItems.reduce((a,b) => a + parseFloat(b.amount * (parseFloat(b.gst)/100)), 0);
        const filterPorders = porders.find(x=>x.id == po_no)
        const tds = filterPorders && filterPorders.tds
        const invAmount = itemAmt + itemGst
        const invTdsAmt = itemAmt - (itemAmt * (parseInt(tds)/100)) + itemGst
        if(invType === 'Service'){
          invoiceAmt = invTdsAmt
        }
        else{
          invoiceAmt = invAmount
        }
        const pay_amount = payments.find(x=>x.invoice_id == id);
        const paidAmt = (pay_amount && pay_amount.amount) || 0;
        const sprice = parseFloat(obj.price || 0);
        if(paidAmt){
          paymentdata.push({
            invoice_id: id,
            amount: sprice,
            paidAmt: parseFloat(paidAmt),
            invAmount: invoiceAmt,
            po_no: po_no,
            vendor_id: vendor_id,
            mode: modeval && modeval.name,
            paid_date: new Date(),
            user_id: user.id,
            group_id: user.group_id
          })
          errors = validateForm(paymentdata, idx);

          if(Object.keys(errors).length) {
           errorsQty.push({
            aaa: errors
           });
           setError(errors);
           return;
          }  
        }
        else {
          paymentdata.push({
            invoice_id: id,
            amount: sprice,
            invAmount: invoiceAmt,
            po_no: po_no,
            vendor_id: vendor_id,
            mode: modeval && modeval.name,
            paid_date: new Date(),
            user_id: user.id,
            group_id: user.group_id
          })
          errors = validateForm(paymentdata, idx);

          if(Object.keys(errors).length) {
            errorsQty.push({
              aaa: errors
            });
            setError(errors);
            return;
          }
        }
      }
    })
    const pdata = {
      items: paymentdata,
      transferId: getTransferId,
      vid: selectedVendorId,
      price: addPrice
    }
    console.log("paymentdata", paymentdata)
    if(Object.keys(errorsQty).length > 0){
      return;
    }
    else {

      //updateIndInvPayment(data)
      postPayments(pdata)
      .then(resp => {
          getIndInvoice(group_id)
          .then(resp => setInvoice(resp.data))
          getPayments(group_id)
          .then(resp => setPayments(resp.data))
          
          if(modeval && modeval.name === 'Cheque'){
            handlePrintCheque();
          }

          toast.success("Status Updated!!!")
          setPPModal(!ppmodal)
          setVendorVal('')
          setModeVal('')
          setPrice([{}]);
        })
        .catch(err => {
          console.log("Error", err);
      })
    }
  }

  const [values, setValues] = useState({});
  const [invoiceDate, handleInvoiceDate] = useState(new Date());
  const [invoicefile, setInvoiceFile] = useState([]);
  const [fields, setFields] = useState([{ description: '', hsn:'', gst:'', length:'', width:'', nos:'', qty:'', rate:'', amount:'' }]);
  
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  function addClick(){
    const values = [...fields];
    values.push({ description: '', hsn:'', gst:'', length:'', width:'', nos:'', qty:'', rate:'', amount:'' });
    setFields(values);
    }

  function handleRemove(i){
    const values = [...fields];
    values.splice(i,1);
    setFields(values);
  }

  function handleChange3(i,e){
    const tempValues = [...fields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFields(tempValues);
  }

  const handleUploadInvoice = async (event) => {
    setInvoiceFile(document.getElementById('invoicefile').files[0]);
  }
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect( () => {
    getIndInvoice(group_id)
     .then(resp => setInvoice(resp.data))
  }, []);

  useEffect( () => {
    getIndInvoiceItems(group_id)
     .then(resp => setInvoiceItems(resp.data))
  }, []);

  useEffect( () => {
    getVendorForm(group_id)
     .then(resp => setVendors(resp.data))
  }, []);

  useEffect( () => {
   getPorders(group_id)
    .then(resp => setPorders(resp.data))
  }, []);

  useEffect( () => {
   getPayments(group_id)
    .then(resp => setPayments(resp.data))
  }, []);
  
  const viewRow = (n) => {
   const id = n.id;
   const invoiceNo = n.invoice_no;
   const invoiceDate = n.invoice_date;
   const custName = n.customer_name;
   const email = n.email_address;
   const website = n.customer_website;
   const amount = n.invoice_amount;
   const invFile = n.inv_file;
   const invExt = n.inv_ext;
   const remarks = n.remarks;
   const vendor_name = n.vendor_id;
   const temp_id = n.temp_id;
   const gst_no = n.gst_no;
   const filterInvItems = invoiceItems.filter(x=>x.invoice_id === n.id);
   props.history.push(`${process.env.PUBLIC_URL}/viewinvoice`, 
    {id,invoiceNo,invoiceDate,custName,email,website,amount,invFile,invExt,filterInvItems,remarks,vendor_name,temp_id,gst_no});
  }

  const deleteRow = (n) => {
    const deleteId = {
      id : n.id,
      temp_id: n.temp_id
    }
    deleteIndInvoice(deleteId)
      .then(resp => {
        getIndInvoice(group_id)
        .then(resp => setInvoice(resp.data))
        toast.error("Invoice deleted!!!")
      })
      .catch(err => {
        console.log("Error", err);
    })
  }

  const [price, setPrice] = React.useState([{}]);
  const addPrice = price.reduce((a, b) => a + parseFloat(b.price), 0);
  const [addItems, setAddItems] = useState([]);
  const addAmount = addItems.reduce((a, b) => a + parseInt(b.invoice_amount), 0);

  const handlePriceChange = (event, i) => {
    const tempQtys = [...price];
    tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
    setPrice(tempQtys);
  }

  const handlePrintCheque = () => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(`<html><head><title>${document.title}</title>
     <style>
     @page { margin: 0 }
      body { margin: 0 }
      .sheet {
      margin: 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      page-break-after: always;
      }

      /* Padding area */
      .sheet.padding-5mm { padding: 5mm }

      /* For screen preview */
      @media screen {
      body { background: #e0e0e0 }
        .sheet {
         background: white;
         box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
         margin: 2mm;
       }
       table {
          border-collapse: collapse;
      }
       tr.border_bottom td {
        border-bottom:1pt solid black;
      }
      table th, table td {
      border:1px solid #000;
        padding: 0.5em;
      }
      }
      @media print {
          html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
          font-size: 9pt !important;
               }
            }

     </style>
     `);
   mywindow.document.write('</head><body>');
   mywindow.document.write(document.getElementById('printreceipt').innerHTML);
   mywindow.document.write('</body></html>');

   mywindow.document.close(); // necessary for IE >= 10
   mywindow.focus(); // necessary for IE >= 10*/

   mywindow.print();
   mywindow.close();

   return true;
  }

  return (
      <Fragment>
        <div style={{display:"none"}}>
          <PrintCheque 
            selectedVendor={selectedVendor}
            rupees={addPrice}
          />
        </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-info">
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Invoice no</th>
                                            <th scope="col">Customer</th>
                                            <th scope="col">Invoice Amount</th>
                                            <th scope="col">Paid Amount</th>
                                            <th scope="col">Due Date</th>
                                            {/*<th scope="col">Action</th> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                            {filterInvoice.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((n, i)=> {
                                              const filterPayments = payments.filter(x=>x.invoice_id == n.id);
                                              const itemAmt = filterPayments.reduce((a,b) => a + parseFloat(b.amount), 0);
                                              return (
                                                <tr>
                                                 <th scope="row">{i+1}</th>
                                                 <td>{n.invoice_no}</td>
                                                 <td>{n.customer_name}</td>
                                                 <td>{n.invoice_amount}</td>
                                                 <td>{itemAmt}</td>
                                                 <td>
                                                  {new Date(n.due_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                 </td>
                                                 {/* new Date() > n.due_date ?
                                                  <td>
                                                   <button>
                                                    <i className="fa fa-eye" onClick={() => viewRow(n)} id="TooltipTop" data-placement="top"
                                                     style={{ width: 35, fontSize: 18, padding: 11, color: 'orange' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      Process Payment
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </button>
                                                   </td>
                                                  :
                                                  <td>
                                                   <button>
                                                    <i className="fa fa-eye" onClick={() => openPaymentProcess()} id="TooltipTop" data-placement="top"
                                                     style={{ width: 35, fontSize: 18, padding: 11, color: 'green' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      Early Payment
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </button>
                                                   </td>
                                                 */}
                                                </tr>
                                              );
                                            })
                                          }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-block row">
                                  <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                   <button className="btn btn-info btn-sm" type="button" onClick={checkInvoice}>
                                     Check for Invoices
                                    </button> &nbsp;&nbsp;
                                    <button className="btn btn-info btn-sm" type="button" onClick={() => openPaymentProcess()}>
                                      Process Payment
                                    </button>
                                  </div>
                                  <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <TablePagination
                                      rowsPerPageOptions={[5, 10, 25]}
                                      component="div"
                                      count={filterInvoice.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onChangePage={handleChangePage}
                                      onChangeRowsPerPage={handleChangeRowsPerPage}
                                     />
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      {/* < !-- Using Modal for Checking Approved Invoices  --> */}
            <Modal isOpen={invoicemodal} toggle={checkInvoice} size="md">
              <ModalHeader toggle={checkInvoice}>Approved Invoices</ModalHeader>  
                <ModalBody>
                    <form>
                     <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                       {filterInvoiceApproved.map((n,i)=> {
                        return(
                         <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                           <button type="button" className="btn btn-primary btn-outline" style={{height:'35px'}}
                            onClick={() => openInvItems(n)}>
                            {n.invoice_no}
                           </button>
                          </li>
                         </ul>
                        )
                        })
                       }  
                      </div>
                     </div>
                    </form>
                </ModalBody>
            </Modal>

      {/* < !-- Using Modal for Viewing  Invoice Items  --> */}
            <Modal isOpen={invitemsmodal} toggle={openInvItems} size="lg">
              <ModalHeader toggle={openInvItems}>Invoice Items</ModalHeader>  
                <ModalBody>
                  <div className="col-xl-12">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                         <thead className="table-info">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Description</th>
                            <th scope="col">HSN</th>
                            <th scope="col">GST</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Rate</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                          </tr>
                         </thead>
                         <tbody>
                          {filterInvItems.map((n,i)=> {
                            return(
                             <tr>
                              <td>{i+1}</td>
                              <td>{n.description}</td>
                              <td>{n.hsn}</td>
                              <td>{n.gst}</td>
                              <td>{n.qty}</td>
                              <td>{n.rate}</td>
                              <td>{n.amount}</td>
                              <td>{n.status}</td>
                             </tr>
                            );
                           })
                          }
                         </tbody>
                        </table>
                      </div>
                  </div>
                  <div className="col-xl-12 text-center m-t-15">
                   <button className="btn btn-success btn-md" type="button" onClick={handleUpdate}>
                    Update Invoice
                   </button>
                  </div>
                </ModalBody>
            </Modal>

      {/* Payment Process Modal */}
        <Modal isOpen={ppmodal} toggle={openPaymentProcess} className="modal-dialog modal-xl">
             <ModalHeader toggle={openPaymentProcess}>Payment Processing</ModalHeader>
              <ModalBody>
                <div className="offset-sm-3 col-xl-6 col-lg-6 col-sm-12">
                  <Autocomplete
                    freeSolo
                    options={approvedVendors}
                    getOptionLabel={option => option.supname}
                    value={vendor_val}                      
                    onChange={(event, newValue) => {
                      setVendorVal(newValue);
                    }}            
                    renderInput={params => (
                      <TextField {...params} label="Vendor" margin="normal" variant="outlined" helperText="Please select Vendor" fullWidth />
                    )}
                  />
                </div>
                { selectedVendor ?
                  <Fragment>
                  <div className="col-xl-12">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered table-hover">
                          <thead className="table-info">
                              <tr>
                                <th scope="col">Sl.No</th>
                                <th scope="col">Inv No</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Paid Amt</th>
                                <th scope="col">To Pay</th>
                                <th scope="col">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              {filInvVendor.map((n, i)=> {
                                const filterInvItems = invoiceItems.filter(x=>x.invoice_id === n.id);
                                const itemAmt = filterInvItems.reduce((a,b) => a + parseFloat(b.amount), 0);
                                const itemGst = filterInvItems.reduce((a,b) => a + parseFloat(b.amount * (parseFloat(b.gst)/100)), 0);
                                const filterPorders = porders.find(x=>x.id == n.po_no)
                                const tds = filterPorders && filterPorders.tds
                                const invAmount = itemAmt + itemGst
                                const invTdsAmt = itemAmt - (itemAmt * (parseInt(tds)/100)) + itemGst
                                const aaa = payments.find(x=>x.invoice_id == n.id) 
                                const paidAmt = aaa && aaa.amount
                                const toPay = parseFloat(n.invoice_amount) - paidAmt;
                                return (
                                  <tr>
                                   <td>{i+1}</td>
                                   <td>{n.invoice_no}</td>
                                   <td>{n.customer_name}</td>
                                   {n.invType === 'Product' ?
                                    <td>{invAmount}</td>
                                    :
                                    <td>{invTdsAmt}</td>
                                   }
                                   
                                   <td>{paidAmt}</td>
                                   <td>
                                    <TextField type="text" name="price" key={i} margin="dense"
                                      value = {price[i] ? price[i].price: 0} onChange={(e) => handlePriceChange(e, i)}/>
                                      <FormHelperText className="text-danger">{errors.price}</FormHelperText>
                                   </td>
                                   <td>
                                    <input type="checkbox" checked={addItems.includes(n)} 
                                     onChange={(e) => {
                                      const checked = addItems.includes(n)
                                      setAddItems((prev) => 
                                       checked
                                      ? prev.filter((sc) => sc !== n)
                                      : [...prev, n ]
                                      );
                                     }} 
                                    />
                                   </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                      </table>
                    </div>
                </div> <br/>
                {addAmount > 0 ?
                 <div className="col-sm-12 text-center m-t-15">
                  <h4> Payment Amount = {addPrice}</h4> <br/>
                  <div className="offset-sm-4 col-xl-4 col-lg-4 col-sm-12">
                    <Autocomplete
                      freeSolo
                      options={modeofpay}
                      getOptionLabel={option => option.name}
                      value={modeval}                      
                      onChange={(event, newValue) => {
                        setModeVal(newValue);
                      }}            
                      renderInput={params => (
                        <TextField {...params} label="Payment Mode" margin="normal" variant="outlined" helperText="Choose mode of payment" fullWidth />
                      )}
                    />
                    <FormHelperText className="text-danger">{errors.mode}</FormHelperText>
                  </div> <br/>
                  <button className="btn btn-success btn-md" type="button" onClick={handleSubmit}>
                   Process Payment
                  </button>
                 </div>
                 : 
                 null  
                }
              </Fragment>
                   : 
                   null
                }
              </ModalBody>
            </Modal>      
      </Fragment>
    );
}

export default withRouter(PaymentProcessing);