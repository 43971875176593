import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Autocomplete } from '@material-ui/lab';
import { TextField, MenuItem, FormHelperText, TablePagination, Snackbar, Slide, IconButton, Chip } from '@material-ui/core';
import { CSVLink } from "react-csv";
import { Download } from 'react-feather';
import {Close as CloseIcon } from "@material-ui/icons";
import { withRouter } from 'react-router-dom';
import { getBankStmt, downloadBankStatement, getTags, postTags } from '../../actions/asyncActions';
import Banktable from './Banktable';
import TagsInput from './TagsInput';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const BankReview = (props) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;

  const elementStyle ={
	  border:'solid',
	  borderRadius:'10px',
	  position:'relative',
	  left:'1vh',
	  height:'6vh',
	  width:'23vh',
	  marginTop:'1vh',
	  marginBottom:'1vh',
	  textAlign: 'center'
  }

  const chipStyle = {
  	display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '1vh',
    margin: '1vh',
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [bankStmt, setBankStmt] = useState([]);
  const [download_bank, setDownload] = useState([]);
  const [download_filter_bank, setFilterDownload] = useState([]);
  const [tags, setTags] = useState([]);
  const [multiSelections, setMultiSelections] = useState([]);
 
  useEffect( () => {
    getBankStmt(group_id)
     .then(resp => setBankStmt(resp.data))
  }, []);

  useEffect( () => {
    downloadBankStatement(group_id)
    .then(resp => setDownload(resp.data))
  }, []);

  useEffect( () => {
    getTags(group_id)
    .then(resp => setTags(resp.data))
  }, []);

  const [selecttagmodal, setSelectTagModal] = useState()
  const addTagRow = (n) => {
  	setSelectTagModal(!selecttagmodal);
  }

  const [tagValue, setTagValue] = useState()
  const [tagmodal, setTagModal] = useState()
  const addTag = () => {
    setTagModal(!tagmodal);
  }

  const handleSubmit = () => {
  	const data = {
  		name: tagValue,
  		user_id: user.id,
  		group_id: group_id
  	}
  	postTags(data)
  	.then(resp => setTags(resp.data))
  	setTagValue('');
  	setTagModal(!tagmodal);
  }

  const [open_download, setOpenDownload] = React.useState(false);
  const handleReviewClose = () => {
    setOpenDownload(false);
  };

  const handleDownloadSubmit = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const data = {
      group_id: user.group_id 
    }
    console.log("data", data)
      downloadBankStatement(data)
      .then(resp => {
        setDownload(resp.data);
        console.log('Download File', resp.data);
      })
      .catch(err => {
        console.log("Error", err);
      })
      setOpenDownload(true);
  }

  const [open_filter_download, setOpenFilterDownload] = React.useState(false);
  const handleReviewClose2 = () => {
    setOpenFilterDownload(false);
  };

  const handleFilterDownloadSubmit = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    
    let downloadData = filteredBankStmt;
    let bankItems_data = [];
    downloadData.forEach(item => {
	  bankItems_data.push({ 
	    date: item.date, particulars: item.particulars, cheque_no: item.cheque_no, value_dt: item.value_dt,
	    withdrawals: item.withdrawals, deposits: item.deposits, balance: item.balance, filename: item.filename
	  })
    });
      setFilterDownload(bankItems_data);
      setOpenFilterDownload(true);
  }

  const [search, setSearch] = useState('');
  const updateSearch = (event) => {
    setSearch(event.target.value.substr(0,20));
  }

  const [filteredBankStmt, setFilteredBankStmt] = useState([]);
  
  useEffect(() => {
  	setFilteredBankStmt(
  	 bankStmt.filter(n => {
  	 	return n.particulars.toLowerCase().includes(search.toLowerCase());
  	 })
  	)
  }, [search, bankStmt])

  const selectedTags = tags => {
    console.log(tags);
  };

    return (
      <Fragment>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                             <div className="row">
                               <div className="col">
	                           	<input type="text" placeholder="Search Items" style={elementStyle} value={search}
	                              onChange={e => setSearch(e.target.value)} />&nbsp;&nbsp;
	                        	 {search?
		                        	<button className="btn btn-info btn-sm" type="button" onClick={handleFilterDownloadSubmit}>
		                             	<Download size="14"/>Export
		                            </button>
		                            : null
	                        	 }
	                           </div>
                              </div>
                             </div>
                             <div className="col-sm-12 col-lg-12 col-xl-12">
                             {/* <Banktable data={searchRow(bankStmt)} /> */}
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-info">
                                          <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">File</th>
                                            <th scope="col">Particulars</th>
                                            <th scope="col">ChequeNo</th>
                                            <th scope="col">Withdrawals</th>
                                            <th scope="col">Deposits</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            {filteredBankStmt.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((n, i)=> {
                                              return (
                                                <tr>
                                                 <td>
                                                  {new Date(n.date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                 </td>
                                                 <td>{n.filename}</td>
                                                 <td>{n.particulars}</td>
                                                 <td>{n.cheque_no}</td>
                                                 <td>{n.withdrawals}</td>
                                                 <td>{n.deposits}</td>
                                                 <td>{n.balance}</td>
                                                 <td>
                                                   <button>
                                                    <i className="fa fa-plus" onClick={() => addTagRow(n)} id="TooltipTop" data-placement="top"
                                                     style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      Add Tags
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </button>
                                                 </td>
                                                </tr>
                                              );
                                            })
                                          }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-block row">
                                  <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                   <button className="btn btn-info btn-sm" type="button" onClick={addTag}>
                                     Create Tags
                                    </button>&nbsp;&nbsp;
                                    <button className="btn btn-info btn-sm" type="button" onClick={handleDownloadSubmit}>
                                     <Download size="14"/>Export
                                    </button>
                                  </div>
                                  <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <TablePagination
                                      rowsPerPageOptions={[5, 10, 25]}
                                      component="div"
                                      count={filteredBankStmt.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onChangePage={handleChangePage}
                                      onChangeRowsPerPage={handleChangeRowsPerPage}
                                     />
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      {/* < !-- Using Modal for Creating Tags --> */}
            <Modal isOpen={tagmodal} toggle={addTag} size="md">
                <div className="media m-t-15">
                 <div className="media-body">
                  <h4 className="f-w-600 text-center">
                      <u>Create Tags</u>
                  </h4>
                 </div>
                </div>
                <ModalBody>
                  <div className="card-body text-center">
                    <input className="form-control" name="name" type="text" 
                      value={tagValue} onChange={(e) => setTagValue(e.target.value)}/>
                  </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={addTag}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit}>Submit</Button>
                </ModalFooter>
            </Modal>

      {/* < !-- Using Modal for Selecting Tags --> */}
            <Modal isOpen={selecttagmodal} toggle={addTagRow} size="lg">
                <div className="media m-t-15">
                 <div className="media-body">
                  <h4 className="f-w-600 text-center">
                      <u>Select Tags</u>
                  </h4>
                 </div>
                </div>
                <ModalBody>
                  <TagsInput selectedTags={selectedTags}  tags={['Rent', 'Electricity', 'DTH Payment', 'Family']}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={addTagRow}>Cancel</Button>
                    <Button color="primary">Submit</Button>
                </ModalFooter>
            </Modal>

      {/***** Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download}
          autoHideDuration={6000}
          onClose={handleReviewClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_bank}
              filename="BankStatement.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadBankStatement} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleReviewClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

    {/***** Download Filtered Items CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_filter_download}
          autoHideDuration={6000}
          onClose={handleReviewClose2}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_filter_bank}
              filename="BankStatement.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadBankStatement} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleReviewClose2}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
          
      </Fragment>
    );
}

export default withRouter(BankReview);