import React, { Fragment } from 'react';
import { TextField, Typography, Grid, FormHelperText } from '@material-ui/core';

const Education = (props) => {

    const { values, errors } = props
    
    return(
     <Fragment>
       <Typography variant="h6" gutterBottom>
            Educational Certificates
        </Typography>
       <Grid container spacing={3} >
       <Grid item xs={12} sm={12} className="m-t-15">
            <Typography gutterBottom>
                X Certificate:
            <input className="ml-2" id="file_tenth" type="file" variant="outlined" onChange={props.handleUploadTenth} /> 
            </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className="m-t-15">
            <Typography gutterBottom>
                XII Certificate: 
            <input className="ml-2" id="file_twelth" type="file" variant="outlined" onChange={props.handleUploadTwelth} />  
            </Typography>   
        </Grid>
        <Grid item xs={12} sm={12} className="m-t-15">
            <Typography gutterBottom>
                Degree Certificate: 
            <input className="ml-2" id="file_degree" type="file" variant="outlined" onChange={props.handleUploadDegree} />  
            </Typography>   
        </Grid>
      </Grid>
     </Fragment>
    );
}

export default Education;