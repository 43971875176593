import React, { Fragment } from 'react';
import { TextField, Typography, Grid, FormHelperText } from '@material-ui/core';

const OtherDetails = (props) => {

    const { values, errors } = props
    
    return(
     <Fragment>
       <Grid container spacing={3} >
       <Grid item xs={12} sm={6}>
            <Typography gutterBottom>
            PAN Number:
            </Typography>
            <TextField id="panno" name="panno" value={values.panno} 
            onChange={props.handleChange} fullWidth />
            <FormHelperText className="text-danger">{errors.panno}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} className="m-t-15">
            <Typography gutterBottom>
            <input id="files" type="file" variant="outlined" onChange={props.handleuploadPAN} /> 
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Typography gutterBottom>
            Aadhar Number: 
            </Typography>
            <TextField id="aadharno" name="aadharno" value={values.aadharno} 
            onChange={props.handleChange} fullWidth />
            </Grid>
            <Grid item xs className="m-t-15">
            <Typography gutterBottom>
                <input id="file" type="file" variant="outlined" onChange={props.handleUploadAadhar} />  
            </Typography>   
        </Grid>
        <Grid item xs={12} sm={6}>
            <Typography gutterBottom>
            Passport Number: 
            </Typography>
            <TextField id="passport" name="passport" value={values.passport} 
            onChange={props.handleChange} fullWidth />
            </Grid>
            <Grid item xs className="m-t-15">
            <Typography gutterBottom>
                <input id="fileppt" type="file" variant="outlined" onChange={props.handleUploadPassPort} />  
            </Typography>   
        </Grid>
        <Grid item xl={6} sm={6}>
         <Typography gutterBottom>
            PF Number:
         </Typography>
         <TextField id="pfno" name="pfno" value={values.pfno} 
           onChange={props.handleChange} fullWidth />
        </Grid>
        <Grid item xs className="m-t-15">
         <Typography gutterBottom>
            <input id="filepf" type="file" variant="outlined" onChange={props.handleUploadPf} />   
         </Typography> 
        </Grid>
        
      </Grid>
     </Fragment>
    );
}

export default OtherDetails;