import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormHelperText, MenuItem, TablePagination } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {
  getClients, getProjects, postProjects, editProjects, approveProjects, getCostSheet,
  BulkUploadCostSheet, postCostSheet2, sendManagerEmail, getAllUser, deleteProjects
} from '../../../actions/asyncActions';
import { toast } from 'react-toastify';

const ProjectDetails = (props) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const mgr_id = user.mgr_id;

  const [users, setUsers] = useState([])
  const initialFormState = {
    id: '', name: '', po_value: '', start_date: '', end_date: '',
    billing_address: '', gstin: '', client_id: ''
  }
  const [data, setCurrentData] = useState(initialFormState)
  const [modal, setModal] = useState();
  const [costsheetmodal, setCostSheetModal] = useState();

  const [addmodal, setAddModal] = useState();
  const [values, setValues] = useState({});
  const [errors, setError] = useState([]);

  const [projects, setProject] = useState([])
  const [clients, setClient] = useState([])
  const [costSheet, setCostSheet] = useState([])
  const filterCostSheet = costSheet.filter(x => x.project_id === data.id); // Cost Sheet Data filtered by project

  const submitted_data = projects.filter(s => s.status === 'submitted' && (s.mgr_id === user.id || s.user_id === user.id));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getProjects(group_id)
      .then(resp => setProject(resp.data))
  }, []);

  useEffect(() => {
    getClients(group_id)
      .then(resp => setClient(resp.data))
  }, []);

  useEffect(() => {
    getCostSheet(group_id)
      .then(resp => setCostSheet(resp.data))
  }, []);

  useEffect(() => {
    getAllUser(group_id)
      .then(resp => setUsers(resp.data))
  }, []);

  const [StartDate, setStartDate] = React.useState(new Date());
  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const [EndDate, setEndDate] = React.useState(new Date());
  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleInputChange = event => {
    const { name, value } = event.target
    setCurrentData({ ...data, [name]: value })
  }

  const addRow = () => {
    setAddModal(!addmodal)
  }

  const viewRow = n => {
    const projectId = n.id;
    props.history.push(`${process.env.PUBLIC_URL}/viewproject`, { projectId });
  }

  const editRow = n => {
    setModal(!modal)
    setCurrentData({
      id: n.id, name: n.name, po_value: n.po_value, start_date: n.start_date, end_date: n.end_date,
      billing_address: n.billing_address, gstin: n.gstin, client_id: n.client_id
    })
  }

  const deleteRow = id => {
    const data = {
      id: id
    }
    deleteProjects(data)
      .then(resp => {
        getProjects(group_id)
          .then(resp => setProject(resp.data))
        toast.warn("Project deleted successfully !!!", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
      })
  }

  const editCostSheet = n => {
    setCostSheetModal(!costsheetmodal)
    setCurrentData({ id: n.id, client_id: n.client_id })
  }

  const [costsheetfile, setFile] = useState();
  const handleUploadCostSheet = (files) => {
    setFile(document.getElementById('file').files[0]);
  };

  const validateForm = (data) => {
    const errors = [];
    if (!data.name) {
      errors.name = "Project Name is required";
    }
    return errors;
  }

  const handleSubmit = () => {
    const findMgr = users.find(x => x.id === mgr_id)
    const mgrEmail = findMgr && findMgr.email;

    const emailData = {
      email: mgrEmail,
      type: 'project'
    }

    const data = {
      name: values.name,
      po_value: values.po_value,
      start_date: StartDate,
      end_date: EndDate,
      billing_address: values.billing_address,
      status: 'submitted',
      client_id: values.client_id,
      gstin: values.gstin,
      mgr_id: mgr_id,
      user_id: user.id,
      group_id: group_id,
    }
    const errors = validateForm(data);
    if (Object.keys(errors).length) {
      setError(errors);
      return;
    }
    postProjects(data)
      .then(resp => {
        if (mgrEmail == '') {
        } else {
          sendManagerEmail(emailData)
        }
        getProjects(group_id)
          .then(resp => setProject(resp.data))
        setValues({ id: '', name: '', start_date: '', end_date: '', billing_address: '', gstin: '', client_id: '' });
      })
      .catch(err => {
        console.log("Error", err);
      })
    setAddModal(!addmodal)
  }

  const handleUpdate = (id, data) => {
    const cat = {
      id: id,
      name: data.name,
      po_value: data.po_value,
      start_date: StartDate,
      end_date: EndDate,
      billing_address: data.billing_address,
      gstin: data.gstin,
      client_id: data.client_id
    }
    const errors = validateForm(data);
    if (Object.keys(errors).length) {
      setError(errors);
      return;
    }
    editProjects(cat)
      .then(resp => {
        getProjects(group_id)
          .then(resp => setProject(resp.data))
      })
      .catch(err => {
        console.log("Error", err);
      })
    setModal(!modal)
  }

  const approveProject = id => {
    const data = {
      id: id,
      status: 'approved'
    }
    approveProjects(data)
      .then(resp => {
        getProjects(group_id)
          .then(resp => setProject(resp.data))
      })
      .catch(err => {
        console.log("Error", err);
      })
  }

  const handleClickOpen = () => {
    //window.location.hash=`/${group_id}/pos/projectstepper`
    props.history.push(`${process.env.PUBLIC_URL}/projectstepper`);
  }

  const handleCostSheetSubmit = () => {
    const formData = new FormData()
    formData.append('file', costsheetfile)
    const data = {
      id: data.id,
      client_id: data.client_id,
      group_id: group_id
    }
    BulkUploadCostSheet(formData)
      .then(resp => {
        postCostSheet2(data)
          .then(resp => {
            getCostSheet(group_id)
              .then(resp => setCostSheet(resp.data))
            setCostSheetModal(!costsheetmodal)
            toast.success("Costsheet uploaded successfully!!!", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
          })
        //Post Cost Sheet here with sunil's python script, check invoiceprocess for the data part
      })

  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-block row">
                <div className="col-sm-12 col-lg-12 col-xl-12">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="table-primary">
                        <tr>
                          <th scope="col">Project</th>
                          <th scope="col">PO Value</th>
                          <th scope="col">Client Name</th>
                          <th scope="col">Schedule Start</th>
                          <th scope="col">Schedule End</th>
                          <th scope="col">GSTIN</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {submitted_data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i) => {
                          const clientName = clients.find(s => s.id === n.client_id);
                          return (
                            <tr>
                              <td>{n.name}</td>
                              <td>{n.po_value}</td>
                              <td>{clientName && clientName.name}</td>
                              <td>
                                {new Date(n.start_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                              </td>
                              <td>
                                {new Date(n.end_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                              </td>
                              <td>{n.gstin}</td>
                              <td>
                                {/*
                                                    <i className="fa fa-eye" onClick={() => viewRow(n)} id="TooltipTop2" data-placement="top"
                                                     style={{ width: 35, fontSize: 16, padding: 11, color: '#178d91' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop2">
                                                      View project details
                                                     </UncontrolledTooltip>
                                                    </i>
                                                  */}
                                <i className="fa fa-edit" onClick={() => editRow(n)} id="TooltipTop" data-placement="bottom"
                                  style={{ width: 35, fontSize: 16, padding: 11, color: '#115f3f' }}>
                                  <UncontrolledTooltip placement="bottom" target="TooltipTop">
                                    Edit Project Details
                                  </UncontrolledTooltip>
                                </i>
                                {n.status === 'submitted' ?
                                  <i className="fa fa-trash" onClick={() => deleteRow(n.id)} id="TooltipTop2" data-placement="bottom"
                                    style={{ width: 35, fontSize: 16, padding: 11, color: '#FF0000 ' }}>
                                    <UncontrolledTooltip placement="bottom" target="TooltipTop2">
                                      Delete Project Details
                                    </UncontrolledTooltip>
                                  </i>
                                  : null
                                }
                                {/* <i className="fa fa-crop" onClick={() => editCostSheet(n)} id="TooltipTop2" data-placement="bottom"
                                  style={{ width: 35, fontSize: 16, padding: 11, color: 'blue' }}>
                                  <UncontrolledTooltip placement="bottom" target="TooltipTop2">
                                    Edit Costsheet
                                  </UncontrolledTooltip>
                                </i> */}
                                {n.mgr_id === user.id ?
                                  <i className="fa fa-thumbs-up" onClick={() => approveProject(n.id)} id="TooltipTop1" data-placement="bottom"
                                    style={{ width: 35, fontSize: 16, padding: 11, color: '#d81cb8' }}>
                                    <UncontrolledTooltip placement="bottom" target="TooltipTop1">
                                      Approve Project
                                    </UncontrolledTooltip>
                                  </i>
                                  : null
                                }
                              </td>
                            </tr>
                          );
                        })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block row">
                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                        <button className="btn btn-info btn-sm" type="button" onClick={() => handleClickOpen()}>
                          Add Project
                        </button>
                      </div>
                      <div className="col-sm-6 col-lg-6 col-xl-6">
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={submitted_data.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* < !-- Using Form Modal for Add Clients --> */}
      <Modal isOpen={addmodal} toggle={addRow}>
        <ModalHeader toggle={addRow}>Add Client</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <TextField fullWidth required id="name" label="Name" margin="dense" name="name"
                  onChange={handleChange} value={values.name} variant="outlined"
                />
                <FormHelperText>{errors.name}</FormHelperText>
              </div>
              <div className="col-md-6 mb-3">
                <TextField fullWidth label="Phone" margin="dense" name="phone"
                  onChange={handleChange} value={values.phone} variant="outlined" id="phone"
                />
              </div>
              <div className="col-md-6 mb-3">
                <TextField fullWidth label="Email" margin="dense" name="email"
                  onChange={handleChange} value={values.email} variant="outlined" id="email"
                />
              </div>
              <div className="col-md-6 mb-3">
                <TextField fullWidth label="Contact Person" margin="dense" name="contact_person"
                  onChange={handleChange} value={values.contact_person} variant="outlined" id="contact_person"
                />
              </div>
              <div className="col-md-6 mb-3">
                <TextField fullWidth label="GSTIN" margin="dense" name="gstin"
                  onChange={handleChange} value={values.gstin} variant="outlined" id="gstin"
                />
              </div>
              <div className="col-md-6 mb-3">
                <TextField fullWidth label="PAN" margin="dense" name="pan"
                  onChange={handleChange} value={values.pan} variant="outlined" id="pan"
                />
              </div>
              <div className="col-md-12 mb-3">
                <TextField fullWidth label="Address" margin="dense" name="billing_address" multiline
                  onChange={handleChange} value={values.billing_address} variant="outlined" id="billing_address"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="col-form-label" htmlFor="message-text">MSA Start Date:</label>
                <DatePicker className="form-control digits"
                  selected={StartDate}
                  value={StartDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="col-form-label" htmlFor="message-text">MSA End Date:</label>
                <DatePicker className="form-control digits"
                  selected={EndDate}
                  value={EndDate}
                  onChange={handleEndDateChange}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit} >Submit</Button>
          <Button color="secondary" onClick={addRow}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* < !-- Using Form Modal for Edit Project --> */}
      <Modal isOpen={modal} toggle={editRow}>
        <ModalHeader toggle={editRow}>Edit Project</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <TextField fullWidth required id="name" label="Project" margin="dense" name="name"
                  onChange={handleInputChange} value={data.name} variant="outlined"
                />
                <FormHelperText>{errors.name}</FormHelperText>
              </div>
              <div className="col-md-6 mb-3">
                <TextField fullWidth label="POValue" margin="dense" name="po_value" onChange={handleInputChange}
                  value={data.po_value} variant="outlined" id="po_value" />
              </div>
              <div className="col-md-6 mb-3">
                <TextField id="client_id" select label="Client Name" value={data.client_id}
                  onChange={handleInputChange} SelectProps={{ name: 'client_id' }} variant="outlined" fullWidth >
                  {
                    clients.map((n, index) => {
                      return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                    })
                  }
                </TextField>
              </div>
              <div className="col-md-6 mb-3">
                <TextField fullWidth label="GSTIN" margin="dense" name="gstin"
                  onChange={handleInputChange} value={data.gstin} variant="outlined" id="gstin"
                />
              </div>
              <div className="col-md-12 mb-3">
                <TextField fullWidth label="Address" margin="dense" name="billing_address" multiline
                  onChange={handleInputChange} value={data.billing_address} variant="outlined" id="billing_address"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="col-form-label" htmlFor="message-text">Start Date:</label>
                <DatePicker className="form-control digits"
                  selected={StartDate}
                  value={StartDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="col-form-label" htmlFor="message-text">End Date:</label>
                <DatePicker className="form-control digits"
                  selected={EndDate}
                  value={EndDate}
                  onChange={handleEndDateChange}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={editRow}>Cancel</Button>
          <Button color="primary" onClick={() => handleUpdate(data.id, data)} >Save Changes</Button>
        </ModalFooter>
      </Modal>

      {/* < !-- Using Modal for Uploading Cost Sheet BOM Items --> */}
      <Modal isOpen={costsheetmodal} toggle={editCostSheet} className="modal-dialog modal-xl">
        <ModalHeader toggle={editCostSheet}>View Costsheet</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                {/*
                            <div className="col-md-2">
                              <input type="text" placeholder="Enter item to be searched" style={elementStyle} value = {searchitems}
                               onChange={updateSearchItems} />
                            </div>
                          */}
                <div className="card-block row">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="row">
                      <div className="col-xl-3 m-t-25">
                        <h5>Upload Costsheet:</h5>
                      </div>
                      <div className="col-xl-4">
                        <TextField id="file" type="file" margin="normal" variant="outlined"
                          onChange={handleUploadCostSheet} fullWidth />
                      </div>
                      {costsheetfile ?
                        <div className="col-xl-4 m-t-20">
                          <button className="btn btn-success btn-lg" onClick={handleCostSheetSubmit}>Upload</button>
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
                  {filterCostSheet.length > 0 ?
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                          <thead className="table-primary">
                            <tr>
                              <th scope="col">Sl.No</th>
                              <th scope="col">Description</th>
                              <th scope="col">Specification</th>
                              <th scope="col">Make</th>
                              <th scope="col">Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterCostSheet.map((n) => {
                              return (
                                <tr>
                                  <td>{n.sl_no}</td>
                                  <td>{n.description}</td>
                                  <td>{n.specifications}</td>
                                  <td>{n.make}</td>
                                  <td>{n.cost}</td>
                                  {/*
                                                   <td>
                                                    <button className="btn btn-info btn-xs" type="button" onClick={() => editItemRow(n)}>
                                                     <i className="icofont icofont-edit"></i>
                                                    </button>
                                                    <button className="btn btn-danger btn-xs" type="button" onClick={() => handleDeleteItem(n)}>
                                                     <i className="fa fa-trash-o"></i>
                                                    </button>
                                                   </td>
                                                   */}
                                </tr>
                              );
                            })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    :
                    null
                  }
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={editCostSheet}>Close</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

export default withRouter(ProjectDetails);