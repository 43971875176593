import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import {Autocomplete} from '@material-ui/lab';
import {TextField, MenuItem } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Logo from '../../../../assets/images/nupielogo.png';
import Table from './Table';
import { indianNumberFormat, usNumberFormat } from '../../../../actions/numberFormat';
import { getProjects, getGroup, getMasterInventory, getRaisedInvoices, postRaisedInvoices, 
         getRaisedInvItems, editRaisedInvoices, editRaisedInvStatus, getSettings, getClients,
         sendClientEmail, sendWappInvoice, editRaisedInvCredit } from '../../../../actions/asyncActions';
//import { sendClientEmail } from '../../../../../../back/services/user.service';

const ViewRaisedInvoice = (props) => {
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const location = props.location;
 const invoiceId = location.state ? location.state.invoiceId : null;
 const invoiceNo = location.state ? location.state.invoiceNo : null; 
 const clientId = location.state ? location.state.clientId : null;
 const clientName = location.state ? location.state.clientName : null;
 const billingAddress = location.state ? location.state.billingAddress : null;
 const txnType = location.state ? location.state.txnType : null;
 const status = location.state ? location.state.status : null;
 const dinvoice = location.state ? location.state.dinvoice : null;
 const dateOfinvoice = new Date(dinvoice);
 const creditPeriod = location.state ? location.state.creditPeriod : null;
 const dueInvoice = location.state ? location.state.dueInvoice : null;
 var dueDate = new Date(dinvoice); 
 var duesDate = new Date(dinvoice).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric'});
 const placeSupply = location.state ? location.state.placeSupply : null;
 const totalAmount = location.state ? location.state.totalAmount : null;
 const currency = location.state ? location.state.currency : null;
 const exchangeRate = location.state ? location.state.exchangeRate : null;
 const customerNotes = location.state ? location.state.customerNotes : null;
 const termsConditions = location.state ? location.state.termsConditions : null;
 const billType = location.state ? location.state.billType : null;
 
 const invItems = location.state ? location.state.invItems : null;

 const tableData = {
   data: invItems,
   resolve: () => {},
   updatedAt: new Date()
 };

 const comonscol = [
   { title: "Item", field: "item_name" },
   { title: "Description", field: "description" },
   { title: "HSN", field: "hsn" },
   { title: "GST(%)", field: "tax_rate" },
   { title: "Qty", field: "qty" },
   { title: "Rate", field: "rate" },
   { title: "Disc", field: "discount" },
   { title: "Total", field: "total" },
 ];

 const [raisedInvoices, setRaisedInvoices] = useState([]);
 //console.log("raisedInvoices", raisedInvoices)
 const [raiseInvItems, setRaiseInvItems] = useState([]);
 const filterInvItems = raiseInvItems.filter(x=>x.invoice_id === invoiceId)

 const initialFormState = {clientId: clientId, client_name: clientName, billing_address: billingAddress, 
  txn_type: txnType, status: status, credit_period: creditPeriod, due_date: dueDate, 
  place_supply: placeSupply, totalAmount: totalAmount, currency: currency, exchangeRate: exchangeRate,
  customer_notes: customerNotes, terms_conditions: termsConditions, bill_type: billType};

 const [invdata, setInvData] = useState(initialFormState);
 const initialGetDate = {invoice_date: dateOfinvoice}
 const [aaaDate, setInvoiceDate] = useState(initialGetDate);

 const [lessdatemodal, setLessDateModal] = useState(false);
 const [moredatemodal, setMoreDateModal] = useState(false);
 const [difference, setDifference] = useState(0);

 const dateofInv= (aaaDate.invoice_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
 
 const [clients, setClients] = useState([])
 const filterClients = clients.find(x=> x.id === invdata.clientId);
 const clientEmail = filterClients && filterClients.email;
 const clientPhone = filterClients && filterClients.phone;

 const toggleLess = () => {
 	setLessDateModal(!lessdatemodal)
 }

 const toggleMore = () => {
 	setMoreDateModal(!moredatemodal)
 }

 const handleDateChange = (date) => {
  setInvoiceDate({invoice_date: date})
 	var date1 = new Date();
 	var date2 = date;
    var difference_In_Time = date2.getTime() - date1.getTime() ;
    var difference_In_Days = Math.round(difference_In_Time / (1000 * 3600 * 24));
    setDifference(difference_In_Days);
 	if(difference_In_Days < 0){
 		//show less date modal
 		setLessDateModal(!lessdatemodal)
 	}
 	else if (difference_In_Days > 0){
 		//show more date modal
 		setMoreDateModal(!moredatemodal)
 	}
 	else {

 	}
 }

 const [settings, setSettings] = useState([]);
 const curSettings = settings.find(x=>x.group_id === group_id);
 const foundCurrency = curSettings ? curSettings && curSettings.currency : null;

 const totalB = (invdata.currency === 'USD') ? usNumberFormat(invdata.totalAmount) : indianNumberFormat(invdata.totalAmount);
 const [deletemodal, setDeleteModal] = useState(false);
 const [reason, setReason] = useState()
 const deleteRow = () => {
   setDeleteModal(!deletemodal)
 }

 const handleChange = event => {
    setInvData({
    ...invdata,
    [event.target.name]: event.target.value
  });
 };

 useEffect( () => {
  getRaisedInvoices(group_id)
  .then(resp => setRaisedInvoices(resp.data))
 }, []);
 //console.log("raisedInvoices2", raisedInvoices)

 useEffect( () => {
  getRaisedInvItems(group_id)
  .then(resp => setRaiseInvItems(resp.data))
 }, []);

 useEffect( () => {
  getSettings(group_id)
  .then(resp => setSettings(resp.data))
 }, []);

 useEffect( () => {
  getClients(group_id)
  .then(resp => setClients(resp.data))
 }, []);

 const projectId = location.state ? location.state.projectId : null;
 //const clientId = location.state ? location.state.clientId : null;
 //const clientName = location.state ? location.state.clientName : null;
 //const billingAddress = location.state ? location.state.billingAddress : null;
 //const creditPeriod = location.state ? location.state.creditPeriod : null;

 const [projects, setProject] = useState([]);
 const [group, setGroup] = useState([]);
 const groupDetails = group.find(x=> x.id === group_id);
 const companyName = groupDetails ? groupDetails && groupDetails.name : null;
 const GSTIN = groupDetails ? groupDetails && groupDetails.gst : null;
 const companyAddress = groupDetails ? groupDetails && groupDetails.address : null;
 let invoiceNumber = groupDetails ? groupDetails && groupDetails.last_inv : null;
 invoiceNumber = invoiceNumber + 1;

 //const aaa = clientDetails ? clientDetails && clientDetails.credit_period : 0;
 const inititalData = {creditDays: creditPeriod}
 const [creditPeriodData, setCreditPeriod] = useState(inititalData);
 console.log("creditPeriodData", creditPeriodData.creditDays)

 const handleChange2 = event => {
    setCreditPeriod({
	  ...creditPeriodData,
	  [event.target.name]: event.target.value
	});
 };
 
 //const creditDays = (creditPeriod).concat('(days)')
 
 const transactionType = [{ name: 'GST Inter-State' }, {name: 'GST Intra-State'}, {name: 'Export Invoice'}, {name: 'Non GST Sale'}];
 const [typeval, setTypeVal] = useState(null);
 const typeOfTransaction = typeval ? typeval && typeval.name : null;
 const statusType = [{name: 'Preparation'}, {name: 'Approve'}];
 const [statusval, setStatusVal] = useState(null);
 const typeOfStatus = statusval ? statusval && statusval.name : null;
 //const [invoiceDate, setInvoiceDate] = useState(new Date());

 //var dueDate = new Date(invoiceDate);
 dueDate.setDate(parseInt((aaaDate.invoice_date).getDate()) + parseInt(invdata.credit_period));
 //console.log("typeOfStatus", typeOfStatus)
 console.log("dueDate", dueDate)

 const deeDate = new Date(dueDate).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })

 const [place_supply, setPlaceSupply] = useState();
 const [fields, setFields] = useState([{ part_no:'', qty:'', rate:'' }]);
 const [cus_notes, setCusNotes] = useState();
 const [terms, setTerms] = useState();
 
 let description = [];

 const [masterInventory, setMasterInventory] = useState([]);
 const filterMastery = masterInventory.filter(x=>x.raw_material === 1);

 const [inventoryValue, setInventoryValue] = useState([]);
 const invDesc = inventoryValue && inventoryValue.description;
 const invRate = inventoryValue && inventoryValue.rate;
 const invTaxRate = inventoryValue && inventoryValue.tax_rate;
 const invHsn = inventoryValue && inventoryValue.hsn;
 //console.log("inventoryValue", inventoryValue)
  //console.log("invDesc", invDesc)

  function handleChange3(i,e){
    const tempValues = [...fields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFields(tempValues);
  }

 useEffect( () => {
  getProjects(group_id)
  .then(resp => setProject(resp.data))
 }, []);

 useEffect( () => {
  getGroup()
  .then(resp => setGroup(resp.data))
 }, []);

 useEffect( () => {
  getMasterInventory(group_id)
  .then(resp => setMasterInventory(resp.data))
 }, []);

 const approveInvoice = () => {
 	const data = {
    id: invoiceId,
 		status_type: 'Approved',
 		doi: aaaDate.invoice_date,
 		credit_period: invdata.credit_period,
 		due_date: dueDate,
 		place_supply: invdata.place_supply,
 		total_amount: totalAmount,
 		cus_notes: invdata.customer_notes,
 		terms: invdata.terms_conditions,
 		user_id: user.id,
 		group_id: group_id
  }
  const pgData = {
    name: invdata.client_name,
    email: clientEmail,
    phone: clientPhone
  }
  // const clientData = {
  //   email: clientEmail,
  //   date: dueDate,
  //   dueMonth: dueDate.getMonth(),
  //   dueDate: dueDate.getDate(),
  //   dueDay: dueDate.getDay(),
  //   total: totalAmount
  // }
  // sendClientEmail(clientData)
   editRaisedInvoices({data, pgData})
   .then(resp => {
     console.log("respData", resp.data)
     
     if(resp.data.status === 'OK'){
      const clientData = {
        invoiceId: invoiceId,
        email: clientEmail,
        phone: clientPhone,
        date: duesDate,
        total: totalAmount,
        paymentLink: resp.data.paymentLink
      }
      //console.log("clientData", clientData)
      //sendClientEmail(clientData)
      //sendWappInvoice(clientData)
      toast.success("Invoice approved successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
      //props.history.push(`${process.env.PUBLIC_URL}/project`);
     }
     else{
      toast.warn("Error in getting payment link...", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
     }
   })
 }

const approveCreditInvoice = () => {
  const data = {
    id: invoiceId,
 		status_type: 'Approved',
 		doi: aaaDate.invoice_date,
 		credit_period: invdata.credit_period,
 		due_date: dueDate,
 		place_supply: invdata.place_supply,
 		total_amount: totalAmount,
 		cus_notes: invdata.customer_notes,
 		terms: invdata.terms_conditions,
 		user_id: user.id,
 		group_id: group_id
  }
  //update Invoice API
  editRaisedInvCredit(data)
  .then(resp => {
    getRaisedInvoices(group_id)
    .then(resp => setRaisedInvoices(resp.data))
    toast.success("Invoice approved successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
    props.history.push(`${process.env.PUBLIC_URL}/project`);
  })
}

const testApproveInvoice = () => {
  const clientData = {
    email: clientEmail,
    date: duesDate,
    dueMonth: dueDate.getMonth(),
    dueDate: dueDate.getDate(),
    dueDay: dueDate.getDay(),
    total: totalAmount
  }
  sendClientEmail(clientData)
}

const deleteInvoice = () => {
  const data = {
    id: invoiceId,
    reason: reason,
    status_type: 'Deleted'
  }
  editRaisedInvStatus(data)
  .then(resp => {
    getRaisedInvoices(group_id)
    .then(resp => setRaisedInvoices(resp.data))
  })
  toast.error("Invoice deleted successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
  props.history.push(`${process.env.PUBLIC_URL}/project`);
}

const handleBack = () => {
  props.history.push(`${process.env.PUBLIC_URL}/project`);
}

	return(
	 <Fragment>
	  <Breadcrumb title="Edit Invoice" parent="Project Management" />
	  <div className="container-fluid">
	   <div className="row">
		<div className="col-xl-8 col-lg-8 col-md-8">
		 <div className="card">
            <div className="card-body">
             <div className="col-xl-12">
               <div className="form-row">
                <div className="col-xl-8 col-lg-8">
             	 <h4>{companyName}</h4>
             	</div>
             	<div className="col-xl-3 col-lg-3">
             	 <img src={Logo} />
             	</div>
               </div>
             </div>
             <div className="col-xl-8">
             	<h6>GSTIN - {GSTIN}</h6>
             </div>
             <div className="col-xl-12 m-t-20">
             </div>
             <div className="col-xl-12 m-t-20">
             </div>
             <div className="col-xl-12 m-t-20">
             </div>
             <div className="col-xl-12">
              <div className="form-row">
               <div className="col-xl-6">
              	<label className="col-form-label" htmlFor="message-text"><b>INVOICE #:</b></label>
                <TextField value={invoiceNo} />
               </div>
               <div className="col-xl-6">
                <label className="col-form-label" htmlFor="message-text"><b>STATUS:</b></label>
                <b style={{color: "green", fontSize:16}}> For Approval</b>
               </div>
              </div>
               <div className="form-row">
                <div className="col-xl-6 col-lg-6">
                 <label className="col-form-label" htmlFor="message-text"><b>BILLING TO:</b></label>
                 <TextField multiline value={clientName} />
                </div>
                <div className="col-xl-6 col-lg-6">
                 <label className="col-form-label" htmlFor="message-text"><b>BILLING ADDRESS:</b></label>
                 <TextField value={billingAddress} />
                </div>
              {/*
                <div className="col-xl-6">
                 <label className="col-form-label" htmlFor="message-text"><b>TRANSACTION TYPE:</b></label>
                  <Autocomplete
                    options={transactionType}
                    getOptionLabel={option => option.name}
                    value={typeval}                      
                    onChange={(event, newValue) => {
                      setTypeVal(newValue);
                    }}            
                    renderInput={params => (
                      <TextField {...params} margin="dense" helperText="Please choose Transaction type" fullWidth />
                    )}
                  />
                </div>
                <div className="col-xl-6">
                 <label className="col-form-label" htmlFor="message-text"><b>STATUS:</b></label>
                  <Autocomplete
                    options={statusType}
                    getOptionLabel={option => option.name}
                    value={statusval}                      
                    onChange={(event, newValue) => {
                      setStatusVal(newValue);
                    }}            
                    renderInput={params => (
                      <TextField {...params} margin="dense" helperText="Please choose the Status" fullWidth />
                    )}
                  />
                </div>
              */}
                <div className="col-xl-6">
                 <label className="col-form-label" htmlFor="message-text"><b>INVOICE DATE:</b></label>
                  <DatePicker className="form-control digits m-t-10"
                   selected={new Date(aaaDate.invoice_date)}
                   value={new Date(aaaDate.invoice_date)}
                   onChange={(date) => handleDateChange(date)}
                   dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="col-xl-6">
                 <label className="col-form-label" htmlFor="message-text"><b>CREDIT PERIOD(DAYS):</b></label>
                 <TextField variant="outlined" name="credit_period" margin="dense" value={invdata.credit_period} 
                 	onChange={handleChange} fullWidth/>
                </div>
                <div className="col-xl-6">
                 <b> DUE DATE: <br/>
                 	<TextField variant="outlined" margin="dense" value={deeDate} fullWidth/>
                 </b>
                </div>
                <div className="col-xl-6">
                 <b> PLACE OF SUPPLY: <br/>
                 	<TextField variant="outlined" name="place_supply" margin="dense" 
                 	 value={invdata.place_supply} onChange={handleChange} fullWidth/>
                 </b>
                </div> 
                <div className="col-xl-12 m-t-15">
                 <div className="table-responsive-lg"> 
                  <Table tableData={tableData} comonscol={comonscol} invoice_id={invoiceId}/>
                 </div>
                 </div>
                 <div className="offset-xl-6 col-xl-6 m-t-15">
                  <h4> Total Amount: {totalB}</h4>
                 </div>
                 <div className="col-xl-12 m-t-15">
                  <label className="col-form-label" htmlFor="message-text"><b>CUSTOMER NOTES</b></label>
                  <TextField name="customer_notes"value={invdata.customer_notes} onChange={handleChange} fullWidth/>
                 </div>
                 <div className="col-xl-12 m-t-15">
                  <label className="col-form-label" htmlFor="message-text"><b>TERMS AND CONDITIONS</b></label>
                  <TextField multiline name="terms_conditions" value={invdata.terms_conditions} rows={4}
                    onChange={handleChange} fullWidth/>
                 </div>
                 <div className="card-footer m-t-20">
                   <h5 className="text-center">{companyName}</h5>
                   <p>{companyAddress}</p>
                 </div>
               </div>
             </div>
            </div>
         </div>
		</div>
		<div className="col-xl-4 col-lg-4 col-md-4">
		  <div className="card">
        <div className="card-body float-right">
          <div className="col-xl-12">
            <i className="fa fa-arrow-left"></i>
            <button className="btn btn-link" onClick={() => handleBack()}> Back </button>
          </div>
          {billType === 'Credit' ?
            <div className="col-xl-12">
              <i className="fa fa-thumbs-up"></i>
              <button className="btn btn-link text-success" onClick={() => testApproveInvoice()}> Approve Invoice </button>
            </div>
            :
            <div className="col-xl-12">
              <i className="fa fa-thumbs-up"></i>
              <button className="btn btn-link text-success" onClick={() => approveInvoice()}> Approve Invoice </button>
            </div>
          }
          <div className="col-xl-12">
            <i className="fa fa-trash-o"></i>
            <button className="btn btn-link text-danger" onClick={() => deleteRow()}> Delete Invoice </button>
          </div>
        </div>
      </div>
    </div>
	 </div>
	</div>
  
  {/* Delete Invoice Modal */}
    <Modal isOpen={deletemodal} toggle={deleteRow}>
      <ModalHeader toggle={deleteRow}>Reason for deletion</ModalHeader>   
        <ModalBody>
          <div className="form-row text-center">
            <div className="col-xl-12">
              <label className="col-form-label" htmlFor="message-text">
                <b>Reason for deleting the invoice:</b>
              </label>
              <TextField multiline label="Reason" variant="outlined" value={reason} 
  	            onChange={(e) => setReason(e.target.value)} rows={6} fullWidth/>
            </div>
            <div className="col-xl-12 m-t-20">
              <button className="btn btn-success" onClick={() => deleteInvoice()}>
                Confirm delete
              </button>
            </div>
          </div>
        </ModalBody>
    </Modal>

    {/* Less Date Modal */}
	  <Modal isOpen={lessdatemodal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 1000 }} 
	   returnFocusAfterClose={false} toggle={toggleLess}>
	   <ModalHeader toggle={toggleLess}>Date confirmation</ModalHeader>
	    <ModalBody>
	     <div className="row">
	      <div className="col-xl-12 text-center">
	     	<h6>The selected Invoice date is <b>{dateofInv}</b> which is <b>{-(difference)}</b> day/s lesser than the current date </h6>
	      </div>
	      <div className="col-xl-12 text-center">
	        <h4>Are you sure about the selected date?</h4>
	      </div>
	      <div className="col-xl-12 text-center">
	       <button className="btn btn-primary" onClick={toggleLess}>OK</button>
	      </div>
	     </div>
	    </ModalBody>
	  </Modal>

	{/* More Date Modal with custom transition timeouts */}
	  <Modal isOpen={moredatemodal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 1000 }} 
	   returnFocusAfterClose={false} toggle={toggleMore}>
	   <ModalHeader toggle={toggleMore}>Date confirmation</ModalHeader>
	    <ModalBody>
	     <div className="row">
	      <div className="col-xl-12 text-center">
	     	<h6>The selected Invoice date is <b>{dateofInv}</b> which is <b>{difference}</b> day/s more than the current date </h6>
	      </div>
	      <div className="col-xl-12 text-center">
	        <h4>Are you sure about the selected date?</h4>
	      </div>
	      <div className="col-xl-12 text-center">
	       <button className="btn btn-primary" onClick={toggleMore}>OK</button>
	      </div>
	     </div>
	    </ModalBody>
	  </Modal>    
	 </Fragment>
	);
}

export default withRouter(ViewRaisedInvoice);