import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Autocomplete} from '@material-ui/lab';
import {TextField} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { getProjects, getVendorForm, getIndentItems, getGroup, getPorders, 
         editIndentItemsPO, postPorders, getTds, postWhatsAppInvoice } from '../../actions/asyncActions';
import PrintPO from './print.js';

const GeneratePO = () => {
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;

 const [projects, setProject] = useState([]);
 const [project_val, setProjectVal] = React.useState(null);
 const selectedProject = project_val && project_val.id;

 const [vendors, setVendors] = useState([]);
 const approvedVendors = vendors.filter(x=> x.status === 'Approved'); 
 const [vendor_val, setVendorVal] = useState(null);
 const selectedVendor = vendor_val && vendor_val.id;
 const selectedVendorName = vendor_val && vendor_val.supname;
 const vendorMobile = vendor_val && vendor_val.mobileno;
 const [popenmodal, setPOpenModal] = useState();

 const [indentItems, setIndentItems] = useState([]);
 const filterIndentItems = indentItems.filter(x=> x.project_id === selectedProject && x.vendor_id === selectedVendor && x.status === "VendorSelected");
 const totalItems = filterIndentItems.length; //No of Items in PO
 console.log("totalItems", totalItems)
 const [groups, setGroups] = useState([]);
 const filterLastPO = groups.find(x=>x.id === user.group_id);
 const lastPO = filterLastPO && filterLastPO.last_po;
 
 const [porders, setPorders] = useState([]);

 const invoiceType = [{ name: 'Product' }, {name: 'Service'}];
 const [typeval, setTypeVal] = useState(null);

 const saleType = [{ name: 'Inter-State' }, {name: 'Intra-State'}];
 const [saletypeval, setSaleTypeVal] = useState(null);
 const selectedSaleType = saletypeval ? saletypeval && saletypeval.name : null;

 const [iGst, setIgst] = useState();
 const [gst, setGst] = useState();

 const [tds, setTds] = useState([]);
 const [tdsval, setTdsVal] = useState(null);
 const [tdsremarks, setTdsRemarks] = useState();
  
 useEffect( () => {
   getGroup(group_id)
    .then(resp => setGroups(resp.data))
 }, []);

 useEffect( () => {
   getPorders(group_id)
    .then(resp => setPorders(resp.data))
 }, []);

 useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
 }, []);

 useEffect( () => {
    getVendorForm(group_id)
    .then(resp => setVendors(resp.data))
 }, []);

 useEffect( () => {
    getIndentItems(group_id)
    .then(resp => setIndentItems(resp.data))
 }, []);

 useEffect( () => {
    getTds(group_id)
    .then(resp => setTds(resp.data))
 }, []);

 const handlePOpen = () => {
 	setPOpenModal(!popenmodal)
  // setVendorVal('');
  // setProjectVal('');
  setTypeVal('');
  setTdsVal('');
  setIgst('');
  setGst('');
  setPlaceSupply('');
  setDispatchVal('');
  setSaleTypeVal('');
 }

const [placesupply, setPlaceSupply] = useState();
const dispatchValues = [{name:"Road"}, {name:"Rail"}, {name:"Sea"}, {name:"Flight"}]
const [dispatchVal, setDispatchVal] = useState(null)
const selectedDispatch = dispatchVal ? dispatchVal && dispatchVal.name : null;

 const handleUpdatePO = () => {
    const filterIndentItems = 
     indentItems.filter(x=> x.project_id === selectedProject && x.vendor_id === selectedVendor && x.status === "VendorSelected");
     const getTotal = filterIndentItems.reduce((a, b) => a + b.total, 0);
    const data = {
      group_id: user.group_id,
      project_id: selectedProject,
      vendor_id: selectedVendor,
      po_no: lastPO + 1,
      psupply: placesupply,
      dispatch: selectedDispatch,
      status: "POSubmitted"
    }
    const arr = [];
    filterIndentItems.forEach(porder => {
      arr.push({ part_num:porder.part_num, part_name:porder.part_name, function: porder.function, item_type: porder.item_type, 
        l1:porder.l1 , l2:porder.l2, l3:porder.l3, l4:porder.l4, l5:porder.l5, material:porder.material, specs: porder.specs,
        finish_size:porder.finish_size, qty: porder.qty, price: porder.price, date: porder.date, remarks: porder.remarks, 
        reason: porder.reason,discount: porder.discount, total: porder.total, cogs_ref: porder.cogs_ref, indentItem_id: porder.id,
        client_id:porder.client_id, user_id: porder.user_id})
    });
    const podata = {
      vendor_id: selectedVendor,
      status: "POSubmitted",
      subtotal: getTotal,
      project_id: selectedProject,
      invType: typeval && typeval.name,
      tds: tdsval && tdsval.tds,
      tds_remarks: tdsremarks,
      igst: iGst,
      gst: gst,
      user_id: user.id,
      mgr_id: user.mgr_id,
      group_id: user.group_id,
    }
    const porder = {
      items: arr,
      group_id: user.group_id,
      project_id: selectedProject,
      vendor_id: selectedVendor,
      po_no: lastPO + 1,
      psupply: placesupply,
      dispatch: selectedDispatch,
      invType: typeval && typeval.name,
      status: "POSubmitted"
    }

    const whatsappData = {
      vendorName :selectedVendorName,
      poNumber: lastPO + 1,
      totalAmount: getTotal,
      totalItems: totalItems,
      mobileno: vendorMobile
    }

      editIndentItemsPO(data)
      .then(resp => {
        postPorders({podata, porder})
        .then(resp=> {
          postWhatsAppInvoice(whatsappData)
          .then(resp => {
            getGroup(group_id)
            .then(resp => setGroups(resp.data))
          .then(resp => {
            getPorders(group_id)
            .then(resp=> setPorders(resp.data))
            handlePrintPO();
            setPOpenModal(!popenmodal);
            toast.success("WhatsApp sent successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
            setVendorVal('');
            setProjectVal('');
            setTypeVal('');
            setTdsVal('');
            setIgst('');
            setGst('');
            setPlaceSupply('');
            setDispatchVal('');
            setSaleTypeVal('')
          })
          })
        })
      })
  }

  const handlePrintPO = () => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(`<html><head><title>${document.title}</title>
     <style>
     @page { margin: 0 }
      body { margin: 0 }
      .sheet {
      margin: 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      page-break-after: always;
      }

      /* Padding area */
      .sheet.padding-5mm { padding: 5mm }

      /* For screen preview */
      @media screen {
      body { background: #e0e0e0 }
        .sheet {
         background: white;
         box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
         margin: 2mm;
       }
       table {
          border-collapse: collapse;
      }
       tr.border_bottom td {
        border-bottom:1pt solid black;
      }
      table th, table td {
      border:1px solid #000;
        padding: 0.5em;
      }
      }
      @media print {
          html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
          font-size: 9pt !important;
               }
            }

     </style>
     `);
   mywindow.document.write('</head><body>');
   mywindow.document.write(document.getElementById('printreceipt').innerHTML);
   mywindow.document.write('</body></html>');

   mywindow.document.close(); // necessary for IE >= 10
   mywindow.focus(); // necessary for IE >= 10*/

   mywindow.print();
   mywindow.close();

   return true;
  }
  
  return (
  	<Fragment>
  	 <div style={{display:"none"}}>
      <PrintPO
        selectedProject = {selectedProject}
        selectedVendor = {selectedVendor}
        placeSupply = {placesupply}
        dispatch = {selectedDispatch}
        lastPO ={lastPO}
      />
     </div>
  		<div className="container-fluid">
            <div className="row">
		  	  <div className="col-sm-12 col-xl-12">
		        <div className="card card-absolute">
		            <div className="card-header bg-secondary">
		                <h5>Generate PO</h5>
		            </div>
		            <div className="card-body">
		              <div className="row">
                       <div className="col-md-3">
                       	<Autocomplete
      			              freeSolo
      			              options={projects}
      			              getOptionLabel={option => option.name}
      			              value={project_val}                      
      			              onChange={(event, newValue) => {
      			                setProjectVal(newValue);
      			              }}            
      			              renderInput={params => (
      			                <TextField {...params} label="Project" margin="normal" variant="outlined" helperText="Please select Project" fullWidth />
      			              )}
      			            />
                       </div>
                       <div className="col-md-3">
                        <Autocomplete 
      			              freeSolo
      			              options={approvedVendors}
      			              getOptionLabel={option => option.supname}
      			              value={vendor_val}                      
      			              onChange={(event, newValue) => {
      			                setVendorVal(newValue);
      			              }}            
      			              renderInput={params => (
      			                <TextField {...params} label="Vendor" margin="normal" variant="outlined" helperText="Please select Vendor" fullWidth />
      			              )}
      			            />
                       </div>
                       { selectedProject && selectedVendor?
                         <div className="col-md-3">
                          <Button color="info" onClick={handlePOpen} className="btn btn-pill btn-secondary-gradien btn-lg m-t-15">
                          	Generate PO
                          </Button>
                         </div>	
                       	: null
          			       }

          			   { (selectedProject && selectedVendor) ?
          			   	<div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive-lg">
                                        <table className="table">
                                          <thead className="table-warning">
                                            <tr>
                                              <th scope="col">#</th>
  				                                    <th scope="col">Indent</th>
  				                                    <th scope="col">Part No</th>
  				                                    <th scope="col">Part Name</th>
  				                                    <th scope="col">Material</th>
  				                                    <th scope="col">Finish Size</th>
  				                                    <th scope="col">Qty</th>
  				                                    <th scope="col">Price</th>
  				                                    <th scope="col">Remarks</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                           {filterIndentItems.map((n, i)=> {
				                                    return (
    			                                    <tr>
    			                                     <th scope="row">{i+1}</th>
    			                                     <td>{("IN-000").concat(n.indent_id)}</td>
    			                                     <td>{n.part_num}</td>
    			                                     <td>{n.part_name}</td>
    			                                     <td>{n.material}</td>
    			                                     <td>{n.finish_size}</td>
    			                                     <td>{n.qty}</td>
    			                                     <td>{n.price}</td>
    			                                     <td>{n.remarks}</td>
    			                                    </tr>
    			                                  );
				                                   })
				                                  } 
                                          </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        : null
                       }
                      </div>
		            </div>
		        </div>
		      </div>
		    </div>
        </div>

    {/* < !-- Using Form Modal for Printing PO--> */}
            <Modal isOpen={popenmodal} toggle={handlePOpen} className="modal-body" centered={true} size="lg">
                <ModalHeader toggle={handlePOpen}>Purchase Order</ModalHeader>   
                <ModalBody>
                    <form>
                       <div className="row">
                        <div className="form-group col-sm-6">
                          <label className="col-form-label" htmlFor="message-text">Place of Supply/Destination:</label>
                          <input className="form-control m-t-10" type="text" name="placesupply"  value={placesupply} 
                           onChange={(e) => setPlaceSupply(e.target.value)} />
                        </div>
                        <div className="form-group col-sm-6">
                          <label className="col-form-label" htmlFor="message-text">Dispatch through:</label>
                          <Autocomplete
                            freeSolo
                            options={dispatchValues}
                            getOptionLabel={option => option.name}
                            value={dispatchVal}                      
                            onChange={(event, newValue) => {
                              setDispatchVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params} margin="dense" variant="outlined" helperText="Please choose Dispatch Type" fullWidth />
                            )}
                          />
                        </div>
                        <div className="form-group col-sm-6">
                          <label className="col-form-label" htmlFor="message-text">PO Type:</label>
                          <Autocomplete
                            freeSolo
                            options={invoiceType}
                            getOptionLabel={option => option.name}
                            value={typeval}                      
                            onChange={(event, newValue) => {
                              setTypeVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params} margin="dense" variant="outlined" helperText="Please choose Type" fullWidth />
                            )}
                          />
                        </div>
                        { typeval && typeval.name === 'Service' ?
                         <div className="form-group col-sm-6">
                          <label className="col-form-label" htmlFor="message-text">Section:</label>
                          <Autocomplete
                            freeSolo
                            options={tds}
                            getOptionLabel={option => option.section}
                            value={tdsval}                      
                            onChange={(event, newValue) => {
                              setTdsVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params} margin="dense" variant="outlined" helperText="Please choose TDS" fullWidth />
                            )}
                          />
                         </div>
                         :
                         null  
                        }
                        {tdsval ?
                          tdsval && tdsval.section === 'Others' ?
                           <div className="form-group col-sm-6">
                            <label className="col-form-label" htmlFor="message-text">Remarks:</label>
                            <input className="form-control m-t-10" type="text" name="tds_remarks"  value={tdsremarks} 
                              onChange={(e) => setTdsRemarks(e.target.value)}/>
                           </div>
                           : 
                           <div className="form-group col-sm-6">
                            <label className="col-form-label" htmlFor="message-text">TDS:</label>
                            <input className="form-control m-t-10" type="text" name="tds_remarks"  value={tdsval && tdsval.tds} />
                           </div>
                          
                         : 
                         null
                        }
                        <div className="form-group col-sm-6">
                          <div className="row">
                            <div className="form-group col-sm-12">
                              <label className="col-form-label" htmlFor="message-text">Sale Type:</label>
                              <Autocomplete
                                freeSolo
                                options={saleType}
                                getOptionLabel={option => option.name}
                                value={saletypeval}                      
                                onChange={(event, newValue) => {
                                  setSaleTypeVal(newValue);
                                }}            
                                renderInput={params => (
                                  <TextField {...params} margin="dense" variant="outlined" helperText="Type of Sale" fullWidth />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                            { saletypeval && saletypeval.name === 'Inter-State' ?
                              <div className="form-group col-sm-6 m-t-10">
                               <label className="col-form-label" htmlFor="message-text">IGST(%):</label>
                               <input className="form-control" type="text" name="igst"  value={iGst} 
                                 onChange={(e) => setIgst(e.target.value)} />
                              </div>
                              : saletypeval && saletypeval.name === 'Intra-State' ?
                              <div className="form-group col-sm-6 m-t-10">
                               <label className="col-form-label" htmlFor="message-text">GST(%):</label>
                               <input className="form-control" type="text" name="gst"  value={gst} 
                                 onChange={(e) => setGst(e.target.value)} />
                              </div>
                              : null
                            }
                            { selectedSaleType && gst != null && selectedSaleType != 'Inter-State' ?
                             <div className="form-group col-sm-12">
                              <div className="row">
                                <div className="form-group col-sm-6 m-t-10">
                                 <label className="col-form-label" htmlFor="message-text">CGST:</label>
                                 <input className="form-control" type="text" name="gst"  value={parseFloat(gst)/2} />
                                </div>
                                <div className="form-group col-sm-6 m-t-10">
                                 <label className="col-form-label" htmlFor="message-text">SGST:</label>
                                 <input className="form-control" type="text" name="gst"  value={parseFloat(gst)/2} />
                                </div>
                              </div>
                             </div>
                             : null
                            }
                          </div>  
                    </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={handlePOpen}>Close</Button>
                  <Button color="primary" onClick={handleUpdatePO}>Print</Button>
                </ModalFooter>
            </Modal>
  	</Fragment>
  )

}
export default GeneratePO;