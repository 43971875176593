import React, { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Home, Settings, MoreHorizontal  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import Department from './Department';
import Designation from './Designation';

const DepartmentTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="All Departments" parent="Department" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Home />Department
                                </Tab>
                                <Tab>
                                    <MoreHorizontal />Designation
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <Department />
                                </TabPanel>
                                <TabPanel>
                                    <Designation />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

export default DepartmentTab;