import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Button, Grid } from '@material-ui/core';
//import Switch from '@material-ui/core/Switch';
//import FormGroup from '@material-ui/core/FormGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import { KeyboardDatePicker } from '@material-ui/pickers';
//import InfoIcon from '@material-ui/icons/Info';
//import Delete from '@material-ui/icons/Delete';
//import { postVendorForm, getVendorForm, aadharUpload, panUpload }  from '../../actions/asyncActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  table_root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  margin: {
    margin: theme.spacing(2),
  },
  errors: {
    color: 'red'
  },
  textfield: {
    padding: theme.spacing(1),
  }
}));

export default function VendorForm(props) {
  //const user = JSON.parse(localStorage.getItem("m_users"));
  // const company_id= user.company_id;
  // const group_id = user.group_id;
  const classes = useStyles();
  //const [values, setValues] = useState({});
  //const [datee, setDate] = useState();
  //const datee = new Date();
  // const [errors, setError] = useState({});
  // const [vendorForm, setVendorForm] = useState([]);
  // const [open, setOpen] = useState(false);
  
  // useEffect( () => {
  //   getVendorForm(group_id)
  //   .then(resp => {
  //     setVendorForm(resp.data);
  //   })
  // }, []);

  

// const {landarea, landown, landpower, turnover, skill, semiskill, landfields, values } = props;
const { landfields, values } = props;

return (
 <React.Fragment>
  <Typography variant="h6" gutterBottom>
    Details Of Land And Building
  </Typography>
  <Grid container spacing={3} >
      <Grid item xs={12} sm={4}>
       <Typography gutterBottom>
        Area:
       </Typography>
        <TextField  id="landarea" name="landarea" value={values.landarea} onChange={props.handleChange} fullWidth autoFocus />
      </Grid>
      <Grid item xs={12} sm={4}>
       <Typography gutterBottom>
        Own (or) Rented:
       </Typography>
        <TextField  id="landown" name="landown" value={values.landown} onChange={props.handleChange} fullWidth />
      </Grid>
      <Grid item xs={12} sm={4}>
       <Typography gutterBottom>
        Power:
       </Typography>
        <TextField  id="landpower" name="landpower" value={values.landpower} onChange={props.handleChange} fullWidth />
      </Grid>
      <Grid item xs={12}>
       <Typography gutterBottom>
        Turn Over:
       </Typography>
        <TextField  id="turnover" name="turnover" value={values.turnover} onChange={props.handleChange} fullWidth />
      </Grid>
      <Grid item xs={12}>
       <Typography variant="h6" gutterBottom>
        Proprietor / Directors Details
       </Typography>
        {landfields.map((el, i)=> {
        return(
        <div key={i} className={classes.textfield}>
          <Grid container spacing={3}>
           <Grid item xs={12} sm={3}>
            <TextField onChange={(e) => props.handleChangeLand(i, e)} value={el.propname || ''} id="propname" name="propname" label="Name"
             fullWidth autoComplete="propname" />
           </Grid>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChangeLand(i, e)} value={el.propqualify || ''} id="propqualify" name="propqualify" 
            label="Qualification" fullWidth autoComplete="propqualify" />
           </Grid>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChangeLand(i, e)} value={el.propexperience || ''} id="propexperience" name="propexperience" 
            label="Experience" fullWidth autoComplete="propexperience" />
           </Grid>
           <Grid item xs={12} sm={4}>
            <TextField onChange={(e) => props.handleChangeLand(i, e)} value={el.propaddress || ''} id="propaddress" name="propaddress" label="Address"
             fullWidth autoComplete="propaddress" />
           </Grid>
           <Grid item xs={12} sm={1}>
             <Button size="large" color="secondary" variant="contained" onClick={() => props.handleRemoveLand(i)}>
              X 
            </Button>
           </Grid>
          </Grid>
        </div>
        );
      })}
      <Button size="medium" color="primary" variant="contained" onClick={() => props.addClickLand()}>
        Add 
      </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
       <Typography variant="h6" gutterBottom>
        Employee Particulars 
       </Typography>
      </Grid>
      <Grid item xs={12}>
       <Typography gutterBottom>
        Skilled M/F: (Num)
       </Typography>
       <div className={classes.textfield}>
       <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
         <TextField id="skillmale" name="skillmale" label="Male" value={values.skillmale} onChange={props.handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
         <TextField id="skillfemale" name="skillfemale" label="Female" value={values.skillfemale} onChange={props.handleChange} fullWidth />
        </Grid>
       </Grid>
       </div>
      </Grid>

      <Grid item xs={12}>
       <Typography gutterBottom>
        SemiSkilled M/F: (Num)
       </Typography>
       <div className={classes.textfield}>
       <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
         <TextField id="semiskillmale" name="semiskillmale" label="Male" value={values.semiskillmale} onChange={props.handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
         <TextField id="semiskillfemale" name="semiskillfemale" label="Female" value={values.semiskillfemale} onChange={props.handleChange} fullWidth />
        </Grid>
       </Grid>
       </div>
      </Grid>
      
  </Grid>
</React.Fragment>
);
}
