import React, { Fragment } from 'react';
import { Checkbox } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Summary = (props) => {
    const { grossSalary, totalSalaryDeductions, totalHPIncome, totalVIADeduction, netTaxableIncome,
            slab5, slab10, slab15, totalTax, consentence, consent } = props;

    return(
     <Fragment>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-primary">
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Total Gross Salary</th>
                                                <th>{grossSalary}</th>
                                            </tr>
                                            <tr>
                                                <th>Total Salary Deductions</th>
                                                <th>{totalSalaryDeductions}</th>
                                            </tr>
                                            <tr>
                                                <th>Total House Property Deductions</th>
                                                <th>{totalHPIncome}</th>
                                            </tr>
                                            <tr>
                                                <th>Total Chapter VI A Deductions</th>
                                                <th>{totalVIADeduction}</th>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <th className="text-success">Net Taxable Income:</th>
                                                <th className="text-success">{netTaxableIncome}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-primary">
                                            <tr>
                                                <th scope="col">Tax Payable</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { slab5 > 0 ?
                                                <tr>
                                                    <th>5%</th>
                                                    <th>{slab5}</th>
                                                </tr>
                                            :
                                            null
                                            }
                                            { slab10 > 0 ?
                                            <tr>
                                                <th>10%</th>
                                                <th>{slab10}</th>
                                            </tr>
                                            :
                                            null
                                            }
                                            { slab15 > 0 ?
                                            <tr>
                                                <th>15%</th>
                                                <th>{slab15}</th>
                                            </tr>
                                            :
                                            null
                                            }
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <th className="text-success">Total Tax for the year:</th>
                                                <th className="text-success">{totalTax}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                            <th>
                                            <Checkbox
                                                checked={consent}
                                                onChange={(e) => props.setConsent(e.target.checked)}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                            {consentence}
                                            </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </Fragment>
    );
}

export default Summary;