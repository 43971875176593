import React, { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Home, ThumbsUp } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import TaskDetails from './TaskDetails';
import TaskApproved from './TaskApproved';

const ClientTab = () => {
    return (
        <Fragment>
            <Breadcrumb title="Task List" parent="Tasks" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <Tabs className="col-sm-12">
                        <TabList className="tabs tab-title">
                            <Tab className="current">
                                <Home />Tasks
                            </Tab>
                            {/* <Tab>
                                <ThumbsUp />Approved
                            </Tab> */}
                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel>
                                <TaskDetails />
                            </TabPanel>
                            {/* <TabPanel>
                                <TaskApproved />
                            </TabPanel> */}
                        </div>
                    </Tabs>
                </div>
            </div>
        </Fragment>
    );
}

export default ClientTab;