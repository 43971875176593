import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Grid } from '@material-ui/core';
//import Switch from '@material-ui/core/Switch';
//import FormGroup from '@material-ui/core/FormGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import { KeyboardDatePicker } from '@material-ui/pickers';
//import InfoIcon from '@material-ui/icons/Info';
//import Delete from '@material-ui/icons/Delete';
//import { postVendorForm, getVendorForm, aadharUpload, panUpload }  from '../../actions/asyncActions';

// const useStyles = makeStyles(theme => ({
//   root: {
//     padding: theme.spacing(1)
//   },
//   table_root: {
//     width: '100%',
//     marginTop: theme.spacing(3),
//     overflowX: 'auto',
//   },
//   table: {
//     minWidth: 700,
//   },
//   margin: {
//     margin: theme.spacing(2),
//   },
//   errors: {
//     color: 'red'
//   },
//   textfield: {
//     padding: theme.spacing(1),
//   }
// }));

export default function VendorForm(props) {
  //const user = JSON.parse(localStorage.getItem("m_users"));
  //const company_id= user.company_id;
  //const group_id = user.group_id;
  //const classes = useStyles();
  //const [values, setValues] = useState({});
  //const [datee, setDate] = useState();
  //const datee = new Date();
  //const [errors, setError] = useState({});
  //const [vendorForm, setVendorForm] = useState([]);
  //const [open, setOpen] = useState(false);

  // const { supname, factAddress, headAddress, telephone, office, res, faxno, mobileno, email, values, bankImage } = props;
  const { values } = props;
 
  // useEffect( () => {
  //   getVendorForm(group_id)
  //   .then(resp => {
  //     setVendorForm(resp.data);
  //   })
  // }, []);

  // const validateForm = (data) => {
  //    const errors = [];
  //    if(!data.supname) {
  //      errors.supname = "Supplier Name is required";
  //    }
  //    if(!data.gstno) {
  //      errors.gstno = "GST number is required";
  //    }
  //    return errors;
  // }

return (
 
 <React.Fragment>
  <Grid container spacing={3}>
    <Grid item xs={12}>
     <Typography gutterBottom>
      Name of the Bank:
     </Typography>
      <TextField required id="bankname" name="bankname"  value={values.bankname} onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12}>
     <Typography gutterBottom>
      Account number:
     </Typography>
      <TextField type="number" required id="accno" name="accno"  value={values.accno} onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12}>
     <Typography gutterBottom>
      Account holder name:
     </Typography>
      <TextField required id="accname" name="accname"  value={values.accname} onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12}>
     <Typography gutterBottom>
      IFSC:
     </Typography>
      <TextField required id="ifsc" name="ifsc"  value={values.ifsc} onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12}>
     <Typography gutterBottom>
      Bank Statement Upload:
     </Typography>
    <Typography gutterBottom>
    {/*
    <label htmlFor="upload-button">
      {
        bankImage.preview ? <img src={ bankImage.preview } width="300" height="200" /> : (
          <>
            <span className="fa-stack fa-2x mt-3 mb-2">
              <i className="fas fa-circle fa-stack-2x" />
              <i className="fas fa-store fa-stack-1x fa-inverse" />
            </span>
            <input id="filess" type="file" variant="outlined" onChange={props.handleUploadBank} /> 
          </>
        )
      }
    </label>
  */}      
       <input id="filess" type="file" variant="outlined" onChange={props.handleUploadBank} /> 
    </Typography>
    </Grid>
  </Grid>
 </React.Fragment>
);
}
