import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Autocomplete} from '@material-ui/lab';
import { TextField, MenuItem, FormHelperText, TablePagination, Typography } from '@material-ui/core';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorIcon from '@material-ui/icons/Error';
import { withRouter } from 'react-router-dom';
import { getIndInvoice, deleteIndInvoice, postIndInvoice, getIndInvoiceItems, updateIndInvoice, getVendorForm,
		 updateIndInvPayment, uploadIndInvoice, getPorders, getPayments, postPayments, getCashfreeBalance, cashDocUpload, 
     postCashPayments, sendPaymentEmail, postF2sms } from '../../actions/asyncActions';
import PrintCheque from './PrintCheque.js';

const NewPayment = (props) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const userEmail = user.email;
  const phoneNumber = user.phone;
  console.log("phoneNumber", phoneNumber)
  const group_id = user.group_id;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [invoice, setInvoice] = useState([]);
  const filterInvoice = invoice.filter(x=>x.status === 'GRN Matched');
  const filterInvoiceApproved = invoice.filter(x=>x.status === 'Approved');
  const [invoicemodal, setInvoiceModal] = useState()
  const [invId, setInvId ] = useState()
  const [invitemsmodal, setInvItemsModal] = useState()
  const [invoiceItems, setInvoiceItems] = useState([]);
  const filterInvItems = invoiceItems.filter(x=>x.invoice_id === invId);
  const findStatus = filterInvItems.find(x=>x.status === 'Approved')
  const [vendorform, setVendors] = useState([]);
  const approvedVendors = vendorform.filter(x=>x.status === 'Approved');
  const [vendor_val, setVendorVal] = useState(null);
  const selectedVendorId = vendor_val && vendor_val.id;
  const selectedVendor = vendor_val && vendor_val.supname;
  const vendorEmail = vendor_val && vendor_val.email;
  const vendorBene = vendor_val && vendor_val.beneId;
  console.log("Beneficiary", vendorBene); 
  const vendorName = (selectedVendor || '').toUpperCase();

  var firstWord = vendorName.replace(/ .*/, ''); //GetFirstWord of Supplier Vendor Name
  // const joinVendor = vendorName.split(" ").join(""); // removed space from the Vendor Name

  function getRandom(length) { //Random Number Generator
    return Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1));
  }

  var getRandomNo = getRandom(12);
  var getTransferId = "transfer".concat(selectedVendorId, getRandomNo); //Transfer ID 

  const filInvVendor = invoice.filter(x=>x.vendor_id == vendorName && x.status === 'GRN Matched');
  const [ppmodal, setPPModal] = useState();
  const [confirmmodal, setConfirmModal] = useState();

  const [porders, setPorders] = useState([]);
  const [payments, setPayments] = useState([]);

  const [showTable, setShowTable] = useState(false);

  const [cashfreeBalance, setBalance] = useState();
  const [cfmodal, setCfModal] = useState();

  const [cashmodal, setCashModal] = useState();
  const [voucher, setVoucher] = useState();
  const [cashremarks, setCashRemarks] = useState();

  const [docfile, setDocFile] = useState([]);

  const [successmodal, setSuccessModal] = useState();
  const [failuremodal, setFailureModal] = useState();

  const [errorResponse, setErrorResponse] = useState();

  const openSuccessModal = () => {
  	setSuccessModal(!successmodal)
    setPrice([{}]);
  }

  const openFailureModal = () => {
  	setFailureModal(!failuremodal)
  }

  const checkInvoice = () => {
    //setInvoiceModal(!invoicemodal);
    setShowTable(true);
  }

  const openInvItems = n => {
    setInvId(n.id)
    setInvItemsModal(!invitemsmodal)

  }

  const [enteredOtp, setOtp] = useState(new Array(6).fill(""));

  const handleOTPChange = (element, index) => {
      if (isNaN(element.value)) return false;

      setOtp([...enteredOtp.map((d, idx) => (idx === index ? element.value : d))]);

      //Focus next input
      if (element.nextSibling) {
          element.nextSibling.focus();
      }
  };

  const handleUpdate = () => {
    if((findStatus || '').status === 'Approved'){
      toast.error("One or more items are yet to be matched with GRN")
      setInvItemsModal(!invitemsmodal)
    } else {

    const aaa = invoice.find(x=>x.id === invId);
    const bbb = vendorform.find(x=>x.supname.toUpperCase() === aaa.vendor_id);
    const getCreditPeriod = bbb.credit;
    var d = new Date(aaa.apr_date);
    d.setDate(d.getDate() + parseInt(getCreditPeriod));
    const data = {
      id: invId,
      status: 'GRN Matched',
      due_date: d
    }
    updateIndInvoice(data)
      .then(resp => {
        getIndInvoice(group_id)
        .then(resp => setInvoice(resp.data))
        toast.success("Status Updated!!!")
        setInvoiceModal(!invoicemodal)
        setInvItemsModal(!invitemsmodal)
      })
      .catch(err => {
        console.log("Error", err);
    })
   }
  }

  const openPaymentProcess = () => {
    setPPModal(!ppmodal)
    setError('')
    setAddItems([])
    setVendorVal('')
    setModeVal('')
    setCashFreeModeVal('')
    setPrice([{}]);
  }

  const [genOTP, setGenOTP] = useState();

  const generateOTP = () => {
    let num = '1234567890';
    let OTP = '';

    for(let i=0;i<6;i++) {
      OTP += num[Math.floor(Math.random()*10)]
    }
    setGenOTP(OTP);
    return OTP;
  }

  const openCashfreeBalance = () => {
  	const pdata = {
  	  checkBalance: true
  	}
  	  //getCashfreeBalance()
  	  postPayments(pdata)
	  .then(resp => {
		setBalance(resp.data.data.availableBalance)
		setCfModal(!cfmodal)
	  })
  	  .catch(err => {
        console.log("Error", err);
      })
  }


  const confirmPayment = () => {
  	if((modeval && modeval.name) === 'Cash') {
  		setCashModal(!cashmodal)
      setCashRemarks('')
      setVoucher('')
  	}
  	else if((modeval && modeval.name) === 'Cashfree') {
      const otpp = generateOTP()

      const f2sms = {
        number: phoneNumber,
        otpval: otpp
      }
  		const pdata = {
	  	  checkBalance: true
	  	}
  		// getCashfreeBalance()
      postF2sms(f2sms)
      .then(resp =>{
        postPayments(pdata)
        .then(resp => {
          setBalance(resp.data.data.availableBalance)
          openConfirm()
        })  
      })
  		.catch(err => {
          console.log("Error", err);
        })
  	}
  	else {
  		handleSubmit()
  	}
  	
  }

  const openConfirm = () => {
  	setConfirmModal(!confirmmodal)
  }

  const modeofpay = [{name: 'Cashfree'}, {name: 'Cheque'}, {name: 'Cash'} ]
  const [modeval, setModeVal] = useState(null);

  const cashfreemodes = [{name: 'Cashfree-IMPS', value:'banktransfer'}, {name: 'Cashfree-NEFT', value:'banktransfer'},
  						 {name: 'Cashfree-UPI', value: 'upi'}, {name: 'Cashfree-Card', value:'card'},
  						 {name: 'Paytm-Wallet', value: 'paytm'}, {name: 'AmazonPay-Wallet', value:'amazonpay'}]
  const [cashfreemodeval, setCashFreeModeVal] = useState(null);

  const [errors, setError] = useState({});
  const validateForm = (data, i) => {
   const errors = [];
    if(!data[i].mode){
      errors.mode = "Please choose mode of payment";
    }
    if((data[i].mode === 'Cashfree') && (!data[i].cashfree_mode)){
      errors.cashmode = "Please choose Cashfree mode";
    }
    if(data[i].amount > data[i].invAmount){
      errors.price = "Entered amount is greater than invoice Amount";
    }
    if(data[i].amount + data[i].paidAmt > data[i].invAmount){
      errors.price = "Entered amount is greater than invoice Amount"; 
    }
   return errors;
 }

  // const handleCashSubmit = async () => {
  // 	const formData = new FormData();
  //   formData.append( 'file', docfile );
  //   const dOrder = await cashDocUpload(formData);
  //   const docfilepath = dOrder.data.filename;

  //   let fieldsComp = addItems;
  //   const arr = [];
  //   fieldsComp.forEach(item => {
  //     arr.push({ 
  //       id:item.id
  //     })
  //   });

  //   let paymentdata = [];
  //   let errors = [];
  //   let errorsQty = [];
  //   let invoiceAmt;
  //   price.forEach(async (obj, idx) => {
  //     if(obj.price > 0){
  //       const {id, po_no, vendor_id, invoice_amount, invType } = fieldsComp[idx];
  //       const filterInvItems = invoiceItems.filter(x=>x.invoice_id === id);
  //       const itemAmt = filterInvItems.reduce((a,b) => a + parseFloat(b.amount), 0);
  //       const itemGst = filterInvItems.reduce((a,b) => a + parseFloat(b.amount * (parseFloat(b.gst)/100)), 0);
  //       const filterPorders = porders.find(x=>x.id == po_no)
  //       const tds = filterPorders && filterPorders.tds
  //       const invAmount = itemAmt + itemGst
  //       const invTdsAmt = itemAmt - (itemAmt * (parseInt(tds)/100)) + itemGst
  //       if(invType === 'Service'){
  //         invoiceAmt = invTdsAmt
  //       }
  //       else{
  //         invoiceAmt = invAmount
  //       }
  //       const pay_amount = payments.find(x=>x.invoice_id == id);
  //       const paidAmt = (pay_amount && pay_amount.amount) || 0;
  //       const sprice = parseFloat(obj.price || 0);
  //       if(paidAmt){
  //         paymentdata.push({
  //           invoice_id: id,
  //           amount: sprice,
  //           paidAmt: parseFloat(paidAmt),
  //           invAmount: invoiceAmt,
  //           po_no: po_no,
  //           vendor_id: vendor_id,
  //           mode: modeval && modeval.name,
  //           voucher: voucher,
  //           remarks: cashremarks,
  //           filepath: docfilepath,
  //           paid_date: new Date(),
  //           user_id: user.id,
  //           group_id: user.group_id
  //         })
  //         errors = validateForm(paymentdata, idx);

  //         if(Object.keys(errors).length) {
  //          errorsQty.push({
  //           aaa: errors
  //          });
  //          setError(errors);
  //          return;
  //         }  
  //       }
  //       else {
  //         paymentdata.push({
  //           invoice_id: id,
  //           amount: sprice,
  //           invAmount: invoiceAmt,
  //           po_no: po_no,
  //           vendor_id: vendor_id,
  //           mode: modeval && modeval.name,
  //           voucher: voucher,
  //           remarks: cashremarks,
  //           filepath: docfilepath,
  //           paid_date: new Date(),
  //           user_id: user.id,
  //           group_id: user.group_id
  //         })
  //         errors = validateForm(paymentdata, idx);

  //         if(Object.keys(errors).length) {
  //           errorsQty.push({
  //             aaa: errors
  //           });
  //           setError(errors);
  //           return;
  //         }
  //       }
  //     }
  //   })
  //   const pdata = {
  //     items: paymentdata
  //   }
  //   if(Object.keys(errorsQty).length > 0){
  //     return;
  //   }
  //   else {

  //     //updateIndInvPayment(data)
  //     postCashPayments(pdata)
  //     .then(resp => {
  //         getIndInvoice(group_id)
  //         .then(resp => setInvoice(resp.data))
  //         getPayments(group_id)
  //         .then(resp => setPayments(resp.data))
  //         setPPModal(!ppmodal)
  //         setVendorVal('')
  //         setModeVal('')
  //         setCashFreeModeVal('')
  //         setPrice([{}]);
  //         setVoucher('')
  //         setCashRemarks('')
  //         setCashModal(!cashmodal)
  //         toast.success("Payment submitted successfully", {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
  //       })
  //       .catch(err => {
  //         console.log("Error", err);
  //     })
  //   }

  // }

  const handleSubmit2 = () => {
    if(parseInt(genOTP) === parseInt(enteredOtp.join(""))){
      toast.success("SUCCESS")
    }
    else{
      toast.error("FAILED")
    }
  }

  const handleSubmit = async() => {
    //const user = JSON.parse(localStorage.getItem("m_users"));
    //const userEmail = user && user.email;
  
    const emailData = {
      userEmail: userEmail,
      vendorEmail: vendorEmail,
      rupees: addPrice,
      reference_id: getTransferId
    }

    const formData = new FormData();
    formData.append( 'file', docfile );
    const dOrder = await cashDocUpload(formData);
    const docfilepath = dOrder.data.filename;

    let fieldsComp = addItems;
    const arr = [];
    fieldsComp.forEach(item => {
      arr.push({ 
        id:item.id
      })
    });
    const data = {
      items: arr,
      group_id: group_id
    }

    let paymentdata = [];
    let errors = [];
    let errorsQty = [];
    let invoiceAmt;
    price.forEach(async (obj, idx) => {
      if(obj.price > 0){
        const {id, po_no, vendor_id, invoice_amount, invType } = fieldsComp[idx];
        const filterInvItems = invoiceItems.filter(x=>x.invoice_id === id);
        const itemAmt = filterInvItems.reduce((a,b) => a + parseFloat(b.amount), 0);
        const itemGst = filterInvItems.reduce((a,b) => a + parseFloat(b.amount * (parseFloat(b.gst)/100)), 0);
        const filterPorders = porders.find(x=>x.id == po_no)
        const tds = filterPorders && filterPorders.tds
        const invAmount = itemAmt + itemGst
        const invTdsAmt = itemAmt - (itemAmt * (parseInt(tds)/100)) + itemGst
        if(invType === 'Service'){
          invoiceAmt = invTdsAmt
        }
        else{
          invoiceAmt = invAmount
        }
        const pay_amount = payments.find(x=>x.invoice_id == id);
        const paidAmt = (pay_amount && pay_amount.amount) || 0;
        const sprice = parseFloat(obj.price || 0);
        if(paidAmt){
          paymentdata.push({
            invoice_id: id,
            amount: sprice,
            paidAmt: parseFloat(paidAmt),
            invAmount: invoiceAmt,
            po_no: po_no,
            vendor_id: vendor_id,
            mode: modeval && modeval.name,
            cashfree_mode: cashfreemodeval && cashfreemodeval.value,
            voucher: voucher,
            remarks: cashremarks,
            filepath: docfilepath,
            paid_date: new Date(),
            user_id: user.id,
            group_id: user.group_id
          })
          errors = validateForm(paymentdata, idx);

          if(Object.keys(errors).length) {
           errorsQty.push({
            aaa: errors
           });
           setError(errors);
           return;
          }  
        }
        else {
          paymentdata.push({
            invoice_id: id,
            amount: sprice,
            invAmount: invoiceAmt,
            po_no: po_no,
            vendor_id: vendor_id,
            mode: modeval && modeval.name,
            cashfree_mode: cashfreemodeval && cashfreemodeval.value,
            voucher: voucher,
            remarks: cashremarks,
            filepath: docfilepath,
            paid_date: new Date(),
            user_id: user.id,
            group_id: user.group_id
          })
          errors = validateForm(paymentdata, idx);

          if(Object.keys(errors).length) {
            errorsQty.push({
              aaa: errors
            });
            setError(errors);
            return;
          }
        }
      }
    })
    const pdata = {
      items: paymentdata,
      transferId: getTransferId,
      vid: selectedVendorId,
      price: addPrice,
      transferMode: cashfreemodeval && cashfreemodeval.value,
      checkBalance: false
    }
    if(Object.keys(errorsQty).length > 0){
      return;
    }
    else if(modeval && modeval.name === 'Cash') {
    	postCashPayments(pdata)
        .then(resp => {
          getIndInvoice(group_id)
          .then(resp => setInvoice(resp.data))
          getPayments(group_id)
          .then(resp => setPayments(resp.data))
          setPPModal(!ppmodal)
          setVendorVal('')
          setModeVal('')
          setCashFreeModeVal('')
          //setPrice([{}]);
          setVoucher('')
          setCashRemarks('')
          setCashModal(!cashmodal)
          setSuccessModal(!successmodal)
          //toast.success("Payment submitted successfully", {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
        })
        .catch(err => {
          console.log("Error", err);
        })
    }
    else if(modeval && modeval.name === 'Cheque') {
    	postCashPayments(pdata)
    	.then(resp => {
    	  getIndInvoice(group_id)
          .then(resp => setInvoice(resp.data))
          getPayments(group_id)
          .then(resp => setPayments(resp.data))
    	  handlePrintCheque();
    	  setPPModal(!ppmodal)
        //setSuccessModal(!successmodal)
    	  toast.success("Payment submitted successfully", {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
    	})
    	.catch(err => {
          console.log("Error", err);
        })
    }
    else if(modeval && modeval.name === 'Cashfree')  {
      if(parseInt(genOTP) === parseInt(enteredOtp.join(""))){
      //updateIndInvPayment(data)
      postPayments(pdata)
      .then(resp => {
      	  console.log("Payment Response:", resp.data);
      	  //console.log("respdatatransfer", resp.data.respTransferStatus.data.status)
      	  setErrorResponse(resp.data.message)
      	  //console.log("getBalance", resp.data.getBala.data.availableBalance);
      	  //alert("Hello Nu-Pie")
          // getIndInvoice(group_id)
          // .then(resp => setInvoice(resp.data))
          // getPayments(group_id)
          // .then(resp => setPayments(resp.data))
          //alert(`The available balance is: ${resp.data.getBala.data.availableBalance}`);
          setPPModal(!ppmodal)
          //setVendorVal('')
          setModeVal('')
          setCashFreeModeVal('')
          //setPrice([{}]);
          openConfirm()
          if(resp.data.name === 'incorectResponseError') {
          	setFailureModal(!failuremodal)
          }
          //else if(resp.data.respTransferStatus.data.status !== undefined ){
          else{
            sendPaymentEmail(emailData)
             setVendorVal('')
             //setPrice([{}])
             //Success ModaL
             setSuccessModal(!successmodal)
          }
        
      //     	if(resp.data.respTransferStatus.data.status === 'SUCCESS'){
      //     		if(userEmail == '' || vendorEmail == '') {
	  			// } 
	  	  //       else {
	  	  // 		 sendPaymentEmail(emailData)
	  	  // 		 .then(resp => {
	  	  // 		 	setVendorVal('')
	  	  // 		 	setPrice([{}])
	  	  // 		 	//Success ModaL
      //     	  setSuccessModal(!successmodal)
	  	  // 		 	//toast.success("An Email has been sent to the vendor!!!", {position: toast.POSITION.TOP_RIGHT, autoClose:3000})
	  	  // 		 })
	  	  // 		}
      //       }
            // else{
          	 // //Failure Modal
          	 // setFailureModal(!failuremodal)
            // }	
          //}
          
        //})
        // .catch(err => {
        //   console.log("Error", err);
        //   console.log("Error Somewhere");
        // })
    })
  }
  else {
    toast.error("The OTP entered is wrong!!!")  
  }
 }
}

  const [values, setValues] = useState({});
  const [invoiceDate, handleInvoiceDate] = useState(new Date());
  const [invoicefile, setInvoiceFile] = useState([]);
  const [fields, setFields] = useState([{ description: '', hsn:'', gst:'', length:'', width:'', nos:'', qty:'', rate:'', amount:'' }]);
  
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  function addClick(){
    const values = [...fields];
    values.push({ description: '', hsn:'', gst:'', length:'', width:'', nos:'', qty:'', rate:'', amount:'' });
    setFields(values);
    }

  function handleRemove(i){
    const values = [...fields];
    values.splice(i,1);
    setFields(values);
  }

  function handleChange3(i,e){
    const tempValues = [...fields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFields(tempValues);
  }

  const handleUploadInvoice = async (event) => {
    setInvoiceFile(document.getElementById('invoicefile').files[0]);
  }
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUploadDoc = async (event) => {
    setDocFile(document.getElementById('filedoc').files[0]);
  }

  useEffect( () => {
    getIndInvoice(group_id)
     .then(resp => setInvoice(resp.data))
  }, []);

  useEffect( () => {
    getIndInvoiceItems(group_id)
     .then(resp => setInvoiceItems(resp.data))
  }, []);

  useEffect( () => {
    getVendorForm(group_id)
     .then(resp => setVendors(resp.data))
  }, []);

  useEffect( () => {
   getPorders(group_id)
    .then(resp => setPorders(resp.data))
  }, []);

  useEffect( () => {
   getPayments(group_id)
    .then(resp => setPayments(resp.data))
  }, []);
  
  const viewRow = (n) => {
   const id = n.id;
   const invoiceNo = n.invoice_no;
   const invoiceDate = n.invoice_date;
   const custName = n.customer_name;
   const email = n.email_address;
   const website = n.customer_website;
   const amount = n.invoice_amount;
   const invFile = n.inv_file;
   const invExt = n.inv_ext;
   const remarks = n.remarks;
   const vendor_name = n.vendor_id;
   const temp_id = n.temp_id;
   const gst_no = n.gst_no;
   const filterInvItems = invoiceItems.filter(x=>x.invoice_id === n.id);
   props.history.push(`${process.env.PUBLIC_URL}/viewinvoice`, 
    {id,invoiceNo,invoiceDate,custName,email,website,amount,invFile,invExt,filterInvItems,remarks,vendor_name,temp_id,gst_no});
  }

  const deleteRow = (n) => {
    const deleteId = {
      id : n.id,
      temp_id: n.temp_id
    }
    deleteIndInvoice(deleteId)
      .then(resp => {
        getIndInvoice(group_id)
        .then(resp => setInvoice(resp.data))
        toast.error("Invoice deleted!!!")
      })
      .catch(err => {
        console.log("Error", err);
    })
  }

  const [price, setPrice] = React.useState([{}]);
  const addPrice = price.reduce((a, b) => a + parseFloat(b.price), 0);
  const [addItems, setAddItems] = useState([]);
  const addAmount = addItems.reduce((a, b) => a + parseInt(b.invoice_amount), 0);

  const [compareDate, setComparedDate] = useState();

  const handlePriceChange = (event, i) => {
    const tempQtys = [...price];
    tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
    setPrice(tempQtys);
  }

  const handlePrintCheque = () => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(`<html><head><title>${document.title}</title>
     <style>
     @page { margin: 0 }
      body { margin: 0 }
      .sheet {
      margin: 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      page-break-after: always;
      }

      /* Padding area */
      .sheet.padding-5mm { padding: 5mm }

      /* For screen preview */
      @media screen {
      body { background: #e0e0e0 }
        .sheet {
         background: white;
         box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
         margin: 2mm;
       }
       table {
          border-collapse: collapse;
      }
       tr.border_bottom td {
        border-bottom:1pt solid black;
      }
      table th, table td {
      border:1px solid #000;
        padding: 0.5em;
      }
      }
      @media print {
          html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
          font-size: 9pt !important;
               }
            }

     </style>
     `);
   mywindow.document.write('</head><body>');
   mywindow.document.write(document.getElementById('printreceipt').innerHTML);
   mywindow.document.write('</body></html>');

   mywindow.document.close(); // necessary for IE >= 10
   mywindow.focus(); // necessary for IE >= 10*/

   mywindow.print();
   mywindow.close();

   return true;
  }

	return (
	 <Fragment>
	 	<div style={{display:"none"}}>
          <PrintCheque 
            selectedVendor={vendorName}
            rupees={addPrice}
          />
        </div>
		<div className="container-fluid">
            <div className="row">
                <div className="col-xl-4 col-md-4 m-t-15">
                 <button className="btn btn-info btn-lg" type="button" onClick={checkInvoice}>
	             	Check for Invoices
	             </button>
	            </div>
	            <div className="col-xl-4 col-md-4 m-t-15">
                 <button className="btn btn-success btn-lg" type="button" onClick={() => openPaymentProcess()}>
	             	Process Payment
	             </button>
	            </div>
	            <div className="col-xl-4 col-md-4 m-t-15">
                 <button className="btn btn-warning btn-lg" type="button" onClick={() => openCashfreeBalance()}>
	             	Check Cashfree Balance
	             </button>
	            </div>
	            {showTable === true ?
	             <div className="col-xl-12 m-t-15">
	              <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-info">
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Invoice no</th>
                                            <th scope="col">Customer</th>
                                            <th scope="col">Invoice Amount</th>
                                            <th scope="col">Paid Amount</th>
                                            <th scope="col">Due Date</th>
                                            {/*<th scope="col">Action</th> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                            {filterInvoice.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((n, i)=> {
                                              const filterPayments = payments.filter(x=>x.invoice_id == n.id);
                                              const itemAmt = filterPayments.reduce((a,b) => a + parseFloat(b.amount), 0);
                                              return (
                                                <tr>
                                                 <th scope="row">{i+1}</th>
                                                 <td>{n.invoice_no}</td>
                                                 <td>{n.customer_name}</td>
                                                 <td>{n.invoice_amount}</td>
                                                 <td>{itemAmt}</td>
                                                 <td>
                                                  {new Date(n.due_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                 </td>
                                                </tr>
                                              );
                                            })
                                          }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-xl-12">
                             <TablePagination
                               rowsPerPageOptions={[5, 10, 25]}
                               component="div"
                               count={filterInvoice.length}
                               rowsPerPage={rowsPerPage}
                               page={page}
                               onChangePage={handleChangePage}
                               onChangeRowsPerPage={handleChangeRowsPerPage}
                             />
                            </div>
                        </div>
                    </div>
	             </div> 
	             : null
	            }
	               
            </div>            
        </div>

        {/* < !-- Using Modal for Checking Approved Invoices  --> */}
            <Modal isOpen={invoicemodal} toggle={checkInvoice} size="md">
              <ModalHeader toggle={checkInvoice}>Approved Invoices</ModalHeader>  
                <ModalBody>
                    <form>
                     <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                       {filterInvoiceApproved.map((n,i)=> {
                        return(
                         <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                           <button type="button" className="btn btn-primary btn-outline" style={{height:'35px'}}
                            onClick={() => openInvItems(n)}>
                            {n.invoice_no}
                           </button>
                          </li>
                         </ul>
                        )
                        })
                       }  
                      </div>
                     </div>
                    </form>
                </ModalBody>
            </Modal>

      {/* < !-- Using Modal for Viewing  Invoice Items  --> */}
            <Modal isOpen={invitemsmodal} toggle={openInvItems} size="lg">
              <ModalHeader toggle={openInvItems}>Invoice Items</ModalHeader>  
                <ModalBody>
                  <div className="col-xl-12">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                         <thead className="table-info">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Description</th>
                            <th scope="col">HSN</th>
                            <th scope="col">GST</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Rate</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                          </tr>
                         </thead>
                         <tbody>
                          {filterInvItems.map((n,i)=> {
                            return(
                             <tr>
                              <td>{i+1}</td>
                              <td>{n.description}</td>
                              <td>{n.hsn}</td>
                              <td>{n.gst}</td>
                              <td>{n.qty}</td>
                              <td>{n.rate}</td>
                              <td>{n.amount}</td>
                              <td>{n.status}</td>
                             </tr>
                            );
                           })
                          }
                         </tbody>
                        </table>
                      </div>
                  </div>
                  <div className="col-xl-12 text-center m-t-15">
                   <button className="btn btn-success btn-md" type="button" onClick={handleUpdate}>
                    Update Invoice
                   </button>
                  </div>
                </ModalBody>
            </Modal> 

            {/* Payment Process Modal */}
        <Modal isOpen={ppmodal} toggle={openPaymentProcess} className="modal-dialog modal-xl">
          <ModalHeader toggle={openPaymentProcess}>Payment Processing</ModalHeader>
            <ModalBody>
                <div className="offset-sm-3 col-xl-6 col-lg-6 col-sm-12">
                  <Autocomplete
                    freeSolo
                    options={approvedVendors}
                    getOptionLabel={option => option.supname}
                    value={vendor_val}                      
                    onChange={(event, newValue) => {
                      setVendorVal(newValue);
                    }}            
                    renderInput={params => (
                      <TextField {...params} label="Vendor" margin="normal" variant="outlined" helperText="Please select Vendor" fullWidth />
                    )}
                  />
                </div>
                { selectedVendor ?
                  <Fragment>
                  <div className="col-xl-12">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered table-hover">
                          <thead className="table-info">
                              <tr>
                                <th scope="col">Sl.No</th>
                                <th scope="col">Inv No</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Paid Amt</th>
                                <th scope="col">DueDate</th>
                                <th scope="col">To Pay</th>
                                <th scope="col">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              {filInvVendor.map((n, i)=> {
                                const filterInvItems = invoiceItems.filter(x=>x.invoice_id === n.id);
                                const itemAmt = filterInvItems.reduce((a,b) => a + parseFloat(b.amount), 0);
                                const itemGst = filterInvItems.reduce((a,b) => a + parseFloat(b.amount * (parseFloat(b.gst)/100)), 0);
                                const filterPorders = porders.find(x=>x.id == n.po_no)
                                const tds = filterPorders && filterPorders.tds
                                const invAmount = itemAmt + itemGst
                                const invTdsAmt = itemAmt - (itemAmt * (parseInt(tds)/100)) + itemGst
                                //const aaa = payments.find(x=>x.invoice_id == n.id) 
                                //const paidAmt = aaa && aaa.amount
                                const filterPayments = payments.filter(x=>x.invoice_id == n.id);
                                const paidAmt = filterPayments.reduce((a,b) => a + parseFloat(b.amount), 0);
                                const toPay = parseFloat(n.invoice_amount) - paidAmt;
                                const dueDate = new Date(n.due_date);
                                const currentDate = new Date();
                                var compareDates = dueDate.getTime() > currentDate.getTime();
                                return (
                                  <tr>
                                   <td>{i+1}</td>
                                   <td>{n.invoice_no}</td>
                                   <td>{n.customer_name}</td>
	                                  {n.invType === 'Product' ?
	                                    <td>{invAmount}</td>
	                                    :
	                                    <td>{invTdsAmt}</td>
	                                  }
                                   <td>{paidAmt}</td>
                                   <td>
                                    {new Date(n.due_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                   </td>
                                   <td>
                                    <TextField type="text" name="price" key={i} margin="dense"
                                      value = {price[i] ? price[i].price: 0} onChange={(e) => handlePriceChange(e, i)}/>
                                      <FormHelperText className="text-danger">{errors.price}</FormHelperText>
                                   </td>
                                   <td>
                                    <input type="checkbox" checked={addItems.includes(n)} 
                                     onChange={(e) => {
                                      const checked = addItems.includes(n)
                                      setAddItems((prev) => 
                                       checked
                                      ? prev.filter((sc) => sc !== n)
                                      : [...prev, n ]
                                      );
                                      setComparedDate(compareDates);
                                     }} 
                                    />
                                   </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                      </table>
                    </div>
                </div> <br/>
                {addAmount > 0 ?
                 <div className="col-sm-12 text-center m-t-15">
                  {compareDate === true ?
                  	<h5> Note: One or more invoices are not "due for payment", are you sure you want to proceed with an early payment? </h5>
                  	: null
                  }
                  <h4 className="m-t-15"> Payment Amount = {addPrice} /-</h4> <br/>
                  <div className="offset-sm-4 col-xl-4 col-lg-4 col-sm-12">
                    <Autocomplete
                      freeSolo
                      options={modeofpay}
                      getOptionLabel={option => option.name}
                      value={modeval}                      
                      onChange={(event, newValue) => {
                        setModeVal(newValue);
                      }}            
                      renderInput={params => (
                        <TextField {...params} label="Payment Modes" margin="normal" variant="outlined" helperText="Choose mode of payment" fullWidth />
                      )}
                    />
                    <FormHelperText className="text-danger">{errors.mode}</FormHelperText>
                  </div> <br/>
                  { modeval && modeval.name === 'Cashfree' ?
                  	<div className="offset-sm-4 col-xl-4 col-lg-4 col-sm-12">
                  	 <Autocomplete
                      freeSolo
                      options={cashfreemodes}
                      getOptionLabel={option => option.name}
                      value={cashfreemodeval}                      
                      onChange={(event, newValue) => {
                        setCashFreeModeVal(newValue);
                      }}            
                      renderInput={params => (
                        <TextField {...params} label="Cashfree Modes" margin="normal" variant="outlined" 
                         helperText="Choose Cashfree modes" fullWidth />
                      )}
                    />
                    <FormHelperText className="text-danger">{errors.cashmode}</FormHelperText>
                    </div>	
                    : null
                  }
                  
                  <button className="btn btn-success btn-md" type="button" onClick={confirmPayment}>
                   Process Payment
                  </button>
                 </div>
                 : 
                 null  
                }
              </Fragment>
                   : 
                   null
                }
            </ModalBody>
        </Modal>

    {/* Cashfree Balance Modal */}
        <Modal isOpen={cfmodal} toggle={openCashfreeBalance} className="modal-dialog modal-md">
          <ModalHeader toggle={openCashfreeBalance}>Cashfree Balance</ModalHeader>
            <ModalBody>
            	<div className="row">
	             <div className="offset-xl-1 col-xl-10">
	              <h4> The current balance in cashfree is: </h4><br/>
	               <h2 className="text-center" style={{color: 'green'}}> ₹ {cashfreeBalance} </h2>
	             </div>
	            </div>
            </ModalBody>
        </Modal>

        {/* Confirm Payment Process Modal */}
        <Modal isOpen={confirmmodal} toggle={confirmPayment} className="modal-dialog modal-md">
          <ModalHeader toggle={openConfirm}>Confirm Payment</ModalHeader> 
            <ModalBody>
              <div className="row">
               <div className="col-xl-12 text-center">
                <h5>Enter the OTP sent to you to verify your identity</h5>
                    {enteredOtp.map((data, index) => {
                        return (
                          <input
                            style={{width: '38px', marginRight: '10px', paddingLeft: '12px', height: '40px'}}
                            type="text"
                            name="enteredOtp"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={e => handleOTPChange(e.target, index)}
                            onFocus={e => e.target.select()}
                          />
                        );
                    })}

                    <p>OTP Entered - {enteredOtp.join("")}</p>
                    <p>
                        <button
                            className="btn btn-secondary mr-2"
                            onClick={e => setOtp([...enteredOtp.map(v => "")])}
                        >
                            Clear
                        </button>
                        {/*
                        <button
                            className="btn btn-primary"
                            onClick={e =>
                                toast.success(`Entered OTP is ${enteredOtp.join("")}`)
                            }
                        >
                            Verify OTP
                        </button>
                      */}
                    </p>
                  </div>
                  <div className="col-xl-12 text-center">
                    <h5> The current balance in cashfree is: </h5>
                    <h2 className="text-center" style={{color: 'green'}}> ₹ {cashfreeBalance} </h2>
                  </div>
                  <div className="col-xl-6 text-center">
                   <button className="btn btn-danger btn-md" type="button" onClick={openConfirm}>
                    Cancel
                   </button>
                  </div>
                  <div className="col-xl-6 text-center">
                   <button className="btn btn-success btn-md" type="button" onClick={handleSubmit}>
                    Confirm Payment
                   </button>
                  </div>
              </div>
            </ModalBody>
        </Modal>

    {/* Cash Mode Modal */}
        <Modal isOpen={cashmodal} toggle={confirmPayment} className="modal-dialog modal-md">
          <ModalHeader toggle={confirmPayment}>Enter the details for cash payment</ModalHeader>
            <ModalBody>
            	<div className="row">
            	 <div className="col-xl-12">
            	  <TextField onChange={(e) => setVoucher(e.target.value)} value={voucher} margin="dense"
            	   label="Voucher" variant="outlined" helperText="Please enter Voucher ID" fullWidth />
            	 </div>
            	 <div className="col-xl-12">
            	  <TextField onChange={(e) => setCashRemarks(e.target.value)} value={cashremarks}
            	   margin="dense" label="Remarks" variant="outlined" fullWidth />
            	 </div>
            	 <div className="col-xl-12">
            	  <TextField type="file" id="filedoc" onChange={handleUploadDoc} 
            	   margin="dense" variant="outlined" fullWidth />
            	 </div>
            	 <div className="col-sm-12 text-center m-t-15">
            	  <button className="btn btn-secondary btn-md" type="button" onClick={handleSubmit}>
	                Confirm Payment
	              </button>
            	 </div>
	            </div>
            </ModalBody>
        </Modal>

	    {/* Success Payment Modal */}
	    <Modal isOpen={successmodal} toggle={openSuccessModal} className="modal-dialog modal-md">
      <ModalHeader toggle={openSuccessModal}>Notification</ModalHeader>
	     <ModalBody>
	        <Typography align="center">
	          <CheckCircleOutlineRoundedIcon style={{fontSize: '100px', color:'#4bb543'}}/>
	        </Typography>
	        <div className="col-xl-12">
	         <Typography component="h2" variant="h6" align="center" gutterBottom>
	          The Payment of ₹ {addPrice} INR was successful...
	         </Typography>
	        </div>
	     </ModalBody>
	    </Modal>

	{/* Failure Payment Modal */}
	    <Modal isOpen={failuremodal} toggle={openFailureModal} className="modal-dialog modal-md">
	     <ModalBody>
	        <Typography align="center">
	          <ErrorIcon style={{fontSize: '100px', color:'#dd2c00'}}/>
	        </Typography>
	        <div className="col-xl-12">
	         <Typography component="h2" variant="h6" align="center" gutterBottom>
	          Payment Failed: {errorResponse}
	         </Typography>
	        </div>
	     </ModalBody>
	    </Modal>

	 </Fragment>
	);
}

export default withRouter(NewPayment);