import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
//import Link from '@material-ui/core/Link';
import { Dialog, DialogActions, DialogContent, Grid, Table, TableHead, TableCell, TableRow,
TextField, FormGroup, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import VendorDetails from './VendorDetails';
import CompanyDetails from './CompanyDetails';
import LandProprietorDetails from './LandProprietorDetails';
import EmployeeDetails from './EmployeeDetails';
import FacilityDetails from './FacilityDetails';
import BankAccount from './BankAccount';
import { Audiotrack } from '@material-ui/icons';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import NuLogo from '../vendorList/nupie.png';
import { postVendorForm, getVendorForm, aadharUpload, panUpload, bankUpload, isoUpload,
         comUpload, esiUpload, gstUpload, pfUpload, getVendorCustForm, getVendorPlaceForm, getVendorPropForm, getVendorMachForm,
         getVendorInstForm }  from '../../actions/asyncActions';
import Breadcrumb from '../../components/common/breadcrumb';

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://material-ui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const useStyles = makeStyles(theme => ({

  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
    },
    // backgroundColor: '#c9918d'
    backgroundColor: '#e4e8ff' 
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    backgroundColor: '#e4e8ff'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    margin: theme.spacing(2),
  },
}));

const steps = ['Vendor', 'Company ', 'Land & Proprietor', 'Certification', 'Facilities', 'Bank Account'];


export default function Checkout(props) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const getThisVenId = user.vendor_id; // Get Vendor Id from the user table
  
  const [errors, setError] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [vendorForm, setVendorForm] = useState([]);
  const [vendorCustForm, setVendorCustForm] = useState([]);
  const [vendorPlaceForm, setVendorPlaceForm] = useState([]);
  const [vendorPropForm, setVendorPropForm] = useState([]);
  const [vendorMachForm, setVendorMachForm] = useState([]);
  const [vendorInstForm, setVendorInstForm] = useState([]);

  const foundVendor = vendorForm.find(x=>x.id === getThisVenId);
  const foundVendorId = foundVendor && foundVendor.id;
  const foundVendorDate = foundVendor && foundVendor.date;

  const filterCustData = vendorCustForm.filter(x => x.vendor_id === foundVendorId);
  const filterPlaceData = vendorPlaceForm.filter(x => x.vendor_id === foundVendorId);
  const filterPropData = vendorPropForm.filter(x => x.vendor_id === foundVendorId);
  const filterMachData = vendorMachForm.filter(x => x.vendor_id === foundVendorId);
  const filterInstData = vendorInstForm.filter(x => x.vendor_id === foundVendorId);
  
  useEffect( () => {
    getVendorForm(group_id)
    .then(resp => {
      console.log("respvendor", resp.data)
      setVendorForm(resp.data);
    })
  }, []);

  useEffect( () => {
    getVendorCustForm(group_id)
    .then(resp => {
      setVendorCustForm(resp.data);
    })
  }, []);

  useEffect( () => {
    getVendorPlaceForm(group_id)
    .then(resp => {
      setVendorPlaceForm(resp.data);
    })
  }, []);

  useEffect( () => {
    getVendorPropForm(group_id)
    .then(resp => {
      setVendorPropForm(resp.data);
    })
  }, []);

  useEffect( () => {
    getVendorMachForm(group_id)
    .then(resp => {
      setVendorMachForm(resp.data);
    })
  }, []);

  useEffect( () => {
    getVendorInstForm(group_id)
    .then(resp => {
      setVendorInstForm(resp.data);
    })
  }, []);

  const [values, setValues] = useState({});
  console.log("values", values);
  const datee = new Date();
  const [adharImage, setAadhar] = useState({preview:'', raw:''});
  const [panImage, setPan] = useState({preview:'', raw:''});
  const [bankImage, setBank] = useState({preview:'', raw:''});
  const [isoImage, setISO] = useState({preview:'', raw:''});
  const [comImage, setCom] = useState({preview:'', raw:''});
  const [esiImage, setEsi] = useState({preview:'', raw:''});
  const [gstImage, setGst] = useState({preview:'', raw:''});
  const [pfImage, setPf] = useState({preview:'', raw:''});
  const [radioVal, SetRadio] = React.useState({
    inspectbrotout:'', inspectbrotoutyes: '', testbrotout:'', testbrotoutyes: '', testbrotoutno: '', inprocess:'',
    finproinspect:'', qualcertify: '', isocertify:'', referredby:'', referredbyyes: '', revenue:'',
  });
  console.log("radioVal", radioVal);
  const [fields, setFields] = useState([{ placename: '', placeaddress:'', placecity:'', placestate:'', placegst:'' }]); 

  function addClick(){
    const values = [...fields];
    values.push({ placename:'', placeaddress:'', placecity:'', placestate:'', placegst:'' });
    setFields(values);
    }

  function handleRemove(i){
    const values = [...fields];
    values.splice(i,1);
    setFields(values);
  }

  function handleChange3(i,e){
    const tempValues = [...fields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFields(tempValues);
  }

  const [landfields, setLandFields] = useState([{ propname: '', propqualify:'', propexperience:'', propaddress:'' }]);
  
  function addClickLand(){
    const values = [...landfields];
    values.push({ propname: '', propqualify:'', propexperience:'', propaddress:'' });
    setLandFields(values);
    }

  function handleRemoveLand(i){
    const values = [...landfields];
    values.splice(i,1);
    setLandFields(values);
  }

  function handleChangeLand(i,e){
    const tempValues = [...landfields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setLandFields(tempValues);
  }

  const [custfields, setCustFields] = useState([{ customerlist:'' }]);
  
  function addClickCust(){
    const values = [...custfields];
    values.push({ customerlist:'' });
    setCustFields(values);
    }

  function handleRemoveCust(i){
    const values = [...custfields];
    values.splice(i,1);
    setCustFields(values);
  }

  function handleChangeCust(i,e){
    const tempValues = [...custfields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setCustFields(tempValues);
  }


  const [facilityfields, setFacilityFields] = 
   useState([{ 
     machines1:'', capacity1:'', qty1:''
   }]);

  function addClickFacility(){
    const values = [...facilityfields];
    values.push({ machines1:'', capacity1:'', qty1:'' });
    setFacilityFields(values);
    }

  function handleRemoveFacility(i){
    const values = [...facilityfields];
    values.splice(i,1);
    setFacilityFields(values);
  }

  function handleChangeFacility(i,e){
    const tempValues = [...facilityfields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFacilityFields(tempValues);
  }

  const [facilityfields2, setFacilityFields2] = 
  useState([{
    instruments2:'',capacity2:'', leasecount2:'', qty2:'', calibration2:''
  }])

  function addClickFacility2(){
    const values = [...facilityfields2];
    values.push({ instruments2:'',capacity2:'', leasecount2:'', qty2:'', calibration2:'' });
    setFacilityFields2(values);
    }

  function handleRemoveFacility2(i){
    const values = [...facilityfields2];
    values.splice(i,1);
    setFacilityFields2(values);
  }

  function handleChangeFacility2(i,e){
    const tempValues = [...facilityfields2];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFacilityFields2(tempValues);
  }

  const [subform, setSubForm] = React.useState(false);

  const handleSubFormClose = () => {
    setSubForm(false);
    setActiveStep(activeStep - (steps.length-1));
  };

  const handleNext = () => {
    const errors = validateForm(values);
     if(Object.keys(errors).length) {
      setError(errors);
      return;
     }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // const initialFormState = 
  // { 
  //   docno:'', revno:'', supname:'', factAddress:'', headAddress:'', telephone:'', office:'', res:'',
  //   faxno:'', mobileno:'', email:'', companyregno:'', esino:'', gstno:'', pfno:'', particularreg:'',
  //   capinvest:'', aadharno:'', panno:'', landarea:'', landown:'', landpower:'', turnover:'', propname:'', propqualify:'',
  //   propexperience:'', propaddress:'', skillmale:'',  skillfemale:'', semiskillmale:'', semiskillfemale:'', customerlist:'', risk:'', scopesupply:'', 
  //   bankname:'', accno:'', accname:'', ifsc:'', inspectbrotoutyes:'', testbrotoutyes:'', testbrotoutno:'', referredbyyes:'',
  // };

  
  const validateForm = (data) => {
     const errors = [];
     if(activeStep === 0){
     if(!data.supname) {
       errors.supname = "Supplier name is required";
     }
     if(!data.mobileno) {
       errors.mobileno = "Mobile number is required";
     }
     if(!data.email) {
       errors.email = "Email Id is required";
     }
    } else if(activeStep === 1){
     if(!data.companyregno){
      errors.companyregno = "Company Regno is required";
     }
     if(!data.gstno){
      errors.gstno = "GST number is required";
     }
     if(!data.panno){
      errors.panno = "PAN number is required";
     }
   }
     return errors;
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleRadioChange = event => {
    SetRadio({
      ...radioVal,
      [event.target.name]: event.target.value
    });
  };

  const handleUploadAadhar = async (event) => {
    //const user = JSON.parse(localStorage.getItem("m_users"));
    setAadhar({preview: URL.createObjectURL(event.target.files[0]), raw: document.getElementById('file').files[0]});
  }

  const handleuploadPAN = async (event) => {
    //const user = JSON.parse(localStorage.getItem("m_users"));
    setPan({preview: URL.createObjectURL(event.target.files[0]), raw: document.getElementById('files').files[0]});
  }

  const handleUploadBank = async (event) => {
    //const user = JSON.parse(localStorage.getItem("m_users"));
    setBank({preview: URL.createObjectURL(event.target.files[0]), raw: document.getElementById('filess').files[0]});
  }

  const handleUploadISO = async (event) => {
    //const user = JSON.parse(localStorage.getItem("m_users"));
    setISO({preview: URL.createObjectURL(event.target.files[0]), raw: document.getElementById('isofile').files[0]});
  }

  const handleUploadCom = async (event) => {
    //const user = JSON.parse(localStorage.getItem("m_users"));
    setCom({preview: URL.createObjectURL(event.target.files[0]), raw: document.getElementById('filecom').files[0]});
  }

  const handleUploadEsi = async (event) => {
    //const user = JSON.parse(localStorage.getItem("m_users"));
    setEsi({preview: URL.createObjectURL(event.target.files[0]), raw: document.getElementById('fileesi').files[0]});
  }

  const handleUploadGst = async (event) => {
    //const user = JSON.parse(localStorage.getItem("m_users"));
    setGst({preview: URL.createObjectURL(event.target.files[0]), raw: document.getElementById('filegst').files[0]});
  }

  const handleUploadPf = async (event) => {
    //const user = JSON.parse(localStorage.getItem("m_users"));
    setPf({preview: URL.createObjectURL(event.target.files[0]), raw: document.getElementById('filepf').files[0]});
  }

  const getStepContent = (step) => {
      switch (step) {
        case 0:
          return <VendorDetails handleChange={handleChange} values={values} errors={errors} />;
        case 1:
          return <CompanyDetails handleChange={handleChange} handleUploadAadhar={handleUploadAadhar} handleuploadPAN={handleuploadPAN}
          handleChange3={handleChange3} addClick={addClick} handleRemove={handleRemove} fields={fields} values={values} 
          handleUploadCom={handleUploadCom} handleUploadEsi={handleUploadEsi} comImage={comImage} esiImage={esiImage}
          gstImage={gstImage} pfImage={pfImage} adharImage={adharImage} panImage={panImage} handleRadioChange={handleRadioChange}
          radioVal={radioVal} handleUploadGst={handleUploadGst} handleUploadPf={handleUploadPf} errors={errors}/>;
        case 2:
          return <LandProprietorDetails handleChange={handleChange} landfields={landfields} handleChangeLand={handleChangeLand}
          handleRemoveLand={handleRemoveLand} addClickLand={addClickLand} values={values} />;
        case 3:
          return <EmployeeDetails handleRadioChange={handleRadioChange} handleChange={handleChange} 
          custfields={custfields} handleChangeCust={handleChangeCust} handleRemoveCust={handleRemoveCust} addClickCust={addClickCust} 
          radioVal={radioVal} values={values} handleUploadISO={handleUploadISO} isoImage={isoImage} />;
        case 4: 
          return <FacilityDetails facilityfields={facilityfields} handleChangeFacility={handleChangeFacility} 
          handleRemoveFacility={handleRemoveFacility} addClickFacility={addClickFacility} facilityfields2={facilityfields2} 
          handleChangeFacility2={handleChangeFacility2} handleRemoveFacility2={handleRemoveFacility2} addClickFacility2={addClickFacility2} 
          values={values} />;
        case 5:
          return <BankAccount handleChange={handleChange} values={values} bankImage={bankImage} handleUploadBank={handleUploadBank} /> 
        default:
          throw new Error('Unknown step');
      }
    }

  const handleSubmit = async(event) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    //const company_id = user.company_id;
    const group_id = user.group_id;
    const formData = new FormData()
    formData.append( 'file', adharImage.raw )
    const aadhar = await aadharUpload(formData)
    const aadharpath = aadhar.data.filename;

    const formData2 = new FormData();
    formData2.append( 'file', panImage.raw );
    const pan = await panUpload(formData2);
    const panpath = pan.data.filename;

    const formData3 = new FormData();
    formData3.append( 'file', bankImage.raw );
    const bank = await bankUpload(formData3);
    const bankpath = bank.data.filename;

    const formData4 = new FormData();
    formData4.append( 'file', isoImage.raw );
    const iso = await isoUpload(formData4);
    const isopath = iso.data.filename;

    const formData5 = new FormData();
    formData5.append( 'file', comImage.raw );
    const comp = await comUpload(formData5);
    const compath = comp.data.filename;

    const formData6 = new FormData();
    formData6.append( 'file', esiImage.raw );
    const esi = await esiUpload(formData6);
    const esipath = esi.data.filename;

    const formData7 = new FormData();
    formData7.append( 'file', gstImage.raw );
    const gst = await gstUpload(formData7);
    const gstpath = gst.data.filename;

    const formData8 = new FormData();
    formData8.append( 'file', pfImage.raw );
    const pff = await pfUpload(formData8);
    const pfpath = pff.data.filename;

    let fieldsComp = fields;
    const arr = [];
    fieldsComp.forEach(item => {
      arr.push({ 
        placename: item.placename, placeaddress: item.placeaddress, placecity:item.placecity, placestate:item.placestate, placegst:item.placegst   
      })
    });

    let fieldsLand = landfields;
    const arr2 = [];
    fieldsLand.forEach(item => {
      arr2.push({
        propname:item.propname, propqualify:item.propqualify, propexperience:item.propexperience, propaddress:item.propaddress
      })
    });
    
    let fieldsCust = custfields;
    const arr3 = [];
    fieldsCust.forEach(item => {
      arr3.push({ customerlist: item.customerlist })
    });

    let fieldsFacility = facilityfields;
    const arr4 = [];
    fieldsFacility.forEach(item => {
      arr4.push({ machines1:item.machines1, capacity1:item.capacity1, qty1:item.qty1 })
    });

    let fieldsFacility2 = facilityfields2;
    const arr5 = [];
    fieldsFacility2.forEach(item => {
      arr5.push({ 
        instruments2:item.instruments2,capacity2:item.capacity2, leasecount2:item.leasecount2, 
        qty2:item.qty2, calibration2:item.calibration2 
      })
    });

    const userdata = {
      docno: 'PUR-F-03',
      revno: '0',
      date: datee,
      supname: values.supname,
      factAddress: values.factAddress,
      headAddress: values.headAddress,
      telephone: values.telephone,
      office: values.office,
      res: values.res,
      faxno: values.faxno,
      mobileno: values.mobileno,
      email: values.email,
      companyregno: values.companyregno,
      esino: values.esino,
      gstno: values.gstno,
      pfno: values.pfno,
      particularreg: values.particularreg,
      capinvest: values.capinvest,
      aadharno: values.aadharno,
      panno: values.panno,
      aadhar: aadharpath,
      pan: panpath,
      landarea: values.landarea,
      landown: values.landown,
      landpower: values.landpower,
      turnover: values.turnover,
      skillmale: values.skillmale,
      skillfemale: values.skillfemale,
      semiskillmale:values.semiskillmale,
      semiskillfemale:values.semiskillfemale,
      inspectbrotout: radioVal.inspectbrotout,
      inspectbrotoutyes: values.inspectbrotoutyes,
      testbrotout: radioVal.testbrotout,
      testbrotoutyes: values.testbrotoutyes,
      testbrotoutno: values.testbrotoutno,
      inprocess: radioVal.inprocess,
      finproinspect: radioVal.finproinspect,
      qualcertify: radioVal.qualcertify,
      isocertify: radioVal.isocertify,
      iso: isopath,
      referredby: radioVal.referredby,
      referredbyyes: values.referredbyyes,
      risk: values.risk,
      scopesupply: values.scopesupply,
      bankname: values.bankname,
      accno: values.accno,
      accname:values.accname,
      ifsc:values.ifsc,
      bank: bankpath,
      comp: compath,
      esi: esipath,
      gst: gstpath,
      pff: pfpath,
      client_id: values.client_id,
      client_secret: values.client_secret,
      status: 'Submitted',
      user_id: user.id,
      mgr_id: user.mgr_id,
      company_id: user.company_id,
      group_id: group_id,
    }
    console.log("userdata", userdata);

    const placedata = {
      items: arr,
      user_id: user.id,
      company_id: user.company_id,
      group_id: group_id,
    }
    const propdata = {
      items2: arr2,
      user_id: user.id,
      company_id: user.company_id,
      group_id: group_id,
    }
    const custdata = {
      items3: arr3,
      user_id: user.id,
      company_id: user.company_id,
      group_id: group_id,
    }
    const machdata = {
      items4: arr4,
      user_id: user.id,
      company_id: user.company_id,
      group_id: group_id,
    }
    const instdata = {
      items5: arr5,
      user_id: user.id,
      company_id: user.company_id,
      group_id: group_id,
    } 
    postVendorForm({userdata, placedata, propdata, custdata, machdata, instdata})
    .then(resp => {
        getVendorForm(group_id)
        //.then(resp => setVendorForm(resp.data))
        setValues({ 
          id: '', docno:'', revno:'', date:'', supname:'', factAddress:'', headAddress:'', telephone:'', office:'', res:'',
          faxno:'', mobileno:'', email:'', companyregno:'', esino:'', gstno:'', pfno:'', particularreg:'',
          capinvest:'', aadharno:'', panno:'', arr:'', aadhar:'', pan:'', landarea:'', landown:'', landpower:'', turnover:'', arr2:'', skill:'', semiskill:'', arr3:'',
          inspectbrotout:'', inspectbrotoutyes:'', testbrotout:'', testbrotoutyes:'', testbrotoutno:'', inprocess:'', finproinspect:'', qualcertify:'',
          isocertify:'', iso:'', referredby:'', referredbyyes:'', risk:'', scopesupply:'', arr4:'', arr5:'', 
          bankname:'', accno:'', accname:'', ifsc:'', bank:'', comp:'', esi:'', gst:'', pff:'' });
      })
      .then(setSubForm(true))
      .catch(err => {
        console.log("Error", err);
      })
  }
  
  return (
    <React.Fragment>
      <Breadcrumb title="Vendor Form" parent="Vendor Master"/>
      <CssBaseline />
      <main className={classes.layout}>
        { foundVendorId === getThisVenId ?
          <Paper>
                       <Paper>
                        <Table aria-label="spanning table">
                         <TableHead>
                          <TableRow>
                            <TableCell> <img src={NuLogo} /> </TableCell>
                            <TableCell colSpan={8} align="center">
                              <b>NU-PIE MCS PVT LTD., <br/>
                              SUPPLIER INITIAL EVALUATION CUM <br/>
                              REGISTRATION </b>
                            </TableCell>
                            <TableCell>
                            Status: <b className="text-success">{foundVendor.status}</b><br/>
                            Doc No:  <b>{foundVendor.docno}</b><br/>
                            Date:  
                            <b>
                            {new Date(foundVendorDate).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                            </b><br/>
                            </TableCell>
                          </TableRow>
                         </TableHead>
                        </Table> 
                       </Paper> 
                    
                

                    <Paper>
       <Table  aria-label="spanning table">
       <TableHead>
         <TableRow>
          <TableCell colSpan={20}>
           Name Of The Supplier: <b>{foundVendor.supname}</b>
          </TableCell>
         </TableRow>
         <TableRow>
          <TableCell colSpan={10}>Address - Factory /Godown <br/>
           <b>{foundVendor.factAddress}</b>
          </TableCell>
          <TableCell colSpan={10}>Address - Head Office <br/>
           <b>{foundVendor.headAddress}</b>
          </TableCell>
         </TableRow>
        <TableRow>
         <TableCell colSpan={10}> 
          Telephone No: 
          <b>{foundVendor.telephone}</b>
         </TableCell>
         <TableCell> 
          Office: <b>{foundVendor.office}</b> 
         </TableCell>
         <TableCell> 
          Res: <b>{foundVendor.res}</b>
         </TableCell>
        </TableRow>
        <TableRow>
         <TableCell colSpan={10}> 
          Fax No:<b>{foundVendor.faxno}</b> 
         </TableCell>
         <TableCell colSpan={10}> 
          Mobile: <b>{foundVendor.mobileno}</b>
         </TableCell>
        </TableRow>
        <TableRow>
         <TableCell> 
          Email Id: <b>{foundVendor.email}</b>
         </TableCell>
        </TableRow>
       </TableHead>
      </Table>
     </Paper>

     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
         <TableCell colSpan={10}> 
         <Grid item xs>
          Company Reg No:<b>{foundVendor.companyregno}</b>
          </Grid><br/>
          {/* foundVendor.comp ?
           <img src={`${comfilepath}`} width="300" height="200" crossorigin="anonymous"/>
           : null
          */}
         </TableCell>
         <TableCell colSpan={10}> 
         <Grid item xs>
          ESI Number: <b>{foundVendor.esino}</b>
         </Grid><br/>
         {/* foundVendor.esi ?
          <img src={`${esifilepath}`} width="300" height="200"/>
          : null
         */}
         </TableCell>
        </TableRow>
       </TableHead>
      </Table>
     </Paper>
     &nbsp;&nbsp;

     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
         <TableCell colSpan={10}> 
         <Grid item xs>
          GST/IGST No: <b>{foundVendor.gstno}</b>
         </Grid><br/>
         {/*foundVendor.gst ?
         <img src={`${gstfilepath}`} width="300" height="200"/>
         : null
          */}
         </TableCell>
         <TableCell colSpan={10}> 
         <Grid item xs>
          PF Number: <b>{foundVendor.pfno}</b>
          </Grid><br/>
          {/* foundVendor.pff ?
            <img src={`${pfffilepath}`} width="300" height="200"/>
            : null
          */}
          </TableCell>
        </TableRow>
        </TableHead>
      </Table>
     </Paper>
     &nbsp;&nbsp;

     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
         <TableCell> 
          Particulars of Registration / Statutory and Regulatory requirements if any <br/>
          <b>{foundVendor.particularreg}</b>
         </TableCell>
        </TableRow>
        <TableRow>
         <TableCell> 
          Capital Investment: <b>{foundVendor.capinvest}</b>
         </TableCell>
        </TableRow>

       <Grid item xs={12} >
        <TableCell>
         Additional Places of Supply:<br/>
            <div >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}><Typography variant="body2">Name</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">Address</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">City</Typography></Grid>
              <Grid item xs={12} sm={2}><Typography variant="body2">State</Typography></Grid>
              <Grid item xs={12} sm={2}><Typography variant="body2">GST</Typography></Grid>
            </Grid>
            {filterPlaceData.map((el, i)=> {
             return(
              <Grid container spacing={3}>
               <Grid item xs={12} sm={2}>
                <TextField value={el.placename} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={el.placeaddress} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={el.placecity} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={2}>
                <TextField value={el.placestate} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={2}>
                <TextField value={el.placegst} fullWidth/>
               </Grid>
              </Grid>
              );
             })
            }
          </div>
        </TableCell>
      </Grid>
      </TableHead>
     </Table>
     </Paper>
     &nbsp;&nbsp;

    <Paper>
     <Table  aria-label="spanning table">
      <TableHead>
       <TableRow>
        <TableCell>
        <Grid item xs>
         Aadhar No: <b>{foundVendor.aadharno}</b>
        </Grid><br/>
        {/* foundVendor.aadhar ?
          <img src={`${aadharfilepath}`} width="300" height="200"/>
          : null
        */}
        </TableCell>
        <TableCell>
        <Grid item xs>
         PAN: <b>{foundVendor.panno}</b>
        </Grid><br/>
        {/* foundVendor.pan ?
         <img src={`${panfilepath}`} width="300" height="200"/>
         : null
        */}
        </TableCell>
       </TableRow>
      </TableHead>    
     </Table>
    </Paper>
    &nbsp;&nbsp;
     
     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
          <TableCell> Details Of Land And Building </TableCell>
        </TableRow>
        <TableRow>
          <TableCell> 
            A) Area: <b>{foundVendor.landarea}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell> 
            B) Own (or) Rented: <b>{foundVendor.landown}</b>
          </TableCell>
        </TableRow>
        <TableRow>  
          <TableCell> 
           C) Power: <b>{foundVendor.landpower}</b>
          </TableCell>
        </TableRow>
       </TableHead>    
      </Table>
     </Paper>

     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
          <TableCell> 
           Turn Over: <b>{foundVendor.turnover}</b>
          </TableCell>
        </TableRow>
       </TableHead>    
      </Table>
     </Paper>

     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <Grid item xs={12} >
          <TableCell>
           Proprietor / Directors Details: <br/>
            <div >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={3}><Typography variant="body2">Name</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">Qualification</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">Experience</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">Address</Typography></Grid>
            </Grid>
            {filterPropData.map((el, i)=> {
             return(
              <Grid container spacing={6}>
               <Grid item xs={12} sm={3}>
                <TextField value={el.propname}/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={el.propqualify}/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={el.propexperience}/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={el.propaddress}/>
               </Grid>
              </Grid>
             );
            })
          }
            </div>
          </TableCell>
        </Grid>
       </TableHead>    
      </Table>
     </Paper>

        <Paper>
         <Table  aria-label="spanning table">
          <TableHead>
           <TableRow>
            <TableCell> Employee Particulars </TableCell>
           </TableRow>
           <TableRow>
            <TableCell> 
             A) Skilled M/F: (Num)<br/>
             <div >
             <Grid container spacing={3}>
              <Grid item xs={12} sm={3}><Typography variant="body2">Male</Typography></Grid>
              <Grid item xs={12} sm={3}><Typography variant="body2">Female</Typography></Grid>
            </Grid>
             <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
               <TextField value={foundVendor.skillmale}/>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField value={foundVendor.skillfemale}/>
              </Grid>
             </Grid>
             </div>
            </TableCell>
           </TableRow>
           <TableRow>
            <TableCell> 
             B) Semi Skilled M/F: (Num) <br/>
              <div >
               <Grid container spacing={3}>
                <Grid item xs={12} sm={3}><Typography variant="body2">Male</Typography></Grid>
                <Grid item xs={12} sm={3}><Typography variant="body2">Female</Typography></Grid>
               </Grid>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                 <TextField value={foundVendor.semiskillmale}/>
               </Grid>
               <Grid item xs={12} sm={3}>
                <TextField value={foundVendor.semiskillfemale}/>
               </Grid>
               </Grid>
               </div>
            </TableCell>
           </TableRow>
          </TableHead>    
         </Table>
        </Paper>

       <Paper>
        <Table  aria-label="spanning table">
         <TableHead>
          <TableRow>
            <TableCell> 
             List Of Customers: ( Give Details) <br/>
              <b>{filterCustData.map((n)=> {
                  return(
                  <TableRow>{n.customerlist}</TableRow>
                  ); 
                })}</b>
            </TableCell>
          </TableRow>
          </TableHead>    
        </Table>
       </Paper>

       <Paper>
        <Table  aria-label="spanning table">
         <TableHead>
         <TableRow>
          <TableCell> Do you Inspect Brought Out materials? 
           <Grid item xs>
            <FormGroup>
              <RadioGroup row aria-label="inspectbrotout" name="inspectbrotout" value={foundVendor.inspectbrotout} fullWidth>
              {foundVendor.inspectbrotout === 'yes'?
                <FormControlLabel value="yes" control={<Radio color="primary" checked="foundVendor.inspectbrotout"/>} label="Yes" />
                :
                <FormControlLabel value="no" control={<Radio color="primary" checked="foundVendor.inspectbrotout"/>} label="No" />
              }
              </RadioGroup>
           </FormGroup>
           {foundVendor.inspectbrotout === 'yes'?
            <Grid item xs={12}>
             <TextField multiline value={foundVendor.inspectbrotoutyes} fullWidth />
            </Grid>
            : null
           }
          </Grid>
          </TableCell>
          <TableCell> 
            Is In-Process Inspection Done During Manufacturing?
            <Grid item xs>
             <FormGroup>
              <RadioGroup row aria-label="inprocess" name="inprocess" value={foundVendor.inprocess} fullWidth>
              {foundVendor.inprocess === 'yes'?
                <FormControlLabel value="yes" control={<Radio color="primary" checked="foundVendor.inprocess"/>} label="Yes" />
                :
                <FormControlLabel value="no" control={<Radio color="primary" checked="foundVendor.inprocess"/>} label="No" />
              }
              </RadioGroup>
           </FormGroup>
            </Grid>  
          </TableCell>
        </TableRow>
       </TableHead>    
      </Table>
     </Paper>
     
     <Paper>
      <Table  aria-label="spanning table">
       <TableHead>
        <TableRow>
          <TableCell> 
            Do you have testing facilities for brought out material? If not, what is done for testing?  ( Give Details)  
            <Grid item xs>
             <FormGroup>
                <RadioGroup row aria-label="testbrotout" name="testbrotout" value={foundVendor.testbrotout} fullWidth>
                {foundVendor.testbrotout === 'yes'?
                  <FormControlLabel value="yes" control={<Radio color="primary" checked="foundVendor.testbrotout"/>} label="Yes" />
                  :
                  <FormControlLabel value="no" control={<Radio color="primary" checked="foundVendor.testbrotout"/>} label="No" />
                }
                </RadioGroup>
             </FormGroup>
           {foundVendor.testbrotout === 'yes'?
            <Grid item xs={12}>
              <TextField multiline value={foundVendor.testbrotoutyes} fullWidth />
            </Grid>
            :
            <Grid item xs={12}>
             <TextField multiline value={foundVendor.testbrotoutno} fullWidth />
            </Grid>
          }
          </Grid> 
          </TableCell>
        </TableRow>
       </TableHead>    
      </Table>
     </Paper>

     <Paper>
    <Table  aria-label="spanning table">
     <TableHead>
      <TableRow>
        <TableCell>
          Do You have finished product inspection set up?   
          <Grid item xs>
           <FormGroup>
              <RadioGroup row aria-label="finproinspect" name="finproinspect" value={foundVendor.finproinspect} fullWidth>
              {foundVendor.finproinspect === 'yes'?
                <FormControlLabel value="yes" control={<Radio color="primary" checked="foundVendor.finproinspect"/>} label="Yes" />
                :
                <FormControlLabel value="no" control={<Radio color="primary" checked="foundVendor.finproinspect"/>} label="No" />
              }
              </RadioGroup>
           </FormGroup>
          </Grid>
        </TableCell>
        <TableCell>
          Can you comply with the quality certificate when ever called for ? ( Self Certification )
          <Grid item xs>
            <FormGroup>
              <RadioGroup row aria-label="qualcertify" name="qualcertify" value={foundVendor.qualcertify} fullWidth>
                {foundVendor.qualcertify === 'yes'?
                  <FormControlLabel value="yes" control={<Radio color="primary" checked="foundVendor.qualcertify"/>} label="Yes" />
                  :
                  <FormControlLabel value="no" control={<Radio color="primary" checked="foundVendor.qualcertify"/>} label="No" />
                }
              </RadioGroup>
           </FormGroup>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          Is Supplier An ISO 9001 / 14001 Certified? (If Yes, attach the copy of relevant certificate)
          <Grid item xs>
            <FormGroup>
              <RadioGroup row aria-label="isocertify" name="isocertify" value={foundVendor.isocertify} fullWidth>
                {foundVendor.isocertify === 'yes'?
                  <FormControlLabel value="yes" control={<Radio color="primary" checked="foundVendor.isocertify"/>} label="Yes" />
                  :
                  <FormControlLabel value="no" control={<Radio color="primary" checked="foundVendor.isocertify"/>} label="No" />
                }
              </RadioGroup>
           </FormGroup>
           {/* foundVendor.iso === '1'?
              <img src={`${isofilepath}`} width="300" height="200"/>
            : null
           */}
          </Grid>
        </TableCell>
        <TableCell>
          Is Supplier Referred By The Customer ? ( If So Give Details )
          <Grid item xs>
            <FormGroup>
              <RadioGroup row aria-label="referredby" name="referredby" value={foundVendor.referredby} fullWidth>
                {foundVendor.referredby === 'yes'?
                  <FormControlLabel value="yes" control={<Radio color="primary" checked="foundVendor.referredby"/>} label="Yes" />
                  :
                  <FormControlLabel value="no" control={<Radio color="primary" checked="foundVendor.referredby"/>} label="No" />
                }
              </RadioGroup>
           </FormGroup>
           {foundVendor.referredby === 'yes' ?
            <Grid item xs={12}>
             <TextField multiline value={foundVendor.referredbyyes} fullWidth />
            </Grid>
            :
            null
          }
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell> 
         Risk & Opportunities Identified: <br/>
         <b>{foundVendor.risk}</b>
        </TableCell>
        <TableCell>
        </TableCell>
      </TableRow>
      </TableHead>    
    </Table>
   </Paper>

   <Paper>
    <Table  aria-label="spanning table">
     <TableHead>
      <TableRow>
        <TableCell> 
          Describe The Scope Of Supply ( Mention the Product / Process for which  you want to register )? <br/>
          <b>{foundVendor.scopesupply}</b>
        </TableCell>
      </TableRow>
     </TableHead>    
    </Table>
   </Paper>

   <Paper>
    <Table  aria-label="spanning table">
     <TableHead>
      <Grid item xs={12} >
        <Typography variant="h6" gutterBottom align="center">
          Facilities Available
        </Typography>
        <TableRow>
        <TableCell>
         A. Machineries/ Equipments<br/>
          <div >
          <Grid container spacing={6}>
            <Grid item xs={12} sm={4}><Typography variant="body2">Machines</Typography></Grid>
            <Grid item xs={12} sm={4}><Typography variant="body2">Capacity</Typography></Grid>
            <Grid item xs={12} sm={4}><Typography variant="body2">Qty</Typography></Grid>
          </Grid>
          {filterMachData.map((el, i)=> {
           return(
            <Grid container spacing={6}>
             <Grid item xs={12} sm={4}>
              <TextField value={el.machines1}/>
             </Grid>
             <Grid item xs={12} sm={4}>
              <TextField value={el.capacity1}/>
             </Grid>
             <Grid item xs={12} sm={4}>
              <TextField value={el.qty1}/>
             </Grid>
            </Grid>
            );
           })
          }
        </div>
      </TableCell>
      </TableRow>
      <TableRow>
      <TableCell>
         B. Instruments<br/>
          <div >
          <Grid container spacing={6}>
            <Grid item xs={12} sm={3}><Typography variant="body2">Instruments</Typography></Grid>
            <Grid item xs={12} sm={2}><Typography variant="body2">Capacity</Typography></Grid>
            <Grid item xs={12} sm={3}><Typography variant="body2">Lease Count</Typography></Grid>
            <Grid item xs={12} sm={2}><Typography variant="body2">Qty</Typography></Grid>
            <Grid item xs={12} sm={2}><Typography variant="body2">Calibration</Typography></Grid>
          </Grid>
          {filterInstData.map((el,i) => {
            return(
            <Grid container spacing={6}>
             <Grid item xs={12} sm={3}>
              <TextField value={el.instruments2}/>
             </Grid>
             <Grid item xs={12} sm={2}>
              <TextField value={el.capacity2}/>
             </Grid>
             <Grid item xs={12} sm={3}>
              <TextField value={el.leasecount2}/>
             </Grid>
             <Grid item xs={12} sm={2}>
              <TextField value={el.qty2}/>
             </Grid>
             <Grid item xs={12} sm={2}>
              <TextField value={el.calibration2}/>
             </Grid>
            </Grid>
            );
          })
          }  
        </div>
      </TableCell>
      </TableRow>
    </Grid>
   </TableHead>    
    </Table>
   </Paper>
   </Paper>
          :
          <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center" >
            <b><i>SUPPLIER/VENDOR INITIAL EVALUATION CUM REGISTRATION</i></b>
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography align="center" variant="h5" gutterBottom>
                  <Audiotrack color="primary"/> Form is Successfully submitted <Audiotrack color="primary"/>
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} round variant="outlined" className={classes.button}>
                      Prev
                    </Button>
                  )}
                  {activeStep === steps.length - 1 ?
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.button}
                    round
                  >
                    Submit the form
                  </Button>
                  :
                  <Button
                    variant="contained"
                    color="primary"
                    round
                    onClick={handleNext}
                    className={classes.button}
                  >
                    Next
                  </Button>
                }
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        }
        
       
      </main>
      <React.Fragment>
      
      {/** Form Submit Model **/}
      <Dialog 
        open={subform} 
        onClose={handleSubFormClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick="true"
        disableEscapeKeyDown="true"
      >
      <DialogContent>
        <Typography align="center">
          <CheckCircleOutlineRoundedIcon style={{fontSize: '100px', color:'#4bb543'}}/>
        </Typography>
        <Grid xs={12} sm={12}>
        <Typography component="h2" variant="h6" align="center" gutterBottom>
          Thank you for submitting the form. You can check the status on your Dashboard...
        </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubFormClose} color="primary" size="sm" round>
          Close
        </Button>
      </DialogActions>
      </Dialog>
    </React.Fragment>  
    </React.Fragment>
  );
}