import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, ThumbsUp, ThumbsDown, XOctagon } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import VendorReview from './VendorReview';
import VendorApprove from './VendorApprove';
import VendorReject from './VendorReject';
import VendorBlock from './VendorBlocked';

const VendorTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="Vendor List" parent="Vendor" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Home />Review
                                </Tab>
                                <Tab>
                                    <ThumbsUp />Approved
                                </Tab>
                                <Tab>
                                    <ThumbsDown />Rejected
                                </Tab>
                                <Tab>
                                    <XOctagon />Blocked
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <VendorReview />
                                </TabPanel>
                                <TabPanel>
                                    <VendorApprove />
                                </TabPanel>
                                <TabPanel >
                                    <VendorReject />
                                </TabPanel>
                                <TabPanel >
                                    <VendorBlock />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

export default VendorTab;