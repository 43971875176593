import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Card, CardContent, Divider, TextField, Button, MenuItem, FormHelperText, Grid } from '@material-ui/core';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1),
  },
  barchartcard: {
    margin: theme.spacing(1),
  },
  errors: {
    color: 'red'
  }
}));

export default function Consumption(props) {
  const classes = useStyles();
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;

  const { values, StartDate, nextDate, EndDate, clients, ClientGST, ClientAddress, errors,
    projectUser, salesUser, projectEmail, salesEmail } = props;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-3">
          <TextField fullWidth required id="name" label="Project Name" margin="dense" name="name" onChange={props.handleChange}
            value={values.name} variant="outlined" />
          <FormHelperText className={classes.errors}>{errors.name}</FormHelperText>
        </div>
        <div className="col-xl-3">
          <TextField fullWidth label="POValue" margin="dense" name="po_value" type="number"
            onChange={props.handleChange} value={values.po_value} variant="outlined" id="po_value" />
        </div>
        <div className="col-xl-3">
          <TextField id="client_id" select label="Client Name" value={values.client_id}
            onChange={props.handleChange} SelectProps={{ name: 'client_id' }} margin="dense" fullWidth variant="outlined" >
            {
              clients.map((n, index) => {
                return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
              })
            }
          </TextField>
        </div>
        <div className="col-xl-3">
          <TextField fullWidth label="GSTIN" margin="dense" name="gstin"
            onChange={props.handleChange} value={ClientGST} variant="outlined" id="gstin"
            defaultValue="GSTIN"
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <div className="col-xl-3">
          <TextField id="project_incharge" select label="Project Incharge" value={values.project_incharge}
            onChange={props.handleChange} SelectProps={{ name: 'project_incharge' }} margin="dense" fullWidth variant="outlined" >
            {
              projectUser.map((n, index) => {
                return <MenuItem key={index} value={n.id}>{n.firstname}</MenuItem>
              })
            }
          </TextField>
        </div>
        <div className="col-xl-3">
          <TextField fullWidth label="Email" margin="dense"
            onChange={props.handleChange} value={projectEmail} variant="outlined"
            defaultValue="abc@xyz.com"
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <div className="col-xl-3">
          <TextField id="sales_incharge" select label="Sales Incharge" value={values.sales_incharge}
            onChange={props.handleChange} SelectProps={{ name: 'sales_incharge' }} margin="dense" fullWidth variant="outlined" >
            {
              salesUser.map((n, index) => {
                return <MenuItem key={index} value={n.id}>{n.firstname}</MenuItem>
              })
            }
          </TextField>
        </div>
        <div className="col-xl-3">
          <TextField fullWidth label="Email" margin="dense"
            onChange={props.handleChange} value={salesEmail} variant="outlined"
            defaultValue="abc@xyz.com"
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <div className="col-xl-12">
          <TextField fullWidth label="Address" margin="normal" name="billing_address" multiline
            value={ClientAddress} variant="outlined" id="billing_address" defaultValue="Address"
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <div className="col-xl-6">
          <label className="col-form-label" htmlFor="message-text">Schedule Start Date:</label>
          <DatePicker className="form-control digits"
            selected={StartDate}
            value={StartDate}
            onChange={props.handleStartDateChange}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="col-xl-6">
          <label className="col-form-label" htmlFor="message-text">Schedule End Date:</label>
          <DatePicker className="form-control digits"
            selected={EndDate}
            value={EndDate}
            onChange={props.handleEndDateChange}
            minDate={new Date(nextDate)}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
    </div>
  );
  {/*
      <Grid container >
            <Grid item md={4} xs={12} >
              <TextField fullWidth required id="name" label="Project Num" margin="normal" name="name" onChange={props.handleChange} 
                value={values.name} variant="outlined" />
                <FormHelperText className={classes.errors}>{errors.name}</FormHelperText>
            </Grid>
            <Grid item md={4} xs={12} >
              <TextField fullWidth label="POValue" margin="normal" name="po_value" 
                onChange={props.handleChange} value={values.po_value} variant="outlined" id="po_value"
              />
            </Grid>
            <Grid item md={4} xs={12} >
              <TextField id="client_id" select label="Client Name" value={values.client_id}
                  onChange={props.handleChange} SelectProps={{ name: 'client_id' }} margin="normal" fullWidth variant="outlined" >
                      {
                        clients.map((n, index) => {
                          return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                        })
                      }
              </TextField>
            </Grid>
            <Grid item md={4} xs={12} >
              <TextField fullWidth label="GSTIN" margin="normal" name="gstin" 
                onChange={props.handleChange} value={ClientGST} variant="outlined" id="gstin"
                defaultValue="GSTIN"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>            
            <Grid item md={4} xs={12} >
                <label className="col-form-label" htmlFor="message-text">Schedule Start Date:</label>
                <DatePicker className="form-control digits"
                  selected={StartDate}
                  value={StartDate}
                  onChange={props.handleStartDateChange}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
            </Grid>
            <Grid item md={4} xs={12} >
                <label className="col-form-label" htmlFor="message-text">Schedule End Date:</label>
                <DatePicker className="form-control digits"
                  selected={EndDate}
                  value={EndDate}
                  onChange={props.handleStartDateChange}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
            </Grid>
            <Grid item md={12} xs={12} >
              <TextField fullWidth label="Address" margin="normal" name="billing_address" multiline 
               value={ClientAddress} variant="outlined" id="billing_address" defaultValue="Address"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>                        
          </Grid>
      */}

}