import React, { Fragment } from 'react';
import { TextField, Typography, Grid, FormHelperText,Radio, RadioGroup, 
         FormControl, FormControlLabel } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SalaryDetails = (props) => {
    const { assessmentYear, filterCompensation, grossSalary } = props;
    const curYear = new Date().getFullYear();

    return(
     <Fragment>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <tbody className="text-center">
                                            <th>
                                            <h5>
                                            <label className="col-form-label">Assessment Year: </label>
                                            <select 
                                                defaultValue={assessmentYear} 
                                                onChange={(e) => props.setAssessmentYear(e.target.value)}
                                                className="ml-1 text-success"
                                                style={{width: '25%', height: '100%'}}
                                            >
                                            <option value={`${curYear}-${curYear + 1}`}>{`${curYear}-${curYear + 1}`}</option>
                                            <option value={`${curYear - 1}-${curYear}`}>{`${curYear-1}-${curYear}`}</option>
                                            <option value={`${curYear-2}-${curYear-1}`}>{`${curYear-2}-${curYear-1}`}</option>
                                            <option value={`${curYear-3}-${curYear-2}`}>{`${curYear-3}-${curYear-2}`}</option>
                                            </select>
                                            </h5>
                                            </th>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-primary">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">Component</th>
                                                <th scope="col">Per Month</th>
                                                <th scope="col">Per Annum</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterCompensation.map((n, i)=> {
                                                return (
                                                <tr>
                                                    <th scope="row">{i+1}</th>
                                                    <td>{n.category}</td>
                                                    <td>{n.component}</td>
                                                    <td>{n.monthly}</td>
                                                    <td>{n.yearly}</td>
                                                </tr>
                                                );
                                             })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 text-center">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12"></div>      
                                <div className="col-xl-12 m-t-10">
                                    <h4 className="text-success">Total Gross Salary for the FY: {grossSalary}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     </Fragment>
    );
}

export default SalaryDetails;