import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormHelperText, MenuItem, TablePagination } from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import { getBoms, getBomItems, getProjects, postBoms, editBoms, postBomItems, editBomItems, 
         deleteBomItems, deleteBoms } from '../../../actions/asyncActions';

const Installation = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    const elementStyle ={
      border:'solid',
      borderRadius:'10px',
      position:'relative',
      left:'1vh',
      height:'6vh',
      width:'23vh',
      marginTop:'1vh',
      marginBottom:'1vh'
    }

    const initialFormState = { id: '', document_no:'', revision_no:'', project_num:'', customer:'', prepared_by:'',
          checked_by:'', approved_by:'', project_id:'', client_id:''}
    const [data, setCurrentData ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();
    const [viewmodal, setViewModal] = useState();
    const [additemmodal, setAddItemModal] = useState();
    const [edititemmodal, setEditItemModal] = useState();
    const [values, setValues] = useState({});
    const [errors, setError] = useState([]);

    const [boms, setBOM] = useState([]);
    const [bom_items, setBOMItems] = useState([]);
    const [projects, setProject] = useState([]);
    const [project_val, setProjectVal] = React.useState(null);
    const selectedProject = project_val && project_val.id;
    const ProjectClientId = project_val && project_val.client_id;

    const ins_data = boms.filter(s => s.status === 'Installation');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getBoms(group_id)
      .then(resp => setBOM(resp.data))
    }, []);

    useEffect( () => {
      getBomItems(group_id)
      .then(resp => setBOMItems(resp.data))
    }, []);

    useEffect( () => {
      getProjects(group_id)
      .then(resp => setProject(resp.data))
    }, []);

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleInputChange = event => {
      const { name, value } = event.target
      setCurrentData({ ...data, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
     setProjectVal(null)
     setValues({})
    }

    const editRow = n => {
     setModal(!modal)
     setCurrentData({ id: n.id, document_no: n.document_no, revision_no: n.revision_no, project_num: n.project_num,
      customer: n.customer, prepared_by: n.prepared_by, checked_by: n.checked_by, approved_by:n.approved_by, 
      project_id:n.project_id, client_id:n.client_id })
    }

    const deleteRow = n => {
      const data = {
        id: n.id
      }
      deleteBoms(data)
      .then(resp => {
        getBoms(group_id)
        .then(resp => setBOM(resp.data))
      })
    }

    const [status_id, setStatus] = useState([]);
    const bomItems = bom_items.filter(s => s.bom_id === status_id);

    const viewBOM = n => {
      setStatus(n.id);
      setViewModal(!viewmodal)
    }
    
    const validateForm = (data) => {
     const errors = [];
     if(!data.document_no) {
       errors.document_no = "Document Number is required";
     }
     return errors;
    }

    const handleSubmit = () => {
    const data = {
      document_no: values.document_no,
      revision_no: values.revision_no,
      project_num: values.project_num,
      customer: values.customer,
      prepared_by: values.prepared_by,
      checked_by: values.checked_by,
      approved_by: values.approved_by,
      project_id: selectedProject,
      client_id: ProjectClientId,
      user_id: user.id,
      group_id: group_id,
      status: "Installation"
    }
    const errors = validateForm(data);
    if(Object.keys(errors).length) {
      setError(errors);
      return;
    }
    postBoms(data)
    .then(resp => {
        getBoms(group_id)
        .then(resp => setBOM(resp.data))
          setValues({ id: '', document_no:'', revision_no:'', project_num:'', customer:'', prepared_by:'', checked_by:'', 
          approved_by:'', project_id:'', client_id:''});        
      })
      .catch(err => {
        console.log("Error", err);
      })
      setAddModal(!addmodal)
      setProjectVal(null)
      setValues({})
  }

    const handleUpdate = (id, data) => {
      const project = projects.find(s => s.id === data.project_id);
      const clientId = project && project.client_id;
      const cat = {
        id: id,
        document_no: data.document_no,
        revision_no: data.revision_no,
        project_num: data.project_num,
        customer: data.customer,
        prepared_by: data.prepared_by,
        checked_by: data.checked_by,
        approved_by: data.approved_by,
        project_id: data.project_id,
        client_id: clientId
      }
      const errors = validateForm(data);
      if(Object.keys(errors).length) {
        setError(errors);
        return;
      }
      editBoms(cat)
      .then(resp => {
          getBoms(group_id)
          .then(resp => setBOM(resp.data))
        })
        .catch(err => {
          console.log("Error", err);
        })
        setModal(!modal)
    }

    const [item_values, setItemValues] = useState({});
    const initialFormState2 = { id: '', document_no:'', sl_no:'', item_class:'', part_num:'', part_name:'', specification:'', location:'',
          scope_of_supply:'', item_type:'', qty:'', status:'', bom_id:'', project_id:'', client_id:''}
    const [ item_data, setCurrentItemData ] = useState(initialFormState2)
    const [bom_doc_val, setBomDocVal] = React.useState(null);
    const selectedDocId = bom_doc_val && bom_doc_val.id;
    const selectedDocNum = bom_doc_val && bom_doc_val.document_no;
    const bom_projectId = bom_doc_val && bom_doc_val.project_id;
    const bom_clientId = bom_doc_val && bom_doc_val.client_id;
    const bomStatus = bom_doc_val && bom_doc_val.status;

    const handleItemChange = event => {
      setItemValues({
        ...item_values,
        [event.target.name]: event.target.value
      });
    };

    const addBOMItems = () => {
      setAddItemModal(!additemmodal)
    }

    const handleItemSubmit = () => {
      const data = {
      document_no: selectedDocNum,
      item_class: item_values.item_class,
      part_num: item_values.part_num,
      part_name: item_values.part_name,
      specification: item_values.specification,
      location: item_values.location,
      scope_of_supply: item_values.scope_of_supply,
      item_type: item_values.item_type,
      qty: item_values.qty,
      status: bomStatus,
      bom_id: selectedDocId,
      project_id: bom_projectId,
      client_id: bom_clientId,
      user_id: user.id,
      group_id: group_id
    }
      postBomItems(data)
      .then(resp => {
          getBomItems(group_id)
          .then(resp => setBOMItems(resp.data))
            setItemValues({ id: '', document_no:'', sl_no:'', item_class:'', part_num:'', part_name:'', function:'', material:'',
              finish_size:'', item_type:'', qty:'', remarks:'', status:'', bom_id:'', project_id:'', client_id:''});        
        })
        .catch(err => {
          console.log("Error", err);
        })
        setViewModal(!viewmodal)
        setAddItemModal(!additemmodal)
    }

    const editItemRow = n => {
      setEditItemModal(!edititemmodal)
      setCurrentItemData({ id: n.id, document_no: n.document_no, sl_no:n.sl_no, item_class: n.item_class, part_num: n.part_num,
      part_name: n.part_name, specification: n.specification, location: n.location, scope_of_supply:n.scope_of_supply, 
      item_type:n.item_type, qty:n.qty, status:n.status, bom_id:n.bom_id, project_id:n.project_id, client_id:n.client_id })
    }

    const handleInputitemChange = event => {
    const { name, value } = event.target
    setCurrentItemData({ ...item_data, [name]: value })
  }

  const handleUpdateItem = (id, data) => {
    const EditBOM = boms.find(s => s.document_no === item_data.document_no);
    const EditBOMId = EditBOM && EditBOM.id;
    const EditBOMProject = EditBOM && EditBOM.project_id;
    const EditBOMClient = EditBOM && EditBOM.client_id;
    const EditBOMStatus = EditBOM && EditBOM.status;
    const cat = {
      id: id,
      document_no: item_data.document_no,
      item_class: item_data.item_class,
      part_num: item_data.part_num,
      part_name: item_data.part_name,
      specification: item_data.specification,
      location: item_data.location,
      scope_of_supply: item_data.scope_of_supply,
      item_type: item_data.item_type,
      qty: item_data.qty,
      status: EditBOMStatus,
      bom_id: EditBOMId,
      project_id: EditBOMProject,
      client_id: EditBOMClient
    }
      
    editBomItems(cat)
    .then(resp => {
        getBomItems(group_id)
        .then(resp => setBOMItems(resp.data))
      })
      .catch(err => {
        console.log("Error", err);
      })
      setViewModal(!viewmodal)
      setEditItemModal(!edititemmodal)
  }

    const handleDeleteItem = (n) => {
      const data ={
        id: n.id
      }
      deleteBomItems(data)
      .then(resp => {
        getBomItems(group_id)
        .then(resp => {
          setBOMItems(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

    const [search, setSearch] = useState(null);
    const updateSearch = (event) => {
      setSearch(event.target.value.substr(0,20));
    }

    const filterItems = (p) => {
      if(search) {
       return p.document_no.toLowerCase().includes(search.toLowerCase()) ||
       (p.assembly || '').toLowerCase().includes(search.toLowerCase()) ||
       (p.customer || '').toLowerCase().includes(search.toLowerCase())
     }
     return true;
    }

    const [searchitems, setSearchItems] = useState(null);
    const updateSearchItems = (event) => {
      setSearchItems(event.target.value.substr(0,20));
    }

    const filterSearchItems = (p) => {
      if(searchitems) {
       return p.document_no.toLowerCase().includes(searchitems.toLowerCase()) ||
       (p.part_num || '').toLowerCase().includes(searchitems.toLowerCase()) ||
       (p.part_name || '').toLowerCase().includes(searchitems.toLowerCase()) ||
       (p.function || '').toLowerCase().includes(searchitems.toLowerCase()) ||
       (p.item_type || '').toLowerCase().includes(searchitems.toLowerCase())
     }
    }
     
        return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="col-md-2">
                              <input type="text" placeholder="Enter item to be searched" style={elementStyle} value = {search}
                               onChange={updateSearch} />
                            </div>
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive-xl">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                    <th scope="col">#</th>
                                                    <th scope="col">Doc No.</th>
                                                    <th scope="col">Project No.</th>
                                                    <th scope="col">Revision No.</th>
                                                    <th scope="col">Assembly</th>
                                                    <th scope="col">Customer</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ins_data.filter(filterItems)
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                  const projectName = projects.find(s => s.id === n.project_id );
                                                  return (
                                                    <tr className="table-sm">
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.document_no}</td>
                                                     <td>{n.project_num}</td>
                                                     <td>{n.revision_no}</td>
                                                     <td>{n.assembly}</td>
                                                     <td>{n.customer}</td>
                                                     <td>
                                                      <i className="fa fa-eye" onClick={() => viewBOM(n)} id="TooltipTop" data-placement="bottom"
                                                        style={{ width: 35, fontSize: 16, padding: 11, color: '#22AF47' }}>
                                                        <UncontrolledTooltip placement="bottom" target="TooltipTop">
                                                          View BOM
                                                        </UncontrolledTooltip>
                                                      </i>
                                                      <i className="fa fa-edit" onClick={() => editRow(n)} id="TooltipTop1" data-placement="bottom"
                                                        style={{ width: 35, fontSize: 16, padding: 11, color: '#115f3f' }}>
                                                        <UncontrolledTooltip placement="bottom" target="TooltipTop1">
                                                          Edit BOM
                                                        </UncontrolledTooltip>
                                                      </i>
                                                      <i className="fa fa-trash-o" onClick={() => deleteRow(n)} id="TooltipTop2" data-placement="bottom"
                                                        style={{ width: 35, fontSize: 16, padding: 11, color: '#ff0033' }}>
                                                        <UncontrolledTooltip placement="bottom" target="TooltipTop2">
                                                          Delete BOM
                                                        </UncontrolledTooltip>
                                                      </i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                       <button className="btn btn-info btn-sm" type="button" onClick={() => addRow()}>
                                         Add BOM
                                        </button>
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={ins_data.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Add BOM --> */}
            <Modal isOpen={addmodal} toggle={addRow}>
                <ModalHeader toggle={addRow}>Add BOM</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth required id="document_no" label="Document Num" margin="dense" name="document_no" 
                                onChange={handleChange} value={values.document_no} variant="outlined" />
                              <FormHelperText>{errors.document_no}</FormHelperText>
                            </div>
                            <div className="col-md-6 mb-3">
                              <Autocomplete
                                freeSolo
                                options={projects}
                                getOptionLabel={option => option.name}
                                value={project_val}                      
                                onChange={(event, newValue) => {
                                  setProjectVal(newValue);
                                }}            
                                renderInput={params => (
                                  <TextField {...params} label="Project" margin="dense" variant="outlined" fullWidth/>
                                )}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Revision No" margin="dense" name="revision_no" onChange={handleChange}                      
                               value={values.revision_no} variant="outlined" id="revision_no" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Project Num" margin="dense" name="project_num" onChange={handleChange}                      
                               value={values.project_num} variant="outlined" id="project_num" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="PreparedBy" margin="dense" name="prepared_by" onChange={handleChange}                      
                               value={values.prepared_by} variant="outlined" id="prepared_by" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="CheckedBy" margin="dense" name="checked_by" onChange={handleChange}                      
                               value={values.checked_by} variant="outlined" id="project_num" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="ApprovedBy" margin="dense" name="approved_by" onChange={handleChange}                      
                               value={values.approved_by} variant="outlined" id="approved_by" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Customer" margin="dense" name="customer" onChange={handleChange}                      
                               value={values.customer} variant="outlined" id="customer" />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={addRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit BOM --> */}
            <Modal isOpen={modal} toggle={editRow}>
                <ModalHeader toggle={editRow}>Edit BOM</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth required id="document_no" label="Document No" margin="dense" name="document_no" 
                                onChange={handleInputChange} value={data.document_no} variant="outlined" />
                              <FormHelperText>{errors.document_no}</FormHelperText>
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField id="project_id" select label="Project" value={data.project_id} margin="dense"
                                onChange={handleInputChange} SelectProps={{ name: 'project_id' }} variant="outlined" fullWidth >
                                  {
                                    projects.map((n, index) => {
                                      return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>
                                    })
                                  }
                              </TextField>
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Revision Num" margin="dense" name="revision_no" onChange={handleInputChange}                      
                               value={data.revision_no} variant="outlined" id="revision_no" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Project Num" margin="dense" name="project_num" onChange={handleInputChange}                      
                               value={data.project_num} variant="outlined" id="project_num" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="PreparedBy" margin="dense" name="prepared_by" onChange={handleInputChange}                      
                               value={data.prepared_by} variant="outlined" id="prepared_by" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="CheckedBy" margin="dense" name="checked_by" onChange={handleInputChange}                      
                               value={data.checked_by} variant="outlined" id="project_num" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="ApprovedBy" margin="dense" name="approved_by" onChange={handleInputChange}                      
                               value={data.approved_by} variant="outlined" id="approved_by" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Customer" margin="dense" name="customer" onChange={handleInputChange}                      
                               value={data.customer} variant="outlined" id="customer" />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={editRow}>Cancel</Button>
                    <Button color="primary" onClick={ () => handleUpdate(data.id, data)} >Save Changes</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Modal for Viewing BOM Items --> */}
            <Modal isOpen={viewmodal} toggle={viewBOM} className="modal-dialog modal-xl">
                <ModalHeader toggle={viewBOM}>View BOM Items</ModalHeader>   
                <ModalBody>
                   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="col-md-2">
                              <input type="text" placeholder="Enter item to be searched" style={elementStyle} value = {searchitems}
                               onChange={updateSearchItems} />
                            </div>
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Sl.No</th>
                                                    <th scope="col">Document No</th>
                                                    <th scope="col">Item Class</th>
                                                    <th scope="col">Part No</th>
                                                    <th scope="col">Part Name</th>
                                                    <th scope="col">Specification</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">ScopeOfSupply</th>
                                                    <th scope="col">ItemType</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bomItems.filter(filterSearchItems).map((n)=> {
                                                  return (
                                                    <tr>
                                                     <td>{n.sl_no}</td>
                                                     <td>{n.document_no}</td>
                                                     <td>{n.item_class}</td>
                                                     <td>{n.part_num}</td>
                                                     <td>{n.part_name}</td>
                                                     <td>{n.specification}</td>
                                                     <td>{n.location}</td>
                                                     <td>{n.scope_of_supply}</td>
                                                     <td>{n.item_type}</td>
                                                     <td>{n.qty}</td>
                                                     <td>
                                                      <button className="btn btn-info btn-xs" type="button" onClick={() => editItemRow(n)}>
                                                       <i className="icofont icofont-edit"></i>
                                                      </button>
                                                      <button className="btn btn-danger btn-xs" type="button" onClick={() => handleDeleteItem(n)}>
                                                       <i className="fa fa-trash-o"></i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={viewBOM}>Close</Button>
                    <Button color="info" onClick={addBOMItems}>Add BOM Items</Button>
                </ModalFooter>
            </Modal>

          {/* < !-- Using Form Modal for Add BOM Items--> */}
            <Modal isOpen={additemmodal} toggle={addBOMItems}>
                <ModalHeader toggle={addBOMItems}>Add BOM Items</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <Autocomplete
                                freeSolo
                                options={boms}
                                getOptionLabel={option => option.document_no}
                                value={bom_doc_val}                      
                                onChange={(event, newValue) => {
                                  setBomDocVal(newValue);
                                }}            
                                renderInput={params => (
                                  <TextField {...params} label="Document Number" margin="dense" variant="outlined" fullWidth/>
                                )}
                              /> 
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth id="item_class" label="Item Class" margin="dense" name="item_class" 
                                onChange={handleItemChange} value={item_values.item_class} variant="outlined" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Part Num" margin="dense" name="part_num" onChange={handleItemChange}                      
                                value={item_values.part_num} variant="outlined" id="part_num" />    
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Part Name" margin="dense" name="part_name" onChange={handleItemChange}                      
                                value={item_values.part_name} variant="outlined" id="part_name" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Specification" margin="dense" name="specification" 
                                onChange={handleItemChange} value={item_values.specification} variant="outlined" id="specification"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Location" margin="dense" name="location" 
                                onChange={handleItemChange} value={item_values.location} variant="outlined" id="location"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Scope of Supply" margin="dense" name="scope_of_supply" 
                                onChange={handleItemChange} value={item_values.scope_of_supply} variant="outlined" id="scope_of_supply"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Item Type" margin="dense" name="item_type" 
                                onChange={handleItemChange} value={item_values.item_type} variant="outlined" id="item_type"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Qty" margin="dense" name="qty" onChange={handleItemChange}                      
                                value={item_values.qty} variant="outlined" id="qty" type="number"/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={addBOMItems}>Cancel</Button>
                    <Button color="primary" onClick={handleItemSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit BOM Items--> */}
            <Modal isOpen={edititemmodal} toggle={editItemRow}>
                <ModalHeader toggle={editItemRow}>Edit BOM Items</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <TextField id="document_no" select label="Document Number" value={item_data.document_no}
                                onChange={handleInputitemChange} SelectProps={{ name: 'document_no' }} variant="outlined" fullWidth >
                                  {
                                    boms.map((n, index) => {
                                      return <MenuItem key={index} value={n.id}>{n.document_no}</MenuItem>
                                    })
                                  }
                              </TextField>
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth id="item_class" label="Item Class" margin="dense" name="item_class" 
                                onChange={handleInputitemChange} value={item_data.item_class} variant="outlined" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Part Num" margin="dense" name="part_num" onChange={handleInputitemChange}                      
                                value={item_data.part_num} variant="outlined" id="part_num" />    
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Part Name" margin="dense" name="part_name" onChange={handleInputitemChange}                      
                                value={item_data.part_name} variant="outlined" id="part_name" />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Specification" margin="dense" name="specification" 
                                onChange={handleInputitemChange} value={item_data.specification} variant="outlined" id="specification"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Location" margin="dense" name="location" 
                                onChange={handleInputitemChange} value={item_data.location} variant="outlined" id="location"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Scope of Supply" margin="dense" name="scope_of_supply" 
                                onChange={handleInputitemChange} value={item_data.scope_of_supply} variant="outlined" id="scope_of_supply"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Item Type" margin="dense" name="item_type" 
                                onChange={handleInputitemChange} value={item_data.item_type} variant="outlined" id="item_type"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField fullWidth label="Qty" margin="dense" name="qty" onChange={handleInputitemChange}                      
                                value={item_data.qty} variant="outlined" id="qty" type="number"/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={editItemRow}>Cancel</Button>
                  <Button color="primary" onClick={ () => handleUpdateItem(item_data.id, item_data)} >Save Changes</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default Installation;