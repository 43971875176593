import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { TextField, FormHelperText, TablePagination } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { getClients, postClients, getTsProject, postTsProject, 
        getTsProjectType, postTsProjectType } from '../../../actions/asyncActions';

const filter = createFilterOptions();

const ProjectCreation = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    // var fs = require('fs');
    // var data = fs.readFile('projectTypes.json');
    // var types = JSON.parse(data);
    // console.log(types)

    const [tsProject, setTsProject] = useState([])

    const [clients, setClients] = useState([]);
    const approvedClients = clients.filter(x=>x.status === 'approved')

    const [projTypes, setProjTypes] = useState([]);
    
    let lineServices = projTypes ? projTypes.lineService : null;
    let nonBillable = projTypes ? projTypes.nonBill : null;
    

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
        getClients(group_id)
        .then(resp=> setClients(resp.data))
    }, []);

    useEffect( () => {
        getTsProject(group_id)
        .then(resp=> setTsProject(resp.data))
    }, []);

    useEffect( () => {
        getTsProjectType()
        .then(resp => setProjTypes(resp.data))
    }, [])

    const projectTypes = [{name:'Billable'}, {name:'Non-Billable'}]
    const [type_val, setTypeVal] = useState("");
    const selectedType = type_val ? type_val && type_val.name : "";

    // const nonBillTypes = [{name: 'A'}, {name: 'B'}, {name:'C'}];
    // const lineService = [{name:'Development'}, {name:'Analytics'}];
    
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = React.useState(new Date());
    var nextDate = new Date(startDate)
    nextDate.setDate(nextDate.getDate() + 1);

    const [endDate, setEndDate] = React.useState(new Date(nextDate));

    const [lineValue, setLineValue] = React.useState("");
    const [value, setValue] = React.useState("");
    const [open, toggleOpen] = React.useState(false);
    const [lineopen, toggleLineOpen] = React.useState(false);

    const handleClose = () => {
        setDialogValue({ name: '' });
        setDialogLineValue({name: ''});
        toggleOpen(false);
        toggleLineOpen(false);
    };

    const [dialogValue, setDialogValue] = React.useState({
        name: '',
    });

    const [dialogLineValue, setDialogLineValue] = useState({
        name: '',
    })

    const [addmodal, setAddModal] = useState();
    const addRow = () => {
        setAddModal(!addmodal)
        setTypeVal("")
        setLineValue("")
        setDescription("")
        setStartDate(new Date())
        setEndDate(new Date(nextDate))
    }

    const handleClientSubmit = (event) => {
        event.preventDefault();
        setValue({
            name: dialogValue.name
        });
        const data = {
            name: dialogValue.name,
            status: 'approved',
            mgr_id: user.mgr_id,
            user_id: user.id,
            group_id: group_id
        }
        const lineData = {
            type: 'nonBill',
            name: dialogValue.name
        }
        if(selectedType === 'Billable'){
            postClients(data)
            .then(resp => {
                getClients(group_id)
                .then(resp => setClients(resp.data))
                handleClose();
            })
            .catch(err => {
                console.log("Error", err)
            })
        }
        else if(selectedType === 'Non-Billable'){
            postTsProjectType(lineData)
            .then(resp => {
                getTsProjectType()
                .then(resp => setProjTypes(resp.data))
                handleClose();
            })
        }
        else {
            handleClose();
        }
        
    };

    const handleLineServiceSubmit = (event) => {
        event.preventDefault();
        setLineValue({
            name: dialogLineValue.name
        });
        const lineData = {
            type: 'line',
            name: dialogLineValue.name
        }
        console.log("lineData", lineData)
        postTsProjectType(lineData)
        .then(resp => {
            getTsProjectType()
            .then(resp => setProjTypes(resp.data))
            console.log(resp.data)
            handleClose();
        })
    }

    const handleSubmit = () => {
        const data = {
            type: selectedType,
            line: lineValue.name,
            sub_type: value.name,
            description: description,
            start_date: startDate,
            end_date: endDate,
            user_id: user.id,
            group_id: group_id,
        }
        console.log("data", data)
        //postTimesheetProject API
        postTsProject(data)
        .then(resp => {
            getTsProject(group_id)
            .then(resp => setTsProject(resp.data))
            toast.success("Project created successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
            addRow()
        })
        .catch(err => {
            console.log(err)
        })
    }

    return(
        <Fragment>
        <Breadcrumb title="Project Creation" parent="Timesheet" />
            <div className="container-fluid">
                <div className="card">
                    <div className="card-block row">
                        <div className="col-sm-12 col-lg-12 col-xl-12">
                            <div className="table-responsive">
                                <table className="table table-sm table-bordered table-hover">
                                    <thead className="table-primary">
                                        <tr>
                                            <th scope="col">Sl.no</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">LineService</th>
                                            <th scope="col">SubType</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Start_Date</th>
                                            <th scope="col">End_Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {tsProject.map((n,i) => {
                                        return(
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td>{n.type}</td>
                                                <td>{n.line}</td>
                                                <td>{n.sub_type}</td>
                                                <td>{n.description}</td>
                                                <td>
                                                {new Date(n.start_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                </td>
                                                <td>
                                                {new Date(n.end_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                </td>
                                            </tr>
                                        )
                                     })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 col-xl-12 m-t-15 ml-1">
                            <div className="card">
                                <div className="card-block row">
                                    <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                        <button className="btn btn-info btn-sm" type="button" onClick={() => addRow()}>
                                            Add New
                                        </button>
                                    </div>
                                    <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={tsProject.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* < !-- Using Form Modal for Add Project --> */}
            <Modal isOpen={addmodal} toggle={addRow} size="md">
              <ModalHeader toggle={addRow}>Add Project</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-12 mb-3">
                              <Autocomplete
                                freeSolo
                                options={projectTypes}
                                getOptionLabel={option => option.name}
                                value={type_val}                      
                                onChange={(event, newValue) => {
                                    setTypeVal(newValue);
                                }}            
                                renderInput={params => (
                                    <TextField {...params} label="Type" margin="dense" variant="outlined" helperText="Select Project Type" fullWidth />
                                )}
                              />
                            </div>
                            <div className="col-xl-12 mb-3">
                                <Autocomplete
                                    value={lineValue}
                                    onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        // timeout to avoid instant validation of the dialog's form.
                                        setTimeout(() => {
                                        toggleLineOpen(true);
                                        setDialogLineValue({
                                            name: newValue,
                                        });
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        toggleLineOpen(true);
                                        setDialogLineValue({
                                        name: newValue.inputValue,
                                        });
                                    } else {
                                        setLineValue(newValue);
                                    }
                                    }}
                                    filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    if (params.inputValue !== '') {
                                        filtered.push({
                                        inputValue: params.inputValue,
                                        name: `Add "${params.inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                    }}
                                    
                                    options={lineServices}
                                    getOptionLabel={(option) => {
                                    // e.g value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.name;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderOption={(option) => option.name}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Service Name" variant="outlined" margin="dense" 
                                            helperText="Select Line of Service" fullWidth/>
                                    )}
                                />
                            </div>
                            {selectedType === 'Billable' ?
                            <div className="col-xl-12 mb-3">
                                <Autocomplete
                                    value={value}
                                    onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        // timeout to avoid instant validation of the dialog's form.
                                        setTimeout(() => {
                                        toggleOpen(true);
                                        setDialogValue({
                                            name: newValue,
                                        });
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        toggleOpen(true);
                                        setDialogValue({
                                        name: newValue.inputValue,
                                        });
                                    } else {
                                        setValue(newValue);
                                    }
                                    }}
                                    filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    if (params.inputValue !== '') {
                                        filtered.push({
                                        inputValue: params.inputValue,
                                        name: `Add "${params.inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                    }}
                                    
                                    options={approvedClients}
                                    getOptionLabel={(option) => {
                                    // e.g value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.name;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderOption={(option) => option.name}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Customer" variant="outlined" margin="dense" 
                                            helperText="Select Customer" fullWidth/>
                                    )}
                                />
                            </div>
                            : selectedType === 'Non-Billable' ?
                            <div className="col-xl-12 mb-3">
                                <Autocomplete
                                    value={value}
                                    onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        // timeout to avoid instant validation of the dialog's form.
                                        setTimeout(() => {
                                        toggleOpen(true);
                                        setDialogValue({
                                            name: newValue,
                                        });
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        toggleOpen(true);
                                        setDialogValue({
                                        name: newValue.inputValue,
                                        });
                                    } else {
                                        setValue(newValue);
                                    }
                                    }}
                                    filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    if (params.inputValue !== '') {
                                        filtered.push({
                                        inputValue: params.inputValue,
                                        name: `Add "${params.inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                    }}
                                    
                                    options={nonBillable}
                                    getOptionLabel={(option) => {
                                    // e.g value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.name;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderOption={(option) => option.name}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Name" variant="outlined" margin="dense" 
                                            helperText="Select Non Billable" fullWidth/>
                                    )}
                                />
                            </div>
                            : ""
                            }
                            <div className="col-xl-12 mb-3">
                              <TextField fullWidth label="Description" margin="dense" name="description" value={description}
                                onChange={(e) => setDescription(e.target.value)} variant="outlined" />
                            </div>
                            <div className="col-xl-6 mb-3">
                             <label className="col-form-label" htmlFor="message-text">Project Start Date:</label>
                              <DatePicker className="form-control digits"
                                selected={startDate}
                                value={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Project End Date:</label>
                              <DatePicker className="form-control digits"
                                selected={endDate}
                                value={endDate}
                                onChange={(date) => setEndDate(date)}
                                minDate={new Date(nextDate)}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={addRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>

            {/* Add new item modal */}
            <Modal isOpen={open} toggle={handleClose}>
              <ModalHeader toggle={handleClose}>Add a new item</ModalHeader>   
                <form onSubmit={handleClientSubmit}>
                    <ModalBody>
                       <div className="ml-1">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogValue.name}
                            onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
                            label="Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                       </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleClose}>Close</Button>
                        <Button color="primary" type="submit" >Add</Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Add new item modal */}
            <Modal isOpen={lineopen} toggle={handleClose}>
              <ModalHeader toggle={handleClose}>Add a new item</ModalHeader>   
                <form onSubmit={handleLineServiceSubmit}>
                    <ModalBody>
                       <div className="ml-1">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogLineValue.name}
                            onChange={(event) => setDialogLineValue({ ...dialogLineValue, name: event.target.value })}
                            label="Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                       </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleClose}>Close</Button>
                        <Button color="primary" type="submit" >Add</Button>
                    </ModalFooter>
                </form>
            </Modal>
        </Fragment>
    );
}

export default ProjectCreation;
