import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { TablePagination, TextField } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Autocomplete } from '@material-ui/lab';
import AddInventory from './AddInventory';
import AddTax from './AddTax.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getMasterInventory, postMasterInventory, editMasterInventory } from '../../actions/asyncActions';

const InventoryMaster = (props) => {
	const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    const [masterInventory, setMasterInventory] = useState([]);
    const [addmodal, setAddModal] = useState();
    const [addtaxmodal, setAddTaxModal] = useState();
    const [servicemodal, setServiceModal] = useState();
    const [typemodal, setTypeModal] = useState();
    const [values, setValues] = useState({});
    console.log("values", values)

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Item details', 'Tax details'];

    const initialFormState = { id: '', mfr:'', category:'', part_no:'', description:'', unit:''}
    const [ currentData, setCurrentData ] = useState(initialFormState)
    const [modal, setModal] = useState();
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getMasterInventory(group_id)
      .then(resp => setMasterInventory(resp.data))
    }, []);

    const inventoryType = [{ name: 'Raw Material', value: 0 }, {name: 'Finish Goods', value: 1}, {name: 'Services', value: 2}];
    const [typeval, setTypeVal] = useState(null);
    const materialType = typeval ? typeval && typeval.value : null;

    const taxRateList = [{name: '0%', value: 0}, {name: '0.1%', value: 0.1}, {name: '0.25%', value: 0.25},
                         {name: '3%', value: 3}, {name: '5%', value: 5}, {name: '12%', value: 12},
                         {name: '18%', value: 18}, {name: '28%', value: 28}];
    const [taxrateval, setTaxRateVal] = useState(null);
    const taxValue = taxrateval ? taxrateval && taxrateval.value : null;

    const handleTaxValue = (value) => {
      setTaxRateVal(value)
    }

    const handleNext = () => {
      setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };

    // const Inventory = (props) => {
    //   const { values } = props;
    //   return (
    //     <form>
    //       <div className="form-row">
    //           <div className="col-xl-6 mb-3">
    //             <label className="col-form-label" htmlFor="message-text">Manufacturer:</label>
    //             <input className="form-control" name="mfr" type="text" placeholder="Manufacturer" 
    //              value={values.mfr} onChange={props.handleChange}/>
    //           </div>
    //           <div className="col-xl-6 mb-3">
    //             <label className="col-form-label" htmlFor="message-text">Category:</label>
    //             <input className="form-control" name="category" type="text" placeholder="Category" 
    //              value={values.category} onChange={props.handleChange}/>
    //           </div>
    //           <div className="col-xl-6 mb-3">
    //             <label className="col-form-label" htmlFor="message-text">Part No:</label>
    //             <input className="form-control" name="part_no" type="text" placeholder="Part Num" 
    //              value={values.part_no} onChange={props.handleChange}/>
    //           </div>
    //           <div className="col-xl-6 mb-3">
    //             <label className="col-form-label" htmlFor="message-text">Unit:</label>
    //             <input className="form-control" name="unit" type="text" placeholder="Unit" 
    //              value={values.unit} onChange={props.handleChange}/>
    //           </div>
    //           <div className="col-md-12 mb-3">
    //             <label className="col-form-label" htmlFor="message-text">Description:</label>
    //             <input className="form-control" name="description" type="text" placeholder="Description" 
    //              value={values.description} onChange={props.handleChange}/>
    //           </div>
    //       </div>
    //     </form>
    //   );
    // }

    // const TaxDetails = (props) => {
    //   const { values } = props;
    //   return (
    //     <form>
    //       <div className="form-row">
    //           <div className="col-xl-6 mb-3">
    //            <label className="col-form-label" htmlFor="message-text">Tax Rate</label>
    //            <Autocomplete
    //               options={taxRateList}
    //               getOptionLabel={option => option.value}
    //               value={taxrateval}                      
    //               onChange={(event, newValue) => {
    //                 setTaxRateVal(newValue);
    //               }}            
    //               renderInput={params => (
    //                 <TextField {...params} margin="dense" variant="outlined" helperText="Select Tax Rate" fullWidth />
    //               )}
    //             />
    //           </div>
    //           <div className="col-xl-6 mb-3">
    //             <label className="col-form-label" htmlFor="message-text">HSN/SAC Code</label>
    //             <input className="form-control m-t-10" name="hsn" type="text" placeholder="HSN/SAC Code"
    //              value={values.hsn} onChange={props.handleChange}/>
    //           </div>
    //       </div>
    //     </form>
    //   );
    // }

    const getStepContent = (step) => {
      switch (step) {
        case 0:
          return <AddInventory handleChange={handleChange} values={values} />;
        case 1:
          return <AddTax handleChange={handleChange} values={values} taxRateList={taxRateList} taxrateval={taxrateval} 
                         handleTaxValue={handleTaxValue}/>; 
        default:
          throw new Error('Unknown step');
      }
    }

    const typeRow = () => {
      setTypeModal(!typemodal)
    }

    const addRow = () => {
      setAddModal(!addmodal)
    }

    const serviceRow = () => {
      setServiceModal(!servicemodal)
    }

    const addTaxRow = () => {
      setAddTaxModal(!addtaxmodal)
    }

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleSubmit = () => {
    	const data = {
          mfr: values.mfr,
          category: values.category,
          part_no: values.part_no,
          particulars: values.particulars,
          description: values.description,
          unit: values.unit,
          raw_material: materialType,
          rate: values.rate,
          tax_rate: taxValue,
          hsn: values.hsn,
          user_id: user.id,
          group_id: group_id,
        }
        console.log("data", data)
        postMasterInventory(data)
        .then(resp => {
            getMasterInventory(group_id)
            .then(resp => setMasterInventory(resp.data))
            setTypeModal(!typemodal)
            setTypeVal(null)
            if(taxValue){
              setAddTaxModal(!addtaxmodal)
            }
            else if(values.particulars){
              setServiceModal(!servicemodal)
            }
            else{
              setAddModal(!addmodal)
            }
            setValues({ mfr:'', category:'', part_no:'', particulars:'', description:'', unit:'', rate:'', hsn:''})
            toast.success("Item added successfully", {position: toast.POSITION.TOP_CENTER})
          })
          .catch(err => {
            console.log("Error", err);
        })
    }

    const editRow = n => {
     setModal(!modal)
     setCurrentData({ id: n.id, mfr: n.mfr, category: n.category, part_no: n.part_no, 
       description:n.description, unit:n.unit })
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentData({ ...currentData, [name]: value })
    }

    const handleUpdate = (id, data) => {
        const aaa = {
          id: id,
          mfr: data.mfr,
          category: data.category,
          part_no: data.part_no,
          description: data.description,
          unit: data.unit,
          user_id: user.id,
        }
        editMasterInventory(aaa)
        .then(resp => {
              getMasterInventory(group_id)
              .then(resp => setMasterInventory(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }

	return(
		<Fragment>
		 <Breadcrumb title="Inventory Master" parent="Inventory" />
		 	<div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Manufacturer</th>
                                                  <th scope="col">Category</th>
                                                  <th scope="col">PartName</th>
                                                  <th scope="col">Description</th>
                                                  <th scope="col">Unit</th>
                                                  <th scope="col">WAC</th>
                                                  <th scope="col">Type</th>
                                                  <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {masterInventory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.mfr}</td>
                                                     {n.particulars ?
                                                      <td>{n.particulars}</td>
                                                      :
                                                      <td>{n.category}</td> 
                                                     }
                                                     <td>{n.part_no}</td>
                                                     <td>{n.description}</td>
                                                     <td>{n.unit}</td>
                                                     <td>{n.wac}</td>
                                                     {n.raw_material === 0 ?
                                                      <td>Raw Material</td>
                                                      : n.raw_material === 1 ?
                                                      <td>Finish Goods</td>
                                                      :
                                                      n.raw_material === 2 ?
                                                      <td>Services</td>
                                                      :
                                                      <td></td>
                                                     }
                                                     {/*
                                                     <td>
                                                      {new Date(n.createdAt).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                 */}
                                                     <td>
                                                      <i className="icofont icofont-edit" onClick={() => editRow(n)} id="TooltipTop" data-placement="top"
                                                       style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff ' }}>
                                                       <UncontrolledTooltip placement="top" target="TooltipTop">
                                                        Edit row
                                                       </UncontrolledTooltip>
                                                      </i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                       <button className="btn btn-info btn-sm" type="button" onClick={() => typeRow()}>
                                         Add New Inventory
                                        </button>
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={masterInventory.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        {/* < !-- Using Form Modal for Type of Inventory --> */}
            <Modal isOpen={typemodal} toggle={typeRow} className="modal-dialog">
             <ModalBody>
              <div className="form-row text-center">
               <div className="col-xl-12">
                <h4> Please select the Inventory type </h4>
               </div>
               <div className="col-xl-12">
                <Autocomplete
                  freeSolo
                  options={inventoryType}
                  getOptionLabel={option => option.name}
                  value={typeval}                      
                  onChange={(event, newValue) => {
                    setTypeVal(newValue);
                    if((newValue && newValue.name) === 'Raw Material'){
                      setAddModal(!addmodal)
                    }
                    else if((newValue && newValue.name) === 'Services'){
                      setServiceModal(!servicemodal)
                    }
                    else{
                      setAddTaxModal(!addtaxmodal)
                    }
                  }}            
                  renderInput={params => (
                    <TextField {...params} margin="dense" variant="outlined" helperText="Please choose Type" fullWidth />
                  )}
                />
               </div>
              </div>
             </ModalBody>
            </Modal>

        {/* < !-- Using Form Modal for Add Inventory (Raw Material)--> */}
            <Modal isOpen={addmodal} toggle={addRow} className="modal-dialog modal-lg">
                <ModalHeader toggle={addRow}>Add Inventory</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Manufacturer:</label>
                              <input className="form-control" name="mfr" type="text" placeholder="Manufacturer" 
                               value={values.mfr} onChange={handleChange}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Category:</label>
                              <input className="form-control" name="category" type="text" placeholder="Category" 
                               value={values.category} onChange={handleChange}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Part No:</label>
                              <input className="form-control" name="part_no" type="text" placeholder="Part Num" 
                               value={values.part_no} onChange={handleChange}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Unit:</label>
                              <input className="form-control" name="unit" type="text" placeholder="Unit" 
                               value={values.unit} onChange={handleChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Description:</label>
                              <input className="form-control" name="description" type="text" placeholder="Description" 
                               value={values.description} onChange={handleChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={addRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>

      {/* < !-- Using Form Modal for Add Inventory with tax rate and hsn/sac(Finish Goods) --> */}
            <Modal isOpen={addtaxmodal} toggle={addTaxRow} className="modal-dialog modal-lg">
                <ModalHeader toggle={addTaxRow}>Add Inventory</ModalHeader>   
                <ModalBody>
                 <Stepper activeStep={activeStep}>
                   {steps.map(label => (
                     <Step key={label}>
                       <StepLabel>{label}</StepLabel>
                     </Step>
                   ))}
                 </Stepper>
                 <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className="btn btn-outline-light txt-dark m-t-3 m-l-1 m-2">
                      Prev
                    </Button>
                  )}
                  {activeStep === steps.length - 1 ?
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className="m-t-3 m-l-1 m-2"
                    round
                  >
                    Submit
                  </Button>
                  :
                  <Button
                    variant="contained"
                    color="primary"
                    round
                    onClick={handleNext}
                    className="m-t-3 m-l-1 m-2"
                  >
                    Next
                  </Button>
                }
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
                  {/*
                    <form>
                        <div className="form-row">
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Manufacturer:</label>
                              <input className="form-control" name="mfr" type="text" placeholder="Manufacturer" 
                               value={values.mfr} onChange={handleChange}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Category:</label>
                              <input className="form-control" name="category" type="text" placeholder="Category" 
                               value={values.category} onChange={handleChange}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Part No:</label>
                              <input className="form-control" name="part_no" type="text" placeholder="Part Num" 
                               value={values.part_no} onChange={handleChange}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Unit:</label>
                              <input className="form-control" name="unit" type="text" placeholder="Unit" 
                               value={values.unit} onChange={handleChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Description:</label>
                              <input className="form-control" name="description" type="text" placeholder="Description" 
                               value={values.description} onChange={handleChange}/>
                            </div>
                        </div>
                    </form>
                  */}
                </ModalBody>
            </Modal>

            {/* < !-- Using Form Modal for Add Inventory (Services)--> */}
            <Modal isOpen={servicemodal} toggle={serviceRow} className="modal-dialog modal-lg">
                <ModalHeader toggle={serviceRow}>Add Inventory</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Particulars:</label>
                              <input className="form-control" name="particulars" type="text" placeholder="Particulars" 
                               value={values.particulars} onChange={handleChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Description:</label>
                              <input className="form-control" name="description" type="text" placeholder="Description" 
                               value={values.description} onChange={handleChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={serviceRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>

        {/* < !-- Using Form Modal for Edit Inventory --> */}
            <Modal isOpen={modal} toggle={editRow} className="modal-dialog modal-lg">
                <ModalHeader toggle={editRow}>Edit Inventory</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Manufacturer:</label>
                              <input className="form-control" name="mfr" type="text" placeholder="Manufacturer" 
                               value={currentData.mfr} onChange={handleInputChange}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Category:</label>
                              <input className="form-control" name="category" type="text" placeholder="Category" 
                               value={currentData.category} onChange={handleInputChange}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Part No:</label>
                              <input className="form-control" name="part_no" type="text" placeholder="Part Num" 
                               value={currentData.part_no} onChange={handleInputChange}/>
                            </div>
                            <div className="col-xl-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Unit:</label>
                              <input className="form-control" name="unit" type="text" placeholder="Unit" 
                               value={currentData.unit} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Description:</label>
                              <input className="form-control" name="description" type="text" placeholder="Description" 
                               value={currentData.description} onChange={handleInputChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={editRow}>Cancel</Button>
                    <Button color="primary" onClick={() =>  handleUpdate(currentData.id, currentData)} >Save Changes</Button>
                </ModalFooter>
            </Modal>
		</Fragment>
	);
}

export default InventoryMaster;
