import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import Breadcrumb from '../../../components/common/breadcrumb';

const Employee = (props) => {
    const user = JSON.parse(localStorage.getItem("m_users"));

    const handleBasicForm = () => {
        props.history.push(`${process.env.PUBLIC_URL}/empbasicform`)
    }

    const handleCompleteForm = () => {
        props.history.push(`${process.env.PUBLIC_URL}/employeeform`)
    }
    return(
     <Fragment>
        <Breadcrumb title="Employee Creation" parent="Employee" />
        <div className="container-fluid">
            <div className="row">
                <div className="offset-xl-1 col-sm-12 col-xl-5 col-md-5 m-t-20">
                    <div className="card">
                        <div className="card-body text-center">
                            <i className="fa fa-address-book" style={{fontSize: '50px', color:'#4bb543'}}></i><br/>
                            <button className="m-t-15 btn btn-link" onClick={handleBasicForm} style={{fontSize: '22px'}}>
                                Basic Form
                            </button>
                            <p>
                              Here, you can send an email to the employee with the credentials to login and complete the form
                            </p> 
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-xl-5 col-md-5 m-t-20">
                    <div className="card">
                        <div className="card-body text-center">
                            <i className="fa fa-address-card" style={{fontSize: '50px', color:'#c71ec4'}}></i><br/>
                            <button className="m-t-15 btn btn-link" onClick={handleCompleteForm} style={{fontSize: '22px'}}>
                                Complete Form
                            </button>
                            <p>
                              Here, an HR can directly fill the form of an employee and send it for approval  
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </Fragment>
    );
}

export default withRouter(Employee);