import React, { Fragment, useState , useEffect } from 'react';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {Autocomplete} from '@material-ui/lab';
import { toast } from 'react-toastify';
import { indianNumberFormat, usNumberFormat } from '../../../actions/numberFormat';
import ViewEmployee from './ViewEmployee';
import { getEmployee, getDepartment, getDesignation, editEmployee, getCompensation } from '../../../actions/asyncActions';

const EmployeeMaster = (props) => {
	const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

   //  const elementStyle ={
	  // border:'solid',
	  // borderRadius:'10px',
	  // position:'relative',
	  // left:'1vh',
	  // height:'6vh',
	  // width:'23vh',
	  // marginTop:'1vh',
	  // marginBottom:'1vh',
	  // textAlign: 'center'
   //  }
   const [employees, setEmployees] = useState([]);
   const filterEmployee2 = employees.find(x=> x.id === user.emp_id);
   
   const [department, setDepartment] = useState([]);
   const [department_val, setDepartmentVal] = useState(null);
   const selectedDepartment = department_val ? department_val && department_val.id : null;

   const [designation, setDesignation] = useState([]);
   const filterDesignation = designation.filter(x=>x.dept_id === selectedDepartment)
   const [designation_val, setDesignationVal] = useState(null)
   const selectedDesignation = designation_val ? designation_val && designation_val.id : null

   const [compensation, setCompensation] = useState([]);

    //const [search, setSearch] = useState('');
	
	//const [filteredInvoices, setFilteredInvoices] = useState([]);
  
    // useEffect(() => {
  	 //  setFilteredInvoices(
  	 //   filterInvoices.filter(n => {
  	 // 	 return n.client_name.toLowerCase().includes(search.toLowerCase());
  	 //   })
  	 //  )
    // }, [search, filterInvoices])

    useEffect( () => {
	  getEmployee(group_id)
	  .then(resp => setEmployees(resp.data))
	}, []);
	
	useEffect( () => {
	  getDepartment(group_id)
	  .then(resp => setDepartment(resp.data))
	}, []);

	useEffect( () => {
	  getDesignation(group_id)
	  .then(resp => setDesignation(resp.data))
	}, []);

	useEffect( () => {
		getCompensation(group_id)
		.then(resp => setCompensation(resp.data))
	  }, []);
    
	const [empId, setEmpId] = useState(0);
	const [empmodal, setEmpModal] = useState(false);

	const filterEmployee = employees.filter(x=>x.id !== empId);
	const [employee_val, setEmployeeVal] = useState(null)
	const selectedManager = employee_val ? employee_val && employee_val.id : null

	const viewEmployee = (n) => {
		const Id = n.id
		props.history.push(`${process.env.PUBLIC_URL}/viewemployee`, {Id} )
	}

	const approveEmployee = (n) => {
		setEmpId(n.id)
		setEmpModal(!empmodal)
	}

	const handleSubmit = (n) => {
		const data = {
			id: empId,
			dept_id: selectedDepartment,
			desg_id: selectedDesignation,
			mgr_id: selectedManager,
			status: 'Approved'
		}
		if(!selectedDepartment || !selectedDesignation){
			toast.error("Department or Designation is not selected!!!",  {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
		}
		else{
			editEmployee(data)
			.then(resp => {
				getEmployee(group_id)
				.then(resp => setEmployees(resp.data))
				setEmpModal(!empmodal)
				setDepartmentVal(null)
				setDesignationVal(null)
				setEmployeeVal(null)
				toast.success("Employee Approved Successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	const addCompensation = (id, name) => {
		props.history.push(`${process.env.PUBLIC_URL}/compensation`, {id, name})
	}

	const viewCompensation = (id) => {
		const filterCompensation = compensation.filter(x=>x.emp_id === id);
		props.history.push(`${process.env.PUBLIC_URL}/viewcompensation`, {id, filterCompensation})
	}

	const viewdeclaration = (id) => {
		props.history.push(`${process.env.PUBLIC_URL}/viewdeclaration`, {id})
	}

	return(
		<Fragment>
		 <div className="container-fluid">
              <div className="row">
              {/*
               <div className="col-xl-12">
                <input type="text" placeholder="Search Invoice" style={elementStyle} value={search}
	              onChange={e => setSearch(e.target.value)} />
               </div>
              */}
               <div className="col-xl-12">
                 <div className="row">
                  {employees.map((n, i) => {
					const desn = designation.find(x=> x.id === n.desg_id)
					const desnName = desn ? desn && desn.name : null;
                   
                   return (
                   	 <div className="col-sm-12 col-xl-4 col-md-6">
			            <div className="ribbon-wrapper card">
			              <div className="card-body">
			                <div className="ribbon ribbon-bookmark ribbon-secondary">
								{n.employee_id}
							</div>
			                <div className="row text-center">
			                 <div className="col-sm-12 col col-md-12">
                            { n.gender === 'male' ?
                              <i className="fa fa-male" style={{fontSize: '50px', color:'#4bb543'}}></i>
                              :
                              <i className="fa fa-female" style={{fontSize: '50px', color:'#4bb543'}}></i>
                            }
                             <p>
			                  Name: <b>{n.name}</b><br/>
			                  Mobile No: <b>{n.phone}</b><br/>
			                  Email: <b> {n.email}</b><br/>
							  Designation: <b>{desnName}</b> <br/>
							  Status: 
							  { n.status === 'Submitted' ? 
							   <b>Form Submitted</b> 
							   : n.status === 'Approved' ?
								n.compensation === 'yes' ?
								<b>Approved and Compensation Added </b>
								:
							   <b>Approved</b> 
							   :
							   <b></b>
							  }
			                 </p>
								 <i className="fa fa-eye" onClick={() => viewEmployee(n)} id="TooltipTop" data-placement="top"
									style={{ width: 35, fontSize: 26, padding: 11, color: 'blue' }}>
									<UncontrolledTooltip placement="top" target="TooltipTop">
										View/Edit Details
									</UncontrolledTooltip>
								 </i>
								 {/* <i className="fa fa-thumbs-up" onClick={() => approveEmployee(n)} id="TooltipTop1" data-placement="top"
									style={{ width: 35, fontSize: 26, padding: 11, color: '#e0a716' }}>
									<UncontrolledTooltip placement="top" target="TooltipTop1">
									   Approve Employee
									</UncontrolledTooltip>
								 </i> */}
								 { n.status === 'Approved' && n.compensation !== 'yes'?
								 <i className="fa fa-money" onClick={() => addCompensation(n.id, n.name)} id="TooltipTop2" data-placement="top"
									style={{ width: 35, fontSize: 26, padding: 11, color: '#26c6da' }}>
									<UncontrolledTooltip placement="top" target="TooltipTop2">
									   Add Compensation
									</UncontrolledTooltip>
								 </i>
								 : n.status === 'Approved' && n.compensation === 'yes'?
								 <>
								 <i className="fa fa-money" onClick={() => viewCompensation(n.id)} id="TooltipTop3" data-placement="top"
									style={{ width: 35, fontSize: 26, padding: 11, color: '#26c6da' }}>
									<UncontrolledTooltip placement="top" target="TooltipTop3">
									   View Compensation
									</UncontrolledTooltip>
								 </i>
								 <i className="fa fa-dollar" onClick={() => viewdeclaration(n.id)} id="TooltipTop4" data-placement="top"
									style={{ width: 35, fontSize: 26, padding: 11, color: '#26c6da' }}>
									<UncontrolledTooltip placement="top" target="TooltipTop4">
									   View Income Declaration
									</UncontrolledTooltip>
								 </i>
								 </>
								 :
								 <i></i>
								}
			                 </div>
			                </div>
			              </div>
			            </div>
			         </div>
	                 );
	                })
	               }
                 </div>
               </div>
              </div>
            </div>

			{/* < !-- Using Form Modal for Approving Employee --> */}
			<Modal isOpen={empmodal} toggle={approveEmployee} className="modal-body" centered={true}>
              <ModalHeader className="text-center" toggle={approveEmployee}>
                Approve Employee
              </ModalHeader>   
              <ModalBody className="text-center">
                  <form>
                    <div className="form-row">
                      <div className="col-md-12">
						<Autocomplete
							freeSolo
							options={department}
							getOptionLabel={option => option.name}
							value={department_val}                      
							onChange={(event, newValue) => {
							setDepartmentVal(newValue);
							}}            
							renderInput={params => (
							<TextField {...params} label="Department" margin="dense" variant="outlined"
							 helperText="Please Select Department" fullWidth />
							)}
                      	/> 
                      </div>
                      <div className="col-md-12">
					    <Autocomplete
							freeSolo
							options={filterDesignation}
							getOptionLabel={option => option.name}
							value={designation_val}                      
							onChange={(event, newValue) => {
							setDesignationVal(newValue);
							}}            
							renderInput={params => (
							<TextField {...params} label="Designation" margin="dense" variant="outlined" 
							 helperText="Please Select Designation" fullWidth />
							)}
                      	/>
                      </div>
					  <div className="col-md-12">
					    <Autocomplete
							freeSolo
							options={filterEmployee}
							getOptionLabel={option => option.name}
							value={employee_val}                      
							onChange={(event, newValue) => {
							setEmployeeVal(newValue);
							}}            
							renderInput={params => (
							<TextField {...params} label="Manager" margin="dense" variant="outlined" 
							 helperText="Please Select Manager" fullWidth />
							)}
                      	/>
                      </div>
                    </div>
                  </form>
              </ModalBody>
              <ModalFooter>
				<button className="btn btn-danger" onClick={approveEmployee}>Cancel</button>
				<button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
              </ModalFooter>
          </Modal>
		</Fragment>
	);
}

export default withRouter(EmployeeMaster);