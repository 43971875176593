import React, { Fragment } from 'react';
import { TextField, Typography, Button, Grid } from '@material-ui/core';

const EmployeeDetails = (props) => {
    const {values, errors} = props

    const downloadContractFile = () => {
        fetch('http://localhost:9004/Data/EmploymentAgreement.docx')
        //fetch('http://172.105.41.149:9004/EmploymentAgreement.docx')
        //fetch('https://testerp.nu-pie.com/EmploymentAgreement.docx')
        // fetch('172.105.41.149:9004/Data/EmploymentAgreement.docx')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'EmploymentContract.docx';
                    a.click();
                });
                //window.location.href = response.url;
        });
    }

    return(
        <Fragment>
         <Typography variant="h6" gutterBottom>
            Previous Job Experience
        </Typography>
        <Grid container spacing={3} >
            <Grid item lg={12} sm={12}>
                <Typography gutterBottom>
                    Name of the Company:
                </Typography>
                <TextField required id="company_name" name="company_name" value={values.company_name} onChange={props.handleChange} fullWidth autoFocus />
            </Grid>
            <Grid item lg={6} sm={6}>
                <Typography gutterBottom>
                    Period(in years):
                </Typography>
                <TextField required id="period" name="period" value={values.period} onChange={props.handleChange} fullWidth />
            </Grid>
            <Grid item lg={6} sm={6}>
                <Typography gutterBottom>
                    Designation:
                </Typography>
                <TextField required id="com_designation" name="com_designation" value={values.com_designation} onChange={props.handleChange} fullWidth />
            </Grid>
            <Grid item lg={6} sm={6}>
                <Typography gutterBottom>
                    Experience Letter:
                </Typography>
                <input className="m-t-15" id="filesexp" type="file" variant="outlined" 
                 onChange={props.handleUploadExperience} />
            </Grid>
            <Grid item lg={6} sm={6}>
                <Typography gutterBottom>
                    Relieving Letter:
                </Typography>
                <input className="m-t-15" id="filesrel" type="file" variant="outlined" 
                 onChange={props.handleUploadRelieving} />
            </Grid>
            <Grid item lg={6} sm={6}>
                <button className="btn btn-info btn-md m-t-15" onClick={downloadContractFile}>
                    Download Employee Contract
                </button>
            </Grid>
            <Grid item lg={6} sm={6}>
                <Typography gutterBottom>
                    Employee Contract:
                </Typography>
                <input className="m-t-15" id="files_contract" type="file" variant="outlined" 
                 onChange={props.handleUploadContract} />
            </Grid>
        </Grid>
        </Fragment>
    );
}

export default EmployeeDetails;