import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { TablePagination, Snackbar, Slide, IconButton,
         Stepper, Step, StepLabel, TextField, MenuItem } from '@material-ui/core';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Collapse } from 'reactstrap';
import { Download, ThumbsUp } from 'react-feather';
import { CSVLink } from "react-csv";
import {Close as CloseIcon } from "@material-ui/icons";
import Payouts from './editStepper/Payouts';
import Deductions from './editStepper/Deductions';
import TaxDeductions from './editStepper/TaxDeductions';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {getEmployee, getPayroll, downloadPayroll, editPayroll, 
        getCompensation, editPayroll2, editPayroll3} from '../../../actions/asyncActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const PayrollFinal = (props) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    const elementStyle = {
      position:'relative',
      left:'1vh',
      height:'6vh',
      width:'32vh',
      marginTop:'1vh',
      marginBottom:'1vh',
      textAlign: 'center'
    }

    const [employees, setEmployees] = useState([])
    const filterEmployees = employees.filter(x=>x.job_status === 'ACTIVE');
    

    const selectedMonth = "January" 
    const selectedYear = "2021"

    const [search, setSearch] = useState("")

    const [payroll, setPayroll] = useState([])

    const abcdMonths = [...new Set(payroll.map(x => x.month))]; //Unique Months in Payroll Table
    const abcdYears = [...new Set(payroll.map(x => x.year))]; //Unique Years in Payroll Table
    
    let payrollMonths = [];
    let payrollYears = [];
    //Get Payroll Months for selection
    abcdMonths.forEach((item, i) => {
      payrollMonths.push({
        id: i+1,
        label:item,
        value: item
      })
    })

    //Get Payroll Years for selection
    abcdYears.forEach((item, i) => {
      payrollYears.push({
        id: i+1,
        label:item,
        value: item
      })
    })

    const [compensation, setCompensation] = useState("");
    const lastPayMonth = compensation ? compensation.last_pay_month : null
    const lastPayYear = compensation ? compensation.last_pay_year : null
    
    const [selectedMonths, setSelectedMonths] = useState("")
    const [selectedYears, setSelectedYears] = useState("")

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect(() => {
      getPayroll(group_id)
      .then(resp => setPayroll(resp.data))
    }, []);

    useEffect(() => {
      getEmployee(group_id)
      .then(resp => setEmployees(resp.data))
    }, [payroll]);

    useEffect(() => {
      getCompensation(group_id)
      .then(resp => {
        const aaa = employees.filter(x => x.job_status === 'ACTIVE');
        const bbb = [...new Set(aaa.map(x => x.id))];
        const ccc = resp.data;
        const ddd = ccc.filter(x=>x.group_id === group_id && bbb.includes(x.emp_id))
        setCompensation(ddd[0])
      })
    }, [employees])

    useEffect(() => {
      if(compensation){
        const lastPayMonth = compensation ? compensation.last_pay_month : null
        const lastPayYear = compensation ? compensation.last_pay_year : null
        setSelectedMonths(lastPayMonth)
        setSelectedYears(lastPayYear)
      }
      else{
        console.log("Error set")
      }
    }, [compensation]);
    

    const [activeIndex, setActiveIndex] = useState(null);

    const [editmodal, setEditModal] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Payouts', 'Deductions', 'Tax Deductions'];

    const [payoutData, setPayoutData] = useState([])
    const [payout_values, setPayoutValues] = useState([]) // Payouts Edited Data
    
    const handlePayoutChange = (event, i, n) => {
        const tempQtys = [...payout_values];
        tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
        setPayoutValues(tempQtys);
    };

    const [deductionData, setDeductionData] = useState([])
    const [deduction_values, setDeductionValues] = useState([]) // Deductions Edited Data
    
    const handleDeductionChange = (event, i, n) => {
        const tempQtys = [...deduction_values];
        tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
        setDeductionValues(tempQtys);
    };

    const [taxesData, setTaxesData] = useState([])
    const [taxes_values, setTaxesValues] = useState([]) // Taxes Edited Data
    
    const handleTaxesChange = (event, i, n) => {
        const tempQtys = [...taxes_values];
        tempQtys[i] = {...tempQtys[i], [event.target.name] : event.target.value};
        setTaxesValues(tempQtys);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
            return <Payouts payoutData={payoutData} payout_values={payout_values} handlePayoutChange={handlePayoutChange} />;
            case 1:
            return <Deductions deductionData={deductionData} deduction_values={deduction_values} 
                handleDeductionChange={handleDeductionChange} />;
            case 2:
            return <TaxDeductions taxesData={taxesData} taxes_values={taxes_values} handleTaxesChange={handleTaxesChange} /> 
            default:
            throw new Error('Unknown step');
        }
    }
    
    const editRow = id => {
        const filterPayouts = payroll.filter(x=>x.emp_id === id && x.category === 'Payouts' && x.month === selectedMonths && x.year === selectedYears)
        setPayoutData(filterPayouts)
        setPayoutValues(filterPayouts)

        const filterDeductions = payroll.filter(x=>x.emp_id === id && x.category === 'Deductions' && x.month === selectedMonths && x.year === selectedYears)
        setDeductionData(filterDeductions)
        setDeductionValues(filterDeductions)

        const filterTaxes = payroll.filter(x=>x.emp_id === id && x.category === 'Taxes' && x.month === selectedMonths && x.year === selectedYears)
        setTaxesData(filterTaxes)
        setTaxesValues(filterTaxes)

        setEditModal(!editmodal)
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };
  
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleSubmit = () => {
      let combinedData = []
      combinedData.push(...payout_values, ...deduction_values, ...taxes_values)
      
      const allData = {
        items: combinedData,
        user_id: user.id,
        group_id: group_id
      }
      //Edit Payroll
      editPayroll(allData)
      .then(resp => {
        getPayroll(group_id)
        .then(resp => setPayroll(resp.data))
        setEditModal(!editmodal)
        setPayoutData([])
        setDeductionData([])
        setTaxesData([])
        toast.success("Data added successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
      })
      

    }

    const [download_payroll, setDownload] = useState([]);
    const [open_download, setOpenDownload] = React.useState(false);

    const handleReviewClose = () => {
        setOpenDownload(false);
    };

    const handleDownloadSubmit = () => {
        const users = JSON.parse(localStorage.getItem("m_users"));
        const abcd = payroll.filter(x=> x.month === selectedMonths && x.year === selectedYears && x.status === 'Submitted' )

        const data = {
            group_id: users.group_id,
            month: selectedMonths,
            year: selectedYears
        }
        if(abcd.length > 0){
          downloadPayroll(data)
          .then(resp => {
          setDownload(resp.data);
          })
          .catch(err => {
          console.log("Error", err);
          })
          setOpenDownload(true);
      }
      else{
          toast.warning("Selected Dataset is not available for the download!!!", 
          {position: toast.POSITION.TOP_CENTER, autoClose:3000})
      }
    }

    // State variable to keep track of all the expanded rows
    // By default, nothing expanded. Hence initialized with empty array.
    const [expandedRows, setExpandedRows] = useState([]);

    // State variable to keep track which row is currently expanded.
    const [expandState, setExpandState] = useState({});

    /**
     * This function gets called when show/hide link is clicked.
     */
    const handleExpandRow = (event, userId) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(userId);

        let obj = {};
        isRowExpanded ? (obj[userId] = false) :  (obj[userId] = true);
        setExpandState(obj);

        // If the row is expanded, we are here to hide it. Hence remove
        // it from the state variable. Otherwise add to it.
        const newExpandedRows = isRowExpanded ?
          currentExpandedRows.filter(id => id !== userId) :
          currentExpandedRows.concat(userId);
        
        setExpandedRows(newExpandedRows);
    }

    const [payrollDateOpen, setPayrollDateOpen] = useState(false)
    const [paymentDate, setPaymentDate] = useState(new Date())
    console.log("paymentDate", paymentDate)

    const dateOpen = () => {
      const filterPayroll = payroll.filter(x=>x.group_id === group_id && 
        x.month === selectedMonths && x.year === selectedYears && x.status === 'Submitted' )
        if(filterPayroll.length > 0){
          setPayrollDateOpen(!payrollDateOpen)
        }
        else {
          toast.warning('Data not found', {position: toast.POSITION.TOP_CENTER, autoClose:3000})
        }
    } 

    const approvePayroll = () => {
        setPayrollDateOpen(!payrollDateOpen)
        const data = {
          group_id: group_id,
          month: selectedMonths,
          year: selectedYears,
          payment_date: paymentDate
        }
        //console.log("data", data)
        editPayroll2(data)
        .then(resp => {
          getPayroll(group_id)
          .then(resp => setPayroll(resp.data))
          toast.success("Payroll Approved Successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
          props.history.push(`${process.env.PUBLIC_URL}/payrollapproved`);
        })
    }

    const stopPayroll = (id) => {
      const data = {
        emp_id: id,
        group_id: group_id,
        month: selectedMonths,
        year: selectedYears
      }
      editPayroll3(data)
      .then(resp => {
        if(resp.data.error){
          toast.warning(`${resp.data.error}`, {position: toast.POSITION.TOP_CENTER, autoClose:3000})
        }
        else{
          getPayroll(group_id)
          .then(resp => setPayroll(resp.data))
          toast.success("Payroll Stopped Successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
          //props.history.push(`${process.env.PUBLIC_URL}/payrollapproved`);
        }
      })
    }

    
    return(
        <Fragment>
            <Breadcrumb title="Submitted Payroll" parent="Payroll" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                  <div className="row">
                                    <div className="col">
                                      <TextField select value={selectedMonths} onChange={(e) => setSelectedMonths(e.target.value)} 
                                        margin="dense" variant="outlined" style={elementStyle} label="Select Month">
                                        {
                                          payrollMonths.map((n, index) => {
                                            return <MenuItem key={index} value={n.value}>{n.label}</MenuItem>
                                          })
                                        }
                                      </TextField>
                                       &nbsp; &nbsp;
                                      <TextField select value={selectedYears} onChange={(e) => setSelectedYears(e.target.value)} 
                                        margin="dense" variant="outlined" style={elementStyle} label="Select Year">
                                        {
                                          payrollYears.map((n, index) => {
                                            return <MenuItem key={index} value={n.value}>{n.label}</MenuItem>
                                          })
                                        }
                                      </TextField>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Employee</th>
                                                    <th scope="col">Month</th>
                                                    <th scope="col">Payouts</th>
                                                    <th scope="col">Deductions</th>
                                                    <th scope="col">Tax Paid</th>
                                                    <th scope="col">Net Salary</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {filterEmployees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i) => {
                                                const filterPayroll = payroll.length > 0 ? payroll.filter(x=>x.emp_id === n.id && x.month === selectedMonths && 
                                                  x.year === selectedYears ) : null
                                                // const filterPayroll = payroll.filter(x=>x.emp_id === n.id)
                                                
                                                const findPayroll = selectedMonths && selectedYears ? payroll.find(x=>x.emp_id === n.id && x.month === selectedMonths && x.year === selectedYears)
                                                : payroll.find(x=>x.emp_id === n.id && x.month === selectedMonth && x.year === selectedYear)
                                                
                                                const filterMonth = findPayroll && findPayroll.month 
                                                const filterYear = findPayroll && findPayroll.year 
                                                const currentStatus = findPayroll && findPayroll.status

                                                const additions = filterPayroll ? 
                                                filterPayroll.filter(x=>x.category === 'Payouts').reduce((a,b) => a + parseFloat(b.net_salary), 0)
                                                : 
                                                null;
                                                const payouts = Math.round((additions + Number.EPSILON) * 100) / 100;
                                                const subtractions = filterPayroll ? 
                                                filterPayroll.filter(x=>x.category === 'Deductions').reduce((a,b) => a + parseFloat(b.net_salary), 0)
                                                : 
                                                null;
                                                const deductions = Math.round((subtractions + Number.EPSILON) * 100) / 100;
                                                const tax_subtractions = filterPayroll ? 
                                                filterPayroll.filter(x=>x.category === 'Taxes').reduce((a,b) => a + parseFloat(b.net_salary), 0)
                                                : 
                                                null;
                                                const tax_deductions = Math.round((tax_subtractions + Number.EPSILON) * 100) / 100;
                                                const netSal = payouts - deductions - tax_deductions;
                                                const netSalary = Math.round((netSal + Number.EPSILON) * 100) / 100;
                                                return(
                                                <>
                                                    <tr key={n.id}>
                                                    {currentStatus === 'Stopped' ? 
                                                      <td className="text-danger">
                                                        {n.employee_id}<br/>
                                                        <h6>{n.name}</h6>
                                                      </td>
                                                      :
                                                      <td className="text-success">
                                                        {n.employee_id}<br/>
                                                        <h6>{n.name}</h6>
                                                      </td>
                                                    }
                                                      <td>{findPayroll ? filterMonth.concat('-', filterYear) : 'NA'}</td>
                                                      <td>{payouts}</td>
                                                      <td>{deductions}</td>
                                                      <td>{tax_deductions}</td>
                                                      <td>{netSalary}</td>
                                                      <td>
                                                        {currentStatus === 'Stopped' || currentStatus === 'Approved' ? 
                                                        null
                                                        :
                                                        <i className="fa fa-stop-circle" onClick={() => stopPayroll(n.id)} id="TooltipTop" data-placement="bottom"
                                                            style={{ width: 35, fontSize: 16, padding: 11, color: 'red' }}>
                                                            <UncontrolledTooltip placement="bottom" target="TooltipTop">
                                                              Stop Payroll
                                                            </UncontrolledTooltip>
                                                        </i>
                                                        }
                                                        <button className="btn btn-link text-center" 
                                                          onClick={event => handleExpandRow(event, n.id)}>
                                                            {
                                                              expandState[n.id] ?
                                                              <i className="fa fa-eye-slash" style={{ width: 35, fontSize: 20, padding: 11, color: 'green' }}/> 
                                                              : 
                                                              <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'green' }}/>
                                                            } 
                                                        </button>
                                                        { currentStatus === 'Approved' ? 
                                                        null
                                                        :
                                                        <i className="fa fa-edit" onClick={() => editRow(n.id)} id="TooltipTop2" data-placement="bottom"
                                                            style={{ width: 35, fontSize: 16, padding: 11, color: '#178d91' }}>
                                                            <UncontrolledTooltip placement="bottom" target="TooltipTop2">
                                                            Edit Data
                                                            </UncontrolledTooltip>
                                                        </i>
                                                        }
                                                      </td>
                                                    </tr>
                                                    <>
                                                    { expandedRows.includes(n.id) ?
                                                     <tr className="text-center">
                                                         <td colSpan={12}>
                                                            <table>
                                                            <thead className="table-warning">
                                                                <th>Category</th>
                                                                <th>Component</th>
                                                                <th>Amount</th>
                                                                <th>LOP</th>
                                                                <th>WorkingDays</th>
                                                                <th>NetSalary</th>
                                                            </thead>
                                                            <tbody>
                                                            {payroll.filter(x=>x.emp_id === n.id && x.month === selectedMonths && x.year === selectedYears)
                                                            .map((n,i) => {
                                                                return(
                                                                    <tr key={i}>
                                                                        <td>{n.category}</td>
                                                                        <td>{n.component}</td>
                                                                        <td>{n.monthly}</td>
                                                                        <td>{n.lop}</td>
                                                                        <td>{n.tot_days}</td>
                                                                        <td>{n.net_salary}</td>
                                                                    </tr>
                                                                );    
                                                              })
                                                             }
                                                            </tbody>
                                                            </table>
                                                         </td>
                                                     </tr>
                                                     : null
                                                    }
                                                    </>
                                                  </>
                                                )}
                                                )
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-6 col-lg-6 col-xl-6 m-t-10">
                                    <button className="btn btn-info btn-sm ml-1" onClick={() => handleDownloadSubmit()} >
                                        <Download size="14"/> Export
                                    </button>
                                    <button className="btn btn-success btn-sm ml-1" onClick={() => dateOpen()} >
                                        <ThumbsUp size="14"/> Approve Payroll
                                    </button>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterEmployees.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        {/***** Download CSV File *****/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download}
          autoHideDuration={6000}
          onClose={handleReviewClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={download_payroll}
              filename="PayrollData.csv"
              className="hidden"
              target="_blank">
              <Button onClick={downloadPayroll} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleReviewClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        {/* < !-- Using Form Modal for Add Inventory with tax rate and hsn/sac(Finish Goods) --> */}
        <Modal isOpen={editmodal} toggle={editRow} className="modal-dialog modal-lg">
                <ModalHeader toggle={editRow}>Edit Payroll Data</ModalHeader>   
                <ModalBody>
                 <Stepper activeStep={activeStep}>
                   {steps.map(label => (
                     <Step key={label}>
                       <StepLabel>{label}</StepLabel>
                     </Step>
                   ))}
                 </Stepper>
                 <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className="btn btn-outline-light txt-dark m-t-3 m-l-1 m-2">
                      Prev
                    </Button>
                  )}
                  {activeStep === steps.length - 1 ?
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className="m-t-3 m-l-1 m-2"
                    round
                  >
                    Submit
                  </Button>
                  :
                  <Button
                    variant="contained"
                    color="primary"
                    round
                    onClick={handleNext}
                    className="m-t-3 m-l-1 m-2"
                  >
                    Next
                  </Button>
                }
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
          </ModalBody>
        </Modal>

        {/* Payment Date Modal */}
        <Modal isOpen={payrollDateOpen} toggle={dateOpen} size="small" centered="true">
              <ModalHeader toggle={dateOpen}>Select Payment Date</ModalHeader>
              <ModalBody>
                <div className="row text-center">
                 <div className="offset-xl-3 col-xl-6">
                  <DatePicker className="form-control digits text-success"
                    selected={paymentDate}
                    value={paymentDate}
                    onChange={(date) => setPaymentDate(date)}
                    dateFormat= "dd-MMM-yyyy"
                    placeholderText="Select Payment Date"
                    showMonthDropdown
                    dropdownMode="select"
                  />
                 </div>
                 <div className="offset-xl-3 col-xl-6 m-t-15">
                  <button className="btn btn-lg btn-success" onClick={approvePayroll}>Alright!!!</button>
                 </div>
                </div>
              </ModalBody>
        </Modal>
    </Fragment>
    );
}

export default PayrollFinal;