import React, {Fragment, useState, useEffect} from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { getEmployeeTax, editEmployeeTax} from '../../../actions/asyncActions';
import { toast } from 'react-toastify';

const ViewDeclaration = (props) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const userRole = user.role;
    const group_id = user.group_id;
    const location = props.location;
    const employeeId = location.state ? location.state.id : null;

    const [employeeTax, setEmployeeTax] = useState([]);
    const filterEmpTax = employeeTax.find(x=>x.emp_id === employeeId);
    const grossSalary = filterEmpTax && filterEmpTax.gross_salary;
    const standardDeduction = filterEmpTax && filterEmpTax.standard_deduction;
    const professionTax = filterEmpTax && filterEmpTax.profession_tax;
    const leastHRA = filterEmpTax && filterEmpTax.least_hra;
    const totalVIADeduction = filterEmpTax && filterEmpTax.total_via_deduction;
    const totalHPIncome = filterEmpTax && filterEmpTax.total_hp_income;
    const netTaxSalary = filterEmpTax && filterEmpTax.net_taxable_income;
    const slabfive = filterEmpTax && filterEmpTax.slab_5;
    const slabten = filterEmpTax && filterEmpTax.slab_10;
    const slabfifteen = filterEmpTax && filterEmpTax.slab15;
    const slabTotalTax = filterEmpTax && filterEmpTax.total_tax;
    const status = filterEmpTax && filterEmpTax.status;

    useEffect(() => {
        getEmployeeTax(group_id)
        .then(resp => setEmployeeTax(resp.data))
    }, []);

    const approveDeclaration = () => {
        const data = {
            emp_id: employeeId,
            status: 'Approved'
        }
        //Edit Employee Tax
        editEmployeeTax(data)
        .then(resp => {
            getEmployeeTax(group_id)
            .then(resp => setEmployeeTax(resp.data))
            toast.success("Declaration Approved Successfully")
            props.history.push(`${process.env.PUBLIC_URL}/employeemaster`)
        }) 
    }

    return(
        <Fragment>
         <Breadcrumb title="IT Declaration" parent="Payroll" />
         <div className="container-fluid">
            <div className="card">
                <div className="card-block row">
                    <div className="col-sm-6 col-lg-6 col-xl-6 form-inline">
                        Current Status: <h6 className="text-primary m-t-10">{status}</h6>
                    </div>
                    <div className="col-sm-6 col-lg-6 col-xl-6 text-right">
                        {status === 'Pending' ?
                            <button className="btn btn-pill btn-success btn-lg" onClick={() => approveDeclaration()}>
                                Approve
                            </button>
                        :
                        null
                        }
                    </div>
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                        <div className="table-responsive m-t-15">
                            <table className="table table-sm table-bordered table-hover">
                                <thead className="table-primary">
                                <tr>
                                    <th scope="col">DESCRIPTION</th>
                                    <th scope="col">AMOUNT</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>Gross Salary</th>
                                    <th>{grossSalary}</th>
                                </tr>
                                <tr>
                                    <th>Standard Deduction</th>
                                    <th>{standardDeduction}</th>
                                </tr>
                                <tr>
                                    <th>Professional Tax</th>
                                    <th>{professionTax}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th className="text-warning">{parseFloat(standardDeduction) + parseFloat(professionTax)}</th>
                                </tr>
                                <tr>
                                    <th>Net Salary</th>
                                    <th>{parseFloat(grossSalary) - parseFloat(standardDeduction) + parseFloat(professionTax)}</th>
                                </tr>
                                <tr>
                                    <th>Tax Related Deductions</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th>HRA</th>
                                    <th>{parseFloat(leastHRA)}</th>
                                </tr>
                                <tr>
                                    <th>VI A Deductions</th>
                                    <th>{parseFloat(totalVIADeduction)}</th>
                                </tr>
                                <tr>
                                    <th>Home Loan Deductions</th>
                                    <th>{parseFloat(totalHPIncome) || 0}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th className="text-danger">{parseFloat(leastHRA || 0) + parseFloat(totalVIADeduction || 0) +parseFloat(totalHPIncome || 0)}</th>
                                </tr>
                                <tr>
                                    <th className="text-success">Net Taxable Salary</th>
                                    <th className="text-success">{parseFloat(netTaxSalary)}</th>
                                </tr>
                                <tr>
                                    <th>Basic Exemption</th>
                                    <th>{'250000'}</th>
                                </tr>
                                <tr>
                                    <th>Tax Payable</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th>5%</th>
                                    <th>{parseFloat(slabfive)}</th>
                                </tr>
                                <tr>
                                    <th>10%</th>
                                    <th>{parseFloat(slabten)}</th>
                                </tr>
                                <tr>
                                    <th>15%</th>
                                    <th>{parseFloat(slabfifteen)}</th>
                                </tr>
                                <tr>
                                    <th>Total Tax for the year</th>
                                    <th>{parseFloat(slabTotalTax)}</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
         </div>
        </Fragment>
    );
}

export default ViewDeclaration;