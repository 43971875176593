import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TextField, FormHelperText, MenuItem } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { getMasterInventory, getProjects, getTransInventory, getAllUser, postTransInventory3 } from '../../actions/asyncActions';

const ItemReturn = () => {
 
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;

 const [masterInventory, setMasterInventory] = useState([]);
 const [transInventory, setTransInventory] = useState([]);
 
 const [allUsers, setAllUsers] = useState([]);
 const filterUsers = allUsers.filter(x=>x.type === 'operations');
 const [user_val, setUserVal] = useState(null);
 const selectedUser = user_val && user_val.id;

 const [masterId, setMasterId] = useState();
 const [returnmodal, setReturnModal] = useState();
 const [quantity, setQuantity] = useState();
 const [cartmodal, setCartModal] = useState();

 const [errors, setError] = useState({});

 const filterTransInventory = transInventory.filter(x=>x.issued_user_id === selectedUser);

 useEffect( () => {
   getMasterInventory(group_id)
    .then(resp => setMasterInventory(resp.data))
 }, []);

 useEffect( () => {
   getTransInventory(group_id)
    .then(resp => setTransInventory(resp.data))
 }, []);

 useEffect( () => {
   getAllUser(group_id)
    .then(resp => setAllUsers(resp.data))
 }, []);

 const returnRow = (n) => {
   setMasterId(n.id);
   setReturnModal(!returnmodal)
 }

 const validateForm = (data) => {
   const errors = [];
    if(data.qty > data.transQty){
     errors.quantity = "Entered Qty cannot be greater than issued Qty";
    }
    if(data.qty <= 0){
      errors.quantity = "Entered Qty cannot be <= zero";
    }
   return errors;
 }

 const handleSubmit = () => {
   const aaa = transInventory.find(x=>x.id === masterId);
   const transQty = aaa && aaa.qty;
   const getRate = aaa && aaa.rate;

   const checkData = {
    qty: quantity,
    transQty: -(transQty)
   }
   const errors = validateForm(checkData);
    if(Object.keys(errors).length) {
     setError(errors);
    return;
   }

   const transData = {
    inventory_id: aaa && aaa.inventory_id,
    qty: quantity,
    rate: getRate,
    total: parseFloat(quantity) * parseFloat(getRate),
    type: 'Return',
    date: new Date(),
    project_id: aaa && aaa.project_id,
    user_id: user.id,
    issued_user_id: aaa && aaa.issued_user_id,
    group_id: group_id 
   }

   postTransInventory3(transData)
   .then(resp => {
    getTransInventory(group_id)
      .then(resp => setTransInventory(resp.data))
    toast.success("Item is added to the transaction table!!!", {autoClose: 2000});
    setReturnModal(!returnmodal)
   })
   .catch(err => {
      console.log("Error", err);
   })
 }


  return (
      <Fragment>
        <Breadcrumb title="Item Return" parent="Return" />
         <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-12">
                <div className="card card-absolute">
                  <div className="card-header bg-secondary">
                    <h5>Return Items</h5>
                  </div>
                  <div className="card-body">
                   <div className="row">
                     <div className="col-md-3">
                      <Autocomplete
                        freeSolo
                        options={filterUsers}
                        getOptionLabel={option => option.firstname}
                        value={user_val}                      
                        onChange={(event, newValue) => {
                          setUserVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Issued Person" margin="normal" variant="outlined" 
                           helperText="To whom items were issued?" fullWidth />
                        )}
                      />
                     </div>
                   </div>
                     {selectedUser ?
                      <div className="row">
                      <div className="col-xl-12">
                       <div className="table-responsive">
                          <table className="table table-sm table-bordered table-hover">
                              <thead className="table-info">
                                  <tr>
                                    <th scope="col">Sl.No</th>
                                    <th scope="col">Part No</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Rate</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {filterTransInventory.map((n, i)=> {
                                    const inventMaster = masterInventory.find(x=>x.id === n.inventory_id);
                                    const idd = inventMaster && inventMaster.id;
                                    const partNo = inventMaster && inventMaster.part_no;
                                    const Desc = inventMaster && inventMaster.description;
                                    const bbb = filterTransInventory.filter(x=>x.inventory_id === idd);
                                    const findQty = bbb.reduce((a,b) => a + parseFloat(b.qty), 0)

                                    return (
                                      <tr>
                                       <th scope="row">{i+1}</th>
                                       <td>{partNo}</td>
                                       <td>{Desc}</td>
                                       <td>{-(n.qty)}</td>
                                       <td>{n.rate}</td>
                                       <td>{-(n.total)}</td>
                                       <td>
                                        <button className="btn btn-secondary btn-sm" type="button" onClick={() => returnRow(n)}>
                                         Return
                                        </button>
                                       </td>
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                          </table>
                       </div>
                      </div>
                     </div>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
         </div> 

        {/* < !-- Using Form Modal for Cart Addition --> */}
            <Modal isOpen={returnmodal} toggle={returnRow} className="modal-body" centered={true}>
                <ModalHeader toggle={returnRow}>Return Qty</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                          <div className="col-sm-12 mb-3">
                            <label className="col-form-label text-center" htmlFor="recipient-name">Return Qty:</label>
                            <input className="form-control" type="text" onChange={e => setQuantity(e.target.value)} value={quantity} />
                            <FormHelperText className="text-danger">{errors.quantity}</FormHelperText>
                          </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={returnRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>
      </Fragment>
    );
}
export default ItemReturn;