import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Button, Collapse } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { indianNumberFormat, usNumberFormat } from '../../../actions/numberFormat';
import { getProjects, getCostSheet, getIndentItems, getPOItems, getIndInvoiceItems, getGrinItems,
         getRaisedInvoices, getSettings, getRaisedInvItems } from '../../../actions/asyncActions';

const ViewAllInvoices = (props) => {
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const location = props.location;
 const projectId = location.state ? location.state.projectId : 1;

 const [raisedInvoices, setRaisedInvoices] = useState([])
 const [raiseInvItems, setRaiseInvItems] = useState([])
 const [settings, setSettings] = useState([])
 const curSettings = settings.find(x=>x.group_id === group_id)
 const foundCurrency = curSettings ? curSettings && curSettings.currency : null;

 const filterInvoices = raisedInvoices.filter(x=> x.project_id === projectId);
 const filterRaisedInvoices = filterInvoices.filter(x => x.status_type === 'Preparation')
 const noOfRaisedInvoices = (filterRaisedInvoices || []).length
 const raisedTotal = filterRaisedInvoices.reduce((a,b) => a + parseFloat(b.total_amount), 0);
 const raisedAmount = foundCurrency === 'INR' ? indianNumberFormat(raisedTotal) : usNumberFormat(raisedTotal)

 const filterApprovedInvoices = filterInvoices.filter(x => x.status_type === 'Approved')
 const noOfApprovedInvoices = (filterApprovedInvoices || []).length
 const approvedTotal = filterApprovedInvoices.reduce((a,b) => a + parseFloat(b.total_amount), 0);
 const approvedAmount = foundCurrency === 'INR' ? indianNumberFormat(approvedTotal) : usNumberFormat(approvedTotal)

 const [projects, setProject] = useState([]);
 const findPOVal = projects.find(x=>x.id === projectId);
 const poValue = findPOVal && findPOVal.po_value;

 const [costSheet, setCostSheet] = useState([]);
 const filterCostSheet = costSheet.filter(x=>x.project_id === projectId);
 const calculateTotalCost = filterCostSheet.reduce((a,b) => a + parseFloat(b.cost), 0);

 const [activeIndex, setActiveIndex] = useState(null);
 const [activeIndex2, setActiveIndex2] = useState(null);
 const [activeIndex3, setActiveIndex3] = useState(null);

 const [costSheetId, setCostSheetId] = useState();
 const [indentItemId, setIndentItemId] = useState();
 const [poItemsId, setPOItemsId] = useState();

 const [indentItems, setIndentItems] = useState([]);
 const filterIndentItems = indentItems.filter(x=>x.cogs_ref === costSheetId);
 const totalIndentCost = indentItems.reduce((a,b) => a + parseFloat(b.total), 0);

 const [poItems, setPOItems] = useState([]);
 const filterPOItems = poItems.filter(x=>x.indentItem_id === indentItemId);

 const [invoiceItems, setInvoiceItems] = useState([]);
 const filterInvoiceItems = invoiceItems.filter(x=>x.po_itemno === poItemsId);

 const [grnItems, setGrnItems] = useState([]);

 useEffect( () => {
  getProjects(group_id)
  .then(resp => setProject(resp.data))
 }, []);

 useEffect( () => {
  getCostSheet(group_id)
  .then(resp => setCostSheet(resp.data))
 }, []);

 useEffect( () => {
  getIndentItems(group_id)
  .then(resp => setIndentItems(resp.data))
 }, []); 

 useEffect( () => {
  getPOItems(group_id)
  .then(resp => setPOItems(resp.data))
 }, []);
 
 useEffect( () => {
  getIndInvoiceItems(group_id)
  .then(resp => setInvoiceItems(resp.data))
 }, []);

 useEffect( () => {
  getGrinItems(group_id)
  .then(resp => setGrnItems(resp.data))
 }, []);

 useEffect( () => {
  getRaisedInvoices(group_id)
  .then(resp => setRaisedInvoices(resp.data))
 }, []);

 useEffect( () => {
  getRaisedInvItems(group_id)
  .then(resp => setRaiseInvItems(resp.data))
 }, []);

 useEffect( () => {
  getSettings(group_id)
  .then(resp => setSettings(resp.data))
 }, []);

 const viewRaisedInvoice = n => {
   const invoiceId = n.id;
	 const invoiceNo = n.invoice_no;
	 const clientId = n.client_id;
	 const clientName = n.client_name;
	 const billingAddress = n.billing_address ;
	 const txnType = n.txn_type;
	 const status = n.status_type;
	 const dinvoice = n.doi;
	 const creditPeriod = n.credit_period;
	 const dueInvoice = n.due_date;
	 const placeSupply = n.place_supply;
	 const totalAmount = n.total_amount;
	 const currency = n.currency;
	 const exchangeRate = n.exchange_rate;
	 const customerNotes = n.cus_notes;
	 const termsConditions = n.terms;
	 const invItems = raiseInvItems.filter(x=>x.invoice_id === n.id);
	 props.history.push(`${process.env.PUBLIC_URL}/viewrinvoice`, {invoiceId, invoiceNo, clientId, clientName, billingAddress, txnType,
		status, dinvoice, creditPeriod, dueInvoice, placeSupply, totalAmount, currency, exchangeRate, customerNotes, termsConditions, invItems});
 }

 const viewApprovedInvoice = n => {
  const invoiceId = n.id;
  const invoiceNo = n.invoice_no;
  const clientName = n.client_name;
  const billingAddress = n.billing_address ;
  const txnType = n.txn_type
  const status = n.status_type;
  const dinvoice = n.doi;
  const creditPeriod = n.credit_period;
  const dueInvoice = n.due_date;
  const placeSupply = n.place_supply;
  const totalAmount = n.total_amount;
  const customerNotes = n.cus_notes;
  const termsConditions = n.terms;
  const invItems = raiseInvItems.filter(x=>x.invoice_id === n.id);
  props.history.push(`${process.env.PUBLIC_URL}/viewainvoice`, {invoiceId, invoiceNo, clientName, billingAddress, txnType, 
    status, dinvoice, creditPeriod, dueInvoice, placeSupply, totalAmount, customerNotes, termsConditions, invItems});
 }

	return(
	 <Fragment>
	  <Breadcrumb title="All Invoices" parent="Project Management" />
	  <div className="container-fluid">
      <div className="row">
        <div className="col-xl-6 col-md-6">
			    <div className="ribbon-wrapper card">
			      <div className="card-body">
              <div className="ribbon ribbon-bookmark ribbon-secondary">
                Raised Invoices
              </div>
              <h5 className="text-center">
                <i className="fa fa-fighter-jet" 
                  style={{ fontSize: 32, padding: 11, color: '#1ea6ec' }}>
                </i> <br/>
                No of Invoices: <b>{noOfRaisedInvoices}</b> <br/>
                Total Amount: <b>{raisedAmount}</b> <br/>
                <button className="btn btn-link text-center" data-toggle="collapse"
                  onClick={event => {setActiveIndex(
                      activeIndex === 0 ? null : 0
                  );
                  }}
                  data-target="#collapseicon" 
                  aria-expanded="false"
                  aria-controls="collapseicon"
                >
                 Click here for more details        
                </button>
                {/* Start: Collapse for viewing raised invoices */}
                { filterRaisedInvoices.length > 0 ?
                  <Collapse isOpen={activeIndex === 0} >
			            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion" >
			             <div className="table-responsive">
			              <table className="table table-bordered">
			                <thead className="table-success">
			                 <tr> 
			                  <td>Invoice No</td>
			                  <td>Invoice Date</td>
			                  <td>Total Amount</td>
                        <td>Action</td>
			                 </tr>
			                </thead>
                      <tbody>
                        { filterRaisedInvoices.map((n,i) => {
                            return(
                              <tr>
                                <td>{n.invoice_no}</td>
                                <td>{new Date(n.doi).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                <td>{n.total_amount}</td>
                                <td>
                                <i className="fa fa-eye" onClick={() => viewRaisedInvoice(n)} id="TooltipTop" data-placement="top"
                                  style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
                                  <UncontrolledTooltip placement="top" target="TooltipTop">
                                    View Invoice
                                  </UncontrolledTooltip>
                                </i>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                   </div>
                  </div>
                  </Collapse>
                  :
                  <Collapse isOpen={activeIndex === 0}>
			              <h3 className="text-danger"> There are no items </h3>
			           </Collapse>
                }
              </h5>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-6">
			    <div className="ribbon-wrapper card">
			      <div className="card-body">
              <div className="ribbon ribbon-bookmark ribbon-success">
                Approved Invoices
              </div>
              <h5 className="text-center">
                <i className="fa fa-thumbs-up" 
                  style={{ fontSize: 32, padding: 11, color: '#22af47' }}>
                </i> <br/>
                No of Invoices: <b>{noOfApprovedInvoices}</b> <br/>
                Total Amount: <b>{approvedAmount}</b> <br/>
                <button className="btn btn-link text-center" data-toggle="collapse"
                  onClick={event => {setActiveIndex(
                      activeIndex === 1 ? null : 1
                  );
                  }}
                  data-target="#collapseicon" 
                  aria-expanded="false"
                  aria-controls="collapseicon"
                >
                  Click here for more details
                </button>
                {/* Start: Collapse for viewing approved invoices */}
                { filterApprovedInvoices.length > 0 ?
                  <Collapse isOpen={activeIndex === 1} >
			            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion" >
			             <div className="table-responsive">
			              <table className="table table-bordered">
			                <thead className="table-success">
			                 <tr> 
			                  <td>Invoice No</td>
			                  <td>Invoice Date</td>
			                  <td>Total Amount</td>
                        <td>Action</td>
			                 </tr>
			                </thead>
                      <tbody>
                        { filterApprovedInvoices.map((n,i) => {
                            return(
                              <tr>
                                <td>{n.invoice_no}</td>
                                <td>{new Date(n.doi).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                <td>{n.total_amount}</td>
                                <td>
                                  <i className="fa fa-eye" onClick={() => viewApprovedInvoice(n)} id="TooltipTop" data-placement="top"
                                    style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
                                    <UncontrolledTooltip placement="top" target="TooltipTop">
                                      View Invoice
                                    </UncontrolledTooltip>
                                  </i>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                   </div>
                  </div>
                  </Collapse>
                       :
                       <Collapse isOpen={activeIndex === 1}>
			            <h3 className="text-danger"> There are no items </h3>
			           </Collapse>
                }
              </h5>
            </div>
          </div>
        </div>
      </div>
	  </div>
	 </Fragment>
	);
}

export default withRouter(ViewAllInvoices);