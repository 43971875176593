import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {TextField, Backdrop , CircularProgress} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//import { toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { getProjects, getBoms, BulkUploadIBOM, runIBOMScript, getBomItems } from '../../actions/asyncActions';

const useStyles = makeStyles(theme => ({
  circular: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const MyBOM = () => {
 
 const classes = useStyles();
 const theme = useTheme();
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const [projects, setProject] = useState([]);
 const [project_val, setProjectVal] = useState(null);
 const selectedProject = project_val && project_val.id;
 const ProjectClientId = project_val && project_val.client_id;
 const bomtype = [{ name: 'Installation' }, {name: 'Others'}];
 const [typeval, setTypeVal] = useState(null);
 const [bomfile, setBomFile] = React.useState([]);
 var [isLoading, setIsLoading] = useState(false);
 const [boms, setBoms] = useState([]);
 const [bom_items, setBOMItems] = useState([]);
 const [viewmodal, setViewModal] = useState();
 const [singlefile, setSingle] = useState([]);
 console.log("singlefile", singlefile);

 const viewBOM = n => {
    setViewModal(!viewmodal)
  }

 useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
  }, []);

 useEffect( () => {
    getBoms(group_id)
    .then(resp => setBoms(resp.data))
  }, []);

 useEffect( () => {
    getBomItems(group_id)
    .then(resp => setBOMItems(resp.data))
 }, []);

 const handleUploadBom = () => {
   setBomFile(document.getElementById('filebom').files[0]);
 }

 const handleSubmit = () => {
      const formData = new FormData()
      formData.append( 'file', bomfile )

      const data = {
        project_id: selectedProject,        
        client_id: ProjectClientId,
        user_id: user.id,
        group_id: group_id,
        type_val: typeval,
      } 
      console.log("data", data);

      setIsLoading(true);
      if (data) {
        setTimeout(() => {  

          BulkUploadIBOM(formData)
          .then(resp => { 
            runIBOMScript(data)
            .then(resp => {
              setSingle(resp.data)
              setProjectVal(null)
              setTypeVal(null)
              setBomFile([])
              //viewBOM()
            })
          })
          setIsLoading(false)
        }, 5000);
      }
      //toast.success("File has been uploaded successfully!!!", {position: toast.POSITION.TOP_CENTER})
  }
  // let bomsObj = {};
  // bomsObj = (boms || []).sort((a,b) => {
  //   return b.id-a.id;
  // });
  // console.log("bomsObj", bomsObj[0]);
  // let mainBom = bomsObj[0];
  // const bomItems = bom_items.filter(x=>x.bom_id === mainBom.id );
  // console.log("bomItems", bomItems);

	return (
        <Fragment>
         <div className="container-fluid">
          {isLoading ? (
		      <Backdrop className={classes.circular} open={isLoading}>
		        <CircularProgress color="inherit" />
		      </Backdrop>
	      ) : (
            <div className="row">
             <div className="col-sm-12 col-xl-6">
              {/*
                <div className="card card-absolute">
                    <div className="card-header bg-secondary">
                        <h5>Upload BOM</h5>
                    </div>
                    <div className="card-body">
              */}
              <div className="ribbon-wrapper card">
                <div className="card-body">
                  <div className="ribbon ribbon-bookmark ribbon-secondary">Upload BOM</div>
                     <div className="col-md-12">
                      <Autocomplete
			              freeSolo
			              options={projects}
			              getOptionLabel={option => option.name}
			              value={project_val}                      
			              onChange={(event, newValue) => {
			                setProjectVal(newValue);
			              }}            
			              renderInput={params => (
			                <TextField {...params} label="Project" margin="normal" variant="outlined" helperText="Please select Project" fullWidth />
			              )}
		              />
		             </div>
		             <div className="col-md-12">
		              <Autocomplete
			              freeSolo
			              options={bomtype}
			              getOptionLabel={option => option.name}
			              value={typeval}                      
			              onChange={(event, newValue) => {
			                setTypeVal(newValue);
			              }}            
			              renderInput={params => (
			                <TextField {...params} label="Type" margin="normal" variant="outlined" helperText="Please select BOM Type" fullWidth />
			              )}
			            />
		             </div>
		             <div className="col-md-12">
		              <label className="col-form-label text-body" htmlFor="message-text">Attach BOM File:</label>
                      <TextField id="filebom" type="file" margin="dense" variant="outlined" onChange={handleUploadBom} fullWidth/>
                     </div>
                    </div>
                    <div className="card-footer text-center">
                      <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
             </div>
            </div>
            )}
          </div> 
        
        {/* < !-- Using Modal for Viewing BOM Items --> */}
            <Modal isOpen={viewmodal} toggle={viewBOM} className="modal-dialog modal-xl">
                <ModalHeader toggle={viewBOM}>View BOM Items</ModalHeader>   
                <ModalBody>
                   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Sl.No</th>
                                                    <th scope="col">Document No</th>
                                                    <th scope="col">Item Class</th>
                                                    <th scope="col">Part No</th>
                                                    <th scope="col">Part Name</th>
                                                    <th scope="col">Function</th>
                                                    <th scope="col">Item Type</th>
                                                    <th scope="col">Material</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Finish Size</th>
                                                    <th scope="col">Remarks</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bom_items.map((n)=> {
                                                  return (
                                                    <tr>
                                                     <td>{n.sl_no}</td>
                                                     <td>{n.document_no}</td>
                                                     <td>{n.item_class}</td>
                                                     <td>{n.part_num}</td>
                                                     <td>{n.part_name}</td>
                                                     <td>{n.function}</td>
                                                     <td>{n.item_type}</td>
                                                     <td>{n.material}</td>
                                                     <td>{n.qty}</td>
                                                     <td>{n.finish_size}</td>
                                                     <td>{n.remarks}</td>
                                                     <td>
                                                      <button className="btn btn-info btn-xs" type="button">
                                                       <i className="icofont icofont-edit"></i>
                                                      </button>
                                                      <button className="btn btn-danger btn-xs" type="button">
                                                       <i className="fa fa-trash-o"></i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={viewBOM}>Add BOM Items</Button>
                    <Button color="secondary" onClick={viewBOM}>Close</Button>
                </ModalFooter>
            </Modal>
          </Fragment>
    );
}
export default MyBOM;