import React, { Fragment } from 'react';
import { TextField, Typography, Grid, FormHelperText,Radio, RadioGroup, 
         FormControl, FormControlLabel } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SalaryDeductions = (props) => {

    const {standardDeduction, professionTax, month_rent, leastHRA, totalSalaryDeductions} = props
    
    return(
     <Fragment>
       <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-primary text-center">
                                            <tr>
                                                <th scope="col">Component</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            <tr>
                                               <th>Standard Deduction</th>
                                               <th>{standardDeduction}</th> 
                                            </tr>
                                            <tr>
                                               <th>Profession Tax</th>
                                               <th>{professionTax}</th> 
                                            </tr>
                                            <tr>
                                               <th>Actual Monthly Rent</th>
                                               <th>
                                                    <TextField
                                                        name="month_rent"
                                                        value={month_rent}
                                                        onChange={(e) => props.setMonthRent(e.target.value)}
                                                    />    
                                               </th>
                                            </tr>
                                            <tr>
                                               <th>House Rent Allowance</th>
                                               <th>{leastHRA}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 text-center">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12"></div>      
                                <div className="col-xl-12 m-t-10">
                                    <h4 className="text-success">Total Salary Deductions for the FY: {totalSalaryDeductions}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
     </Fragment>
    );
}

export default SalaryDeductions;