import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getVendorForm, getIndentItems, getProjects, getGroup } from '../../actions/asyncActions';
import * as moment from 'moment';

export default function MaterialTableDemo(props) {
  
  //const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  var numberToText = require('number2text');

  const { selectedVendor, rupees } = props;
  const rupeesInWords = numberToText(rupees,'', true);
  const datee = new Date();
  const currentDate = moment(datee).format('DD MM YYYY')
  const dateSplit = currentDate.toString().split(" ").join("");
  const newDate = dateSplit.split('').join(' ');

  return (
    <div id="printreceipt">
     <section className="sheet padding-5mm">
      <div style={{width:'20.32cm', height:'9.32cm'}}>
        {/*
        <span style={{top: '1.0cm', left: '9.0cm', borderBottom:'1 px solid', borderTop:'1 px solid', position: 'absolute'}}>
         Acc. Payee
        </span>
        */}
        <span style={{top: '0.7cm', left: '15.7cm', position: 'absolute'}}>
         {newDate}
        </span>
        <span style={{top: '2.0cm', left: '2.0cm', position: 'absolute'}}>
         {selectedVendor}
        </span>
        <span style={{top: '3.0cm', left: '3.0cm', position: 'absolute'}}>
         {rupeesInWords}
        </span>
        <span style={{top: '3.5cm', left: '16.2cm', position: 'absolute'}}>
         {rupees}
        </span>
      </div>
     </section>
    </div>
  );
}