import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { UncontrolledTooltip } from 'reactstrap';
import { TextField, FormHelperText, TablePagination } from '@material-ui/core';
import { getVendorForm, getLogs, postLogs, 
  getComCategory, getComComponents, postComComponents, deleteComComponents } from '../../../actions/asyncActions';

const Components = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const [values, setValues] = useState({});

    const [category, setCategory] = useState([]);
    const [components, setComponents] = useState([]);

    const [dept_val, setDeptVal] = useState(null);
    const deptId = dept_val && dept_val.id;
    const deptName = dept_val && dept_val.name;

    //const [errors, setError] = React.useState({});
    const [vendorForm, setVendors] = useState([]);
    
    const [securityLogs, setSecurityLogs] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
      getVendorForm(group_id)
      .then(resp => setVendors(resp.data))
    }, []);

    useEffect( () => {
      getLogs(group_id)
      .then(resp => setSecurityLogs(resp.data))
    }, []);

    useEffect( () => {
      getComCategory(group_id)
      .then(resp => setCategory(resp.data))
    }, []);

    useEffect( () => {
      getComComponents(group_id)
      .then(resp => setComponents(resp.data))
    }, []);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const validateForm = (data) => {
    const errors = [];
    if(data.emp_id === data.mgr_id){
      errors.mgr_id = "Cannot select Employee as Manager";
     }
     return errors;
  }

  const deleteRow = n => {
    const data = {
      id: n.id
    }
    deleteComComponents(data)
    .then(resp => {
      getComComponents(group_id)
      .then(resp => setComponents(resp.data))
      toast.warn("Component deleted successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 2000})
    })
  }

  const handleSubmit = () => {
    const users = JSON.parse(localStorage.getItem("m_users"));
    const group_id = users.group_id;
    const data = {
      name: values.name,
      category_id: deptId,
      user_id: users.id,
      group_id: group_id,
    }
    if(!values.name || !deptId){
      toast.warning("Component or Category is missing !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
    }
    else{
      postComComponents(data)
      .then(resp => {
          getComComponents(user.group_id)
          .then(resp => setComponents(resp.data))
          setValues({name:''});
          setDeptVal('');
          toast.success("Component added successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 2000})
        })
        .catch(err => {
          console.log("Error", err);
      })
    }
  }

        return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-5">
                     <div className="card card-absolute">
                        <div className="card-header bg-secondary">
                            <h5>Add Components</h5>
                        </div>
                        <div className="card-body">
                         <div className="col-md-12">
                          <label className="col-form-label text-body" htmlFor="message-text">Component:</label> 
                          <input className="form-control" name="name" type="text" 
                            value={values.name} onChange={handleChange}/>
                         </div>
                         <div className="col-md-12">
                          <Autocomplete
                            freeSolo
                            options={category}
                            getOptionLabel={option => option.name}
                            value={dept_val}                      
                            onChange={(event, newValue) => {
                              setDeptVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params} label="Category" margin="normal" variant="outlined" helperText="Please select Category" fullWidth />
                            )}
                          />
                         </div>
                         <div className="col-md-12 text-center m-t-15">
                          <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Submit</button>
                         </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-7 m-t-20">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-info">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Component</th>
                                                  <th scope="col">Category Name</th>
                                                  <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {components.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  const categor = category.find(x=>x.id === n.category_id)
                                                  const deptName = categor && categor.name
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.name}</td>
                                                     <td>{deptName}</td>
                                                     <td>
                                                     <i className="fa fa-trash-o" onClick={() => deleteRow(n)} id="TooltipTop" data-placement="top"
                                                        style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
                                                        <UncontrolledTooltip placement="top" target="TooltipTop">
                                                            Delete components
                                                        </UncontrolledTooltip>
                                                     </i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={components.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        );
}

export default Components;