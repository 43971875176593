import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, Grid, Table, TableHead, TableCell, TableRow,
TextField, FormGroup, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Audiotrack } from '@material-ui/icons';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { aadharUpload, panUpload, bankUpload, pfUpload, postEmployee, passportUpload, 
    experienceUpload, relieveUpload, tenthUpload, twelthUpload, degreeUpload, 
    contractUpload, getEmployee, editUsers, postUsers, sendInviteVendor }  from '../../../actions/asyncActions';
import Breadcrumb from '../../../components/common/breadcrumb';
import { toast } from 'react-toastify';
import Personal from './employeeStep/Personal';
import Education from './employeeStep/Education';
import OtherDetails from './employeeStep/OtherDetails';
import EmployeeDetails from './employeeStep/EmployeeDetails';
import Leaves from './employeeStep/Leaves';
import BankAccount from './employeeStep/BankAccount';
import EmployeeMaster from './EmployeeMaster2';

const useStyles = makeStyles(theme => ({

  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
    },
    // backgroundColor: '#c9918d'
    backgroundColor: '#e4e8ff' 
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    backgroundColor: '#e4e8ff'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    margin: theme.spacing(2),
  },
}));

const steps = ['Personal', 'Education', 'Other', 'Employee', 'Leaves', 'Bank Account'];
const steps2 = ['Personal', 'Education', 'Other', 'Employee', 'Bank Account'];


export default function Checkout(props) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const userRole = user.role;
  const getThisEmpId = user.emp_id; // Get Employee Id from the user table

  const [employee, setEmployee] = useState([]);
  let empObj = {};
  empObj = (employee || []).sort((a,b) => {
    return b.id-a.id;
  });
  var empId = empObj ? (empObj && empObj[0] || []).employee_id : null;
  var foundLen = employee ? employee.length : 0;
  console.log("foundLen", foundLen)
  var str2 = "NU-";
  var str = foundLen === 0 ? "NU-0000" : empId;
  var res = str.split('-');
  var Num = res[1];
  var incrementValue = (+Num) + 1;
  incrementValue = ("0000" + incrementValue).slice(-4);
  var employeeId = str2 + incrementValue
  console.log("employeeId", employeeId)

  const [dob, setDob] = useState()
  const [finance_start, setFinanceStart] = useState()
  const [finance_end, setFinanceEnd] = useState()
  const [errors, setError] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStep2, setActiveStep2] = React.useState(0);
  

  const [values, setValues] = useState({
    name: userRole === 'Employee'? user.firstname : '',
    phone: userRole === 'Employee'? user.phone : '',
    email: userRole === 'Employee'? user.email : ''
  });
  console.log("values", values);
  const datee = new Date();
  const [panImage, setPan] = useState();
  const [adharImage, setAadhar] = useState();
  const [passportImage, setPassPortImage] = useState();
  const [pfImage, setPf] = useState();
  const [expLetter, setExperience] = useState();
  const [relLetter, setRelieving] = useState();
  const [contract_file, setContract] = useState();
  const [bankImage, setBank] = useState();
  const [tenth_file, setTenth] = useState();
  const [twelth_file, setTwelth] = useState();
  const [degree_file, setDegree] = useState();
  
  const [radioVal, SetRadio] = React.useState({ gender: '' });
  
  const [subform, setSubForm] = React.useState(false);

  
  useEffect( () => {
    getEmployee(user.group_id)
    .then(resp => setEmployee(resp.data))
  }, [])

  const handleSubFormClose = () => {
    setSubForm(false);
    if(userRole === 'Employee'){
      setActiveStep2(activeStep2 - (steps2.length-1));
      props.history.push(`${process.env.PUBLIC_URL}/employeeform`)
      window.location.reload()
    }
    else{
      setActiveStep(activeStep - (steps.length-1));
      props.history.push(`${process.env.PUBLIC_URL}/employee`)
    }
  };

  const handleNext = () => {
    const errors = validateForm(values);
     if(Object.keys(errors).length) {
      setError(errors);
      return;
     }
     else{
      setError({})
      setActiveStep(activeStep + 1);   
     }
    
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext2 = () => {
    const errors = validateForm(values);
     if(Object.keys(errors).length) {
      setError(errors);
      return;
     }
    setActiveStep2(activeStep2 + 1);
  };

  const handleBack2 = () => {
    setActiveStep2(activeStep2 - 1);
  };
  
  const validateForm = (data) => {
    var phoneLen = data.phone;
    var phoneLength = phoneLen ? phoneLen.length : 0;
    var phoneno = /^\d{10}$/;
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
     const errors = [];
     if(activeStep === 0 || activeStep2 === 0){
     if(!data.name) {
       errors.name = "Employee name is required";
     }
     if(!data.father) {
        errors.father = "Father name is required";
      }
     if(!data.phone) {
       errors.phone = "Mobile number is required";
     }
     if(!(data.phone.match(phoneno))){
      errors.phone = "Invalid Mobile Number";
     }
     if(!data.email) {
       errors.email = "Email Id is required";
     }
     if(!(data.email.match(mailformat))){
      errors.email = "Invalid email address";
     }
    } else if(activeStep === 2 || activeStep2 === 2){
     if(!data.panno){
      errors.panno = "PAN number is required";
     }
   }
     return errors;
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleRadioChange = event => {
    SetRadio({
      ...radioVal,
      [event.target.name]: event.target.value
    });
  };

  const handleuploadPAN = async (event) => {
    setPan(document.getElementById('files').files[0]);
  }

  const handleUploadAadhar = async (event) => {
    setAadhar(document.getElementById('file').files[0]);
  }

  const handleUploadPf = async (event) => {
    setPf(document.getElementById('filepf').files[0]);
  }

  const handleUploadPassPort = async (event) => {
    setPassPortImage(document.getElementById('fileppt').files[0]);
  } 

  const handleUploadExperience = async (event) => {
    setExperience(document.getElementById('filesexp').files[0]);
  }

  const handleUploadRelieving = async (event) => {
    setRelieving(document.getElementById('filesrel').files[0]);
  }

  const handleUploadContract = async (event) => {
    setContract(document.getElementById('files_contract').files[0]);
  }

  const handleUploadBank = async (event) => {
    setBank(document.getElementById('filess').files[0]);
  }

  const handleUploadTenth = async (event) => {
    setTenth(document.getElementById('file_tenth').files[0]);
  }

  const handleUploadTwelth = async (event) => {
    setTwelth(document.getElementById('file_twelth').files[0]);
  }

  const handleUploadDegree = async (event) => {
    setDegree(document.getElementById('file_degree').files[0]);
  }
  
  const getStepContent = (step) => {
    switch (step) {
        case 0: 
          return <Personal handleChange={handleChange} values={values} errors={errors}
          dob={dob} setDob={setDob} radioVal={radioVal} handleRadioChange={handleRadioChange}/>;
        case 1:
          return <Education tenth_file={tenth_file} handleUploadTenth={handleUploadTenth} 
          twelth_file={twelth_file} handleUploadTwelth={handleUploadTwelth} 
          degree_file={degree_file} handleUploadDegree={handleUploadDegree} />;
        case 2:
          return <OtherDetails handleChange={handleChange} values={values} errors={errors} 
          panImage={panImage} handleuploadPAN={handleuploadPAN} 
          adharImage={adharImage} handleUploadAadhar={handleUploadAadhar}
          passportImage={passportImage} handleUploadPassPort={handleUploadPassPort}
          pfImage={pfImage} handleUploadPf={handleUploadPf}/>;
        case 3:
          return <EmployeeDetails handleChange={handleChange} values={values} errors={errors}
          expLetter={expLetter} handleUploadExperience={handleUploadExperience}
          relLetter={relLetter} handleUploadRelieving={handleUploadRelieving}
          contract_file={contract_file} handleUploadContract={handleUploadContract} />;
        case 4:
          return <Leaves values={values} errors={errors} handleChange={handleChange}
          finance_start={finance_start} setFinanceStart={setFinanceStart}
          finance_end={finance_end} setFinanceEnd={setFinanceEnd} />;
        case 5:
          return <BankAccount handleChange={handleChange} values={values} bankImage={bankImage} 
          handleUploadBank={handleUploadBank} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const getStepContent2 = (step) => {
    switch (step) {
        case 0: 
          return <Personal handleChange={handleChange} values={values} errors={errors}
          dob={dob} setDob={setDob} radioVal={radioVal} handleRadioChange={handleRadioChange}/>;
        case 1:
          return <Education tenth_file={tenth_file} handleUploadTenth={handleUploadTenth} 
          twelth_file={twelth_file} handleUploadTwelth={handleUploadTwelth} 
          degree_file={degree_file} handleUploadDegree={handleUploadDegree} />;
        case 2:
          return <OtherDetails handleChange={handleChange} values={values} errors={errors} 
          panImage={panImage} handleuploadPAN={handleuploadPAN} 
          adharImage={adharImage} handleUploadAadhar={handleUploadAadhar}
          passportImage={passportImage} handleUploadPassPort={handleUploadPassPort}
          pfImage={pfImage} handleUploadPf={handleUploadPf}/>;
        case 3:
          return <EmployeeDetails handleChange={handleChange} values={values} errors={errors}
          expLetter={expLetter} handleUploadExperience={handleUploadExperience}
          relLetter={relLetter} handleUploadRelieving={handleUploadRelieving}
          contract_file={contract_file} handleUploadContract={handleUploadContract} />;
        case 4:
          return <BankAccount handleChange={handleChange} values={values} bankImage={bankImage} 
          handleUploadBank={handleUploadBank} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleSubmit = async(event) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const formData = new FormData()
    formData.append( 'file', adharImage )
    const aadhar = adharImage ? await aadharUpload(formData) : null;
    const aadharpath = aadhar ? "/" + aadhar.data.filename : null;

    const formData2 = new FormData();
    formData2.append( 'file', panImage );
    const pan = panImage ? await panUpload(formData2) : null
    const panpath = pan ? "/" + pan.data.filename : null;

    const formData3 = new FormData();
    formData3.append( 'file', bankImage );
    const bank = bankImage ? await bankUpload(formData3) : null
    const bankpath = bank ? "/" + bank.data.filename : null;

    const formData4 = new FormData();
    formData4.append( 'file', pfImage );
    const pff = pfImage ? await pfUpload(formData4) : null
    const pfpath = pff ? "/" + pff.data.filename : null;

    const formData5 = new FormData();
    formData4.append( 'file', passportImage );
    const pass = passportImage ? await passportUpload(formData4) : null;
    const pptpath = pass ? pass.data.filename : null;
    const passportpath = pptpath ? "/Data/Employee/" + pptpath : null;

    const formData6 = new FormData();
    formData6.append( 'file', expLetter );
    const exp = expLetter ? await experienceUpload(formData6) : null
    const expath = exp ? exp.data.filename : null;
    const exppath = expath ? "/Data/Employee/" + expath : null

    const formData7 = new FormData();
    formData7.append( 'file', relLetter );
    const rel = relLetter ? await relieveUpload(formData7) : null
    const repath = rel ? rel.data.filename : null
    const relpath = repath ? "/Data/Employee/" + repath : null

    const formData8 = new FormData();
    formData8.append( 'file', tenth_file );
    const tenth = tenth_file ? await tenthUpload(formData8) : null
    const tenpath = tenth ? tenth.data.filename: null;
    const tenthpath = tenpath ? "/Data/Employee/" + tenpath : null

    const formData9 = new FormData();
    formData9.append( 'file', twelth_file );
    const twelth = twelth_file ? await twelthUpload(formData9) : null
    const twelpath = twelth ? twelth.data.filename : null;
    const twelthpath = twelpath ? "/Data/Employee/" + twelpath : null

    const formData10 = new FormData();
    formData10.append( 'file', degree_file );
    const degree = degree_file ? await degreeUpload(formData10) : null
    const degpath = degree ? degree.data.filename : null;
    const degreepath = degpath ? "/Data/Employee/" + degpath : null

    const formData11 = new FormData();
    formData11.append( 'file', contract_file );
    const contract = contract_file ? await contractUpload(formData11) : null
    const contpath = contract ? contract.data.filename : null
    const contractpath = contpath ? "/Data/Employee/" + contpath : null

    const userdata = {
      employee_id: employeeId,  
      name: values.name,
      father: values.father,
      phone: values.phone,
      email: values.email,
      gender: radioVal.gender,
      dob: dob,
      emer_name: values.emer_name,
      emer_num: values.emer_num,
      address: values.address,
      tenth: tenthpath,
      twelth: twelthpath,
      degree: degreepath,
      panno: values.panno,
      pan: panpath,
      aadharno: values.aadharno,
      aadhar: aadharpath,
      passportno: values.passport,
      passport: passportpath,  
      pfno: values.pfno,
      pff: pfpath,
      company_name: values.company_name,
      period: values.period,
      com_designation: values.com_designation,
      exp_letter: exppath,
      rel_letter: relpath,
      contract: contractpath,
      bankname: values.bankname,
      accno: values.accno,
      accname:values.accname,
      ifsc:values.ifsc,
      bank: bankpath,
      med_leave: values.med_leave,
      cas_leave: values.cas_leave,
      maternity_leave: values.maternity_leave,
      finance_start: finance_start,
      finance_end: finance_end,
      status: 'Submitted',
      user_id: user.id,
      mgr_id: user.mgr_id,
      group_id: group_id
    }
    console.log("userdata", userdata);
    postEmployee(userdata)
    .then(resp => {
      if(userRole === 'Employee'){
        const data = {
          id: user.id,
          emp_id: resp.data.id
        }
        editUsers(data)
        .then(resp => {
          //props.history.push(`${process.env.PUBLIC_URL}/employeeform`)
          setSubForm(true)
          setValues({name:'', father:'', phone:'', email:'', emer_name:'', emer_num:'', address:'' })
          //props.history.push(`${process.env.PUBLIC_URL}/employee`)
        })
      } 
      else {
        const userdata = {
          firstname: values.name,
          username: values.email,
          password: values.phone,
          email: values.email,
          phone: values.phone,
          department: 'HR',
          role: 'Employee',
          emp_id: resp.data.id,
          group_id: group_id
        }
        const sendEmail = {
          fname: values.firstname,  
          email: values.email,
          phone: values.phone,
          type: 'Employee'
        }
        postUsers(userdata)
        .then(resp => {
          sendInviteVendor(sendEmail)
          .then(resp => {
            setValues({name:'', father:'', phone:'', email:'', emer_name:'', emer_num:'', address:'' })
            props.history.push(`${process.env.PUBLIC_URL}/employee`)
            toast.success("Form entered successfully !!!", {position: toast.POSITION.TOP_CENTER})
          })
        })
      }
    })
    .catch(err => {
    console.log("Error", err);
    })
  }
  
  const foundVendor = employee.find(x=>x.id === getThisEmpId);
  const foundVendorId = foundVendor ? foundVendor && foundVendor.id : null

  return (
    <React.Fragment>
      <Breadcrumb title="Complete Form" parent="Employee" />
      <CssBaseline />
      <main className={classes.layout}>
        { getThisEmpId ?
           <EmployeeMaster />
          :
          userRole === 'Employee' ?
          <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center" >
            <b><i>EMPLOYEE FORM CREATION</i></b>
          </Typography>
          <Stepper activeStep={activeStep2} className={classes.stepper} alternativeLabel>
            {steps2.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep2 === steps2.length ? (
              <React.Fragment>
                <Typography align="center" variant="h5" gutterBottom>
                  <Audiotrack color="primary"/> Form is Successfully submitted <Audiotrack color="primary"/>
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent2(activeStep2)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack2} round variant="outlined" className={classes.button}>
                      Prev
                    </Button>
                  )}
                  {activeStep2 === steps2.length - 1 ?
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.button}
                    round
                  >
                    Submit the form
                  </Button>
                  :
                  <Button
                    variant="contained"
                    color="primary"
                    round
                    onClick={handleNext2}
                    className={classes.button}
                  >
                    Next
                  </Button>
                }
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
          :
          <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center" >
            <b><i>EMPLOYEE FORM CREATION</i></b>
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography align="center" variant="h5" gutterBottom>
                  <Audiotrack color="primary"/> Form is Successfully submitted <Audiotrack color="primary"/>
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} round variant="outlined" className={classes.button}>
                      Prev
                    </Button>
                  )}
                  {activeStep === steps.length - 1 ?
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.button}
                      round
                    >
                      Submit the form
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="primary"
                      round
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  }
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        }
      </main>
      <React.Fragment>
      
      {/** Form Submit Model **/}
      <Dialog 
        open={subform} 
        onClose={handleSubFormClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick="true"
        disableEscapeKeyDown="true"
      >
      <DialogContent>
        <Typography align="center">
          <CheckCircleOutlineRoundedIcon style={{fontSize: '100px', color:'#4bb543'}}/>
        </Typography>
        <Grid xs={12} sm={12}>
        <Typography component="h2" variant="h6" align="center" gutterBottom>
          Thank you for submitting the form. You can check the status on your Dashboard...
        </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubFormClose} color="primary" size="sm" round>
          Close
        </Button>
      </DialogActions>
      </Dialog>
    </React.Fragment>  
    </React.Fragment>
  );
}