import React, {Fragment,  useState} from 'react';
import {TextField, InputAdornment} from '@material-ui/core';

export default function Deductions(props) {
    const {deductionData, deduction_values} = props;
    
    return(
        <Fragment>
           <div className="container-fluid">
                <div className="card">
                    <div className="card-block row">
                        <div className="col-sm-12 col-lg-12 col-xl-12">
                            <div className="table-responsive">
                                <table className="table table-sm table-bordered table-hover">
                                    <thead className="table-primary">
                                        <tr>
                                            <th scope="col">Components</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">LOP</th>
                                            <th scope="col">Working Days</th>
                                            <th scope="col">Net Salary</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deductionData && deductionData.map((n,i) => {
                                            // const netSal = values[i] ? 
                                            // parseFloat(values[i].monthly) * (parseFloat(values[i].tot_days) - parseFloat(values[i].lop)) / 31
                                            // : null;
                                            // const netSalary = Math.round((netSal + Number.EPSILON) * 100) / 100;
                                            return(
                                                <tr key={i}>
                                                    <td>{n.component}</td>
                                                    <td>{n.monthly}</td>
                                                    <td>{n.lop}</td>
                                                    <td>{n.tot_days}</td>
                                                    <td>
                                                    <TextField
                                                        key={i}
                                                        name="net_salary"
                                                        type="number"
                                                        value = {deduction_values[i] ? deduction_values[i].net_salary: null}
                                                        onChange={(e) => props.handleDeductionChange(e,i,n)} 
                                                    />
                                                    </td>
                                                </tr>
                                            );
                                        })

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </Fragment>
    );
}