import React, { Fragment } from 'react';
import { TextField, Typography, Grid, FormHelperText,Radio, RadioGroup, 
         FormControl, FormControlLabel } from '@material-ui/core';
import {UncontrolledTooltip } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ChapterVIA = (props) => {

    const {values} = props
    
    return(
     <Fragment>
       <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-xl-12">
                                <div className="form-row ml-1 mr-1">
                                    <div className="col-xl-6">
                                        <label className="col-form-label" htmlFor="message-text">
                                            <b>Basic Deductions - 80C
                                            <i className="fa fa-info-circle" id="TooltipTop" data-placement="top"
                                                style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
                                                <UncontrolledTooltip placement="top" target="TooltipTop">
                                                    Amount invested/paid in tax saving instruments such as PPF, ELSS mutual funds, LIC Premium, etc.,
                                                </UncontrolledTooltip>
                                            </i>
                                            </b>
                                        </label>
                                        <TextField name="deductions_80_c" value={values.deductions_80_c} onChange={props.handleChange} 
                                         variant="outlined" margin="dense" type="number" fullWidth/>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="col-form-label" htmlFor="message-text">
                                            <b>Interest from Deposits - 80TTA
                                            <i className="fa fa-info-circle" id="TooltipTop1" data-placement="top"
                                                style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
                                                <UncontrolledTooltip placement="top" target="TooltipTop1">
                                                    Amount of interest income on deposits in savings account (includes fixed/recurring deposit interest in case of senior citizen)
                                                </UncontrolledTooltip>
                                            </i>
                                            </b>
                                        </label>
                                        <TextField name="deductions_80_tta" value={values.deductions_80_tta} onChange={props.handleChange} 
                                         variant="outlined" margin="dense" type="number" fullWidth/>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="col-form-label" htmlFor="message-text">
                                            <b>Eligible Donations - 80G
                                            <i className="fa fa-info-circle" id="TooltipTop3" data-placement="top"
                                                style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
                                                <UncontrolledTooltip placement="top" target="TooltipTop3">
                                                Amount paid as donation to charitable insitutions or certain recognized funds
                                                </UncontrolledTooltip>
                                            </i>    
                                            </b>
                                        </label>
                                        <TextField name="deductions_80_g" value={values.deductions_80_g} onChange={props.handleChange} 
                                         variant="outlined" margin="dense" type="number" fullWidth/>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="col-form-label" htmlFor="message-text">
                                            <b>Interest on Educational Loan - 80E
                                            <i className="fa fa-info-circle" id="TooltipTop4" data-placement="top"
                                                style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
                                                <UncontrolledTooltip placement="top" target="TooltipTop4">
                                                Amount of interest paid on loan taken for higher education
                                                </UncontrolledTooltip>
                                            </i>   
                                            </b>
                                        </label>
                                        <TextField name="deductions_80_e" value={values.deductions_80_e} onChange={props.handleChange} 
                                         variant="outlined" margin="dense" type="number" fullWidth/>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="col-form-label" htmlFor="message-text">
                                            <b>Interest on Housing Loan - 80EEA
                                            <i className="fa fa-info-circle" id="TooltipTop5" data-placement="top"
                                                style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
                                                <UncontrolledTooltip placement="top" target="TooltipTop5">
                                                Amount of interest paid on housing loan sanctioned during FY 2020-21
                                                </UncontrolledTooltip>
                                            </i>
                                            </b>
                                        </label>
                                        <TextField name="deductions_80_eea" value={values.deductions_80_eea} onChange={props.handleChange} 
                                         variant="outlined" margin="dense" type="number" fullWidth/>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="col-form-label" htmlFor="message-text">
                                            <b>Employees Contribution to NPS - 80CCD
                                            <i className="fa fa-info-circle" id="TooltipTop6" data-placement="top"
                                                style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
                                                <UncontrolledTooltip placement="top" target="TooltipTop6">
                                                Includes voluntary contribution to National Pension Scheme (NPS) under section 80CCD(1) and 80CCD(1B)
                                                </UncontrolledTooltip>
                                            </i>
                                            </b>
                                        </label>
                                        <TextField name="deductions_80_ccd" value={values.deductions_80_ccd} onChange={props.handleChange} 
                                         variant="outlined" margin="dense" type="number" fullWidth/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </Fragment>
    );
}

export default ChapterVIA;