import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { TablePagination, Typography } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import PrintPO from './reprint.js';
import { getProjects, getClients, getVendorForm, getPorders, getPOItems,
         getItemLone, getItemLtwo, getItemLthree, getItemLfour, getItemLfive, } from '../../actions/asyncActions';

const IssuedPO = () => {
	const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const vendId = user.vendor_id;

    const elementStyle = {
        border:'solid',
        borderRadius:'10px',
        position:'relative',
        left:'1vh',
        height:'6vh',
        width:'23vh',
        marginTop:'1vh',
        marginBottom:'1vh'
    }

    const initialFormState = { id: '', name:'', lfour_id:'', lthree_id:'', ltwo_id:'', lone_id:'' }
	const [ data, setCurrentData ] = useState(initialFormState);

  const [porders, setPOrders] = useState([]);
  const [poitems, setPOItems] = useState([]);
  const [projects, setProject] = useState([]);
  const [ clients, setClient ] = useState([]);
  const [vendorForm, setVendors] = useState([]);

  const [lone, setLone] = useState([]);
	const [ltwo, setLtwo] = useState([]);
	const [lthree, setLthree] = useState([]);
	const [lfour, setLfour] = useState([]);
	const [lfive, setLfive] = useState([]);

	const filter_porders = porders.filter(s => s.status === 'POSubmitted' && s.vendor_id === vendId);

	const [modal, setModal] = useState();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

	useEffect( () => {
	    getProjects(group_id)
	    .then(resp => setProject(resp.data))
	}, []);

    useEffect( () => {
      getClients(group_id)
      .then(resp => setClient(resp.data))
    }, []);

    useEffect( () => {
      getVendorForm(group_id)
      .then(resp => setVendors(resp.data))
    }, []);

    useEffect( () => {
	    getPorders(group_id)
	    .then(resp => setPOrders(resp.data))
    }, []);

    useEffect( () => {
	    getPOItems(group_id)
	    .then(resp => setPOItems(resp.data))
    }, []);

    useEffect( () => {
        getItemLone(group_id)
         .then(resp => {
        setLone(resp.data);
      })
    }, []);

    useEffect( () => {
	    getItemLtwo(group_id)
	    .then(resp => {
	      setLtwo(resp.data);
	    })
    }, []);

    useEffect( () => {
	    getItemLthree(group_id)
	    .then(resp => {
	      setLthree(resp.data);
	    })
    }, []);

    useEffect( () => {
	    getItemLfour(group_id)
	    .then(resp => {
	      setLfour(resp.data);
	    })
    }, []);

    useEffect( () => {
	    getItemLfive(group_id)
	    .then(resp => {
	      setLfive(resp.data);
	    })
    }, []);

    const [selId, setSelectedID] = React.useState([]);
    const filter_poItems = poitems.filter(x => x.po_no === selId);
    
  const viewRow = porder => {
    setSelectedID(porder.id);
    setModal(!modal);
    const projectId = (projects || []).find(x=> x.id === porder.project_id);
    const projectName = projectId && projectId.name;
    const clientId = (clients || []).find(x=> x.id === porder.client_id);
    const clientName = clientId && clientId.name;

    const lev5 = (lfive || []).find(x=> x.id == porder.l5);
    const leve5 = lev5 && lev5.name;
    const lev4 = (lfour || []).find(x=> x.id == porder.l4);
    const leve4 = lev4 && lev4.name;
    const lev3 = (lthree || []).find(x=> x.id == porder.l3);
    const leve3 = lev3 && lev3.name;
    const lev2 = (ltwo || []).find(x=> x.id == porder.l2);
    const leve2 = lev2 && lev2.name;
    const lev1 = (lone || []).find(x=> x.id == porder.l1);
    const leve1 = lev1 && lev1.name;
    
    setCurrentData({id: porder.id, part_num:porder.part_num, l1:porder.l1 , l2:porder.l2, l3:porder.l3, l4:porder.l4, l5:porder.l5, 
     material:porder.material, finish_size:porder.finish_size, qty: porder.qty, price: porder.price, date: porder.date, remarks: porder.remarks, 
     discount: porder.discount, client_id:clientName, user_id: porder.user_id, group_id: porder.group_id,project_id: projectName,
     vendor_id: porder.vendor_id, po_no: porder.po_no, psupply: porder.psupply, dispatch: porder.dispatch, status: porder.status})
  }

  const [selectedPO, setSelectedPO] = React.useState([]);
  const filterPOItems = poitems.filter(x => x.po_no === selectedPO);
  console.log("filterPOItems", filterPOItems)
  const selectedProject = (filterPOItems.length !== 0) ? (filterPOItems && filterPOItems[0].project_id) : null
  const selectedVendor = (filterPOItems.length !== 0) ? (filterPOItems && filterPOItems[0].vendor_id) : null
  const placeSupply = (filterPOItems.length !== 0) ? (filterPOItems && filterPOItems[0].psupply) : null
  const dispatch = (filterPOItems.length !== 0) ? (filterPOItems && filterPOItems[0].dispatch) : null

  const [printmodalopen, setPrintModalOpen] = useState();
  const printView = (n) => {
    setSelectedPO(n.id);
    setPrintModalOpen(!printmodalopen)
  }

  const openPrint = () => {
    handlePrintPO()
    setPrintModalOpen(!printmodalopen) 
  }


  const handlePrintPO = () => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(`<html><head><title>${document.title}</title>
     <style>
     @page { margin: 0 }
      body { margin: 0 }
      .sheet {
      margin: 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      page-break-after: always;
      }

      /* Padding area */
      .sheet.padding-5mm { padding: 5mm }

      /* For screen preview */
      @media screen {
      body { background: #e0e0e0 }
        .sheet {
         background: white;
         box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
         margin: 2mm;
       }
       table {
          border-collapse: collapse;
      }
       tr.border_bottom td {
        border-bottom:1pt solid black;
      }
      table th, table td {
      border:1px solid #000;
        padding: 0.5em;
      }
      }
      @media print {
          html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
          font-size: 9pt !important;
               }
            }

     </style>
     `);
   mywindow.document.write('</head><body>');
   mywindow.document.write(document.getElementById('printreceipt').innerHTML);
   mywindow.document.write('</body></html>');

   mywindow.document.close(); // necessary for IE >= 10
   mywindow.focus(); // necessary for IE >= 10*/

   mywindow.print();
   mywindow.close();

   return true;
  }

  const [search, setSearch] = useState(null);
  const updateSearch = (event) => {
    setSearch(event.target.value.substr(0,20));
  }

  const filterItems = (p) => {
    if(search) { 
      return (p.id).toString().includes(search) 
    }
    return true;
  }

    return (
        <Fragment>
          <Breadcrumb title="Purchase Orders" parent="PO" />
            <div style={{display:"none"}}>
              <PrintPO
                selectedProject = {selectedProject}
                selectedVendor = {selectedVendor}
                placeSupply = {placeSupply}
                dispatch = {dispatch}
                lastPO ={selectedPO}
              />
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="col-md-2">
                              <input type="text" placeholder="Enter item to be searched" style={elementStyle} value = {search}
                                onChange={updateSearch} />
                            </div>
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">PO</th>
                                                  <th scope="col">Vendor</th>
                                                  <th scope="col">Project</th>
                                                  <th scope="col">Invoiced Date</th>
                                                  <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filter_porders.filter(filterItems)
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                    const projectName = (projects || []).find(x=> x.id === n.project_id);
                                                    const clientName = (clients || []).find(x=> x.id === n.client_id);
                                                    const vendorName = (vendorForm || []).find(x=>x.id === n.vendor_id);

                                                  return (
                                                    <tr>
                                                     <td>{("PO-000").concat(n.id)}</td>
                                                     <td>{vendorName && vendorName.supname}</td>
                                                     <td>{projectName && projectName.name}</td>
                                                     <td>
                                                      {new Date(n.createdAt).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>
                                                      <i className="fa fa-eye" onClick={() => viewRow(n)} id="TooltipTop" data-placement="top"
                                                       style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff ' }}>
                                                       <UncontrolledTooltip placement="top" target="TooltipTop">
                                                        View Items
                                                       </UncontrolledTooltip>
                                                      </i>
                                                      <i className="fa fa-print" onClick={() => printView(n)} id="TooltipTop1" data-placement="top"
                                                       style={{ width: 35, fontSize: 18, padding: 11, color: '#5023a4 ' }}>
                                                       <UncontrolledTooltip placement="top" target="TooltipTop1">
                                                        Reprint Receipt
                                                       </UncontrolledTooltip>
                                                      </i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                      {/*
                                       <button className="btn btn-info btn-sm" type="button">
                                         Export
                                        </button>
                                      */}
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={filter_porders.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          {/* < !-- Using Modal for Viewing PO --> */}
            <Modal isOpen={modal} toggle={viewRow} className="modal-dialog modal-xl">
                <ModalHeader toggle={viewRow}>View PO</ModalHeader>   
                <ModalBody>
                   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Project</th>
                                                    <th scope="col">Part No</th>
                                                    <th scope="col">Part Name</th>
                                                    <th scope="col">Material</th>
                                                    <th scope="col">Finish Size</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Dispatch</th>
                                                    <th scope="col">Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filter_poItems.map((n)=> {
                                                  const proj = projects.find(x=>x.id === n.project_id);
                                                  const clint = clients.find(x=>x.id === n.client_id);
                                                  const projectName = proj && proj.name;
                                                  const clientName = clint && clint.name;

                                                  return (
                                                    <tr>
                                                     <td>{projectName}</td>
                                                     <td>{n.part_num}</td>
                                                     <td>{n.part_name}</td>
                                                     <td>{n.material}</td>
                                                     <td>{n.finish_size}</td>
                                                     <td>{n.qty}</td>
                                                     <td>{n.price}</td>
                                                     <td>
                                                      {new Date(n.date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                     </td>
                                                     <td>{n.psupply}</td>
                                                     <td>{n.dispatch}</td>
                                                     <td>{n.remarks}</td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={viewRow}>Close</Button>
                </ModalFooter>
            </Modal>

            {/* Block Vendor Email Model */}
            <Modal isOpen={printmodalopen} toggle={printView} className="modal-body" centered={true}>
              <ModalBody>
                <Typography gutterBottom>
                  Do you want to reprint the PO?
                </Typography>
              </ModalBody>
              <ModalFooter>
                <Button className="btn btn-pill btn-success active" onClick={ () => openPrint()}>Yes</Button>
                <Button className="btn btn-pill btn-secondary active" onClick={ () => setPrintModalOpen(!printmodalopen)}>No</Button>
              </ModalFooter>
            </Modal>
            
        </Fragment>
    );
}
export default IssuedPO;