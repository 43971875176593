import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Button, Grid } from '@material-ui/core';
//import Switch from '@material-ui/core/Switch';
//import FormGroup from '@material-ui/core/FormGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import { KeyboardDatePicker } from '@material-ui/pickers';
//import InfoIcon from '@material-ui/icons/Info';
//import Delete from '@material-ui/icons/Delete';
//import { postVendorForm, getVendorForm, aadharUpload, panUpload }  from '../../actions/asyncActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  table_root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  margin: {
    margin: theme.spacing(2),
  },
  errors: {
    color: 'red'
  },
  textfield: {
    padding: theme.spacing(1),
  }
}));

export default function VendorForm(props) {
  // const user = JSON.parse(localStorage.getItem("m_users"));
  // const company_id= user.company_id;
  // const group_id = user.group_id;
  const classes = useStyles();
  //const [values, setValues] = useState({});
  //const [errors, setError] = useState({});
  //const [vendorForm, setVendorForm] = useState([]);
  //const [open, setOpen] = useState(false);
  
  // useEffect( () => {
  //   getVendorForm(group_id)
  //   .then(resp => {
  //     setVendorForm(resp.data);
  //   })
  // }, []);

  const { facilityfields, facilityfields2 } = props;

return (
 <React.Fragment>
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom align="center">
        Facilities Available
      </Typography>
      <Typography gutterBottom>
        A. Machineries/ Equipments
      </Typography>
      {facilityfields.map((el, i)=> {
        return(
        <div key={i} className={classes.textfield}>
          <Grid container spacing={2}>
           <Grid item xs={12} sm={3}>
            <TextField onChange={(e) => props.handleChangeFacility(i, e)} value={el.machines1 || ''} id="machines1" name="machines1" 
            label="Machines" fullWidth autoComplete="machines1" autoFocus/>
           </Grid>
           <Grid item xs={12} sm={3}>
            <TextField onChange={(e) => props.handleChangeFacility(i, e)} value={el.capacity1 || ''} id="capacity1" name="capacity1" label="Capacity"
             fullWidth autoComplete="capacity1" />
           </Grid>
           <Grid item xs={12} sm={3}>
            <TextField onChange={(e) => props.handleChangeFacility(i, e)} value={el.qty1 || ''} id="qty1" name="qty1" label="Qty"
             fullWidth autoComplete="qty1" />
           </Grid>
           <Grid item xs={12} sm={2}>
            <Button size="large" color="secondary" variant="contained" onClick={() => props.handleRemoveFacility(i)}>
             X 
            </Button>
           </Grid>
          </Grid>
        </div>
        );
      })}
      <Button size="medium" color="primary" variant="contained" onClick={() => props.addClickFacility()}>
        Add 
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Typography gutterBottom>
        B. Instruments
      </Typography>
      {facilityfields2.map((el, i)=> {
         return(
        <div key={i} className={classes.textfield}>
          <Grid container spacing={2}>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChangeFacility2(i, e)} value={el.instruments2 || ''} id="instruments2" name="instruments2" 
            label="Instruments" fullWidth autoComplete="instruments2" />
           </Grid>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChangeFacility2(i, e)} value={el.capacity2 || ''} id="capacity2" name="capacity2" 
            label="Capacity" fullWidth autoComplete="capacity2" />
           </Grid>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChangeFacility2(i, e)} value={el.leasecount2 || ''} id="leasecount2" name="leasecount2" 
            label="Lease Count" fullWidth autoComplete="leasecount2" />
           </Grid>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChangeFacility2(i, e)} value={el.qty2 || ''} id="qty2" name="qty2" label="Qty"
             fullWidth autoComplete="qty2" />
           </Grid>
           <Grid item xs={12} sm={2}>
            <TextField onChange={(e) => props.handleChangeFacility2(i, e)} value={el.calibration2 || ''} id="calibration2" name="calibration2" 
            label="Calibration" fullWidth autoComplete="calibration2" />
           </Grid>
           <Grid item xs={12} sm={1}>
            <Button size="large" color="secondary" variant="contained" onClick={() => props.handleRemoveFacility2(i)}>
             X 
            </Button>
           </Grid>
          </Grid>
        </div>
        );
      })}
      <Button size="medium" color="primary" variant="contained" onClick={() => props.addClickFacility2()}>
        Add 
      </Button>
    </Grid>
  </Grid>
</React.Fragment>
);
}
