import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, ThumbsUp, MoreHorizontal  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import MaterialMgmt from './MaterialMgmt';
import PendingQC from './PendingQCTable';
import GRNCompleted from './GRNCompletedTable';

const MaterialManagementTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="Material Management" parent="Material Management" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Home />Material Management
                                </Tab>
                                <Tab>
                                    <MoreHorizontal /> Pending QC Inventory
                                </Tab>
                                <Tab>
                                   <ThumbsUp /> GRN Completed Inventory
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <MaterialMgmt />
                                </TabPanel>
                                <TabPanel>
                                    <PendingQC />
                                </TabPanel>
                                <TabPanel>
                                    <GRNCompleted />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

export default MaterialManagementTab;