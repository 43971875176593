import React, { Fragment, useState , useEffect } from 'react';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { TablePagination, TextField, Typography } from '@material-ui/core';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import {Autocomplete} from '@material-ui/lab';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import { indianNumberFormat, usNumberFormat } from '../../../../actions/numberFormat';
import { getRaisedInvoices, getRaisedInvItems, getSettings, getBillPayments,
		postBillPayments, checkOrderStatus } from '../../../../actions/asyncActions';

const ApprovedInvoices = (props) => {
	const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

   //  const elementStyle ={
	  // border:'solid',
	  // borderRadius:'10px',
	  // position:'relative',
	  // left:'1vh',
	  // height:'6vh',
	  // width:'23vh',
	  // marginTop:'1vh',
	  // marginBottom:'1vh',
	  // textAlign: 'center'
   //  }

   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(5);
   
   const handleChangePage = (event, newPage) => {
	 setPage(newPage);
   };

   const handleChangeRowsPerPage = event => {
	 setRowsPerPage(parseInt(event.target.value, 10));
	 setPage(0);
   };

   const [raisedinvoices, setRaisedInvoices] = useState([]);
   const filterInvoices = raisedinvoices.filter(x=>x.status_type === 'Approved');

    const [raiseInvItems, setRaiseInvItems] = useState([]);
    const [settings, setSettings] = useState([]);
    const curSettings = settings.find(x=>x.group_id === group_id);
	const foundCurrency = curSettings ? curSettings && curSettings.currency : null;
	
	const [billpayments, setBillPayments] = useState([]);

    //const [search, setSearch] = useState('');
	
	//const [filteredInvoices, setFilteredInvoices] = useState([]);
  
    // useEffect(() => {
  	 //  setFilteredInvoices(
  	 //   filterInvoices.filter(n => {
  	 // 	 return n.client_name.toLowerCase().includes(search.toLowerCase());
  	 //   })
  	 //  )
    // }, [search, filterInvoices])

    useEffect( () => {
	  getRaisedInvoices(group_id)
	  .then(resp => setRaisedInvoices(resp.data))
	}, []);

	useEffect( () => {
	  getRaisedInvItems(group_id)
	  .then(resp => setRaiseInvItems(resp.data))
	}, []);

	useEffect( () => {
	  getSettings(group_id)
	  .then(resp => setSettings(resp.data))
	}, []); 
	
	useEffect( () => {
		getBillPayments(group_id)
		.then(resp => setBillPayments(resp.data))
	}, []);

	const viewForm = (n) => {
	 const invoiceId = n.id;
	 const invoiceNo = n.invoice_no;
	 const clientName = n.client_name;
	 const billingAddress = n.billing_address ;
	 const txnType = n.txn_type
	 const status = n.status_type;
	 const dinvoice = n.doi;
	 const creditPeriod = n.credit_period;
	 const dueInvoice = n.due_date;
	 const placeSupply = n.place_supply;
	 const totalAmount = n.total_amount;
	 const customerNotes = n.cus_notes;
	 const termsConditions = n.terms;
	 const invItems = raiseInvItems.filter(x=>x.invoice_id === n.id);
	 props.history.push(`${process.env.PUBLIC_URL}/viewainvoice`, {invoiceId, invoiceNo, clientName, billingAddress, txnType, 
	 				status, dinvoice, creditPeriod, dueInvoice, placeSupply, totalAmount, customerNotes, termsConditions, invItems});
	}
	
	const [invoiceData, setInvoiceData] = useState({id:''})
	const [editmodal, setEditModal] = useState(false)
	const [reason, setReason] = useState();
	const invItems = raiseInvItems.filter(x=>x.invoice_id === invoiceData.id);
	console.log("invItems", invItems)
	
	const editRow = (n) => {
		setInvoiceData({id:n.id, invoiceNo: n.invoice_no, clientName: n.client_name, billingAddress: n.billing_address,
		 txnType: n.txn_type, status: n.status_type, dinvoice: n.doi, creditPeriod: n.credit_period,
		 dueDate: n.due_date, placeSupply: n.place_supply, totalAmount: n.total_amount, revision: n.revision,
		customerNotes: n.cus_notes, terms: n.terms, currency: n.currency, exchangeRate: n.exchange_rate })
		setEditModal(!editmodal)
	}

	const editInvoice = () => {
	 const invoiceId = invoiceData.id;
	 const invoiceNo = invoiceData.invoiceNo;
	 const clientName = invoiceData.clientName;
	 const billingAddress = invoiceData.billingAddress ;
	 const txnType = invoiceData.txnType
	 const status = invoiceData.status;
	 const dinvoice = invoiceData.dinvoice;
	 const creditPeriod = invoiceData.creditPeriod;
	 const dueDate = invoiceData.dueDate;
	 const placeSupply = invoiceData.placeSupply;
	 const totalAmount = invoiceData.totalAmount;
	 const customerNotes = invoiceData.customerNotes;
	 const termsConditions = invoiceData.terms;
	 const currency = invoiceData.currency;
	 const exchangeRate = invoiceData.exchangeRate;
	 const revision = invoiceData.revision
	 props.history.push(`${process.env.PUBLIC_URL}/editainvoice`, {invoiceId, invoiceNo, clientName, 
		billingAddress, txnType, status, dinvoice, creditPeriod, dueDate, placeSupply, totalAmount, 
		customerNotes, termsConditions, currency, exchangeRate, reason, invItems, revision});
	}

	const checkRow = (n) => {
	  const data = {
		id: n.id,
		orderId: n.order_id
      }
	  checkOrderStatus(data)
	  .then(resp => {
		console.log("response", resp.data)
		const result = resp.data;
		if(result.txStatus === 'SUCCESS' && result.orderStatus === 'PAID'){
			//Success Modal
			toast.success("Payment Received Successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
		}
		else if(result.orderStatus === 'ACTIVE'){
		  //No payments attempted against an order
		  toast.info("No payments attempted against an order!!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
		}
		else if(result.status === 'ERROR'){
		  //get the reason and show in a Modal
		  toast.warn("There was an error while making the payment!!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
		}
		else{
		  toast.warn("Something went wrong...", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
		} 
	  })
	}
	
	const [paymentmodal, setPaymentModal] = useState(false);
	const [values, setValues] = useState({});
	
	const modes = [{name: 'Bank Statement'}, {name: 'Cash'}, {name: 'Cheque'}, {name: 'Cashfree'}]
	const [modeval, setModeVal] = useState(null)
	const selectedMode = modeval? modeval && modeval.name : null;
	const [paymentDate, setPaymentDate] = useState(new Date());

	const totalA = foundCurrency === 'INR' ? 
				  indianNumberFormat(invoiceData.totalAmount) 
				  : 
				  usNumberFormat(invoiceData.totalAmount);
	
	const aaa = billpayments.length > 0 ? billpayments.filter(x=>x.invoice_id === invoiceData.id) : null;
	console.log("aaaa", aaa)
	const totalPaymentMade = aaa ? aaa.reduce((a,b) => a + parseFloat(b.paid_amount), 0) : 0;
	const paymentMade = foundCurrency === 'INR' ? 
				  indianNumberFormat(totalPaymentMade) 
				  : 
				  usNumberFormat(totalPaymentMade);
	const balanceA = parseFloat(invoiceData.totalAmount) - totalPaymentMade;
	const balanceAmount = foundCurrency === 'INR' ?	indianNumberFormat(balanceA) : usNumberFormat(balanceA);  

	const handleSettlementChange = (event) => {
		setValues({
			...values,
			[event.target.name]: event.target.value
		});
	}

	const currentTot = parseFloat(values.cash_discount) + parseFloat(values.tds) + parseFloat(values.paid_amount);
	const currentTotal = currentTot ? foundCurrency === 'INR' ? indianNumberFormat(currentTot) : usNumberFormat(currentTot) : 0;
	const currentBal = balanceA - currentTot;
	const currentBalance = currentBal ? foundCurrency === 'INR' ? indianNumberFormat(currentBal) : usNumberFormat(currentBal) : 0;

	const bookSettlement = (n) => {
		setInvoiceData({id:n.id, totalAmount: n.total_amount})
		setPaymentModal(!paymentmodal)
	}

	const submitSettlement = () => {
		const data = {
			invoice_id: invoiceData.id,
			paid_amount: currentTot,
			cash_discount: values.cash_discount,
			tds: values.tds,
			payment_mode: selectedMode,
			payment_date: paymentDate,
			user_id: user.id,
			group_id: group_id
		}
		postBillPayments(data)
		.then(resp => {
			getBillPayments(group_id)
			.then(resp => setBillPayments(resp.data))
			setValues({paid_amount:'', cash_discount:'', tds:'' })
			setModeVal(null)
			setPaymentModal(!paymentmodal)
			toast.success("Settlement booked successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
		})
		.catch(err => {
			console.log("Error", err)
		})
	}
	
	return(
		<Fragment>
		 <div className="container-fluid">
            <div className="row">
              {/*
               <div className="col-xl-12">
                <input type="text" placeholder="Search Invoice" style={elementStyle} value={search}
	              onChange={e => setSearch(e.target.value)} />
               </div>
			  */}
			  <div className="col-xl-12">
			    <div className="table-responsive">
					<table className="table table-sm table-bordered table-hover">
						<thead className="table-primary">
							<tr>
								<th scope="col">Inv #</th>
								<th scope="col">Revision</th>
								<th scope="col">Client</th>
								<th scope="col">Address</th>
								<th scope="col">Invoice Date</th>
								<th scope="col">Due Date</th>
								<th scope="col">Total</th>
								<th scope="col">Action</th>
							</tr>
						</thead>
						<tbody>
						 {filterInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
							return (
								<tr>
								<td>{n.invoice_no}</td>
								{ n.revision != null ?
									<td>{n.revision}</td>
									:
									<td>0</td>
								}
								<td>{n.client_name}</td>
								<td>{n.billing_address}</td>
								<td>
								{new Date(n.doi).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
								</td>
								<td>
								{new Date(n.due_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
								</td>
								<td>{n.total_amount}</td>
								<td>
								<i className="fa fa-eye" onClick={() => viewForm(n)} id="TooltipTop2" data-placement="bottom"
									style={{ width: 35, fontSize: 16, padding: 11, color: '#178d91' }}>
									<UncontrolledTooltip placement="bottom" target="TooltipTop2">
										View Invoice
									</UncontrolledTooltip>
								</i>
								<i className="fa fa-edit" onClick={() => editRow(n)} id="TooltipTop" data-placement="bottom"
									style={{ width: 35, fontSize: 16, padding: 11, color: '#115f3f' }}>
									<UncontrolledTooltip placement="bottom" target="TooltipTop">
										Edit Invoice
									</UncontrolledTooltip>
								</i>
								<i className="fa fa-check-circle-o" onClick={() => checkRow(n)} id="TooltipTop3" data-placement="left"
									style={{ width: 35, fontSize: 16, padding: 11, color: '#114fd4' }}>
									<UncontrolledTooltip placement="left" target="TooltipTop3">
										Check Cashfree Payment Status
									</UncontrolledTooltip>
								</i>
								<i className="fa fa-book" onClick={() => bookSettlement(n)} id="TooltipTop1" data-placement="bottom"
									style={{ width: 35, fontSize: 16, padding: 11, color: '#d81cb8' }}>
									<UncontrolledTooltip placement="bottom" target="TooltipTop1">
										Book Settlements
									</UncontrolledTooltip>
								</i>
								</td>
								</tr>
							);
							})
							}
						</tbody>
					</table>
				 </div>
				</div>
				<div className="col-sm-12">
					<div className="card">
						<div className="card-block row">
							<div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
							</div>
							<div className="col-sm-6 col-lg-6 col-xl-6">
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={filterInvoices.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onChangePage={handleChangePage}
									onChangeRowsPerPage={handleChangeRowsPerPage}
								/>
							</div>
						</div>
				 	</div>
				</div>
              </div>
            </div>

			{/* Edit Invoice Modal */}
			<Modal isOpen={editmodal} toggle={editRow}>
			 <ModalHeader toggle={editRow}>Reason for the Edit</ModalHeader>   
			  <ModalBody>
				<div className="form-row text-center">
					<div className="col-xl-12">
					<label className="col-form-label" htmlFor="message-text">
						<b>Please mention the reason for editing the invoice:</b>
					</label>
					<TextField multiline label="Reason" variant="outlined" value={reason} 
						onChange={(e) => setReason(e.target.value)} rows={6} fullWidth/>
					</div>
					<div className="col-xl-12 m-t-20">
						<button className="btn btn-success" onClick={() => editInvoice()}>
							Confirm
						</button>
					</div>
				</div>
			  </ModalBody>
			</Modal>

			{/* Book Settlement Modal */}
			<Modal isOpen={paymentmodal} toggle={bookSettlement} centered={true}>
			 <ModalHeader toggle={bookSettlement}>Book Settlement/Payment</ModalHeader>   
			  <ModalBody>
				{balanceA === 0 ?
				<div className="form-row">
				  <div className="col-xl-12 text-center">
				    <Typography >
						<CheckCircleOutlineRoundedIcon style={{fontSize: '100px', color:'#4bb543'}}/>
					</Typography>
					<Typography component="h2" variant="h6" align="center" gutterBottom>
						The Amount of <b>{totalA}</b> is Booked Successfully...
					</Typography>
				  </div>
				</div>
				:
				<div className="form-row">
					<div className="col-xl-12 text-center">
						<h4>   
							Total Invoice Amount:{totalA} <br/>
							Payment Already Booked:{paymentMade} <br/>
							Balance:{balanceAmount}
						</h4>
					</div>
					<div className="col-xl-12 m-t-20">
					 <div className="row">
					  <div className="col-xl-6">
					    <label className="col-form-label" htmlFor="message-text">
						   <b>Mode of payment:</b>
						</label>
						<Autocomplete
							options={modes}
							getOptionLabel={option => option.name}
							value={modeval}                      
							onChange={(event, newValue) => {
							setModeVal(newValue);
							}}            
							renderInput={params => (
							<TextField {...params} variant="outlined" margin="dense" helperText="Select Payment Mode" 
							 fullWidth />
							)}
						/>
					  </div>
					  <div className="col-xl-6">
					    <label className="col-form-label" htmlFor="message-text"><b>Payment Date:</b></label>
						<DatePicker className="form-control digits m-t-10"
							selected={paymentDate}
							value={paymentDate}
							onChange={(date) => setPaymentDate(date)}
							dateFormat= "dd/MM/yyyy"
							maxDate= {new Date()}
						/>
					  </div>
					  <div className="col-xl-4">
					   <label className="col-form-label" htmlFor="message-text"><b>Cash Discount:</b></label>
					   <TextField margin="dense" variant="outlined" name="cash_discount" 
					    value={values.cash_discount} onChange={handleSettlementChange} fullWidth />
					  </div>
					  <div className="col-xl-4">
					   <label className="col-form-label" htmlFor="message-text"><b>TDS Deducted:</b></label>
					   <TextField margin="dense" variant="outlined" name="tds" 
					    value={values.tds} onChange={handleSettlementChange} fullWidth />
					  </div>
					  <div className="col-xl-4">
					   <label className="col-form-label" htmlFor="message-text"><b>Amount Paid:</b></label>
					   <TextField margin="dense" variant="outlined" name="paid_amount" 
					    value={values.paid_amount} onChange={handleSettlementChange} fullWidth />
					  </div>
				 	 </div>
					</div>
					<div className="col-xl-12 text-center m-t-20">
						<h4>Total: {currentTotal}</h4>
					</div>
					<div className="col-xl-12 text-center m-t-20">
						<h4>Balance: {currentBalance}</h4>
					</div>
					<div className="col-xl-12 text-center m-t-20">
						<button className="btn btn-success" onClick={() => submitSettlement()}>
							Submit
						</button>
					</div>
				</div>
				}
			  </ModalBody>
			</Modal>

		</Fragment>
	);
}

export default withRouter(ApprovedInvoices);