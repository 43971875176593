import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Autocomplete} from '@material-ui/lab';
import { TextField, MenuItem, FormHelperText, TablePagination } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { getIndInvoice, deleteIndInvoice, postIndInvoice, getIndInvoiceItems, editIndInvoiceItems, uploadIndInvoice } from '../../actions/asyncActions';

const GRNMatch = (props) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [invoice, setInvoice] = useState([]);
  const filterInvoice = invoice.filter(x=>x.status === 'Processed');
  const [invoiceItems, setInvoiceItems] = useState([]);
  const filterInvoiceItems = invoiceItems.filter(x=>x.status === 'GRN Matched');
  const [values, setValues] = useState({});
  const [invoiceDate, handleInvoiceDate] = useState(new Date());
  const [invoicefile, setInvoiceFile] = useState([]);
  const [fields, setFields] = useState([{ description: '', hsn:'', gst:'', length:'', width:'', nos:'', qty:'', rate:'', amount:'' }]);
  
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  function addClick(){
    const values = [...fields];
    values.push({ description: '', hsn:'', gst:'', length:'', width:'', nos:'', qty:'', rate:'', amount:'' });
    setFields(values);
    }

  function handleRemove(i){
    const values = [...fields];
    values.splice(i,1);
    setFields(values);
  }

  function handleChange3(i,e){
    const tempValues = [...fields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFields(tempValues);
  }

  const handleUploadInvoice = async (event) => {
    setInvoiceFile(document.getElementById('invoicefile').files[0]);
  }
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect( () => {
    getIndInvoice(group_id)
     .then(resp => setInvoice(resp.data))
  }, []);

  useEffect( () => {
    getIndInvoiceItems(group_id)
     .then(resp => setInvoiceItems(resp.data))
  }, []);
  
  const viewRow = (n) => {
   const invItemId = n.id;
   const filterInvoice = invoice.find(x=>x.id === n.invoice_id);
   props.history.push(`${process.env.PUBLIC_URL}/viewthreewayinvoice`, {invItemId, filterInvoice});
  }

  const unmatchRow = (n) => {
  	const data = {
  	  id: n.id,
  	  status: 'Approved',
  	  invQty: 0,
  	  po_itemno: n.po_itemno
  	}
  	editIndInvoiceItems(data)
  	.then(resp => {
  	  getIndInvoiceItems(group_id)
  	  .then(resp => setInvoiceItems(resp.data))
    })
  }

  const deleteRow = (n) => {
    const deleteId = {
      id : n.id,
      temp_id: n.temp_id
    }
    deleteIndInvoice(deleteId)
      .then(resp => {
        getIndInvoice(group_id)
        .then(resp => setInvoice(resp.data))
        toast.error("Invoice deleted!!!")
      })
      .catch(err => {
        console.log("Error", err);
    })
  }

  const [invoicemodal, setInvoiceModal] = useState()
  const addInvoice = () => {
    setInvoiceModal(!invoicemodal);
  }

  return (
    <Fragment>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-info">
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Vendor</th>
                                            <th scope="col">ItemName</th>
                                            <th scope="col">Qty</th>
                                            <th scope="col">Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            {filterInvoiceItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((n, i)=> {
                                              return (
                                                <tr>
                                                 <th scope="row">{i+1}</th>
                                                 <td>{n.vendor_id}</td>
                                                 <td>{n.description}</td>
                                                 <td>{n.qty}</td>
                                                 <td>
                                                   <button>
                                                    <i className="fa fa-eye" onClick={() => viewRow(n)} id="TooltipTop" data-placement="top"
                                                     style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      View Invoice
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </button>
                                                   <button>
                                                   <i className="fa fa-trash-o" onClick={() => unmatchRow(n)} id="TooltipTop1" data-placement="top"
                                                   style={{ width: 35, fontSize: 18, padding: 11, color: '#e6152a' }}>
                                                   <UncontrolledTooltip placement="top" target="TooltipTop1">
                                                    Unmatch Invoice
                                                   </UncontrolledTooltip>
                                                  </i>
                                                  </button>
                                                 </td>
                                                </tr>
                                              );
                                            })
                                          }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-block row">
                                  <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                   {/*<button className="btn btn-info btn-sm" type="button" onClick={addInvoice}>
                                                                        Add Invoice
                                                                       </button>*/}
                                  </div>
                                  <div className="col-sm-6 col-lg-6 col-xl-6">
                                    <TablePagination
                                      rowsPerPageOptions={[5, 10, 25]}
                                      component="div"
                                      count={filterInvoiceItems.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onChangePage={handleChangePage}
                                      onChangeRowsPerPage={handleChangeRowsPerPage}
                                     />
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Fragment>
   );
}

export default withRouter(GRNMatch);