import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { Download } from 'react-feather';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { TablePagination } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { indianNumberFormat, usNumberFormat } from '../../../actions/numberFormat';
import { getClients, getProjects, postProjects, editProjects, approveProjects, 
         getRaisedInvoices, getSettings } from '../../../actions/asyncActions';

const ProjectApproved = (props) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    
    const [projects, setProject] = useState([])
    const approved_data = projects.filter(s => s.status === 'approved');

    const [clients, setClient] = useState([])
    const [raisedInvoices, setRaisedInvoices] = useState([])

    const [settings, setSettings] = useState([]);
    const curSettings = settings.find(x=>x.group_id === group_id);
    const foundCurrency = curSettings ? curSettings && curSettings.currency : null;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const viewRow = n => {
      const projectId = n.id;
      props.history.push(`${process.env.PUBLIC_URL}/viewproject`, {projectId});
    }

    const viewInvoices = n => {
      const projectId = n.id;
      props.history.push(`${process.env.PUBLIC_URL}/viewallinvoices`, {projectId});
    }

    const raiseInvoice = n => {
      const projectId = n.id;
      const salesIncharge = n.sales_incharge;
      const salesEmail = n.sales_email;
      const clientId = n.client_id;
      const clientDetails = clients.find(x=>x.id === clientId);
      const clientName = clientDetails ? clientDetails && clientDetails.name : null;
      const billingAddress = clientDetails ? clientDetails && clientDetails.billing_address : null;
      const creditPeriod = clientDetails ? clientDetails && clientDetails.credit_period : 0;
      props.history.push(`${process.env.PUBLIC_URL}/raiseinvoice`, {projectId, salesIncharge, salesEmail, clientId, clientName, billingAddress, creditPeriod });
    }

    const raiseCredit = n => {
      const projectId = n.id;
      const clientId = n.client_id;
      const clientDetails = clients.find(x=>x.id === clientId);
      const clientName = clientDetails ? clientDetails && clientDetails.name : null;
      const billingAddress = clientDetails ? clientDetails && clientDetails.billing_address : null;
      const creditPeriod = clientDetails ? clientDetails && clientDetails.credit_period : 0;
      props.history.push(`${process.env.PUBLIC_URL}/raisecreditnote`, {projectId, clientId, clientName, billingAddress, creditPeriod });
    }

    useEffect( () => {
      getProjects(group_id)
      .then(resp => setProject(resp.data))
    }, []);

    useEffect( () => {
      getClients(group_id)
      .then(resp => setClient(resp.data))
    }, []);

    useEffect( () => {
      getRaisedInvoices(group_id)
      .then(resp => setRaisedInvoices(resp.data))
    }, []);

    useEffect( () => {
      getSettings(group_id)
      .then(resp => setSettings(resp.data))
    }, []);

    return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive text-wrap">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                  <th scope="col">Project</th>
                                                  <th scope="col">PO Value</th>
                                                  <th scope="col">Invoice Value</th>
                                                  <th scope="col">Client Name</th>
                                                  <th scope="col">ScheduleStart</th>
                                                  <th scope="col">ScheduleEnd</th>
                                                  <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {approved_data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                const clientName = clients.find(s => s.id === n.client_id );
                                                const  filterInvoice = raisedInvoices.filter(x=>x.project_id === n.id && x.status_type === 'Approved');
                                                const showPoVal = foundCurrency === 'INR' ? indianNumberFormat(n.po_value) : usNumberFormat(n.po_value);
                                                const poValuePercentage = parseFloat((90/100) * parseFloat(n.po_value));
                                                console.log("poValuePercentage", poValuePercentage)
                                                const totalAmount = filterInvoice.reduce((a,b) => a + parseFloat(b.total_amount), 0);
                                                const showTotal = foundCurrency === 'INR' ? indianNumberFormat(totalAmount) : usNumberFormat(totalAmount);
                                                console.log("difference", parseFloat(totalAmount)  - parseFloat(poValuePercentage)) 
                                                 return (
                                                  <tr>
                                                   <td>{n.name}</td>
                                                   <td>{showPoVal}</td>
                                                   { parseFloat(totalAmount) > parseFloat(poValuePercentage) ?
                                                    <td className="text-danger">{showTotal}</td>
                                                    :
                                                    <td className="text-primary">{showTotal}</td>
                                                   }
                                                   <td>{clientName && clientName.name}</td>
                                                   <td>
                                                    {new Date(n.start_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                   </td>
                                                   <td>
                                                    {new Date(n.end_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                   </td>
                                                   <td>
                                                    <i className="fa fa-eye" onClick={() => viewRow(n)} id="TooltipTop" data-placement="top"
                                                     style={{ width: 35, fontSize: 16, padding: 11, color: '#178d91' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      View project details
                                                     </UncontrolledTooltip>
                                                    </i>
                                                    <i className="fa fa-bullseye" onClick={() => viewInvoices(n)} id="TooltipTop1" data-placement="top"
                                                     style={{ width: 35, fontSize: 16, padding: 11, color: '#178d91' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop1">
                                                      View Invoices
                                                     </UncontrolledTooltip>
                                                    </i>
                                                    <i className="fa fa-fighter-jet" onClick={() => raiseInvoice(n)} id="TooltipTop2" data-placement="top"
                                                     style={{ width: 35, fontSize: 16, padding: 11, color: '#178d91' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop2">
                                                      Raise Invoice
                                                     </UncontrolledTooltip>
                                                    </i>
                                                    <i className="fa fa-credit-card" onClick={() => raiseCredit(n)} id="TooltipTop3" data-placement="top"
                                                     style={{ width: 35, fontSize: 16, padding: 11, color: '#178d91' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop3">
                                                      Raise Credit note
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </td>
                                                  </tr>
                                                 );
                                               })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-10">
                                        <button className="btn btn-info btn-sm" type="button">
                                          <Download size="14"/>Export
                                        </button>
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={approved_data.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        );
}

export default withRouter(ProjectApproved);