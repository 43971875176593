import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import {Autocomplete} from '@material-ui/lab';
import { TextField, MenuItem, FormHelperText, TablePagination } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import { getIndInvoice, editIndInvoice} from '../../actions/asyncActions';

const InvoiceApproved = (props) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [invoice, setInvoice] = useState([]);
  const filterInvoice = invoice.filter(x=>x.status === 'Approved');
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect( () => {
    getIndInvoice(group_id)
     .then(resp => setInvoice(resp.data))
  }, []);

  const [modal, setModal] = useState();
  const [selId, setSelectedID] = React.useState([]);
  const viewImage = invoice.find(x => x.id === selId);
  const invoiceExtension = (viewImage || []).inv_ext;
  const invoicedirectory = (viewImage || []).inv_file;
  const [numPages, setNumPage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  console.log("invoiceExtension", invoiceExtension);
  console.log("invoicedirectory", invoicedirectory);

  //const invoicePath = `http://localhost:9004/${invoicedirectory}`;
  //const pathpath = 'http://172.105.62.208:9004'
  //const pathpath = 'http://172.105.38.239:9004'
  const pathpath = 'http://172.105.41.149:9004'
  const invoicePath = `${pathpath}${invoicedirectory}`

  const onDocumentLoadSuccess = ({ numPages }) => {
   setNumPage(numPages);
  }

  const goToPrevPage = () => {
   setPageNumber(pageNumber - 1);
  }
  const goToNextPage = () => {
    setPageNumber(pageNumber + 1);
  }
  
  // const viewRow = (n) => {
  //   setSelectedID(n.id);
  //   setModal(!modal)
  // }
  const viewRow = (n) => {
   const id = n.id;
   const invoiceNo = n.invoice_no;
   const invoiceDate = n.invoice_date;
   const custName = n.customer_name;
   const email = n.email_address;
   const website = n.customer_website;
   const amount = n.invoice_amount;
   const invFile = n.inv_file;
   const invExt = n.inv_ext;
   props.history.push(`${process.env.PUBLIC_URL}/viewappinvoice`, {id,invoiceNo,invoiceDate,custName,email,website,amount,invFile,invExt});
  }

  const rejectRow = (n) => {
    const data = {
      id: n.id,
      status: 'Rejected'
    }
    editIndInvoice(data)
    .then(resp => {
     getIndInvoice(group_id)
      .then(resp => setInvoice(resp.data))
      toast.error("Invoice Rejected !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 2000})
     })
     .catch(err => {
       console.log("Error", err);
     })
  }

    return (
      <Fragment>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead className="table-info">
                                            <tr>
                                              <th scope="col">#</th>
                                              <th scope="col">Invoice no</th>
                                              <th scope="col">Invoice Date</th>
                                              <th scope="col">Customer</th>
                                              <th scope="col">Approved Date</th>
                                              <th scope="col">Invoice Amount</th>
                                              <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterInvoice.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((n, i)=> {
                                              return (
                                                <tr>
                                                 <th scope="row">{i+1}</th>
                                                 <td>{n.invoice_no}</td>
                                                 <td>
                                                  {new Date(n.invoice_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                 </td>
                                                 <td>{n.customer_name}</td>
                                                 <td>
                                                  {new Date(n.apr_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                                 </td>
                                                 <td>{n.invoice_amount}</td>
                                                 <td>
                                                   <button>
                                                    <i className="fa fa-eye" onClick={() => viewRow(n)} id="TooltipTop" data-placement="top"
                                                     style={{ width: 35, fontSize: 18, padding: 11, color: '#007bff' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop">
                                                      View Invoice
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </button>
                                                   <button>
                                                    <i className="fa fa-thumbs-down" onClick={() => rejectRow(n)} id="TooltipTop1" data-placement="top"
                                                     style={{ width: 35, fontSize: 18, padding: 11, color: '#e6152a' }}>
                                                     <UncontrolledTooltip placement="top" target="TooltipTop1">
                                                      Reject Invoice
                                                     </UncontrolledTooltip>
                                                    </i>
                                                   </button>
                                                 </td>
                                                </tr>
                                              );
                                            })
                                          }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-12">
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filterInvoice.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                               />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal isOpen={modal} toggle={viewRow} className="modal-dialog modal-lg">
          <ModalHeader toggle={viewRow}>View Invoice</ModalHeader>   
            <ModalBody>
             {invoiceExtension === 'application/pdf' ?
              <div>
              <nav>
               <button className="btn btn-light" onClick={goToPrevPage}>Prev</button>&nbsp;&nbsp;
               <button className="btn btn-secondary" onClick={goToNextPage}>Next</button>
              </nav>
              <Document
                file={`${invoicePath}`}
                onLoadSuccess={onDocumentLoadSuccess}
               >
               <Page pageNumber={pageNumber} />
              </Document>
              <p>Page {pageNumber} of {numPages}</p>
              </div>
             :
              <PinchZoomPan>
                  <img alt='Test Image' src={`${invoicePath}`} />
              </PinchZoomPan>
             }
            </ModalBody>
        </Modal>
      </Fragment>
    );
}

export default withRouter(InvoiceApproved);