import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import {Autocomplete} from '@material-ui/lab';
import {TextField, MenuItem, FormHelperText } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Logo from '../../../assets/images/nupielogo.png';
import { indianNumberFormat, usNumberFormat } from '../../../actions/numberFormat';
import { state_arr } from './IndianStates';
import { getProjects, getGroup, getMasterInventory, postRaisedInvoices, postPdfInvoice,
  getPdfInvoice, getSettings, getAllUser} from '../../../actions/asyncActions';
import { saveAs } from 'file-saver';
import PrintInvoice from './print.js';

const RaiseInvoice = (props) => {
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const location = props.location;
 const projectId = location.state ? location.state.projectId : null;
 const salesIncharge = location.state ? location.state.salesIncharge : null;
 const salesEmail = location.state ? location.state.salesEmail : null;
 const clientId = location.state ? location.state.clientId : null;
 const clientName = location.state ? location.state.clientName : null;
 const billingAddress = location.state ? location.state.billingAddress : null;
 const creditPeriod = location.state ? location.state.creditPeriod : null;

 const aaa = {clientName: clientName}
 const [firstData, setFirstData] = useState(aaa);

 const [projects, setProject] = useState([]);
 const [group, setGroup] = useState([]);
 const [users, setUsers] = useState([]);
 const groupDetails = group.find(x=> x.id === group_id);
 const companyName = groupDetails ? groupDetails && groupDetails.name : null;
 const GSTIN = groupDetails ? groupDetails && groupDetails.gst : null;
 const companyAddress = groupDetails ? groupDetails && groupDetails.address : null;
 let invoiceNumber = groupDetails ? groupDetails && groupDetails.last_inv : null;
 invoiceNumber = invoiceNumber + 1;

 //const aaa = clientDetails ? clientDetails && clientDetails.credit_period : 0;
 const initialData = {creditDays: creditPeriod}
 const [creditPeriodData, setCreditPeriod] = useState(initialData);

 const handleChange = event => {
    setCreditPeriod({
	  ...creditPeriodData,
	  [event.target.name]: event.target.value
	});
 };
 
 //const creditDays = (creditPeriod).concat('(days)') 
 
 const transactionType = [{ name: 'GST Inter-State' }, {name: 'GST Intra-State'}, {name: 'Export Invoice'}, {name: 'Non GST Sale'}];
 const [typeval, setTypeVal] = useState(null);
 const typeOfTransaction = typeval ? typeval && typeval.name : null;
 const statusType = [{name: 'Preparation'}, {name: 'Approve'}];
 const [statusval, setStatusVal] = useState(null);
 const typeOfStatus = statusval ? statusval && statusval.name : null;

 const list_currency = [{name:'USD'}, {name:'INR'}];
 const [currency_val, setCurrencyVal] = useState(null)
 const selectedCurrency = currency_val ? currency_val && currency_val.name : null;

 const [exchange_rate, setExchangeRate] = useState(0);

 const [lessdatemodal, setLessDateModal] = useState(false);
 const [moredatemodal, setMoreDateModal] = useState(false);
 const [invoiceDate, setInvoiceDate] = useState(new Date());
 const [difference, setDifference] = useState(0);

 const dateofInvoice = invoiceDate.toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
 
 const toggleLess = () => {
 	setLessDateModal(!lessdatemodal)
 }

 const toggleMore = () => {
 	setMoreDateModal(!moredatemodal)
 }

 const handleDateChange = (date) => {
 	setInvoiceDate(date)
 	var date1 = new Date();
 	var date2 = date;
    var difference_In_Time = date2.getTime() - date1.getTime() ;
    var difference_In_Days = Math.round(difference_In_Time / (1000 * 3600 * 24));
    setDifference(difference_In_Days);
 	if(difference_In_Days < 0){
 		//show less date modal
 		setLessDateModal(!lessdatemodal)
 	}
 	else if (difference_In_Days > 0){
 		//show more date modal
 		setMoreDateModal(!moredatemodal)
 	}
 	else {

 	}
 }

 var dueDate = new Date(invoiceDate);
 dueDate.setDate(dueDate.getDate() + parseInt(creditPeriodData.creditDays));

 const deeDate = new Date(dueDate).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })

 const [place_supply, setPlaceSupply] = useState(null);
 const selectedPlace = place_supply ? place_supply && place_supply.name : null;

 const [discount, setDiscount] = useState(0);
 
 const getDiscount = () => {
  let disc;
  if(discount === ''){
    return totalAmount;
  }
  if (String(discount).indexOf('%') !== -1)
  {
    let calculation = parseFloat(totalAmount) * parseFloat(discount) / 100
     let  disc = (Number(totalAmount) - parseFloat(calculation))
       return disc;
  }
  else
   {
       disc = (Number(totalAmount) - Number(discount))
       return disc;
   }
 }

 const [fields, setFields] = useState([{ part_no:'', qty:'', rate:'', discount:'' }]);
 const [cus_notes, setCusNotes] = useState();
 const [terms, setTerms] = useState();
 
 let description = [];

 const [masterInventory, setMasterInventory] = useState([]);
 const filterMastery = masterInventory.filter(x=>x.raw_material === 1);

 let totalAmount = 0;
 
 let totalA = 0;
 let totalB = 0;

 const [errors, setError] = useState([]);

 function addClick(){
   const values = [...fields];
   values.push({ part_no:'', qty:'', rate:'' });
   setFields(values);
 }

  function handleRemove(i){
    const values = [...fields];
    values.splice(i,1);
    setFields(values);
  }

  const [inventoryValue, setInventoryValue] = useState([]);
  const invDesc = inventoryValue && inventoryValue.description;
  const invRate = inventoryValue && inventoryValue.rate;
  const invTaxRate = inventoryValue && inventoryValue.tax_rate;
  const invHsn = inventoryValue && inventoryValue.hsn;

  const [settings, setSettings] = useState([]);
  const curSettings = settings.find(x=>x.group_id === group_id);
  const foundCurrency = curSettings ? curSettings && curSettings.currency : null;

  function handleChange3(i,e){
    const tempValues = [...fields];
    tempValues[i] = {...tempValues[i], [e.target.name]: e.target.value};
    setFields(tempValues);
  }

 useEffect( () => {
  getProjects(group_id)
  .then(resp => setProject(resp.data))
 }, []);

 useEffect( () => {
  getGroup()
  .then(resp => setGroup(resp.data))
 }, []);

 useEffect( () => {
  getMasterInventory(group_id)
  .then(resp => setMasterInventory(resp.data))
 }, []);

 useEffect( () => {
  getSettings(group_id)
  .then(resp => setSettings(resp.data))
 }, []);

 useEffect( () => {
  getAllUser(group_id)
  .then(resp => setUsers(resp.data))
 }, []);

 const validateForm = (data) => {
  const errors = [];
  if(!data.txn_type) {
    errors.txn_type = "Transaction Type is not selected";
  }
  return errors;
 }

 const saveInvoice = () => {
  const salesInchar = users.find(x=>x.id === salesIncharge)
  const salesName = salesInchar && salesInchar.firstname;
 	let fieldsComp = fields;
    const arr = [];
    fieldsComp.forEach(item => {
      arr.push({ 
        ids:item.part_no,
        qty: item.qty,
        discount: item.discount
      })
    });
 	const data = {
 		invoice_no: invoiceNumber,
 		project_id: projectId,
 		client_id: clientId,
 		client_name: clientName,
    billing_address: billingAddress,
    sales_incharge: salesName, 
 		txn_type: typeOfTransaction,
 		status_type: "Preparation",
 		doi: invoiceDate,
 		credit_period: creditPeriodData.creditDays,
 		due_date: dueDate,
 		place_supply: selectedPlace,
    total_amount: totalAmount,
 		cus_notes: cus_notes,
    terms: terms,
    currency: selectedCurrency,
    exchange_rate: exchange_rate, 
 		user_id: user.id,
 		group_id: group_id
   }
 	const dataItems = {
 		items: arr,
 		status: "Preparation",
 		user_id: user.id,
 		group_id: group_id
  }
  const errors = validateForm(data);
    if(Object.keys(errors).length) {
      setError(errors);
      return;
    }
    if(dataItems.items[0].ids === ''){
      toast.error("Please add atleast one item to continue",  {position: toast.POSITION.TOP_CENTER, autoClose:3000})
      return;
    }
    if(parseFloat(data.total_amount) <= 0){
      toast.error("Please add quantity for the item",  {position: toast.POSITION.TOP_CENTER, autoClose:3000})
      return;
    }
   postRaisedInvoices({data, dataItems})
   .then(resp => {
    handlePrintInvoice();
    toast.success("Invoice raised successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose:3000})
 	  props.history.push(`${process.env.PUBLIC_URL}/project`);
   })
   
 }

 const handleBack = () => {
  props.history.push(`${process.env.PUBLIC_URL}/project`);
 }

 const handlePrintInvoice = () => {
  var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(`<html><head><title>${document.title}</title>
     <style>
     @page { margin: 0 }
      body { margin: 0 }
      .sheet {
      margin: 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      page-break-after: always;
      }

      /* Padding area */
      .sheet.padding-5mm { padding: 5mm }

      /* For screen preview */
      @media screen {
      body { background: #e0e0e0 }
        .sheet {
         background: white;
         box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
         margin: 2mm;
       }
       table {
          border-collapse: collapse;
      }
       tr.border_bottom td {
        border-bottom:1pt solid black;
      }
      table th, table td {
      border:1px solid #000;
        padding: 0.5em;
      }
      }
      @media print {
          html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
          font-size: 9pt !important;
               }
            }

     </style>
     `);
   mywindow.document.write('</head><body>');
   mywindow.document.write(document.getElementById('printreceipt').innerHTML);
   mywindow.document.write('</body></html>');

   mywindow.document.close(); // necessary for IE >= 10
   mywindow.focus(); // necessary for IE >= 10*/

   mywindow.print();
   mywindow.close();

   return true;
 }

	return(
	 <Fragment>
	  <Breadcrumb title="Invoice" parent="Project Management" />
    <div style={{display:"none"}}>
      <PrintInvoice
        companyName = {companyName}
        companyAddress = {companyAddress}
        GSTIN = {GSTIN}
        invoiceNo = {invoiceNumber}
        billingTo = {clientName}
        billingAddress= {billingAddress}
        placeSupply = {selectedPlace}
        transactionType = {typeOfTransaction}
        invoiceDate = {invoiceDate}
        dueDate = {dueDate}
        itemData = {fields}
        selectedCurrency = {selectedCurrency}
        exchangeRate = {exchange_rate}
        customerNotes = {cus_notes}
        termsConditions = {terms}
      />
     </div>
	  <div className="container-fluid">
	   <div className="row">
		<div className="col-xl-9">
		 <div className="card">
            <div className="card-body">
             <div className="col-xl-12">
               <div className="form-row">
                <div className="col-xl-8 col-lg-8">
             	 <h4>{companyName}</h4>
             	</div>
             	<div className="col-xl-3 col-lg-3">
             	 <img src={Logo} />
             	</div>
               </div>
             </div>
             <div className="col-xl-8">
             	<h6>GSTIN - {GSTIN}</h6>
             </div>
             <div className="col-xl-12 m-t-20">
             </div>
             <div className="col-xl-12 m-t-20">
             </div>
             <div className="col-xl-12 m-t-20">
             </div>
             <div className="col-xl-12">
              <div className="form-row">
               <div className="col-xl-6">
              	<label className="col-form-label" htmlFor="message-text"><b>INVOICE #:</b></label>
                <TextField value={invoiceNumber} />
               </div>
               <div className="col-xl-6">
                <label className="col-form-label" htmlFor="message-text"><b>STATUS:</b></label>
                <b style={{color: "blue", fontSize:16}}> Under Preparation</b>
               </div>
              </div>
               <div className="form-row">
                <div className="col-xl-6">
                 <label className="col-form-label" htmlFor="message-text"><b>BILLING TO:</b></label>
                 <TextField multiline value={clientName} />
                </div>
                <div className="col-xl-6">
                 <label className="col-form-label" htmlFor="message-text"><b>BILLING ADDRESS:</b></label>
                 <TextField value={billingAddress} />
                </div>
                <div className="col-xl-6">
                 <label className="col-form-label" htmlFor="message-text"><b>PLACE SUPPLY:</b></label>
                  <Autocomplete
                    options={state_arr}
                    getOptionLabel={option => option.name}
                    value={place_supply}                      
                    onChange={(event, newValue) => {
                      setPlaceSupply(newValue);
                    }}            
                    renderInput={params => (
                      <TextField {...params} margin="dense" helperText="Please choose the State" fullWidth />
                    )}
                  />
                </div>
                <div className="col-xl-6">
                 <label className="col-form-label" htmlFor="message-text"><b>TRANSACTION TYPE:</b></label>
                  <Autocomplete
                    options={transactionType}
                    getOptionLabel={option => option.name}
                    value={typeval}                      
                    onChange={(event, newValue) => {
                      setTypeVal(newValue);
                    }}            
                    renderInput={params => (
                      <TextField {...params} margin="dense" helperText="Please choose Transaction Type" fullWidth />
                    )}
                  />
                  <FormHelperText className="text-danger">{errors.txn_type}</FormHelperText>
                </div>
                { typeOfTransaction === 'Export Invoice' ?
                  <>
                  <div className="col-xl-6 m-t-10">
                    <label className="col-form-label" htmlFor="message-text"><b>CURRENCY:</b></label>
                    <Autocomplete
                      options={list_currency}
                      className="m-t-10"
                      getOptionLabel={option => option.name}
                      value={currency_val}                      
                      onChange={(event, newValue) => {
                        setCurrencyVal(newValue);
                      }}            
                      renderInput={params => (
                        <TextField {...params} margin="dense" helperText="Please choose currency" fullWidth />
                      )}
                    />
                  </div>
                  <div className="col-xl-6">
                    <label className="col-form-label" htmlFor="message-text">
                      <b>EXCHANGE RATE:
                        <i className="fa fa-info-circle" id="TooltipTop" data-placement="top"
                          style={{ width: 35, fontSize: 16, padding: 11, color: '#26c6da' }}>
                          <UncontrolledTooltip placement="top" target="TooltipTop">
                            Please enter the exchange rate to convert invoice value to applicable currency.
                            For ex: if you are invoicing in USD, and the local currency is INR, 
                            then please enter the exchange rate which is equivalent to 1 USD = (74 INR)
                          </UncontrolledTooltip>
                        </i>
                      </b>
                    </label>
                    <TextField className="m-t-10" name="exchange_rate" margin="dense" value={exchange_rate} 
                 	    onChange={(e) => setExchangeRate(e.target.value)} fullWidth/>
                  </div>  
                  </> 
                  :
                  <>
                  </>
                }
                <div className="col-xl-4">
                 <label className="col-form-label" htmlFor="message-text"><b>INVOICE DATE:</b></label>
                 <DatePicker className="form-control digits m-t-10"
                   selected={invoiceDate}
                   value={invoiceDate}
                   onChange={date => handleDateChange(date)}
                   dateFormat= "dd/MM/yyyy"
                 />
                </div>
                <div className="col-xl-4">
                 <label className="col-form-label" htmlFor="message-text"><b>CREDIT PERIOD(DAYS):</b></label>
                 <TextField variant="outlined" name="creditDays" margin="dense" value={creditPeriodData.creditDays} 
                 	onChange={handleChange} fullWidth/>
                </div>
                <div className="col-xl-4">
                 <label className="col-form-label" htmlFor="message-text"><b>DUE DATE:</b></label>
                 <TextField variant="outlined" margin="dense" value={deeDate} fullWidth/>
                </div>
                <div className="col-xl-12 m-t-15">
                 <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                   <thead className="table-primary">
                    <tr>
                     <th style={{width:'25%'}}>DESCRIPTION</th>
                     <th style={{width:'13%'}}>RATE</th>
                     <th style={{width:'8.3%'}}>QTY</th>
                     <th style={{width:'8.3%'}}>DISC</th>
                     <th style={{width:'15%'}}>TOTAL</th>
                     <th style={{width:'8.3%'}}>ACTION</th>
                    </tr>
                   </thead>
                  </table>
                 </div>
             	{ typeOfTransaction === 'GST Intra-State' ?
                    fields.map((el, i)=> {
                      const bbb = filterMastery.find(x=>x.id === el.part_no);
                      const aRate = bbb && bbb.rate;
                      const aDescription = bbb && bbb.description;
                      const aTaxRate = bbb && bbb.tax_rate;
                      //const aGst = Math.round(parseFloat(aRate - (aRate/(1+(aTaxRate/100))))) * el.qty;
                      // const aGst = Math.round(parseFloat(aRate * (aTaxRate/100))) * el.qty;
                      const aHsn = bbb && bbb.hsn;
                      const disc = (el.discount) === '' ? 0 
                        : 
                        (String(el.discount).indexOf('%') !== -1) ? 
                        parseFloat(el.qty * aRate) * parseFloat(el.discount) / 100 
                        :
                        parseFloat(el.discount)
                      const aTotal = parseFloat(el.qty * aRate) - disc || 0;
                      const aGst = Math.round(parseFloat(aTotal * (aTaxRate/100)));
                      const aTotalRs = foundCurrency ==='INR' ? indianNumberFormat(aTotal) : usNumberFormat(aTotal); 
                      totalAmount += parseFloat(aTotal + aGst) || 0;
                      totalA = getDiscount();
                      //totalB = indianNumberFormat(totalA);
                      totalB = foundCurrency ==='INR' ? indianNumberFormat(totalAmount) : usNumberFormat(totalAmount); 

                      return(
                       <div key={i} className="row p-1">
                        <div className="col-xl-4 col-md-4 col-sm-4">
                         <TextField id="part_no" select value={el.part_no || ''} fullWidth
                           onChange={(e) => handleChange3(i, e)} SelectProps={{ name: 'part_no' }}
                            margin="dense" label="Select an item">
                            {
                              filterMastery.map((n, index) => {
                                return <MenuItem key={index} value={n.id}>{n.part_no}</MenuItem>
                              })
                            }
                          </TextField>
                        </div>
                        <div className="col-xl-2 col-md-2 col-sm-2 p-l-0 m-t-20">
                         <TextField className="form-control btn-pill" type="text" value={aRate || ''} 
                          id="rate" name="rate" />
                        </div>
                        <div className="col-xl-1 col-md-1 col-sm-1 p-l-0 m-t-20">
                         <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.qty || ''} 
                          id="qty" name="qty"/>
                        </div>
                        <div className="col-xl-1 col-md-1 col-sm-1 p-l-0 m-t-20">
                         <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.discount || ''} 
                          id="discount" name="discount"/>
                        </div>
                        <div className="col-xl-2 col-md-2 col-sm-2 m-t-20">
                         <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} 
                          value={aTotalRs} />
                        </div>
                        <div className="col-xl-2 col-md-2 col-sm-2 m-t-20">
                          <button className="btn btn-link" onClick={() => handleRemove(i)}>
                            <span>X</span> 
                          </button>
                        </div>
                        {aRate ?
                         <>
	                        <div className="col-xl-4">
	                          <h4>{aDescription}</h4>
	                          <h6>HSN - {aHsn} </h6>
	                        </div>
	                        <div className="col-xl-3">
	                          <h6>CGST @ {parseFloat(aTaxRate/2)}%</h6>
	                          <h6>SGST @ {parseFloat(aTaxRate/2)}%</h6>
	                        </div>
	                        <div className="col-xl-3">
	                          <h6>+₹{parseFloat(aGst/2)}</h6>
	                          <h6>+₹{parseFloat(aGst/2)}</h6>
	                        </div>
	                     </>
	                        : <> </>
                        }
                       </div>
                      );
                     }) 
                    : typeOfTransaction === 'GST Inter-State' ?
                    fields.map((el, i)=> {
                      const bbb = filterMastery.find(x=>x.id === el.part_no);
                      const aRate = bbb && bbb.rate;
                      const aDescription = bbb && bbb.description;
                      const aTaxRate = bbb && bbb.tax_rate;
                      //const aGst = Math.round(parseFloat(aRate - (aRate/(1+(aTaxRate/100))))) * el.qty;
                      // const aGst = Math.round(parseFloat(aRate * (aTaxRate/100))) * el.qty;
                      const aHsn = bbb && bbb.hsn;
                      const disc = (el.discount) === '' ? 0 
                        : 
                        (String(el.discount).indexOf('%') !== -1) ? 
                        parseFloat(el.qty * aRate) * parseFloat(el.discount) / 100 
                        :
                        parseFloat(el.discount)
                      const aTotal = parseFloat(el.qty * aRate) - disc || 0;
                      const aGst = Math.round(parseFloat(aTotal * (aTaxRate/100)));
                      const aTotalRs = foundCurrency ==='INR' ? indianNumberFormat(aTotal) : usNumberFormat(aTotal); 
                      totalAmount += parseFloat(aTotal + aGst) || 0;
                      totalA = getDiscount();
                      //totalB = indianNumberFormat(totalA);
                      totalB = foundCurrency ==='INR' ? indianNumberFormat(totalAmount) : usNumberFormat(totalAmount);

                      return(
                       <div key={i} className="row p-1">
                        <div className="col-xl-4 col-md-4 col-sm-4">
                         <TextField id="part_no" select value={el.part_no || ''} fullWidth
                           onChange={(e) => handleChange3(i, e)} SelectProps={{ name: 'part_no' }}
                            margin="dense" label="Select an item">
                            {
                              filterMastery.map((n, index) => {
                                return <MenuItem key={index} value={n.id}>{n.part_no}</MenuItem>
                              })
                            }
                          </TextField>
                        </div>
                        <div className="col-xl-2 col-md-2 col-sm-2 p-l-0 m-t-20">
                         <TextField className="form-control btn-pill" type="text" value={aRate || ''} 
                          id="rate" name="rate"/>
                        </div>
                        <div className="col-xl-1 col-md-1 col-sm-1 p-l-0 m-t-20">
                         <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.qty || ''} 
                          id="qty" name="qty"/>
                        </div>
                        <div className="col-xl-1 col-md-1 col-sm-1 p-l-0 m-t-20">
                         <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.discount || ''} 
                          id="discount" name="discount"/>
                        </div>
                        <div className="col-xl-2 col-md-2 col-sm-2 m-t-20">
                         <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} 
                          value={aTotalRs}/>
                        </div>
                        <div className="col-xl-2 col-md-2 col-sm-2 m-t-20">
                          <button className="btn btn-link" onClick={() => handleRemove(i)}>
                            <span>X</span> 
                          </button>
                        </div>
                        {aRate ?
                        <> 
                        <div className="col-xl-4">
                          <h4>{aDescription}</h4>
                          <h6>HSN - {aHsn} </h6>
                        </div>
                        <div className="col-xl-3">
                          <h6>IGST @ {parseFloat(aTaxRate)}%</h6>
                        </div>
                        <div className="col-xl-3">
                          <h6>+₹{parseFloat(aGst)}</h6>
                        </div>
                        </>
                        : <> </>
                        }
                       </div>
                      );
                     })
                      : typeOfTransaction === 'Export Invoice' ?
                      fields.map((el, i)=> {
                        const bbb = filterMastery.find(x=>x.id === el.part_no);
                        const aRate = bbb && bbb.rate;
                        const aDescription = bbb && bbb.description;
                        const aTaxRate = bbb && bbb.tax_rate;
                        const aGst = Math.round(parseFloat(aRate - (aRate/(1+(aTaxRate/100))))) * el.qty;
                        const aHsn = bbb && bbb.hsn;
                        var exRate = parseFloat(exchange_rate);
                        var rateExc = parseFloat(aRate/exRate).toFixed(2); //Divide exchange rate with aRate
                        const disc = (el.discount) === '' ? 0 
                                      : 
                                      (String(el.discount).indexOf('%') !== -1) ? 
                                      parseFloat(el.qty * rateExc) * parseFloat(el.discount) / 100 
                                      :
                                      parseFloat(el.discount)
                        const aTotal = parseFloat(el.qty * rateExc) - disc || 0;
                        //const aTotal = parseFloat(el.qty * rateExc) || 0;
                        const aTotalRs = selectedCurrency === 'USD' ? usNumberFormat(aTotal) : indianNumberFormat(aTotal);
                        totalAmount += parseFloat(aTotal) || 0;
                        totalA = getDiscount();
                        //totalB = indianNumberFormat(totalA);
                        totalB = selectedCurrency ==='USD' ? usNumberFormat(totalAmount) : indianNumberFormat(totalAmount);
                        
                        
                        return(
                         <div key={i} className="row p-1">
                          <div className="col-xl-4 col-md-4 col-sm-4">
                           <TextField id="part_no" select value={el.part_no || ''} fullWidth
                             onChange={(e) => handleChange3(i, e)} SelectProps={{ name: 'part_no' }}
                              margin="dense" label="Select an item">
                              {
                                filterMastery.map((n, index) => {
                                  return <MenuItem key={index} value={n.id}>{n.part_no}</MenuItem>
                                })
                              }
                            </TextField>
                          </div>
                          <div className="col-xl-2 col-md-2 col-sm-2 p-l-0 m-t-20">
                           <TextField className="form-control btn-pill" type="text" value={rateExc || 0} 
                            id="rate" name="rate"/>
                          </div>
                          <div className="col-xl-1 col-md-1 col-sm-1 p-l-0 m-t-20">
                           <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.qty || ''} 
                            id="qty" name="qty"/>
                          </div>
                          <div className="col-xl-1 col-md-1 col-sm-1 p-l-0 m-t-20">
                          <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.discount || ''} 
                            id="discount" name="discount"/>
                          </div>
                          <div className="col-xl-2 col-md-2 col-sm-2 m-t-20">
                           <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} 
                            value={aTotalRs}/>
                          </div>
                          <div className="col-xl-2 col-md-2 col-sm-2 m-t-20">
                            <button className="btn btn-link" onClick={() => handleRemove(i)}>
                              <span>X</span> 
                            </button>
                          </div>
                          {aRate ?
                            <> 
                            <div className="col-xl-4">
                              <h4>{aDescription}</h4>
                              <h6>HSN - {aHsn} </h6>
                            </div>
                            </>
                            : <> </>
                          }
                         </div>
                        );
                       })
                      :
                      fields.map((el, i)=> {
                      const bbb = filterMastery.find(x=>x.id === el.part_no);
                      const aRate = bbb && bbb.rate;
                      const aDescription = bbb && bbb.description;
                      const aTaxRate = bbb && bbb.tax_rate;
                      const aHsn = bbb && bbb.hsn;
                      const disc = (el.discount) === '' ? 0 
                                    : 
                                    (String(el.discount).indexOf('%') !== -1) ? 
                                    parseFloat(el.qty * aRate) * parseFloat(el.discount) / 100 
                                    :
                                    parseFloat(el.discount)
                      const aTotal = parseFloat(el.qty * aRate) - disc || 0;
                      const aTotalRs = foundCurrency ==='INR' ? indianNumberFormat(aTotal) : usNumberFormat(aTotal);
                      totalAmount += parseFloat(aTotal) || 0;
                      totalA = getDiscount();
                      totalB = foundCurrency ==='INR' ? indianNumberFormat(totalAmount) : usNumberFormat(totalAmount);

                      return(
                       <div key={i} className="row p-1">
                        <div className="col-xl-4 col-md-4 col-sm-4">
                         <TextField id="part_no" select value={el.part_no || ''} fullWidth
                           onChange={(e) => handleChange3(i, e)} SelectProps={{ name: 'part_no' }}
                            margin="dense" label="Select an item">
                            {
                              filterMastery.map((n, index) => {
                                return <MenuItem key={index} value={n.id}>{n.part_no}</MenuItem>
                              })
                            }
                          </TextField>
                        </div>
                        <div className="col-xl-2 col-md-2 col-sm-2 p-l-0 m-t-20">
                         <TextField className="form-control btn-pill" type="text" value={aRate || ''} 
                          id="rate" name="rate"/>
                        </div>
                        <div className="col-xl-1 col-md-1 col-sm-1 p-l-0 m-t-20">
                         <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.qty || ''} 
                          id="qty" name="qty"/>
                        </div>
                        <div className="col-xl-1 col-md-1 col-sm-1 p-l-0 m-t-20">
                          <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} value={el.discount || ''} 
                            id="discount" name="discount"/>
                          </div>
                        <div className="col-xl-2 col-md-2 col-sm-2 m-t-20">
                         <TextField className="form-control btn-pill" type="text" onChange={(e) => handleChange3(i, e)} 
                          value={aTotalRs}/>
                        </div>
                        <div className="col-xl-2 col-md-2 col-sm-2 m-t-20">
                          <button className="btn btn-link" onClick={() => handleRemove(i)}>
                            <span>X</span> 
                          </button>
                        </div>
                        {aRate ?
                        <> 
                        <div className="col-xl-4">
                          <h4>{aDescription}</h4>
                          <h6>HSN - {aHsn} </h6>
                        </div>
                        </>
                        : <> </>
                        }
                       </div>
                      );
                     })
                    }
                    <button className="btn btn-link" onClick={() => addClick()}>
                      <span>+</span>Add Line Item 
                    </button>
                   </div>
                   <div className="offset-xl-6 col-xl-6 m-t-15">
                    <h4> Total Amount: { totalB || totalAmount}</h4>
                   </div>
                   <div className="col-xl-12 m-t-15">
                    <label className="col-form-label" htmlFor="message-text"><b>CUSTOMER NOTES</b></label>
                    <TextField multiline name="cus_notes"value={cus_notes} onChange={(e) => setCusNotes(e.target.value)} fullWidth/>
                   </div>
                   <div className="col-xl-12 m-t-15">
                    <label className="col-form-label" htmlFor="message-text"><b>TERMS AND CONDITIONS</b></label>
                    <TextField multiline name="terms"value={terms} onChange={(e) => setTerms(e.target.value)} 
                      rows={4} fullWidth/>
                   </div>
                   <div className="card-footer m-t-20">
                     <h5 className="text-center">{companyName}</h5>
                     <p>{companyAddress}</p>
                   </div>
               </div>
             </div>
            </div>
         </div>
		</div>
		<div className="col-xl-3">
		 <div className="card">
            <div className="card-body">
              <div className="col-xl-12">
               <i className="fa fa-arrow-left"></i>
               	<button className="btn btn-link" onClick={() => handleBack()}> Back </button>
              </div>
              <div className="col-xl-12">
               <i className="fa fa-save"></i>
               	<button className="btn btn-link text-success" onClick={() => saveInvoice()}> Save Invoice </button>
              </div>
            </div>
         </div>
        </div>
	   </div>
	  </div>
	  
	  {/* Less Date Modal */}
	  <Modal isOpen={lessdatemodal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 1000 }} 
	   returnFocusAfterClose={false} toggle={toggleLess}>
	   <ModalHeader toggle={toggleLess}>Date confirmation</ModalHeader>
	    <ModalBody>
	     <div className="row">
	      <div className="col-xl-12 text-center">
	     	<h6>The selected Invoice date is <b>{dateofInvoice}</b> which is <b>{-(difference)}</b> day/s lesser than the current date </h6>
	      </div>
	      <div className="col-xl-12 text-center">
	        <h4>Are you sure about the selected date?</h4>
	      </div>
	      <div className="col-xl-12 text-center">
	       <button className="btn btn-danger" onClick={toggleLess}>OK</button>
	      </div>
	     </div>
	    </ModalBody>
	  </Modal>

	{/* More Date Modal with custom transition timeouts */}
	  <Modal isOpen={moredatemodal} modalTransition={{ timeout: 500 }} backdropTransition={{ timeout: 1000 }} 
	   returnFocusAfterClose={false} toggle={toggleMore}>
	   <ModalHeader toggle={toggleMore}>Date confirmation</ModalHeader>
	    <ModalBody>
	     <div className="row">
	      <div className="col-xl-12 text-center">
	     	<h6>The selected Invoice date is <b>{dateofInvoice}</b> which is <b>{difference}</b> day/s more than the current date </h6>
	      </div>
	      <div className="col-xl-12 text-center">
	        <h4>Are you sure about the selected date?</h4>
	      </div>
	      <div className="col-xl-12 text-center">
	       <button className="btn btn-danger" onClick={toggleMore}>OK</button>
	      </div>
	     </div>
	    </ModalBody>
	  </Modal>
	 </Fragment>
	);
}

export default withRouter(RaiseInvoice);