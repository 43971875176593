import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//import clsx from 'clsx';
import { Card, CardContent, Divider, TextField, Button, MenuItem, Grid,
         FormControl, FormLabel, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1),
  },
  barchartcard: {
    margin: theme.spacing(1),
  },
  }));

export default function Consumption(props) {
  const classes = useStyles();
    const theme = useTheme();
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    const downloadBomFile = () => {
      // fetch('http://localhost:9004/Data/BOM/bom.xlsx')
      //fetch('http://172.105.41.149:9004/Data/BOM/bom.xlsx')
      //fetch('https://testerp.nu-pie.com/Data/BOM/bom.xlsx')
      fetch('172.105.41.149:9004/Data/BOM/bom.xlsx')
          .then(response => {
              response.blob().then(blob => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = 'bom.xlsx';
                  a.click();
              });
              //window.location.href = response.url;
      });
    }
  
    const { importfile, ProjectNum, values } = props;

    // const [bom_applicable, setBomApplicable] = useState();
    // const handleChange = event => {
    //   setBomApplicable(event.target.value);
    // }
    
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <FormControl component="fieldset">
              <FormLabel component="legend">Is BOM Applicable for this project ?</FormLabel>
              <RadioGroup row aria-label="bom" name="bom_applicable" value={values.bom_applicable} onChange={props.handleChange} fullWidth>
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          {values.bom_applicable === 'yes' ?
            <div className="row">
             <div className="col-xl-6">
              <TextField fullWidth required id="name" label="Project Num" margin="normal" name="name" 
                value={ProjectNum} variant="outlined" />
             </div>
             <div className="col-xl-6">
              <TextField id="files" type="file" margin="normal" variant="outlined" fullWidth onChange={props.handleUploadBOM} />
             </div>
            </div>
            :
            <div>
            </div>
          }
          <div className="col-xl-4 m-t-20">
            <button className="btn btn-info btn-lg" onClick={downloadBomFile}>Download BOM Template</button>
          </div>
        </div>
      </div>
    );
}