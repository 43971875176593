import React, { Fragment } from 'react';
import { TextField, Typography, Button, Grid } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Leaves = (props) => {
    const {values, errors, finance_start, finance_end} = props

    return(
        <Fragment>
         <Typography variant="h6" gutterBottom>
            Leave Balance
        </Typography>
        <Grid container spacing={3} >
            <Grid item lg={12}>
                <div className="ml-1">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                    <TextField id="med_leave" type="number" name="med_leave" label="Medical" value={values.med_leave} onChange={props.handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <TextField id="cas_leave" type="number" name="cas_leave" label="Casual" value={values.cas_leave} onChange={props.handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <TextField id="maternity_leave" type="number" name="maternity_leave" label="Maternity/Paternity" value={values.maternity_leave} onChange={props.handleChange} fullWidth />
                    </Grid>
                </Grid>
                </div>
            </Grid>
            <Grid item lg={12}>
                <Typography variant="h6" gutterBottom>
                    Financial Year
                </Typography>
                <div className="ml-1">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <DatePicker className="form-control digits text-success"
                            selected={finance_start}
                            value={finance_start}
                            onChange={date => props.setFinanceStart(date)}
                            dateFormat="MMM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showTwoColumnMonthYearPicker
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Select Start of the year"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePicker className="form-control digits text-success"
                            selected={finance_end}
                            value={finance_end}
                            onChange={date => props.setFinanceEnd(date)}
                            dateFormat="MMM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showTwoColumnMonthYearPicker
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Select End of the year"
                        />
                    </Grid>
                </Grid>
                </div>
            </Grid>
        </Grid>
        </Fragment>
    );
}

export default Leaves;