import React, { useState, useEffect } from 'react';
import { indianNumberFormat, usNumberFormat } from '../../../actions/numberFormat';
import { getVendorForm, getIndentItems, getProjects, 
        getMasterInventory, getSettings } from '../../../actions/asyncActions';
import * as moment from 'moment';
import Logo from '../../../assets/images/nupielogo.png';

export default function MaterialTableDemo(props) {
  
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  
  const [masterInventory, setMasterInventory] = useState([]);
  const [settings, setSettings] = useState([]);
  const curSettings = settings.find(x=>x.group_id === group_id);
  const foundCurrency = curSettings ? curSettings && curSettings.currency : null;
  var numberToText = require('number2text');

  let totalAmount = 0;
  let totalA = 0;
  let totalInWords = 0;
  let totalGstAmount = 0;
  let totalG = 0;
  let totalSGSTAmount = 0; 
  let totalS = 0;
  

  const {companyName, companyAddress, GSTIN, invoiceNo, billingTo, billingAddress, placeSupply, 
         transactionType, invoiceDate, dueDate, itemData, selectedCurrency,
         exchangeRate, customerNotes, termsConditions } = props;
  const doi = moment(invoiceDate).format('ll');
  const dateDue = moment(dueDate).format('ll');
  const [vendors, setVendors] = useState([]);
  const [indentItems, setIndentItems] = useState([]);
  const [projects, setProject] = useState([]);
    
  useEffect( () => {
    getMasterInventory(group_id)
    .then(resp => setMasterInventory(resp.data))
  }, []);

  useEffect( () => {
    getSettings(group_id)
    .then(resp => setSettings(resp.data))
  }, []);

  useEffect( () => {
    getVendorForm(group_id)
    .then(resp => setVendors(resp.data))
  }, []); 

  useEffect( () => {
    getIndentItems(group_id)
    .then(resp => setIndentItems(resp.data))
  }, []);

  useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
  }, []);

  const filterIndentItems = indentItems.filter
  (x=> x.project_id === props.selectedProject && x.vendor_id === props.selectedVendor && x.status === "VendorSelected");
  const getTotal = filterIndentItems.reduce((a, b) => a + b.total, 0);
  //const totalInWords = numberToText(getTotal,'', true);

  const proName = projects.find(x=>x.id === props.selectedProject);
  const projectName = proName && proName.name;

  const InvItems = () => {
    if(transactionType === 'Export Invoice') {
      return itemData.map((n, i) => {
        const aaa = masterInventory.find(x=>x.id === n.part_no);
        const description = aaa && aaa.description;
        const aHsn = aaa && aaa.hsn;
        const aRate = aaa && aaa.rate;
        var exRate = parseFloat(exchangeRate);
        var rateExc = parseFloat(aRate/exRate).toFixed(2);
        var partDescription = aaa && aaa.part_no.concat('(',description, ')')
        const disc = (n.discount) === '' ? 0 
                      : 
                      (String(n.discount).indexOf('%') !== -1) ? 
                      parseFloat(n.qty * rateExc) * parseFloat(n.discount) / 100 
                      :
                      parseFloat(n.discount)
        const aTotal = parseFloat(n.qty * rateExc) - disc || 0;
        const aTotalRs = selectedCurrency === 'USD' ? usNumberFormat(aTotal) : indianNumberFormat(aTotal);
        
        totalAmount += parseFloat(aTotal) || 0;
        totalInWords = selectedCurrency === 'USD' ? numberToText(totalAmount,'English') : numberToText(totalAmount,'', true);
        totalA = selectedCurrency ==='USD' ? usNumberFormat(totalAmount) : indianNumberFormat(totalAmount);

        return(
        <tr key={i}>
            <td>{i+1}</td>
            <td>{partDescription}</td>
            <td>{aHsn}</td>
            <td>{aRate}</td>
            <td>{n.qty}</td>
            <td>{n.discount}</td>
            <td>{aTotalRs}</td>
        </tr>
        );
    })
    }
    else if (transactionType === 'Non GST Sale') {
      return itemData.map((n, i) => {
        const aaa = masterInventory.find(x=>x.id === n.part_no);
        const description = aaa && aaa.description;
        const aHsn = aaa && aaa.hsn;
        const aRate = aaa && aaa.rate;
        var partDescription = aaa && aaa.part_no.concat('(',description, ')')
        const disc = (n.discount) === '' ? 0 
                      : 
                      (String(n.discount).indexOf('%') !== -1) ? 
                      parseFloat(n.qty * aRate) * parseFloat(n.discount) / 100 
                      :
                      parseFloat(n.discount)
        const aTotal = parseFloat(n.qty * aRate) - disc || 0;
        const aTotalRs = foundCurrency === 'USD' ? usNumberFormat(aTotal) : indianNumberFormat(aTotal);
        
        totalAmount += parseFloat(aTotal) || 0;
        totalInWords = foundCurrency === 'USD' ? numberToText(totalAmount,'English') : numberToText(totalAmount,'', true);
        totalA = foundCurrency ==='USD' ? usNumberFormat(totalAmount) : indianNumberFormat(totalAmount);

        return(
        <tr key={i}>
            <td>{i+1}</td>
            <td>{partDescription}</td>
            <td>{aHsn}</td>
            <td>{aRate}</td>
            <td>{n.qty}</td>
            <td>{n.discount}</td>
            <td>{aTotalRs}</td>
        </tr>
        );
      })
    }
    else {
      return itemData.map((n, i) => {
        const aaa = masterInventory.find(x=>x.id === n.part_no);
        const description = aaa && aaa.description;
        const aHsn = aaa && aaa.hsn;
        const aRate = aaa && aaa.rate;
        const aTaxRate = aaa && aaa.tax_rate;
        var partDescription = aaa && aaa.part_no.concat('(',description, ')')
        const disc = (n.discount) === '' ? 0 
                        : 
                        (String(n.discount).indexOf('%') !== -1) ? 
                        parseFloat(n.qty * aRate) * parseFloat(n.discount) / 100 
                        :
                        parseFloat(n.discount)
        const aTotal = parseFloat(n.qty * aRate) - disc || 0;
        const aTotalRs = foundCurrency ==='INR' ? indianNumberFormat(aTotal) : usNumberFormat(aTotal);
        const aGst = Math.round(parseFloat(aTotal * (aTaxRate/100)));
        
        totalAmount += parseFloat(aTotal + aGst) || 0;
        totalInWords = foundCurrency === 'USD' ? numberToText(totalAmount,'English') : numberToText(totalAmount,'', true);
        totalGstAmount += aGst || 0;
        totalSGSTAmount += (aGst/2) || 0; 
        
        totalA = foundCurrency ==='INR' ? indianNumberFormat(totalAmount) : usNumberFormat(totalAmount);
        totalG = foundCurrency ==='INR' ? indianNumberFormat(totalGstAmount) : usNumberFormat(totalGstAmount);
        totalS = foundCurrency ==='INR' ? indianNumberFormat(totalSGSTAmount) : usNumberFormat(totalSGSTAmount);

        return(
        <tr key={i}>
            <td>{i+1}</td>
            <td>{partDescription}</td>
            <td>{aHsn}</td>
            <td>{aRate}</td>
            <td>{n.qty}</td>
            <td>{n.discount}</td>
            <td>{aTotalRs}</td>
        </tr>
        );
    })
    }
    
  }

  const {selectedProject, selectedVendor, dispatch, lastPO } = props;
  const datee = new Date();
  const date = moment(datee).format('ll');
  const vendorAddress = vendors.find(x=>x.id === props.selectedVendor);
  const supplier = vendorAddress && vendorAddress.supname;
  const address = vendorAddress && vendorAddress.factAddress;
  const gstno = vendorAddress && vendorAddress.gstno;
  const invitems = InvItems();

  return (
    <div id="printreceipt">
     <section className="sheet padding-5mm">
        <table style={{width:'95%', paddingBottom: '20px', marginTop: '20px'}}>
            <tr>
                <td><h2>{companyName}</h2></td>
                <td><img src={Logo} style={{width:"100%", maxWidth:"120px"}} /></td>
            </tr>
        </table>
        <table style={{width:'95%', paddingBottom: '20px'}}>
            <tr>
                <td>Invoice #: <b>{invoiceNo}</b> </td>
                <td></td>
            </tr>
        </table>
        <table style={{width:'95%', paddingBottom: '10px'}}>
            <tr>
                <td>Billing To:<b>{props.billingTo}</b> </td>
                <td>Billing Address:<b>{props.billingAddress}</b></td>
            </tr>
            <tr>
                <td>Transaction Type:<b>{props.transactionType}</b></td>
                <td>Place of Supply:<b>{props.placeSupply}</b> </td>
            </tr>
            <tr>
                <td>Date of Invoice:<b>{doi}</b> </td>
                <td>Due Date:<b>{dateDue}</b></td>
            </tr>
        </table>
        <table style={{width:'95%', border: '1px solid', paddingBottom: '10px'}}>
            <thead>
                <tr>              
                    <th align="left">Sl.No</th>
                    <th align="left">Description of Goods</th>
                    <th align="left">HSN</th>
                    <th align="left">Rate</th>
                    <th align="left">Qty</th>
                    <th align="left">Disc</th>
                    <th align="left">Total</th>
                </tr>
            </thead>
            <tbody>
                {invitems}
            </tbody>
        </table>
        <table style={{width:'95%', border: '1px solid'}}>
            { transactionType === 'GST Intra-State' ?
                <>
                <tr>
                  <td align="left"><p>Amount Chargeable (in words)</p></td>
                  <td colSpan="4" align="right">SGST: <b>{totalS}</b></td>
                </tr>
                <tr>
                 <td align="left"><b>{totalInWords}</b></td>
                 <td colSpan="4" align="right">CGST: <b>{totalS}</b></td>
                </tr>
                </>
                : transactionType === 'GST Inter-State' ?
                <tr>
                 <td align="left">
                    <p>Amount Chargeable (in words) <br/>
                      <b>{totalInWords}</b>
                    </p>
                 </td>
                 <td colSpan="4" align="right">IGST: <b>{totalG}</b></td>
                </tr>
                :
                <>
                  <td align="left">
                    <p>Amount Chargeable (in words) <br/>
                      <b>{totalInWords}</b>
                    </p>
                  </td>
                </>
            }
            <tr>
            <td colSpan="4" align="right"><h2>Grand Total: {totalA}</h2></td>
            </tr>
        </table>
        <table style={{width:'95%', border: '1px solid'}}>
          <tr>
            <td align="center">Customer Notes:</td>
          </tr>
          <tr>
            <td><b>{customerNotes}</b></td>
          </tr>
        </table>
        <table style={{width:'95%', border: '1px solid'}}>
          <tr>
            <td align="center">Terms and Conditions:</td>
          </tr>
          <tr>
            <td><b>{termsConditions}</b></td>
          </tr>
        </table>
        {/*
        <table style={{width: '95%'}}>
          <tr>
            <td align="center">{companyName}</td>
          </tr>
          <tr>
            <td align="center">{companyAddress}</td>
          </tr>
        </table>
        */}
        <footer style={{position: "fixed", left: "0", bottom: "0", width:"100%"}}>
          <h4 align="center">{companyName}</h4>
          <p align="center">{companyAddress}</p>
        </footer>
     </section>
    </div>
  );
}