import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Grid, FormHelperText } from '@material-ui/core';
//import Switch from '@material-ui/core/Switch';
//import FormGroup from '@material-ui/core/FormGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import { KeyboardDatePicker } from '@material-ui/pickers';
//import InfoIcon from '@material-ui/icons/Info';
//import Delete from '@material-ui/icons/Delete';
//import { postVendorForm, getVendorForm, aadharUpload, panUpload }  from '../../actions/asyncActions';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  table_root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  margin: {
    margin: theme.spacing(2),
  },
  errors: {
    color: 'red'
  }
}));

export default function VendorForm(props) {
  //const user = JSON.parse(localStorage.getItem("m_users"));
  //const company_id= user.company_id;
  //const group_id = user.group_id;
  const classes = useStyles();
  //const [values, setValues] = useState({});
  //const [datee, setDate] = useState();
  //const datee = new Date();
  // const [errors, setError] = useState({});
  //const [vendorForm, setVendorForm] = useState([]);
  //const [open, setOpen] = useState(false);

  // const { supname, factAddress, headAddress, telephone, office, res, faxno, mobileno, email, values, errors } = props;
  const { values, errors } = props;
 
  // useEffect( () => {
  //   getVendorForm(group_id)
  //   .then(resp => {
  //     setVendorForm(resp.data);
  //   })
  // }, []);

return (
<React.Fragment>
  <Grid container spacing={3} >
    <Grid item xs={12}>
     <Typography gutterBottom>
      Name of the Supplier:
     </Typography>
      <TextField required id="supname" name="supname" value={values.supname} onChange={props.handleChange} fullWidth autoFocus />
      <FormHelperText className={classes.errors}>{errors.supname}</FormHelperText>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Typography gutterBottom>
       Address - Factory /Godown:
     </Typography>
     <TextField multiline id="factAddress" name="factAddress"  value={values.factAddress} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Typography gutterBottom>
       Address - Head Office:
     </Typography>
     <TextField multiline id="headAddress" name="headAddress" value={values.headAddress} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Typography gutterBottom>
       Telephone:
     </Typography>
     <TextField type="number" id="telephone" name="telephone" value={values.telephone} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Typography gutterBottom>
       Office:
     </Typography>
     <TextField type="number" id="office" name="office" value={values.office} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Typography gutterBottom>
       Residential:
     </Typography>
     <TextField type="number" id="res" name="res" value={values.res} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Typography gutterBottom>
       Fax:
     </Typography>
     <TextField type="number" id="faxno" name="faxno" value={values.faxno} 
       onChange={props.handleChange} fullWidth />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Typography gutterBottom>
       Mobile:
     </Typography>
     <TextField required type="number" id="mobileno" name="mobileno" value={values.mobileno} 
       onChange={props.handleChange} fullWidth />
     <FormHelperText className={classes.errors}>{errors.mobileno}</FormHelperText>
    </Grid>
    <Grid item xs={12}>
      <Typography gutterBottom>
       Email Id:
     </Typography>
     <TextField required id="email" name="email" value={values.email} 
       onChange={props.handleChange} fullWidth />
     <FormHelperText className={classes.errors}>{errors.email}</FormHelperText>
    </Grid>
      
  </Grid>
</React.Fragment>
 );
 }
