import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { TextField, MenuItem, FormHelperText, TablePagination } from '@material-ui/core';
import { getAllUser, getManagers, postManagers, editManagers } from '../../actions/asyncActions';

const MyManager = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const [employee, setEmployee] = useState([]);
    const [person, setPerson] = useState([]);
    const initialFormState = { id: '', emp_id:'', mgr_id:'', }
    const [ cu_user, setCurrentUser ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();
    const [values, setValues] = useState({});
    const [errors, setError] = React.useState({});

    const filterPerson = employee.filter(x=>x.role !== 'superadmin')

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
     getAllUser(group_id)
     .then(resp => {
      setEmployee(resp.data);
     })
    }, []);

    useEffect( () => {
    getManagers(group_id)
    .then(resp => {
      setPerson(resp.data);
    })
  }, []);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const addRow = () => {
     setAddModal(!addmodal)
     setValues('');
  }

  const editRow = user => {
    setModal(!modal)
    setCurrentUser({ id: user.id, emp_id:user.emp_id, mgr_id:user.mgr_id })
  }

  const validateForm = (data) => {
     const errors = [];
     const existEmp = person.find(x=>x.emp_id === data.emp_id);
     const existMgr = person.find(x=>x.mgr_id === data.mgr_id);
     const existEmpMgr = person.find(x=>x.emp_id === data.mgr_id && x.mgr_id === data.emp_id);
     if(existEmp){
      errors.emp_id = "Employee already exists";
     }
     if(existMgr){
      errors.mgr_id = "Manager already exists";
     }
     if(existEmpMgr){
      errors.emp_id = "Assigned Manager cannot be an Employee";
     }
     if(data.emp_id === data.mgr_id){
      errors.mgr_id = "Cannot select Employee as Manager";
     }
     return errors;
  }

  const validateForm2 = (data) => {
    const errors = [];
    if(data.emp_id === data.mgr_id){
      errors.mgr_id = "Cannot select Employee as Manager";
     }
     return errors;
  }

  const handleSubmit = () => {
    const users = JSON.parse(localStorage.getItem("m_users"));
    const group_id = users.group_id;
    const user = {
      emp_id: values.emp_id,
      mgr_id: values.mgr_id,
      user_id: users.id,
      group_id: group_id,
      company_id: users.company_id
    }
    const errors = validateForm(user);
    if(Object.keys(errors).length) {
      setError(errors);
      return;
    }
    postManagers(user)
    .then(resp => {
        getManagers(user.group_id)
        .then(resp => setPerson(resp.data))
        toast.success("Manager Mapped Successfully !!!")
        setAddModal(!addmodal)
        setValues('');
      })
      .catch(err => {
        console.log("Error", err);
    })
  }

  
  const handleInputChange = event => {
    const { name, value } = event.target
    setCurrentUser({ ...cu_user, [name]: value })
  }

  const handleUpdate = (id, data) => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const store = user && user.group_id;
    const bbb = {
      id: id,
      emp_id: data.emp_id,
      mgr_id: data.mgr_id,
    }
    const errors = validateForm2(data);
    if(Object.keys(errors).length) {
      setError(errors);
      return;
    }
    editManagers(bbb)
    .then(resp => {
          getManagers(store)
          .then(resp => setPerson(resp.data))
          toast.success("Mapping Updated Successfully!")
          setValues('');
        })
        .catch(err => {
          console.log("Error", err);
        })
        setModal(!modal)
  }

        return (
            <Fragment>
            <Breadcrumb title="Manager Mapping" parent="Users" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                              <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Employee Name</th>
                                                <th scope="col">Manager Name</th>
                                                <th scope="col">Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {person.filter(s => s.role !== 'superadmin')
                                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                              .map((n, i)=> {
                                                const empName = (employee || []).find(s => s.id === n.emp_id);
                                                const mgrName = (employee || []).find(s => s.id === n.mgr_id);
                                                return (
                                                  <tr>
                                                   <th scope="row">{i+1}</th>
                                                   <td>{empName && empName.firstname}</td>
                                                   <td>{mgrName && mgrName.firstname}</td>
                                                   <td>
                                                    <button className="btn btn-info btn-xs" type="button" onClick={() => editRow(n)}>
                                                     <i className="icofont icofont-edit"></i>
                                                    </button>
                                                   </td>
                                                  </tr>
                                                );
                                               })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                       <button className="btn btn-info btn-sm" type="button" onClick={() => addRow()}>
                                         Map Manager
                                        </button>
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={person.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Manager Mapping --> */}
            <Modal isOpen={addmodal} toggle={addRow} className="modal-body" centered={true}>
                <ModalHeader toggle={addRow}>Map Manager</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <TextField id="emp_id" select helperText="Please Select Employee Name" label="Employee Name" value={values.emp_id}
                                onChange={handleChange} SelectProps={{ name: 'emp_id' }} margin="dense" fullWidth variant="outlined" >
                                    {
                                      filterPerson.map((n, index) => {
                                        return <MenuItem key={index} value={n.id}>{n.firstname}</MenuItem>
                                      })
                                    }
                              </TextField>
                              <FormHelperText className="text-danger">{errors.emp_id}</FormHelperText>
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField id="mgr_id" select helperText="Please Select Manager Name" label="Manager Name" value={values.mgr_id}
                                onChange={handleChange} SelectProps={{ name: 'mgr_id' }} margin="dense" fullWidth variant="outlined" >
                                    {
                                      filterPerson.map((n, index) => {
                                        return <MenuItem key={index} value={n.id}>{n.firstname}</MenuItem>
                                      })
                                    }
                              </TextField>
                              <FormHelperText className="text-danger">{errors.mgr_id}</FormHelperText>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                    <Button color="secondary" onClick={addRow}>Cancel</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Manager Mapping --> */}
            <Modal isOpen={modal} toggle={editRow} className="modal-body" centered={true}>
                <ModalHeader toggle={editRow}>Edit User</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <TextField id="emp_id" select helperText="Please Select Employee Name" label="Employee Name" value={cu_user.emp_id}
                                onChange={handleInputChange} SelectProps={{ name: 'emp_id' }} margin="dense" fullWidth variant="outlined" >
                                    {
                                      filterPerson.map((n, index) => {
                                        return <MenuItem key={index} value={n.id}>{n.firstname}</MenuItem>
                                      })
                                    }
                              </TextField>
                            </div>
                            <div className="col-md-6 mb-3">
                              <TextField id="mgr_id" select helperText="Please Select Manager Name" label="Manager Name" value={cu_user.mgr_id}
                                onChange={handleInputChange} SelectProps={{ name: 'mgr_id' }} margin="dense" fullWidth variant="outlined" >
                                    {
                                      filterPerson.map((n, index) => {
                                        return <MenuItem key={index} value={n.id}>{n.firstname}</MenuItem>
                                      })
                                    }
                              </TextField>
                              <FormHelperText className="text-danger">{errors.mgr_id}</FormHelperText>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={ () => handleUpdate(cu_user.id, cu_user)} >Save Changes</Button>
                    <Button color="secondary" onClick={editRow}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default MyManager;